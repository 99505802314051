// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  position: relative;
  width: 76px;
  height: 34px;
  border: 1px solid #C4C4C4;
  border-radius: 26px;
  background: #EDF2F4;
  padding: 3px;
}

.switchUnchecked {
  position: absolute;
  left: 0;
  margin-left: 4px;
  width: 34px;
  height: 26px;
  border-radius: 26px;
  background-color: #003049;
}

.switchChecked {
  position: absolute;
  right: 0;
  margin-right: 4px;
  width: 34px;
  height: 26px;
  border-radius: 26px;
  background-color: #EA004C;
}`, "",{"version":3,"sources":["webpack://./src/components/Switch/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,OAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,QAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAGF","sourcesContent":[".switch {\n  position: relative;\n  width: 76px;\n  height: 34px;\n  border: 1px solid #C4C4C4;\n  border-radius: 26px;\n  background: #EDF2F4;\n  padding: 3px;\n}\n.switchUnchecked {\n  position: absolute;\n  left: 0;\n  margin-left: 4px;\n  width: 34px;\n  height: 26px;\n  border-radius: 26px;\n  background-color: #003049;\n}\n.switchChecked {\n  position: absolute;\n  right: 0;\n  margin-right: 4px;\n  width: 34px;\n  height: 26px;\n  border-radius: 26px;\n  background-color:#EA004C;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
