import React from 'react';
import BaseTemplate from '../../template/baseTemplate';
import successIcon from '../../assets/images/successIcon.png';
import { BackButtom } from '../SignUp/styles';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export default function ConfirmAccount() {
  const { t } = useTranslation();
  return (
    <BaseTemplate text={t('message.welcome.title')}>
      <div className="form-confirm">
        <div className="content">
          <div className="row">
            <BackButtom>
              <h5 className="text-center">Confirmar cadastro</h5>
            </BackButtom>
          </div>
          <hr className="redLine" />
          <div className="container-image">
            <img src={successIcon} alt="" />
            <span>Sucesso!</span>
          </div>
          <div>
            <span>Parabéns</span>
            <p>Sua conta foi validada com sucesso</p>
          </div>
        </div>
      </div>
    </BaseTemplate>
  );
}
