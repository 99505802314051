import Loading from 'core/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import api from 'services/api';
import closeModalIcon from '../../../../assets/images/closeIcon.svg';
import iconFilter from '../../../../assets/images/filterTower.svg';
import CheckBox from './CheckBox';
import InputCustom from './InputCustom';

import { Container } from './styles';

export default function Filters({ setData, romaneioId, onClose }) {
  const [deliveryOrder, setDeliveryOrder] = React.useState('');
  const [deliveryId, setDeliveryId] = useState('');
  const [docNumber, setDocNumber] = useState('');
  const [clientName, setClientName] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [knowledge, setKnowledge] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    checkedColeta: false,
    checkedDelivery: false,
    checkedDone: false,
    checkedLate: false,
    checkedOnTime: false,
    checkedCancelled: false,
  });

  const filtersData = [
    {
      field: 'romaneio.id',
      value: romaneioId,
      operation: 'EQUAL',
    },
  ];
  const [arrFilters, setArrFilters] = useState(filtersData);

  const getCargoData = useCallback(async dataSend => {
    try {
      setLoading(true);
      const dataCargo = {
        filters: dataSend || filtersData,
        orders: [],
        size: 1000,
        page: 0,
      };
      const response = await api.post(
        '/delivery/conhecimentos/pageable',
        dataCargo
      );

      const orderLate = response.data.content
        .filter(op => op.statusEntrega === 'ATRASADO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderOnTime = response.data.content
        .filter(op => op.statusEntrega === 'NO_PRAZO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderCancelled = response.data.content
        .filter(op => op.statusEntrega === 'CANCELADO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderDone = response.data.content
        .filter(op => op.statusEntrega === 'ENTREGUE')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderLoads = orderLate.concat(
        orderOnTime,
        orderCancelled,
        orderDone
      );

      setKnowledge(orderLoads);

      setData(orderLoads);
      setLoading(false);
    } catch (err) {}
  }, []);

  function clearNav() {
    onClose();
  }

  const { t } = useTranslation();

  useEffect(() => {
    getCargoData();
  }, [getCargoData]);
  return (
    <>
      <Loading loading={loading} />
      <Container>
        <div
          className="iconClose"
          style={{
            position: 'absolute',
            top: -17,
            right: -16,
            padding: 20,
          }}
        >
          <button
            style={{ background: 'transparent', border: 'none' }}
            type="button"
            onClick={() => clearNav()}
          >
            <img src={closeModalIcon} alt="" />
          </button>
        </div>
        <div
          style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
        >
          <InputCustom
            type="number"
            placeholder={t('listAttendances.filters.searchOrder')}
            size={80}
            onChange={e => {
              setDeliveryOrder(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'ordemEntrega'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) => (item.field === 'ordemEntrega' ? i : -1))
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'ordemEntrega',
                  value: e.target.value,
                  operation: 'EQUAL',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'ordemEntrega'
                );
                dataFilters = filterArr;

                setArrFilters(dataFilters);
              }
            }}
            value={deliveryOrder}
          />
          <InputCustom
            placeholder={t('listAttendances.filters.searchId')}
            size={114}
            type="number"
            onChange={e => {
              setDeliveryId(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'id'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) => (item.field === 'id' ? i : -1))
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'id',
                  value: e.target.value,
                  operation: 'EQUAL',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'id'
                );
                dataFilters = filterArr;
                setArrFilters(dataFilters);
              }
            }}
            value={deliveryId}
          />
          <InputCustom
            placeholder={t('listAttendances.filters.searchDoc')}
            size={159}
            type="number"
            onChange={e => {
              setDocNumber(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'numero'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) => (item.field === 'numero' ? i : -1))
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'numero',
                  value: e.target.value,
                  operation: 'EQUAL',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'numero'
                );
                dataFilters = filterArr;
                setArrFilters(dataFilters);
              }
            }}
            value={docNumber}
          />
          <InputCustom
            placeholder={t('listAttendances.filters.searchClientName')}
            size={306}
            onChange={e => {
              setClientName(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'cliente.nome'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) => (item.field === 'cliente.nome' ? i : -1))
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'cliente.nome',
                  value: e.target.value,
                  operation: 'MATCH',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'cliente.nome'
                );
                dataFilters = filterArr;
                setArrFilters(dataFilters);
              }
            }}
            value={clientName}
          />
          <CheckBox
            label={t('listAttendances.filters.collect')}
            onChecked={() => {
              setChecked({ ...checked, checkedColeta: !checked.checkedColeta });

              if (checked.checkedColeta) {
                setData(knowledge);
              } else {
                const dataFilters = knowledge;
                const filterData = dataFilters.filter(
                  item =>
                    item.tipo === 'COLETA' || item.tipo === 'COLETAENTREGA'
                );

                setData(filterData);
              }
            }}
            active={checked.checkedColeta}
          />
          <CheckBox
            label={t('listAttendances.filters.delivery')}
            active={checked.checkedDelivery}
            onChecked={() => {
              setChecked({
                ...checked,
                checkedDelivery: !checked.checkedDelivery,
              });
              if (checked.checkedDelivery) {
                setData(knowledge);
              } else {
                const dataFilters = knowledge;
                const filterData = dataFilters.filter(
                  item => item.tipo === 'ENTREGA'
                );

                setData(filterData);
              }
            }}
          />

          <button
            className="buttonFilterTower"
            type="button"
            onClick={() => getCargoData(arrFilters)}
          >
            <img src={iconFilter} alt="" style={{ marginRight: 6 }} />
            <Trans i18nKey="controlTower.buttonFilterActions" />
          </button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <InputCustom
            placeholder={t('listAttendances.filters.searchAddressAttendance')}
            size={306}
            onChange={e => {
              setDeliveryAddress(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'endereco.logradouro'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) =>
                    item.field === 'endereco.logradouro' ? i : -1
                  )
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'endereco.logradouro',
                  value: e.target.value,
                  operation: 'MATCH',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'endereco.logradouro'
                );
                dataFilters = filterArr;
                setArrFilters(dataFilters);
              }
            }}
            value={deliveryAddress}
          />
          <InputCustom
            placeholder={t('listAttendances.filters.searchClientPhone')}
            size={174}
            onChange={e => {
              setClientPhone(e.target.value);
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'cliente.telefone'
              );

              if (duplicatedFilters && e.target.value !== '') {
                const nanoIndexes = arrFilters
                  .map((item, i) =>
                    item.field === 'cliente.telefone' ? i : -1
                  )
                  .filter(index => index !== -1);
                dataFilters[nanoIndexes[0]].value = e.target.value;

                setArrFilters(dataFilters);
              } else if (e.target.value !== '') {
                dataFilters.push({
                  field: 'cliente.telefone',
                  value: e.target.value,
                  operation: 'MATCH',
                });
                setArrFilters(dataFilters);
              } else {
                const filterArr = arrFilters.filter(
                  item => item.field !== 'cliente.telefone'
                );
                dataFilters = filterArr;
                setArrFilters(dataFilters);
              }
            }}
            value={clientPhone}
          />
          <CheckBox
            label={t('listAttendances.filters.done')}
            active={checked.checkedDone}
            onChecked={() => {
              setChecked({
                ...checked,
                checkedDone: !checked.checkedDone,
              });
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'statusEntrega' && item.value === 'ENTREGUE'
              );

              if (duplicatedFilters && checked.checkedDone) {
                const nanoIndexes = arrFilters
                  .filter((item) => item.field !== 'statusEntrega' && item.value !== 'ENTREGUE');

                setArrFilters(nanoIndexes);
              } else {
                dataFilters.push({
                  field: 'statusEntrega',
                  value: 'ENTREGUE',
                  operation: 'EQUAL',
                });
                setArrFilters(dataFilters);
              }
            }}
          />
          <CheckBox
            label={t('listAttendances.filters.onTime')}
            active={checked.checkedOnTime}
            onChecked={() => {
              setChecked({
                ...checked,
                checkedOnTime: !checked.checkedOnTime,
              });
              let dataFilters = arrFilters;

              const duplicatedFilters = arrFilters.find(
                item => item.field === 'statusEntrega' && item.value === 'NO_PRAZO'
              );

              if (duplicatedFilters && checked.checkedOnTime) {
                const nanoIndexes = arrFilters
                  .filter((item) => item.field !== 'statusEntrega' && item.value !== 'NO_PRAZO');

                setArrFilters(nanoIndexes);
              } else {
                dataFilters.push({
                  field: 'statusEntrega',
                  value: 'NO_PRAZO',
                  operation: 'EQUAL',
                });
                setArrFilters(dataFilters);
              }

              // if (checked.checkedOnTime) {
              //   const dataFilters = arrFilters;

              //   getCargoData(dataFilters);
              // } else {
              //   const dataFilters = knowledge;

              //   const filterChecked = dataFilters.filter(
              //     item => item.statusEntrega === 'NO_PRAZO'
              //   );

              //   // setData(filterChecked);
              // }
            }}
          />
          <CheckBox
            label={t('listAttendances.filters.late')}
            active={checked.checkedLate}
            onChecked={() => {
              setChecked({
                ...checked,
                checkedLate: !checked.checkedLate,
              });
              if (checked.checkedLate) {
                const dataFilters = arrFilters;

                getCargoData(dataFilters);
              } else {
                const dataFilters = knowledge;

                const filterChecked = dataFilters.filter(
                  item => item.statusEntrega === 'ATRASADO'
                );

                setData(filterChecked);
              }
            }}
          />
          <CheckBox
            label={t('listAttendances.filters.cancelled')}
            active={checked.checkedCancelled}
            onChecked={() => {
              setChecked({
                ...checked,
                checkedCancelled: !checked.checkedCancelled,
              });
              if (checked.checkedCancelled) {
                const dataFilters = arrFilters;

                getCargoData(dataFilters);
              } else {
                const dataFilters = knowledge;

                const filterChecked = dataFilters.filter(
                  item => item.statusEntrega === 'CANCELADO'
                );

                setData(filterChecked);
              }
            }}
          />
          <span
            className="cleanFilters"
            onClick={() => {
              setDeliveryOrder('');
              setDeliveryId('');
              setDocNumber('');
              setClientName('');
              setDeliveryAddress('');
              setClientPhone('');
              setChecked({
                checkedOnTime: false,
                checkedCancelled: false,
                checkedColeta: false,
                checkedDelivery: false,
                checkedDone: false,
                checkedLate: false,
              });
              // setArrFilters(filtersData);
              // setData(filtersData);
            }}
          >
            Limpar filtros
          </span>
        </div>
      </Container>
    </>
  );
}
