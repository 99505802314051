import i18next from 'i18next';

export const formatLanguage = value => {
  let languageValue;

  const getLanguage = () => {
    return i18next.language;
  };

  if (
    (value === true && getLanguage() === 'pt') ||
    (value === true && getLanguage() === 'pt-BR')
  ) {
    languageValue = 'SIM';
  } else if (
    (value === true && getLanguage() === 'en') ||
    (value === true && getLanguage() === 'en-US')
  ) {
    languageValue = 'YES';
  } else if (
    (!value && getLanguage() === 'pt') ||
    (!value && getLanguage() === 'pt-BR')
  ) {
    languageValue = 'NÃO';
  } else if (
    (!value && getLanguage() === 'en') ||
    (!value && getLanguage() === 'en-US')
  ) {
    languageValue = 'NO';
  }

  return languageValue;
};
