import styled from 'styled-components';

interface StylesProps {
  theme: string
}

export const ButtonCustom = styled.button`
  background-color: ${({theme}: StylesProps) => theme === 'cancelButton' ? "#ccc" : "#ea004c"};
  box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
  width: 100%;
  border: none;
  height: 52px;
`;