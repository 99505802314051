import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  referenceId: string()
    .required('* Informe o ID Referência')
    .min(1, '* Mínimo de 1 caratere'),
  cnpj: string()
    .required('* Informe o CNJP!')
    .min(6, '* Minimo de 6 caracteres!')
    .max(40, '* Maximo de 40 caracteres!'),
  companyName: string()
    .required('* Informe a razão social')
    .min(6, '* Minimo de 6 caracteres!')
    .max(40, '* Maximo de 40 caracteres!'),
  trademarkName: string()
    .required('* Informe o nome fantasia')
    .min(6, '* Minimo de 6 caracteres!')
    .max(40, '* Maximo de 40 caracteres!'),
});
