import CustomModal from 'components/ModalCustom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import EditShippingTable from './components/EditShippingTable';
import ListShippingTable from './components/ListShippingTable';
import IShippingTable from './components/ListShippingTable/interfaces/IShippingTable';
import NewShippingTable from './components/NewShippingTable';

interface ShippingTaableProps {
  isClose: () => void;
}

const ShippingTable: React.FC<ShippingTaableProps> = ({
  isClose,
}: ShippingTaableProps) => {
  const [tabs, setTabs] = useState<number>(0);
  const [isEditRow, setIsEditRow] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number>(0);
  const [dataShippingTable, setDataShippingTable] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const [removeId, setRemoveId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IShippingTable[]>([]);

  const { t } = useTranslation();

  const userData = useSelector((state: any) => state.auth.user);

  const onDeleteRow = useCallback(
    async (rowId: number | null) => {
      try {
        const { language } = i18next;

        const erroLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
        const response = await api.delete(
          `/delivery/tabelas/frete/${rowId}?lang=${erroLanguage}`
        );
        if (response.status === 204) {
          FrontendNotification(
            t('shippingTable.messageRemove'),
            NotificationType.SUCCESS
          );
        }
        setRemoveConfirmation(false);
        setTabs(1);
        setTabs(0);
        // getShippingTable();
      } catch {}
    },
    [currentPage]
  );

  const setPublishedShippingTable = useCallback(async idShippingTable => {
    try {
      const response = await api.patch(
        `/delivery/tabelas/frete/publica/${idShippingTable}`
      );

      if (response.status === 201) {
        FrontendNotification(
          t('shippingTable.messagePublished'),
          NotificationType.SUCCESS
        );
      }

      setTabs(1);
      setTabs(0);

      // getShippingTable();
    } catch {}
  }, []);

  const toogleRemove = () => {
    setRemoveConfirmation(!removeConfirmation);
    setRemoveId(null);
  };

  const getOperadorLogistico = useCallback(async () => {
    const dataFilters = {
      filters: [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/delivery/operadoresLogisticos/findByFilters',
      dataFilters
    );

    const content = response.data.map((operator: any) => {
      return {
        id: operator.id,
        label: operator.razaoSocial,
      };
    });

    return content;
  }, []);

  const getVeiculo = useCallback(async () => {
    const dataFilters = {
      filters: [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/delivery/veiculos/findByFilters',
      dataFilters
    );

    const content = response.data.map((vehicle: any) => {
      return {
        id: vehicle.id,
        label: vehicle.placa,
      };
    });

    return content;
  }, []);

  const getRatingDataContent = useCallback(async () => {
    try {
      const getSubsidiary = userData.filiais.map((filial: any) => {
        return {
          id: filial.id,
          label: filial.nome,
        };
      });
      const getLogisticOperator = await getOperadorLogistico();
      const getVehicle = await getVeiculo();

      console.log('Subsidiary', getSubsidiary);
      localStorage.setItem(
        'ratingData',
        JSON.stringify({
          branchs: getSubsidiary,
          logisticOperators: getLogisticOperator,
          vehicle: getVehicle,
        })
      );

      // setRatingData({
      //   filiais: getSubsidiary,
      //   operadorLogistico: getLogisticOperator,
      //   veiculo: getVehicle,
      // });
    } catch {}
  }, []);

  useEffect(() => {
    getRatingDataContent();
  }, [getRatingDataContent]);

  return (
    <CustomModal
      isOpen
      label="Tabela de frete"
      styles={{ minWidth: '95%', height: '800px' }}
      isClose={isClose}
    >
      <Loading loading={loading} />
      <Modal isOpen={removeConfirmation} toggle={toogleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => onDeleteRow(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toogleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <div style={{ maxWidth: '100%' }}>
        <Tabs
          selectedIndex={tabs}
          onSelect={(index: number) => {
            setIsEditRow(false);
            setIsCopy(false);
            setIsView(false);
            setUpdateId(0);
            setTabs(index);
          }}
        >
          <TabList>
            <Tab>
              <Trans i18nKey="tabs.list" />
            </Tab>
            <Tab>
              <Trans i18nKey="tabs.new" />
            </Tab>
            <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
          </TabList>
          <TabPanel>
            <ListShippingTable
              data={dataShippingTable}
              totalPages={totalPages}
              currentPage={currentPage}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              onCreated={() => {
                setIsEditRow(false);
                setIsCopy(false);
                setIsView(false);
                setUpdateId(0);
                setTabs(1);
              }}
              onRemove={(rowId: number) => {
                setRemoveId(rowId);
                setRemoveConfirmation(!removeConfirmation);
              }}
              onUpdate={(rowId: number) => {
                setTabs(2);
                setIsEditRow(true);
                setUpdateId(rowId);
              }}
              onCopy={(editId: number) => {
                setTabs(1);
                console.log('Copy', dataShippingTable[editId]);
                setIsCopy(!isCopy);
                setIsEditRow(true);
                setUpdateId(editId);
              }}
              onView={(editId: number) => {
                setTabs(1);
                setIsView(!isView);
                setIsEditRow(true);
                setUpdateId(editId);
              }}
              onPublish={(rowId: number) => setPublishedShippingTable(rowId)}
              onChangePage={(page: number) => setCurrentPage(page - 1)}
            />
          </TabPanel>

          <TabPanel>
            <NewShippingTable
              isEdit={isEditRow}
              editId={updateId}
              isCopy={isCopy}
              isView={isView}
              data={selectedRow}
              onHandleSubmit={() => {
                setTabs(0);
                // getShippingTable();
              }}
            />
          </TabPanel>
          <TabPanel>
            <EditShippingTable
              isEdit={isEditRow}
              editId={updateId}
              data={selectedRow}
              onHandleSubmit={() => {
                setTabs(0);
                // getShippingTable();
              }}
            />
          </TabPanel>
        </Tabs>
      </div>
    </CustomModal>
  );
};

export default ShippingTable;
