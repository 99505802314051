/* eslint-disable no-nested-ternary */
import closeModalIcon from 'assets/images/closeIcon.svg';
import DetailsDelivery from 'components/DetailsDelivery';
import CustomTooltip from 'components/Input/CustomTooltip';
import ModalSignature from 'components/Modal/ModalSignature';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';

import {
  ButtonClose,
  ImageClose,
  WrapperClose,
} from 'components/ModalCustom/styles';
// import Tooltip from 'components/Tooltip';
import { pt, ptBR } from 'date-fns/locale';
import GetRoutes from 'pages/ControlTower/GetRoutes';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import api from 'services/api';
import permissionMenu from 'shared/Permission';
import IconLocalization from '../../assets/images/assinatura.svg';
import IconDeliveryCancel from '../../assets/images/cancelIcon.svg';
import IconDeliveryNoPrazo from '../../assets/images/deliveryPrazo.svg';
import IconRegistraAtendimentos from '../../assets/images/iconRegistraAtendimentos.svg';
import IconArrowDown from '../../assets/images/knowledge/arrowDown.svg';
import IconArrowUp from '../../assets/images/knowledge/arrowUp.svg';
import IconDeliveryDone from '../../assets/images/knowledge/deliveryDone.svg';
import IconDeliveryOverdue from '../../assets/images/knowledge/deliveryOverdue.svg';
import IconDocument from '../../assets/images/knowledge/document.svg';
import IconPhoto from '../../assets/images/knowledge/photo.svg';
import maps from '../../assets/images/mapIcon.svg';
import skipServiceIcon from '../../assets/images/skipServiceIcon.svg';
import ModalOccurrence from './ModalOccurrence';
import ModalReceiver from './ModalSignature';
import {
  Card,
  Contractor,
  ContractorLabel,
  NumberDelivery,
  NumberDeliveryLabel,
  NumberOrder,
  Requirements,
  ServiceColumn,
  ServiceRow,
  Status,
  StatusLabel,
} from './styles';

export default function CardKnowledge({
  data,
  indexKnowledge,
  dataArr,
  moreDetailsList,
  onReloadKnowledge,
}) {
  const [positionMap, setPositionMap] = React.useState({
    romaneioId: '',
    lat: '',
    lng: '',
  });
  const [getRoutes, setGetRoutes] = useState(false);
  const [linkTracking, setLinkTracking] = useState();
  const [imageUrl, setImageUrl] = useState('');
  const [typePhoto, setTypePhoto] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const [errorPhoto, setErrorPhoto] = useState(false);
  const [dataReceiver, setDataReceiver] = useState();
  const [typeKnowledge, setTypeKnowledge] = useState();
  const [activeReceiver, setActiveReceiver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeOcorrences, setTypeOcorrences] = useState('');

  const [selectedOcorrences, setSelectedOcorrences] = useState();
  const [knowledgeId, setKnowledgeId] = useState();
  const [cargoId, setCargoId] = useState();
  const [confirmFinishKnowledge, setConfirmFinishKnowledge] = useState(false);

  const [knowledgeNumber, setNumberKnowledge] = useState();
  const [modalOcorrence, setModalOcorrence] = useState(false);

  const userData = useSelector(state => state.auth.user);

  const [delivery, setDelivery] = useState(false);
  const [deliveryId, setDeliveryId] = useState();

  const [tooltipBaixaKnowledge, setTooltipBaixaKnowledge] = useState(false);

  const [permission, setPermission] = useState([]);

  const [driverId, setDriverId] = useState();

  const [moreDetails, setMoreDetails] = useState({
    id: 0,
    collapse: true,
    collapse2: true,
  });
  const [parameters, setParameters] = useState([]);

  function toggleCollapse(id, collapse) {
    setMoreDetails({
      id,
      collapse,
    });
    moreDetailsList(id);
  }

  const requestModal = useCallback(async knowledgeId => {
    const response = await api.post(
      '/delivery/conhecimentosMidias/findByFilters',
      {
        filters: [
          {
            field: 'conhecimento.id',
            value: knowledgeId,
            operation: 'EQUAL',
          },
        ],
      }
    );
    const getSignatures = response.data.filter(
      item => item.tipo === 'ASSINATURA'
    );

    if (getSignatures.length > 0 && getSignatures[0].url.length > 0) {
      setImageUrl(getSignatures[0].url);
      setTypePhoto('Assinatura');
      setErrorPhoto(false);
      setModalActive(!modalActive);
    } else {
      setErrorPhoto(true);
      setModalActive(!modalActive);

      setTypePhoto('Assinatura');
    }
  }, []);

  const requestModalPhoto = useCallback(async knowledgeId => {
    const response = await api.post(
      '/delivery/conhecimentosMidias/findByFilters',
      {
        filters: [
          {
            field: 'conhecimento.id',
            value: knowledgeId,
            operation: 'EQUAL',
          },
        ],
      }
    );

    const getPhoto = response.data.filter(item => item.tipo === 'FOTO');

    if (getPhoto.length > 0 && getPhoto[0].url.length > 0) {
      const photo = response.data.find(item => item.tipo === 'FOTO');

      setImageUrl(getPhoto[0].url);
      setErrorPhoto(false);
      setTypePhoto('Foto');
      setModalActive(!modalActive);
    } else {
      setTypePhoto('Foto');
      setErrorPhoto(true);
    }
  }, []);

  let isLow = false;
  let isMedium = false;
  let isGood = false;
  let isNeutral = false;

  const c = data;
  const knowledge = dataArr;
  const index = indexKnowledge;

  // Define a classe a partir do percentual
  // da bateria do motorista.
  if (c.statusEntrega === 'ATRASADO') {
    isMedium = true;
  } else if (c.statusEntrega === 'ENTREGUE') {
    isGood = true;
  } else if (c.statusEntrega === 'NO_PRAZO') {
    // default é azul/roxo
    isNeutral = true;
  } else {
    // default é azul/roxo
    isLow = true;
  }

  const endereco = `${c.endereco.logradouro}, ${c.endereco.numero}
            ${c.endereco.bairro}, ${c.endereco.cidade}, ${c.endereco.uf}`;

  const lowColor = '#d62828';
  const mediumColor = '#f77f00';
  const goodColor = '#08b639';
  const neutralColor = '#6369d1';

  const { t } = useTranslation();

  const getParameters = useCallback(async () => {
    try {
      const data = {
        filters: [],
      };
      const response = await api.post(
        '/cadastro/parametrosWeb/findByFilters',
        data
      );

      setParameters(response.data);
    } catch (err) {}
  }, []);

  const onFinishKnowledge = useCallback(
    async (selectedOcorrence, baixaLote) => {
      try {
        setLoading(true);
        const date = format(new Date(), 'yyyy-MM-dd', {
          locale: ptBR,
        });
        const hour = format(new Date(), 'HH:mm:ss', {
          locale: ptBR,
        });
        const data = {
          dataHora: `${date}T${hour}`,
          numeroOc: selectedOcorrence.numero,
          baixaLote: baixaLote,
        };

        const response = await api.patch(
          `/delivery/conhecimentos/registraAtendimentoManual/${
            baixaLote && baixaLote === true
              ? selectedOcorrence.cargoId
              : selectedOcorrence.id
          }`,
          data
        );

        setLoading(false);

        FrontendNotification(
          t('listAttendances.finishKnowledgeSuccess'),
          NotificationType.SUCCESS
        );
        onReloadKnowledge();
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getParameters();
    getUser();
  }, [getParameters, getUser]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      <Modal
        isOpen={confirmFinishKnowledge}
        toggle={() => setConfirmFinishKnowledge(!confirmFinishKnowledge)}
      >
        <ModalHeader>
          <WrapperClose style={{ top: '-10px' }}>
            <ButtonClose
              onClick={() => setConfirmFinishKnowledge(!confirmFinishKnowledge)}
            >
              <ImageClose src={closeModalIcon} />
            </ButtonClose>
          </WrapperClose>
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="listAttendances.messageConfirmFinishKnowledge" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setModalOcorrence(!modalOcorrence);
              onFinishKnowledge(selectedOcorrences, true);
              setConfirmFinishKnowledge(!confirmFinishKnowledge);
            }}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.yes" />
          </Button>{' '}
          <Button
            color="danger"
            onClick={() => {
              setModalOcorrence(!modalOcorrence);
              onFinishKnowledge(selectedOcorrences, false);
              setConfirmFinishKnowledge(!confirmFinishKnowledge);
            }}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.no" />
          </Button>
        </ModalFooter>
      </Modal>
      {modalOcorrence && (
        <ModalOccurrence
          onClear={() => setModalOcorrence(!modalOcorrence)}
          typeOcorrence={typeOcorrences}
          onConfirm={selectedOcorrence => {
            setSelectedOcorrences({
              ...selectedOcorrence,
              id: knowledgeId,
              cargoId: cargoId,
            });
            setConfirmFinishKnowledge(true);
          }}
        />
      )}
      <ModalSignature
        modalToogle={modalActive}
        imageLink={imageUrl}
        title={typePhoto}
        erro={errorPhoto}
        type={typePhoto}
        setModal={() => setModalActive(!modalActive)}
      />

      <ModalReceiver
        modalToogle={activeReceiver}
        title={t('knowledge.receiver')}
        setModal={() => setActiveReceiver(!activeReceiver)}
        data={dataReceiver}
      />
      {getRoutes && (
        <GetRoutes
          onClear={() => setGetRoutes(!getRoutes)}
          idRomaneio={positionMap.romaneioId}
          motoristaId={driverId}
          subsidiaryPosition={{
            lat: positionMap.lat,
            lng: positionMap.lng,
          }}
          // longitude={positionMap.longitude}
        />
      )}

      {delivery && (
        <DetailsDelivery
          knowledgeType={typeKnowledge}
          knowledgeId={deliveryId}
          knowledgeNumber={knowledgeNumber}
          onClear={() => setDelivery(!delivery)}
        />
      )}
      <Card
        key={index}
        className="box"
        colorActive={
          isLow
            ? lowColor
            : isMedium
            ? mediumColor
            : isGood
            ? goodColor
            : neutralColor
        }
      >
        <ServiceRow>
          <NumberOrder size={c.ordemEntrega <= 99 ? 'normal' : 'max'}>
            <span>{c.ordemEntrega}</span>
          </NumberOrder>
          <ServiceColumn size={30}>
            <NumberDeliveryLabel>
              {c.tipo === 'ENTREGA' ? (
                <Trans i18nKey="knowledge.deliveryNumber" />
              ) : (
                <Trans i18nKey="knowledge.collectionNumber" />
              )}
            </NumberDeliveryLabel>
            <NumberDelivery>
              {c.numero}
              {c.dataBaixaApp === null && (
                <CustomTooltip
                  message={t('listAttendances.messageTooltipFinishKnowledge')}
                  icon={IconRegistraAtendimentos}
                  onClick={() => {
                    if (
                      permissionMenu(permission, 'controlTower.baixa-manual')
                    ) {
                      setTypeOcorrences(c.tipo);
                      setModalOcorrence(true);
                      setKnowledgeId(c.id);
                      setCargoId(c.romaneio.id);
                    } else {
                      FrontendNotification(
                        t('common.permission'),
                        NotificationType.ERROR
                      );
                    }
                  }}
                />
              )}
              {/* )} */}
              {/* {!tooltipBaixaKnowledge && (
                <img
                  src={IconRegistraAtendimentos}
                  width={18}
                  height={20}
                  onClick={() =>
                    setTooltipBaixaKnowledge(!tooltipBaixaKnowledge)
                  }
                />
              )} */}
            </NumberDelivery>
          </ServiceColumn>
          <ServiceColumn size={40}>
            <StatusLabel>
              <Trans i18nKey="knowledge.status" />
            </StatusLabel>
            <Status>
              {c.statusEntrega === 'ATRASADO' ? (
                <img
                  src={IconDeliveryOverdue}
                  alt=""
                  className="icon-custom fa imgMargin"
                />
              ) : c.statusEntrega === 'NO_PRAZO' ? (
                <img
                  src={IconDeliveryNoPrazo}
                  alt=""
                  className="icon-custom fa imgMargin"
                />
              ) : c.statusEntrega === 'CANCELADO' ? (
                <img
                  src={IconDeliveryCancel}
                  alt=""
                  className="icon-custom fa imgMargin"
                />
              ) : (
                <img
                  src={IconDeliveryDone}
                  alt=""
                  className="icon-custom fa imgMargin"
                />
              )}
              {c.ocorrencia !== null ? (
                <>
                  {(c.ocorrencia.bipagem === false &&
                    c.statusEntrega === 'CANCELADO') ||
                  (c.ocorrencia.bipagem === false &&
                    c.ocorrencia.insucesso === 'SIM') ? (
                    <span className="labelStatus" color="#d62828">
                      {`${c.ocorrencia.numero} -
                        ${c.ocorrencia.descricao.toLowerCase()}`}
                    </span>
                  ) : c.statusEntrega === 'NO_PRAZO' &&
                    c.ocorrencia.bipagem === false ? (
                    <span className="labelStatus" color="#6369d1">
                      {`${c.ocorrencia.numero} -
                        ${c.ocorrencia.descricao.toLowerCase()}`}
                    </span>
                  ) : c.statusEntrega === 'ATRASADO' &&
                    c.ocorrencia.bipagem === false ? (
                    <span className="labelStatus" color="#F77F00">
                      {`${c.ocorrencia.numero} -
                        ${c.ocorrencia.descricao.toLowerCase()}`}
                    </span>
                  ) : (
                    <span className="labelStatus" color="#08B639">
                      {`${c.ocorrencia.numero} -
                        ${c.ocorrencia.descricao.toLowerCase()}`}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {c.statusEntrega === 'NO_PRAZO' ? (
                    <span className="labelStatus" color="#6369d1">
                      <Trans i18nKey="knowledge.onTime" />
                    </span>
                  ) : c.statusEntrega === 'CANCELADO' ? (
                    <span className="labelStatus" color="#d62828">
                      {c.statusEntrega}
                    </span>
                  ) : c.statusEntrega === 'ATRASADO' ? (
                    <span className="labelStatus" color="#F77F00">
                      <Trans i18nKey="knowledge.delayed" />
                    </span>
                  ) : (
                    <span className="labelStatus" color="#6369d1">
                      {c.statusEntrega}
                    </span>
                  )}
                </>
              )}
            </Status>
          </ServiceColumn>
          <ServiceColumn size={40}>
            <NumberDeliveryLabel>
              <Trans i18nKey="knowledge.eta" />
            </NumberDeliveryLabel>
            <NumberDelivery>
              {c.eta
                ? `${format(new Date(c.eta), 'dd-MM-yyyy HH:mm', pt)}`
                : '---'}{' '}
            </NumberDelivery>
          </ServiceColumn>
        </ServiceRow>
        <ServiceRow>
          <ServiceColumn>
            <ContractorLabel>Contratante</ContractorLabel>

            <Contractor>
              {c.contratante !== null
                ? c.contratante.pessoaJuridica.nomeFantasia
                : '---'}
            </Contractor>
          </ServiceColumn>
          <ServiceColumn>
            <ContractorLabel>Rota</ContractorLabel>

            <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-text-nowrap tw-max-w-[14ch] tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer">
              {c.rota !== null && c.rota.length > 0 ? c.rota : '---'}
            </span>
          </ServiceColumn>
        </ServiceRow>
        <ServiceRow>
          <ServiceColumn size={70}>
            <ContractorLabel>
              <Trans i18nKey="knowledge.client" />
            </ContractorLabel>

            <Contractor className="uriLimit">{c.cliente.nome}</Contractor>
          </ServiceColumn>
          <ServiceColumn size={30}>
            <ContractorLabel>Pulou Atend.</ContractorLabel>

            <Contractor>
              {c.pulaAtendimento ? (
                <img src={skipServiceIcon} className="skipImage" alt="" />
              ) : (
                '---'
              )}
            </Contractor>
          </ServiceColumn>
        </ServiceRow>
        <ServiceRow>
          <ServiceColumn size={60}>
            <ContractorLabel>Etapa Atual</ContractorLabel>

            <Contractor>
              {c.etapa !== null ? c.etapa.descricao : '---'}
            </Contractor>

            <span
              className="linkButton"
              onClick={() => {
                setDelivery(!delivery);
                setDeliveryId(c.id);
                setNumberKnowledge(c.numero);
                setTypeKnowledge(c.tipo);
              }}
            >
              <Trans i18nKey="knowledge.viewSteps" />
            </span>
          </ServiceColumn>
          <ServiceColumn size={40}>
            <ContractorLabel>Tipo atividade</ContractorLabel>

            <Contractor>
              {c.tipoAtividade !== null ? c.tipoAtividade.nome : '---'}
            </Contractor>
          </ServiceColumn>
        </ServiceRow>
        <hr />
        <ServiceRow>
          <ContractorLabel>
            <Trans i18nKey="knowledge.address" />
          </ContractorLabel>
        </ServiceRow>
        <ServiceRow>
          <img
            src={maps}
            width="20"
            alt=""
            style={{ marginTop: 10, cursor: 'pointer' }}
            onClick={() => {
              if (
                knowledge[index].latitude !== null &&
                knowledge[index].longitude !== null
              ) {
                setPositionMap({
                  romaneioId: knowledge[index].romaneio.id,
                  lat: knowledge[index].latitude,
                  lng: knowledge[index].longitude,
                });
              } else {
                setPositionMap({
                  romaneioId: knowledge[index].romaneio.id,
                  lat: knowledge[index].endereco.latitude,
                  lng: knowledge[index].endereco.longitude,
                });
              }
              setDriverId(knowledge[index].romaneio.motorista.id);
              setGetRoutes(!getRoutes);
            }}
          />
          <div className="line" />
          <ServiceColumn>
            <Contractor>{endereco}</Contractor>
          </ServiceColumn>
        </ServiceRow>
        <hr />
        {c.id === moreDetails.id && moreDetails.collapse === false ? (
          ''
        ) : (
          <Row>
            <Col xs="12">
              <button
                type="button"
                className="more-details btn align-items"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleCollapse(c.id, false);
                }}
              >
                <span className="more-details" style={{ fontFamily: 'Lato' }}>
                  <Trans i18nKey="knowledge.moreDetails" />
                </span>
                <img
                  src={IconArrowDown}
                  alt=""
                  className="icon-custom fa left-margin"
                />
              </button>
            </Col>
          </Row>
        )}
        {c.id == moreDetails.id && moreDetails.collapse == false && (
          <>
            <ServiceRow>
              <ServiceColumn size={50}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.deliveryTime" />
                </ContractorLabel>

                <Contractor>
                  {c.dataBaixaApp !== null
                    ? format(new Date(c.dataBaixaApp), 'dd/MM/yyyy HH:mm', pt)
                    : '---'}
                </Contractor>
              </ServiceColumn>
              <ServiceColumn size={50}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.idReference" />
                </ContractorLabel>

                <Contractor>{c.idReferencia}</Contractor>
              </ServiceColumn>
            </ServiceRow>
            <ServiceRow>
              <ServiceColumn size={50}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.cityFrom" />
                </ContractorLabel>

                <Contractor>
                  {c.filialOrigem.endereco.uf} -{' '}
                  {c.filialOrigem.endereco.cidade}{' '}
                </Contractor>
              </ServiceColumn>
              <ServiceColumn size={50}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.cityTo" />
                </ContractorLabel>

                <Contractor>
                  {c.filialDestino.endereco.uf} -{' '}
                  {c.filialDestino.endereco.cidade}{' '}
                </Contractor>
              </ServiceColumn>
            </ServiceRow>
            <ServiceRow>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.typeTransport" />
                </ContractorLabel>

                <Contractor>
                  <Trans i18nKey="knowledge.nameTransport" />
                </Contractor>
              </ServiceColumn>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.weight" />
                </ContractorLabel>

                <Contractor>{c.peso}KG</Contractor>
              </ServiceColumn>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.volume" />
                </ContractorLabel>

                <Contractor>{c.qtdVolumes}</Contractor>
              </ServiceColumn>
            </ServiceRow>
            <ServiceRow>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.tracking" />
                </ContractorLabel>

                <Contractor>
                  {c.tracking === true ? (
                    <Trans i18nKey="common.yes" />
                  ) : (
                    <Trans i18nKey="common.no" />
                  )}
                </Contractor>
              </ServiceColumn>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.dangerGood" />
                </ContractorLabel>

                <Contractor>
                  {c.dangerGood === true ? (
                    <Trans i18nKey="common.yes" />
                  ) : (
                    <Trans i18nKey="common.no" />
                  )}
                </Contractor>
              </ServiceColumn>
              <ServiceColumn size={30}>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.value" />
                </ContractorLabel>

                <Contractor>R$ {parseFloat(c.valor).toFixed(2)}</Contractor>
              </ServiceColumn>
            </ServiceRow>
            <ServiceRow>
              <ServiceColumn size={80}>
                <ContractorLabel>Baixa Manual</ContractorLabel>
                <Contractor>
                  {c.ocorrencia !== null && c.ocorrencia.baixaManual
                    ? 'Sim'
                    : 'Não'}
                  &nbsp;
                  {c.ocorrencia !== null && c.ocorrencia.baixaManual && <>-</>}
                  &nbsp;
                  {c.ocorrencia !== null &&
                    c.ocorrencia.baixaManual &&
                    'Usuário:'}
                  &nbsp;
                  {c.ocorrencia !== null &&
                    c.ocorrencia.baixaManual &&
                    userData.nome}
                </Contractor>
              </ServiceColumn>
            </ServiceRow>
            <hr />

            <ServiceRow>
              <Requirements>Exigências</Requirements>
            </ServiceRow>

            <ServiceRow>
              <ServiceColumn size="25">
                <ContractorLabel
                  click
                  onClick={() => {
                    setActiveReceiver(!activeReceiver);
                    setDataReceiver(c);
                  }}
                >
                  <img
                    src={IconDocument}
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                    }}
                    alt=""
                    className="icon-custom fa"
                  />
                  <Trans i18nKey="knowledge.receiver" />
                </ContractorLabel>
                <Contractor
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </ServiceColumn>
              <ServiceColumn size="15">
                <ContractorLabel click onClick={() => requestModal(c.id)}>
                  <img
                    src={IconLocalization}
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                    }}
                    alt=""
                    className="icon-custom fa"
                  />
                  <Trans i18nKey="knowledge.requirements.localization" />
                </ContractorLabel>
                <Contractor
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </ServiceColumn>
              <ServiceColumn size="15">
                <ContractorLabel click onClick={() => requestModalPhoto(c.id)}>
                  <img
                    src={IconPhoto}
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                    }}
                    alt=""
                    className="icon-custom fa"
                  />
                  <Trans i18nKey="knowledge.requirements.photo" />
                </ContractorLabel>
                <Contractor
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </ServiceColumn>
            </ServiceRow>
            <hr />
            <ServiceRow>
              <ServiceColumn>
                <ContractorLabel>
                  <Trans i18nKey="knowledge.linkTracking" />
                </ContractorLabel>
                {c.etapa !== null ? (
                  <>
                    {c.urlTracklink !== null ? (
                      <Contractor>
                        <a
                          target="_blank"
                          style={{
                            cursor: 'pointer',
                            color: '#0000FF',
                            wordBreak: 'break-word',
                          }}
                          href={linkTracking}
                          onClick={() => {
                            setLinkTracking(`${c.urlTracklink}?lang=pt-BR`);
                          }}
                          rel="noreferrer"
                        >
                          {c.urlTracklink}
                        </a>
                      </Contractor>
                    ) : (
                      '---'
                    )}
                  </>
                ) : (
                  '---'
                )}
              </ServiceColumn>
            </ServiceRow>
            <hr />
            <button
              type="button"
              className="more-details btn align-items"
              style={{
                fontSize: 13,
                color: '#EA004C',
                fontFamily: 'Lato',
                fontWeight: 'bold',
              }}
              onClick={e => {
                e.preventDefault();
                toggleCollapse(c.id, true);
              }}
            >
              <Trans i18nKey="knowledge.lessDetail" />
              <img
                src={IconArrowUp}
                alt=""
                className="icon-custom fa left-margin"
              />
            </button>
          </>
        )}
      </Card>
    </>
  );
}
