import DeleteIcon from 'assets/images/deleteIcon.svg';
import CustomSelectVehicles from 'components/CustomSelectVehicles';
import InputCustom from 'components/Input';
import { inputForm } from 'components/Ocorrence/styles';
import SelectCustom from 'components/SelectCustom';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import api from 'services/api';
import collaboratorTeamIcon from '../../../assets/images/collaboratorTeamIcon.svg';
// Assets
import driverTeamIcon from '../../../assets/images/driverTeamIcon.svg';
import { inputCustom, labelStyle } from './styles';
import './styles.scss';

export default function EditVehicles({ loadVehicles, loadList }) {
  const [modal, setModal] = useState(true);

  const [filialData, setFilialData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [collaboratorData, setCollaboratorData] = useState([]);
  const [vehiclesId, setVehiclesId] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [helperText, setHelperText] = useState("");

  const [tabs, setTabs] = useState(0);

  const { t } = useTranslation();

  const getFiliais = useCallback(async () => {
    try {
      const dataFilial = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post(
        '/cadastro/filiais/findByFilters',
        dataFilial
      );

      setFilialData(response.data);
    } catch (err) {}
  }, []);

  const getDriver = useCallback(async (subsidiaryId, nameDriver, listTeam) => {
    try {
      const currentSubsidiary = sessionStorage.getItem('subsidiary');
      const dataDriver = {
        filters: [
          {
            field: 'filial.id',
            value: subsidiaryId,
            operation: 'EQUAL',
          },
          {
            field: 'nome',
            operation: 'MATCH',
            value: nameDriver,
          },
        ],
        page: 0,
        size: 20,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/pageable',
        dataDriver
      );

      const sortDriver = response.data.content.sort((a, b) => {
        const isReversed = 1;

        return isReversed * a.nome.localeCompare(b.nome);
      });

      const mappingDriverData = sortDriver.map(item => {
        return {
          label: item.nome,
          value: item.id,
        };
      });

      let compareDriver = mappingDriverData.filter(
        driver =>
          !listTeam.some(selectedTeam => selectedTeam.id === driver.value)
      );

      setDriverData(compareDriver);
    } catch (err) {}
  }, []);

  const getCollaborator = useCallback(
    async (subsidiaryId, nameCollaborator, listTeam) => {
      try {
        const dataCollaborator = {
          filters: [
            {
              field: 'filial.id',
              value: subsidiaryId,
              operation: 'EQUAL',
            },
            {
              field: 'nome',
              operation: 'MATCH',
              value: nameCollaborator,
            },
          ],
          page: 0,
          size: 20,
          orders: [],
        };

        const response = await api.post(
          '/delivery/colaboradores/pageable',
          dataCollaborator
        );

        const sortCollaborator = response.data.content.sort((a, b) => {
          const isReversed = 1;

          return isReversed * a.nome.localeCompare(b.nome);
        });

        const mappingCollaboratorData = sortCollaborator.map(item => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        let compareCollaborator = mappingCollaboratorData.filter(
          collaborator =>
            !listTeam.some(
              selectedTeam => selectedTeam.id === collaborator.value
            )
        );

        setCollaboratorData(compareCollaborator);
      } catch (err) {}
    },
    []
  );

  useEffect(() => {
    getFiliais();
  }, []);

  const handleSubmit = useCallback(async (values, reset, team) => {
    try {
      let dataSubmit;
      console.log('entrou', values)
      console.log(team);
      const isVehicles = values.veiculoProprio === 'Sim';

      const getDriverTeam = team.map(item => {
        return item.id;
      });

      const getCollaboratorTeam = team
        .filter(itemFilter => itemFilter.type === 'COLLABORATOR')
        .map(item => {
          return {
            id: item.id,
          };
        });

      dataSubmit = {
        tipo: values.tipo === "3/4" ? "TRES_QUARTOS" : values.tipo === "3/4_FRIO" ? "TRES_QUARTOS_FRIO" : values.tipo,
        descricao: values.descricao,
        idReferencia: values.idReferencia,
        motorista: {
          id: getDriverTeam[0],
        },
        filial: {
          id: values.filialId,
        },
        veiculoProprio: isVehicles,
        placa: values.placa,
        marcaModelo: values.marcaModelo,
        ano: values.ano,
        anoFabricacao: values.anoFabricacao,
        peso: values.peso,
        quantidadeAtendimento: values.quantidadeAtendimento,
        cubagem: values.cubagem,
        valor: values.valor,
        velocidadeMedia: values.velocidadeMedia,
        colaboradores: getCollaboratorTeam,
      };

      const response = await api.patch(
        `/delivery/veiculos/${loadVehicles}`,
        dataSubmit
      );

      if (response.status === 204) {
        FrontendNotification(
          t('vehicles.msgSuccessSave'),
          NotificationType.SUCCESS
        );

        reset({});
        loadList();
        setTeamList([]);
      }
    } catch (err) {}
  }, []);

  const formik = useFormik({
    initialValues: {
      tipo: '',
      descricao: '',
      idReferencia: '',
      filialId: '',
      veiculoProprio: '',
      placa: '',
      marcaModelo: '',
      ano: '',
      anoFabricacao: '',
      peso: '',
      quantidadeAtendimento: '',
      cubagem: '',
      valor: '',
      velocidadeMedia: '',
    },
    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, resetForm, teamList),
  });

  const customStyles = {
    control: styles => ({
      ...styles,
      border: '2px solid rgba(0, 48, 73, 0.2)',
      borderRadius: 10,
    }),
  };

  const onChangeDriver = useCallback((filialId, inputText, list) => {
    console.log(inputText);

    if (inputText.length >= 3) {
      getDriver(filialId, inputText, list);
    }
  }, []);

  const onChangeDriverTeam = useCallback((text, list) => {
    let updateTeam = list;

    const findDriverTeam = updateTeam
      .map((driverTeam, indexDriverTeam) =>
        driverTeam.type === 'DRIVER' ? indexDriverTeam : -1
      )
      .filter(driverTeamFilter => driverTeamFilter !== -1);

    if (findDriverTeam.length > 0) {
      updateTeam[findDriverTeam[0]].id = text.value;
      updateTeam[findDriverTeam[0]].nome = text.label;
    } else {
      updateTeam.unshift({
        type: 'DRIVER',
        id: text.value,
        nome: text.label,
      });
    }

    setTeamList(updateTeam);

    setDriverData([]);
  }, []);

  const onChangeCollaborator = useCallback((filialId, inputText, list) => {
    if (inputText.length >= 3) {
      getCollaborator(filialId, inputText, list);
    }
  }, []);

  const onChangeCollaboratorTeam = useCallback((text, list) => {
    let updateTeam = list;

    updateTeam.push({
      type: 'COLLABORATOR',
      id: text.value,
      nome: text.label,
    });

    setTeamList(updateTeam);

    setCollaboratorData([]);
  }, []);

  const getVehicleId = useCallback(async () => {
    try {
      const response = await api.get(`/delivery/veiculos/${loadVehicles}`);

      const isVehicles = response.data.veiculoProprio === true ? 'Sim' : 'Nao';

      let teamVehicles = teamList;

      const driverData = {
        id: response.data.motorista.id,
        nome: response.data.motorista.nome,
        type: 'DRIVER'
      };

      teamVehicles.push(driverData)

      const collaboratorDataVehicles = response.data.colaboradores.map(itemCollaborator => {
        return teamVehicles.push({
          id: itemCollaborator.id,
          nome: itemCollaborator.nome,
          type: 'COLLABORATOR'
        })
      });

      setTeamList(teamVehicles);

      formik.setFieldValue('tipo', response.data.tipo === "TRES_QUARTOS" ? "3/4" : response.data.tipo === "TRES_QUARTOS_FRIO" ? "3/4_FRIO" : response.data.tipo);
      formik.setFieldValue('descricao', response.data.descricao);
      formik.setFieldValue('idReferencia', response.data.idReferencia);
      formik.setFieldValue('filialId', String(response.data.filial.id));
      formik.setFieldValue('veiculoProprio', isVehicles);
      formik.setFieldValue('placa', response.data.placa);
      formik.setFieldValue('marcaModelo', response.data.marcaModelo);
      formik.setFieldValue('ano', response.data.ano);
      formik.setFieldValue('anoFabricacao', response.data.anoFabricacao);
      formik.setFieldValue('peso', response.data.peso);
      formik.setFieldValue(
        'quantidadeAtendimento',
        response.data.quantidadeAtendimento
      );
      formik.setFieldValue('cubagem', response.data.cubagem);
      formik.setFieldValue('valor', response.data.valor);
      formik.setFieldValue(
        'velocidadeMedia',
        response.data.velocidadeMedia !== null
          ? response.data.velocidadeMedia
          : ''
      );
    } catch (err) {}
  }, []);

  useEffect(() => {
    getVehicleId();
  }, [getVehicleId]);

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <Col md={3}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.type" />*
          </Label>
          {formik.values.tipo.length > 0 && (
            <CustomSelectVehicles
              fieldId="selectType"
              onChange={type => formik.setFieldValue('tipo', type)}
              value={formik.values.tipo}
            />
          )}
        </Col>
        <Col md={6}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.description" />*
          </Label>
          <Input
            type="text"
            placeholder={t('vehicles.placeholder.description')}
            style={{
              ...inputCustom,
            }}
            onChange={formik.handleChange('descricao')}
            value={formik.values.descricao}
          />
          {formik.touched.descricao && formik.errors.descricao && (
            <p style={{ fontSize: 14, color: 'red' }}>
              {formik.errors.descricao}
            </p>
          )}
        </Col>
        <Col md={3}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.idReference" />
          </Label>
          <InputCustom
            type="text"
            placeholder={t('vehicles.placeholder.idReference')}
            style={{
              ...inputCustom,
            }}
            onChange={formik.handleChange('idReferencia')}
            value={formik.values.idReferencia}
            error={formik.errors.idReferencia}
            touched={formik.touched.idReferencia}
          />
        </Col>
      </Row>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
              }}
            >
              <SelectCustom
                title={t('vehicles.fields.subsidiary')}
                type="select"
                style={{
                  ...inputCustom,
                }}
                id="selectSubsidiary"
                value={formik.values.filialId}
                onChange={e => {
                  formik.setFieldValue('filialId', e.target.value);
                }}
                touched={formik.touched.filialId}
                error={formik.errors.filialId}
              >
                <option label={t('vehicles.placeholder.subsidiary')} />
                {filialData.map(itemFilial => (
                  <option label={itemFilial.nome} value={itemFilial.id} />
                ))}
              </SelectCustom>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <SelectCustom
                title={t('vehicles.fields.ownerVehicles')}
                type="select"
                id="selectOwnerVehicles"
                style={{
                  ...inputCustom,
                }}
                value={formik.values.veiculoProprio}
                onChange={formik.handleChange('veiculoProprio')}
                touched={formik.touched.veiculoProprio}
                error={formik.errors.veiculoProprio}
              >
                <option label={t('common.select')} value="" />
                <option label="Sim" value="Sim" />
                <option label="Nao" value="Nao" />
              </SelectCustom>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
              }}
            >
              <InputCustom
                title={t('vehicles.fields.year')}
                type="number"
                placeholder={t('vehicles.placeholder.year')}
                style={{
                  ...inputForm,
                }}
                maxLength="4"
                onChange={formik.handleChange('ano')}
                value={formik.values.ano}
                touched={formik.touched.ano}
                error={formik.errors.ano}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputCustom
                title={t('vehicles.fields.manufacturingYear')}
                type="number"
                placeholder={t('vehicles.placeholder.manufacturingYear')}
                style={{
                  ...inputForm,
                }}
                maxLength="4"
                onChange={formik.handleChange('anoFabricacao')}
                value={formik.values.anoFabricacao}
                error={formik.errors.anoFabricacao}
                touched={formik.touched.anoFabricacao}
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10,
              }}
            >
              <InputCustom
                title={t('vehicles.fields.licensePlate')}
                type="text"
                placeholder={t('vehicles.placeholder.licensePlate')}
                style={{
                  ...inputCustom,
                }}
                onChange={formik.handleChange('placa')}
                value={formik.values.placa}
                error={formik.errors.placa}
                touched={formik.touched.placa}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <InputCustom
                title={t('vehicles.fields.brandModel')}
                type="text"
                placeholder={t('vehicles.placeholder.brandModel')}
                style={{
                  ...inputCustom,
                }}
                onChange={formik.handleChange('marcaModelo')}
                value={formik.values.marcaModelo}
                error={formik.errors.marcaModelo}
                touched={formik.touched.marcaModelo}
              />
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <div className="boxTeam">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {formik.values.filialId.length > 0 && (
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Label style={{ ...labelStyle }}>Motorista</Label>
                      <Select
                        styles={customStyles}
                        options={driverData}
                        onMenuOpen={() => {
                          setHelperText("Digite o nome do motorista");
                        }}
                        onMenuClose={() => {
                          setHelperText("");
                        }}
                        onInputChange={inputText =>
                          onChangeDriver(
                            formik.values.filialId,
                            inputText,
                            teamList
                          )
                        }
                        onChange={text => onChangeDriverTeam(text, teamList)}
                        value={{
                          label: 'Digite o nome do motorista',
                          value: '',
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Label style={{ ...labelStyle }}>Colaboradores</Label>
                      <Select
                        styles={customStyles}
                        options={collaboratorData}
                        onMenuOpen={() => {
                          setHelperText("Digite o nome do colaborador.");
                        }}
                        onMenuClose={() => {
                          setHelperText("");
                        }}
                        onInputChange={inputText =>
                          onChangeCollaborator(
                            formik.values.filialId,
                            inputText,
                            teamList
                          )
                        }
                        onChange={text =>
                          onChangeCollaboratorTeam(text, teamList)
                        }
                        value={{
                          label: 'Digite o nome do colaborador',
                          value: '',
                        }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Label style={{ ...labelStyle }}>Equipe</Label>
                <div className="boxTeamList">
                  {teamList
                    .sort((a, b) => a.type - b.type)
                    .map((team, indexTeam) => (
                      <div className="listGroupTeam">
                        <img
                          src={
                            team.type === 'DRIVER'
                              ? driverTeamIcon
                              : collaboratorTeamIcon
                          }
                        />
                        <span
                          className={
                            team.type === 'DRIVER'
                              ? 'textTeamDriver'
                              : 'textTeamCollaborator'
                          }
                        >
                          {team.nome}
                        </span>
                        <Button
                          color="link"
                          className="noMargin"
                          onClick={() => {
                            let removedElementTeam = teamList;

                            const filterRemovedElement = removedElementTeam.filter(
                              (item, index) => index !== indexTeam
                            );

                            setTeamList(filterRemovedElement);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt=""
                          />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Row style={{ marginTop: 15 }}>
        <Col md={4}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.weight" />*
          </Label>
          <Input
            type="number"
            placeholder={t('vehicles.placeholder.weight')}
            style={{
              ...inputForm,
            }}
            onChange={formik.handleChange('peso')}
            value={formik.values.peso}
          />
          {formik.touched.peso && formik.errors.peso && (
            <p style={{ fontSize: 14, color: 'red' }}>{formik.errors.peso}</p>
          )}
        </Col>
        <Col md={4}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.maxAttendances" />*
          </Label>
          <Input
            type="number"
            placeholder={t('vehicles.placeholder.maxAttendances')}
            style={{
              ...inputForm,
            }}
            onChange={formik.handleChange('quantidadeAtendimento')}
            value={formik.values.quantidadeAtendimento}
          />
          {formik.touched.quantidadeAtendimento &&
            formik.errors.quantidadeAtendimento && (
              <p style={{ fontSize: 14, color: 'red' }}>
                {formik.errors.quantidadeAtendimento}
              </p>
            )}
        </Col>
        <Col md={4}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.cubagemMax" />*
          </Label>
          <Input
            type="number"
            placeholder={t('vehicles.placeholder.cubagemMax')}
            style={{
              ...inputForm,
            }}
            onChange={formik.handleChange('cubagem')}
            value={formik.values.cubagem}
          />
          {formik.touched.cubagem && formik.errors.cubagem && (
            <p style={{ fontSize: 14, color: 'red' }}>
              {formik.errors.cubagem}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.priceMax" />*
          </Label>
          <Input
            type="number"
            placeholder={t('vehicles.placeholder.priceMax')}
            style={{
              ...inputForm,
            }}
            onChange={formik.handleChange('valor')}
            value={formik.values.valor}
          />
          {formik.touched.valor && formik.errors.valor && (
            <p style={{ fontSize: 14, color: 'red' }}>{formik.errors.valor}</p>
          )}
        </Col>
        <Col md={6}>
          <Label style={{ ...labelStyle }}>
            <Trans i18nKey="vehicles.fields.averageSpeed" />
          </Label>
          <Input
            type="number"
            placeholder={t('vehicles.placeholder.averageSpeed')}
            style={{
              ...inputForm,
            }}
            onChange={formik.handleChange('velocidadeMedia')}
            value={formik.values.velocidadeMedia}
          />
          {formik.touched.velocidadeMedia && formik.errors.velocidadeMedia && (
            <p style={{ fontSize: 14, color: 'red' }}>
              {formik.errors.velocidadeMedia}
            </p>
          )}
        </Col>
      </Row>

      <div className="footerButton">
        <button
          type="button"
          id="buttonUpdateVehicles"
          className="buttonSaveVehicles"
          onClick={() => formik.handleSubmit()}
        >
          <Trans i18nKey="vehicles.buttonUpdate" />
        </button>
      </div>
    </>
  );
}
