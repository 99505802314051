import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import InputCustom from '../../../components/Input';
import CustomModal from '../../../components/ModalCustom';
import SelectCustom from '../../../components/SelectCustom';
import Loading from '../../../core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from '../../../core/common/Notification';
import api from '../../../services/api';
import { responsiveSize } from '../../../styles/responsive';
// import CheckBox from '../../../pages/ControlTower/ListAttendances/Filters/CheckBox';
import CheckBox from './CheckBox';
import formValidator from './formValidator';
import formValidatorReason from './formValidatorReason';

interface Props {
  onClear?: any;
  pControlId: string;
}

interface FormValues {
  reasonId: string;
  anotherReason: string;
}

const RemoveData: React.FC<Props> = ({ onClear, pControlId }: Props) => {
  const [checkedReason, setCheckedReason] = useState<boolean>(false);
  const [reasons, setReasons] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormValues, pControl: string, reasonCheck: boolean) => {
      try {
        setLoading(true);

        const data = {
          reasonId: reasonCheck ? null : values.reasonId,
          anotherReason: reasonCheck ? values.anotherReason : null,
        };

        const response = await api.post(
          `/workday/pcontrols/remove/${pControl}`,
          data
        );

        if (response.status === 204) {
          FrontendNotification(
            'Ponto de controle removido com sucesso!',
            NotificationType.SUCCESS
          );

          onClear();
        }

        setLoading(false);
      } catch {}
    },
    []
  );

  const getReason = useCallback(async () => {
    try {
      const response = await api.get('/workday/reasons');

      setReasons(response.data);
    } catch {}
  }, []);

  const initialValues: FormValues = {
    reasonId: '',
    anotherReason: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: checkedReason ? formValidatorReason : formValidator,
    onSubmit: values => handleSubmit(values, pControlId, checkedReason),
  });

  useEffect(() => {
    getReason();
  }, [getReason]);

  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isOpen
        isClose={onClear}
        label="Remover"
        styles={{ maxWidth: 432 }}
      >
        <div style={{ marginTop: responsiveSize(40) }}>
          <Row>
            <Col md={12}>
              <SelectCustom
                id="reasonId"
                onChange={formik.handleChange('reasonId')}
                value={formik.values.reasonId}
                touched={formik.touched.reasonId}
                error={formik.errors.reasonId}
                title="Motivo"
              >
                <option label={t('common.select')} />
                {reasons.map((item: any) => (
                  <option label={item.name} value={item.id} />
                ))}
              </SelectCustom>
            </Col>
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
            <CheckBox
              id="checkedReason"
              active={checkedReason}
              label=""
              onChecked={() => setCheckedReason(!checkedReason)}
              styles={{}}
            />

            <span
              style={{
                fontFamily: 'Lato',
                fontSize: responsiveSize(12),
                color: '#003049',
                opacity: 0.6,
              }}
            >
              Outro motivo
            </span>
          </div>
          {checkedReason && (
            <InputCustom
              id="anotherReason"
              onChange={formik.handleChange('anotherReason')}
              placeholder=""
              value={formik.values.anotherReason}
              touched={formik.touched.anotherReason}
              error={formik.errors.anotherReason}
            />
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <button
              className="button"
              id="removePointControl"
              type="button"
              onClick={() => formik.handleSubmit()}
            >
              Confirmar
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RemoveData;
