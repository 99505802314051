import InputCustom from 'components/Input';
import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import { FrontendNotification } from 'components/Notification';
import Loading from 'core/common/Loading';
import { NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
// import { Line } from 'pages/ControlTower/components/Table/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import formValidator from './formValidator';
// import { Line } from './styles copy';
import { Line } from './styles';
import './styles.scss';

interface Props {
  onClear?: any;
}

interface CompanyData {
  appId: string;
  appKey: string;
  id: number;
}

interface FormValues {
  apiKey: string;
  emailFrom: string;
}

interface Filters {
  field: string;
  value: any;
  operation: string;
}

const IntegrationsSendGrid: React.FC<Props> = ({ onClear }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSendGrid, setDataSendGrid] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [filters, setFilters] = useState<Filters[]>([]);

  const { t } = useTranslation();

  const getIntegrationSendGrid = useCallback(async () => {
    try {
      setLoading(true);
      const currentFilters = [
        {
          field: 'type',
          value: 'SENDGRID',
          operation: 'EQUAL',
        },
      ];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPage || 0,
        size: 5,
      };
      const response = await api.post('/audit/integration/api/pageable', data);

      setDataSendGrid(response.data.content);
      setTotalPages(response.data.totalPages);

      setLoading(false);
    } catch (err) {}
  }, [currentPage, filters]);

  const handleSubmit = useCallback(async (values: FormValues) => {
    try {
      setLoading(true);
      const data = {
        apiKey: values.apiKey,
        from: values.emailFrom
      };
      const response = await api.post('/cadastro/parametros/sendgrid', data);
      setLoading(false);

      FrontendNotification(
        t('integrationsAPI.messageSuccess'),
        NotificationType.SUCCESS
      );
    } catch {}
  }, []);

  function paginate(pageNumber: number) {
    setCurrentPage(pageNumber - 1);
    
  }

  const initialValues: FormValues = {
    apiKey: '',
    emailFrom: '',
  };

  

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values)
  });

  const getParametersSendgrid = useCallback(async () => {
    const response = await api.get('/cadastro/parametros/sendgrid');

    if (response.data.length > 0) {
      if (response.data[0].apiKey !== null) {
        formik.setFieldValue('apiKey', response.data[0].apiKey);
      }
      if (response.data[0].from !== null) {
        formik.setFieldValue('emailFrom', response.data[0].from);
      }
     
    }
  }, []);

  useEffect(() => {
    getIntegrationSendGrid();
    getParametersSendgrid();
  }, [getIntegrationSendGrid, getParametersSendgrid]);
  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isOpen
        isIntegrationSendgrid
        label={`${t('integrationsAPI.menuTitle')}`}
        styles={{ maxWidth: '90%' }}
        isClose={() => onClear()}
      >
        <Row>
          <Col md={12}>
            <InputCustom
              title="API KEY"
              onChange={formik.handleChange('apiKey')}
              placeholder=""
              styleTitle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: '#003049',
              }}
              value={formik.values.apiKey}
              touched={formik.touched.apiKey}
              error={formik.errors.apiKey}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <InputCustom
              title="Email from"
              onChange={formik.handleChange('emailFrom')}
              placeholder=""
              styleTitle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: '#003049',
              }}
              value={formik.values.emailFrom}
              touched={formik.touched.emailFrom}
              error={formik.errors.emailFrom}
            />
          </Col>
          <Col md={6}>
            <button
              type="button"
              className="button"
              style={{ marginTop: 40 }}
              onClick={() => formik.handleSubmit()}
            >
              Salvar
            </button>
          </Col>
        </Row>

        <Line style={{ padding: 0, margin: 0, marginTop: 30 }} />

        <Logs
          data={dataSendGrid}
          totalPages={totalPages}
          page={currentPage}
          onPaginate={(page: number) => paginate(page)}
          onFilters={(date: string, status: string) => {
            setCurrentPage(0);
            if (date.length > 0 && status.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
                {
                  field: 'type',
                  value: 'SENDGRID',
                  operation: 'EQUAL'
                }
              ]);
            } else if (date.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
                {
                  field: 'type',
                  value: 'SENDGRID',
                  operation: 'EQUAL'
                }
              ]);
            } else if (status.length > 0) {
              setFilters([
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
                {
                  field: 'type',
                  value: 'SENDGRID',
                  operation: 'EQUAL'
                }
              ]);
            } else {
              setFilters([
                {
                  field: 'type',
                  value: 'SENDGRID',
                  operation: 'EQUAL'
                }
              ]);
            }
          }}
        />
      </CustomModal>
    </>
  );
};

export default IntegrationsSendGrid;
