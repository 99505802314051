import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import formValidatorEN from '../formValidatorEN';
import formValidatorPT from '../formValidatorPT';
import { CargoType, Responsability, TypeOccurrence } from '../interfaces/types';
import Switch from './components/Switch';

export interface FormValues {
  descricao: string;
  numero: string;
  tipo: string;
  tipoOcorrencia: string;
  sequencial: string;
  responsavel: string;
  insucesso: string;
  activityType: string;
  signature: string;
  contractor: string;
  photo: string;
  docReceiver: string;
  nameReceiver: string;
}

export interface SwitchOptions {
  checkedSuccess: boolean;
  checkedInformativa: boolean;
  checkedPhoto: boolean;
  checkedAss: boolean;
  checkedBeep: boolean;
  checkedFinisher: boolean;
  checkedDriver: boolean;
  checkedDocReceiver: boolean;
  checkedNameReceiver: boolean;
  checkedBaixaManual: boolean;
}

interface Props {
  onSubmit: (values: FormValues, checked: SwitchOptions) => void;
}

const NewOccurrence: React.FC<Props> = (props: Props) => {
  const [activities, setActivities] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [checked, setChecked] = useState<SwitchOptions>({
    checkedSuccess: false,
    checkedInformativa: false,
    checkedPhoto: false,
    checkedAss: false,
    checkedBeep: false,
    checkedFinisher: false,
    checkedDriver: true,
    checkedDocReceiver: false,
    checkedNameReceiver: false,
    checkedBaixaManual: false,
  });

  const { t } = useTranslation();

  const getLanguage = () => {
    return i18next.language;
  };

  const initialValues: FormValues = {
    descricao: '',
    numero: '',
    tipo: '',
    tipoOcorrencia: TypeOccurrence.SUCESSO,
    sequencial: '',
    responsavel: '',
    insucesso: '',
    activityType: '',
    signature: '',
    contractor: '',
    photo: '',
    docReceiver: '',
    nameReceiver: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      getLanguage() === 'pt' || getLanguage() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values: FormValues) => props.onSubmit(values, checked),
  });

  const getContractors = useCallback(async () => {
    const { data } = await api.get('/delivery/contratantes');

    const mappingContractors = data.map((item: any) => {
      return {
        value: item.id,
        label: item.pessoaJuridica.nomeFantasia,
      };
    });
    setContractors(mappingContractors);
  }, []);

  const getActivities = useCallback(async () => {
    const { data } = await api.get('/delivery/tiposAtividades');

    const mappingActivities = data.map((item: any) => {
      return {
        value: item.id,
        label: item.nome,
      };
    });
    setActivities(mappingActivities);
  }, []);

  const renderSwitchOptions = useCallback(
    (values: FormValues) => {
      switch (values.tipoOcorrencia) {
        case TypeOccurrence.SUCESSO:
          return (
            <>
              <Col md="2">
                <Switch
                  id="checkedFinisher"
                  title={t('ocorrences.fields.closure')}
                  onChange={() =>
                    setChecked({
                      ...checked,
                      checkedFinisher: !checked.checkedFinisher,
                    })
                  }
                  active={checked.checkedFinisher}
                />
              </Col>
              {!checked.checkedBaixaManual && (
                <>
                  <Col md="2">
                    <Switch
                      id="switchBeep"
                      title={t('ocorrences.fields.beep')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedBeep: !checked.checkedBeep,
                        })
                      }
                      active={checked.checkedBeep}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchDriver"
                      title={t('ocorrences.fields.driver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedDriver: !checked.checkedDriver,
                        })
                      }
                      active={checked.checkedDriver}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchSignature"
                      title={t('ocorrences.fields.signatures')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedAss: !checked.checkedAss,
                        })
                      }
                      active={checked.checkedAss}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchPhoto"
                      title={t('ocorrences.fields.photo')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedPhoto: !checked.checkedPhoto,
                        })
                      }
                      active={checked.checkedPhoto}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchDocReceiver"
                      title={t('ocorrences.fields.docReceiver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedDocReceiver: !checked.checkedDocReceiver,
                        })
                      }
                      active={checked.checkedDocReceiver}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchNameReceiver"
                      title={t('ocorrences.fields.nameReceiver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedNameReceiver: !checked.checkedNameReceiver,
                        })
                      }
                      active={checked.checkedNameReceiver}
                    />
                  </Col>
                </>
              )}

              <Col md="2">
                <Switch
                  id="switchBaixaManual"
                  title={t('ocorrences.fields.baixaManual')}
                  onChange={() =>
                    setChecked({
                      ...checked,
                      checkedAss: false,
                      checkedBeep: false,
                      checkedDocReceiver: false,
                      checkedDriver: false,
                      checkedFinisher: false,
                      checkedInformativa: false,
                      checkedNameReceiver: false,
                      checkedPhoto: false,
                      checkedSuccess: false,
                      checkedBaixaManual: !checked.checkedBaixaManual,
                    })
                  }
                  active={checked.checkedBaixaManual}
                />
              </Col>
            </>
          );
          break;
        case TypeOccurrence.INSUCESSO:
          return (
            <>
              <Col md="2">
                <Switch
                  id="switchFinisher"
                  title={t('ocorrences.fields.closure')}
                  onChange={() =>
                    setChecked({
                      ...checked,
                      checkedFinisher: !checked.checkedFinisher,
                    })
                  }
                  active={checked.checkedFinisher}
                />
              </Col>
              {!checked.checkedBaixaManual && (
                <>
                  <Col md="2">
                    <Switch
                      id="switchDriver"
                      title={t('ocorrences.fields.driver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedDriver: !checked.checkedDriver,
                        })
                      }
                      active={checked.checkedDriver}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchSignature"
                      title={t('ocorrences.fields.signatures')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedAss: !checked.checkedAss,
                        })
                      }
                      active={checked.checkedAss}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchPhoto"
                      title={t('ocorrences.fields.photo')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedPhoto: !checked.checkedPhoto,
                        })
                      }
                      active={checked.checkedPhoto}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchDocReceiver"
                      title={t('ocorrences.fields.docReceiver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedDocReceiver: !checked.checkedDocReceiver,
                        })
                      }
                      active={checked.checkedDocReceiver}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchNameReceiver"
                      title={t('ocorrences.fields.nameReceiver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedNameReceiver: !checked.checkedNameReceiver,
                        })
                      }
                      active={checked.checkedNameReceiver}
                    />
                  </Col>
                </>
              )}

              <Col md="2">
                <Switch
                  id="switchBaixaManual"
                  title={t('ocorrences.fields.baixaManual')}
                  onChange={() =>
                    setChecked({
                      ...checked,
                      checkedAss: false,
                      checkedBeep: false,
                      checkedDocReceiver: false,
                      checkedDriver: false,
                      checkedFinisher: false,
                      checkedInformativa: false,
                      checkedNameReceiver: false,
                      checkedPhoto: false,
                      checkedSuccess: false,
                      checkedBaixaManual: !checked.checkedBaixaManual,
                    })
                  }
                  active={checked.checkedBaixaManual}
                />
              </Col>
            </>
          );
          break;

        case TypeOccurrence.INFORMATIVA:
          return (
            <>
              {!checked.checkedBaixaManual && (
                <>
                  <Col md="2">
                    <Switch
                      id="switchDriver"
                      title={t('ocorrences.fields.driver')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedDriver: !checked.checkedDriver,
                        })
                      }
                      active={checked.checkedDriver}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchSignature"
                      title={t('ocorrences.fields.signatures')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedAss: !checked.checkedAss,
                        })
                      }
                      active={checked.checkedAss}
                    />
                  </Col>
                  <Col md="2">
                    <Switch
                      id="switchPhoto"
                      title={t('ocorrences.fields.photo')}
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedPhoto: !checked.checkedPhoto,
                        })
                      }
                      active={checked.checkedPhoto}
                    />
                  </Col>
                </>
              )}
            </>
          );
          break;

        default:
          break;
      }
    },
    [formik.values, checked]
  );

  useEffect(() => {
    getContractors();
    getActivities();
  }, [getContractors, getActivities]);

  return (
    <div>
      <Row>
        <Col md="3">
          <InputCustom
            type="text"
            id="description"
            title={t('ocorrences.fields.description')}
            onChange={formik.handleChange('descricao')}
            placeholder={t('ocorrences.fields.description')}
            error={formik.errors.descricao}
            touched={formik.touched.descricao}
            value={formik.values.descricao}
          />
        </Col>
        <Col md="3">
          <SelectCustom
            id="type"
            title={t('ocorrences.fields.typeService')}
            onChange={formik.handleChange('tipo')}
            error={formik.errors.tipo}
            touched={formik.touched.tipo}
            value={formik.values.tipo}
          >
            <option label={t('ocorrences.select')} id={t('ocorrences.select')} />
            <option
              value={CargoType.ENTREGA}
              label={t('ocorrences.fields.delivery')}
              id={t('ocorrences.fields.delivery')}
            />
            <option
              value={CargoType.COLETA}
              label={t('ocorrences.fields.collect')}
              id={t('ocorrences.fields.collect')}
            />
          </SelectCustom>
        </Col>
        <Col md="3">
          <SelectCustom
            title={t('ocorrences.fields.typeOcorrence')}
            onChange={formik.handleChange('tipoOcorrencia')}
            error={formik.errors.tipoOcorrencia}
            touched={formik.touched.tipoOcorrencia}
            value={formik.values.tipoOcorrencia}
            id="typeOccurrence"
          >
            {/* <option label={t('ocorrences.select')} /> */}
            <option
              value={TypeOccurrence.SUCESSO}
              label={t('ocorrences.fields.success')}
              id={t('ocorrences.fields.success')}
            />
            <option
              value={TypeOccurrence.INSUCESSO}
              label={t('ocorrences.fields.insucess')}
              id={t('ocorrences.fields.insucess')}
            />
            <option
              value={TypeOccurrence.INFORMATIVA}
              label={t('ocorrences.fields.information')}
              id={t('ocorrences.fields.information')}
            />
          </SelectCustom>
        </Col>
        <Col md="3">
          <InputCustom
            type="number"
            title={t('ocorrences.fields.number')}
            onChange={formik.handleChange('numero')}
            placeholder="Número"
            error={formik.errors.numero}
            touched={formik.touched.numero}
            value={formik.values.numero}
            id="numero"
          />
        </Col>
      </Row>
      <Row className="tw-mt-3">
        <Col md="3">
          <SelectCustom
            title={t('ocorrences.fields.responsible')}
            onChange={formik.handleChange('responsavel')}
            error={formik.errors.responsavel}
            touched={formik.touched.responsavel}
            value={formik.values.responsavel}
            id="responsible"
          >
            <option label={t('ocorrences.select')} />
            <option
              value={Responsability.EMBARCADOR}
              label={t('ocorrences.fields.shipper')}
              id={t('ocorrences.fields.shipper')}
            />
            <option
              value={Responsability.TRANSPORTADOR}
              label={t('ocorrences.fields.transporter')}
              id={t('ocorrences.fields.transporter')}
            />
            <option
              value={Responsability.CLIENTE}
              label={t('ocorrences.fields.client')}
              id={t('ocorrences.fields.client')}
            />
          </SelectCustom>
        </Col>
        <Col md="3">
          <InputCustom
            type="number"
            title={t('ocorrences.fields.sequence')}
            onChange={formik.handleChange('sequencial')}
            placeholder="Sequencial"
            error={formik.errors.sequencial}
            touched={formik.touched.sequencial}
            value={formik.values.sequencial}
            id="sequence"
          />
        </Col>
        <Col md="3">
          <SelectCustom
            title={t('ocorrences.fields.contractor')}
            onChange={formik.handleChange('contractor')}
            error={formik.errors.contractor}
            touched={formik.touched.contractor}
            value={formik.values.contractor}
            id="contractor"
          >
            <>
              <option label={t('ocorrences.select')} />
              {contractors.map((item: any) => (
                <option value={item.value} label={item.label} />
              ))}
            </>
          </SelectCustom>
        </Col>
        <Col md="3">
          <SelectCustom
            title={t('ocorrences.fields.activityType')}
            onChange={formik.handleChange('activityType')}
            error={formik.errors.activityType}
            touched={formik.touched.activityType}
            value={formik.values.activityType}
            id="activityType"
          >
            <>
              <option label={t('ocorrences.select')} />
              {activities.map((item: any) => (
                <option value={item.value} label={item.label} />
              ))}
            </>
          </SelectCustom>
        </Col>
      </Row>
      <Row className="tw-mt-3">
        {checked.checkedPhoto && (
          <Col md="3">
            <InputCustom
              type="text"
              title={t('ocorrences.fields.photo')}
              onChange={formik.handleChange('photo')}
              placeholder="Foto"
              error={formik.errors.photo}
              touched={formik.touched.photo}
              value={formik.values.photo}
              id="occurrencePhoto"
            />
          </Col>
        )}
        {checked.checkedNameReceiver && (
          <Col md="3">
            <InputCustom
              type="text"
              title={t('ocorrences.fields.nameReceiver')}
              onChange={formik.handleChange('nameReceiver')}
              placeholder="Nome do recebedor"
              error={formik.errors.nameReceiver}
              touched={formik.touched.nameReceiver}
              value={formik.values.nameReceiver}
              id="occurrenceNameReceiver"
            />
          </Col>
        )}
        {checked.checkedDocReceiver && (
          <Col md="3">
            <InputCustom
              type="text"
              title={t('ocorrences.fields.docReceiver')}
              onChange={formik.handleChange('docReceiver')}
              placeholder="Documento do recebedor"
              error={formik.errors.docReceiver}
              touched={formik.touched.docReceiver}
              value={formik.values.docReceiver}
              id="occurrenceDocReceiver"
            />
          </Col>
        )}
        {checked.checkedAss && (
          <Col md="3">
            <InputCustom
              type="text"
              title={t('ocorrences.fields.signatures')}
              onChange={formik.handleChange('signature')}
              placeholder="Assinatura"
              error={formik.errors.signature}
              touched={formik.touched.signature}
              value={formik.values.signature}
              id="occurrenceSignature"
            />
          </Col>
        )}
      </Row>
      <Row>{renderSwitchOptions(formik.values)}</Row>
      <div className="tw-mt-4 tw-w-full tw-flex tw-justify-end">
        <button
          onClick={() => formik.handleSubmit()}
          className="button tw-w-[220px]"
          type="button"
          id="buttonCreateOcorrences"
        >
          <Trans i18nKey="ocorrences.save" />
        </button>
      </div>
    </div>
  );
};

export default NewOccurrence;
