import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';
import { responsiveSize } from 'styles/responsive';


interface StyledProps {
  edit: boolean;
  disabled: boolean;
}

export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: rgb(0, 48, 73);
  opacity: 0.6;
  font-style: normal;
  display: flex;
  flex-direction: column;
`;

export const InputForm = styled.input`
  height: 2.5rem;
  background-color: ${({edit, disabled}: StyledProps) => edit || disabled ? '#ccc' : '#FFFFFF'};
  opacity: ${({edit}: StyledProps) => edit ? 0.8 : 1};
  border: 2px solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.188rem;
  color: rgb(0, 48, 73);
  margin-top: 0.5rem;
  padding-left: 0.625rem;
  /* margin-bottom: ${responsiveSize(10)}; */
  width: 100%;

  &::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 0.875rem;
    color: rgb(0, 48, 73);
  }
`;

export const InputMask = styled(ReactInputMask)`
  height: 2.5rem;
  background-color: ${({edit, disabled}: StyledProps) => edit || disabled  ? '#ccc' : '#FFFFFF'};
  opacity: ${({edit}: StyledProps) => edit ? 0.8 : 1};
  border: 2px solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.188rem;
  color: rgb(0, 48, 73);
  margin-top: 0.5rem;
  padding-left: 0.625rem;
  /* padding-top: ${responsiveSize(5)}; */
  /* margin-bottom: ${responsiveSize(10)}; */
  width: 100%;
  &::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 0.875rem;
    color: rgb(0, 48, 73);
  }
`;



export const Error = styled.span`
  font-size: 0.875rem;
  color: #EA004C;
  font-weight: bold;
`;