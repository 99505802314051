// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  width: 100%;
}

.modal-footer {
  justify-content: center !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/ListAttendances/components/CardKnowledge/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kCAAA;AACF","sourcesContent":[".modal-title {\n  width: 100%;\n}\n\n.modal-footer {\n  justify-content: center !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
