import { Pagination } from '@mui/material';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import SearchMoreIcon from 'assets/images/searchMoreStep.svg';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'reactstrap/lib';
import api from 'services/api';
import CloseIcon from '../../../assets/images/closeIcon.svg';
import ListShipment from './ListShipment';

interface RouteringDetailsProps {
  isOpen: boolean;
  onClear: () => void;
  idRoute: string;
}

const RouteringDetails: React.FC<RouteringDetailsProps> = ({
  isOpen,
  onClear,
  idRoute,
}: RouteringDetailsProps) => {
  const [details, setDetails] = useState([]);
  const [detailsRoutering, setDetailsRoutering] = useState(false);
  const [detailsRouteringId, setDetailsRouteringId] = useState();
  const [optionsFilter, setOptionsFilter] = useState('idReferencia');
  const [valueSearch, setValueSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filters, setFilters] = useState<any>([]);
  const [routeStepRemoveId, setRouteStepRemoveId] = useState<number>(0);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [idRoute, setIdRoute] = useState(idRoute);

  const getRouteringDetails = useCallback(async () => {
    try {
      setLoading(true);
      const currentFilters = [
        {
          field: 'optmization.id',
          value: localStorage.getItem('optmizationId'),
          operation: 'EQUAL',
        },
      ];

      let filtersRoutering = filters;

      const findFiltersByIndex = filters.map((itemFilter: any, indexFilter: any) => itemFilter.column === 'optmization.id' ? indexFilter : -1).filter((filterColumn: any) => filterColumn !== -1);

      if(findFiltersByIndex.length > 0) {
        filtersRoutering[findFiltersByIndex].value = localStorage.getItem('optmizationId');
      }

      const data = {
        filters: filtersRoutering.length > 0 ? filtersRoutering : currentFilters,
        orders: [
          {
            field: "order",
            direction: "ASC"
          }
        ],
        size: 10,
        page: currentPage,
      };
      const response = await api.post('/route/steps/pageable', data);

      setDetails(response.data.content);

      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [idRoute, filters, currentPage]);

  const deleteCargo = useCallback(async (idCargo: number, isReactivate: boolean) => {
    try {
      setLoading(true);

      const data: any = {
        isCargoReactivate: isReactivate,
        idOptmization: localStorage.getItem('optmizationId')
      }

      const response = await api.delete(`/delivery/conhecimentosPre/cargo/${idCargo}`, {
        data
      });

      FrontendNotification('Atendimento removido com sucesso', NotificationType.SUCCESS);

      setRemoveConfirmation(false);

      getRouteringDetails();

      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    getRouteringDetails();
  }, [getRouteringDetails]);
  return (
    <React.Fragment>
      <Modal isOpen={removeConfirmation}>
        <ModalBody>
          <div style={{ display: 'flex', }} >
          Deseja reativar esse conhecimento na tela de planejamento?
          {/* <Trans i18nKey="common.questionRemove" /> */}
          <div style={{ marginLeft: '0.712rem', cursor: 'pointer',  }} onClick={() => setRemoveConfirmation(!removeConfirmation)}>

          <img src={CloseIcon} alt="" />
          </div>
          </div>
        </ModalBody>
        <ModalFooter>
       
          <Button color="success" onClick={() => deleteCargo(routeStepRemoveId, true)}>
            <Trans i18nKey="common.yes" />
          </Button>{' '}
          <Button
            color="danger"
            onClick={() => deleteCargo(routeStepRemoveId, false)}
          >
            <Trans i18nKey="common.no" />
          </Button>
        </ModalFooter>
      </Modal>
      <Loading loading={loading} />
      {detailsRoutering && (
        <div
          style={{
            position: 'absolute',
            top: '10%',
            left: '40%',
            zIndex: 99999999,
          }}
        >
          <ListShipment
            idRoute={detailsRouteringId}
            onClear={() => setDetailsRoutering(!detailsRoutering)}
            alertCargo={false}
          />
        </div>
      )}
      <CustomModal
        isOpen={isOpen}
        label="Atendimentos"
        isClose={onClear}
        styles={{ zIndex: 9999999 }}
      >
        <div style={{ marginBottom: 20 }}>
          <SearchFilter
            isReferenceId
            isClientName
            isCity
            isNeighborhood
            isService
            // isTypeDriver={optionsFilter === 'idRe'}
            valueSearch={valueSearch}
            optionValue={optionsFilter}
            handleChange={(e: any) => setValueSearch(e.target.value)}
            handleSelect={(e: any) => setOptionsFilter(e.target.value)}
            handleSelectDriver={(e: any) => setValueSearch(e.target.value)}
            onExecuteFilter={() => {
              setCurrentPage(0);
              if (optionsFilter === 'idReferencia' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'optmization.id',
                    value: idRoute,
                    operation: 'EQUAL',
                  },
                  {
                    field: 'conhecimentoPre.idReferencia',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              } else if (optionsFilter === 'nomeCliente' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'optmization.id',
                    value: idRoute,
                    operation: 'EQUAL',
                  },
                  {
                    field: 'conhecimentoPre.clienteNome',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              } else if (optionsFilter === 'cidade' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'optmization.id',
                    value: idRoute,
                    operation: 'EQUAL',
                  },
                  {
                    field: 'conhecimentoPre.enderecoCidade',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              }else if (optionsFilter === 'bairro' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'optmization.id',
                    value: idRoute,
                    operation: 'EQUAL',
                  },
                  {
                    field: 'conhecimentoPre.enderecoBairro',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              } else {
                setFilters([
                  {
                    field: 'optmization.id',
                    value: localStorage.getItem('optmizationId'),
                    operation: 'EQUAL',
                  },
                ]);
              }
            }}
          />
        </div>

        <table style={{ width: '100%', marginTop: 60 }}>
          <thead>
            <tr>
              <th style={{ padding: 0 }}>
                <span className="columnRoutering">Id de Ref.</span>
              </th>
              <th className="columnRoutering" style={{ padding: 0 }}>
                <span>Nome do cliente</span>
              </th>
              <th className="columnRoutering" style={{ padding: 0 }}>
                <span>Número do Documento</span>
              </th>
              <th className="columnRoutering" style={{ padding: 0 }}>
                <span>Ordem</span>
              </th>
              <th className="columnRoutering" style={{ padding: 0 }}>
                <span>Endereço</span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {details.map((item: any) => (
              <tr className="lineGroupSimulation" style={{ height: 27 }}>
                <td style={{ padding: 0, marginLeft: 10 }}>
                  <span className="rowRoutering">
                    {item.conhecimentoPre.idReferencia}
                  </span>
                </td>
                <td style={{ padding: 0, marginLeft: 10 }}>
                  <span className="rowRoutering">
                    {item.conhecimentoPre.clienteNome}
                  </span>
                </td>
                <td style={{ padding: 0, marginLeft: 10 }}>
                  <span className="rowRoutering">
                    {item.conhecimentoPre.numero}
                  </span>
                </td>
                <td style={{ padding: 0, marginLeft: 10 }}>
                  <span className="rowRoutering">{item.order}</span>
                </td>
                <td>
                  <span className="rowRoutering">
                    {item.conhecimentoPre.tipo.toLowerCase() === 'entrega'
                      ? `${item.conhecimentoPre.enderecoLogradouro}, ${item.conhecimentoPre.enderecoNumero}, ${item.conhecimentoPre.enderecoComplemento}`
                      : `${item.conhecimentoPre.enderecoLogradouroColeta}, ${item.conhecimentoPre.enderecoNumeroColeta}, ${item.conhecimentoPre.enderecoComplementoColeta}`}
                  </span>
                </td>
                <td>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      setDetailsRoutering(!detailsRoutering);
                      setDetailsRouteringId(item.id);
                      // setRouteCargoId(item.optmization.id);
                      // setRemoveConfirmation(!removeConfirmation);
                    }}
                  >
                    <img
                      src={SearchMoreIcon}
                      alt=""
                    />
                  </Button>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      // setDetailsRoutering(!detailsRoutering);
                      // setDetailsRouteringId(item.id);
                      setRouteStepRemoveId(item.conhecimentoPre.id);
                      setRemoveConfirmation(!removeConfirmation);
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt=""
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e: any, page: number) => {
              setCurrentPage(page - 1);
            }}
          />
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default RouteringDetails;
