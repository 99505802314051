import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import CancelBlingRequestIcon from 'assets/images/cancelBlingRequest.svg';
import SuccessBlingRequestIcon from 'assets/images/successBlingRequest.svg';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import CustomModal from '../../ModalCustom';
import './styles.scss';

interface Props {
  loading?: boolean;
  statusCode?: any;
  data?: any,
  onClear?: any
}

const LoadingBling: React.FC<Props> = ({
  loading,
  statusCode,
  data,
  onClear
}: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  const { t } = useTranslation()



  // useEffect(() => setLoadingActive(loading), [loading])
  return (
    <CustomModal
      isOpen={isOpen}
      isNotClose
      styles={{ maxWidth: '420px', maxHeight: '200px', padding: 40 }}
      label=""
    >
      <Container>
        {loading ? (
          <>
            <span className="titleLoading">{t('integrationsBling.loading.title')}</span>
            <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
              <LinearProgress color="error" />
            </Stack>
          </>
        ) : (
          <div className="resultContainer">
            <img
              src={statusCode < 400 ? SuccessBlingRequestIcon : CancelBlingRequestIcon}
            />
            <span className="resultTitle">
              {statusCode < 400 ? t('integrationsBling.loading.success') : t('integrationsBling.loading.error')}
            </span>

            <span className="resultContent">
              {statusCode < 400 ? (
                <>
                  {data.totalGeral} <Trans i18nKey="integrationsBling.loading.textSuccess" />
                  <br /> <Trans i18nKey="integrationsBling.loading.textSuccessSicronize" />
                </>
              ) : (
                <Trans i18nKey="integrationsBling.loading.textError" />
              )}
            </span>
            <button className='button' onClick={() => onClear()}>
                <Trans i18nKey="integrationsBling.loading.buttonClose" />
            </button>
          </div>
        )}
      </Container>
    </CustomModal>
  );
};

export default LoadingBling;
