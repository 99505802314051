import { Input } from 'reactstrap/lib';
import styled from 'styled-components';
import { responsiveSize } from 'styles/responsive';

interface StyledProps {
  edit?: boolean
}

export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: #003049;
  opacity: 0.6;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: #003049 !important;
`;

export const Error = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  color: red;
`;

export const InputSelect = styled(Input)`
  height: 2.5rem;
  background-color: ${({edit}: StyledProps) => edit ? '#ccc' : '#FFFFFF'};
  opacity: ${({edit}: StyledProps) => edit ? 0.8 : 1};
  border: ${responsiveSize(2)} solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 0.625rem;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  line-height: 1.188rem;
  color: rgb(0, 48, 73);
  margin-top: 0.5rem;
  padding-top: 0;
  padding-left: 0.625rem;


  &::-webkit-input-placeholder {
    font-size: 0.875rem;
    font-weight: 300;
  }
`;