import { EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH } from 'constants/field';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import BaseTemplate from 'template/baseTemplate';
import { isValidEmail } from 'util/validationUtil';
import dataAlert from '../ControlTower/components/Alert/data/data.json';
import componentShipment from '../ControlTower/data/component.json';
import dataShipment from '../ControlTower/data/data.json';


import { setLoadingSignIn, signInRequest } from 'store/modules/auth/actions';

import {
  ContainerTitle,
  LabelInput,
  Line,
  PasswordContainer,
  Title
} from 'components/Styled/guiPublic';
import { Input } from 'core/common/Styled/FormStyled';
import { Button } from 'reactstrap';
import { ContainerLogin, ForgotPasswordLink } from './styles';

export default function SignIn() {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = useState('');
  const loading = useSelector(state => state.auth.loadingSignIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoadingSignIn(false));
  }, [dispatch]);

  const isValid = data => {
    let message = null;
    if (!data.email) {
      message = t('form.field.message.emailRequired');
      FrontendNotification(message, NotificationType.WARNING);
      return false;
    }
    if (!isValidEmail(data.email)) {
      message = t('form.field.message.invalidEmail');
      FrontendNotification(message, NotificationType.WARNING);
      return false;
    }
    if (!data.password) {
      message = t('form.field.message.passwordRequired');
      FrontendNotification(message, NotificationType.WARNING);
      return false;
    }

    return true;
  };

  const getData = () => {
    return {
      email,
      password,
    };
  };

  const handleSubmit = () => {
    if (isValid(getData())) {
      sessionStorage.setItem(
        'component::shipment',
        JSON.stringify(componentShipment)
      );
      sessionStorage.setItem('data::shipment', JSON.stringify(dataShipment));
      sessionStorage.setItem('data::alert', JSON.stringify(dataAlert));
      sessionStorage.setItem(
        'kpis',
        JSON.stringify({
          totalVeiculos: 0,
          totalEntregas: 0,
          totalVeiculosBase: 0,
          totalVeiculosBasePerc: 0,
          totalVeiculosRetornandoBase: 0,
          totalVeiculosRetornandoBasePerc: 0,
          totalCanceladas: 0,
          totalCanceladasPerc: 0,
          totalPendentes: 0,
          totalPendentesPerc: 0,
          totalEfetivadas: 0,
          totalEfetivadasPerc: 0,
          totalEmAtendimento: 0,
          totalInsucessos: 0,
          totalInsucessosPerc: 0,
          totalEmAtendimentoPerc: 0,
          totalVeiculosSemComunicacao: 0,
          totalVeiculosSemComunicacaoPerc: 0,
          totalAtendimentosValidos: 0,
          totalAtendimentosValiosPerc: 0,
          totalAtendimentosRealizados: 0,
        })
      );
      dispatch(signInRequest(getData().email, getData().password));
    }
  };

  return (
    <BaseTemplate loading={loading} text={t('message.welcome.back')}>
      <ContainerLogin>
        <ContainerTitle>
          <Title>
            <Trans i18nKey="signIn.title" />
          </Title>
          <Line />
        </ContainerTitle>
        <Form style={{ marginBottom: '104px' }}>
          <Row>
            <Col md="12">
              <FormGroup>
                <LabelInput>
                  <Trans i18nKey="form.field.email" />
                </LabelInput>
                <Input
                  placeholder={t('form.field.placeholder.email')}
                  maxLength={EMAIL_MAX_LENGTH}
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <PasswordContainer>
                  <LabelInput>
                    <Trans i18nKey="form.field.password" />
                  </LabelInput>

                  <ForgotPasswordLink to="/ForgotPassword">
                    <Trans i18nKey="signIn.forgotYourPassword" />
                  </ForgotPasswordLink>
                </PasswordContainer>

                <Input
                  type="password"
                  placeholder={t('form.field.placeholder.password')}
                  maxLength={PASSWORD_MAX_LENGTH}
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      handleSubmit();
                    }
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-7">
            <Col md="12">
              <FormGroup>
                <Button
                  type="button"
                  className="btn btn-signup btn-block"
                  onClick={() => handleSubmit()}
                >
                  <Trans i18nKey="form.button.login" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
          {/* <hr />
          <center className="mb-5 access" style={{ marginBottom: 120 }}>
            {/* <p>
              <Trans i18nKey="signIn.dontHaveAccount" />
            </p> 
             <FowardButton to="/SignUp">
              <Trans i18nKey="form.button.createAccount" />
            </FowardButton> 
          </center> */}
        </Form>
      </ContainerLogin>
    </BaseTemplate>
  );
}
