import styled from 'styled-components';
import { responsiveSize } from 'styles/responsive';


export const Container = styled.div`
  padding: 1rem;
  border-radius: 0.625rem;
  height: auto;
  z-index: 9;
`;

export const WrapperClose = styled.div`
  /* position: absolute;
  top: 0;
  right: 0;
  margin-right: 0.453rem; */
  /* padding: ${responsiveSize(20)}; */
`;

export const ButtonClose = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  /* height: ${responsiveSize(60)}; */
`;

export const ImageClose = styled.img`
  width: 1rem;
  height: 1rem;
`;

export const ModalTitle = styled.p`
  font-size: 1.2rem;
  font-family: 'Lato';
  font-weight: bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;