import React from 'react';

class BaseComponents extends React.Component {
    showLoading = (value) => {
        this.setState({
            loading: value
        });
    }

    getStatusLoading = () => {
        return this.state.loading;
    }

    initState = (state) => {
        state.loading = false;
        return state;
    }
}

export default BaseComponents;
