import ModalCreatedCargo from 'components/ModalCreatedCargo';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import permissionMenu from 'shared/Permission';
import {
  addAccumulatorRouteringCargas,
  getPorcentageAccumulator,
  removeAccumulatorRouteringCargas,
  resetCargasAccumulator,
  resetValueAccumulator,
  updateAccumulatorRoutering,
} from 'store/modules/accumulator/actions';
import ModalAddress from './ModalAddress';
import ModalDelete from './ModalDelete';
import SearchAddress from './SearchAddress';
import Table from './Table';
import TopBar from './TopBar';
import './styles.scss';

export interface ParametersData {
  roteirizacaoAtiva?: boolean;
}

const CorrectCSV = () => {
  const arrData = useSelector((state: any) => state.csvMapping.data);
  const arrDataAddress = useSelector(
    (state: any) => state.csvMapping.dataAddress
  );

  const getSubsidiaryId = sessionStorage.getItem('subsidiary');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const [removeConfirmationAll, setRemoveConfirmationAll] = useState<boolean>(
    false
  );
  const [vehiclesData, setVehiclesData] = React.useState([]);
  const [arrElements, setArrElements] = useState<any[]>(arrData.elements);
  const [arrHeaders, setArrHeaders] = useState<any>(arrData.headers || []);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalItemsAddress, setTotalItemsAddress] = useState<number>(0);
  const [totalPagesAddress, setTotalPagesAddress] = useState<number>(0);
  const [errors, setErrors] = useState([]);
  const [showModalAddress, setShowModalAddress] = useState<boolean>(false);
  const [parameters, setParameters] = useState<ParametersData[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [subsidiary, setSubsidiary] = useState([]);
  const [selectSubsidiaryList, setSelectSubsidiary] = useState();
  const [totalPages, setTotalPages] = useState<number>();
  const [activeTooltipFilter, setActiveTooltipFilter] = useState<boolean>(
    false
  );
  const [coordenatesFilter, setCoordenatesFilter] = useState<any>({});
  const [activeTooltipFilterError, setActiveTooltipFilterError] = useState<
    boolean
  >(false);
  const [coordenatesFilterError, setCoordenatesFilterError] = useState<any>({});
  const [dataFilterField, setDataFilterField] = useState({});
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>('');
  const [dataLocation, setDataLocation] = useState<any[]>([]);
  const [isTable, setIsTable] = useState<boolean>(true);
  const [dataColumnsLocation, setDataColumns] = useState<any[]>([]);
  const [modalLocal, setModalLocal] = useState<boolean>(false);
  const [elementsIndex, setElementsIndex] = useState<number | null>(null);
  const [modifyAddressError, setModifyAddressError] = useState();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [arrDriver, setArrDriver] = useState([]);
  const [dataCargo, setDataCargo] = useState({});
  const [modalCargo, setModalCargo] = useState(false);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [addressNoLocation, setAddressNoLocation] = useState(false);
  const [typeDelivery, setTypeDelivery] = useState('mapPrecisaoStatus');
  const { t } = useTranslation();

  const userData = useSelector((state: any) => state.auth.user);


  const dispatch = useDispatch();

  const setDriver = useCallback((id: number, name: string, dataDriver: any) => {
    const updateCSVToDriver = dataDriver.map((driver: any, i: number) => {
      if (driver.active === true) {
        const item = {
          ...driver,
          nameDriver: name,
          vehicleId: id,
        };
        return item;
      }
      return driver;
    });

    setArrElements(updateCSVToDriver);
  }, []);

  const getParametersRoutering = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/cadastro/parametrosWeb');

      setParameters(response.data);
      setLoading(false);
    } catch (err) {}
  }, []);

  const getSubsidiary = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/cadastro/filiais');
    setSubsidiary(response.data);
    setLoading(false);
  }, []);

  const handleSubmitRouteringInactive = useCallback(
    async (
      data,
      filialId,
      driverArr,
      vehiclesSelectArr,
      dataParameters,
      cargoData
    ) => {
      try {
        let mappingVehicle = [];
        const findDriver = cargoData.find((item: any) => item.vehicleId !== '');

        if (findDriver.columns.length > 0 && filialId !== undefined) {
          setLoading(true);

          const filterDriver = cargoData.filter(
            (item: any) => item.vehicleId && item.vehicleId !== ''
          );

          const arrDataSubmit = filterDriver.map((itemChange: any) => {
            return {
              ...itemChange,
              vehicleId: itemChange.vehicleId,
              filial: {
                id: filialId,
              },
              elements: [
                {
                  columns: itemChange.columns,
                },
              ],
            };
          });

          let reduced = [];

          const emailSendLanguage =
            navigator.language === 'pt' || navigator.language === 'pt-BR'
              ? 'pt-Br'
              : 'en-US';
          const response = await api.post(
            `/delivery/csvMapping/createShipment?${emailSendLanguage}`,
            arrDataSubmit
          );

          if (response.status === 200) {
            const message = t('uploadCSV.messageCreated');

            FrontendNotification(message, NotificationType.SUCCESS);

            setDataCargo(response.data);
            setModalCargo(true);
          }
          setLoading(false);
        } else if (typeof filialId === 'undefined') {
          FrontendNotification(
            t('uploadCSV.messageErroFilialRequired'),
            NotificationType.ERROR
          );
        } else {
          setLoading(false);
          const message = t('uploadCSV.messageErroDriver');

          FrontendNotification(message, NotificationType.ERROR);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // BackendErrorNotification(err);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      data,
      filialId,
      driverArr,
      vehiclesSelectArr,
      dataParametersRoutering,
      dataCargas
    ) => {
      try {
        if (
          dataParametersRoutering.length === 0 ||
          dataParametersRoutering[0].roteirizacaoAtiva === false
        ) {
          handleSubmitRouteringInactive(
            data,
            filialId,
            driverArr,
            vehiclesSelectArr,
            dataParametersRoutering,
            dataCargas
          );
        } else if (filialId !== undefined && vehiclesSelectArr.length > 0) {
          setLoading(true);

          const findDriverTeste = data.filter(
            (item: any) => item.active === true
          );

          const mappingKnowledge = findDriverTeste.map(
            (itemKnowledge: any, indexKnowledge: number) => {
              return {
                id: itemKnowledge.columns[0].data,
                priority: 0,
              };
            }
          );

          const dataSubmit = {
            veiculos: vehiclesSelectArr,
            // conhecimentos: mappingKnowledge,
            filial: parseInt(filialId),
          };

          const langRequest = navigator.language;

          const response = await api.post(
            `/delivery/conhecimentosPre/geraRotas?lang=${langRequest}`,
            dataSubmit
          );

          setLoading(false);

          sessionStorage.setItem(
            'idSimulacao',
            response.data.id_route_simulation
          );

          // dispatch(geraRotasSimulation(response.data.id_route_simulation));

          if (response.status === 201) {
            const message = t('uploadCSV.messageCreated');

            FrontendNotification(message, NotificationType.SUCCESS);

            history.push('/routering');
            window.location.reload();
          }
        } else if (vehiclesSelectArr.length === 0) {
          FrontendNotification(
            t('uploadCSV.messageErroVehiclesRequired'),
            NotificationType.ERROR
          );
        } else if (typeof filialId === 'undefined') {
          FrontendNotification(
            t('uploadCSV.messageErroFilialRequired'),
            NotificationType.ERROR
          );
        } else {
          setLoading(false);
          const message = t('uploadCSV.messageErroDriver');

          FrontendNotification(message, NotificationType.ERROR);
        }
      } catch (err) {
        setLoading(false);
      }
    },
    []
  );

  const getElements = useCallback(
    async (isSelectAll: boolean, pageSelect: number, type: any) => {
      setLoading(true);

      const currentOrders = [
        {
          field: 'ordemEntrega',
          direction: 'ASC',
        },
        {
          field: 'id',
          direction: 'ASC',
        },
      ];
      const language =
        navigator.language === 'pt' || 'pt-BR' ? 'pt-Br' : 'en-US';
      const dataCSV = {
        filters: [
          {
            field: 'filial',
            value: [Number(sessionStorage.getItem('subsidiary'))],
            operation: 'IN',
          },
          {
            field: type,
            value: 50,
            operation: 'LESS_THAN_EQUAL',
          },
          {
            field: 'tipo',
            value: type === 'mapPrecisaoStatus' ? 'Entrega' : 'Coleta',
            operation: 'MATCH',
          },
        ],
        orders: currentOrders,
        page: pageSelect || 0,
        size: 10,
      };
      let elements = [];
      const { data } = await api.post(
        `/delivery/csvMapping/pageable?lang=${language}`,
        dataCSV
      );

      setErrors(data.content.elements);
      setTotalItemsAddress(data.totalElements);
      setTotalPagesAddress(data.totalPages);

      elements = data.content;

      if (!isSelectAll) {
        setShowModalAddress(true);
      } else if (elements.elements.length > 0) {
        // onCheckBoxHandler(
        //   elements.elements[0],
        //   0,
        //   true,
        //   selectedRows,
        //   elements.elements
        // );
      }
      setLoading(false);
    },
    [totalItems, typeDelivery]
  );

  function getIndexElements(elementsId: number) {
    const nanoIndexesElements = arrElements
      .map((item: any, i: number) =>
        item.columns[0].data === elementsId ? i : -1
      )
      .filter((index: number) => index !== -1);

    return nanoIndexesElements[0];
  }

  const setAddressColumn = useCallback(
    async (
      dataArr,
      data,
      columnsData,
      indexElements,
      typeAddress,
      isMultipleLine,
      addressId,
      dataAddressModify,
      dataAddress,
      addressNoLocation
    ) => {
      setLoading(true);
      // if (isTable === false) {
      //   setShowModalAddress(true);
      // }
      let dataChange = isTable ? dataArr : errors;
      let indexesClone = isTable ? dataArr : errors;

      const nanoIndexesAddress = dataChange
        .map((item: any, i: number) =>
          item.columns[0].data === addressId ? i : -1
        )
        .filter((index: number) => index !== -1);
      const getIndexAddress = nanoIndexesAddress[0];

      const nanoIndexes =
        isTable === false
          ? data[0].columns
              .map((item: any, i: number) =>
                item.id === 'address1' ||
                item.id === 'addressNumber' ||
                item.id === 'addressNeightborhood' ||
                item.id === 'addressCity' ||
                item.id === 'addressState' ||
                item.id === 'addressZipcode' ||
                item.id === 'latitude' ||
                item.id === 'longitude'
                  ? i
                  : -1
              )
              .filter((index: number) => index !== -1)
          : data
              .map((item: any, i: number) =>
                item.id === 'address1' ||
                item.id === 'addressNumber' ||
                item.id === 'addressNeightborhood' ||
                item.id === 'addressCity' ||
                item.id === 'addressState' ||
                item.id === 'addressZipcode' ||
                item.id === 'latitude' ||
                item.id === 'longitude'
                  ? i
                  : -1
              )
              .filter((index: number) => index !== -1);
      const nanoIndexesPickup =
        isTable === false
          ? data[0].columns
              .map((item: any, i: number) =>
                item.id === 'address1Pickup' ||
                item.id === 'addressNumberPickup' ||
                item.id === 'addressNeightborhoodPickup' ||
                item.id === 'addressCityPickup' ||
                item.id === 'addressStatePickup' ||
                item.id === 'addressZipcodePickup' ||
                item.id === 'latitudePickup' ||
                item.id === 'longitudePickup'
                  ? i
                  : -1
              )
              .filter((index: number) => index !== -1)
          : data
              .map((item: any, i: number) =>
                item.id === 'address1Pickup' ||
                item.id === 'addressNumberPickup' ||
                item.id === 'addressNeightborhoodPickup' ||
                item.id === 'addressCityPickup' ||
                item.id === 'addressStatePickup' ||
                item.id === 'addressZipcodePickup' ||
                item.id === 'latitudePickup' ||
                item.id === 'longitudePickup'
                  ? i
                  : -1
              )
              .filter((index: number) => index !== -1);

      const typeIndexes =
        typeAddress === 'delivery' ? nanoIndexes : nanoIndexesPickup;

      const getElementsIndexes = indexesClone.map(
        (itemElements: any, indexElements: number) => {
          return {
            index: indexElements,
            ...itemElements,
          };
        }
      );
      const getElementsByColumns =
        isTable === false
          ? getElementsIndexes.filter(
              (itemElement: any, indexElement: number) =>
                itemElement.columns[typeIndexes[0]].data ===
                  data[0].columns[typeIndexes[0]].data &&
                itemElement.columns[typeIndexes[1]].data ===
                  data[0].columns[typeIndexes[1]].data &&
                itemElement.columns[typeIndexes[2]].data ===
                  data[0].columns[typeIndexes[2]].data &&
                itemElement.columns[typeIndexes[3]].data ===
                  data[0].columns[typeIndexes[3]].data &&
                itemElement.columns[typeIndexes[4]].data ===
                  data[0].columns[typeIndexes[4]].data &&
                itemElement.columns[typeIndexes[5]].data ===
                  data[0].columns[typeIndexes[5]].data
            )
          : getElementsIndexes.filter(
              (itemElement: any, indexElement: number) =>
                itemElement.columns[typeIndexes[0]].data ===
                  data[typeIndexes[0]].data &&
                itemElement.columns[typeIndexes[1]].data ===
                  data[typeIndexes[1]].data &&
                itemElement.columns[typeIndexes[2]].data ===
                  data[typeIndexes[2]].data &&
                itemElement.columns[typeIndexes[3]].data ===
                  data[typeIndexes[3]].data &&
                itemElement.columns[typeIndexes[4]].data ===
                  data[typeIndexes[4]].data &&
                itemElement.columns[typeIndexes[5]].data ===
                  data[typeIndexes[5]].data
            );

      let getMappingColumns = [];

      let cloneData = columnsData;
      if (String(cloneData[5].dataValue).includes('-') === false) {
        cloneData[5].dataValue = `${String(cloneData[5].dataValue).substring(
          0,
          5
        )}-${String(cloneData[5].dataValue).substring(5)}`;
      }

      if (isMultipleLine === 'MULTIPLE') {
        const setValue = getElementsByColumns.map(
          (itemRow: any, indexRow: number) => {
            typeIndexes.map((itemColumn: any, indexColumn: number) => {
              columnsData.forEach(
                (itemColumnData: any, indexColumnData: number) => {
                  dataChange[itemRow.index].columns[itemColumn].data =
                    columnsData[indexColumn].dataValue;

                  if (typeAddress === 'delivery') {
                    dataChange[itemRow.index].columns[9].data = 100;
                  } else {
                    dataChange[itemRow.index].columns[32].data = 100;
                  }

                  getElementsByColumns[indexRow].columns[itemColumn].data =
                    columnsData[indexColumn].dataValue;
                }
              );
            });
          }
        );

        getMappingColumns = getElementsByColumns.map(
          (itemMappingColumns: any) => {
            return {
              id: itemMappingColumns.columns[0].data,
              element: {
                columns: itemMappingColumns.columns,
              },
            };
          }
        );
      } else if (isMultipleLine === 'SINGLE') {
        const setValue = typeIndexes.map(
          (itemColumn: any, indexColumn: number) => {
            columnsData.forEach(
              (itemColumnData: any, indexColumnData: number) => {
                if (isTable === false) {
                  dataChange[getIndexAddress].columns[itemColumn].data =
                    columnsData[indexColumn].dataValue;
                } else {
                  dataChange[indexElements].columns[itemColumn].data =
                    columnsData[indexColumn].dataValue;
                }
              }
            );
          }
        );

        if (typeAddress === 'delivery') {
          if (isTable === false) {
            dataChange[getIndexAddress].columns[9].data = 100;
          } else {
            dataChange[indexElements].columns[9].data = 100;
          }
        } else if (isTable === false) {
          dataChange[getIndexAddress].columns[32].data = 100;
        } else {
          dataChange[indexElements].columns[32].data = 100;
        }

        getMappingColumns.push({
          id: data[0].data,
          element: {
            columns: data,
          },
        });
      }

      const response = await api.put(
        '/delivery/csvMapping/lineValidation',
        getMappingColumns
      );

      setArrElements(dataChange);
      setType('');

      let openModal = isTable;
      setIsTable(openModal);

      getElementsCSV(currentPage, null);

      setShowModalAddress(false);
      setAddressNoLocation(false);

      setLoading(false);
    },
    [isTable, errors]
  );

  const handleFilter = useCallback(
    (
      e: any,
      arrColumn,
      dataFilterValue,
      operationValue,
      indexFilter,
      arrFilterElement,
      isOperationFilter
    ) => {
      let activeHeader = arrColumn;
      const filters = sessionStorage.getItem('filters');
      let filterColumn: any[] = [];
      if (filters) {
        filterColumn = JSON.parse(filters);
      }
      let dataFormatType: any;

      const textValue = e.target.value;
      console.log(textValue);

      if (dataFilterValue.type === 'DATE' && textValue.length > 0) {
        dataFormatType = new Date(textValue);
        dataFormatType.setDate(dataFormatType.getDate() + 1);
        dataFormatType = format(dataFormatType, 'yyyy-MM-dd');
      }

      let valueFilterColumn = '';

      if (isOperationFilter) {
        valueFilterColumn =
          dataFilterValue.type === 'NUMBER'
            ? Number(textValue)
            : dataFilterValue.type === 'DATE'
            ? dataFormatType
            : '';
      } else {
        valueFilterColumn =
          dataFilterValue.type === 'NUMBER'
            ? Number(textValue)
            : dataFilterValue.type === 'DATE'
            ? dataFormatType
            : textValue;
      }
      console.log(dataFilterValue);

      activeHeader[dataFilterValue.index].active = true;

      const duplicatedFilters = filterColumn.find(
        item => item.field === dataFilterValue.field
      );
      console.log('Filters Duplicated', duplicatedFilters);
      console.log('Filters Duplicated', valueFilterColumn);
      if (valueFilterColumn.length === 0) {
        const nanoIndexesFilter = filterColumn
          .map((item, i) => (item.field === dataFilterValue.field ? i : -1))
          .filter(index => index !== -1);

        const nanoIndexesHeader = activeHeader
          .map((item: any, i: number) =>
            item.field === dataFilterValue.field ? i : -1
          )
          .filter((index: any) => index !== -1);

        // console.log('Field', filterColumn[nanoIndexesFilter[indexFilter]]);

        filterColumn.splice(nanoIndexesFilter[0], 1);
        activeHeader[nanoIndexesHeader[0]].active = false;
        // sessionStorage.setItem('filters', JSON.stringify(filterColumn));
      } else if (duplicatedFilters && valueFilterColumn.length > 0) {
        const nanoIndexes = filterColumn
          .map((item, i) => (item.field === dataFilterValue.field ? i : -1))
          .filter(index => index !== -1);

        filterColumn[nanoIndexes[indexFilter]].value = valueFilterColumn;
        filterColumn[nanoIndexes[indexFilter]].operation = `${operationValue}`;

        sessionStorage.setItem('filters', JSON.stringify(filterColumn));
      } else if (valueFilterColumn.length > 0) {
        const valueFilter = valueFilterColumn;
        const dataFilter = {
          field: dataFilterValue.field,
          value: valueFilter,
          operation: operationValue,
        };

        filterColumn.push(dataFilter);

        sessionStorage.setItem('filters', JSON.stringify(filterColumn));
      }
      setArrHeaders(activeHeader);
    },

    []
  );

  const getCheckAddAll = useCallback((data: any) => {
    const updateRows = data.map((item: any) => {
      return {
        ...item,
        activeAll: true,
        active: true,
      };
    });

    sessionStorage.setItem('selectedRows', JSON.stringify(updateRows));

    setSelectedRows(updateRows);

    setArrElements(updateRows);

    updateRows.map((item: any) => {
      addTotalityAccumulator(item, updateRows, true, '');
    });

    getCheckAddItem(updateRows);
  }, []);

  const getElementsAll = useCallback(
    async (isSelectAll: boolean, pageSelect: number, dataElements: any) => {
      setLoading(true);

      const data = {
        action: 'ADD_ALL',
      };

      const response = await api.post('/delivery/csvMapping/check/item', data);

      getCheckAddAll(dataElements);

      // setArrFilter(dataAddressAll);

      // setSelectedRows(newValue);
      // filterActive.map(item => {
      //   addTotalityAccumulator(item, newValue, true, '');
      // });

      setLoading(false);
    },
    [totalItems]
  );

  const findAddress = useCallback((data, row) => {
    let addresses = [];

    setLoading(true);

    const mappingColumn = {
      logradouro: `${data.rua}`,
      numero: data.numero,
      bairro: data.bairro,
      cidade: data.cidade,
      estado: data.estado,
      complemento: data.complemento,
      cep: data.cep,
      latitude: data.lat,
      longitude: data.lng,
    };
    setType('delivery');
    addresses.push(mappingColumn);
    setDataLocation(addresses);
    setIsTable(false);
    setDataColumns(row);
    setModalLocal(true);
    setAddressNoLocation(true);
    setLoading(false);
  }, []);

  const findColumnsAddress = useCallback(
    (data: any, indexRow: number, typeFind: string) => {
      const findColumn = data.filter(
        (item: any) =>
          item.id === 'address1' ||
          item.id === 'address2' ||
          item.id === 'addressNumber' ||
          item.id === 'addressNeightborhood' ||
          item.id === 'addressCity' ||
          item.id === 'addressState' ||
          item.id === 'addressZipcode' ||
          item.id === 'latitude' ||
          item.id === 'longitude'
      );

      const findColumnPickup = data.filter(
        (item: any) =>
          item.id === 'address1Pickup' ||
          item.id === 'address2Pickup' ||
          item.id === 'addressNumberPickup' ||
          item.id === 'addressNeightborhoodPickup' ||
          item.id === 'addressCityPickup' ||
          item.id === 'addressStatePickup' ||
          item.id === 'addressZipcodePickup' ||
          item.id === 'latitudePickup' ||
          item.id === 'longitudePickup'
      );

      const typeFindColumn =
        typeFind === 'delivery' ? findColumn : findColumnPickup;

      const mappingColumn = typeFindColumn.map((item: any) => {
        return {
          logradouro: `${typeFindColumn[0].data}`,
          numero: typeFindColumn[2].data,
          complemento: typeFindColumn[1].data,
          bairro: typeFindColumn[3].data,
          cidade: typeFindColumn[4].data,
          estado: typeFindColumn[5].data,
          cep: typeFindColumn[6].data,
          latitude: typeFindColumn[7].data,
          longitude: typeFindColumn[8].data,
        };
      });

      setType(typeFind);

      setDataLocation(mappingColumn);
      setDataColumns(data);
      setModalLocal(true);
    },
    []
  );

  const getElementsCSVRemoved = useCallback(async () => {
    try {
      const dataCSV = {
        filters: [
          {
            field: 'filial',
            value: [Number(sessionStorage.getItem('subsidiary'))],
            operation: 'IN',
          },
        ],
        orders: [],
        page: 0,
        size: 1000,
      };

      const responseMap = await api.post(
        `/delivery/csvMapping/pageable`,
        dataCSV
      );

      if (responseMap.data.content.elements.length === 0) {
        history.push('/uploadCSV');
        window.location.reload();
      }
    } catch (err) {}
  }, []);

  const getElementsCSV = useCallback(
    async (pageSelected: any, updateSubsidiary: any) => {
      try {
        setLoading(true);
        const filters = sessionStorage.getItem('filters');

        let currentFilters: any[] = [];

        if (filters) {
          currentFilters = JSON.parse(filters);
        }

        console.log('CurrentFilters', currentFilters);

        const isPresentSubsidiary = currentFilters.find(
          (item: any) => item.field === 'filial'
        );

        const findIndexSubsidiary = currentFilters
          .map((itemUpdate: any, indexUpdate: number) =>
            itemUpdate.field === 'filial' ? indexUpdate : -1
          )
          .filter((itemFilterUpdate: any) => itemFilterUpdate !== -1);

        if (findIndexSubsidiary.length > 0) {
          currentFilters[findIndexSubsidiary[0]].value = [
            Number(
              updateSubsidiary !== null
                ? updateSubsidiary
                : sessionStorage.getItem('subsidiary')
            ),
          ];
        } else if (!isPresentSubsidiary) {
          currentFilters.push({
            field: 'filial',
            value: [
              Number(
                updateSubsidiary !== null
                  ? updateSubsidiary
                  : sessionStorage.getItem('subsidiary')
              ),
            ],
            operation: 'IN',
          });
        }

        const language =
          navigator.language === 'pt' || 'pt-BR' ? 'pt-Br' : 'en-US';
        const currentOrders = [
          {
            field: 'ordemEntrega',
            direction: 'ASC',
          },
          {
            field: 'id',
            direction: 'ASC',
          },
        ];
        const dataCSV = {
          filters: currentFilters,
          orders: currentOrders,
          page: pageSelected || 0,
          size: 10,
        };
        const response = await api.post(
          `/delivery/csvMapping/pageable?lang=${language}`,
          dataCSV
        );
        setArrElements(response.data.content.elements);
        setTotalPages(response.data.totalPages);
        setTotalItems(response.data.totalElements);
        if (
          response.data.content &&
          response.data.content.elements.length > 0
        ) {
          getCheckAddItem(response.data.content.elements);
        }
        setLoading(false);
      } catch {}
    },
    []
  );

  const addTotalityAccumulator = useCallback(
    (dataTotality: any, dataArr: any, allRows: any, type: any) => {
      const calcValueMapping = dataTotality.columns
        .map((item: any, i: number) =>
          item.id === 'cubage' || item.id === 'weight' || item.id === 'value'
            ? i
            : -1
        )
        .filter((index: number) => index !== -1);

      let valueVolume;
      let valueWeight;
      let valuePrice;

      if (type.id === 'cubage') {
        valueVolume =
          dataTotality.columns[calcValueMapping[0]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[0]].data !== null
            ? dataTotality.columns[calcValueMapping[0]].data
            : 0;
      } else if (type.id === 'weight') {
        valueWeight =
          dataTotality.columns[calcValueMapping[1]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[1]].data !== null
            ? dataTotality.columns[calcValueMapping[1]].data
            : 0;
      } else if (type.id === 'value') {
        valuePrice =
          dataTotality.columns[calcValueMapping[2]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[2]].data !== null
            ? dataTotality.columns[calcValueMapping[2]].data
            : 0;
      } else {
        valueWeight =
          dataTotality.columns[calcValueMapping[1]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[1]].data !== null
            ? dataTotality.columns[calcValueMapping[1]].data
            : 0;
        valueVolume =
          dataTotality.columns[calcValueMapping[0]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[0]].data !== null
            ? dataTotality.columns[calcValueMapping[0]].data
            : 0;
        valuePrice =
          dataTotality.columns[calcValueMapping[2]].data.length > 0 &&
          dataTotality.columns[calcValueMapping[2]].data !== null
            ? dataTotality.columns[calcValueMapping[2]].data
            : 0;
      }

      dispatch(
        addAccumulatorRouteringCargas(
          1,
          parseFloat(valueWeight),
          parseFloat(valueVolume),
          parseFloat(valuePrice)
        )
      );

      dispatch(getPorcentageAccumulator());
    },
    []
  );

  const removeTotalityAccumulator = useCallback(
    (dataTotality, dataArr, allRows) => {
      const calcValueMapping = dataTotality.columns
        .map((item: any, i: number) =>
          item.id === 'cubage' || item.id === 'weight' || item.id === 'value'
            ? i
            : -1
        )
        .filter((index: number) => index !== -1);
      let valueWeight =
        dataTotality.columns[calcValueMapping[1]].data.length > 0 &&
        dataTotality.columns[calcValueMapping[1]].data !== null
          ? dataTotality.columns[calcValueMapping[1]].data
          : 0;
      let valueVolume =
        dataTotality.columns[calcValueMapping[0]].data.length > 0 &&
        dataTotality.columns[calcValueMapping[0]].data !== null
          ? dataTotality.columns[calcValueMapping[0]].data
          : 0;
      let valuePrice =
        dataTotality.columns[calcValueMapping[2]].data.length > 0 &&
        dataTotality.columns[calcValueMapping[2]].data !== null
          ? dataTotality.columns[calcValueMapping[2]].data
          : 0;

      dispatch(
        removeAccumulatorRouteringCargas(
          1,
          parseFloat(valueWeight),
          parseFloat(valueVolume),
          parseFloat(valuePrice)
        )
      );

      dispatch(getPorcentageAccumulator());
    },
    []
  );

  const addServiceLoad = useCallback(
    (dataSelectedRows: any, dataService: any, allRowsLoad: any) => {
      const isPresentLoad = dataSelectedRows
        .map((item: any, i: number) =>
          item.columns[0].data === dataService.columns[0].data ? i : -1
        )
        .filter((index: number) => index !== -1);

      if (isPresentLoad.length === 0) {
        console.log('entrou');
        const data = sessionStorage.getItem('selectedRows');
        if (data) {
          console.log('entrou json');
          const selectRows: any[] = JSON.parse(data);

          selectRows.push(dataService);
          sessionStorage.setItem('selectedRows', JSON.stringify(selectRows));
        }

        setSelectedRows((prevState: any) => {
          return [...prevState, dataService];
        });
        addTotalityAccumulator(dataService, dataSelectedRows, allRowsLoad, '');
      }
    },
    [addTotalityAccumulator]
  );

  const removeServiceLoad = useCallback(
    (dataSelectedRows, dataService, allRowsLoad) => {
      let arrSelected = sessionStorage.getItem('selectedRows');
      if (arrSelected) {
        const selectedRow: any[] = JSON.parse(arrSelected);
        const isPresentLoad = selectedRow
          .map((item: any, i: number) =>
            item.columns[0].data === dataService.columns[0].data ? i : -1
          )
          .filter((index: number) => index !== -1);
        if (isPresentLoad.length > 0) {
          selectedRow.splice(isPresentLoad[0], 1);
          sessionStorage.setItem('selectedRows', JSON.stringify(selectedRow));
          // arrSelected.splice(isPresentLoad[0], 1);
          setSelectedRows(selectedRow);
          removeTotalityAccumulator(dataService, selectedRow, allRowsLoad);
        }
      }
    },
    [removeTotalityAccumulator]
  );

  const onCheckBoxHandler = useCallback(
    (dataCheck, indexCheck, allRows, rowsSelected, dataElementCheck) => {
      const updateValueRows = dataElementCheck.map((check: any, i: number) => {
        if (allRows === true) {
          return {
            ...check,
            activeAll: !check.activeAll,
            active: check.activeAll !== true,
          };
        }
        if (i === indexCheck) {
          return {
            ...check,
            active: check.active !== true,
          };
        }
        return check;
      });

      setArrElements(updateValueRows);

      if (updateValueRows[indexCheck].activeAll && allRows === true) {
        updateValueRows.map((item: any) => {
          addServiceLoad(rowsSelected, item, allRows);
        });
      } else if (updateValueRows[indexCheck].active && allRows === false) {
        if (updateValueRows[indexCheck].active) {
          addServiceLoad(rowsSelected, updateValueRows[indexCheck], allRows);
        } else {
          removeServiceLoad(rowsSelected, updateValueRows[indexCheck], allRows);
        }
      } else {
        const findLoadsActive = updateValueRows.find(
          (itemActive: any) => itemActive.active === true
        );
        if (findLoadsActive) {
          removeServiceLoad(rowsSelected, updateValueRows[indexCheck], allRows);
        } else {
          updateValueRows.map((item: any) => {
            removeServiceLoad(rowsSelected, item, allRows);
          });
        }
      }
    },
    []
  );

  const getCheckAddItem = useCallback(async data => {
    try {
      setLoading(true);

      const response = await api.get('/delivery/csvMapping/check/item');

      dispatch(resetCargasAccumulator());
      // dispatch(resetVehiclesAccumulator());
      // dispatch(clearVehiclesSelected());
      sessionStorage.setItem('selectedRows', JSON.stringify([]));
      // setSelectedRows([]);

      dispatch(
        updateAccumulatorRoutering(
          response.data.metric.capacity,
          response.data.metric.weight,
          response.data.metric.cubage,
          response.data.metric.value
        )
      );

      dispatch(getPorcentageAccumulator());

      const updateMappingRows = data.map((item: any) => {
        if (
          response.data.ids.find(
            (itemCheck: any) => itemCheck === item.columns[0].data
          )
        ) {
          return {
            ...item,
            active: true,
            activeAll: response.data.ids.length >= data.length ? true : false,
          };
        }
        return item;
      });

      const filterMappingRows = updateMappingRows.filter(
        (item: any) => item.active === true
      );

      setArrElements(updateMappingRows);

      setSelectedRows(filterMappingRows);

      sessionStorage.setItem('selectedRows', JSON.stringify(filterMappingRows));

      setLoading(false);
    } catch {}
  }, []);

  const deleteRow = useCallback(async () => {
    try {
      let response: any;
      if (removeConfirmationAll) {
        await api.patch(`/delivery/conhecimentosPre/inactive/all/${sessionStorage.getItem('subsidiary')}`);
      } else {
        let rowsDelete = arrElements;

        const arrFind = rowsDelete
          .filter((item: any) => item.active === true)
          .map((itemFilter: any) => {
            return itemFilter.columns[0].data;
          });

        await api.post('/delivery/conhecimentosPre/inativaIds', arrFind);
      }

      dispatch(resetValueAccumulator());

      setSelectedRows([]);

      setRemoveConfirmation(false);
      setRemoveConfirmationAll(false);

      const dataFilterSelectedRows = sessionStorage.getItem('selectedRows');

      if (dataFilterSelectedRows) {
        const selectRows = JSON.parse(dataFilterSelectedRows);
        onCheckAddItem(selectRows, arrElements);
        getElementsCSV(0, null);
      }

      getElementsCSVRemoved();
    } catch (err) {}
  }, [arrElements, removeConfirmationAll, totalItems]);

  const onCheckAddItem = useCallback(
    async (dataSelected: any, dataArr: any) => {
      try {
        setLoading(true);

        const getAddIds = dataSelected.map((itemSelected: any) => {
          return itemSelected.columns[0].data;
        });

        const getRemoveIds = dataArr
          .filter(
            (itemFilterSelected: any) => itemFilterSelected.active !== true
          )
          .map((itemSelected: any) => {
            return itemSelected.columns[0].data;
          });

        const data = {
          addIds: getAddIds,
          removeIds: getRemoveIds,
        };

        const response = await api.post(
          '/delivery/csvMapping/check/item',
          data
        );
        setLoading(false);
      } catch {}
    },
    []
  );

  // const onCheckRemoveItem = useCallback(
  //   async (dataSelected: any, dataArr: any) => {
  //     try {
  //       setLoading(true);

  //       const mappingSelectedRows = dataArr
  //         .filter(
  //           (itemFilterSelected: any) => itemFilterSelected.active !== true
  //         )
  //         .map((itemSelected: any) => {
  //           return itemSelected.columns[0].data;
  //         });

  //       const data = {
  //         action: 'REMOVE',
  //         ids: mappingSelectedRows,
  //       };

  //       const response = await api.post(
  //         '/delivery/csvMapping/check/item',
  //         data
  //       );
  //       setLoading(false);
  //     } catch {}
  //   },
  //   []
  // );

  const onChangeService = useCallback(
    (dataChangeService, indexRow, indexColumnService, value, dataColumn) => {
      let dataChange = dataChangeService;

      dataChange[indexRow].columns[indexColumnService].data = value;

      const dataInput = {
        headers: dataColumn,
        elements: dataChange,
      };

      setArrElements(dataChange);
    },
    []
  );

  const updateColumnsError = useCallback(
    async (info, indexRow, idColumn2, elementRowsSelected) => {
      try {
        const dataFilterSelectedRows = sessionStorage.getItem('selectedRows');
        let dataChange = info;

        const dataUpdate = [
          {
            id: idColumn2,
            element: {
              columns: info[indexRow].columns,
            },
          },
        ];

        const { data } = await api.put(
          '/delivery/csvMapping/lineValidation',
          dataUpdate
        );

        dataChange[indexRow].columns = data[0].elements[0].columns;

        if (dataFilterSelectedRows) {
          const selectRows = JSON.parse(dataFilterSelectedRows);
          onCheckAddItem(selectRows, arrElements);
          // onCheckRemoveItem(selectRows, arrElements);
          getElementsCSV(currentPage, null);
        }

        // getElementsCSV([], currentPage, null);
      } catch (err) {}
    },
    [getElementsCSV, arrElements]
  );

  const updateTotalityAccumulator = useCallback(
    (select, dataUpdate, value, indexUpdated) => {
      let findWeightValue = dataUpdate
        .map((itemUpdate: any, indexUpdate: number) =>
          itemUpdate.id === 'weight' ? indexUpdate : -1
        )
        .filter((itemFilterUpdate: any) => itemFilterUpdate !== -1);
      let findPriceValue = dataUpdate
        .map((itemUpdate: any, indexUpdate: number) =>
          itemUpdate.id === 'value' ? indexUpdate : -1
        )
        .filter((itemFilterUpdate: any) => itemFilterUpdate !== -1);
      let findVolumeValue = dataUpdate
        .map((itemUpdate: any, indexUpdate: number) =>
          itemUpdate.id === 'cubage' ? indexUpdate : -1
        )
        .filter((itemFilterUpdate: any) => itemFilterUpdate !== -1);

      const currentValueTotality =
        value.length > 0 && value !== null ? value : 0;

      dispatch(
        removeAccumulatorRouteringCargas(
          1,
          parseFloat(
            indexUpdated === findWeightValue[0] ? currentValueTotality : 0
          ),
          parseFloat(
            indexUpdated === findVolumeValue[0] ? currentValueTotality : 0
          ),
          parseFloat(
            indexUpdated === findPriceValue[0] ? currentValueTotality : 0
          )
        )
      );

      dispatch(
        addAccumulatorRouteringCargas(
          1,
          parseFloat(
            indexUpdated === findWeightValue[0]
              ? dataUpdate[findWeightValue[0]].data
              : 0
          ),

          parseFloat(
            indexUpdated === findVolumeValue[0]
              ? dataUpdate[findVolumeValue[0]].data
              : 0
          ),
          parseFloat(
            indexUpdated === findPriceValue[0]
              ? dataUpdate[findPriceValue[0]].data
              : 0
          )
        )
      );
      dispatch(getPorcentageAccumulator());
    },
    []
  );

  const onCheckRemoveAll = useCallback(async () => {
    try {
      setLoading(true);

      const data = {
        action: 'REMOVE_ALL',
      };

      const response = await api.post('/delivery/csvMapping/check/item', data);

      getElementsCSV(currentPage, null);

      setLoading(false);
    } catch {}
  }, []);

  const toggleRemove = () => {
    setRemoveConfirmation(false);
    setRemoveConfirmationAll(false);
  };

  const paginate = (pageNumber: number) => {
    setPageIndex(pageNumber - 1);
    getElements(false, pageNumber - 1, typeDelivery);
  };

  useEffect(() => {
    getElementsCSV(currentPage, null);
    sessionStorage.setItem('filters', JSON.stringify([]));
  }, [getElementsCSV]);

  useEffect(() => {
    getSubsidiary();
    getParametersRoutering();
  }, [getSubsidiary, getParametersRoutering]);

  return (
    <div>
      <TopBar
        listSubsidiary={subsidiary}
        onChangeSubsidiary={(value: any) => getElementsCSV(currentPage, value)}
        blockedSubsidiary={
          selectedRows.length > 0 || vehiclesData.length > 0
            ? 'blocked'
            : 'unblocked'
        }
      />

      <Loading loading={loading} />

      {showModalAddress && (
        <ModalAddress
          confirmAddress={(e: any, row: any, idAddress: any) => {
            setModifyAddressError(idAddress);
            findAddress(e, row);
            setIsTable(false);
          }}
          onChangeTypeDelivery={(type: any) => {
            getElements(false, currentPage, type);
            setTypeDelivery(type);
          }}
          isOpen={showModalAddress}
          elements={errors}
          totalItems={totalItemsAddress}
          totalPagesAddress={totalPagesAddress}
          onPaginateAddress={(pageAddress: number) => paginate(pageAddress)}
          onClose={() => setShowModalAddress(false)}
        />
      )}

      {modalCargo && (
        <ModalCreatedCargo
          onClear={() => {
            setModalCargo(!modalCargo);
            getElementsCSV(currentPage, null);
          }}
          data={dataCargo}
        />
      )}

      <ModalDelete
        isOpen={removeConfirmation || removeConfirmationAll}
        onCancel={toggleRemove}
        onConfirm={() => deleteRow()}
      />

      {modalLocal && (
        <SearchAddress
          onClear={() => setModalLocal(!modalLocal)}
          data={dataLocation}
          columns={dataColumnsLocation}
          onEditColumns={(
            data: any,
            valueColumn: any,
            isMultipleRows: boolean
          ) =>
            setAddressColumn(
              arrElements,
              data,
              valueColumn,
              elementsIndex,
              type,
              isMultipleRows,
              modifyAddressError,
              arrDataAddress,
              currentPage,
              addressNoLocation
            )
          }
        />
      )}

      <div className="containerText">
        <Table
          vehiclesData={vehiclesData}
          dataElements={arrElements}
          dataFiltersElements={sessionStorage.getItem('filters')}
          parameters={parameters}
          totalPages={totalPages}
          totalElements={totalItems}
          dataHeaders={arrData.headers}
          onChangeVehiclesTable={() => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectedRows, arrElements);
              // onCheckRemoveItem(selectedRows, arrElements);
            }
            getElementsCSV(0, null);
          }}
          onUpdateShipmentSelected={() => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectRows, arrElements);
              // onCheckRemoveItem(selectRows, arrElements);
              getElementsCSV(0, null);
            }
          }}
          onClearTable={(filtersClear: any[]) => {
            sessionStorage.setItem('filters', JSON.stringify([]));

            getElementsCSV(currentPage, null);
          }}
          onFilterSelected={(idsSelected: any[]) => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );
            const filters = sessionStorage.getItem('filters');
            let filterSelected: any[] = [];
            if (filters) {
              filterSelected = JSON.parse(filters);
            }

            const nanoIndexesFilter = filterSelected
              .map((item, i) => (item.field === 'id' ? i : -1))
              .filter(index => index !== -1);

            if (nanoIndexesFilter.length === 0) {
              const dataFilter = {
                field: 'id',
                value: idsSelected,
                operation: 'IN',
              };

              filterSelected.push(dataFilter);

              sessionStorage.setItem('filters', JSON.stringify(filterSelected));

              if (dataFilterSelectedRows) {
                const selectRows = JSON.parse(dataFilterSelectedRows);
                onCheckAddItem(selectRows, arrElements);
                // onCheckRemoveItem(selectRows, arrElements);
                getElementsCSV(0, null);
              }

              setCurrentPage(0);
            } else {
              filterSelected.splice(nanoIndexesFilter[0], 1);

              sessionStorage.setItem('filters', JSON.stringify(filterSelected));

              if (dataFilterSelectedRows) {
                const selectRows = JSON.parse(dataFilterSelectedRows);
                onCheckAddItem(selectRows, arrElements);
                // onCheckRemoveItem(selectRows, arrElements);
                getElementsCSV(currentPage, null);
              }
              setCurrentPage(0);
            }
          }}
          onFilterError={(text: string) => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );
            const filters = sessionStorage.getItem('filters');
            let filterError: any[] = [];
            if (filters) {
              filterError = JSON.parse(filters);
            }

            const nanoIndexesFilter = filterError
              .map((item, i) => (item.field === 'isErro' ? i : -1))
              .filter(index => index !== -1);

            if (nanoIndexesFilter.length === 0) {
              const dataFilter = {
                field: 'isErro',
                value: text === 'Sim' ? true : false,
                operation: 'EQUAL',
              };

              filterError.push(dataFilter);

              sessionStorage.setItem('filters', JSON.stringify(filterError));

              if (dataFilterSelectedRows) {
                const selectRows = JSON.parse(dataFilterSelectedRows);
                onCheckAddItem(selectRows, arrElements);
                // onCheckRemoveItem(selectRows, arrElements);
                getElementsCSV(currentPage, null);
              }
              setCurrentPage(0);
            } else {
              if (text.length === 0) {
                filterError.splice(nanoIndexesFilter[0], 1);
                sessionStorage.setItem('filters', JSON.stringify(filterError));
                if (dataFilterSelectedRows) {
                  const selectRows = JSON.parse(dataFilterSelectedRows);
                  onCheckAddItem(selectRows, arrElements);
                  // onCheckRemoveItem(selectRows, arrElements);
                  getElementsCSV(currentPage, null);
                }
                setCurrentPage(0);
              } else {
                filterError[nanoIndexesFilter[0]].value =
                  text === 'Sim' ? true : false;

                sessionStorage.setItem('filters', JSON.stringify(filterError));

                if (dataFilterSelectedRows) {
                  const selectRows = JSON.parse(dataFilterSelectedRows);
                  onCheckAddItem(selectRows, arrElements);
                  // onCheckRemoveItem(selectRows, arrElements);
                  getElementsCSV(currentPage, null);
                }
                setCurrentPage(0);
              }
            }

            // setArrElements(data);
          }}
          onPaginate={(page: number) => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            setCurrentPage(page - 1);

            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectedRows, arrElements);
              // onCheckRemoveItem(selectedRows, arrElements);
            }
            getElementsCSV(page - 1, null);
          }}
          onCheckAdd={(
            dataRow: any,
            indexRow: number,
            allRows: boolean,
            data: any
          ) => {
            const dataSelected = sessionStorage.getItem('selectedRows');
            if (dataSelected) {
              const selectRows = JSON.parse(dataSelected);
              onCheckBoxHandler(dataRow, indexRow, allRows, selectRows, data);
            }
          }}
          onUpdateSelected={() => {}}
          onExecuteFilterMultiple={() => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );
            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectRows, arrElements);
              // onCheckRemoveItem(selectRows, arrElements);
              getElementsCSV(currentPage, null);
            }
            setCurrentPage(0);
          }}
          onUpdateSequence={(dataSequence: any[]) =>
            setArrElements(dataSequence)
          }
          onUpdateAddressTable={(
            valueColumn: number,
            dataColumns: any,
            indexRow: number,
            columnId: string
          ) => {
            setElementsIndex(getIndexElements(valueColumn));
            findColumnsAddress(
              dataColumns,
              indexRow,
              columnId === 'accuracyStatus' ? 'delivery' : 'pickup'
            );
          }}
          onUpdateRowTable={(
            dataElementsTable: any,
            indexRow: number,
            indexColumn: number,
            valueRow: any,
            columns: any,
            dataColumnTable: any,
            isActive?: boolean
          ) => {
            onChangeService(
              dataElementsTable,
              indexRow,
              indexColumn,
              valueRow,
              dataColumnTable
            );
            updateColumnsError(
              dataElementsTable,
              indexRow,
              columns[0].data,
              selectedRows
            );
            if (isActive) {
              updateTotalityAccumulator(
                selectedRows,
                columns,
                valueRow,
                indexColumn
              );
            }
          }}
          onFilter={(
            text: string | number,
            dataHeader: any,
            dataColumn: any,
            operationValue: any,
            indexFilter: number,
            elements: any
          ) => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            // onCheckAddItem(selectedRows, arrElements);
            // onCheckRemoveItem(selectedRows, arrElements);
            handleFilter(
              text,
              dataHeader,
              dataColumn,
              operationValue,
              indexFilter,
              elements,
              false
            );

            // if (dataFilterSelectedRows) {
            //   const selectRows = JSON.parse(dataFilterSelectedRows);
            //   onCheckAddItem(selectRows, arrElements);
            //   onCheckRemoveItem(selectRows, arrElements);
            //   getElementsCSV(arrFilters, currentPage, null);
            // }

            // setCurrentPage(0);
          }}
          onOperationFilter={(
            text: string | number,
            dataHeader: any,
            dataColumn: any,
            operationValue: any,
            indexFilter: number,
            elements: any
          ) => {
            // handleFilter(
            //   text,
            //   dataHeader,
            //   dataColumn,
            //   operationValue,
            //   indexFilter,
            //   elements,
            //   true
            // );
            // getElementsCSV(filters, 0);
          }}
          onChangeFilters={(dataFilters: any) => {
            sessionStorage.setItem('filters', JSON.stringify(dataFilters));
          }}
          onFilterCustom={() => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            console.log('Headers', arrData.headers);

            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectRows, arrElements);
              // onCheckRemoveItem(selectRows, arrElements);
              getElementsCSV(currentPage, null);
            }
          }}
          onSelectAll={() => {
            getElementsCSV(currentPage, null);
            getElementsAll(true, currentPage, arrElements);
          }}
          onModalAddress={() => getElements(false, 0, typeDelivery)}
          onChangeDriver={(idVehicle: any, nameVehicle: string) =>
            setDriver(idVehicle, nameVehicle, arrElements)
          }
          onChangeVehicles={(vehiclesArr: any) => setVehiclesData(vehiclesArr)}
          onPageSize={(value: number) => setPageSize(value)}
          // onClearFiltersHeader={(filterHeader: any) => setArrFilters(filterHeader)}
          onClearHeader={(filterHeaderClear: any) => {
            const dataFilterSelectedRows = sessionStorage.getItem(
              'selectedRows'
            );

            if (dataFilterSelectedRows) {
              const selectRows = JSON.parse(dataFilterSelectedRows);
              onCheckAddItem(selectRows, arrElements);
              // onCheckRemoveItem(selectRows, arrElements);
              getElementsCSV(currentPage, null);
            }
          }}
          onRemove={() => {
            if (
              permissionMenu(userData.grupo.permissoes, 'correctCSV.remove-cargo')
            ) {
              setRemoveConfirmation(true);
            } else {
              FrontendNotification(
                t('common.permission'),
                NotificationType.WARNING
              );
            }
          }}
          onRemoveAll={() => {
            if (
              permissionMenu(userData.grupo.permissoes, 'correctCSV.remove-cargo')
            ) {
              setRemoveConfirmationAll(true)
            } else {
              FrontendNotification(
                t('common.permission'),
                NotificationType.WARNING
              );
            }
          }}
          onRemoveSelectAll={() => onCheckRemoveAll()}
        />
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <button
            type="button"
            id="cancelRoutering"
            onClick={() => {
              history.push('/uploadCSV');
              window.location.reload();
            }}
            style={{
              boxShadow: '0px 4px 6px rgba(234, 0, 76, 0.2)',
              width: 367,
              height: 35,
              background: '#8C8C8C',
              border: 'none',
              borderRadius: 6,
              fontFamily: 'Lato',
              fontWeight: 'bold',
              fontSize: 16,
              marginTop: 20,
              color: '#fff',
            }}
          >
            <Trans i18nKey="uploadCSV.buttons.cancel" />
          </button>

          <button
            type="button"
            id="routeringButton"
            onClick={() => {
              onCheckAddItem(selectedRows, arrElements);
              // onCheckRemoveItem(selectedRows, arrElements);
              setTimeout(() => {
                handleSubmit(
                  selectedRows,
                  getSubsidiaryId,
                  arrDriver,
                  vehiclesData,
                  parameters,
                  arrElements
                );
              }, 2000);
            }}
            style={{
              boxShadow: '0px 4px 6px rgba(234, 0, 76, 0.2)',
              width: 367,
              height: 35,
              background: '#EA004C',
              border: 'none',
              borderRadius: 6,
              fontFamily: 'Lato',
              marginTop: 20,

              fontWeight: 'bold',
              fontSize: 16,
              color: '#fff',
            }}
          >
            {parameters.length === 0 ||
            parameters[0].roteirizacaoAtiva === false ? (
              <Trans i18nKey="uploadCSV.buttons.currentFinish" />
            ) : (
              <Trans i18nKey="uploadCSV.buttons.finish" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CorrectCSV;
