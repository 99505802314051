import closeModalIcon from 'assets/images/closeIcon.svg';
import React from 'react';
import { Modal } from 'reactstrap';
import LogoSAPB1 from '../../assets/images/Sap-b1-logo.svg';
import LogoIntegrationSendGrid from '../../assets/images/integrationsSendGrid.svg';
import LogoBling from '../../assets/images/logoBling.svg';
import LogoSascar from '../../assets/images/sascar-logo.svg';
import {
  ButtonClose,
  Container,
  Header,
  ImageClose,
  ModalTitle,
  WrapperClose,
} from './styles';

interface ModalProps {
  styles?: React.CSSProperties;
  isOpen: boolean;
  label: string;
  isClose?: any;
  isNotClose?: any;
  isIntegration?: boolean;
  isIntegrationSAP?: boolean;
  isIntegrationSascar?: boolean;
  isIntegrationSendgrid?: boolean;
  children: React.ReactChild | React.ReactChild[];
  buttons?: JSX.Element;
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  label,
  isClose,
  styles,
  children,
  isIntegration,
  isIntegrationSAP,
  isIntegrationSascar,
  isIntegrationSendgrid,
  isNotClose,
  buttons
}: ModalProps) => {
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      style={{
        ...styles,
        maxWidth: 'fit-content',
        minHeight: '700px',
        height: 'fit-content',
        // minWidth: '800px',
        marginTop: 10,
      }}
      dialogClassName="my-modal"
    >
      <Container>
        <Header>
          <ModalTitle>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {label}
              {buttons}
              {isIntegration || isIntegrationSendgrid ? (
                <img
                  src={isIntegration ? LogoBling : LogoIntegrationSendGrid}
                  width={isIntegrationSendgrid ? '120' : '84'}
                  height={isIntegrationSendgrid ? '120' : '84'}
                  style={{ marginLeft: 30, marginTop: 10 }}
                />
              ) : isIntegrationSAP ? (
                <img
                  src={LogoSAPB1}
                  width="84"
                  height="84"
                  style={{ marginLeft: 30, marginTop: 10 }}
                />
              ) : isIntegrationSascar ? (
                <img
                  src={LogoSascar}
                  width="154"
                  height="154"
                  style={{ marginLeft: 30, marginTop: 10 }}
                />
              ) : (
                <></>
              )}
            </div>
          </ModalTitle>
          <WrapperClose>
            {isNotClose ? (
              <></>
            ) : (
              <ButtonClose onClick={isClose}>
                <ImageClose src={closeModalIcon} />
              </ButtonClose>
            )}
          </WrapperClose>
        </Header>

        {children}
      </Container>
    </Modal>
  );
};

export default CustomModal;
