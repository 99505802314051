import InputCustom from 'components/Input';
import React from 'react';
import { ICreateShippingTableForm } from '../../..';
import {
  FormContent,
  FormContentField
} from './styles';

interface ShippingZipCodeProps {
  onChange: (field: string, value: string) => void;
  values: ICreateShippingTableForm;
  errors: any;
  touched: any;
}

const ShippingZipCode: React.FC<ShippingZipCodeProps> = ({
  onChange,
  values,
  errors,
  touched,
}: ShippingZipCodeProps) => {
  return (
    <FormContent>
      <FormContentField>
        <InputCustom
          typeInput="mask"
          mask="99999-999"
          placeholder="Digite o CEP"
          title="CEP"
          onChange={(e: any) => onChange('zipcode', e.target.value)}
          className="inputHeader"
          value={values.zipcode || ''}
          error={errors.zipcode}
          touched={touched.zipcode}
          id='zipcode'
        />
      </FormContentField>
      <FormContentField>
      <InputCustom
          title="Valor R$"
          placeholder="Insira o valor"
          onChange={(event: any) => {
            let userInput: string = event.target.value.replace(/[^0-9]/g, '');

            if (userInput === '') {
              onChange("valueKM", "0,00");
            } else {
              let userInputAsNumber: number = parseInt(userInput, 10) / 100;

              let formattedNumber: string = `${userInputAsNumber
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')}`;

              onChange('valueZipcode', formattedNumber);
            }
          }}
          value={values.valueZipcode}
          error={errors.valueZipcode}
          touched={touched.valueZipcode}
          id='valueZipcode'
        />
      </FormContentField>
    </FormContent>
  );
};

export default ShippingZipCode;
