import styled from 'styled-components';

export const HeaderAcceptRefuse = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const BodyAcceptRefuse = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BodyTextAreaAcceptRefuse = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 10px;

  .isSecond {
    margin-right: 25%;
  }
`;

export const columnSwitch = styled.div`
  display: 'flex';
  flex: 1;
  flex-wrap: 'nowrap';
  flex-direction: 'column';
  align-items: 'center';
  padding: '0 8px';
`;

export const labelSwitch = {
  fontSize: '0.9rem',
  whiteSpace: 'nowrap',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
};
