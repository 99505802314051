import React from 'react';
import iconMapCSV from '../../../../../assets/images/iconMapCSV.png';
import iconViewMap from '../../../../../assets/images/iconViewMap.svg';
import './styles.scss';

interface IEditMode {
  status: boolean;
  rowKey: number;
  index: number;
}

interface IErrorColumn {
  message?: string;
}

interface Props {
  editMode?: IEditMode;
  indexColumn?: number | any;
  indexRow?: number;
  dataRow?: string | number | boolean | null | any;
  id?: string | number | any;
  isTooltip?: boolean;
  error?: IErrorColumn | null;
  arrElements?: any;
  valueColumn?: string | any | null;
  onChangeRow?: any;
  onUpdateAddress?: any;
  onUpdateRow?: any;
}

const EditMode: React.FC<Props> = ({
  editMode,
  indexColumn,
  indexRow,
  dataRow,
  id,
  isTooltip,
  error,
  arrElements,
  valueColumn,
  onChangeRow,
  onUpdateAddress,
  onUpdateRow
}: Props) => {
  const getEditMode = (dataEditMode: any) => {
    let editModeColumn = dataEditMode;

    let isEditMode;

    const findColumnEditMode = editModeColumn.find(
      (editModeField: string) => editModeField !== id
    );

    if (
      editMode?.status === true &&
      editMode.rowKey === id &&
      editMode.index === indexRow &&
      indexColumn > 0 &&
      findColumnEditMode
    ) {
      isEditMode = true;
    } else {
      isEditMode = false;
    }

    return isEditMode;
  };

  return (
    <>
      {editMode?.status === true &&
      editMode.rowKey === id &&
      editMode.index === indexRow &&
      indexColumn > 0 &&
      id !== 'address1' &&
      id !== 'addressNeightborhood' &&
      id !== 'addressCity' &&
      id !== 'addressState' &&
      id !== 'addressZipcode' &&
      id !== 'latitude' &&
      id !== 'longitude' &&
      id !== 'accuracyStatus' &&
      id !== 'accuracyStatusPickup' &&
      id !== 'address1Pickup' &&
      id !== 'addressNeightborhoodPickup' &&
      id !== 'addressCityPickup' &&
      id !== 'addressStatePickup' &&
      id !== 'addressZipcodePickup' &&
      id !== 'latitudePickup' &&
      id !== 'longitudePickup' &&
      id !== 'deliveryOrder' ? (
        <div style={{ position: 'relative' }}>
          {id === 'timeWindowOpen' ||
          id === 'timeWindowClose' ||
          id === 'docNumber' ||
          id === 'referenceId' ||
          id === 'contactPhone' ||
          id === 'boxesQty' ||
          id === 'weight' ||
          id === 'value' ? (
            <>
              {!isTooltip && error !== null && (
                <>
                  <div className="popoverError">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: 'Lato',
                        color: '#EA004C',
                      }}
                    >
                      {error?.message}
                    </span>
                  </div>
                </>
              )}
              <input
                type="text"
                value={valueColumn}
                style={{
                  borderRadius: 7,
                  // marginTop: 40,
                  position: 'relative',
                }}
                onBlur={() => {
                  // onCancel(item.columns);
                  onUpdateRow(valueColumn, dataRow)
                  
                }}
                onChange={event => {
                  onChangeRow(event.target.value)
                }}
              />
            </>
          ) : (
            <div style={{ display: 'flex' }}>
              {isTooltip && error !== null && (
                <>
                  <div className="popoverError">
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: 'Lato',
                        color: '#EA004C',
                      }}
                    >
                      {error?.message}
                    </span>
                  </div>
                </>
              )}
              <input
                value={valueColumn}
                style={{ borderRadius: 7 }}
                onBlur={() => {
                  onUpdateRow(valueColumn, dataRow)

                  // onCancel(item.columns);
                  // onChangeService(arr, index, indexColumn, valueRow);
                  // updateColumnsError(
                  //   arr,
                  //   index,
                  //   item.columns[0].data,
                  //   selectedRows
                  // );
                  // if (item.active) {
                  //   updateTotalityAccumulator(
                  //     selectedRows,
                  //     item.columns,
                  //     valueRowUpdated,
                  //     indexColumn
                  //   );
                  // }
                }}
                onChange={event => {
                  onChangeRow(event.target.value)
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          {error !== null ? (
            <>
              {id === 'accuracyStatus' ||
              id === 'accuracyStatusPickup' ? (
                <div
                  style={{
                    width: 120,
                  }}
                >
                  <span
                    style={{
                      fontSize: 10,
                      padding: 10,
                      color:
                        (id === 'accuracyStatus' &&
                          dataRow <= 50) ||
                        (id === 'accuracyStatusPickup' &&
                          dataRow <= 50)
                          ? '#EA004C'
                          : '#000',
                      fontWeight:
                        (id === 'accuracyStatus' &&
                          dataRow <= 50) ||
                        (id === 'accuracyStatusPickup' &&
                          dataRow <= 50)
                          ? 'bold'
                          : 'normal',
                    }}
                  >
                    {id === 'accuracyStatus' ||
                    id === 'accuracyStatusPickup'
                      ? `${dataRow}%`
                      : `${dataRow}`}
                    {id === 'accuracyStatus' ||
                    id === 'accuracyStatusPickup' ? (
                      <img
                        src={
                          (id === 'accuracyStatus' &&
                            dataRow <= 50) ||
                          (id === 'accuracyStatusPickup' &&
                            dataRow <= 50)
                            ? iconViewMap
                            : iconMapCSV
                        }
                        style={{
                          width: 18,
                          height: 18,
                          cursor: 'pointer',
                        }}
                        alt=""
                        onClick={() => {
                          onUpdateAddress();
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <div
                    className="error"
                    onClick={() => {
                      // setTooltipOpen(true);
                    }}
                    id="box"
                  >
                    {id === 'latitudePickup' ? (
                      <p className="limitText">{dataRow}</p>
                    ) : (
                      <span>{dataRow}</span>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                width: 120,
              }}
            >
              <span
                style={{
                  fontSize: 10,
                  padding: 10,
                  color:
                    (id === 'accuracyStatus' &&
                      dataRow <= 50) ||
                    (id === 'accuracyStatusPickup' &&
                      dataRow <= 50)
                      ? '#EA004C'
                      : '#000',
                  fontWeight:
                    (id === 'accuracyStatus' &&
                      dataRow <= 50) ||
                    (id === 'accuracyStatusPickup' &&
                      dataRow <= 50)
                      ? 'bold'
                      : 'normal',
                }}
              >
                {id === 'accuracyStatus' ||
                id === 'accuracyStatusPickup'
                  ? `${dataRow}%`
                  : `${dataRow}`}
                {id === 'accuracyStatus' ||
                id === 'accuracyStatusPickup' ? (
                  <img
                    src={
                      (id === 'accuracyStatus' &&
                        dataRow <= 50) ||
                      (id === 'accuracyStatusPickup' &&
                        dataRow <= 50)
                        ? iconViewMap
                        : iconMapCSV
                    }
                    style={{
                      width: 18,
                      height: 18,
                      cursor: 'pointer',
                    }}
                    alt=""
                    onClick={() => {
                      onUpdateAddress();
                     
                    }}
                  />
                ) : (
                  <></>
                )}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditMode;
