import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  caches: [],
};

i18n
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // connect with React
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false,
      wait: true,
    },
    detection: options,
    // lng: Cookies.get('locale') || 'pt', // Idioma inicial usado.
    // lng: 'pt',
    fallbackLng: 'pt', // o idioma que será usado caso algumas traduções não sejam definidas.
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
