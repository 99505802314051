import InputCustom from 'components/Input';
import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import { useFormik } from 'formik';
// import { Line } from 'pages/ControlTower/components/Table/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import formValidator from './formValidator';
import { Line } from './styles';

interface DataFilters {
  field: string;
  value: any;
  operation: string;
}

type FormValues = {
  apiKey: string;
  time: number;
  situation: string;
};

interface Props {
  onClear: any;
}

const IntegrationsSascar: React.FC<Props> = ({ onClear }: Props) => {
  const [dataSascar, setDataSascar] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<DataFilters[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [dataBlingResult, setDataBlingResult] = useState();

  const getIntegrationSascar = useCallback(async () => {
    try {
      const data = {
        filters: filters.length > 0 ? filters : [],
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPage || 0,
        size: 5,
      };
      const response = await api.post('/audit/integration/api/pageable', data);

      setDataSascar([]);
      setTotalPages(0);
    } catch (err) {}
  }, [filters, currentPage]);

  function paginate(pageNumber: number) {
    setCurrentPage(pageNumber - 1);
  }

  useEffect(() => {
    getIntegrationSascar();
  }, [getIntegrationSascar]);

  const initialValues: FormValues = {
    apiKey: '',
    time: 0,
    situation: '',
  };

  const handleSubmit = useCallback(async (values: FormValues) => {
    setLoading(true);

    sessionStorage.setItem('loadingBling', 'true');

    setActiveLoading(true);
    const dataBling = {
      tempo: values.time === 0 ? null : values.time,
      token: values.apiKey.length > 0 ? values.apiKey.trim() : null,
      situacao: values.situation.length > 0 ? values.situation.trim() : null,
    };

    let urlBlingSicronize = '/integracao/tmsErp/bling/pedidos';

    if (dataBling.token !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(`?token=${dataBling.token}`);
    }
    if (dataBling.tempo !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(`&tempo=${dataBling.tempo}`);
    }
    if (dataBling.situacao !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(
        `&situacao=${dataBling.situacao}`
      );
    }

    const response = await api.get(`${urlBlingSicronize}`);
    setStatusCode(response.status === 200 ? 200 : 400);
    setDataBlingResult(response.data);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values),
  });


  return (
    <>
      <CustomModal
        isIntegrationSascar
        isOpen
        isClose={() => onClear()}
        label={t('integrationsBling.menuTitle')}
        styles={{ maxWidth: '90%' }}
      >
        <Row>
          <Col md={12}>
            <InputCustom
              title="Url Sasintegração"
              onChange={formik.handleChange('apiKey')}
              placeholder=""
              styleTitle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: '#003049',
              }}
              value={formik.values.apiKey}
              touched={formik.touched.apiKey}
              error={formik.errors.apiKey}
            />
          </Col>
        </Row>
        <Row>
        <Col md={3}>
            <InputCustom
              title="Usuário"
              onChange={formik.handleChange('apiKey')}
              placeholder=""
              styleTitle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: '#003049',
              }}
              value={formik.values.apiKey}
              touched={formik.touched.apiKey}
              error={formik.errors.apiKey}
            />
          </Col>
          <Col md={3}>
            <InputCustom
              title="Senha"
              onChange={formik.handleChange('apiKey')}
              placeholder=""
              styleTitle={{
                fontSize: 14,
                fontWeight: 'normal',
                color: '#003049',
              }}
              value={formik.values.apiKey}
              touched={formik.touched.apiKey}
              error={formik.errors.apiKey}
            />
          </Col>
          <Col md={3}>
            <div />
          </Col>
          <Col md={3}>
            <button
              type="button"
              className="button"
              style={{ marginTop: 40 }}
              onClick={() => formik.handleSubmit()}
            >
              Salvar
              {/* <Trans i18nKey="integrationsBling.buttonSicronize" /> */}
            </button>
          </Col>
        </Row>
        <Line style={{ padding: 0, margin: 0, marginTop: 30 }} />

        <Logs
          data={dataSascar}
          totalPages={totalPages}
          page={currentPage}
          onPaginate={(page: number) => paginate(page)}
          onFilters={(date: string, status: string) => {
            setCurrentPage(0);
            if (date.length > 0 && status.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else if (date.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
              ]);
            } else if (status.length > 0) {
              setFilters([
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else {
              setFilters([]);
            }
          }}
        />
      </CustomModal>
    </>
  );
};

export default IntegrationsSascar;
