import React from 'react';

import batteryUltraHighVehicle from '../../../assets/images/batteryUltraHighVehicle.svg';
import batteryMediumVehicle from '../../../assets/images/batteryMediumVehicle.svg';
import batteryMedium2Vehicle from '../../../assets/images/batteryMedium2.svg';
import batteryHighVehicle from '../../../assets/images/batteryHighVehicle.svg';
import batteryLowVehicle from '../../../assets/images/batteryLowVehicle.svg';
import batteryZeroVehicle from '../../../assets/images/batteryZeroVehicle.svg';

export default function TotalizatorValue({ progressValue }) {
  let battery = batteryZeroVehicle;

  // Indicador da bateria do dispositivo do entregador.
  if (progressValue <= 0) {
    battery = batteryZeroVehicle;
  } else if (progressValue <= 20) {
    battery = batteryLowVehicle;
  } else if (progressValue <= 50) {
    battery = batteryMediumVehicle;
  } else if (progressValue <= 70) {
    battery = batteryMedium2Vehicle;
  } else if (progressValue <= 100) {
    battery = batteryHighVehicle;
  } else if (progressValue > 100) {
    battery = batteryUltraHighVehicle;
  }

  return (
    <>
      <img src={battery} alt="" />
    </>
  );
}
