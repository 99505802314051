import Pagination from '@material-ui/lab/Pagination';
import CustomModal from 'components/ModalCustom';
import Reasons from 'components/Reasons';
import { BackendErrorNotification } from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import api from 'services/api';
import addDriver from '../../../assets/images/addDriver.png';
import ConfirmationDriver from './ConfirmationDriver';
import CustomFilters from './CustomFilters';



type Props = {
  onClear: any,
  onLoadCargo: any,
  idRomaneio: string
}

const CancelCargo: React.FC<Props> = ({ idRomaneio, onClear, onLoadCargo }: Props) => {
  const [modal, setModal] = useState<boolean>(true);
  const [valueSearch, setValueSearch] = useState('');
  const [optionsFilter, setOptionsFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState([
    {
      field: 'status',
      value: 'ACTIVE',
      operation: 'EQUAL'
    }
  ]);
  const [reasons, setReasons] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [registerReason, setRegisterReason] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [dataChange, setDataChange] = useState({
    cpf: '',
    romaneioId: '',
  });

  const { t } = useTranslation();

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);


  const getReasons = useCallback(async () => {
    try {
      const currentFilters = [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ];
      const dataReasons = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [],
      };
      const response = await api.post('/delivery/motivos/pageable', dataReasons)

      setReasons(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      BackendErrorNotification(err)
    }
  }, [currentPage, filters])

  const onChangeDriver = useCallback((cpf, id) => {
    setConfirmation(!confirmation);
    setDataChange({
      cpf,
      romaneioId: id,
    });
  }, []);

  useEffect(() => {
    getReasons();
  }, [getReasons])
  return (
    <>
      {registerReason && (
        <Reasons
          active={undefined}
          onClear={() => {
            setRegisterReason(!registerReason);
            getReasons();
          }}
        />
      )}
      {confirmation && (
        <ConfirmationDriver
          idRomaneio={dataChange.romaneioId}
          cpfDriver={dataChange.cpf}
          onClear={() => setConfirmation(!confirmation)}
          onLoad={onLoadCargo}
          onConfirm={() => {
            onClear();
            setConfirmation(!confirmation);
            setModal(!modal);
          }}
        />
      )}
      <CustomModal isOpen={modal} label={t('cancelDriver.titleDriver')} styles={{ minWidth:'60%', maxHeight: '90%' }} isClose={() => {
        onClear();
        setModal(!modal)
      }}>
        <Row>
          <CustomFilters
            options={optionsFilter}
            textSearch={valueSearch}
            onChangeSearch={(text: any) => setValueSearch(text)}
            onChangeOptions={(option: any) => setOptionsFilter(option)}
            onExecuteFilter={(data: any) => {
              setCurrentPage(0);
              setFilters(data);
            }}
          />
        </Row>
        <Row style={{ marginTop: 40, marginLeft: 5 }}>

          <button
            type="button"
            className="buttonDriver"
            onClick={() => setRegisterReason(!registerReason)}
          >
            <img src={addDriver} className="imgAdd" alt="" />
            <Trans i18nKey="cancelDriver.driver" />
          </button>

        </Row>

        <div className="tw-bg-[#fff] tw-border-2 tw-border-[#edf2f4] tw-rounded-lg tw-p-4 tw-w-4/5 tw-mt-4" style={{ border: '2px solid #EDF2F4' }}>
          <div className="table-responsive">
            <table className="tableReason">
              <thead>
                <tr>
                  <th>
                    <Trans i18nKey="cancelDriver.reasons" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {reasons.map((item: any) => (
                  <tr
                    className="lineGroup selectDriver"
                    onClick={() => onChangeDriver(item.id, idRomaneio)}
                  >
                    <td>{item.descricao}</td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, page) => paginate(page)}
          />
        </div>
      </CustomModal>
    </>
  )
}

export default CancelCargo;