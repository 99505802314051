import styled from 'styled-components';

export const Container = styled.div``;
export const Title = styled.span`
  font-size: 16px;
  color: #535669;
`;
export const ItemFilters = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WrapperFilters = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 2ch;
  margin-top: 0.825rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ddd;
`;
