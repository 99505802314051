import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Label, Row } from 'reactstrap';
import Loading from '../../../core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from '../../../core/common/Notification';
import api from '../../../services/api';
import InputTag from '../../InputTag';
import SelectParameters from '../../SelectParameters';
import './style.scss';
import {
  BodyAcceptRefuse,
  BodyTextAreaAcceptRefuse,
  HeaderAcceptRefuse,
  labelSwitch
} from './styles';

export default function AcceptDecline() {
  const [selectParamets, setSelectParamets] = useState(false);
  const [typeParameters, setTypeParameters] = useState('');
  const [count, setCount] = useState(0);
  const [countDecline, setCountDecline] = useState(0);
  const [countNew, setCountNew] = useState(0);

  const [loading, setLoading] = useState(false);
  const [descriptionSMSAccept, setDescriptionSMSAccept] = useState('');
  const [descriptionSMSDecline, setDescriptionSMSDecline] = useState('');
  const [descriptionSMSNew, setDescriptionSMSNew] = useState('');
  const [acceptDeclineList, setAcceptDeclineList] = useState([]);
  const [errorMaxLength, setErrorMaxLength] = useState('');
  const [checked, setChecked] = useState({
    checkedAtivate: false,
    checkedActiveSMS: false,
    checkedDeclineSMS: false,
    checkedNewSMS: false,
  });

  const tagAceitaRef = useRef();
  const tagRecusaRef = useRef();
  const tagNextRef = useRef();
  const getLanguage = () => {
    return i18next.language;
  };
  const getAcceptDecline = useCallback(async () => {
    try {
      const response = await api.get('/delivery/etapas/config');

      setAcceptDeclineList(response.data);

      setChecked({
        checkedAtivate: !!response.data[0].aceitaRecusa.ativo,
        checkedActiveSMS: !!response.data[0].aceitaRecusa.smsAceita,
        checkedDeclineSMS: !!response.data[0].aceitaRecusa.smsRecusa,
        checkedNewSMS: !!response.data[0].proximoAtendimento
          .proximoAtendimentoAtivo,
      });

      if (response.data[0].aceitaRecusa.msgSmsAceita !== null) {
        setDescriptionSMSAccept(response.data[0].aceitaRecusa.msgSmsAceita);
        setCount(response.data[0].aceitaRecusa.msgSmsAceita.length);
      }

      if (response.data[0].aceitaRecusa.msgSmsRecusa !== null) {
        setDescriptionSMSDecline(response.data[0].aceitaRecusa.msgSmsRecusa);
        setCountDecline(response.data[0].aceitaRecusa.msgSmsRecusa.length);
      }

      if (response.data[0].proximoAtendimento.msgSmsProxAtendimento !== null) {
        setDescriptionSMSNew(
          response.data[0].proximoAtendimento.msgSmsProxAtendimento
        );
        setCountNew(
          response.data[0].proximoAtendimento.msgSmsProxAtendimento.length
        );
      }
    } catch (err) {}
  }, []);

  const { t } = useTranslation();
  const ServiceAcceptDecline = useCallback(
    async (check, textAccept, textDecline, textNext) => {
      const removeChaves = textAccept.replace(/(\{)/g, '');
      const removeChaves2 = removeChaves.replace(/(\})/g, '');
      const removeAspas = removeChaves2.replace(/(\")/g, '');
      const removeValue = removeAspas.replace('value:', '');

      const removeChavesDecline = textDecline.replace(/(\{)/g, '');
      const removeChaves2Decline = removeChavesDecline.replace(/(\})/g, '');
      const removeAspasDecline = removeChaves2Decline.replace(/(\")/g, '');
      const removeValueDecline = removeAspasDecline.replace('value:', '');

      const removeChavesNext = textNext.replace(/(\{)/g, '');
      const removeChaves2Next = removeChavesNext.replace(/(\})/g, '');
      const removeAspasNext = removeChaves2Next.replace(/(\")/g, '');
      const removeValueNext = removeAspasNext.replace('value:', '');

      const data = {
        aceitaRecusa: {
          ativo: !!check.checkedAtivate,
          smsAceita: !!check.checkedActiveSMS,
          smsRecusa: !!check.checkedDeclineSMS,
          msgSmsAceita: removeValue.length > 0 ? removeValue : null,
          msgSmsRecusa:
            removeValueDecline.length > 0 ? removeValueDecline : null,
        },
        proximoAtendimento: {
          proximoAtendimentoAtivo: !!check.checkedNewSMS,
          msgSmsProxAtendimento:
            removeValueNext.length > 0 ? removeValueNext : null,
        },
      };
      const response = await api.post('/delivery/etapas/config', data);

      if (response.status === 201) {
        setLoading(false);
        FrontendNotification(
          t('phases.messageCreated'),
          NotificationType.SUCCESS
        );
        getAcceptDecline();
      }
    },
    []
  );

  const getParameters = useCallback(async tagField => {
    const response = await api.get('/delivery/templates/markups');

    const filter = response.data.filter(item => item.tag === `[[${tagField}]]`);

    return filter[0].length;
  }, []);

  const addTags = useCallback(
    (
      check,
      params,
      textAccept,
      textDecline,
      textNew,
      countSMS,
      countDeclineSMS,
      countNewSMS,
      type
    ) => {
      const concatText = `${
        type === 'aceita'
          ? textAccept
          : type === 'recusa'
          ? textDecline
          : textNew
      } ${params.tag}`;

      const maxLength = process.env.MAX_LENGTH_SMS || 132;

      if (
        countSMS > maxLength ||
        countDeclineSMS > maxLength ||
        countNewSMS > maxLength
      ) {
        const message =
          getLanguage() === 'pt' || getLanguage() === 'pt-BR'
            ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
            : 'Character limit exceeded! It is not possible to add the parameter.';

        FrontendNotification(message, NotificationType.ERROR);
      } else {
        if (type === 'aceita') {
          setCount(prevState => prevState + params.length);
        } else if (type === 'recusa') {
          setCountDecline(prevState => prevState + params.length);
        } else {
          setCountNew(prevState => prevState + params.length);
        }
        if (type === 'aceita') {
          setDescriptionSMSAccept(concatText);
        } else if (type === 'recusa') {
          setDescriptionSMSDecline(concatText);
        } else {
          setDescriptionSMSNew(concatText);
        }
      }
      setTypeParameters('');
    },
    []
  );

  useEffect(() => {
    getAcceptDecline();
  }, [getAcceptDecline]);

  return (
    <>
      <Loading loading={loading} />
      {selectParamets && (
        <SelectParameters
          onClear={() => setSelectParamets(!selectParamets)}
          onSelectParameters={item =>
            addTags(
              checked,
              item,
              descriptionSMSAccept,
              descriptionSMSDecline,
              descriptionSMSNew,
              count,
              countDecline,
              countNew,
              typeParameters
            )
          }
        />
      )}
      <BodyAcceptRefuse allOptions={checked.checkedAtivate}>
        <HeaderAcceptRefuse>
          <columnSwitch>
            <p style={{ ...labelSwitch }}>
              <Trans i18nKey="phases.fields.activeFunction" />
            </p>
            <div
              className="switch"
              aria-hidden="true"
              onClick={() =>
                setChecked({
                  ...checked,
                  checkedAtivate: !checked.checkedAtivate,
                  checkedActiveSMS: !checked.checkedActiveSMS,
                  checkedDeclineSMS: !checked.checkedDeclineSMS,
                })
              }
            >
              {checked.checkedAtivate ? (
                <div className="switchChecked" />
              ) : (
                <div className="switchUnchecked" />
              )}
            </div>
          </columnSwitch>
        </HeaderAcceptRefuse>

        <>
          <BodyTextAreaAcceptRefuse>
            <Row>
              <Label style={{ marginRight: 60 }}>
                <columnSwitch>
                  <p style={{ ...labelSwitch }}>
                    <Trans i18nKey="phases.fields.activeSMS" />
                  </p>
                  <div
                    className="switch"
                    aria-hidden="true"
                    onClick={() => {
                      if (checked.checkedAtivate) {
                        setChecked({
                          ...checked,
                          checkedActiveSMS: !checked.checkedActiveSMS,
                        });
                      }
                    }}
                  >
                    {checked.checkedActiveSMS ? (
                      <div className="switchChecked" />
                    ) : (
                      <div className="switchUnchecked" />
                    )}
                  </div>
                </columnSwitch>
                {checked.checkedAtivate && checked.checkedActiveSMS ? (
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Form.Label>
                        <Trans i18nKey="phases.smsMessage" /> ({count}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          aria-hidden="true"
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#EA004C',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectParamets(!selectParamets);
                            setTypeParameters('aceita');
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        height: 129,
                        width: 300,
                        marginRight: 10,
                      }}
                    >
                      <InputTag
                        tagifyRef={tagAceitaRef}
                        onChange={e => {
                          setDescriptionSMSAccept(e.target.value);
                          const maxLength = process.env.MAX_LENGTH_SMS || 132;

                          if (e.target.value.length > maxLength) {
                            const message =
                              getLanguage() === 'pt' ||
                              getLanguage() === 'pt-BR'
                                ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
                                : 'Character limit exceeded! It is not possible to add the parameter.';

                            setErrorMaxLength(message);
                          } else {
                            setErrorMaxLength('');
                          }
                        }}
                        onInput={e => {
                          const value = e.detail.textContent;

                          if (value.length === 0) {
                            setCount(0);
                          }
                        }}
                        onKeydown={e => {
                          const key =
                            e.detail.originalEvent.keyCode ||
                            e.detail.originalEvent.charCode;

                          if (key == '8') {
                            setCount(prevState => {
                              if (prevState === 0) {
                                return 0;
                              }
                              return prevState - 1;
                            });
                          } else if (
                            key == '18' ||
                            key == '17' ||
                            key == '9' ||
                            key == '16' ||
                            key == '20' ||
                            key == '3'
                          ) {
                            setCount(count);
                          } else if (
                            key !== '8' &&
                            key !== '18' &&
                            key !== '17' &&
                            key !== '9' &&
                            key !== '16' &&
                            key !== '20' &&
                            key !== '3'
                          ) {
                            setCount(prevState => prevState + 1);
                          } else {
                            setCount(0);
                          }
                        }}
                        onRemove={async e => {
                          const lengthRemove = await getParameters(
                            e.detail.data.value
                          );

                          setCount(prevState => prevState - lengthRemove);
                        }}
                        value={descriptionSMSAccept}
                      />
                    </div>

                    {errorMaxLength !== '' && (
                      <p
                        style={{
                          fontSize: 14,
                          color: 'red',
                        }}
                      >
                        * {errorMaxLength}
                      </p>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginRight: 25,
                      }}
                    >
                      <Form.Label style={{ color: '#ccc' }} readOnly>
                        <Trans i18nKey="phases.smsMessage" /> ({count}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#ccc',
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>
                    <Form.Control
                      style={{
                        width: 300,
                        height: 129,
                        marginRight: 25,
                      }}
                      as="textarea"
                      readOnly
                    />
                  </Form.Group>
                )}
              </Label>
              <Label>
                <columnSwitch>
                  <p style={{ ...labelSwitch }}>
                    <Trans i18nKey="phases.fields.newSMS" />
                  </p>
                  <div
                    className="switch"
                    aria-hidden="true"
                    onClick={() =>
                      setChecked({
                        ...checked,
                        checkedNewSMS: !checked.checkedNewSMS,
                      })
                    }
                  >
                    {checked.checkedNewSMS ? (
                      <div className="switchChecked" />
                    ) : (
                      <div className="switchUnchecked" />
                    )}
                  </div>
                </columnSwitch>
                {checked.checkedNewSMS ? (
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Form.Label>
                        <Trans i18nKey="phases.smsMessage" /> ({countNew}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          aria-hidden="true"
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#EA004C',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectParamets(!selectParamets);
                            setTypeParameters('novo');
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        height: 129,
                        width: 300,
                        marginRight: 10,
                      }}
                    >
                      <InputTag
                        tagifyRef={tagNextRef}
                        onChange={e => {
                          setDescriptionSMSNew(e.target.value);
                          const maxLength = process.env.MAX_LENGTH_SMS || 132;

                          if (e.target.value.length > maxLength) {
                            const message =
                              getLanguage() === 'pt' ||
                              getLanguage() === 'pt-BR'
                                ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
                                : 'Character limit exceeded! It is not possible to add the parameter.';

                            setErrorMaxLength(message);
                          } else {
                            setErrorMaxLength('');
                          }
                        }}
                        onInput={e => {
                          const value = e.detail.textContent;

                          if (value.length === 0) {
                            setCountNew(0);
                          }
                        }}
                        onKeydown={e => {
                          const key =
                            e.detail.originalEvent.keyCode ||
                            e.detail.originalEvent.charCode;

                          if (key == '8') {
                            setCountNew(prevState => {
                              if (prevState === 0) {
                                return 0;
                              }
                              return prevState - 1;
                            });
                          } else if (
                            key == '18' ||
                            key == '17' ||
                            key == '9' ||
                            key == '16' ||
                            key == '20' ||
                            key == '3'
                          ) {
                            setCountNew(countNew);
                          } else if (
                            key !== '8' &&
                            key !== '18' &&
                            key !== '17' &&
                            key !== '9' &&
                            key !== '16' &&
                            key !== '20' &&
                            key !== '3'
                          ) {
                            setCountNew(prevState => prevState + 1);
                          } else {
                            setCountNew(0);
                          }
                        }}
                        onRemove={async e => {
                          const lengthRemove = await getParameters(
                            e.detail.data.value
                          );

                          setCountNew(prevState => prevState - lengthRemove);
                        }}
                        value={descriptionSMSNew}
                      />
                    </div>

                    {errorMaxLength !== '' && (
                      <p
                        style={{
                          fontSize: 14,
                          color: 'red',
                        }}
                      >
                        * {errorMaxLength}
                      </p>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginRight: 25,
                      }}
                    >
                      <Form.Label style={{ color: '#ccc' }} readOnly>
                        <Trans i18nKey="phases.smsMessage" /> ({countNew}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#ccc',
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>
                    <Form.Control
                      style={{
                        width: 300,
                        height: 129,
                        marginRight: 25,
                      }}
                      as="textarea"
                      readOnly
                    />
                  </Form.Group>
                )}
              </Label>
            </Row>
          </BodyTextAreaAcceptRefuse>
          <BodyTextAreaAcceptRefuse isSecond>
            <Row className="isSecond">
              <Label>
                <columnSwitch>
                  <p style={{ ...labelSwitch }}>
                    <Trans i18nKey="phases.fields.refuseSMS" />
                  </p>
                  <div
                    className="switch"
                    aria-hidden="true"
                    onClick={() => {
                      if (checked.checkedAtivate) {
                        setChecked({
                          ...checked,
                          checkedDeclineSMS: !checked.checkedDeclineSMS,
                        });
                      }
                    }}
                  >
                    {checked.checkedAtivate && checked.checkedDeclineSMS ? (
                      <div className="switchChecked" />
                    ) : (
                      <div className="switchUnchecked" />
                    )}
                  </div>
                </columnSwitch>
                {checked.checkedAtivate && checked.checkedDeclineSMS ? (
                  <Form.Group
                    controlId="exampleForm.ControlTextarea1"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Form.Label>
                        <Trans i18nKey="phases.smsMessage" /> ({countDecline}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          aria-hidden="true"
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#EA004C',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectParamets(!selectParamets);
                            setTypeParameters('recusa');
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        height: 129,
                        width: 300,
                        marginRight: 10,
                      }}
                    >
                      <InputTag
                        tagifyRef={tagRecusaRef}
                        onChange={e => {
                          setDescriptionSMSDecline(e.target.value);
                          const maxLength = process.env.MAX_LENGTH_SMS || 132;

                          if (e.target.value.length > maxLength) {
                            const message =
                              getLanguage() === 'pt' ||
                              getLanguage() === 'pt-BR'
                                ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
                                : 'Character limit exceeded! It is not possible to add the parameter.';

                            setErrorMaxLength(message);
                          } else {
                            setErrorMaxLength('');
                          }
                        }}
                        onInput={e => {
                          const value = e.detail.textContent;

                          if (value.length === 0) {
                            setCountDecline(0);
                          }
                        }}
                        onKeydown={e => {
                          const key =
                            e.detail.originalEvent.keyCode ||
                            e.detail.originalEvent.charCode;

                          if (key == '8') {
                            setCountDecline(prevState => {
                              if (prevState === 0) {
                                return 0;
                              }
                              return prevState - 1;
                            });
                          } else if (
                            key == '18' ||
                            key == '17' ||
                            key == '9' ||
                            key == '16' ||
                            key == '20' ||
                            key == '3'
                          ) {
                            setCountDecline(countDecline);
                          } else if (
                            key !== '8' &&
                            key !== '18' &&
                            key !== '17' &&
                            key !== '9' &&
                            key !== '16' &&
                            key !== '20' &&
                            key !== '3'
                          ) {
                            setCountDecline(prevState => prevState + 1);
                          } else {
                            setCountDecline(0);
                          }
                        }}
                        onRemove={async e => {
                          const lengthRemove = await getParameters(
                            e.detail.data.value
                          );

                          setCountDecline(
                            prevState => prevState - lengthRemove
                          );
                        }}
                        value={descriptionSMSDecline}
                      />
                    </div>

                    {errorMaxLength !== '' && (
                      <p
                        style={{
                          fontSize: 14,
                          color: 'red',
                        }}
                      >
                        * {errorMaxLength}
                      </p>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginRight: 25,
                      }}
                    >
                      <Form.Label style={{ color: '#ccc' }} readOnly>
                        <Trans i18nKey="phases.smsMessage" /> ({countDecline}
                        /132)
                      </Form.Label>

                      <div>
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#ccc',
                          }}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>
                    <Form.Control
                      style={{
                        width: 300,
                        height: 129,
                        marginRight: 25,
                      }}
                      as="textarea"
                      readOnly
                    />
                  </Form.Group>
                )}
              </Label>
            </Row>
          </BodyTextAreaAcceptRefuse>
        </>

        <div className="footerButton">
          <button
            onClick={() => {
              ServiceAcceptDecline(
                checked,
                descriptionSMSAccept,
                descriptionSMSDecline,
                descriptionSMSNew
              );
            }}
            className="button"
            type="button"
          >
            <Trans i18nKey="phases.saveOptions" />
          </button>
        </div>
      </BodyAcceptRefuse>
    </>
  );
}
