export function saveRouteMap(data) {
  return {
    type: '@map/SAVE_ROUTE_MAP',
    payload: { data },
  };
}

export function typeIntegrationCSV(type) {
  return {
    type: '@map/TYPE_INTEGRATION_CSV',
    payload: { type },
  };
}

export function updateGroupsUserId(userId) {
  return {
    type: '@map/UPDATE_GROUP_USER',
    payload: { userId },
  };
}
export function deleteGroupsUserId() {
  return {
    type: '@map/DELETE_GROUP_USER',
  };
}
export function updateGroupsEdit(edit) {
  return {
    type: '@map/UPDATE_GROUP_EDIT',
    payload: { edit },
  };
}
export function deleteGroupsEdit() {
  return {
    type: '@map/DELETE_GROUP_EDIT',
  };
}

export function saveTabRoute(editId, tabId) {
  return {
    type: '@map/SAVE_TAB_ROUTE',
    payload: { editId, tabId },
  };
}
export function saveTabIndex(tabId) {
  return {
    type: '@map/SAVE_TAB_INDEX',
    payload: { tabId },
  };
}

export function saveSubsidiary(idSubsidiary) {
  return {
    type: '@map/SAVE_SUBSIDIARY',
    payload: { idSubsidiary },
  };
}
