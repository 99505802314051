import React from 'react';
import SearchFilter from 'components/SearchFilter';

export default function Filters({
  options,
  searchText,
  onChangeText,
  onChangeOptions,
  onChangeOptionText,
  onFilters,
  subsidiaryFilters,
}) {
  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <SearchFilter
        isName
        isCPF
        isService
        isTypeDriver={options === 'tipo'}
        valueSearch={searchText}
        optionValue={options}
        handleChange={e => onChangeText(e.target.value)}
        handleSelect={e => onChangeOptions(e.target.value)}
        handleSelectDriver={e => onChangeOptionText(e.target.value)}
        onExecuteFilter={() => {
          if (options === 'nome' && searchText.length > 0) {
            onFilters([
              {
                field: 'filial.id',
                value: subsidiaryFilters,
                operation: 'EQUAL',
              },
              {
                field: 'nome',
                value: searchText,
                operation: 'MATCH',
              },
            ]);
          } else if (options === 'cpf' && searchText.length > 0) {
            onFilters([
              {
                field: 'filial.id',
                value: subsidiaryFilters,
                operation: 'EQUAL',
              },
              {
                field: 'cpf',
                value: searchText,
                operation: 'MATCH',
              },
            ]);
          } else {
            onFilters([
              {
                field: 'filial.id',
                value: subsidiaryFilters,
                operation: 'EQUAL',
              },
            ]);
          }
        }}
      />
    </div>
  );
}
