/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { Map, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import Leaflet from 'leaflet';

import { Modal } from 'reactstrap';
import api from 'services/api';
import polyline from '@mapbox/polyline';
import getIconVehicle from 'shared/IconVehicles';
import TruckIcon from '../../../assets/images/map/truck.svg';
import closeModalIcon from '../../../assets/images/closeIcon.svg';

export default function GetDriverPosition({ latitude, longitude, onClear }) {
  const [modal, setModal] = useState(true);
  let leafletMap = useRef();

  const [initialPosition, setInitialPosition] = useState([]);
  const [zoom, setZoom] = useState(13);
  const [positionDriver, setPositionDriver] = useState([]);
  const [cargoData, setCargo] = useState([]);
  const [jsonOSRM, setJsonOSRM] = useState([]);
  const [knowledge, setKnowledge] = useState([]);
  const [stepsActual, setStepsActual] = useState(null);
  const [multiplePoints, setMultiplePoints] = useState([]);

  const getPositionDriver = useCallback(async () => {
    try {
      setPositionDriver([latitude, longitude]);

      setInitialPosition([latitude, longitude]);

      setZoom(30);
    } catch (err) {}
  }, [latitude, longitude]);

  function clearNav() {
    setModal(!modal);
    onClear();
  }

  useEffect(() => {
    getPositionDriver();
  }, [getPositionDriver]);

  const pinIcon = new Leaflet.Icon({
    iconUrl: TruckIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  return (
    <Modal
      size="xl"
      isOpen={modal}
      // toggle={toggle}
      // className={className}
      dialogClassName="my-modal"
      style={{ maxWidth: '80%', maxHeight: '90%' }}
    >
      <div
        className=""
        style={{ height: 'auto', padding: 40, borderRadius: 10, zIndex: 90 }}
      >
        <div
          className="iconClose"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 20,
          }}
        >
          <button
            style={{ background: 'transparent', border: 'none' }}
            type="button"
            onClick={() => clearNav()}
          >
            <img src={closeModalIcon} alt="" />
          </button>
        </div>
        <p
          className="title"
          style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
        >
          Mapa do motorista
          {/* <Trans i18nKey="phases.smsPhases" /> */}
          {/* <Trans i18nKey="uploadCSV.select" /> */}
        </p>
        <></>
        {initialPosition.length > 0 && (
          <Map
            ref={m => {
              leafletMap = m;
            }}
            style={{
              maxHeight: 440,
              width: '100%',
              cursor: 'pointer',
            }}
            center={initialPosition}
            zoom={zoom}
            zoomControl
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {positionDriver.length > 0 && (
              <Marker
                position={positionDriver}
                icon={pinIcon}
                // data={point}
              />
            )}
          </Map>
        )}
      </div>
    </Modal>
  );
}
