import {
  ValueFormatterParams,
} from 'ag-grid-community';
import Grid from 'components/Grid/Grid';
import {
  ColumnDef,
  Filter,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { numberToCurrencyBRL } from 'util/format';
import ConfirmationDriver from './ConfirmationDriver';
import './styles.scss';
import { VehiclesData } from './types';

// import { Container } from './styles';

interface Props {
  idRomaneio: number;
  onClear: () => void;
  onChangeCargo: () => void;
  idSubsidiary: number;
  vehiclesId: number;
}

const ChangeVehicles: React.FC<Props> = ({
  idRomaneio,
  onClear,
  onChangeCargo,
  idSubsidiary,
  vehiclesId,
}: Props) => {
  /* Local States */
  const [selectedRows, setSelectedRows] = useState<VehiclesData[]>([]);
  const [rowData, setRowData] = useState<VehiclesData[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Defined columns list in grid.
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'placa',
      checkboxSelection: true,
      headerName: 'Placa',
      filter: true,
      width: 120,
    },
    {
      field: 'disponivel',
      headerName: 'Disponível',
      filter: true,
      width: 120,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      filter: true,
      width: 120,
    },
    {
      field: 'id',
      headerName: 'ID',
      filter: true,
      width: 90,
    },
    {
      field: 'motorista.nome',
      headerName: 'Motorista',
      filter: true,
      width: 120,
    },
    {
      field: 'quantidadeAtendimento',
      headerName: 'Atend. máx.',
      filter: true,
    },
    {
      field: 'peso',
      headerName: 'Peso máx.',
      filter: true,
    },
    {
      field: 'cubagem',
      headerName: 'Cub. máx.',
      filter: true,
    },
    {
      field: 'valor',
      headerName: 'Valor máx.',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        return numberToCurrencyBRL(params.value)
      },
    },
  ]);

  // Defined initial filters in list.
  const [filters] = useState<Filter[]>([
    {
      field: 'status',
      value: 'ACTIVE',
      operation: SearchOperation.EQUAL,
    },
    {
      field: 'filial.id',
      value: String(idSubsidiary),
      operation: SearchOperation.EQUAL,
    },
    {
      field: 'id',
      value: String(vehiclesId),
      operation: SearchOperation.NOT_EQUAL,
    },
  ]);

  // Auxiliary function for translation
  const { t } = useTranslation();

  return (
    <CustomModal isOpen label="Trocar Veículo" isClose={onClear} styles={{ minWidth: '60%' }}>
      <div className="tw-flex-1 tw-h-full">
        {showConfirmation && (
          <ConfirmationDriver
            idRomaneio={idRomaneio}
            cpfDriver={selectedRows[0].id}
            onClear={() => setShowConfirmation(!showConfirmation)}
            onConfirm={() => {
              onClear();
              setShowConfirmation(!showConfirmation);
            }}
            onLoad={onChangeCargo}
          />
        )}

        <Row className="mb-1">
          <Col md="12">
            <ButtonGroup>
              <div className="tw-flex tw-items-center">
                <DropdownButton
                  as={ButtonGroup}
                  title={t('options')}
                  className="dropdown-custom"
                >
                  <Dropdown.Item
                    className="btn-default"
                    eventKey="region"
                    onClick={() => {
                      if(selectedRows.length > 0) {
                        setShowConfirmation(!showConfirmation);
                      } else {
                        FrontendNotification('Para realizar a atualização é necessário selecionar os itens.', NotificationType.WARNING)
                      }
                    }}
                  >
                    Alterar Veículo
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </ButtonGroup>
          </Col>
        </Row>

        <Row className="tw-mb-2 tw-h-[400px]">
          <Col md="12">
            {/* Grid com os pré-conhecimentos. */}
            <Grid<VehiclesData>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.SINGLE}
              path="delivery/veiculos/pageable"
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              showCrudButtons={false}
              filters={filters}
              setRowData={setRowData}
            />
          </Col>
        </Row>
      </div>
    </CustomModal>
  );
};

export default ChangeVehicles;
