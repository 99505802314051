// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teste {
  font-size: 14px;
  color: #003049;
}
.teste:hover {
  transition: 0.5s;
  -webkit-text-decoration: underline black;
          text-decoration: underline black;
}

.boxOptions {
  background: #FFFFFF;
  /* 05 */
  border: 2px solid #EDF2F4;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.textPhases {
  font-size: 14px;
  color: #003049;
}

.titlePhases {
  font-family: "Lato";
  font-size: 15px;
  color: #003049;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/Phases/style.scss"],"names":[],"mappings":"AACA;EAKE,eAAA;EACA,cAAA;AAJF;AADE;EACE,gBAAA;EACA,wCAAA;UAAA,gCAAA;AAGJ;;AAEA;EACE,mBAAA;EACF,OAAA;EAEA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AAAA;;AAGA;EACE,eAAA;EACA,cAAA;AAAF;;AAGA;EACE,mBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AAAF","sourcesContent":["\n.teste {\n  &:hover{\n    transition: 0.5s;\n    text-decoration: underline black;\n  }\n  font-size: 14px;\n  color: #003049;\n}\n.boxOptions {\n  background: #FFFFFF;\n/* 05 */\n\nborder: 2px solid #EDF2F4;\nborder-radius: 10px;\npadding: 10px;\nmargin-top: 10px;\n}\n\n.textPhases {\n  font-size: 14px;\n  color: #003049;\n}\n\n.titlePhases {\n  font-family: 'Lato';\n  font-size: 15px;\n  color: #003049;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
