/* eslint-disable operator-assignment */
import produce from 'immer';

const INITIAL_STATE = {
  routeOptmiId: null,
  editSimulator: false,
  id_route_simulation: null,
  id_step: null,
  changeCargo: false,
  id_knowledge: null,
  isChangeRoute: false,
  isAddress: false,
  address: null,
  addressData: null,
  id_route_optmi: null,
  id_route_step_order: null,
};

export default function routeringSimulation(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@simulator/ADD_SIMULATOR_ROUTE_OPTMI': {
        draft.routeOptmiId = action.payload.routeOptmisId;
        break;
      }
      case '@simulator/EDIT_SIMULATOR_ROUTE': {
        draft.editSimulator = action.payload.editSimulator;
        break;
      }

      case '@simulator/SET_ID_SIMULATION': {
        draft.id_route_simulation = action.payload.id_route_simulation;
        break;
      }
      case '@simulator/SAVE_ID_STEP': {
        draft.id_step = action.payload.id_step;
        // draft.changeCargo = true;
        break;
      }
      case '@simulator/CLEAR': {
        draft.id_step = null;
        break;
        // draft.changeCargo = false;
      }
      case '@simulator/SET_ID_KNOWLEDGE': {
        draft.id_knowledge = action.payload.id_knowledge;
        break;
      }
      case '@simulator/SET_CHANGE_ROUTE': {
        draft.isChangeRoute = !draft.isChangeRoute;
        break;
      }

      case '@simulator/CLEAR_CHANGE_ROUTE': {
        draft.isChangeRoute = false;
        break;
      }

      case '@simulator/SET_ADDRESS_MAP': {
        draft.address = action.payload.address;
        draft.addressData = action.payload.addressData;
        draft.isAddress = !draft.isAddress;
        break;
      }

      case '@simulator/CLEAR_ADDRESS': {
        draft.isAddress = false;
        break;
      }
      case '@simulator/SAVE_ROUTE_OPTMI': {
        draft.id_route_optmi = action.payload.routeOptmiId;
        break;
      }

      case '@simulator/SAVE_ROUTE_STEP': {
        draft.id_route_step_order = action.payload.routeStep;
        break;
      }

      default:
    }
  });
}
