import produce from 'immer';

const INITIAL_STATE = {
  horaInicial: '',
  horaFinal: '',
};

export default function driverScheduling(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@driverScheduling/SET_DATA_DRIVER_SCHEDULING': {
        draft.horaInicial = action.payload.horaInicial;
        draft.horaFinal = action.payload.horaFinal;
        break;
      }
      case '@driverScheduling/CLEAR_DATA_DRIVER_SCHEDULING': {
        draft.horaInicial = '';
        draft.horaFinal = '';
        draft.horariosPausa = [];
        break;
      }
      default:
    }
  });
}
