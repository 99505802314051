import React from 'react';

import './style.scss';

export default function ProgressBar({ value, widthProgress, topProgress }) {
  let progressBar = (
    <div
      className="progressBar text-right"
      style={{ flex: 1, width: `${value}%`, minWidth: '20%' }}
    >
      {value}%
    </div>
  );

  if (value === 0) {
    progressBar = <div className="noProgress text-right">{`${value}%`}</div>;
  }

  return (
    <div
      id="progressBarMain"
      className="progressBarContainer"
      style={{ width: widthProgress, marginTop: topProgress, marginTop: 10 }}
    >
      {progressBar}
    </div>
  );
}
