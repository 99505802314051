// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lineGroupSimulation {
  border-bottom: 1px solid #ccd6db;
}

.pagination-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.customFilter-filter {
  padding: 4px;
  width: 200px;
}

.customFilter-filter > div:first-child {
  font-weight: bold;
}

.customFilter-filter > div:not(:last-child) {
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/RouteringSimulations/styles.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAGA;EACI,YAAA;EACA,YAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".lineGroupSimulation {\n    border-bottom: 1px solid #ccd6db;\n}\n\n.pagination-footer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20px;\n}\n\n// Filters\n.customFilter-filter {\n    padding: 4px;\n    width: 200px;\n}\n\n.customFilter-filter > div:first-child {\n    font-weight: bold;\n}\n\n.customFilter-filter > div:not(:last-child) {\n    margin-bottom: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
