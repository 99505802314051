import React from 'react';
import { Input } from 'reactstrap';
import './styles.scss';

export default function InputCustom({
  placeholder,
  size,
  onChange,
  value,
  type,
}) {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      style={{
        width: size,

        height: 30,
        borderRadius: '10px',
        marginRight: 10,
        // marginBottom: 11,
        fontFamily: 'Lato',
        fontSize: 12,
      }}
    />
  );
}
