import React from 'react';
import alertCargo from '../../../../../assets/images/alertCargo.svg';
import Kpi from '../Kpis';
import { Container } from './styles';


interface KpiDataCargoType {
  value: number;
  name: string;
  label: string;
  itemStyle: {
    color: string;
  };
}

interface KpiDataCargoProps {
  data: any;
}

const KpiDataCargo: React.FC<KpiDataCargoProps> = ({
  data,
}: KpiDataCargoProps) => {
  const option = {
    tooltip: {
      trigger: 'item',
      show: false,
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '65%',
        // center: ['50%', '60%'],
        legend: [
          {
            label: 'Atendimentos',
            icon: alertCargo,
          }
        ],
        data: [
          {
            value: data !== null && data.totalEntregas,
            name: null,
            label: 'Total',
            itemStyle: {
              color: '#000000',
            },
          },
          {
            value: data !== null && data.totalEmAtendimento,
            name: data !== null && data.totalEmAtendimentoPerc,
            label: 'Em atendimento',
            itemStyle: {
              color: '#FC8800',
            },
          },

          {
            formatter: '{a} <br/>{b} : {c} ({d}%)',
            value: data !== null && data.totalEfetivadas,
            name: data !== null && data.totalEfetivadasPerc,
            label: 'Sucesso',
            itemStyle: {
              color: '#0DCE15',
            },
          },
          {
            value: data !== null && data.totalInsucessos,
            name: data !== null && data.totalInsucessosPerc,
            label: 'Insucesso',
            itemStyle: {
              color: '#EA004C',
            },
          },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <Container>
    
       
        <Kpi legend={option.series[0].legend} data={option.series[0].data} isSizeLess />
    </Container>
  );
};

export default KpiDataCargo;
