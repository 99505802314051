/* eslint-disable prefer-const */
import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import history from 'services/history';

import i18n from 'i18next';
import {
  signFailure,
  signInSuccess,
  signUpFailure,
  signUpSuccess,
} from './actions';
import { FrontendNotification, NotificationType } from 'core/common/Notification';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const emailSendLanguage =
      navigator.language === 'pt' || navigator.language === 'pt-BR'
        ? 'pt_BR'
        : 'en_US';

    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_API_URL}/auth?lang=${emailSendLanguage}`,
      {
        username: email,
        password,
        type: 'WEB',
      },
    );

    const { data } = response;

    if (data.primeiroAcesso === true) {
      window.open(`${process.env.REACT_APP_URL}/dashboard`);
      yield put(
        signInSuccess(data.token, email, data.primeiroAcesso, data.usuario)
      );
      window.location.reload();
    } else {
      history.push('/dashboard');
      yield put(
        signInSuccess(data.token, email, data.primeiroAcesso, data.usuario)
      );
      window.location.reload();
    }
    // } else {
    //   yield put(signFailure());
    //   // BackendErrorNotification(response);
  } catch (error) {
    // BackendErrorNotification(error);
    const getLanguage = i18n.language;


    if (error.response.data.message === 'User without account activation.') {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Para realizar o login, é necessário ativar sua conta pelo link enviado por e-mail. Por favor, verifique sua caixa de entrada.',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'In order to login, you must activate your account via the link sent to your e-mail. Please check your e-mail inbox.',
            NotificationType.ERROR
          );
    }  else if (error.response.data.message === 'Invalid username/password.') {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Email e senha invalidos!',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'Invalid username/password',
            NotificationType.ERROR
          );
    } else {
      FrontendNotification(error.response.data.message, NotificationType.ERROR);
    }


    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    // api.defaults.timeout = 50000;
    const { email, password, name } = payload;
    const response = yield call(api.post, 'accounts/user/register', {
      email,
      password,
      name,
    });
    if (response.data && response.data.ok) {
      yield put(signUpSuccess());
    } else {
      yield put(signUpFailure());
    }
    // api.defaults.timeout = 30000;
  } catch (error) {
    yield put(signUpFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) {
    return;
  }
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

export function setTokenInitial({ payload }) {
  if (!payload) {
    return;
  }
  const { token } = payload;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenInitial),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGNUP', signUp),
]);
