// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unCheckedCargoRoutering {
  border: 2px solid #6369D1;
  box-sizing: border-box;
  border-radius: 2px;
  width: 16px;
  height: 16px;
}

.checkedCargoRoutering {
  background: #6369D1;
  border-radius: 4px;
  border: none;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/RouterCargo/components/CargasRouting/components/CheckBox/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACF,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACA;;AACA;EACE,mBAAA;EACF,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEA","sourcesContent":[".unCheckedCargoRoutering {\n  border: 2px solid #6369D1;\nbox-sizing: border-box;\nborder-radius: 2px;\nwidth: 16px;\nheight: 16px;\n}\n.checkedCargoRoutering {\n  background: #6369D1;\nborder-radius: 4px;\nborder: none;\nwidth: 16px;\nheight: 16px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
