// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleLoading {
  font-size: 24px;
  font-weight: bold;
  color: #003049;
  margin-bottom: 30px;
}

.resultContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.resultTitle {
  font-size: 20px;
  font-weight: bold;
  color: #003049;
}

.resultContent {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #003049;
}`, "",{"version":3,"sources":["webpack://./src/components/IntegrationsBling/LoadingBling/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF","sourcesContent":[".titleLoading {\n  font-size: 24px;\n  font-weight: bold;\n  color: #003049;\n  margin-bottom: 30px;\n}\n\n.resultContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.resultTitle {\n  font-size: 20px;\n  font-weight: bold;\n  color: #003049;\n}\n\n.resultContent {\n  text-align: center;\n  font-size: 12px;\n  font-weight: bold;\n  color: #003049;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
