import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import publishIcon from 'assets/images/publishIcon.svg';
import publishIconActive from 'assets/images/publishIconActive.svg';
import { IconColumn } from 'components/Driver/styles';
import React from 'react';
import { useTable } from 'react-table';
import { Button } from 'reactstrap/lib';
import lockedClose from '../../assets/images/lockedClose.svg';
import lockedOpen from '../../assets/images/lockedOpen.svg';
import viewTemplateIcon from '../../assets/images/viewTemplateIcon.png';

interface TableProps {
  showEdit?: boolean,
  showBlocked?: boolean,
  showPublished?: boolean | JSX.Element[],
  showDelete?: boolean,
  showView?: boolean,
  columns?: any
  data?: any,
  handleEdit?: any,
  handleView?: any,
  handleDelete?: any,
  handlePublished?: any,
  handleBlocked?: any
}

const CustomTable: React.FC<TableProps> = ({ showEdit, showBlocked, showPublished, showDelete, showView, columns, data, handleEdit, handleView, handleDelete, handlePublished, handleBlocked }: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any, i: any) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()} className='lineGroup'>
              {row.cells.map((cell: any) => {
                return (
                  <>
                    {cell.column === 'Grupo de envio' ||
                      cell.column === 'Send Group' ? (
                      <td {...cell.getCellProps()}>
                        {cell.value === 'ALL_SUBSIDIARY'
                          ? 'Selecionados'
                          : 'Todos'}
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )}
                  </>
                )
              })}
              <IconColumn>
              {showPublished && (
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      handlePublished(row.original.id);
                    }}
                  >
                      <img
                        src={row.original.publicado === 'SIM' ? publishIconActive : publishIcon}
                        alt=""
                      />                   
                  </Button>
                )}
                {showView && row.original.publicado === 'SIM' && (
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      handleView(row.original.id);
                    }}
                  >
                    <img src={viewTemplateIcon} alt="" />
                  </Button>
                )}
                {showEdit && row.original.publicado !== 'SIM' && (
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      handleEdit(row.original.id);
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt=""
                    />
                  </Button>
                )}

                {showBlocked && (
                  <Button
                  color="link"
                  className="noMargin"
                  onClick={() => {
                    handleBlocked(row.original.id);
                  }}
                >
                  <img
                    src={row.original.bloqueado ? lockedClose : lockedOpen}
                    alt=""
                  />
                </Button>
                )}

                {showDelete && (
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      handleDelete(row.original.id);
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt=""
                    />
                  </Button>
                )}
              </IconColumn>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default CustomTable;