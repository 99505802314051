import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import TopBar from 'util/TopBar';

export default function Reports() {
  const [findFilters, setFindFilters] = React.useState();

  const urlReport = useSelector(state => state.reports.url);
  const [params, setParams] = useState(urlReport);

  const urlParams = sessionStorage.getItem('urlParams');

  const responseServiceFindByFilters = useCallback(async () => {
    try {
      const data = {
        filters: [{ field: 'status', value: 'ACTIVE', operation: 'EQUAL' }],
      };

      const response = await api.post('/cadastro/filiais/findByFilters', data);
      setFindFilters(response.data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    responseServiceFindByFilters();
  }, [responseServiceFindByFilters]);
  return (
    <>
      <TopBar listSubsidiary={findFilters} onChangeSubsidiary={() => {}} />
      <div
        style={{
          display: 'flex',
          padding: 20,
          width: '100%',
          height: '87vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <iframe
          style={{ width: '80%', height: '87vh', marginTop: 20 }}
          src={urlParams}
          frameBorder="0"
          // style="border:0"
          allowFullScreen
        />
      </div>
    </>
  );
}
