/* eslint-disable prefer-const */
import closeModalIcon from 'assets/images/closeIcon.svg';
import { FrontendNotification } from 'components/Notification';
import {
  NotificationType
} from 'core/common/Notification';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import api from 'services/api';
import './styles.scss';


interface ConfirmationDriverProps {
  idRomaneio?: any;
  cpfDriver?: any;
  onClear: () => void;
  onConfirm?: any;
  onLoad: () => void;
}

export default function ConfirmationDriver({
  idRomaneio,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
}: ConfirmationDriverProps) {
  const [modal, setModal] = useState(true);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(async () => {
    try {
      const dataSubmit = {
        id: idRomaneio,
        idMotorista: cpfDriver,
      };

      const response = await api.delete(`/route/optmis/${idRomaneio}`);
      if (response.status === 204) {
        FrontendNotification(
          t('changeVehicles.messageSuccessDelete'),
          NotificationType.SUCCESS
        );
        setModal(!modal);
        onClear();
        onLoad();
        onConfirm();
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%' }}
      >
        <div className="modalWrapperVehicles" style={{ padding: '20px' }}>
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            Confirma remoção
            {/* <Trans i18nKey="changeDriver.title" /> */}
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="routeOptmization.routeOptmis.messageConfirmationDelete" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeDriver.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => handleSubmit()}
              >
                <Trans i18nKey="changeDriver.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
