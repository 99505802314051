import { object, ref, string } from 'yup';

export default object().shape({
  cpf: string().required('* Enter the cpf!'),
  nome: string()
    .required('* Enter the name!')
    .max(50, '* Maximum of 50 characters'),
  telefone: string().required('* Enter the phone!'),
  tipo: string().required('* Enter the type!'),
  confirmarSenha: string().when('password', (password, field) =>
    password
      ? field
          .required('* Repita sua senha!')
          .min(6, '* Mínimo 6 caracteres!')
          .oneOf([ref('password')], '* Senhas não coincidem!')
      : field
  ),
  filial: string().required('* Enter the subsidiary'),
});
