// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cleanFiltersCSV {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  /* 06 */
  color: #EA004C;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadCSV/CorrectCSV/Header/styles.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACJ,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EAEA,OAAA;EAEA,cAAA;EAEA,eAAA;AAFA","sourcesContent":[".cleanFiltersCSV {\n    font-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 16px;\nline-height: 19px;\n\n/* 06 */\n\ncolor: #EA004C;\n\ncursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
