import React from 'react';
import alertVehicle from '../../../../../assets/images/alertTruck.svg';
import Kpis from '../Kpis';
import { Container } from './styles';

interface KpiDataVehicleType {
  value: number;
  name: string;
  label: string;
  itemStyle: {
    color: string;
  };
}

interface KpiDataVehicleProps {
  data: any;
}

const KpiDataVehicle: React.FC<KpiDataVehicleProps> = ({ data }: KpiDataVehicleProps) => {
  
  const option = {
    tooltip: {
      trigger: 'item',
      show: false,
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: '65%',
        // center: ['50%', '60%'],
        legend: [
          {
            label: 'Veículos',
            icon: alertVehicle
          }
        ],
        data: [
          {
            formatter: '{a} <br/>{b} : {c} ({d}%)',
            value: data !== null && data.totalVeiculos,
            name: null,
            label: 'Total',
            itemStyle: {
              color: '#000000',
            },
          },
          {
            value: data !== null && data.totalVeiculosEmOperacaoProprio,
            name: data !== null && data.totalVeiculosEmOperacaoProprioPerc,
            label: 'Em operação próprio',
            itemStyle: {
              color: '#08B639',
            },
          },
          {
            value: data !== null && data.totalVeiculosEmOperacaoTerceirizado,
            name: data !== null && data.totalVeiculosEmOperacaoTerceirizadoPerc,
            label: 'Em operação terceirizado',
            itemStyle: {
              color: '#545454',
            },
          },
          {
            value: data !== null && data.totalVeiculosEmAtendimento,
            name: data !== null && data.totalVeiculosEmAtendimentoPerc,
            label: 'Em atendimento',
            itemStyle: {
              color: '#FC8800',
            },
          },
          {
            value: data !== null && data.totalVeiculosEmAtraso,
            name: data !== null && data.totalVeiculosEmAtrasoPerc,
            label: 'Em atraso',
            itemStyle: {
              color: '#D62828',
            },
          },
          {
            value: data !== null && data.totalVeiculosRetornandoBase,
            name: data !== null && data.totalVeiculosRetornandoBasePerc,
            label: 'Voltando à base',
            itemStyle: {
              color: '#6369D1',
            },
          },
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };

  return (
    <Container>
      {/* <ReactEcharts option={option} style={{ width: 350, height: 200 }} /> */}

      {/* <Kpi /> */}

      <Kpis legend={option.series[0].legend} data={option.series[0].data} />

      {/* {option.series.map(item => (
        <WrapperLegend>
          {item.data.map(opt => (
            <Legend color={opt.itemStyle.color} text={opt.label} />
          ))}
        </WrapperLegend>
      ))} */}
    </Container>
  );
};

export default KpiDataVehicle;
