/* eslint-disable no-nested-ternary */
import { useFormik } from 'formik';
import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';

import api from '../../services/api';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from '../Notification';

import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

import closeModalIcon from '../../assets/images/closeIcon.svg';
import {
  BoxForm,
  CustomDatePicker,
  CustomMultiSelect,
  Footer,
  GroupInput,
  SendButton,
} from './styles';

import './style.scss';

import ListNotification from './ListNotification';

export default function SendNotification({ onClear }) {
  const userData = useSelector(state => state.auth.user);

  const { t } = useTranslation();
  const [modal, setModal] = useState(true);
  const [edit, setEdit] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [driver, setDriver] = useState([]);
  const [perPage, setPerPage] = useState(10);

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [notificationId, setNotificationId] = useState(0);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [group, setGroup] = useState([
    {
      id: 1,
      name:
        i18next.language === 'pt' || i18next.language === 'pt-BR'
          ? 'Motoristas da empresa'
          : 'Company Drivers',
      value: 'ALL_COMPANY',
    },
    {
      id: 2,
      name:
        i18next.language === 'pt' || i18next.language === 'pt-BR'
          ? 'Motoristas da filial'
          : 'Subsidiary Drivers',
      value: 'ALL_SUBSIDIARY',
    },
  ]);
  const [tabs, setTabs] = useState(0);

  const getNotificationList = useCallback(async idSubsidiary => {
    try {
      const dataFilter = {
        filters: [
          {
            field: 'filial.id',
            value: idSubsidiary,
            operation: 'EQUAL',
          },
        ],
      };
      const { data } = await api.get('/delivery/notificacoes');

      padronizeNotifications(data);
    } catch (err) {}
  }, []);

  const formik = useFormik({
    initialValues: {
      sendGroup: '',
      title: '',
      date: '',
      description: '',
      selectedDrivers: '',
    },
    validationSchema:
      i18next.language === 'pt' || i18next.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: values => scheduleNotification(values),
  });

  const formik2 = useFormik({
    initialValues: {
      sendGroup: '',
      title: '',
      date: '',
      description: '',
      selectedDrivers: '',
    },
    validationSchema:
      i18next.language === 'pt' || i18next.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: values => {
      updateNotification(values);
    },
  });

  const updateNotification = useCallback(
    async values => {
      try {
        const date = new Date();
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const dataSend = new Date(values.date.getTime() - userTimezoneOffset);

        const dataEnvio =
          `${dataSend.toISOString().slice(0, 10)}` +
          ` ` +
          `${dataSend.toISOString().slice(11, 19)}`;

        let sendDrivers = [];
        if (values.sendGroup === 'ALL_SUBSIDIARY') {
          sendDrivers = await Promise.all(
            selectedDrivers.map(element => {
              return { motorista: { id: element.id } };
            })
          );
        }

        await api.patch(`/delivery/notificacoes/${notificationId}`, {
          titulo: values.title,
          descricao: values.description,
          tipo: values.sendGroup,
          dataEnvio,
          motoristas:
            values.sendGroup === 'ALL_SUBSIDIARY' ? sendDrivers : null,
        });
        FrontendNotification(
          t('notifications.messageSuccessUpdate'),
          NotificationType.SUCCESS
        );
        formik2.handleReset({});
        getNotificationList();
        setEdit(false);
        setTabs(0);
      } catch (err) {
        // setLoading(false);
        FrontendNotification(
          t('notifications.messageErrorUpdate'),
          NotificationType.ERROR
        );
      }
    },
    [formik2, getNotificationList, notificationId, selectedDrivers, t]
  );

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const handleColor = time => {
    return time.getHours() > 12 ? 'text-success' : 'text-error';
  };

  const getDriverList = useCallback(async idSubsidiary => {
    try {
      const data = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
          {
            field: 'filial.id',
            value: idSubsidiary,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/motoristas/findByFilters',
        data
      );

      const allDrivers = [];
      response.data.map(async element => {
        allDrivers.push({
          label: element.nome,
          value: element.id,
          cpf: element.cpf,
        });
      });
      setDriver(allDrivers);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  function padronizeNotifications(notify) {
    const padronizedNotify = [];
    notify.map(async element => {
      padronizedNotify.push({
        id: element.id,
        dataEnvio: element.dataEnvio,
        dataModificacao: element.dataModificacao,
        tipo:
          element.tipo === 'ALL_SUBSIDIARY'
            ? i18next.language === 'pt-BR'
              ? 'Selecionados'
              : 'Selected'
            : i18next.language === 'pt-BR'
            ? 'Todos'
            : 'All',
        titulo: element.titulo,
      });
    });
    setNotifications(padronizedNotify);
  }

  const deleteDriver = useCallback(async id => {
    try {
      // setRemoveConfirmation(true);
      const response = await api.delete(`/delivery/notificacoes/${id}`);

      if (response.status === 204 || response.status === 200) {
        FrontendNotification(
          t('notifications.messageSuccessDelete'),
          NotificationType.SUCCESS
        );
        setEdit(false);
        formik.resetForm({});
      } else {
        FrontendNotification(
          t('notifications.messageErrorDelete'),
          NotificationType.ERROR
        );
      }

      setRemoveConfirmation(false);
      getNotificationList();
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const scheduleNotification = useCallback(
    async values => {
      try {
        let sendDrivers = [];
        if (values.sendGroup === 'ALL_SUBSIDIARY') {
          sendDrivers = await Promise.all(
            selectedDrivers.map(element => {
              return { motorista: { id: element } };
            })
          );
        }
        const date = new Date();
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const dataSend = new Date(values.date.getTime() - userTimezoneOffset);

        const response = await api.post('delivery/notificacoes', {
          titulo: values.title,
          descricao: values.description,
          tipo: values.sendGroup,
          dataEnvio: dataSend,
          motoristas:
            values.sendGroup === 'ALL_SUBSIDIARY' ? sendDrivers : null,
        });
        if (response.status === 201 || response.status === 200) {
          FrontendNotification(
            t('notifications.messageSuccess'),
            NotificationType.SUCCESS
          );
          formik.handleReset();
          getNotificationList();
        } else {
          FrontendNotification(
            t('notifications.messageError'),
            NotificationType.ERROR
          );
        }
      } catch (err) {
        // setLoading(false);
        BackendErrorNotification(err);
      }
    },
    [formik, getNotificationList, selectedDrivers, t]
  );

  const getNotificationInfo = useCallback(
    async id => {
      try {
        const promise = api.get(`/delivery/notificacoes/${id}`);

        const { data } = await Promise.resolve(promise);
        const { dataEnvio, descricao, titulo, tipo, motoristas } = data;
        formik2.setFieldValue('sendGroup', tipo);
        formik2.setFieldValue('title', titulo);
        formik2.setFieldValue('date', new Date(dataEnvio));
        const allDrivers = [];
        formik2.setFieldValue('description', descricao);
        motoristas.map(element => {
          allDrivers.push(element.motorista.id);
        });

        setSelectedDrivers(allDrivers);
        getDriverList(userData.filiais[0].id);
      } catch (err) {}
    },
    [formik2, getDriverList, userData]
  );

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  useEffect(() => {
    getNotificationList();
    if (formik.values.sendGroup === 'ALL_SUBSIDIARY' && !edit) {
      getDriverList(userData.filiais[0].id);
    }
  }, [formik.values.sendGroup, getDriverList, userData]);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteDriver(notificationId)}>
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={toggleRemove}>
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', maxHeight: '75%' }}
      >
        <div className="boxModal">
          <div className="iconCloseView">
            <button
              className="buttonClose"
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <p className="title">
            {/* Nova Notificação */}
            {tabs === 0 ? (
              <Trans i18nKey="notifications.titleField" />
            ) : (
              <Trans i18nKey="notifications.scheduleNotification" />
            )}
          </p>
          <Tabs
            selectedIndex={tabs}
            onSelect={index => {
              setTabs(index);
            }}
          >
            <TabList>
              <Tab>
                <Trans i18nKey="tabs.list" />
              </Tab>
              <Tab>
                <Trans i18nKey="tabs.new" />
              </Tab>
              <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            </TabList>
            <TabPanel>
              <ListNotification
                data={notifications}
                handleEdit={id => {
                  setTabs(2);
                  setEdit(true);
                  setNotificationId(id);

                  getNotificationInfo(id);
                }}
                handleDelete={id => {
                  setNotificationId(id);
                  setRemoveConfirmation(true);
                }}
              />
            </TabPanel>

            <TabPanel>
              <div className="d-flex">
                <BoxForm style={{ width: '100%' }}>
                  <Row>
                    <Col md={6}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.title" />
                      </Label>
                      <Input
                        type="text"
                        name="select"
                        maxLength={128}
                        id="SelectState"
                        placeholder={t('notifications.placeholder.title')}
                        onChange={formik.handleChange('title')}
                        value={formik.values.title}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <p className="textError">{formik.errors.title}</p>
                      )}
                    </Col>

                    <Col md={6}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.dateSend" />
                      </Label>
                      <div className="customDatePickerWidth">
                        <CustomDatePicker
                          showTimeSelect
                          selected={formik.values.date}
                          onChange={date => {
                            formik.setFieldValue('date', date);
                          }}
                          timeClassName={handleColor}
                          placeholderText={t(
                            'notifications.placeholder.dateSend'
                          )}
                          locale="pt-BR"
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat="Pp"
                          wrapperClassName="datePicker"
                        />
                      </div>
                      {formik.touched.date && formik.errors.date && (
                        <p className="textError">{formik.errors.date}</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.description" />
                      </Label>
                      <Input
                        type="textarea"
                        name="select"
                        id="SelectState"
                        wrap
                        maxLength={255}
                        placeholder={t('notifications.placeholder.description')}
                        onChange={formik.handleChange('description')}
                        style={{
                          height: 196,
                          resize: 'none',
                          background: '#FFFFFF',
                          border: '2px solid rgba(0, 48, 73, 0.2)',
                          boxSizing: 'border-box',
                          borderRadius: 10,
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#003049',
                          overflowY: 'hidden',
                        }}
                        value={formik.values.description}
                      />
                      {formik.touched.description &&
                        formik.errors.description && (
                          <p className="textError">
                            {formik.errors.description}
                          </p>
                        )}
                    </Col>
                  </Row>

                  <Footer>
                    <GroupInput>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.whoSend" />
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        id="group"
                        placeholder={t('notifications.fields.group')}
                        onChange={formik.handleChange('sendGroup')}
                        value={formik.values.sendGroup}
                      >
                        <option label={t('notifications.fields.group')} />
                        {group.map(item => (
                          <option value={item.value}>
                            {t(`${item.name.toString()}`)}
                          </option>
                        ))}
                      </Input>
                      {formik.touched.sendGroup && formik.errors.sendGroup && (
                        <p className="textError">{formik.errors.sendGroup}</p>
                      )}
                    </GroupInput>
                    {formik.values.sendGroup === 'ALL_SUBSIDIARY' && (
                      <GroupInput>
                        <Label for="SelectState" className="labelForm">
                          <Trans i18nKey="notifications.fields.selectDrivers" />
                        </Label>
                        <CustomMultiSelect
                          options={driver}
                          selected={selectedDrivers}
                          onSelectedChanged={selected => {
                            setSelectedDrivers(selected);
                          }}
                          overrideStrings={{
                            selectSomeItems:
                              i18next.language === 'pt-BR'
                                ? 'Selecione os motoristas'
                                : 'Select some drivers...',
                            allItemsAreSelected:
                              i18next.language === 'pt-BR'
                                ? 'Todos os motoristas foram selecionados'
                                : 'All drivers are Selected',
                            selectAll:
                              i18next.language === 'pt-BR'
                                ? 'Selecionar todos'
                                : 'Select All',
                            search:
                              i18next.language === 'pt-BR'
                                ? 'Pesquisar'
                                : 'Search',
                          }}
                        />
                      </GroupInput>
                    )}
                    <SendButton type="submit" onClick={formik.handleSubmit}>
                      <Trans i18nKey="notifications.scheduleNotification" />
                    </SendButton>
                  </Footer>
                </BoxForm>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="d-flex">
                <BoxForm style={{ width: '100%' }}>
                  <Row>
                    <Col md={6}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.title" />
                      </Label>
                      <Input
                        type="text"
                        name="select"
                        maxLength={128}
                        id="SelectState"
                        placeholder={t('notifications.placeholder.title')}
                        onChange={formik2.handleChange('title')}
                        value={formik2.values.title}
                      />
                      {formik2.touched.title && formik2.errors.title && (
                        <p className="textError">{formik2.errors.title}</p>
                      )}
                    </Col>

                    <Col md={6}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.dateSend" />
                      </Label>
                      <div className="customDatePickerWidth">
                        <CustomDatePicker
                          showTimeSelect
                          selected={formik2.values.date}
                          onChange={date => {
                            formik2.setFieldValue('date', date);
                          }}
                          timeClassName={handleColor}
                          placeholderText={t(
                            'notifications.placeholder.dateSend'
                          )}
                          locale="pt-BR"
                          timeFormat="p"
                          timeIntervals={15}
                          dateFormat="Pp"
                          wrapperClassName="datePicker"
                        />
                      </div>
                      {formik2.touched.date && formik2.errors.date && (
                        <p className="textError">{formik2.errors.date}</p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.description" />
                      </Label>
                      <Input
                        type="textarea"
                        name="select"
                        id="SelectState"
                        wrap
                        maxLength={255}
                        placeholder={t('notifications.placeholder.description')}
                        onChange={formik2.handleChange('description')}
                        style={{
                          height: 196,
                          resize: 'none',
                          background: '#FFFFFF',
                          border: '2px solid rgba(0, 48, 73, 0.2)',
                          boxSizing: 'border-box',
                          borderRadius: 10,
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: 14,
                          color: '#003049',
                          overflowY: 'hidden',
                        }}
                        value={formik2.values.description}
                      />
                      {formik2.touched.description &&
                        formik2.errors.description && (
                          <p className="textError">
                            {formik2.errors.description}
                          </p>
                        )}
                    </Col>
                  </Row>

                  <Footer>
                    <GroupInput>
                      <Label for="SelectState" className="labelForm">
                        <Trans i18nKey="notifications.fields.whoSend" />
                      </Label>
                      <Input
                        type="select"
                        name="select"
                        id="group"
                        placeholder={
                          <Trans i18nKey="notifications.fields.group" />
                        }
                        onChange={formik2.handleChange('sendGroup')}
                        value={formik2.values.sendGroup}
                      >
                        <option label={t('notifications.fields.group')} />
                        {group.map(item => (
                          <option value={item.value}>
                            {t(`${item.name.toString()}`)}
                          </option>
                        ))}
                      </Input>
                    </GroupInput>
                    {formik2.values.sendGroup === 'ALL_SUBSIDIARY' && (
                      <GroupInput>
                        <Label for="SelectState" className="labelForm">
                          <Trans i18nKey="notifications.fields.selectDrivers" />
                        </Label>
                        <CustomMultiSelect
                          options={driver}
                          selected={selectedDrivers}
                          onSelectedChanged={selected => {
                            setSelectedDrivers(selected);
                          }}
                          overrideStrings={{
                            selectSomeItems:
                              i18next.language === 'pt-BR'
                                ? 'Selecione os motoristas'
                                : 'Select some drivers...',
                            allItemsAreSelected:
                              i18next.language === 'pt-BR'
                                ? 'Todos os motoristas foram selecionados'
                                : 'All drivers are Selected',
                            selectAll:
                              i18next.language === 'pt-BR'
                                ? 'Selecionar todos'
                                : 'Select All',
                            search:
                              i18next.language === 'pt-BR'
                                ? 'Pesquisar'
                                : 'Search',
                          }}
                        />
                      </GroupInput>
                    )}
                    <SendButton type="submit" onClick={formik2.handleSubmit}>
                      <Trans i18nKey="notifications.scheduleNotification" />
                    </SendButton>
                  </Footer>
                </BoxForm>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
