import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowFowardIos from '@mui/icons-material/ArrowForwardIos';
import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';

interface Props {
  title: string;
  onClear: () => void;
  images: string[];
}


const Carroussel: React.FC<Props> = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? props.images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === props.images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <CustomModal isOpen label={props.title} isClose={props.onClear}>

    <div className='tw-max-w-lg tw-mx-auto'>
      <div className='tw-relative tw-overflow-hidden tw-rounded-lg'>
      <div
          className="tw-flex tw-transition-transform tw-ease-out tw-duration-300"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {props.images.map((image, index) => (
            <img key={index} src={image} className="tw-w-full tw-flex-shrink-0"  style={{ minWidth: '400px', maxHeight: 350 }} />
          ))}
        </div>
        <button
          className="tw-absolute tw-top-1/2 tw-left-2 tw-transform tw--translate-y-1/2 tw-bg-[#fff] tw-p-2 tw-rounded-full tw-shadow-md tw-border-none tw-flex tw-items-center tw-justify-center"
          onClick={prevSlide}
        >
          <ArrowBackIosIcon color='error' style={{ display: 'flex', justifyContent: 'center' }} />
        </button>
        <button
          className="tw-absolute tw-top-1/2 tw-right-2 tw-transform tw--translate-y-1/2 tw-bg-[#fff] tw-p-2 tw-rounded-full tw-shadow-md tw-border-none"
          onClick={nextSlide}
        >
          <ArrowFowardIos color='error' style={{ display: 'flex', justifyContent: 'center' }} />
        </button>
      </div>
    </div>
    </CustomModal>
  );
}

export default Carroussel;