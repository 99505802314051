import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Title = styled.span`
  font-size: 0.938rem;
  color: #ffff;
  margin-left: 0.625rem;
`;

export const CloseModal = styled.div`
  position: absolute;
  margin-bottom: 0.230rem;
  cursor: pointer;
  right: 0;
`;
