import React from 'react';
import MoreComponent from './More';
import {
  Container,
  Header,
  LoadAnimation,
  More,
  Wrapper
} from './styles';

interface CustomComponentProps {
  title: string;
  icon?: boolean;
  imageUrl?: string;
  loading?: boolean;
  animationResource?: JSX.Element;
  onFilter: () => void;
  onUpdate: () => void;
  disabledOptions?: boolean;
  styles?: any;
  color?: any;
}

const CustomComponent: React.FC<CustomComponentProps> = ({
  title,
  icon,
  imageUrl,
  loading,
  animationResource,
  children,
  onFilter,
  onUpdate,
  disabledOptions,
  styles,
  color,
}) => {
  return (
    <Wrapper
      className={loading === false ? 'loadingComponent' : undefined}
      style={styles}
    >
      <Container
        className={loading === false ? 'loadingComponent' : undefined}
        color={color}
      >
        <div />
        <Header>
          {/* {icon && <Icon src={imageUrl} />}
          <Title>{title}</Title> */}
        </Header>
      </Container>
      {loading && <LoadAnimation>{animationResource}</LoadAnimation>}
      <div style={{ display: loading ? 'none' : 'unset' }}>
        <div style={{ position: 'relative' }}>
          {!disabledOptions && (
            <More>
              <MoreComponent onFilter={onFilter} onUpdate={() => onUpdate()} />
              {/* <MoreIcon src={moreIcon} /> */}
            </More>
          )}
        </div>

          {children}
      </div>
    </Wrapper>
  );
};

export default CustomComponent;
