import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { DataRouteringCargas } from 'store/modules/routering/actions';
import iconCargoAction from '../../../../../../../../assets/images/orderRightCargo.svg';
// import { Container } from './styles';

interface Props {
  onExpand: () => void;
  data: DataRouteringCargas;
}

const CardCompacted: React.FC<Props> = ({ onExpand, data }: Props) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <div
        className="tw-flex tw-items-center tw-cursor-pointer tw-w-max-6"
        onClick={(e: any) => {
          e.stopPropagation();
          onExpand();
        }}
      >
        <AddIcon color="error" />
        <span className="tw-text-sm tw-font-bold tw-text-[#003049] tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden">
          {data.destiny}
        </span>
      </div>
      <div>
        <span className="tw-text-sm tw-text-[#003049] tw-text-nowrap">
          {data.vehicle.placa}
        </span>
      </div>
      <div className="actionCargo">
        <img src={iconCargoAction} alt="click cargo" />
      </div>
    </div>
  );
};

export default CardCompacted;
