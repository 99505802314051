import MoreAswerIcon from 'assets/images/moreAswer.svg';
import React from 'react';
import Tooltip from '../../Tooltip';

interface Props {
  message?: JSX.Element;
  icon?: any;
  onClick?: any;
  isAllowedIcon?: boolean;
  children?: JSX.Element;
}

const CustomTooltip: React.FC<Props> = ({
  message,
  icon,
  onClick,
  isAllowedIcon = true,
}: Props) => {
  return (
    <Tooltip isDescription content={message}>
      {isAllowedIcon && (
        <img
          src={icon ? icon : MoreAswerIcon}
          style={{
            cursor: 'pointer',
            // marginLeft: 10,
            position: 'relative',
            right: 2,
            top: -2,
          }}
          onClick={onClick}
          alt=""
          onMouseEnter={e => {
            // setCoordenates({
            //   clientX: e.clientX,
            //   clientY: e.clientY,
            // });
          }}
        />
      )}
      <span onMouseEnter={(e) => {}}>

      {!isAllowedIcon && icon}
      </span>
    </Tooltip>
  );
};

export default CustomTooltip;
