import Pagination from '@material-ui/lab/Pagination';
import CustomModal from 'components/ModalCustom';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap/lib';
import { Container, CorrectButton, SelectTypeDelivery } from './styles';
import './styles.scss';

interface ErrorData {
  rua: string;
  numero: number;
  bairro: string;
  cidade: string;
  estado: string;
  complemento: string;
  lat: string;
  lng: string;
  cep: string;
  id: number;
  tipo: string;
  reps?: number;
}

interface Props {
  isOpen?: boolean;
  totalItems?: number;
  onClose?: any;
  elements?: any;
  confirmAddress?: any;
  totalPagesAddress?: number;
  onPaginateAddress?: any;
  onChangeTypeDelivery?: any;
}

const ModalAddress: React.FC<Props> = ({
  isOpen,
  totalItems,
  onClose,
  elements,
  confirmAddress,
  totalPagesAddress,
  onPaginateAddress,
  onChangeTypeDelivery
}: Props) => {
  const [errors, setErrors] = useState<ErrorData[]>([]);

  const padronizeElement = useCallback((options: any) => {
    let values: ErrorData[] = [];

    options.map(function(element: any) {
      const had: any = values.find(
        error =>
          error.rua === element.columns[10].data &&
          error.numero === element.columns[12].data &&
          error.bairro === element.columns[13].data &&
          error.cidade === element.columns[14].data &&
          error.estado === element.columns[15].data &&
          error.cep === element.columns[16].data
      );

      if (had) {
        had.reps += 1;
      } else {
        values.push({
          id: element.columns[0].data,
          rua: element.columns[6].data === "Entrega" ? element.columns[10].data : element.columns[33].data,
          tipo: element.columns[6].data,
          complemento: element.columns[6].data === "Entrega" ? element.columns[11].data : element.columns[34].data,
          numero: element.columns[6].data === "Entrega" ? element.columns[12].data : element.columns[35].data,
          bairro: element.columns[6].data === "Entrega" ? element.columns[13].data : element.columns[36].data,
          cidade: element.columns[6].data === "Entrega" ? element.columns[14].data : element.columns[37].data,
          estado: element.columns[6].data === "Entrega" ? element.columns[15].data : element.columns[38].data,
          cep: element.columns[6].data === "Entrega" ? element.columns[16].data : element.columns[39].data,
          lat: element.columns[6].data === "Entrega" ? element.columns[18].data : element.columns[41].data,
          lng: element.columns[6].data === "Entrega" ? element.columns[19].data : element.columns[42].data,
          reps: 1,
        });
      }
    });

    values = values.sort(function(a, b) {
      const ufA = a.estado;
      const ufB = b.estado;
      const cityA = a.cidade;
      const cityB = b.cidade;
      const streetA = a.rua;
      const streetB = b.rua;

      // Compare the 2 keys
      if (ufA < ufB) return -1;
      if (ufA > ufB) return 1;
      if (cityA < cityB) return -1;
      if (cityA > cityB) return 1;
      if (streetA < streetB) return -1;
      if (streetA > streetB) return 1;
      return 0;
    });

    setErrors(values);
  }, []);

  const findRow = (id: number) => {
    return elements.filter((element: any) => element.columns[0].data === id);
  };

  useEffect(() => {
    padronizeElement(elements);
  }, [elements, padronizeElement]);
  return (
    <CustomModal
      isOpen
      label={`Endereços não encontrados (${totalItems})`}
      isClose={() => onClose()}
    >
      <React.Fragment>
        <Container>
          <SelectTypeDelivery onChange={(e: any) => onChangeTypeDelivery(e.target.value)}>
            <option label="Entrega" value="mapPrecisaoStatus" />
            <option label="Coleta" value="mapPrecisaoStatusColeta" />
          </SelectTypeDelivery>
        </Container>

        <Row style={{ marginBottom: '2rem' }}>
          <Col md={1} style={{ textAlign: 'center' }}>
            <strong>Repetições</strong>
          </Col>
          <Col md={2}>
            <strong>Rua</strong>
          </Col>
          <Col md={1}>
            <strong>Tipo</strong>
          </Col>
          <Col md={1}>
            <strong>Número</strong>
          </Col>
          <Col md={2}>
            <strong>Bairro</strong>
          </Col>
          <Col md={2}>
            <strong>Cidade</strong>
          </Col>
          <Col md={1} style={{ textAlign: 'center' }}>
            <strong>Estado</strong>
          </Col>
          <Col md={2}>
            <strong>Ações</strong>
          </Col>
        </Row>
        <div>
          {errors.map((element: ErrorData) => (
            <Row>
              <Col md={1} style={{ textAlign: 'center' }}>
                <p>{element.reps}</p>
              </Col>
              <Col md={2}>
                <p>{element.rua}</p>
              </Col>
              <Col md={1}>
                <p>{element.tipo}</p>
              </Col>
              <Col md={1}>
                <p>{element.numero}</p>
              </Col>
              <Col md={2}>
                <p>{element.bairro}</p>
              </Col>
              <Col md={2}>
                <p>{element.cidade}</p>
              </Col>
              <Col md={1} style={{ textAlign: 'center' }}>
                <p>{element.estado}</p>
              </Col>
              <Col md={2}>
                <CorrectButton
                  type="button"
                  onClick={() => {
                    confirmAddress(element, findRow(element.id), element.id);
                  }}
                >
                  <span>Corrigir agora</span>
                </CorrectButton>
              </Col>
            </Row>
          ))}
          <div className="paginationAddress">
            <Pagination
              count={totalPagesAddress}
              variant="outlined"
              shape="rounded"
              onChange={(e, page) => onPaginateAddress(page)}
            />
          </div>
        </div>
      </React.Fragment>
    </CustomModal>
  );
};

export default ModalAddress;
