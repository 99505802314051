import { date, object, ref, string } from 'yup';

export default object().shape({
  filialExpedicao: string().required('* Informe a filial!'),
  dataAtendimentoInicial: date(),
  dataAtendimentoFinal: date().min(
    ref('dataAtendimentoInicial'),
    "* Data Atendimento Final não pode ser menor que data inicial!"
  )
    .when('dataAtendimentoInicial', (dataAtendimentoInicial, field) =>
      dataAtendimentoInicial
        ? field
          .required('* Informe a data final!')
        : field
    ),
});
