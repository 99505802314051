import produce from 'immer';

const INITIAL_STATE = {
  data: {
    lat: 0,
    lng: 0,
    zoom: 13,
    initialPosition: [-8.0527, -34.9099],
    jsonOSRM: [],
  },
  tabRoute: 0,
  editId: 0,
  groupEdit: false,
  groupsUsersId: null,
  typeIntegration: '',
  subsidiaryId: null,
};

export default function map(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@map/SAVE_ROUTE_MAP': {
        draft.data = action.payload.data;
        break;
      }
      case '@map/SAVE_TAB_ROUTE': {
        draft.editId = action.payload.editId;
        draft.tabRoute = action.payload.tabRoute;
        break;
      }
      case '@map/UPDATE_GROUP_USER': {
        draft.groupsUsersId = action.payload.userId;
        break;
      }
      case '@map/DELETE_GROUP_USER': {
        draft.groupsUsersId = null;
        break;
      }
      case '@map/UPDATE_GROUP_EDIT': {
        draft.groupEdit = action.payload.edit;
        break;
      }
      case '@map/DELETE_GROUP_EDIT': {
        draft.groupEdit = false;
        break;
      }
      case '@map/SAVE_TAB_INDEX': {
        draft.tabRoute = action.payload.tabRoute;
        break;
      }
      case '@map/TYPE_INTEGRATION_CSV':
        draft.typeIntegration = action.payload.type;
        break;
      case '@map/SAVE_SUBSIDIARY':
        draft.subsidiaryId = action.payload.idSubsidiary;
        break;
      default:
    }
  });
}
