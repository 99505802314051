import React, { useState } from 'react';
import filterAddField from '../../../../assets/images/filterAddField.svg';
import filterRemoveField from '../../../../assets/images/filterRemoveField.svg';
import './style.css';

interface Props {
  children?: JSX.Element;
  setFilter?: any;
  coordenates?: any;
}

const FilterTooltipError: React.FC<Props> = ({ children, setFilter, coordenates }: Props) => {
  const [active, setActive] = useState(true);
  const [operationFilter, setOperationFilter] = useState('EQUAL');

  return (
    <div className="Tooltip-Wrapper">
      {children}

      {active && (
        <div
          className={`Tooltip-Tip-Filter-Error ${'top'}`}
          style={{ left: `${coordenates.clientX - 125}px` }}
        >
          <div className="containerFilter">
            <div className="containerFilterColumn">
              <select
                className="inputFilter"
                onChange={e => {
                  setOperationFilter(e.target.value);
                  setFilter(e);
                }}
                value={operationFilter}
              >
                <option label="Selecione uma opção" value="" />
                <option label="Sim" value="Sim" />
                <option label="Não" value="Nao" />
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterTooltipError;
