import downloadIcon from 'assets/images/downloadIcon.svg';
import erroIcon from 'assets/images/erroIcon.png';
import successIconTable from 'assets/images/successIconTable.png';
import uploadIcon from 'assets/images/uploadIcon.svg';
import Pagination from 'components/Pagination';
import TopBar from 'pages/ManagementForms/TopBar';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Col, Input, Label, Row } from 'reactstrap';
import api from 'services/api';
import './styles.scss';

export default function IntegrationGW() {
  const [subsidiary, setSubsidiary] = React.useState([]);
  const [tabs, setTabs] = React.useState();

  const getSubsidiary = React.useCallback(async () => {
    const response = await api.get('/cadastro/filiais');
    setSubsidiary(response.data);
  }, []);

  React.useEffect(() => {
    getSubsidiary();
  }, [getSubsidiary]);
  return (
    <>
      <TopBar listSubsidiary={subsidiary} />

      <div className="containerView">
        <span className="titleGW">Configuração de Integração</span>

        <Tabs
          style={{ marginTop: 36 }}
          selectedIndex={tabs}
          onSelect={index => setTabs(index)}
        >
          <TabList>
            <Tab>
              GW
              {/* <Trans i18nKey="tabs.list" /> */}
            </Tab>
            <Tab disabled>
              SAP B1
              {/* <Trans i18nKey="tabs.new" /> */}
            </Tab>
          </TabList>
          <TabPanel>
            <Row style={{ marginTop: 36 }}>
              <Col md="3">
                <Label>Login</Label>
                <Input
                  type="text"
                  name="select"
                  id="SelectState"
                  // onChange={formik.handleChange('group')}
                  style={{
                    width: '100%',
                    // maxWidth: '211px',
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                />
              </Col>
              <Col md="3">
                <Label>Senha</Label>
                <Input
                  type="text"
                  name="select"
                  id="SelectState"
                  // onChange={formik.handleChange('group')}
                  style={{
                    // maxWidth: '211px',
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                />
              </Col>
              <Col md="3">
                <Label>GUID</Label>
                <Input
                  type="text"
                  name="select"
                  id="SelectState"
                  // onChange={formik.handleChange('group')}
                  style={{
                    // maxWidth: '211px',
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                />
              </Col>
              <Col md="3">
                <Label>Intervalo de atualização</Label>
                <Input
                  type="text"
                  name="select"
                  id="SelectState"
                  // onChange={formik.handleChange('group')}
                  style={{
                    // maxWidth: '211px',
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Label>URL</Label>
                <Input
                  type="text"
                  name="select"
                  id="SelectState"
                  // onChange={formik.handleChange('group')}
                  style={{
                    // maxWidth: '211px',
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                />
              </Col>
              <Col md="4">
                <Label />
                <button
                  // onClick={formik.handleSubmit}
                  type="button"
                  className="button"
                >
                  Salvar configuração
                </button>
              </Col>
            </Row>
            <div className="lineView" />

            <div className="headerTable">
              <div>
                <span className="titleGW">Logs</span>
              </div>
              <div style={{ display: 'flex', width: '25%' }}>
                <button
                  // onClick={formik.handleSubmit}
                  type="button"
                  className="button"
                >
                  <img src={downloadIcon} alt="" style={{ marginRight: 9 }} />
                  Buscar registro
                </button>
                <button
                  // onClick={formik.handleSubmit}
                  type="button"
                  className="button"
                >
                  <img src={uploadIcon} alt="" style={{ marginRight: 9 }} />
                  Enviar registro
                </button>
              </div>
            </div>
            <table className="tableLog">
              <thead>
                <tr>
                  <th>
                    <span className="tableTitle">Data/Hora</span>
                  </th>
                  <th>
                    <span className="tableTitle">Serviço</span>
                  </th>
                  <th>
                    <span className="tableTitle">Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="lineGroup">
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarTokennn
                  </td>
                  <td>
                    <img src={erroIcon} style={{ marginRight: 5 }} alt="" />
                    404
                    <span
                      style={{
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: '#EA004C',
                        cursor: 'pointer',
                        marginLeft: 8,
                      }}
                    >
                      Detalhes
                    </span>
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-11-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarToken
                  </td>
                  <td>
                    <img src={erroIcon} style={{ marginRight: 5 }} alt="" />
                    422
                    <span
                      style={{
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        fontSize: 14,
                        color: '#EA004C',
                        cursor: 'pointer',
                        marginLeft: 8,
                      }}
                    >
                      Detalhes
                    </span>
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarToken
                  </td>
                  <td>
                    <img
                      src={successIconTable}
                      style={{ marginRight: 5 }}
                      alt=""
                    />
                    200
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/baixarNotas
                  </td>
                  <td>
                    <img
                      src={successIconTable}
                      style={{ marginRight: 5 }}
                      alt=""
                    />
                    201
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarToken
                  </td>
                  <td>
                    <img
                      src={successIconTable}
                      style={{ marginRight: 5 }}
                      alt=""
                    />
                    200
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarToken
                  </td>
                  <td>
                    <img
                      src={successIconTable}
                      style={{ marginRight: 5 }}
                      alt=""
                    />
                    200
                  </td>
                </tr>
                <tr className="lineGroup" style={{ marginTop: 25 }}>
                  <td>2020-02-10-18-46-25</td>
                  <td>
                    http://gwfiscal.gwcloud.com.br/webresources/v2/servicosGW/solicitarToken
                  </td>
                  <td>
                    <img
                      src={successIconTable}
                      style={{ marginRight: 5 }}
                      alt=""
                    />
                    200
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              perPage={10}
              total={30}
              // onPaginate={paginate}
              // currentPage={currentPage}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}
