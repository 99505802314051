import {
  CellClickedEvent,
  GetRowIdParams,
  RowClassParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import CargoPre from 'interfaces/Delivery/CargoPre';
import CreateAttendance from 'pages/CargoCreate/components/CreateAttendance';
import DetailedCargos from 'pages/CargoCreate/components/DetailedCargos';
import CargoRoutingDialog from 'pages/CargoCreate/components/LoadCargos/components/CargoRoutingDialog';
import CreateCargoDialog from 'pages/CargoCreate/components/LoadCargos/components/CreateCargoDialog';
import DetailsCargos from 'pages/CargoCreate/components/LoadCargos/components/DetailsCargos';
import ModalAddress from 'pages/CargoCreate/components/ModalAddress/iindex';
import ReleaseLoadingDialog from 'pages/CargoCreate/components/ReleaseLoadingDialog';
import Routering from 'pages/CargoCreate/components/Routering';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import { generateRandomColor } from 'util/color';
import {
  formatDate,
  formatNumberBR,
  numberToCurrencyBRL,
  strToNumber,
} from 'util/format';
import { generateGroupedReturnData, uncheckedItems } from './groupingGridData';
import {
  checkBasicRn,
  checkIfExistsRouting,
  checkRoutingRn,
  hasMoreThanOneSelected,
} from './groupingGridValidation';
import './styles.css';
import {
  DetailedRow,
  GroupedProps,
  GroupedRow,
  GroupedType,
  Metric,
  Team,
} from './types';

const GroupedGrid = (props: GroupedProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const detailGridRef = useRef<AgGridReact>(null);
  const [groupedType] = useState<GroupedType>(props.type);
  const [groupedRowData, setGroupedRowData] = useState<GroupedRow[]>([]);
  const [detailedRowData, setDetailedRowData] = useState<DetailedRow[]>([]);
  const [transferData, setTransferData] = useState<DetailedRow[]>([]);
  const [
    currentTransferedGroupedRow,
    setCurrentTransferedGroupedRow,
  ] = useState<GroupedRow>();
  const [selectedGroupedRow, setSelectedGroupRow] = useState<GroupedRow | null>(
    null
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [transferMenuDisabled, setTransferMenuDisabled] = useState(false);

  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(400);

  // Modal detalhe do agrupamento.
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal contendo o nome do agrupamento.
  const [showGroupingNameModal, setShowGroupingNameModal] = useState(false);
  const handleGroupingNameModalClose = () => setShowGroupingNameModal(false);
  const handleGroupingNameModalShow = () => setShowGroupingNameModal(true);

  // Modal detalhe da carga.
  const [showCargoDetail, setShowCargoDetail] = useState(false);
  const handleCloseCargoDetail = () => setShowCargoDetail(false);
  const handleShowCargoDetail = () => setShowCargoDetail(true);

  // Modal criaçãp da carga.
  const [showCreateCargo, setShowCreateCargo] = useState(false);
  const handleCloseCreateCargo = () => setShowCreateCargo(false);
  const handleShowCreateCargo = () => setShowCreateCargo(true);

  // Modal de Liberacao da carga pra carregamento.
  const [showReleaseLoading, setShowReleaseLoading] = useState(false);
  const handleCloseReleaseLoading = () => setShowReleaseLoading(false);
  const handleShowReleaseLoading = () => setShowReleaseLoading(true);

  // Modal de Liberacao da carga pra carregamento.
  const [showCargoRouting, setShowCargoRouting] = useState(false);
  const handleCloseCargoRouting = () => setShowCargoRouting(false);
  const handleShowCargoRouting = () => setShowCargoRouting(true);

  // Modal de Alteracao do endereco do atendimento.
  const [showChangeAddress, setShowChangeAddress] = useState(false);
  const handleShowChangeAddress = () => setShowChangeAddress(true);

  const [showCreateAttendance, setShowCreateAttendance] = useState(false);
  const handleShowCreateAttendance = () => setShowCreateAttendance(true);
  const handleCloseCreateAttendance = () => setShowCreateAttendance(false);

  const [showRoutering, setShowRoutering] = useState(false);

  // Nome do novo agrupamento
  const [dynamicallyGroupedName, setDynamicallyGroupedName] = useState('');

  // Preenche o grid com os dados do agrupamento.
  const generateData = () => {
    var groupedBy: Record<string, CargoPre[]> = {};
    if (groupedType === GroupedType.REGION) {
      // Agrupa pela região.
      groupedBy = groupBy<CargoPre>(props.rowData, 'region');
    } else if (groupedType === GroupedType.ROUTE) {
      // Agrupa pela rota.
      groupedBy = groupBy<CargoPre>(props.rowData, 'route');
    } else {
      // Agrupa pela seção.
      groupedBy = groupBy<CargoPre>(props.rowData, 'section');
    }

    // Iterando sobre as chaves do objeto
    let groupedRowData: GroupedRow[] = [];
    for (const key in groupedBy) {
      if (groupedBy.hasOwnProperty(key)) {
        const items = groupedBy[key];

        let metric = calculateMetric(items);

        groupedRowData.push(newGrouping(key, metric, items));
      }
    }
    setGroupedRowData(groupedRowData);

    // Atualiza as linhas de retorno com o resultado o agrupamento.
    props.setReturnedRows(generateGroupedReturnData(groupedRowData));
  };

  // Função para agrupar por coluna.
  // A função groupBy agora é genérica, aceitando um tipo T para representar o tipo dos
  // objetos no array. A chave da coluna passada como argumento é garantida como uma chave
  // válida para o tipo T usando keyof T. Isso ajuda a obter a sugestão de código e o controle de
  // tipo adequado durante o desenvolvimento.
  function groupBy<T>(array: T[], columnName: keyof T): Record<string, T[]> {
    return array.reduce((groups: Record<string, T[]>, item: T) => {
      const key = item[columnName] as string;

      if (!groups[key]) {
        groups[key] = [];
      }

      groups[key].push(item);

      return groups;
    }, {});
  }

  // Formata a coluna com a moeda brasileira.
  const currencyFormatter = (params: ValueFormatterParams) => {
    return numberToCurrencyBRL(params.value);
  };

  // Formata a coluna com a unidade de peso.
  const kgFormatter = (params: ValueFormatterParams) => {
    return formatNumberBR(params.value) + ' kg';
  };

  // Formata a coluna com a unidade de volume.
  const m3Formatter = (params: ValueFormatterParams) => {
    return formatNumberBR(params.value) + ' m³';
  };

  // Formata a coluna com a unidade de peso.
  const dateFormatter = (params: ValueFormatterParams) => {
    if (params.data != null && params.data.estimateDate != null) {
      return formatDate(params.data.estimateDate);
    }
    return '';
  };

  // Detalha a linha selecionada.
  const onDetail = () => {
    // Seleciona o agrupamento selecionado.
    const selectedRow: GroupedRow = getSelectedGroupedRow();

    // Caso tenha apenas 1 item no grid de agrupamento,
    // deve bloquear o menu de transferência.
    if (groupedRowData.length === 1) {
      setTransferMenuDisabled(true);
    } else {
      setTransferMenuDisabled(false);
    }

    if (transferData !== null && transferData.length === 0) {
      const items: CargoPre[] = selectedRow.rows;

      // Carrega os itens associados ao agrupamento no grid de detalhe.
      const rowData: DetailedRow[] = [];
      items.forEach(i => {
        rowData.push({
          id: String(i.id),
          isErro: i.isErro !== null ? i.isErro : false,
          clienteNome: String(i.clienteNome),
          customerReferenceId: String(i.clienteIdReferencia),
          tipo: String(i.tipo),
          status: `${
            i.tipo === 'Entrega'
              ? i.mapPrecisaoStatus
              : i.mapPrecisaoStatusColeta
          }%`,
          customerName: String(i.clienteNome),
          addressStreet: String(
            i.enderecoLogradouro && i.enderecoLogradouro.length > 0
              ? i.enderecoLogradouro
              : i.enderecoLogradouroColeta &&
                i.enderecoLogradouroColeta.length > 0
              ? i.enderecoLogradouroColeta
              : ''
          ),
          addressCity: String(
            i.enderecoCidade && i.enderecoCidade.length > 0
              ? i.enderecoCidade
              : i.enderecoCidadeColeta && i.enderecoCidadeColeta.length > 0
              ? i.enderecoCidadeColeta
              : ''
          ),
          addressState:
            i.enderecoUf !== null
              ? String(
                  i.enderecoUf.length > 0 ? i.enderecoUf : i.enderecoUfColeta
                )
              : '',
          addressNeighborhood: String(
            i.enderecoBairro && i.enderecoBairro.length > 0
              ? i.enderecoBairro
              : i.enderecoBairroColeta && i.enderecoBairroColeta.length > 0
              ? i.enderecoBairroColeta
              : ''
          ),
          addressNumber: String(
            i.enderecoNumero && i.enderecoNumero?.length > 0
              ? i.enderecoNumero
              : i.enderecoNumeroColeta && i.enderecoNumeroColeta.length > 0
              ? i.enderecoNumeroColeta
              : ''
          ),
          addressComplement: String(
            i.enderecoComplemento.length > 0
              ? i.enderecoComplemento
              : i.enderecoComplementoColeta
          ),
          enderecoLatitude: String(
            i.enderecoLatitude.length > 0
              ? i.enderecoLatitude
              : i.enderecoLatitudeColeta
          ),
          enderecoLongitude: String(
            i.enderecoLongitude.length > 0
              ? i.enderecoLongitude
              : i.enderecoLongitudeColeta
          ),
          cubage: Number(i.cubagem),
          weight: Number(i.peso),
          volume: Number(i.qtdVolumes),
          value: Number(i.valor),
          addressZipcode: String(
            i.enderecoCep.length > 0 ? i.enderecoCep : i.enderecoCepColeta
          ),
          referenceId: i.idReferencia,
          docNumber: i.numero,
          region: i.region,
          route: i.route,
          section: i.section,
          parentReferenceId: i.parentReferenceId,
          obs: i.obs !== null ? String(i.obs) : '',
          branchShipping:
            i.branchShipping !== null ? String(i.branchShipping) : '',
          branchShippingReferenceId:
            i.branchShippingReferenceId !== null
              ? String(i.branchShippingReferenceId)
              : '',
          reentrega: i.reentrega !== null ? i.reentrega : false,
        });
      });
      setDetailedRowData(rowData);

      // Guarda o item que foi selecionado no agrupamento. Esse item pode ser utilizado
      // o usuário decida realizar a transferência de carga.
      // setCurrentGroupedRow(selectedRow[0]);
      handleShow();
    } else if (transferData !== null && transferData.length > 0) {
      // Remove os itens transferidos do agrupamento em que pertencia.
      if (currentTransferedGroupedRow !== undefined) {
        // Só faz a transferência se o agrupamento de transferência for diferente do agrupamento que receberá os itens.
        if (selectedRow.grouper != currentTransferedGroupedRow.grouper) {
          // Transfere a carga para o agrupamento selecionado.
          transferData.forEach(i => {
            // Agrupamento que receberá a transferência.
            let cargoPre = getItemById(Number(i.id));
            if (cargoPre !== null) {
              selectedRow.rows.push(cargoPre);
            }
          });

          const removedIds: number[] = transferData.map(i => Number(i.id));
          const rows: CargoPre[] = currentTransferedGroupedRow.rows.filter(
            i => !removedIds.includes(i.id)
          );
          const current: GroupedRow = currentTransferedGroupedRow;
          current.rows = rows;
          setCurrentTransferedGroupedRow(current);

          groupedRowData.forEach(i => {
            if (i.grouper === currentTransferedGroupedRow.grouper) {
              i.rows = rows;
            }
          });

          setGroupedRowData(groupedRowData);

          currentTransferedGroupedRow.routing = false;
          currentTransferedGroupedRow.simulation = null;

          // Atualiza as linhas com os novos dados de transferência.
          updateRow(currentTransferedGroupedRow);
          updateRow(selectedRow);

          props.setReturnedRows(generateGroupedReturnData(groupedRowData));
        }
      }

      // Volta para os valores iniciais para que seja realizado um novo fluxo.
      // Quando faz isso a apliacação, ao clicar na linha de agrupamento, exibe o detalhe e não
      // mais a escolha de um grupo para transferência.
      setTransferData([]);

      FrontendNotification(
        'Carga(s) transferida(s) com sucesso.',
        NotificationType.SUCCESS
      );
    }
  };

  // Atualiza uma linha com os novos dados.
  const updateRow = (entity: GroupedRow) => {
    const rowNode = gridRef!.current?.api.getRowNode(entity.grouper)!;

    // Calcula as métricas.
    let metric: Metric = calculateMetric(entity.rows);
    entity.cubage = metric.cubage;
    entity.weight = metric.weight;
    entity.value = metric.value;
    entity.volume = metric.volume;
    entity.order = metric.order;
    entity.customer = metric.customer;

    rowNode.updateData(rowNode.data);
  };

  // Atualiza os dados de uma linha do grid de detalhamento.
  const updateDetailedRow = (entity: DetailedRow, dataAddress: any) => {
    const rowNode = detailGridRef!.current?.api.getRowNode(entity.id)!;

    entity.addressNeighborhood = dataAddress[0].enderecoBairro;
    entity.addressCity = dataAddress[0].enderecoCidade;
    entity.addressState = dataAddress[0].enderecoUf;
    entity.addressStreet = dataAddress[0].enderecoLogradouro;
    entity.addressZipcode = dataAddress[0].enderecoCep;
    entity.enderecoLatitude = dataAddress[0].enderecoLatitude;
    entity.enderecoLongitude = dataAddress[0].enderecoLongitude;
    entity.status = '100%';

    rowNode.updateData(rowNode.data);
  };

  // Controla a exibição do botão de opções
  const disableOptionsButton = () => {
    if (transferData != null && transferData.length > 0) {
      return true;
    }

    return false;
  };

  // Calcula os valores das métricas de linha.
  const calculateMetric = (entities: CargoPre[]): Metric => {
    let totalWeight = 0;
    let totalCubage = 0;
    let totalValue = 0;
    let totalVolume = 0;

    let customers: string[] = [];
    for (const entity of entities) {
      totalWeight += strToNumber(entity.peso);
      totalCubage += strToNumber(entity.cubagem);
      totalValue += strToNumber(entity.valor);
      totalVolume += strToNumber(entity.qtdVolumes);

      // Verifica se o id do cliente já existe no array.
      // Isso impedirá a duplicação de nomes e portanto,
      // poderemos identificar o número de clientes distintos no agrupamento.
      if (!customers.includes(entity.clienteIdReferencia)) {
        customers.push(entity.clienteIdReferencia);
      }
    }

    let metric: Metric = {
      weight: totalWeight,
      cubage: totalCubage,
      value: totalValue,
      volume: totalVolume,
      customer: customers.length,
      order: entities.length,
    };

    return metric;
  };

  // Transfe itens para outro agrupamento.
  const onTransfer = () => {
    const selectedRows = detailGridRef.current?.api.getSelectedRows();
    if (selectedRows !== undefined && selectedRows.length === 0) {
      FrontendNotification(
        'Para realizar a transferência é necessário selecionar os itens.',
        NotificationType.WARNING
      );
    } else {
      let rows: DetailedRow[] = [];
      selectedRows?.forEach((i: DetailedRow) => {
        rows.push(i);
      });

      // Guarda os dados selecionados para a transferência.
      setTransferData(rows);

      // Guarda a linha de agrupamento selecionada na transferência.
      // Essa operação é necessária para que se possa realizar a remoção dos itens transferidos.
      detailGridRef.current?.api.getSelectedRows();
      setCurrentTransferedGroupedRow(getSelectedGroupedRow());

      // Fecha o modal para que o usuário possa selecionar no grid, o agrupamento
      // que receberá esses itens da transferência.
      handleClose();

      // Desmarca os itens do grouped grid para que o usuário possa selecionar o
      // agrupamento que os registros transferidos irão.
      uncheckedItems(gridRef);
    }
  };

  // Cancela a transferência.
  const onCancelTransfer = () => {
    setTransferData([]);

    FrontendNotification(
      'Transferência de carga(s) cancelada.',
      NotificationType.SUCCESS
    );
  };

  // Busca o item através do id.
  const getItemById = (id: number): CargoPre | null => {
    for (let i = 0; i < props.rowData.length; i++) {
      if (id === props.rowData[i].id) {
        return props.rowData[i];
      }
    }

    return null;
  };

  // Guarda o grouped row selecionado pelo usuário.
  const getSelectedGroupedRow = (): GroupedRow => {
    let rows = gridRef.current?.api.getSelectedRows();
    return rows?.[0];
  };

  const [colDefs] = useState([
    {
      field: 'color',
      width: 10,
      headerName: '',
      cellStyle: (params: { value: string }) => {
        // Altera a cor da célula.
        return { backgroundColor: params.value };
      },
      valueFormatter: () => {
        return '';
      },
    },
    {
      field: 'grouper',
      headerName: 'Grupo',
      checkboxSelection: true,
      filter: true,
      cellStyle: (params: any) => {
        const data = params.data;
        const findByStatus = data.rows.filter(
          (row: CargoPre) =>
            (row.tipo === 'Entrega' && Number(row.mapPrecisaoStatus) <= 50) ||
            (row.tipo === 'Coleta' && Number(row.mapPrecisaoStatusColeta) <= 50)
        );
        // Altera a cor da célula.
        if (findByStatus.length > 0) {
          return { backgroundColor: '#ff6961', color: 'white' };
        }
      },
    },
    {
      field: 'destiny',
      headerName: 'Destino',
      width: 120,
      filter: true,
    },
    {
      field: 'weight',
      headerName: 'Peso',
      valueFormatter: kgFormatter,
      width: 90,
      filter: true,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      valueFormatter: m3Formatter,
      width: 120,
      filter: true,
    },
    {
      field: 'customer',
      headerName: 'Clientes',
      width: 120,
      filter: true,
    },
    {
      field: 'order',
      headerName: 'Atend.',
      width: 120,
      filter: true,
    },
    {
      field: 'licensePlate',
      headerName: 'Veículo',
      width: 120,
      filter: true,
    },
    {
      field: 'estimateDate',
      headerName: 'Agend.',
      width: 150,
      valueFormatter: dateFormatter,
      filter: true,
    },
   
    {
      field: 'cubage',
      headerName: 'Cubagem',
      valueFormatter: m3Formatter,
      filter: true,
    },
    {
      field: 'value',
      headerName: 'Valor',
      valueFormatter: currencyFormatter,
      filter: true,
    },
    {
      field: 'routing',
      headerName: 'Roteirizado',
      width: 110,
    },
    {
      field: 'released',
      headerName: 'Liberada',
      width: 90,
    },
    {
      field: 'created',
      headerName: 'Criada',
      width: 90,
    },
  ]);

  const [detailedColDefs] = useState([
    {
      field: 'docNumber',
      headerName: 'Nº Doc',
      filter: true,
      checkboxSelection: true,
      cellStyle: (params: ValueFormatterParams) => {
        // Altera a cor da célula.
        if(parseInt(params.data.status) <= 50) {
          return { backgroundColor: '#EA004C', color: '#fff' };
          
        }
      },
    },
    {
      field: 'reentrega',
      headerName: 'Reentrega',
      filter: true,
    },
    
    {
      field: 'customerReferenceId',
      headerName: 'Id Cliente',
      filter: true,
    },
    {
      field: 'customerName',
      headerName: 'Cliente',
      width: 120,
      filter: true,
    },
    {
      field: 'addressCity',
      headerName: 'Cidade',
      filter: true,
    },
    {
      field: 'addressStreet',
      headerName: 'Logradouro',
      filter: true,
    },
    {
      field: 'addressNeighborhood',
      headerName: 'Bairro',
      filter: true,
    },
    {
      field: 'region',
      headerName: 'Regiao',
      filter: true,
    },
    {
      field: 'obs',
      headerName: 'Observação',
      filter: true,
    },
    {
      field: 'branchShipping',
      headerName: 'Filial de Expedição',
      filter: true,
    },

    {
      field: 'branchShippingReferenceId',
      headerName: 'Id Filial de Expedição',
      filter: true,
    },
    {
      field: 'route',
      headerName: 'Rota',
      filter: true,
    },

    {
      field: 'section',
      headerName: 'Setor',
      filter: true,
    },

    {
      field: 'referenceId',
      headerName: 'Id Ref',
      filter: true,
    },

    {
      field: 'status',
      headerName: 'Acuracidade',
      filter: true,
    },

    {
      field: 'addressState',
      headerName: 'Estado',
      filter: true,
    },

    {
      field: 'addressNumber',
      headerName: 'Numero',
      filter: true,
    },
    {
      field: 'addressComplement',
      headerName: 'Complemento',
      filter: true,
    },
    {
      field: 'addressZipcode',
      headerName: 'CEP',
      filter: true,
    },
    {
      field: 'weight',
      headerName: 'Peso',
      valueFormatter: kgFormatter,
    },
    {
      field: 'value',
      headerName: 'Valor',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      valueFormatter: m3Formatter,
    },
    {
      field: 'cubage',
      headerName: 'Cubagem',
      valueFormatter: m3Formatter,
    },

    {
      field: 'parentReferenceId',
      headerName: 'ID Link',
      filter: true,
    },
  ]);

  // Remove itens do grid de detalhamento.
  const onRemove = () => {
    const selectedDetailRows = detailGridRef.current?.api.getSelectedRows();

    if (selectedDetailRows !== undefined && selectedDetailRows?.length > 0) {
      // Remove o item do grid de detalhamento.
      detailGridRef.current?.api.applyTransaction({
        remove: selectedDetailRows,
      });

      // Identifica as linhas (cargas) que serão removidas do detalhamento.
      const selectedGroupedRow = getSelectedGroupedRow();
      const removedIds: number[] = selectedDetailRows.map((i: DetailedRow) =>
        Number(i.id)
      );

      // Remove do agrupamento os itens que foram selecionados como removidos.
      const rows: CargoPre[] = selectedGroupedRow.rows.filter(
        i => !removedIds.includes(i.id)
      );

      // Atualiza a lista de cargas dentro do agrupamento.
      groupedRowData.forEach(i => {
        if (i.grouper === selectedGroupedRow.grouper) {
          i.rows = rows;
        }
      });

      // Seta valores padrão para o agrupamento selecionado.
      selectedGroupedRow.simulation = null;
      selectedGroupedRow.routing = false;

      // Verifica se existe algum agrupamento em que todas as linhas foram removidas.
      // Nesse caso esse agrupamento é para sair da lista de agrupamentos.
      const groupedRows: GroupedRow[] = groupedRowData.filter(
        groupedRow => groupedRow.rows.length != 0
      );

      // Atualiza o array de agrupamentos com os dados finais.
      setGroupedRowData(groupedRows);

      // Atualiza a linha do agrupamento no grid.
      updateRow(selectedGroupedRow);
    } else {
      FrontendNotification(
        'Para realizar a remoção é necessário selecionar os itens.',
        NotificationType.WARNING
      );
    }
  };

  // Altera o endereço de uma carga.
  const onChangeAddress = (data: any[], dataAddress: any) => {
    // Identifica as linhas selecionadas no grid de detalhe. Nesse grid,
    // só possível selecionar apenas uma linha.
    let selectedRows = detailGridRef.current?.api.getSelectedRows();

    if (selectedRows !== undefined && selectedRows.length > 0) {
      const selectedGroupedRow = getSelectedGroupedRow();

      selectedGroupedRow.rows.forEach(g => {
        if (g.id == data[0].id) {
          if (g.tipo === 'Entrega') {
            g.enderecoBairro = dataAddress[0].enderecoBairro;
            g.enderecoCidade = dataAddress[0].enderecoCidade;
            g.enderecoCep = dataAddress[0].enderecoCep;
            g.enderecoLatitude = dataAddress[0].enderecoLatitude;
            g.enderecoLongitude = dataAddress[0].enderecoLongitude;
            g.enderecoLogradouro = dataAddress[0].enderecoLogradouro;
            g.enderecoUf = dataAddress[0].enderecoUf;
            g.mapPrecisaoStatus = '100';
          } else {
            g.enderecoBairroColeta = dataAddress[0].enderecoBairro;
            g.enderecoCidadeColeta = dataAddress[0].enderecoCidade;
            g.enderecoCepColeta = dataAddress[0].enderecoCep;
            g.enderecoLatitudeColeta = dataAddress[0].enderecoLatitude;
            g.enderecoLongitudeColeta = dataAddress[0].enderecoLongitude;
            g.enderecoLogradouroColeta = dataAddress[0].enderecoLogradouro;
            g.enderecoUf = dataAddress[0].enderecoUf;
            g.mapPrecisaoStatusColeta = '100';
          }
        }
      });

      groupedRowData.forEach(i => {
        if (i.grouper === selectedGroupedRow.grouper) {
          i.rows.forEach((r: CargoPre) => {
            if (r.id == data[0].id) {
              if (r.tipo === 'Entrega') {
                r.enderecoBairro = dataAddress[0].enderecoBairro;
                r.enderecoCidade = dataAddress[0].enderecoCidade;
                r.enderecoCep = dataAddress[0].enderecoCep;
                r.enderecoLatitude = dataAddress[0].enderecoLatitude;
                r.enderecoLongitude = dataAddress[0].enderecoLongitude;
                r.enderecoLogradouro = dataAddress[0].enderecoLogradouro;
                r.enderecoUf = dataAddress[0].enderecoUf;
                r.mapPrecisaoStatus = '100';
              } else {
                r.enderecoBairroColeta = dataAddress[0].enderecoBairro;
                r.enderecoCidadeColeta = dataAddress[0].enderecoCidade;
                r.enderecoCepColeta = dataAddress[0].enderecoCep;
                r.enderecoLatitudeColeta = dataAddress[0].enderecoLatitude;
                r.enderecoLongitudeColeta = dataAddress[0].enderecoLongitude;
                r.enderecoLogradouroColeta = dataAddress[0].enderecoLogradouro;
                r.enderecoUf = dataAddress[0].enderecoUf;
                r.mapPrecisaoStatusColeta = '100';
              }
            }
          });
        }
      });

      selectedGroupedRow.simulation = null;
      selectedGroupedRow.routing = false;
      setGroupedRowData(groupedRowData);
      updateRow(selectedGroupedRow);
      updateDetailedRow(selectedRows[0], dataAddress);
    }
  };

  // Abre uma janala para o usuário selecionar um veículo.
  const onCargoDetail = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();

    if (selectedRows !== undefined && selectedRows.length > 0) {
      if (!checkBasicRn(selectedRows) || hasMoreThanOneSelected(selectedRows)) {
        return;
      } else {
        openCargoDetail();
      }
    } else {
      FrontendNotification(
        'Selecione o agrupamento que deseja detalhar',
        NotificationType.WARNING
      );
    }
  };

  // Seleciona a linha agrupada. Essa linha pode
  // ser passada para outros componentes através de
  const openCargoDetail = () => {
    setSelectedGroupRow(getSelectedGroupedRow());
    handleShowCargoDetail();
  };

  // Abre uma janala para o usuário criar a carga selecionada.
  const onCreateCargo = () => {
    const selectedRows = gridRef.current?.api.getSelectedRows();

    // Verificação básica para realização da operação.
    if (selectedRows !== undefined && selectedRows.length > 0) {
      if (
        !checkBasicRn(selectedRows) ||
        !checkRoutingRn(selectedRows) ||
        !checkIfExistsRouting(selectedRows)
      ) {
        return;
      }
      openCreateCargo();
    } else {
      FrontendNotification(
        'Selecione os agrupamentos que deseja roteirizar',
        NotificationType.WARNING
      );
    }
  };

  // Seleciona a linha agrupada. Essa linha pode
  // ser passada para outros componentes através de
  const openCreateCargo = () => {
    setSelectedGroupRow(getSelectedGroupedRow());
    handleShowCreateCargo();
  };

  // Realiza a roteirização dos agrupamentos selecionados.
  const onRouting = () => {
    // Identifica os agrupamentos selecionados.
    const selectedRows = gridRef.current?.api.getSelectedRows();

    // Verificação básica para realização da operação.
    if (selectedRows !== undefined && selectedRows.length > 0) {
      if (!checkBasicRn(selectedRows) || !checkRoutingRn(selectedRows)) {
        return;
      }

      openRouting(selectedRows);
    } else {
      FrontendNotification(
        'Selecione os agrupamentos que deseja roteirizar',
        NotificationType.WARNING
      );
    }
  };

  // Abre a tela resposável por realizar a roteirização.
  const openRouting = (rows: any) => {
    setSelectedRows(rows);
    handleShowCargoRouting();
  };

  // Abre uma janela para o usuário liberar a carga pra carregamento.
  const onReleased = () => {
    // Identifica os agrupamentos selecionados.
    const selectedRows = gridRef.current?.api.getSelectedRows();

    // Verificação básica para realização da operação.
    if (selectedRows !== undefined && selectedRows.length > 0) {
      if (
        !checkBasicRn(selectedRows) ||
        !checkIfExistsRouting(selectedRows) ||
        !checkIfExistsRouting(selectedRows)
      ) {
        return;
      }
      openReleaseLoading();
    } else {
      FrontendNotification(
        'Selecione os agrupamentos que deseja liberar a carga para carregamento',
        NotificationType.WARNING
      );
    }
  };

  // Seleciona a linha agrupada. Essa linha pode
  // ser passada para outros componentes através de
  const openReleaseLoading = () => {
    setSelectedGroupRow(getSelectedGroupedRow());
    handleShowReleaseLoading();
  };

  // Abre o modal contendo a alteração de endereço.
  const openChangeAddress = () => {
    const selectedRows = detailGridRef.current?.api.getSelectedRows();
    if (selectedRows !== undefined && selectedRows.length > 0) {
      setSelectedRows(selectedRows);
      handleShowChangeAddress();
    } else {
      FrontendNotification(
        'Selecione a carga que deseja alterar o endereço',
        NotificationType.WARNING
      );
    }
  };

  // Atualiza as colunas complementares do grid de agrupamento.
  const updateRowCargoDetail = (
    licensePlate: string,
    estimateDate: Date,
    teamList: Team[],
    roundtrip: boolean,
    destiny: string
  ) => {
    setShowCargoDetail(!showCargoDetail);
    let selectedRow = getSelectedGroupedRow();
    selectedRow.destiny = destiny;
    selectedRow.estimateDate = estimateDate;
    selectedRow.licensePlate = licensePlate;
    selectedRow.roundtrip = roundtrip;
    selectedRow.teamList = teamList;
    selectedRow.routing = false;
    selectedRow.simulation = null;
    updateRow(selectedRow);
  };

  // Atualiza as colunas complementares do grid de agrupamento.
  const updateRouteringCargo = (routing: boolean, simulation: string) => {
    setShowCargoRouting(!showCargoRouting);
    setShowRoutering(!showRoutering);
    const selectedRows = gridRef.current?.api.getSelectedRows();
    selectedRows?.map((row: GroupedRow) => {
      let selectedRow = row;
      selectedRow.routing = routing;
      selectedRow.simulation = simulation;
      updateRow(selectedRow);
    });
  };

  // Atualiza as colunas complementares do grid de agrupamento.
  const updateCreateCargo = () => {
    setShowCreateCargo(!showCreateCargo);
    const selectedRows = gridRef.current?.api.getSelectedRows();
    selectedRows?.map((row: GroupedRow) => {
      let selectedRow = row;
      selectedRow.created = true;
      updateRow(selectedRow);
    });
  };

  // Atualiza as colunas complementares do grid de agrupamento.
  const updateReleaseLoading = () => {
    setShowReleaseLoading(!showReleaseLoading);
    const selectedRows = gridRef.current?.api.getSelectedRows();
    selectedRows?.map((row: GroupedRow) => {
      let selectedRow = row;
      selectedRow.released = true;
      updateRow(selectedRow);
    });
  };

  // Cria um novo registro no grid de agrupamento.
  const newGrouping = (key: string, metric: any, items: CargoPre[]) => {
    let entity = {
      destiny: null,
      licensePlate: null,
      estimateDate: null,
      color: generateRandomColor(),
      grouper: key,
      weight: metric.weight,
      cubage: metric.cubage,
      value: metric.value,
      volume: metric.volume,
      customer: metric.customer,
      order: metric.order,
      rows: items,
      teamList: [],
      routing: false,
      simulation: null,
      released: false,
      created: false,
      roundtrip: true,
    };
    return entity;
  };

  // Cria um novo agrupamento.
  const openGroupingNameModal = () => {
    handleGroupingNameModalShow();
  };
  const onSaveGroupingName = () => {
    if (dynamicallyGroupedName != '') {
      let metric: Metric = calculateMetric([]);
      let newGroupedRow: any = newGrouping(dynamicallyGroupedName, metric, []);

      let groupedRowDataTmp = groupedRowData;
      groupedRowDataTmp.push(newGroupedRow);
      setGroupedRowData(groupedRowDataTmp);

      // Atualiza o grid com a nova linha.
      gridRef.current?.api.applyTransaction({
        add: [newGroupedRow],
      });

      handleGroupingNameModalClose();
    } else {
      FrontendNotification(
        t('É necesário informar um nome para o agrupamento'),
        NotificationType.WARNING
      );
    }
  };

  const onAddCargo = (rows: any[]) => {
    let selectedRow: GroupedRow = getSelectedGroupedRow();

    const addCargo = selectedRow.rows;

    if (selectedRow != undefined) {
      let addedCargo = false;

      rows.forEach((i: CargoPre, index: number) => {
        let cargoPre = addCargo.find(
          row => String(row.id) == String(rows[index].id)
        );
        // Agrupamento que receberá a transferência.
        if (cargoPre == undefined) {
          addCargo.unshift(i);
          selectedRow.rows = addCargo;
          addedCargo = true;
        } else {
          FrontendNotification(
            `Atendimento ${
              cargoPre.idReferencia.length > 0 ? cargoPre.idReferencia : ''
            } já faz parte do agrupamento`,
            NotificationType.WARNING
          );

          return;
        }

      });

      groupedRowData.forEach(j => {
        if (j.grouper === selectedRow.grouper) {
          j.rows = addCargo;
        }
      });

      setGroupedRowData(groupedRowData);
      updateRow(selectedRow);

      rows.forEach((i: CargoPre, index: number) => {
        let cargoPre = addCargo.find(
          row => String(row.id) == String(rows[index].id)
        );

        if (cargoPre == undefined) {
          detailGridRef.current?.api.applyTransaction({
            add: [rows[0]],
          });

          addedCargo = true;
        }
      });

      if (addedCargo) {
        FrontendNotification(
          'Atendimento adicionado com sucesso!',
          NotificationType.SUCCESS
        );

        setShow(false);
      }
    }
  };

  // Atualiza as colunas complementares do grid de agrupamento ao realizar mudancas na roteirizacao.
  const updateRowCargoRoutering = (
    rows: CargoPre[]
    
  ) => {
    setShowRoutering(!showRoutering);
    
    let selectedRow = getSelectedGroupedRow();
    selectedRow.rows = rows;
    updateRow(selectedRow);
  };

  // Define o tamanho do modal da tela de atendimentos.
  const onResize = (data: any) => {
    setWidth(data.size.width);
    setHeight(data.size.height);
  };

  // Acionado quando o usuário clica em alguma linha.
  const onCellClicked = useCallback((params: CellClickedEvent) => {
    props.setSelectedGroupedRow(params.data);
  }, []);

  useEffect(() => {
    if (groupedType !== GroupedType.NO_ACTION) {
      generateData();
    }
  }, []);

  // Qualquer mudança no groupedRowData deve retorno para o mapa os pontos restantes.
  useEffect(() => {
    props.setReturnedRows(generateGroupedReturnData(groupedRowData));
  }, [groupedRowData]);

  return (
    <>
      {showGroupingNameModal && (
        <CustomModal
          isClose={handleGroupingNameModalClose}
          label=""
          isOpen
          buttons={
            <Button className="btn btn-custom" onClick={onSaveGroupingName}>
              {t('action.save')}
            </Button>
          }
        >
          <Row>
            <Col md="12">
              <Input
                type="text"
                name="groupedName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDynamicallyGroupedName(e.target.value);
                }}
              />
            </Col>
          </Row>
        </CustomModal>
      )}

      {show && (
        <DetailedCargos
          onClose={handleClose}
          onTransfer={onTransfer}
          onRemove={onRemove}
          onOpenChangeAddress={openChangeAddress}
          onCreate={handleShowCreateAttendance}
          isTransferMenuDisabled={transferMenuDisabled}
          gridRef={detailGridRef}
          gridColumns={detailedColDefs}
          gridData={detailedRowData}
        />
      )}

      {showChangeAddress && (
        <ModalAddress
          open={showChangeAddress}
          onClear={() => setShowChangeAddress(!showChangeAddress)}
          data={selectedRows}
          onEditColumns={(data: any, dataAddress: any) => {
            onChangeAddress(data, dataAddress);
          }}
        />
      )}

      <DetailsCargos
        open={showCargoDetail}
        onClose={handleCloseCargoDetail}
        selectedRow={selectedGroupedRow}
        update={updateRowCargoDetail}
        updateRow={updateRouteringCargo}
      />
      {showCreateCargo && (
        <CreateCargoDialog
          open={showCreateCargo}
          onClose={handleCloseCreateCargo}
          data={selectedRows}
          onUpdateRow={updateCreateCargo}
        />
      )}
      {showCargoRouting && (
        <CargoRoutingDialog
          data={selectedRows}
          open={showCargoRouting}
          onClose={handleCloseCargoRouting}
          onUpdateRow={updateRouteringCargo}
        />
      )}

      {showRoutering && (
        <Routering
          onConfirm={(data: CargoPre[]) => {
            updateRowCargoRoutering(data)
          }}
        />
      )}

      {showReleaseLoading && (
        <ReleaseLoadingDialog
          open={showReleaseLoading}
          onClose={handleCloseReleaseLoading}
          data={selectedRows}
          onUpdateRow={updateReleaseLoading}
        />
      )}

      {showCreateAttendance && (
        <CreateAttendance
          open
          onClose={handleCloseCreateAttendance}
          onConfirm={(rows: any[]) => onAddCargo(rows)}
        />
      )}

      <Row className="mb-1">
        <Col md="2">
          <DropdownButton
            as={ButtonGroup}
            title={t('options')}
            className="dropdown-custom"
            disabled={disableOptionsButton()}
          >
            <Dropdown.Item
              className="btn-default"
              eventKey="detail"
              onClick={() => onCargoDetail()}
            >
              {' '}
              {t('action.detail')}
            </Dropdown.Item>
            <Dropdown.Item
              className="btn-default"
              eventKey="detail"
              onClick={() => openGroupingNameModal()}
            >
              {' '}
              {t('action.createGrouping')}
            </Dropdown.Item>
            <Dropdown.Item
              className="btn-default"
              eventKey="create"
              onClick={() => onCreateCargo()}
            >
              {' '}
              {t('action.createCargo')}
            </Dropdown.Item>
            <Dropdown.Item
              className="btn-default"
              eventKey="release"
              onClick={() => onReleased()}
            >
              {' '}
              {t('action.releaseLoading')}
            </Dropdown.Item>
            <Dropdown.Item
              className="btn-default"
              eventKey="detail"
              onClick={() => onRouting()}
            >
              {' '}
              Roteirizar
            </Dropdown.Item>
            <Dropdown.Item
              className="btn-default"
              eventKey="release"
              onClick={() => props.setGroupedType(GroupedType.NO_ACTION)}
            >
              {' '}
              {t('action.back')}
            </Dropdown.Item>
          </DropdownButton>
        </Col>
        <Col md="4" className="pt-1">
          {transferData != null && transferData.length > 0 ? (
            <>
              <span className="badge badge-warning">
                <i className="fa-solid fa-circle-info fa-fade"></i>
                <strong className="ml-1">Cargas a transferir:</strong>{' '}
                {transferData.length}
              </span>
              <a
                href="#"
                onClick={onCancelTransfer}
                className="badge badge-light"
                title="Cancelar transferência de cargas"
              >
                X
              </a>
            </>
          ) : (
            ''
          )}
        </Col>
        <Col md="6" className="pt-1 text-right">
          <span className="badge badge-success mr-1">Carga Fechada</span>
          <span className="badge badge-danger">Endereço não encontrado</span>
        </Col>
      </Row>

      <Row className="ag-theme-quartz h-100">
        <Col md="12">
          {/* The AG Grid component */}
          <AgGridReact
            ref={gridRef}
            rowData={groupedRowData}
            columnDefs={colDefs}
            pagination={true}
            rowSelection={RowSelection.MULTIPLE}
            onCellDoubleClicked={onDetail}
            getRowId={(params: GetRowIdParams) => {
              return params.data.grouper;
            }}
            enableCellChangeFlash={true}
            getRowClass={(params: RowClassParams) => {
              var data = params.data;
              if (data.licensePlate != null) {
                return 'bg-success text-white';
              }

              // no extra classes for leaf rows
              return undefined;
            }}
            onCellClicked={onCellClicked}
          />
        </Col>
      </Row>
    </>
  );
};

export default GroupedGrid;
