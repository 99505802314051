// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unCheckedVehicles {
  border: 1px solid #EA004C;
  box-sizing: border-box;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.checkedVehicles {
  background: #EA004C;
  border-radius: 4px;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectVehicles/CheckBox/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACF,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACA;;AACA;EACE,mBAAA;EACF,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEA","sourcesContent":[".unCheckedVehicles {\n  border: 1px solid #EA004C;\nbox-sizing: border-box;\nborder-radius: 4px;\nwidth: 24px;\nheight: 24px;\n}\n.checkedVehicles {\n  background: #EA004C;\nborder-radius: 4px;\nborder: none;\nwidth: 24px;\nheight: 24px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
