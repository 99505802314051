import { object, ref, string } from 'yup';

export default object().shape({
  cpf: string().required('* Informe seu cpf!'),
  nome: string()
    .required('* Informe seu nome!')
    .max(50, '* Máximo de 50 caracteres'),
  tipo: string().required('* Informe o tipo!'),
  filial: string().required('* Informe a filial'),
});
