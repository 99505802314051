import React, { useState } from 'react';
import './style.css';

interface Props {
  title?: JSX.Element,
  title2?: JSX.Element,
  content?: JSX.Element,
  content2?: JSX.Element,
  delay?: number,
  direction?: string,
  minSize?: number
  isDescription?: boolean

}

const Tooltip: React.FC<Props> = ({
  title,
  delay,
  direction,
  content,
  minSize,
  title2,
  content2,
  isDescription,
  children,
}) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && (
        <div className="Tooltip-Tip-Attendances">
          {title}
          <div className="Tooltip-text"><span>{content}</span></div>
          {title2}
          <div className="Tooltip-text">{content2}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
