// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonDriver {
  background: #EA004C;
  border-radius: 6px;
  padding: 7px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 07 */
  color: #FFFFFF;
}

.inputDriver {
  width: 100%;
  background: #FFFFFF;
  border: 2px solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  height: 35px;
  padding-left: 17px;
}

.itemDriver {
  font-size: 90%;
}

.iconCloseView {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.modalWrapper {
  max-height: 50%;
  padding: 40px;
  border-radius: 10px;
  z-index: 90;
  position: relative;
}

.buttonWrapper {
  background-color: transparent;
  border: none;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: "#003049";
}

.contentModal {
  padding: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 420px;
  overflow-y: scroll;
  position: relative;
}

.tableReason {
  width: 100%;
}

.imgAdd {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/ChangeDriver/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACF,kBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EAGA,OAAA;EAEA,cAAA;AAFA;;AAKA;EACE,WAAA;EACA,mBAAA;EACF,sCAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AAFA;;AAKA;EACE,cAAA;AAFF;;AAMA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;AAHF;;AAKA;EACE,eAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AAFF;;AAKA;EACE,6BAAA;EACA,YAAA;AAFF;;AAKA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAFF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,kBAAA;AADF","sourcesContent":[".buttonDriver {\n  background: #EA004C;\nborder-radius: 6px;\npadding: 7px;\nborder: none;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ncolor: white;\nfont-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 14px;\nline-height: 17px;\n/* identical to box height */\n\n\n/* 07 */\n\ncolor: #FFFFFF;\n}\n\n.inputDriver {\n  width: 100%;\n  background: #FFFFFF;\nborder: 2px solid rgba(0, 48, 73, 0.2);\nbox-sizing: border-box;\nborder-radius: 10px;\nheight: 35px;\npadding-left: 17px;\n}\n\n.itemDriver {\n  font-size: 90%;\n}\n\n\n.iconCloseView {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 20px;\n}\n.modalWrapper {\n  max-height: 50%; \n  padding: 40px; \n  border-radius: 10px; \n  z-index: 90;\n  position: relative;\n}\n\n.buttonWrapper {\n  background-color: transparent;\n  border: none;\n}\n\n.title {\n  font-size: 20px;\n  font-weight: bold;\n  color: '#003049'\n}\n\n.contentModal {\n  padding: 0px;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  max-height: 420px;\n  overflow-y: scroll;\n  position: relative;\n}\n.tableReason {\n  width: 100%;\n}\n\n.imgAdd {\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
