export const country = [
  {
    Pais: 'Brasil',
  },
  {
    Pais: 'Afeganistão',
  },
  {
    Pais: 'África do Sul',
  },
  {
    Pais: 'Albânia',
  },
  {
    Pais: 'Alemanha',
  },
  {
    Pais: 'Andorra',
  },
  {
    Pais: 'Angola',
  },
  {
    Pais: 'Anguila',
  },
  {
    Pais: 'Antártida',
  },
  {
    Pais: 'Antígua e Barbuda',
  },
  {
    Pais: 'Antilhas Holandesas',
  },
  {
    Pais: 'Arábia Saudita',
  },
  {
    Pais: 'Argélia',
  },
  {
    Pais: 'Argentina',
  },
  {
    Pais: 'Armênia',
  },
  {
    Pais: 'Aruba',
  },
  {
    Pais: 'Austrália',
  },
  {
    Pais: 'Áustria',
  },
  {
    Pais: 'Autoridade Palestina',
  },
  {
    Pais: 'Azerbaijão',
  },
  {
    Pais: 'Bahamas',
  },
  {
    Pais: 'Bahrein',
  },
  {
    Pais: 'Bangladesh',
  },
  {
    Pais: 'Barbados',
  },
  {
    Pais: 'Belarus',
  },
  {
    Pais: 'Bélgica',
  },
  {
    Pais: 'Belize',
  },
  {
    Pais: 'Benin',
  },
  {
    Pais: 'Bermuda',
  },
  {
    Pais: 'Bolívia',
  },
  {
    Pais: 'Bósnia e Herzegovina',
  },
  {
    Pais: 'Botsuana',
  },
  
  {
    Pais: 'Brunei',
  },
  {
    Pais: 'Bulgária',
  },
  {
    Pais: 'Burquina Faso',
  },
  {
    Pais: 'Burundi',
  },
  {
    Pais: 'Butão',
  },
  {
    Pais: 'Cabo Verde',
  },
  {
    Pais: 'Camarões',
  },
  {
    Pais: 'Camboja',
  },
  {
    Pais: 'Canadá',
  },
  {
    Pais: 'Catar',
  },
  {
    Pais: 'Cazaquistão',
  },
  {
    Pais: 'Chade',
  },
  {
    Pais: 'Chile',
  },
  {
    Pais: 'China',
  },
  {
    Pais: 'Chipre',
  },
  {
    Pais: 'Cidade do Vaticano',
  },
  {
    Pais: 'Cingapura',
  },
  {
    Pais: 'Colômbia',
  },
  {
    Pais: 'Comoros',
  },
  {
    Pais: 'Congo',
  },
  {
    Pais: 'Congo, República Democrática do',
  },
  {
    Pais: 'Coreia',
  },
  {
    Pais: 'Coreia do Norte',
  },
  {
    Pais: 'Costa Rica',
  },
  {
    Pais: "Côte d'Ivoire (Costa do Marfim)",
  },
  {
    Pais: 'Croácia',
  },
  {
    Pais: 'Cuba',
  },
  {
    Pais: 'Dinamarca',
  },
  {
    Pais: 'Djibuti',
  },
  {
    Pais: 'Dominica',
  },
  {
    Pais: 'Egito',
  },
  {
    Pais: 'El Salvador',
  },
  {
    Pais: 'Emirados Árabes Unidos',
  },
  {
    Pais: 'Equador',
  },
  {
    Pais: 'Eritreia',
  },
  {
    Pais: 'Eslováquia',
  },
  {
    Pais: 'Eslovênia',
  },
  {
    Pais: 'Espanha',
  },
  {
    Pais: 'Estados Unidos',
  },
  {
    Pais: 'Estônia',
  },
  {
    Pais: 'Etiópia',
  },
  {
    Pais: 'Filipinas',
  },
  {
    Pais: 'Finlândia',
  },
  {
    Pais: 'França',
  },
  {
    Pais: 'Gabão',
  },
  {
    Pais: 'Gâmbia',
  },
  {
    Pais: 'Gana',
  },
  {
    Pais: 'Geórgia',
  },
  {
    Pais: 'Geórgia do Sul e Ilhas Sandwich do Sul',
  },
  {
    Pais: 'Gibraltar',
  },
  {
    Pais: 'Granada',
  },
  {
    Pais: 'Grécia',
  },
  {
    Pais: 'Groenlândia',
  },
  {
    Pais: 'Guadalupe',
  },
  {
    Pais: 'Guam',
  },
  {
    Pais: 'Guatemala',
  },
  {
    Pais: 'Guernsey',
  },
  {
    Pais: 'Guiana',
  },
  {
    Pais: 'Guiana Francesa',
  },
  {
    Pais: 'Guiné',
  },
  {
    Pais: 'Guiné Equatorial',
  },
  {
    Pais: 'Guiné-Bissau',
  },
  {
    Pais: 'Haiti',
  },
  {
    Pais: 'Honduras',
  },
  {
    Pais: 'Hong Kong S. A. R',
  },
  {
    Pais: 'Hungria',
  },
  {
    Pais: 'Iêmen',
  },
  {
    Pais: 'Ilha Bouvet',
  },
  {
    Pais: 'Ilha Christmas',
  },
  {
    Pais: 'Ilha de Man',
  },
  {
    Pais: 'Ilha Norfolk',
  },
  {
    Pais: 'Ilhas Cayman',
  },
  {
    Pais: 'Ilhas Cocos (Keeling)',
  },
  {
    Pais: 'Ilhas Cook',
  },
  {
    Pais: 'Ilhas Faroés',
  },
  {
    Pais: 'Ilhas Fiji',
  },
  {
    Pais: 'Ilhas Heard e Ilhas McDonald',
  },
  {
    Pais: 'Ilhas Malvinas (Falkland Islands)',
  },
  {
    Pais: 'Ilhas Marianas do Norte',
  },
  {
    Pais: 'Ilhas Marshall',
  },
  {
    Pais: 'Ilhas Salomão',
  },
  {
    Pais: 'Ilhas Turks e Caicos',
  },
  {
    Pais: 'Ilhas Virgens Americanas',
  },
  {
    Pais: 'Ilhas Virgens Britânicas',
  },
  {
    Pais: 'Índia',
  },
  {
    Pais: 'Indonésia',
  },
  {
    Pais: 'Irã',
  },
  {
    Pais: 'Iraque',
  },
  {
    Pais: 'Irlanda',
  },
  {
    Pais: 'Islândia',
  },
  {
    Pais: 'Israel',
  },
  {
    Pais: 'Itália',
  },
  {
    Pais: 'Jamaica',
  },
  {
    Pais: 'Japão',
  },
  {
    Pais: 'Jersey',
  },
  {
    Pais: 'Jordânia',
  },
  {
    Pais: 'Kiribati',
  },
  {
    Pais: 'Kuaite',
  },
  {
    Pais: 'Laos',
  },
  {
    Pais: 'Lesoto',
  },
  {
    Pais: 'Letônia',
  },
  {
    Pais: 'Líbano',
  },
  {
    Pais: 'Libéria',
  },
  {
    Pais: 'Líbia',
  },
  {
    Pais: 'Liechtenstein',
  },
  {
    Pais: 'Lituânia',
  },
  {
    Pais: 'Luxemburgo',
  },
  {
    Pais: 'Macedônia, Antiga República Iugoslava da',
  },
  {
    Pais: 'Madagascar',
  },
  {
    Pais: 'Malásia',
  },
  {
    Pais: 'Malaui',
  },
  {
    Pais: 'Maldivas',
  },
  {
    Pais: 'Mali',
  },
  {
    Pais: 'Malta',
  },
  {
    Pais: 'Marrocos',
  },
  {
    Pais: 'Martinica',
  },
  {
    Pais: 'Maurício',
  },
  {
    Pais: 'Mauritânia',
  },
  {
    Pais: 'Mayotte',
  },
  {
    Pais: 'México',
  },
  {
    Pais: 'Micronésia',
  },
  {
    Pais: 'Moçambique',
  },
  {
    Pais: 'Moldova',
  },
  {
    Pais: 'Mônaco',
  },
  {
    Pais: 'Mongólia',
  },
  {
    Pais: 'Montenegro',
  },
  {
    Pais: 'Montserrat',
  },
  {
    Pais: 'Myanma',
  },
  {
    Pais: 'Namíbia',
  },
  {
    Pais: 'Nauru',
  },
  {
    Pais: 'Nepal',
  },
  {
    Pais: 'Nicarágua',
  },
  {
    Pais: 'Níger',
  },
  {
    Pais: 'Nigéria',
  },
  {
    Pais: 'Niue',
  },
  {
    Pais: 'Noruega',
  },
  {
    Pais: 'Nova Caledônia',
  },
  {
    Pais: 'Nova Zelândia',
  },
  {
    Pais: 'Omã',
  },
  {
    Pais: 'Países Baixos',
  },
  {
    Pais: 'Palau',
  },
  {
    Pais: 'Panamá',
  },
  {
    Pais: 'Papua-Nova Guiné',
  },
  {
    Pais: 'Paquistão',
  },
  {
    Pais: 'Paraguai',
  },
  {
    Pais: 'Peru',
  },
  {
    Pais: 'Pitcairn',
  },
  {
    Pais: 'Polinésia Francesa',
  },
  {
    Pais: 'Polônia',
  },
  {
    Pais: 'Porto Rico',
  },
  {
    Pais: 'Portugal',
  },
  {
    Pais: 'Quênia',
  },
  {
    Pais: 'Quirguistão',
  },
  {
    Pais: 'RAE de Macau',
  },
  {
    Pais: 'Reino Unido',
  },
  {
    Pais: 'República Centro-Africana',
  },
  {
    Pais: 'República Dominicana',
  },
  {
    Pais: 'República Tcheca',
  },
  {
    Pais: 'Reunião',
  },
  {
    Pais: 'Romênia',
  },
  {
    Pais: 'Ruanda',
  },
  {
    Pais: 'Rússia',
  },
  {
    Pais: 'Saint-Pierre e Miquelon',
  },
  {
    Pais: 'Samoa',
  },
  {
    Pais: 'Samoa Americana',
  },
  {
    Pais: 'San Marino',
  },
  {
    Pais: 'Santa Helena',
  },
  {
    Pais: 'Santa Lúcia',
  },
  {
    Pais: 'São Tomé e Príncipe',
  },
  {
    Pais: 'São Vicente e Granadinas',
  },
  {
    Pais: 'Seicheles',
  },
  {
    Pais: 'Senegal',
  },
  {
    Pais: 'Serra Leoa',
  },
  {
    Pais: 'Sérvia',
  },
  {
    Pais: 'Síria',
  },
  {
    Pais: 'Somália',
  },
  {
    Pais: 'Sri Lanka',
  },
  {
    Pais: 'St. Kitts e Névis',
  },
  {
    Pais: 'Suazilândia',
  },
  {
    Pais: 'Sudão',
  },
  {
    Pais: 'Suécia',
  },
  {
    Pais: 'Suíça',
  },
  {
    Pais: 'Suriname',
  },
  {
    Pais: 'Svalbard',
  },
  {
    Pais: 'Tagiquistão',
  },
  {
    Pais: 'Tailândia',
  },
  {
    Pais: 'Taiwan',
  },
  {
    Pais: 'Tanzânia',
  },
  {
    Pais: 'Terras Austrais e Antárticas Francesas',
  },
  {
    Pais: 'Território Britânico do Oceano Índico',
  },
  {
    Pais: 'Territórios Insulares dos EUA',
  },
  {
    Pais: 'Timor Leste',
  },
  {
    Pais: 'Togo',
  },
  {
    Pais: 'Tokelau',
  },
  {
    Pais: 'Tonga',
  },
  {
    Pais: 'Trinidad e Tobago',
  },
  {
    Pais: 'Tunísia',
  },
  {
    Pais: 'Turcomenistão',
  },
  {
    Pais: 'Turquia',
  },
  {
    Pais: 'Tuvalu',
  },
  {
    Pais: 'Ucrânia',
  },
  {
    Pais: 'Uganda',
  },
  {
    Pais: 'Uruguai',
  },
  {
    Pais: 'Uzbequistão',
  },
  {
    Pais: 'Vanuatu',
  },
  {
    Pais: 'Venezuela',
  },
  {
    Pais: 'Vietnã',
  },
  {
    Pais: 'Wallis e Futuna',
  },
  {
    Pais: 'Zâmbia',
  },
  {
    Pais: 'Zimbábue',
  },
];
