import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Battery from 'components/Map/Cargo/battery';
import OperatorSignal from 'components/OperatorSignal';
// import Tooltip from 'components/Tooltip';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import gpsActive from '../../../../../assets/images/gpsActive.svg';
import gpsInactive from '../../../../../assets/images/gpsInactive.svg';
import phoneVersion from '../../../../../assets/images/phoneVersion.svg';
import phoneVersionActive from '../../../../../assets/images/phoneVersionActive.svg';
import sinalOperadoraEmpty from '../../../../../assets/images/sinalOperdoraEmpty.svg';
import '../styles.scss';
import DialogAppVersion from './DialogAppVersion';

interface DevicesProps {
  data?: any;
  communication: boolean;
  onHistoric: () => void;
}

const Devices: React.FC<DevicesProps> = ({
  data,
  communication,
  onHistoric,
}: DevicesProps) => {
  const [isPhoneVersion, setIsPhoneVersion] = useState(false);
  const [appVersion, setAppVersion] = useState('');
  const [appPhoneNumber, setAppPhoneNumber] = useState('');

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <>
      {isPhoneVersion && (
        <DialogAppVersion
          phoneNumber={appPhoneNumber}
          phoneVersion={appVersion}
          onClear={() => {
            setIsPhoneVersion(!isPhoneVersion);
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>
          <img
            src={
              !data.romaneio.motorista.statusComunicacao
                ? phoneVersionActive
                : phoneVersion
            }
            alt="Phone Version"
            className="imgDevice"
            style={{
              width: '1.763rem',
              height: '1.763rem',

              cursor: 'pointer',
            }}
            onClick={() => {
              setIsPhoneVersion(!isPhoneVersion);
              if (data.romaneio.motorista.appVersion) {
                setAppVersion(data.romaneio.motorista.appVersion);
              }
              setAppPhoneNumber(data.romaneio.motorista.telefone);
            }}
          />
          {data.romaneio.motorista.sinalOperadora &&
          data.romaneio.motorista.sinalOperadora === null ? (
            <HtmlTooltip
              title={
                <React.Fragment>
                  <div style={{ display: 'flex' }}>
                    <div className="tooltipStylesWrap">
                      <span className="reasonCancel">
                        <Trans i18nKey="common.tooltipDevices" />
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              }
            >
              <img
                src={sinalOperadoraEmpty}
                style={{
                  width: 25,
                  height: 24,
                }}
                alt=""
                className="imgDevice"
              />
            </HtmlTooltip>
          ) : (
            <OperatorSignal
              porcentage={data.romaneio.motorista.sinalOperadora}
            />
          )}

          {data.romaneio.motorista.bateria &&
          data.romaneio.motorista.bateria === null ? (
            <></>
          ) : (
            <Battery percentage={data.romaneio.motorista.bateria} marginRight />
          )}

          {data.romaneio.motorista.gps &&
          data.romaneio.motorista.gps !== null &&
          data.romaneio.motorista.gps === 'ACTIVE' ? (
            <img src={gpsActive} alt="" className="imgDevice" />
          ) : (
            <img src={gpsInactive} alt="" className="imgDevice" />
          )}
        </>
      </div>
    </>
  );
};

export default Devices;
