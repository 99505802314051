import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap/lib';

interface Props {
  isOpen?: boolean;
  onCancel?: any;
  onConfirm?: any;
}

const ModalDelete: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <Trans i18nKey="common.questionRemove" />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onConfirm}>
          <Trans i18nKey="common.confirmRemove" />
        </Button>{' '}
        <Button color="secondary" onClick={onCancel}>
          <Trans i18nKey="common.cancelRemove" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
