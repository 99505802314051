import React from 'react';

import BatteryEmpty from '../../../../assets/images/batteryEmpty.svg';
import BatteryGood from '../../../../assets/images/batteryGood.svg';
import BatteryGood100 from '../../../../assets/images/batteryGood100.svg';
import BatteryLow from '../../../../assets/images/batteryLow.svg';
import BatteryMedium from '../../../../assets/images/batteryMedium.svg';

class Battery extends React.Component {
  render() {
    let battery = BatteryGood;

    // Indicador da bateria do dispositivo do entregador.
    if(this.props.porcentage === 0)  {
      battery = BatteryEmpty;
    }
    else if (this.props.percentage <= 20) {
      battery = BatteryLow;
    } else if (this.props.percentage <= 50) {
      battery = BatteryMedium;
    } else if (this.props.percentage <= 70) {
      battery = BatteryGood;
    } else if (this.props.percentage <= 100) {
      battery = BatteryGood100;
    }

    return (
      <>
        <img
          src={battery}
          style={{
            marginRight: this.props.marginRight && 12,
            width: 45,
            height: 24,
          }}
          alt=""
        />
      </>
    );
  }
}

export default Battery;
