import SideBar from 'components/Sidebar';
import { useState } from 'react';
import '../../styles/template.scss';
import Content from '../../template/content/content';


const Main = props => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  return (
    <div className="template wrapper">
      <SideBar
        toggle={toggleSidebar}
        isOpen={sidebarIsOpen}
        selectedPage="MONITORING"
      />
      <Content toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen}>
        {props.children}
      </Content>
    </div>
  );
};

export default Main;
