import styled from 'styled-components';

export const Content = styled.div`
  background-color: #fff;
  border: 2px solid #edf2f4;
  border-radius: 10px;
  padding: 20px;
  width: 50%;
  height: 120px;
  .lineGroup {
    border-bottom: 1px solid #ccd6db;
  }
`;

export const BoxForm = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Footer = styled.div`
  .button {
    height: 3.25rem;
    background: #ea004c;
    border-radius: 6px;
    text-align: center;
    color: #ffffff;
    /* float: right; */
    width: 100%;
    margin-top: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);

    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
    }
  }

  .table-line {
    width: 320px;
  }
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const BodyAcceptRefuse = styled.div`
  display: flex;
  width: 100%;
  /* height: 70vh; */
  flex-direction: column;
`;

export const BodyTextAreaAcceptRefuse = styled.div`
  display: flex;
  width: 100%;
  /* height: 50vh; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ContainerTextArea = styled.div`
  display: flex;
  flex: 1;
`;

export const HeaderAcceptRefuse = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

export const modalStyle = {
  maxWidth: '90%',
  maxHeight: '100%',
};

export const iconCloseBody = {
  padding: '2.2rem',
  borderRadius: 10,
  zIndex: 9,
};

export const iconClose = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '1.25rem',
};

export const inputDescription = {
  height: 35,
  background: '#FFFFFF',
  border: '2px solid rgba(0, 48, 73, 0.2)',
  boxSizing: 'border-box',
  borderRadius: 10,
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: 14,
  lineHeight: 19,
  color: '#003049',
  marginTop: 8,
  paddingLeft: 10,
  marginBottom: 10,
};

export const labelStyle = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 500,
  // margin: '10px 0 0 0',
  display: 'flex',
  flexDirection: 'column',
};

export const dropStyle = {
  display: 'flex',
  flex: 1,
  alignItems: 'left',
  justifyContent: 'left',
  background: '#fff',
  border: '2px solid rgba(0, 48, 73, 0.2)',
  borderRadius: 10,
  height: 40,
  marginTop: 8,
};

export const containerSwitch = {
  display: 'flex',
  flexDirection: 'row',
  // justifyContent: 'center',
  alignItems: 'center',
};

export const columnSwitch = {
  display: 'flex',
  flex: 1,
  flexWrap: 'nowrap',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 8px',
};

export const labelSwitch = {
  fontSize: '0.9rem',
  whiteSpace: 'nowrap',
  overFlow: 'hidden',
  textOverflow: 'ellipsis',
};
