import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import formValidator from './formValidator';

// import { Container } from './styles';

type Region = {
  id: number;
  description: string;
  referenceId: string;
}

type Route = {
  id: number;
  description: string;
  referenceId: string;
  region: Region;
}

interface NewSectorProps {
  onClear: () => void;
}

type NewSectorSchema = {
  description: string;
  referenceId: string;
  route: string;
};

const NewSector: React.FC<NewSectorProps> = ({ onClear }: NewSectorProps) => {
  const [routeList, setRouteList] = useState<Route[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const onHandleSubmit = useCallback(async (data: NewSectorSchema) => {
    try {
      setLoading(true);
      const body = {
        description: data.description,
        referenceId: data.referenceId,
        route: {
          id: data.route
        }
      };
      const response = await api.post('/delivery/sector', body);

      if (response.status === 201) {
        FrontendNotification(
          'Setor criado com sucesso!',
          NotificationType.SUCCESS
        );
        setLoading(false);
        onClear();
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const initialValues: NewSectorSchema = {
    description: '',
    referenceId: '',
    route: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: NewSectorSchema) => onHandleSubmit(values),
  });


  const onHandleListRoute = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filters: [],
        orders: [],
        size: 1000,
        page: 0,
      };

      const response = await api.post('/delivery/route/pageable', body);

      setRouteList(response.data.content);
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, [])


  useEffect(() => {
    onHandleListRoute();
  }, [onHandleListRoute])

  return (
    <div className="tw-flex tw-container tw-max-w-full tw-mt-4">
      <Loading loading={loading} />
      <form className="tw-w-full">
        <div className="tw-flex tw-flex-1 tw-w-full tw-mb-6">
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-4">
            <InputCustom
              title="Descrição"
              onChange={formik.handleChange('description')}
              placeholder="Insira a descrição do setor"
              error={formik.errors.description}
              touched={formik.touched.description}
              value={formik.values.description}
              id='description'
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <InputCustom
              title="Id de referencia"
              onChange={formik.handleChange('referenceId')}
              placeholder="Insira o id de referência do setor"
              error={formik.errors.referenceId}
              touched={formik.touched.referenceId}
              value={formik.values.referenceId}
              id='referenceId'
            />
          </div>
        </div>

        <div className="tw-flex tw-mb-6">
          <div className="tw-flex tw-flex-col tw-w-[50%] tw-mr-4">
          <SelectCustom
              title="Rota"
              onChange={formik.handleChange('route')}
              value={formik.values.route}
              touched={formik.touched.route}
              error={formik.errors.route}
              id='route'
            >
              <>
              <option label={t('common.select')} />
              {routeList.map((route: Route, index: number) => (
                <option label={route.description} key={index} value={route.id}>
                  {route.description}
                </option>
              ))}
              </>
            </SelectCustom>
          </div>
        </div>

        <button
          type="button"
          id='btnSaveSector'
          onClick={() => formik.handleSubmit()}
          className="tw-w-[350px] tw-h-9 tw-rounded-md tw-text-[#fff] tw-bg-[#EA004C] tw-border-none"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default NewSector;
