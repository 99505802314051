import InputCustom from 'components/Input';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import formValidator from './formValidator';

// import { Container } from './styles';

type EditRegionSchema = {
  description: string;
  referenceId: string;
}

type EditRegionData = {
  id: number;
  description?: string;
  referenceId?: string;
}

interface EditRegionProps {
  onClear: () => void;
  data: EditRegionData;
}



const EditRegion: React.FC<EditRegionProps> = ({ onClear, data }: EditRegionProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const onHandleSubmit = useCallback(async (values: EditRegionSchema) => {
    try {
      setLoading(false);
      const body = {
        description: values.description,
        referenceId: values.referenceId,
        
      }
      const response = await api.patch(`/delivery/region/${data.id}`, body);

      if(response.status === 204) {
        FrontendNotification('Região atualizada com sucesso!', NotificationType.SUCCESS);
        setLoading(false);
        onClear();
      }
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, []);

  const initialValues: EditRegionSchema = {
    description: '',
    referenceId: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidator,
    onSubmit: (values: EditRegionSchema) => onHandleSubmit(values),
  });

  const onLoadRouteData = useCallback((data: EditRegionData) => {
    formik.setFieldValue('description', data.description);
    formik.setFieldValue('referenceId', data.referenceId);
  }, [])


  useEffect(() => {
    onLoadRouteData(data);
  }, [])

  return (
    <div className="tw-flex tw-container tw-max-w-full tw-mt-4">
      <Loading loading={loading} />
      <form className="tw-w-full">
        <div className="tw-flex tw-flex-1 tw-w-full tw-mb-2">
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-4">
            <InputCustom
              title="Descrição"
              onChange={formik.handleChange('description')}
              value={formik.values.description}
              touched={formik.touched.description}
              error={formik.errors.description}
              placeholder="Insira a descrição da região"
              id='description'
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <InputCustom
              title="Id de referência"
              onChange={formik.handleChange('referenceId')}
              value={formik.values.referenceId}
              touched={formik.touched.referenceId}
              error={formik.errors.referenceId}
              placeholder="Insira o id de referência da região"
              id='referenceId'
            />
          </div>
        </div>
        
        <button
          type="button"
          id='btnUpdateRegion'
          onClick={() => formik.handleSubmit()}
          className="tw-w-[50%] tw-h-9 tw-rounded-md tw-text-[#fff] tw-bg-[#EA004C] tw-border-none"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default EditRegion;
