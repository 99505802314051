import Pagination from '@material-ui/lab/Pagination';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import '../../pages/DeliveryTracking/styles.scss';
import { Content } from '../Ocorrence/styles';
import './style.scss';

interface HistoricCommunicationProps {
  onClear: any;
  cpfDriver: string;
}

export default function HistoricCommunication({
  onClear,
  cpfDriver,
}: HistoricCommunicationProps) {
  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const [communication, setCommunication] = useState<any>([]);
  const [optionsFilter, setOptionsFilter] = useState<string>('URI');
  const [startDate, setStartDate] = useState<any>(null);
  const [driver, setDriver] = useState<any>([]);
  const [driverName, setDriverName] = useState<string>(cpfDriver);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const [valueSearch, setValueSearch] = useState<string>('');
  const dateCurrent = format(new Date(), 'yyyy-MM-dd', {
    locale: pt,
  });
  const [dateSearch, setDateSearch] = useState('');
  const [filters, setFilters] = useState<any[]>([
    {
      field: 'dataCriacao',
      value: `${dateCurrent}T00:00:00`,
      operation: 'GREATER_THAN_EQUAL',
    },
    {
      field: 'dataCriacao',
      value: `${dateCurrent}T23:59:59`,
      operation: 'LESS_THAN_EQUAL',
    },
    {
      field: 'username',
      value: cpfDriver,
      operation: 'MATCH',
    },
  ]);

  const { t } = useTranslation();

  const customStyles = {
    content: {
      zIndex: 4,
    },
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  function toggleStartDate(date: any) {
    setStartDate(date);
  }

  const getCommunication = useCallback(
    async (filtersData?: any, page?: number) => {
      try {
        setLoading(true);
        const { language } = i18next;

        const serviceLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
        const data = {
          filters:
            filtersData && filtersData.length > 0 ? filtersData : filters,
          orders: [
            {
              field: 'dataCriacao',
              direction: 'DESC',
            },
          ],
          page: page || currentPage,
          size: 10,
        };

        const response = await api.post(
          `/audit/mobile/pageable?lang=${serviceLanguage}`,
          data
        );

        setCommunication(response.data.content);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (err) {}
    },
    [currentPage]
  );

  const getDriver = useCallback(async () => {
    try {
      const response = await api.get('/delivery/motoristas');
      setDriver(response.data);
    } catch {}
  }, []);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
    getCommunication(filters, pageNumber - 1);
  };

  function closeModal() {
    setModal(!modal);
    onClear();
  }

  useEffect(() => {
    getDriver();
    getCommunication();
  }, [getCommunication, getDriver]);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      <CustomModal
        label={t('historicCommunication.title')}
        isOpen
        isClose={onClear}
        styles={{ maxWidth: '90%', maxHeight: '100%' }}
      >
        <Row style={{ marginBottom: 15, marginTop: 0 }}>
          <Col md={3}>
            <InputCustom
              type="date"
              onChange={(e: any) => {
                const dataFilters: any = filters;
                const findDateStart = filters.find(
                  (item: any) => item.field === 'dataCriacao'
                );

                setDateSearch(e.target.value);

                // console.log(e.target.value);

                if (e.target.value.length > 0) {
                  let formatDateCurrent: any = new Date(e.target.value);
                  console.log(formatDateCurrent);
                  formatDateCurrent = formatDateCurrent.setDate(
                    formatDateCurrent.getDate() + 1
                  );
                  const dateCurrent = format(formatDateCurrent, 'yyyy-MM-dd', {
                    locale: pt,
                  });

                  if (findDateStart) {
                    const mappingDateCreated = filters
                      .map((itemFilters: any, indexFilters: number) =>
                        itemFilters.field === 'dataCriacao' ? indexFilters : -1
                      )
                      .filter((itemFilter: any) => itemFilter !== -1);
                    dataFilters[
                      mappingDateCreated[0]
                    ].value = `${dateCurrent}T00:00:00`;
                    dataFilters[
                      mappingDateCreated[1]
                    ].value = `${dateCurrent}T23:59:59`;
                  } else {
                    dataFilters.push(
                      {
                        field: 'dataCriacao',
                        value: `${dateCurrent}T00:00:00`,
                        operation: 'GREATER_THAN_EQUAL',
                      },
                      {
                        field: 'dataCriacao',
                        value: `${dateCurrent}T23:59:59`,
                        operation: 'LESS_THAN_EQUAL',
                      }
                    );
                  }
                }

                setFilters(dataFilters);
              }}
              title="Data"
              placeholder=""
              value={dateSearch}
            />
          </Col>
          <Col md={3}>
            <InputCustom
              type="text"
              onChange={(e: any) => {
                let dataFilters: any = filters;
                let dataFiltersURI;
                const findFilters = filters.find(
                  (item: any) => item.field === 'uri'
                );
                if (e.target.value.length === 0) {
                  dataFilters = dataFilters.filter(
                    (item: any) => item.field !== 'uri'
                  );
                } else if (findFilters) {
                  const mappingDateCreated = filters
                    .map((itemFilters: any, indexFilters: number) =>
                      itemFilters.field === 'uri' ? indexFilters : -1
                    )
                    .filter((itemFilter: any) => itemFilter !== -1);
                  dataFilters[mappingDateCreated[0]].value = e.target.value;
                } else {
                  dataFilters.push({
                    field: 'uri',
                    value: `${e.target.value}`,
                    operation: 'MATCH',
                  });
                }
                setFilters(dataFilters);
              }}
              title="URI"
              placeholder=""
            />
          </Col>
          <Col md={3}>
            <SelectCustom
              onChange={(e: any) => {
                const dataFilters: any = filters;
                const findFilters = filters.find(
                  (item: any) => item.field === 'username'
                );
                if (findFilters) {
                  const mappingDateCreated = filters
                    .map((itemFilters: any, indexFilters: number) =>
                      itemFilters.field === 'username' ? indexFilters : -1
                    )
                    .filter((itemFilter: any) => itemFilter !== -1);
                  dataFilters[mappingDateCreated[0]].value = e.target.value;
                } else {
                  dataFilters.push({
                    field: 'username',
                    value: `${e.target.value}`,
                    operation: 'MATCH',
                  });
                }
                setFilters(dataFilters);
                setDriverName(e.target.value);
              }}
              title="Motorista"
              value={driverName}
            >
              <option label={t('common.select')} />
              {driver.map((driverItem: any) => (
                <option value={driverItem.cpf} label={driverItem.nome} />
              ))}
            </SelectCustom>
          </Col>
          <Col md={3}>
            <button
              className="button"
              style={{ marginTop: 40, width: '70%' }}
              onClick={() => getCommunication(filters)}
            >
              Filtrar
            </button>
          </Col>
        </Row>
        <Content style={{ width: '100%', marginTop: 100 }}>
          <div className="table-responsive">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>
                    <Trans i18nKey="historicCommunication.dateCreated" />
                  </th>
                  <th>
                    <Trans i18nKey="historicCommunication.uri" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {communication.map((itemCommunication: any, index: number) => (
                  <tr className="lineGroup">
                    <td>{itemCommunication.dataCriacao}</td>
                    <td>
                      <p className="uriLimit" style={{ maxWidth: '27ch' }}>
                        {itemCommunication.uri}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e, page) => paginate(page)}
              />
            </div>
          </div>
        </Content>
      </CustomModal>
    </React.Fragment>
  );
}
