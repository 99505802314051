import styled from 'styled-components';

interface StyledProps {
  type?: string;
}

export const ToggleSwitch = styled.div`
  position: relative;
  width: 76px;
  height: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 26px;
  background: #edf2f4;
  padding: 4px;
`;

export const SwitchUnchecked = styled.div`
  position: absolute;
  left: 0;
  margin-left: 4px;
  width: 34px;
  height: 20px;
  border-radius: 26px;
  background-color: #003049;
`;

export const SwitchChecked = styled.div`
  position: absolute;
  right: 0;
  margin-right: 4px;
  width: 34px;
  height: 20px;
  border-radius: 26px;
  background-color: ${(props: StyledProps) =>
    props.type === 'fence'
      ? '#FC8800'
      : props.type === 'coarse'
      ? '#08B639'
      : props.type === 'position' ? '#006CEA' : '#6369D1'};
`;

export const ContainerToggle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  p {
    font-size: 14px;
    margin-bottom: -1px;
    color: #003049;
    opacity: 0.6;
  }
  #container {
    margin-left: 15px;
  }
`;
