/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Modal } from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import { saveTabRoute } from '../../store/modules/map/actions';
import EditFiliais from './EditFiliais';
import ListSubsidiary from './ListSubsidiary';
import NewSubsidiary from './NewSubsidiary';
import './styles.scss';

export default function SubsidiaryRegister({ onClear, onInitial }) {
  const [tabs, setTabs] = React.useState(0);

  const [modal, setModal] = useState(true);
  const [filiais, setFiliais] = useState([]);

  const primeiroAcesso = useSelector(state => state.auth.primaryAccess);

  const [closeModal, setCloseModal] = useState(primeiroAcesso);

  const getFiliais = useCallback(async primaryAccess => {
    try {
      const data = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post('/cadastro/filiais/findByFilters', data);

      const orderFilial = response.data.sort((a, b) => b.id - a.id);

      if (response.data.length === 0) {
        setTabs(1);
      }

      if (response.data.length > 0 && primaryAccess === 'newFilial') {
        setModal(!modal);
        onClear();
      }

      setFiliais(orderFilial);
    } catch (err) {
      // BackendErrorNotification(err);
    }
  }, []);

  function clearNav(data) {
    onClear();
    setModal(!modal);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    getFiliais();
  }, [getFiliais]);
  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        dialogClassName="my-modal"
        style={{ maxWidth: '75%', position: 'relative' }}
      >
        <div
          className=""
          style={{
            height: '90%',
            padding: '40px',
            borderRadius: '10px',
            zIndex: 9,
            position: 'relative',
          }}
        >
          <p
            className="title"
            style={{ fontSize: 30, fontWeight: 'bold', color: '#003049' }}
          >
            <Trans i18nKey="filiais.listTitle" />
          </p>
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => filiais.length > 0 && clearNav(filiais)}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <Tabs selectedIndex={tabs} onSelect={index => setTabs(index)}>
            <TabList>
              <Tab>
                <Trans i18nKey="tabs.list" />
              </Tab>
              <Tab>
                <Trans i18nKey="tabs.new" />
              </Tab>

              {tabs === 2 ? (
                <Tab disabled>
                  <Trans i18nKey="tabs.edit" />
                </Tab>
              ) : (
                <></>
              )}
            </TabList>
            <TabPanel>
              <ListSubsidiary
                onEdit={filialId => {
                  setTabs(2);
                  dispatch(saveTabRoute(filialId));
                }}
                data={filiais}
                onRefresh={() => getFiliais()}
              />
            </TabPanel>

            <TabPanel>
              <NewSubsidiary
                onRegister={() => {
                  setTabs(0);
                  getFiliais('update');
                }}
                onPrimaryRegister={() => {
                  setCloseModal(!closeModal);
                  clearNav();
                }}
                onClearSubsidiary={() => {
                  onClear();
                  setModal(!modal);
                }}
              />
            </TabPanel>
            <TabPanel>
              <EditFiliais
                loadList={() => {
                  setTabs(0);
                  getFiliais('update');
                }}
              />
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
