/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-const */
import CustomModal from 'components/ModalCustom';
// import { Container } from 'pages/ControlTower/components/Table/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderHTML } from './renderHTML';
import { Container } from './styles';
import './styles.scss';

interface PrivacyProps {
  onClear: () => void;
}

export default function Privacy({ onClear }: PrivacyProps) {
  const [modal, setModal] = useState(true);
  const [dataText, setDataText] = useState();

  function clearNav() {
    onClear();
    setModal(false);
  }

  const { t } = useTranslation();

  const windowHeight = window.innerHeight / 2 + 100;

  return (
    <React.Fragment>
      <CustomModal
        label={t('privacy.menuTitle')}
        isOpen={modal}
        styles={{ maxWidth: '90%' }}
        isClose={onClear}
      >
        <Container
          dangerouslySetInnerHTML={{
            __html: renderHTML,
          }}
          className="scrollDiv"
          style={{
            border: '2px solid #EA004C',
            borderRadius: 5,
            marginTop: 20,
            padding: 20,
            maxHeight: `${window.innerHeight / 2 + 130}px`,
          }}
        />
      </CustomModal>
    </React.Fragment>
  );
}
