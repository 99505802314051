import React, { useState } from 'react';
import './style.css';

interface Props {
  children?: JSX.Element;
  activeTooltip?: string;
  cargasAccumulator: any;
  vehicleAccumulator: any;
  porcentageAccumulator: any;
  typeTotalizator?: string;
}

const TotalizadorTC: React.FC<Props> = ({
  children,
  activeTooltip,
  cargasAccumulator,
  vehicleAccumulator,
  porcentageAccumulator,
  typeTotalizator,
}: Props) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && activeTooltip === 'active' && (
        <div className={`Tooltip-Tip-Totalizator-TC ${'bottom'}`}>
          <div className="tw-flex-col tw-items-center tw-justify-center tw-mr-4">
            <div
              className={
                porcentageAccumulator.porcentageWeight < 100
                  ? 'tagTotalizatorPrimary'
                  : 'tagTotalizatorSecondary'
              }
            >
              Peso
            </div>
            <div className="totalizatorFooter">
              <span
                className={
                  porcentageAccumulator.porcentageWeight < 100
                    ? 'textFooterTC primary'
                    : 'textFooterTC secondary'
                }
              >{`${porcentageAccumulator.porcentageWeight}%`}</span>
            </div>
          </div>

          <div className="tw-flex-col tw-items-center tw-justify-center tw-mr-4">
            <div
              className={
                porcentageAccumulator.porcentageVolume < 100
                  ? 'tagTotalizatorPrimary'
                  : 'tagTotalizatorSecondary'
              }
            >
              Cubagem
            </div>
            <div className="totalizatorFooter">
              <span
                className={
                  porcentageAccumulator.porcentageVolume < 100
                    ? 'textFooterTC primary'
                    : 'textFooterTC secondary'
                }
              >{`${porcentageAccumulator.porcentageVolume}%`}</span>
            </div>
          </div>

          <div className="tw-flex-col tw-items-center tw-justify-center tw-mr-2">
            <div
              className={
                porcentageAccumulator.porcentagePrice < 100
                  ? 'tagTotalizatorPrimary'
                  : 'tagTotalizatorSecondary'
              }
            >
              Valor
            </div>
            <div className="totalizatorFooter">
              <span
                className={
                  porcentageAccumulator.porcentagePrice < 100
                    ? 'textFooterTC primary'
                    : 'textFooterTC secondary'
                }
              >{`${porcentageAccumulator.porcentagePrice}%`}</span>
            </div>
          </div>
{/* 
          <div className="tw-flex-col">
            <div
              className={
                porcentageAccumulator < 100
                  ? 'tagTotalizatorPrimary'
                  : 'tagTotalizatorSecondary'
              }
            >
              Peso
            </div>
            <div className="contentTotalizator">
              <div className="textTotalizator">
                <span className="titleTotalizator">Veiculos</span>
                <span className="separatorTotalizator">
                  {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                  {vehicleAccumulator}
                  {typeTotalizator === 'Peso' ? (
                    'Kg'
                  ) : typeTotalizator === 'Cubagem' ? (
                    'm³'
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div className="textTotalizator separatorTotalizator">X</div>
              <div className="textTotalizator">
                <span className="titleTotalizator">Cargas</span>
                <span className="separatorTotalizator">
                  {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                  {cargasAccumulator}
                  {typeTotalizator === 'Peso' ? (
                    'Kg'
                  ) : typeTotalizator === 'Cubagem' ? (
                    'm³'
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
            <div className="totalizatorFooter">
              <span
                className={
                  porcentageAccumulator < 100
                    ? 'textFooter primary'
                    : 'textFooter secondary'
                }
              >{`${porcentageAccumulator}%`}</span>
              <span
                className={
                  porcentageAccumulator < 100
                    ? 'textFooter primary'
                    : 'textFooter secondary'
                }
              >
                da capacidade
              </span>
            </div>
          </div>
          <div className="tw-flex-col">
            <div
              className={
                porcentageAccumulator < 100
                  ? 'tagTotalizatorPrimary'
                  : 'tagTotalizatorSecondary'
              }
            >
              Peso
            </div>
            <div className="contentTotalizator">
              <div className="textTotalizator">
                <span className="titleTotalizator">Veiculos</span>
                <span className="separatorTotalizator">
                  {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                  {vehicleAccumulator}
                  {typeTotalizator === 'Peso' ? (
                    'Kg'
                  ) : typeTotalizator === 'Cubagem' ? (
                    'm³'
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div className="textTotalizator separatorTotalizator">X</div>
              <div className="textTotalizator">
                <span className="titleTotalizator">Cargas</span>
                <span className="separatorTotalizator">
                  {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                  {cargasAccumulator}
                  {typeTotalizator === 'Peso' ? (
                    'Kg'
                  ) : typeTotalizator === 'Cubagem' ? (
                    'm³'
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
            <div className="totalizatorFooter">
              <span
                className={
                  porcentageAccumulator < 100
                    ? 'textFooter primary'
                    : 'textFooter secondary'
                }
              >{`${porcentageAccumulator}%`}</span>
              <span
                className={
                  porcentageAccumulator < 100
                    ? 'textFooter primary'
                    : 'textFooter secondary'
                }
              >
                da capacidade
              </span>
            </div>
          </div>
         */}
        </div>
      )}
    </div>
  );
};

export default TotalizadorTC;
