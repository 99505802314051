import { GroupedRow, ReturnedRow } from 'components/CreateCargo/types';
import { Filter } from 'components/Grid/types';
import Loading from 'core/common/LoadingRoutering';
import CargoPre from 'interfaces/Delivery/CargoPre';
import Leaflet from 'leaflet';

import React, { useCallback, useEffect, useState } from 'react';
import SideBar from 'pages/RouterCargo/components/SidebarRoutes';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Carousel from './components/Carousel';
import CustomFilters from './components/ModalFilter';
import LoadCargos from './components/LoadCargos';
import { Container } from './styles';
import './styles.scss';

const CargoCreate: React.FC = () => {

  const [zoom, setZoom] = useState<number>(10);
  const [initialPosition, setInitialPosition] = useState<any>([
    -8.04162,
    -34.88891,
  ]);
  const [showLoadCargos, setShowLoadCargos] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [cargoPositions, setCargoPositions] = useState<ReturnedRow[]>([]);
  const [multiplePoints, setMultiplePoints] = useState<any>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedGroupedRow, setSelectedGroupedRow] = useState<GroupedRow>();

  function getHtmlPositionIcon(number: number, fillColor: any) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `<div style='border: 5px solid ${fillColor}' class=${number < 99
        ? 'minSizeCurrent'
        : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
        }><div class='marker-multiple-content'><i class='marker-text-current' style='color: ${fillColor}'></i></div></div>`,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  function getHtmlPositionIconMultiple(number: number, fillColor: any) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `<div style='border: 5px solid ${fillColor}' class=${number < 99 ? 'minSize' : number < 1000 ? 'mediumSize' : 'maxSize'
        }><div class='marker-multiple-content'><i class='marker-text-multiple' style='color: ${fillColor}'>
      </i></div></div>`,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  const getMarkersPositions = (
    index: number,
    color: string
  ) => {
    if (findCargoPosition.length > 1) {
      return getHtmlPositionIconMultiple(findCargoPosition.length, color);
    }

    return getHtmlPositionIcon(index, color);
  };

  function findCargoPosition(
    latitude: any,
    longitude: any,
    stepsData: any[]
  ) {
    let positionMultiple = stepsData.filter(
      (itemPosition: any) =>
        itemPosition.enderecoLatitude === latitude ||
        (itemPosition.enderecoLatitudeColeta === latitude &&
          itemPosition.enderecoLongitude === longitude) ||
        itemPosition.enderecoLongitudeColeta === longitude
    );

    return positionMultiple;
  }

  // Responsável por configurar uma lista com os dados para os marcadores.
  const onConfigMarkerList = useCallback((data: ReturnedRow[]) => {
    let rows: any[] = [];

    // Varre a lista de dados retornadas e monta um array para 
    // que sejam exibidos os marcadores a partir desse array.
    data.map((item: ReturnedRow) => {
      item.rows.map((row: CargoPre) => {
        rows.push({
          ...row,
          color: item.color,
        });
      });
    });

    setCargoPositions(rows);
  }, []);

  // Recebe a linha de agrupamento selecionada.
  const onSetSelectedGroupedRow = (groupedRow: GroupedRow) => {
    setSelectedGroupedRow(groupedRow);
  }

  useEffect(() => {
    if (selectedGroupedRow != null && selectedGroupedRow.rows != null && selectedGroupedRow.rows.length > 0) {
      // Percorre todos os registros para pegar o primeiro que possui lat e lng.
      for (let i = 0; i < selectedGroupedRow.rows.length; i++) {
        let lat: string = selectedGroupedRow?.rows[i].enderecoLatitude != '' ?
          selectedGroupedRow?.rows[i].enderecoLatitude : selectedGroupedRow?.rows[i].enderecoLatitudeColeta;
        let lng: string = selectedGroupedRow?.rows[i].enderecoLongitude != '' ?
          selectedGroupedRow?.rows[i].enderecoLongitude : selectedGroupedRow?.rows[i].enderecoLongitudeColeta;

        if (lat !== '' && lng !== '') {
          let numLat = Number(lat);
          let numLng = Number(lng);
          setInitialPosition([numLat, numLng]);
          setZoom(13);
          return;
        }
      }
    }
  }, [selectedGroupedRow]);

  return (
    <Container className="tw-relative">
      <Loading loading={loading} />
      <CustomFilters
        open={showFilters}
        onConfirm={(data: Filter[]) => {
          setLoading(true);
          setFilters(data);
          setShowFilters(false);
          setShowLoadCargos(true);
          setLoading(false);
        }}
        onClose={() => { }}
      />
      {showLoadCargos && (
        <LoadCargos
          handleReturnedRows={(data: ReturnedRow[]) =>
            onConfigMarkerList(data)
          }
          handleSelectedGroupedRow={onSetSelectedGroupedRow}
          filters={filters}
        />
      )}
      <SideBar onChange={() => { }} />

      <Map
        style={{
          width: '100%',
          cursor: 'pointer',
        }}
        center={initialPosition}
        zoom={zoom}
        zoomControl
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {cargoPositions.length > 0 &&
          cargoPositions.map((row: any, index: number) => (
            <>
              <Marker
                key={`marker-${row.id}`}
                position={
                  row.tipo === 'Entrega'
                    ? [row.enderecoLatitude, row.enderecoLongitude]
                    : [row.enderecoLatitudeColeta, row.enderecoLongitudeColeta]
                }
                icon={
                  getMarkersPositions(
                    index,
                    row.color
                  )
                }
                onClick={(e: any) => {
                  setLoading(true);
                  setMultiplePoints([]);
                  setMultiplePoints([cargoPositions[index]]);
                  setLoading(false);
                }}
              >
                <Popup
                  className="popupCargas"
                  style={{ top: '-232px !important' }}
                >
                  <Carousel
                    data={multiplePoints}
                    onChangeRoute={() => { }}
                    onDeleteStep={() => { }}
                    onChangeAddress={() => { }}
                  />
                </Popup>
              </Marker>
            </>
          ))}
      </Map>
    </Container>
  );
};

export default CargoCreate;
