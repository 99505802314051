import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import { BoxForm, Content, Footer, IconColumn } from 'components/Driver/styles';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import api from 'services/api';
import { responsiveSize } from 'styles/responsive';
import formValidator from './formValidator';

interface Props {
  onClear?: any;
}

interface FormValues {
  descricao: string;
}

interface BreaksData {
  name: string;
  id: number;
}

const Breaks: React.FC<Props> = ({ onClear }: Props) => {
  const [edit, setEdit] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState('descricao');
  const [valueSearch, setValueSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [updateId, setUpdateId] = useState<number | null>(null);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState<number | null>(null);
  const [dataBreaks, setDataBreaks] = useState<BreaksData[]>([]);

  const { t } = useTranslation();

  const getBreaks = useCallback(async () => {
    try {
      const currentFilters: any = [];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        orders: [],
        page: currentPage,
        size: 10,
      };

      const response = await api.post('/workday/pauses/pageable', data);

      setDataBreaks(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch {}
  }, [currentPage, filters]);

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      breaksId: number | null,
      resetForm: any,
      edit: boolean
    ) => {
      try {
        const data = {
          name: values.descricao,
        };

        const { language } = i18next;

        const erroLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

        if (edit) {
          const response = await api.patch(
            `/workday/pauses/${breaksId}?lang=${erroLanguage}`,
            data
          );
          if (response.status) {
            FrontendNotification(
              t('breaks.messageUpdated'),
              NotificationType.SUCCESS
            );
            setEdit(false);
            setUpdateId(null);
          }
        } else {
          const response = await api.post(
            `/workday/pauses?lang=${erroLanguage}`,
            data
          );
          if (response.status) {
            FrontendNotification(
              t('breaks.messageSuccess'),
              NotificationType.SUCCESS
            );
          }
        }

        resetForm({});
        setEdit(false);
        setUpdateId(null);
        getBreaks();
      } catch {}
    },
    []
  );

  const initialValues: FormValues = {
    descricao: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues, { resetForm }) =>
      handleSubmit(values, updateId, resetForm, edit),
  });

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const deleteDriver = useCallback(async (breakId: any) => {
    try {
      // setRemoveConfirmation(true);
      const { language } = i18next;

      const erroLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
      const response = await api.delete(
        `/workday/pauses/${breakId}?lang=${erroLanguage}`
      );
      if (response.status === 204) {
        FrontendNotification(
          t('breaks.messageRemove'),
          NotificationType.SUCCESS
        );
      }
      setRemoveConfirmation(false);
      getBreaks();
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    getBreaks();
  }, [getBreaks]);

  return (
    <CustomModal isOpen label="Pausas" isClose={onClear}>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <SearchFilter
        isDescription
        isService
        isTypeDriver={optionsFilter === 'tipo'}
        valueSearch={valueSearch}
        optionValue={optionsFilter}
        handleChange={(e: any) => setValueSearch(e.target.value)}
        handleSelect={(e: any) => setOptionsFilter(e.target.value)}
        onExecuteFilter={() => {
          setCurrentPage(0);
          if (optionsFilter === 'descricao' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'name',
                value: valueSearch,
                operation: 'MATCH',
              },
            ]);
          } else {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
            ]);
          }
        }}
      />
      <div className="d-flex">
        <BoxForm style={{ marginTop: responsiveSize(50) }}>
          <InputCustom
            onChange={formik.handleChange('descricao')}
            placeholder="Insira a descrição da pausa"
            title="Descrição"
            value={formik.values.descricao}
            error={formik.errors.descricao}
            touched={formik.touched.descricao}
          />

          <Footer>
            <Row>
              {edit ? (
                <>
                  <Col md={6}>
                    <button
                      onClick={() => {
                        formik.resetForm({});
                        setEdit(false);
                        setUpdateId(null);
                      }}
                      id="cancel"
                      className="button"
                      type="button"
                      style={{
                        width: '100%',
                        background: '#ccc',
                        color: '#000',
                      }}
                    >
                      <Trans i18nKey="common.cancel" />
                    </button>
                  </Col>

                  <Col md={6}>
                    <button
                      id="update"
                      className="button"
                      type="button"
                      onClick={() => formik.handleSubmit()}
                      style={{ width: '100%' }}
                    >
                      Atualizar Pausa
                      {/* <Trans i18nKey="driver.update" /> */}
                    </button>
                  </Col>
                </>
              ) : (
                <Col md={12} xs={12}>
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="button"
                    id="save"
                    type="button"
                    style={{ width: '100%' }}
                  >
                    Criar Pausa
                    {/* <Trans i18nKey="driver.title" /> */}
                  </button>
                </Col>
              )}
            </Row>
          </Footer>
        </BoxForm>
        <Content style={{ marginTop: responsiveSize(50) }}>
          <div className="table-responsive">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>
                    <span
                      style={{
                        fontSize: responsiveSize(15),
                        color: '#003049',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                      }}
                    >
                      Descricao
                    </span>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {dataBreaks.map((item: BreaksData) => (
                  <tr className="lineGroup">
                    <td>
                      <span
                        style={{
                          fontSize: responsiveSize(14),
                          color: '#003049',
                        }}
                      >
                        {item.name}
                      </span>
                    </td>
                    <IconColumn>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          formik.setFieldValue('descricao', item.name);
                          setEdit(true);
                          setUpdateId(item.id);
                        }}
                      >
                        <img
                          src={EditIcon}
                          alt=""
                        />
                      </Button>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          setRemoveId(item.id);
                          setRemoveConfirmation(!removeConfirmation);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                        />
                      </Button>
                    </IconColumn>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: responsiveSize(20),
              }}
            >
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e, page) => {}}
              />
            </div>
          </div>
        </Content>
      </div>
    </CustomModal>
  );
};

export default Breaks;
