// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablesJourney {
  border: 1px solid #CCD6DB;
}
.tablesJourney th {
  border-right: 1px solid #CCD6DB;
  flex-direction: column;
  padding: 20px;
}

.reportsColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerJourney {
  font-size: 16px;
  line-height: 19px;
  color: #EA004C;
  text-align: center;
}

.headerJourneyDate {
  font-size: 16px;
  font-family: "Lato";
  font-weight: 400;
  color: #003049;
  margin-top: 0.7rem;
}

.rowJourney {
  border-right: 1px solid #CCD6DB;
  border-top: 1px solid #CCD6DB;
  padding: 18px;
}

.alignRowJourney {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowText {
  font-size: 16px;
  color: #003049;
  opacity: 0.6;
  line-height: 19px;
}`, "",{"version":3,"sources":["webpack://./src/components/ReportsJourney/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,+BAAA;EACA,sBAAA;EACA,aAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,+BAAA;EACA,6BAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,eAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;AACF","sourcesContent":[".tablesJourney {\n  border: 1px solid #CCD6DB;\n  th {\n    border-right: 1px solid #CCD6DB;\n    flex-direction: column;\n    padding: 20px;\n  }\n}\n\n.reportsColumn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.headerJourney {\n  font-size: 16px;\n  line-height: 19px;\n  color: #EA004C;\n  text-align: center;\n}\n\n.headerJourneyDate {\n  font-size: 16px;\n  font-family: 'Lato';\n  font-weight: 400;\n  color: #003049;\n  margin-top: 0.7rem;\n}\n\n.rowJourney {\n  border-right: 1px solid #CCD6DB;\n  border-top: 1px solid #CCD6DB;\n  padding: 18px;\n}\n\n.alignRowJourney {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.rowText {\n  font-size: 16px;\n  color: #003049;\n  opacity: 0.6;\n  line-height: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
