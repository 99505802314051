import Grid from 'components/Grid/Grid';
import {
  ColumnDef,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import { Alert } from 'components/ReasonsAlert/interfaces/types';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  alert: string;
  onClear: () => void;
  onConfirm: (data: Alert) => void;
}

const ReasonsAlert: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'descricao',
      headerName: 'Descrição',
      filter: true,
      checkboxSelection: true,
    },
    {
      field: 'tipo',
      headerName: 'Alerta',
      width: 550,
      filter: true,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<Alert[]>([]);

  const { t } = useTranslation();

  return (
    <CustomModal
      label={t('reasonsAlert.title')}
      isOpen
      isClose={props.onClear}
      styles={{ minWidth: '70%' }}
    >
      <>
        <div className="tw-flex tw-w-full tw-h-96">
          <Grid<Alert>
            columns={columns}
            filters={[
              {
                field: 'tipo',
                operation: SearchOperation.MATCH,
                value: props.alert,
              },
            ]}
            pagination
            path="/delivery/motivos/alertas/pageable"
            showCrudButtons={false}
            rowSelection={RowSelection.SINGLE}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>
        <div className="tw-flex tw-justify-end">
          <button
            className="button"
            onClick={() => {
              if (selectedRows.length > 0) {
                props.onConfirm(selectedRows[0]);
              } else {
                FrontendNotification(
                  'Selecione pelo menos um registro',
                  NotificationType.WARNING
                );
              }
            }}
          >
            Confirmar
          </button>
        </div>
      </>
    </CustomModal>
  );
};

export default ReasonsAlert;
