import { ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import iconLido from 'assets/images/iconLido.svg';
import iconNaoLido from 'assets/images/iconNaoLido.svg';
import Grid from 'components/Grid/Grid';
import {
  ColumnDef,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import { Alert } from 'components/ReasonsAlert/interfaces/types';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import ReasonsAlert from './components/ReasonsAlert';
import { CargoAlert } from './interface/types';
import ListAttendances from './ListAttendances';

interface Props {
  idCargo?: number | string | null;
  onClear: () => void;
  isShipment: boolean;
}

const Alertas: React.FC<Props> = (props: Props) => {
  const [showCargo, setShowCargo] = useState<boolean>(false);
  const [cargoId, setCargoId] = useState<any>();
  const [showViewAlerts, setShowViewAlerts] = useState<boolean>(false);
  const [typeAlert, setTypeAlert] = useState<string>('');
  const [alertId, setAlertId] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState('');

  const [columns] = useState<ColumnDef[]>([
    {
      field: 'leitura',
      headerName: '',
      filter: true,
      width: 60,
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <div className="tw-flex tw-items-center tw-mt-2 tw-cursor-pointer">
            {params.data.dataLeitura !== null ? (
              <img src={iconLido} alt="" />
            ) : (
              <img
                src={iconNaoLido}
                alt=""
                className="imgActionAlert"
                onClick={() => {
                  setShowViewAlerts(!showViewAlerts);
                  setTypeAlert(params.data.tipo);
                  setAlertId(params.data.id);
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: 'tipo',
      headerName: 'Alerta',
      filter: true,
    },
    {
      field: 'descricao',
      headerName: 'Etapa',
      filter: true,
    },
    {
      field: 'motivoAlerta.descricao',
      headerName: 'Justificativa',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value && params.value !== null) {
          return params.value;
        }
        return '---';
      },
    },
    {
      field: 'conhecimento',
      headerName: '',
      cellRenderer: (params: CustomCellRendererProps) => {
        console.log(params);
        return (
          <div className="tw-flex tw-items-center tw-mt-2">
            <button
              className="tw-text-sm tw-font-bold tw-text-[#EA004C] tw-border-none"
              style={{ background: 'transparent' }}
              onClick={() => {
                setShowCargo(!showCargo);
                setCargoId(params.data.conhecimentoId);
              }}
            >
              Ver atendimento
            </button>
          </div>
        );
      },
    },
    {
      field: 'dataRegistro',
      headerName: 'Data/Hora',
      filter: true,
    },
    {
      field: 'usuarioLeitura.nome',
      headerName: 'Lido por',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value && params.value !== null) {
          return params.value;
        }
        return '---';
      },
    },
    {
      field: 'dataLeitura',
      headerName: 'Lido em',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value && params.value !== null) {
          return format(new Date(params.value), 'dd/MM/yyyy HH:mm', {
            locale: pt,
          });
        }
        return '---';
      },
    },
    {
      field: 'conhecimento.romaneio.numero',
      headerName: 'Carga',
      filter: true,
    },
  ]);
  const [rowData, setRowData] = useState<Alert[]>([]);
  const [filters, setFilters] = useState([
    {
      field: props.isShipment ? 'conhecimento.id' : 'conhecimento.romaneio.id',
      operation: SearchOperation.EQUAL,
      value: String(props.idCargo),
    },
  ]);

  const { t } = useTranslation();

  const onAlertReading = useCallback(
    async (alertId?: number, motivoId?: number) => {
      try {
        setLoading(true);

        const response = await api.patch(
          `/delivery/conhecimentosAlertas/realizaLeitura/${alertId}/motivo/${motivoId}`
        );
        setShowViewAlerts(false);
        setRefresh(String(Math.random()));

        FrontendNotification(
          'Alerta lido com sucesso!',
          NotificationType.SUCCESS
        );
        setLoading(false);

        // getAlerts();
      } catch (err) {}
    },
    []
  );

  return (
    <CustomModal
      label={t('alerts.title')}
      isClose={() => props.onClear()}
      isOpen
      styles={{ minWidth: '70%' }}
    >
      <>
        <Loading loading={loading} />
        {showCargo && (
          <ListAttendances
            alertCargo={cargoId}
            isAlert={true}
            onClear={() => setShowCargo(!showCargo)}
            idRomaneio={null}
          />
        )}
        {showViewAlerts && (
          <ReasonsAlert
            alert={typeAlert}
            onClear={() => setShowViewAlerts(!showViewAlerts)}
            onConfirm={(data: Alert) => onAlertReading(alertId, data.id)}
          />
        )}

        <div className="tw-flex tw-w-full tw-h-96">
          <Grid<CargoAlert>
            columns={columns}
            filters={filters}
            pagination
            path="/delivery/conhecimentosAlertas/pageable"
            rowSelection={RowSelection.SINGLE}
            showCrudButtons={false}
            setRowData={setRowData}
            refresh={refresh}
          />
        </div>
      </>
    </CustomModal>
  );
};

export default Alertas;
