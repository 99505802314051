// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalWrapperVehicles {
  height: 90%;
  padding: 40px;
  border-radius: 10px;
  z-index: 9;
  position: relative;
}

.iconViewModal {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

.buttonCloseView {
  background-color: transparent;
  border: none;
}

.formSearchVehicles {
  display: flex;
}

.imgSearchVehicles {
  width: 24px;
  height: 24px;
  position: relative;
  left: 33px;
  top: 9px;
}

.inputSearchVehicles {
  padding-left: 40px;
}

.formSearchMargin {
  margin-left: 0px;
}

.addVehicles {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/RouterCargo/components/CargasRouting/components/ChangeVehicles/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;AACJ;;AAMA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,aAAA;AAHJ;;AAMA;EACI,6BAAA;EACA,YAAA;AAHJ;;AAMA;EACI,aAAA;AAHJ;;AAMA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;AAHJ;;AAMA;EACI,kBAAA;AAHJ;;AAMA;EACI,gBAAA;AAHJ;;AAMA;EACI,kBAAA;AAHJ","sourcesContent":[".modalWrapperVehicles {\n    height: 90%;\n    padding: 40px;\n    border-radius: 10px;\n    z-index: 9;\n    position: relative;\n}\n\n.ag-root-wrapper-body.ag-layout-normal {\n    // height: 40%;\n}\n\n.iconViewModal {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 20px;\n}\n\n.buttonCloseView {\n    background-color: transparent;\n    border: none;\n}\n\n.formSearchVehicles {\n    display: flex;\n}\n\n.imgSearchVehicles {\n    width: 24px;\n    height: 24px;\n    position: relative;\n    left: 33px;\n    top: 9px;\n}\n\n.inputSearchVehicles {\n    padding-left: 40px;\n}\n\n.formSearchMargin {\n    margin-left: 0px;\n}\n\n.addVehicles {\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
