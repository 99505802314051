import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  height: auto;
  background: #fff;
  margin-top: 20px;
  margin-left: 20px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.18);
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 32px;
`;

export const OrderTitle = styled.span`
  font-size: 20px;
  color: #003049;
  font-family: Lato;

  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 64px;
`;

export const Line = styled.hr`
  width: 100%;
  background-color: #d2d2d2;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 49px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 49px;
  position: relative;
  left: -140px;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
