import { object, ref, string } from 'yup';

export default object().shape({
  cpf: string().required('* Enter the cpf!'),
  // .min(14, '* Minimum of 14 characters'),
  // .max(14, '* Máximo de 14 caracteres'),
  nome: string()
    .required('* Enter the name!')
    .max(50, '* Maximum of 50 characters'),
  telefone: string().required('* Enter the phone!'),
  tipo: string().required('* Enter the type!'),
  confirmarSenha: string().when('password', (password, field) =>
    password
      ? field
          .required('* Repeat your password!')
          .min(6, '* Mínimo 6 caracteres!')
          .oneOf([ref('password')], '* Passwords does not match!')
      : field
  ),
  filial: string().required('* Enter the subsidiary'),
});
