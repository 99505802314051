import produce from 'immer';

const INITIAL_STATE = {
  data: {},
  dataAddress: [],
};

export default function csvMapping(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@csvMapping/GET_DATA_CSV': {
        draft.data = action.payload.data;
        break;
      }
      case '@csvMapping/SET_DATA_ADDRESS': {
        draft.dataAddress = action.payload.dataAddress;
        break;
      }
      default:
    }
  });
}
