import CloseIcon from '@mui/icons-material/Close';
import { Dialog, styled } from '@mui/material';
import React from 'react';
import { Modal } from 'reactstrap';
import { CloseModal, Container, Title } from './styles';

// import { Container } from './styles';

type Props = {
  onClose: () => void;
  open: boolean;
  children: JSX.Element;
  showHeaderIcon: boolean;
  title: string;
  width?: number;
  height?: number;
};

const CustomDialog: React.FC<Props> = ({
  onClose,
  open,
  children,
  showHeaderIcon,
  title,
  width,
  height,
}) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiPaper-root': {
      borderRadius: '0.313rem',
      width: width || '800px',
      height: height,
    },
    '& .MuiDialogTitle-root': {
      background: '#EA004C',
      height: '2rem',
      padding: '0.313rem',
      borderRadius: '0.313rem 0.313rem 0 0',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  // const handleResize = () => {
  //   if (modalRef.current) {
  //     const { offsetWidth, offsetHeight } = modalRef.current;
  //     setDimensions({ width: offsetWidth, height: offsetHeight });
  //   }
  // };

  

  return (
    <Modal
      size="xl"
      isOpen={open}
      style={{
        width: width,
        height: height,
        // minWidth: '800px',
        marginTop: 10,
      }}
      dialogClassName="my-modal"
    >
      <>
        <div className="tw-bg-[#EA004C] tw-h-8 tw-p-1">
          <Container>
            <Title>{title}</Title>

            <CloseModal onClick={onClose}>
              <CloseIcon style={{ color: 'white' }} />
            </CloseModal>
          </Container>
        </div>
        <div className="tw-p-4">{children}</div>
      </>
    </Modal>
  );
};

export default CustomDialog;
