import {
  NAME_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from '../constants/field';

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const formatDateToDBDate = (date: string) => {
  const d = date.split('/');
  return `${d[2]}-${d[1]}-${d[0]}`;
};

export const isValidPassword = (password: string) => {
  return (
    password.length >= PASSWORD_MIN_LENGTH &&
    password.length <= PASSWORD_MAX_LENGTH
  );
};

export const isValidFirstName = (firstName: string) => {
  return firstName.length >= NAME_MIN_LENGTH;
};

export const isValidLastName = (lastName: string) => {
  return lastName.length >= NAME_MIN_LENGTH;
};

export const isValidPasswordConfirmation = (password: string, passwordConfirmation: string) => {
  return password === passwordConfirmation;
};

export const validateZipcode = (zipcode: string) => {
  const regexCEP = /^[0-9]{5}-[0-9]{3}$/;

  if (regexCEP.test(zipcode)) {
    return true;
  } else {
    return false;
  }
};