/* eslint-disable prefer-const */
import React from 'react';
import vuc from '../../assets/images/typeVehicles/vuc.svg';
import toco from '../../assets/images/typeVehicles/toco.svg';
import truck from '../../assets/images/typeVehicles/truck.svg';
import carreta from '../../assets/images/typeVehicles/cart.svg';
import van from '../../assets/images/typeVehicles/van.svg';
import tourVehicles from '../../assets/images/typeVehicles/tourVehicles.svg';
import motorcycle from '../../assets/images/typeVehicles/motorcycle.svg';
import bicycle from '../../assets/images/typeVehicles/bicycle.svg';

// Icon Lates

import vucLate from '../../assets/images/typeVehicles/vucLate.svg';
import tocoLate from '../../assets/images/typeVehicles/tocoLate.svg';
import truckLate from '../../assets/images/typeVehicles/truckLate.svg';
import carretaLate from '../../assets/images/typeVehicles/cartLate.svg';
import vanLate from '../../assets/images/typeVehicles/vanLate.svg';
import tourVehicleLate from '../../assets/images/typeVehicles/tourVehicleLate.svg';
import motorcycleLate from '../../assets/images/typeVehicles/motorcycleLate.svg';
import bicycleLate from '../../assets/images/typeVehicles/bicycleLate.svg';

// Alerts

import vucAlert from '../../assets/images/typeVehicles/vucAlert.svg';
import tocoAlert from '../../assets/images/typeVehicles/tocoAlert.svg';
import truckAlert from '../../assets/images/typeVehicles/truckAlert.svg';
import carretaAlert from '../../assets/images/typeVehicles/cartAlert.svg';
import vanAlert from '../../assets/images/typeVehicles/vanAlert.svg';
import tourVehiclesAlert from '../../assets/images/typeVehicles/tourVehiclesAlert.svg';
import motorcycleAlert from '../../assets/images/typeVehicles/motorcycleAlert.svg';
import bicycleAlert from '../../assets/images/typeVehicles/bicycleAlert.svg';
// Cancelado

import vucCancel from '../../assets/images/typeVehicles/vucCancel.svg';
import tocoCancel from '../../assets/images/typeVehicles/tocoCancel.svg';
import truckCancel from '../../assets/images/typeVehicles/truckCancel.svg';
import carretaCancel from '../../assets/images/typeVehicles/cartCancel.svg';
import vanCancel from '../../assets/images/typeVehicles/vanCancel.svg';
import tourVehiclesCancel from '../../assets/images/typeVehicles/tourVehiclesCancel.svg';
import motorcycleCancel from '../../assets/images/typeVehicles/motorcyleCancel.svg';
import bicycleCancel from '../../assets/images/typeVehicles/bicycleCancel.svg';

export default function getIconVehicle(
  type,
  alertNotRead,
  statusCarga,
  isCancel
) {
  let iconVehicles;
  if (alertNotRead === true) {
    if (type === 'VUC') {
      iconVehicles = vucAlert;
    } else if (type === 'TOCO') {
      iconVehicles = tocoAlert;
    } else if (type === 'TRUCK') {
      iconVehicles = truckAlert;
    } else if (type === 'CARRETA') {
      iconVehicles = carretaAlert;
    } else if (type === 'VAN') {
      iconVehicles = vanAlert;
    } else if (type === 'VEICULO_PASSEIO') {
      iconVehicles = tourVehiclesAlert;
    } else if (type === 'MOTO') {
      iconVehicles = motorcycleAlert;
    } else if (type === 'BICICLETA') {
      iconVehicles = bicycleAlert;
    } else {
      iconVehicles = vucAlert;
    }
  } else if (alertNotRead === false) {
    if (statusCarga === 'ATRASADO') {
      if (type === 'VUC') {
        iconVehicles = vucLate;
      } else if (type === 'TOCO') {
        iconVehicles = tocoLate;
      } else if (type === 'TRUCK') {
        iconVehicles = truckLate;
      } else if (type === 'CARRETA') {
        iconVehicles = carretaLate;
      } else if (type === 'VAN') {
        iconVehicles = vanLate;
      } else if (type === 'VEICULO_PASSEIO') {
        iconVehicles = tourVehicleLate;
      } else if (type === 'MOTO') {
        iconVehicles = motorcycleLate;
      } else if (type === 'BICICLETA') {
        iconVehicles = bicycleLate;
      } else {
        iconVehicles = vucLate;
      }
    } else if (statusCarga !== 'ATRASADO') {
      if (type === 'VUC') {
        iconVehicles = vuc;
      } else if (type === 'TOCO') {
        iconVehicles = toco;
      } else if (type === 'TRUCK') {
        iconVehicles = truck;
      } else if (type === 'CARRETA') {
        iconVehicles = carreta;
      } else if (type === 'VAN') {
        iconVehicles = van;
      } else if (type === 'VEICULO_PASSEIO') {
        iconVehicles = tourVehicles;
      } else if (type === 'MOTO') {
        iconVehicles = motorcycle;
      } else if (type === 'BICICLETA') {
        iconVehicles = bicycle;
      } else {
        iconVehicles = vuc;
      }
    }
  } else if (alertNotRead === false) {
    if (isCancel !== null) {
      if (type === 'VUC') {
        iconVehicles = vucCancel;
      } else if (type === 'TOCO') {
        iconVehicles = tocoCancel;
      } else if (type === 'TRUCK') {
        iconVehicles = truckCancel;
      } else if (type === 'CARRETA') {
        iconVehicles = carretaCancel;
      } else if (type === 'VAN') {
        iconVehicles = vanCancel;
      } else if (type === 'VEICULO_PASSEIO') {
        iconVehicles = tourVehiclesCancel;
      } else if (type === 'MOTO') {
        iconVehicles = motorcycleCancel;
      } else if (type === 'BICICLETA') {
        iconVehicles = bicycleCancel;
      } else {
        iconVehicles = vucCancel;
      }
    } else if (statusCarga === 'ATRASADO') {
      if (type === 'VUC') {
        iconVehicles = vucLate;
      } else if (type === 'TOCO') {
        iconVehicles = tocoLate;
      } else if (type === 'TRUCK') {
        iconVehicles = truckLate;
      } else if (type === 'CARRETA') {
        iconVehicles = carretaLate;
      } else if (type === 'VAN') {
        iconVehicles = vanLate;
      } else if (type === 'VEICULO_PASSEIO') {
        iconVehicles = tourVehicleLate;
      } else if (type === 'MOTO') {
        iconVehicles = motorcycleLate;
      } else if (type === 'BICICLETA') {
        iconVehicles = bicycleLate;
      } else {
        iconVehicles = vucLate;
      }
    } else if (statusCarga !== 'ATRASADO') {
      if (type === 'VUC') {
        iconVehicles = vuc;
      } else if (type === 'TOCO') {
        iconVehicles = toco;
      } else if (type === 'TRUCK') {
        iconVehicles = truck;
      } else if (type === 'CARRETA') {
        iconVehicles = carreta;
      } else if (type === 'VAN') {
        iconVehicles = van;
      } else if (type === 'VEICULO_PASSEIO') {
        iconVehicles = tourVehicles;
      } else if (type === 'MOTO') {
        iconVehicles = motorcycle;
      } else if (type === 'BICICLETA') {
        iconVehicles = bicycle;
      } else {
        iconVehicles = vuc;
      }
    }
  }

  return iconVehicles;
}
