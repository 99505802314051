import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  referenceId: string()
    .required('*Enter the Reference ID')
    .min(1, 'Minimum 1 characters!'),
  cnpj: string()
    .required('* Enter the CNJP!')
    .min(6, '* Minimum 6 characters!')
    .max(40, '* Maximum 40 characters!'),
  companyName: string()
    .required('* Enter the company name')
    .min(6, '* Minimum 6 characters!')
    .max(40, '* Maximum 40 characters!'),
  trademarkName: string()
    .required('* Enter trademark name')
    .min(6, '* Minimum 6 characters!')
    .max(40, '* Maximum 40 characters!'),
});
