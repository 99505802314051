export const renderHTML = `<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style>
    /* Font Definitions */
    @font-face {
      font-family: Wingdings;
      panose-1: 5 0 0 0 0 0 0 0 0 0;
    }
    @font-face {
      font-family: "Cambria Math";
      panose-1: 2 4 5 3 5 4 6 3 2 4;
    }
    @font-face {
      font-family: Calibri;
      panose-1: 2 15 5 2 2 2 4 3 2 4;
    }
    @font-face {
      font-family: Verdana;
      panose-1: 2 11 6 4 3 5 4 4 2 4;
    }
    @font-face {
      font-family: "Segoe UI";
      panose-1: 2 11 5 2 4 2 4 2 2 3;
    }
    /* Style Definitions */
    p.MsoNormal,
    li.MsoNormal,
    div.MsoNormal {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      line-height: 107%;
      font-size: 11pt;
      font-family: "Calibri", sans-serif;
    }
    a:link,
    span.MsoHyperlink {
      color: blue;
      text-decoration: underline;
    }
    p.MsoListParagraph,
    li.MsoListParagraph,
    div.MsoListParagraph {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: "Calibri", sans-serif;
    }
    p.MsoListParagraphCxSpFirst,
    li.MsoListParagraphCxSpFirst,
    div.MsoListParagraphCxSpFirst {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: "Calibri", sans-serif;
    }
    p.MsoListParagraphCxSpMiddle,
    li.MsoListParagraphCxSpMiddle,
    div.MsoListParagraphCxSpMiddle {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: "Calibri", sans-serif;
    }
    p.MsoListParagraphCxSpLast,
    li.MsoListParagraphCxSpLast,
    div.MsoListParagraphCxSpLast {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: "Calibri", sans-serif;
    }
    span.normaltextrun {
      mso-style-name: normaltextrun;
    }
    span.eop {
      mso-style-name: eop;
    }
    .MsoChpDefault {
      font-family: "Calibri", sans-serif;
    }
    .MsoPapDefault {
      margin-bottom: 8pt;
      line-height: 107%;
    }
    @page WordSection1 {
      size: 595.3pt 841.9pt;
      margin: 70.85pt 85.05pt 70.85pt 85.05pt;
    }
    div.WordSection1 {
      page: WordSection1;
    }
    /* List Definitions */
    ol {
      margin-bottom: 0in;
    }
    ul {
      margin-bottom: 0in;
    }
  </style>
</head>

<body lang="EN-US" link="blue" vlink="#954F72" style="word-wrap: break-word">
  <div class="WordSection1">
    <p
      class="MsoNormal"
      align="right"
      style="margin-bottom: 0in; text-align: right; line-height: 115%"
    >
      <span lang="PT-BR">Data da última alteração: 24/11/2021</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><u
          ><span lang="PT-BR"
            ><span style="text-decoration: none">&nbsp;</span></span
          ></u
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      align="center"
      style="margin-bottom: 0in; text-align: center; line-height: 115%"
    >
      <b
        ><u
          ><span lang="PT-BR"
            >POLÍTICA DE PRIVACIDADE DO FASTDELIVERY</span
          ></u
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><u
          ><span lang="PT-BR"
            ><span style="text-decoration: none">&nbsp;</span></span
          ></u
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">1. INTRODUÇÃO E DISPOSIÇÕES GERAIS</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">1.1. Nós da </span
      ><b
        ><span lang="PT-BR"
          >Trinity Brasil soluções tecnológicas LTDA.</span
        ></b
      ><span lang="PT-BR"
        >, pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº
        08.750.138/0001-10, com sede na Rua do Bom Jesus, nº 180, Loja 0000,
        Bairro do Recife, Recife/PE, CEP: 50.030-170, e-mail:
        <span class="MsoHyperlink">contato@fastdeliverytech.com</span> (o
        “Fastdelivery”ou “Nós”), prezamos pela segurança das informações
        pessoais de todos os usuários cadastrados no Aplicativo, motivo pelo
        qual elaboramos a presente Política de Privacidade (a “Política”) a
        fim de esclarecer como Nós realizamos o Tratamento de Dados Pessoais
        cadastrados no Aplicativo, incluindo Você.</span
      ><span lang="PT-BR"> </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >1.2. A presente Política de Privacidade é parte integrante dos Termos
        e Condições de Uso do FASTDELIVERY (os “Termos de Uso”), acessível por
        meio do link (</span
      ><span lang="PT-BR" style="color: black"
        >www.fastdeliverytech.com/app/</span
      ><span lang="PT-BR"
        >termosdeuso). Palavras indicadas no presente instrumento com as
        letras iniciais em caixa alta, sem que estejam aqui definidas,
        seguirão as definições constantes nos Termos de Uso.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >1.3. Nós recomendamos que Você leia todo o conteúdo desta Política de
        Privacidade antes de se cadastrar ou mesmo utilizar o
        Aplicativo.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">2. DEFINIÇÕES</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >2.1. Com o objetivo de facilitar a compreensão da presente Política,
        apresentamos alguns conceitos que utilizaremos neste instrumento,
        indicados com as letras iniciais em caixa alta.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">“Anonimização”:</span></b
      ><span lang="PT-BR">
        todo e qualquer meio e processo técnico razoável e disponível na
        ocasião do Tratamento de Dados Pessoais que resulte na não
        identificação direta ou indireta do Titular do referido Dado Pessoal.
        Os dados anonimizados não serão considerados Dados Pessoais para os
        fins da legislação aplicável, salvo quando o processo de anonimização
        ao qual foram submetidos for reversível, ou quando, com esforços
        razoáveis, puder ser revertido.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >“<b>Consentimento</b>”: manifestação livre, informada, inequívoca e
        expressa pela qual o Titular concorda com o Tratamento de seus Dados
        Pessoais para uma finalidade determinada.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">“Dado Pessoal” ou “Dados”</span></b
      ><span lang="PT-BR"
        >: informações obtidas online ou offline, capazes de identificar ou
        tornar identificáveis pessoas físicas, incluindo dados que possam ser
        combinados com outras informações para identificar um indivíduo e/ou
        que se relacionem com a identidade, características ou comportamento
        de um indivíduo. Esses dados podem ser sensíveis a exemplo da filiação
        a sindicato.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">“Titular”</span></b
      ><span lang="PT-BR"
        >: pessoa natural a quem se referem os Dados Pessoais que são objeto
        de Tratamento.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoListParagraphCxSpFirst"
      style="margin: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >“Tratamento” (bem como os termos derivados)</span
        ></b
      ><span lang="PT-BR"
        >: toda operação realizada com Dados Pessoais como as que se referem a
        coleta, produção, recepção, classificação, utilização, acesso,
        reprodução, transmissão, distribuição, processamento, arquivamento,
        armazenamento, eliminação, avaliação ou controle da informação,
        modificação, edição, comunicação, transferência, difusão ou
        extração.</span
      >
    </p>

    <p
      class="MsoListParagraphCxSpMiddle"
      style="margin: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">&nbsp;</span></b>
    </p>

    <p
      class="MsoListParagraphCxSpLast"
      style="margin: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">3. TIPOS DE DADOS TRATADOS POR NÓS</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >3.1. Os tipos de Dados Pessoais Tratados e a forma com que Nós
        coletamos esses Dados depende de como Você interage com o Aplicativo.
        Abaixo, listamos algumas das possibilidades de Tratamento dos seus
        Dados Pessoais, que realizamos.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >a) Dados fornecidos diretamente por Você usuário</span
        ></b
      ><span lang="PT-BR"
        >: Grande parte dos Dados que Tratamos são fornecidos por Você ao
        utilizar o Aplicativo, entre os quais figuram:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">&nbsp;</span></b>
    </p>

    <table
      class="MsoTableGrid"
      border="1"
      cellspacing="0"
      cellpadding="0"
      style="border-collapse: collapse; border: none"
    >
      <tr>
        <td
          width="208"
          valign="top"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">Tipos de Dados</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border: solid windowtext 1pt;
            border-left: none;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b
              ><span lang="PT-BR" style="color: black"
                >Dados Coletados</span
              ></b
            >
          </p>
        </td>
      </tr>

      <tr>
        <td
          width="208"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">DADOS DE COMUNICAÇÃO</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados que o usuário envia quando entra em contato conosco
              quando utiliza o Serviço de Chat do Aplicativo, quando faz
              avaliações ou elogios sobre o Aplicativo ou sobre os Nossos
              serviços ou interage por meio do Aplicativo de qualquer outra
              forma.</span
            >
          </p>
        </td>
      </tr>
    </table>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >b) Dados gerados pelo acesso e utilização do Aplicativo</span
        ></b
      ><span lang="PT-BR"
        >: Nós também podemos coletar dados relacionados à forma com que Você
        acessa e utiliza o Aplicativo. Entre eles, destacamos:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <table
      class="MsoTableGrid"
      border="1"
      cellspacing="0"
      cellpadding="0"
      style="border-collapse: collapse; border: none"
    >
      <tr>
        <td
          width="208"
          valign="top"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b
              ><span lang="PT-BR"
                >Tipos de Dados<span style="color: black"> </span></span
            ></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border: solid windowtext 1pt;
            border-left: none;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b
              ><span lang="PT-BR" style="color: black"
                >Dados Coletados</span
              ></b
            >
          </p>
        </td>
      </tr>
      <tr>
        <td
          width="208"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">DADOS DE GEOLOCALIZAÇÃO</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados de geolocalização, exata ou aproximada, por meio de dados
              como GPS, endereço MAC e endereço de IP.</span
            >
          </p>
        </td>
      </tr>
      <tr>
        <td
          width="208"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">DADOS DE ENGAJAMENTO</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados sobre a experiência e engajamento do usuário no
              Aplicativo, incluindo pesquisas realizadas no Aplicativo, datas
              e horários de acesso, recursos do Aplicativo ou funcionalidades
              acessados e outras atividades<s>.</s></span
            >
          </p>
        </td>
      </tr>
      <tr>
        <td
          width="208"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">DADOS DOS APARELHOS</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados sobre os aparelhos que Você utiliza para acessar o
              Aplicativo, incluindo modelos de hardware, sistemas operacionais
              e versões dos sistemas, tipos de software e firmware, idioma de
              preferência, identificadores únicos do aparelho (como Apple ID e
              IMEI), identificadores de publicidade e dados da rede
              móvel.</span
            >
          </p>
        </td>
      </tr>
      <tr>
        <td
          width="208"
          style="
            width: 155.7pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR"> DADOS DE REGISTRO</span></b>
          </p>
        </td>
        <td
          width="359"
          valign="top"
          style="
            width: 269pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados de registro de conexão do Titular (endereço IP, data e
              horários de acesso).</span
            >
          </p>
        </td>
      </tr>
    </table>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >3.3. Em razão de obrigações legais, caso aplicável, outros Dados e
        informações poderão ser coletados e Tratados por Nós.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >4.  FINALIDADES DO TRATAMENTO DE DADOS E BASES LEGAIS</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >4.1. Nós poderemos utilizar parte dos seus Dados para as seguintes
        finalidades:</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <table
      class="MsoTableGrid"
      border="1"
      cellspacing="0"
      cellpadding="0"
      style="border-collapse: collapse; border: none"
    >
      <tr style="height: 15pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 15pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR">#</span></b>
          </p>
        </td>
        <td
          width="109"
          style="
            width: 124.75pt;
            border: solid windowtext 1pt;
            border-left: none;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 15pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b><span lang="PT-BR" style="color: black">Finalidade</span></b>
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border: solid windowtext 1pt;
            border-left: none;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 15pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b
              ><span lang="PT-BR" style="color: black"
                >Tipos de Dados</span
              ></b
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border: solid windowtext 1pt;
            border-left: none;
            background: #bfbfbf;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 15pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <b
              ><span lang="PT-BR" style="color: black"
                >Base Legal Aplicável</span
              ></b
            >
          </p>
        </td>
      </tr>
      <tr style="height: 60pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">1</span>
          </p>
        </td>
        <td
          width="257"
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Cumprimento das obrigações decorrentes dos Serviços oferecidos
              por Nós aos usuários</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados Cadastrais de usuários <br />
              Dados de Geolocalização</span
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Execução do contrato e prestação dos serviços
              relacionados</span
            >
          </p>
        </td>
      </tr>
      <tr style="height: 60pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">2</span>
          </p>
        </td>
        <td
          width="257"
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Contato com o usuário para envio de informações relacionadas
              aos Serviços FASTDELIVERY,</br> bem como para comunicar alterações
              nos nossos serviços</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados Cadastrais de usuários<br />
              Dados de Comunicação</span
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Execução do contrato e prestação dos serviços
              relacionados</span
            >
          </p>
        </td>
      </tr>
      <tr style="height: 60pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">4</span>
          </p>
        </td>
        <td
          width="257"
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Permitir a comunicação entre os usuários por meio dos Serviços
              de Chat</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados Cadastrais de usuários<br />
              Dados de Comunicação</span
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Execução do contrato e prestação dos serviços
              relacionados</span
            >
          </p>
        </td>
      </tr>
      <tr style="height: 60pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">6</span>
          </p>
        </td>
        <td
          width="257"
          nowrap
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Coleta, armazenamento de Dados e/ou Compartilhamento de Dados</br>
              com o poder público para cumprimento de obrigação legal </br> ou
              regulatória ou em resposta a solicitações de agências</br>
              reguladoras, órgãos governamentais, decisões judiciais e
              inquéritos oficiais</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Todos os tipos de Dados coletados</span>
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Obrigação Legal</span>
          </p>
        </td>
      </tr>
      <tr style="height: 60pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">7</span>
          </p>
        </td>
        <td
          width="257"
          nowrap
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >A manutenção dos registros de acesso a aplicações de internet,<br />
              sob sigilo, em ambiente controlado e de segurança, pelo prazo de</br>
              6 (seis) meses, de acordo com o exigido pela Lei nº 12.965/2014
              (Marco Civil da Internet)</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Dados de Registro</span>
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 60pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Obrigação Legal</span>
          </p>
        </td>
      </tr>
      <tr style="height: 75pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">8</span>
          </p>
        </td>
        <td
          width="257"
          nowrap
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Solução de controvérsias: armazenamento e compartilhamento</br>
              alguns Dados para solucionar impasses e controvérsias</br>
              eventualmente surgidas ao longo da exploração dos Nossos
              Serviços</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Todos os tipos de Dados coletados</span>
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Exercício regular de direitos em processos judiciais,
              administrativos ou arbitrais</span
            >
          </p>
        </td>
      </tr>
      <tr style="height: 45.65pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 45.65pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">10</span>
          </p>
        </td>
        <td
          width="257"
          style="
            width: 192.75pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 45.65pt;
          "
        >
          <p
            class="MsoNormal"
            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <span lang="PT-BR"
              >Desenvolvimento de novos produtos/serviços: por meio da análise </br>
              dos Dados gerados pelo acesso e utilização do Aplicativo,</br> Nós
              poderemos (i) antecipar e viabilizar a correção de erros e </br>
              falhas do Aplicativo; (ii) aperfeiçoar progressivamente os
              nossos serviços,</br> de maneira que o Usuário possua a melhor
              experiência possível durante a </br> utilização do
              Aplicativo;</br>&nbsp;(iii) entender melhor os interesses e as
              características dos Titulares; bem como (iv) </br> aprender sobre o
              seu comportamento e obter informações demográficas sobre </br> a nossa
              base de Titulares, de maneira geral</span
            >
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 45.65pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
              >Dados de Geolocalização<br />
              Dados de Engajamento<br />
              Dados do Aparelho</span
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 45.65pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"> Interesse Legítimo</span>
          </p>
        </td>
      </tr>
      <tr style="height: 75pt">
        <td
          width="37"
          style="
            width: 28.1pt;
            border: solid windowtext 1pt;
            border-top: none;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">11</span>
          </p>
        </td>
        <td
          width="106"
          nowrap
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"

            style="margin-bottom: 0in; text-align: justify; line-height: 115%"
          >
            <div lang="PT-BR"
              ><span>Envio de notificações</span> <i>push</i> <span style="width: 40px;">

              personalizadas, de acordo com
              a legislação aplicável,</br> podendo conter conteúdo informativo,
              publicitário, bem como outros materiais </br> promocionais voltados ao </br>
              marketing que possam interessar o Usuário. Poderemos também </br>
              utilizar os Dados para marketing direcionado em plataformas e
              redes sociais</span></div>
          </p>
        </td>
        <td
          width="165"
          style="
            width: 124pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR"
             
              >Dados Cadastrais de usuários<br />
              Dados de Geolocalização<br />
              Dados de Engajamento<br />
              Dados do Aparelho</span
            >
          </p>
        </td>
        <td
          width="106"
          style="
            width: 79.85pt;
            border-top: none;
            border-left: none;
            border-bottom: solid windowtext 1pt;
            border-right: solid windowtext 1pt;
            padding: 0in 5.4pt 0in 5.4pt;
            height: 75pt;
          "
        >
          <p
            class="MsoNormal"
            align="center"
            style="margin-bottom: 0in; text-align: center; line-height: 115%"
          >
            <span lang="PT-BR">Consentimento ou Interesse Legítimo</span>
          </p>
        </td>
      </tr>
    </table>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >4.2. Nós poderemos Tratar Dados para outras finalidades que não
        estejam ainda descritas na presente Política, desde que para tanto
        haja uma base legal adequada para tal finalidade e que informações a
        esse respeito estejam disponíveis para acesso pelos Titulares quando
        solicitado.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">5. RESPONSABILIDADES DOS USUÁRIOS</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >5.1. Os Usuários são os únicos responsáveis pelos Dados fornecidos no
        Aplicativo. Por esse motivo, Você deve cuidadosamente considerar os
        riscos de fazer certas declarações ou publicar informações pessoais no
        Aplicativo. Nós não assumiremos qualquer responsabilidade por atos ou
        omissões de terceiros que ocorram em decorrência da informação que
        Você disponibilizou no Aplicativo ou que o Você tenha compartilhado
        com qualquer pessoa.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >6.  DIVULGAÇÃO DOS DADOS DOS USUÁRIOS COM TERCEIROS</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >6.1. Nós poderemos compartilhar os seus Dados com terceiros para
        permitir a operação do Aplicativo e dos Serviços FASTDELIVERY
        elencados nos Termos de Uso, bem como aos Nossos colaboradores que
        estejam devidamente autorizados para tanto e somente para os fins
        expressamente previstos nesta Política. Nesses casos, buscaremos
        prestadores de serviços que demonstrem o mesmo comprometimento que
        possuímos com a proteção de seus Dados.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >6.3. Os Dados coletados por Nós serão armazenados em um banco de
        dados em servidores de empresas especializadas em hospedagem,
        localizados no Brasil ou em outros países que ofereçam serviço de
        armazenamento de nuvem confiáveis e usualmente utilizados por empresas
        de tecnologia, tais como Estados Unidos da América (EUA) e em países
        da América Latina e da Europa. </span
      ><span lang="PT-BR"> </span
      ><span lang="PT-BR"
        >Por esse motivo, certos Dados serão compartilhados com as empresas
        especializadas em hospedagem contratadas por Nós. Essa forma de
        compartilhamento é imprescindível às atividades desenvolvidas por Nós
        por meio do Aplicativo e o acesso a esses Dados será concedido apenas
        a essas empresas que necessitem deles para prestação adequada dos
        serviços ofertados no Aplicativo.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >6.4. Nós poderemos também compartilhar seus Dados ou transferir
        nossos bancos de Dados na hipótese de reestruturação, fusão ou venda
        de ativos do FASTDELIVERY, desde que respeitados os termos desta
        Política e previsões legais aplicáveis.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR">7. MECANISMOS DE SEGURANÇA DO APLICATIVO</span></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >7.1. Nós utilizamos modernos meios de segurança da informação para
        controlar de maneira restrita o acesso de terceiros não autorizados
        aos Dados Tratados. Nós assumimos o compromisso de fazer o que estiver
        ao nosso alcance a fim de manter a privacidade dos seus Dados,
        utilizando tecnologias que julgamos adequadas e suficientes para tal
        proteção, tanto no transporte como no armazenamento de seus Dados.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >7.2. Sabendo que nenhum sistema de segurança é infalível, Nós não
        podemos garantir a completa segurança dos Dados armazenados em Nossos
        bancos de dados ou dos terceiros, a despeito de todas as medidas de
        segurança razoáveis adotadas, com a tecnologia disponível no momento
        da adoção para proteção dos Dados Pessoais. Reiteramos, contudo, nosso
        compromisso de informar a Vocês a respeito de quaisquer incidentes de
        segurança que possam acarretar risco ou dano relevante a Você, bem
        como adotar as medidas possíveis para mitigar seus eventuais
        riscos.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >7.3. O FASTDELIVERY não poderá ser responsabilizado por dano e/ou
        prejuízo decorrente de culpa exclusiva do Titular de Dados ou de ação
        ou omissão do FASTDELIVERY em conformidade com a legislação de
        proteção de dados.</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">8. DIREITOS DO TITULAR </span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >8.1. A legislação brasileira garante aos Titulares diversos direitos
        em relação a seus Dados Tratados, os quais poderão ser exercidos a
        qualquer momento e mediante requisição ao FASTDELIVERY, tais como a
        confirmação da existência de Tratamento, o acesso aos Dados, a
        correção de Dados incompletos, inexatos ou desatualizados, a
        Anonimização, bloqueio ou eliminação de Dados desnecessários,
        excessivos ou Tratados em desconformidade com o disposto nesta Lei, a
        eliminação dos Dados e a revogação do Consentimento.
      </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >8.2. Caso o Usuário ou outro Titular de Dados tenha interesse em
        exercer seus direitos, deverá entrar em contato conosco por meio do
        seguinte endereço eletrônico: </span
      ><span lang="PT-BR"
        ><a href="about:blank">contato@fastdeliverytech.com</a></span
      ><span lang="PT-BR"
        >. Caso alguma das requisições enviadas pelos Titulares não sejam
        respondidas de forma imediata, Nós nos comprometemos a enviar a
        resposta em um prazo razoável e em conformidade com a legislação
        aplicável</span
      ><span class="normaltextrun"
        ><span
          lang="PT-BR"
          style="
            font-size: 9pt;
            line-height: 115%;
            font-family: 'Verdana', sans-serif;
            color: black;
          "
          >.</span
        ></span
      ><span class="eop"
        ><span
          lang="PT-BR"
          style="
            font-size: 9pt;
            line-height: 115%;
            font-family: 'Verdana', sans-serif;
            color: black;
          "
          >&nbsp;Caso alguma requisição dos Titulares não possa ser atendida,
          conforme solicitado, o FASTDELIVERY se compromete a enviar a
          justificativa ao respectivo Titular.</span
        ></span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">9. ARMAZENAMENTO E RETENÇÃO DE DADOS</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">9.1.</span><b><span lang="PT-BR"> </span></b
      ><span lang="PT-BR"
        >Nós apenas manteremos os seus Dados armazenados durante o período
        necessário para o cumprimento das finalidades para as quais esses
        Dados foram coletados, salvo se houver outra razão para a sua
        manutenção, tais como a existência de algum motivo de segurança
        justificável (problemas no processamento de pagamento, por
        exemplo).</span
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >9.2. Após o encerramento de sua conta, Nós poderemos manter alguns de
        seus Dados pelo período exigido pela legislação aplicável (tais como
        Dados de Registro) ou em cumprimento à determinação judicial ou em
        razão de alguma base legal. Os demais Dados que não precisarem ser
        armazenados serão deletados ou anonimizados em até 60 (sessenta)
        dias<span style="color: black; background: white"> </span>contados da
        data da solicitação de encerramento da conta. Importante lembrar que a
        simples desinstalação do Aplicativo dos aparelhos do Usuário não
        configura o encerramento da conta, sendo necessário que Você entre em
        contato conosco por meio do seguinte endereço eletrônico: </span
      ><span lang="PT-BR"
        ><a href="about:blank">cadastro@fastdeliverytech.com</a></span
      ><span lang="PT-BR">.</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR">&nbsp;</span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">10. CONTATO</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >10.1. Caso Você ainda tenha qualquer dúvida sobre como seus Dados são
        Tratados por Nós e precise entrar em contato para abordar assuntos
        referentes aos seus Dados Pessoais ou queira nos enviar comentários ou
        sugestões relacionadas à presente Política, solicitamos que entre em
        contato conosco por meio do seguinte endereço eletrônico: </span
      ><span lang="PT-BR"
        ><a href="about:blank">cadastro@fastdeliverytech.com</a></span
      ><span class="MsoHyperlink"><span lang="PT-BR">.</span></span
      ><span lang="PT-BR"> </span>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b><span lang="PT-BR">&nbsp;</span></b>
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <b
        ><span lang="PT-BR"
          >11. ATUALIZAÇÃO DESTA POLÍTICA DE PRIVACIDADE</span
        ></b
      >
    </p>

    <p
      class="MsoNormal"
      style="margin-bottom: 0in; text-align: justify; line-height: 115%"
    >
      <span lang="PT-BR"
        >11.1. Nossa Política poderá ser alterada ocasionalmente. A cada
        atualização, constará no topo da presente Política a data da última
        alteração. Em caso de futuras alterações, o Usuário será informado por
        meio do Aplicativo,  e-mail e/ou SMS. Recomendamos que o Usuário,
        sempre que possível, reveja o conteúdo da Política a fim de acompanhar
        as suas atualizações.</span
      >
    </p>
  </div>
</body>
</html>

`;
