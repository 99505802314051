import styled from 'styled-components';

interface StyledProps {
  error?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

export const Form = styled.div`
  width: 100%;
`;

export const WrapperList = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 2rem;
`;

export const WrapperFilters = styled.div`
  display: flex;
  align-items: center;
`;


export const ContainerList = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1.600rem;
  margin-top: 1.450rem;
`;

export const ButtonsAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ContainerUploaded = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const Status = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: ${(props: StyledProps) => props.error ? '#EA004C' : 'green'};
`;
export const FileName = styled.div`
  font-size: 14px;
  color: #003049;
  font-style: normal;
`;
export const ResetFile = styled.div`
  font-size: 14px;
  color: #003049;
  margin-right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #EA004C;
    border-radius: 100%;
    color: #ffffff
  }

`;