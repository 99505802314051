import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseGeneralIndicatorsIcon from '../../assets/images/closeGeneralIndicatorsIcon.svg';
import GeneralIndicatorsIcon from '../../assets/images/generalIndicatorsIcon.svg';
import './style.css';

export default function GeneralIndicators({ generalIndicators }) {
  const { t } = useTranslation();
  const [indicator] = useState([
    { class: 'pending', value: 1 },
    { class: 'inProgress', value: 0 },
    { class: 'accomplished', value: 4 },
    { class: 'reDeliveries', value: 0 },
    { class: 'canceled', value: 1 },
  ]);
  const [showIndicators, setShowIndicators] = useState(false);

  useEffect(() => {
    if (generalIndicators) {
      indicator.forEach(item => {
        if (item.class === 'pending') {
          item.value = generalIndicators.totalPendentes;
        } else if (item.class === 'inProgress') {
          // não é retornado no servico
          item.value = 0;
        } else if (item.class === 'accomplished') {
          item.value = generalIndicators.totalEfetivadas;
        } else if (item.class === 'reDeliveries') {
          item.value = generalIndicators.totalReentregas;
        } else if (item.class === 'canceled') {
          item.value = generalIndicators.totalCanceladas;
        }
      });
    }
  }, [generalIndicators, indicator]);

  return (
    <>
      {showIndicators ? (
        <div
          id="indicators-container"
          style={{
            position: 'absolute',
            top: '10% !important',
            zIndex: 3,
            width: 34,
            height: 34,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <img
            src={CloseGeneralIndicatorsIcon}
            alt=""
            onClick={() => setShowIndicators(false)}
          />
          <div id="status">
            <div className={indicator[0].class}>
              <span>
                {t('generalIndicators.pending.part1')}
                <br />
                {t('generalIndicators.pending.part2')}
              </span>
              <span id="value">{indicator[0].value}</span>
              <div id="separator" />
            </div>

            <div className={indicator[2].class}>
              <span>
                {t('generalIndicators.accomplished.part1')} <br />
                {t('generalIndicators.accomplished.part2')}
              </span>
              <span id="value">{indicator[2].value}</span>
              <div id="separator" />
            </div>
            <div className={indicator[3].class}>
              <p>{t('generalIndicators.reDeliveries')}</p>
              <span id="value">{indicator[3].value}</span>
              <div id="separator" />
            </div>
            <div className={indicator[4].class}>
              <span>
                {t('generalIndicators.canceled.part1')}
                <br />
                {t('generalIndicators.canceled.part2')}
              </span>
              <span id="value">{indicator[4].value}</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          id="indicators-container"
          style={{
            position: 'absolute',
            top: '10%',
            zIndex: 3,
            width: 34,
            height: 34,
          }}
        >
          <img
            src={GeneralIndicatorsIcon}
            alt=""
            onClick={() => setShowIndicators(true)}
          />
        </div>
      )}
    </>
  );
}
