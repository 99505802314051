// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressBarContainer {
  background: #EDF2F4;
  border: 0.063rem solid #CDD9DD;
  box-sizing: border-box;
  border-radius: 2.5rem;
  padding: 0.188rem;
}
.progressBarContainer .progressBar {
  background: #6369D1;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: bold;
  color: #FFF;
  padding-right: 0.625rem;
}
.progressBarContainer .noProgress {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: bold;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/components/Table/ProgressBar/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,8BAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;AACF;AACE;EACI,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,uBAAA;AACN;AAEE;EACI,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AAAN","sourcesContent":[".progressBarContainer {\n  background: #EDF2F4;\n  border: 0.063rem solid #CDD9DD;\n  box-sizing: border-box;\n  border-radius: 2.5rem;\n  padding: 0.188rem;\n\n  .progressBar {\n      background: #6369D1;\n      border-radius: 1.25rem;\n      font-size: 0.75rem;\n      font-style: normal;\n      font-weight: bold;\n      color: #FFF;\n      padding-right: 0.625rem;\n  }\n\n  .noProgress {\n      font-size: 0.75rem;\n      font-style: normal;\n      font-weight: bold;\n      color: #000;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
