import { SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact } from 'ag-grid-react';
import ContentCopyIcon from 'assets/images/contentCopy.svg';
import PublishIcon from 'assets/images/publishIcon.svg';
import ViewTemplateIcon from 'assets/images/viewTemplateIcon.svg';
import CreateIcon from 'components/Grid/icons/CreateIcon';
import RemoveIcon from 'components/Grid/icons/RemoveIcon';
import UpdateIcon from 'components/Grid/icons/UpdateIcon';
import { Filter, SearchOperation } from 'components/Grid/types';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import IShippingTable from './interfaces/IShippingTable';
import { Container } from './styles';

interface ListShippingTableProps {
  data: IShippingTable[];
  totalPages: number;
  currentPage: number;
  onCreated: () => void;
  onRemove: (rowId: number) => void;
  onUpdate: (rowId: number) => void;
  onCopy: (rowId: number) => void;
  onPublish: (rowId: number) => void;
  onView: (rowId: number) => void;
  onChangePage: (page: number) => void;
  selectedRow: IShippingTable[];
  setSelectedRow: any;
}

const ListShippingTable: React.FC<ListShippingTableProps> = ({
  data,
  totalPages,
  currentPage,
  onCreated,
  onRemove,
  onUpdate,
  onCopy,
  onPublish,
  onView,
  onChangePage,
  selectedRow,
  setSelectedRow,
}: ListShippingTableProps) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [columns] = useState([
    {
      field: 'descricao',
      headerName: 'Nome da tabela',
      checkboxSelection: true,
      filter: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === 'ACTIVE') {
            return 'ATIVO';
          }
          return 'INATIVO';
        }
        return '';
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo da tabela',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        console.log(params);
        if (params.value) {
          return params.value.replace('VALOR_FIXO', 'Valor Fixo');
        }
        return '';
      },
    },
    {
      field: 'dataInicial',
      headerName: 'Vigência',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        console.log(params);
        if (params.value) {
          return `${params.data.dataInicial} - ${params.data.dataFinal}`;
        }
        return '';
      },
    },
    {
      field: 'publicada',
      headerName: 'Publicada',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === true) {
            return 'SIM';
          } else {
            return 'NÃO';
          }
          // return params.value === true ? "SIM" : params.value === false ? "NAO" : ""
        }
        return 'NÃO';
      },
    },
  ]);
  const [colDefs, setColDefs] = useState<any>([]);
  const [rowData, setRowData] = useState();
  '';
  const [gridApi, setGridApi] = useState<any>(null);
  const [filters] = useState<Filter[]>([
    {
      field: 'status',
      value: ['INACTIVE', 'ACTIVE'],
      operation: SearchOperation.IN,
    },
  ]);

  const { t, i18n } = useTranslation();

  const onGridReady = async (params: any) => {
    let cols: any[] = [];
    columns.forEach(column => {
      // Realiza a tradução da key.
      if (column.headerName) {
        column.headerName = i18n.t(column.headerName);
      }

      cols.push(column);
    });

    setColDefs(cols);
    setGridApi(params.api);

    const reqDTO = {
      filters: filters,
      orders: [],
      size: 1000,
      page: 0,
    };

    api.post('/delivery/tabelas/frete/pageable', reqDTO).then(response => {
      console.log(response.data.content);
      setRowData(response.data.content);
      // props.setRowData?.(response.data.content);
    });

    if (gridRef.current) {
      const api = gridRef.current.api;
      // api.setColumnFilterModel()
      await api.setColumnFilterModel('status', {
        filterType: 'text',
        type: 'equals',
        filter: 'ACTIVE',
      });
      api.onFilterChanged();
    }
  };

  function onUpdated() {
    if (selectedRow.length > 0) {
      if (selectedRow[0].status === 'ACTIVE' && selectedRow[0].publicada == false) {
        onUpdate(selectedRow[0].id);
      } else {
        FrontendNotification(
          'Operação não permitida!',
          NotificationType.WARNING
        );
      }
    } else {
      FrontendNotification(
        'Selecione pelo menos um registro!',
        NotificationType.WARNING
      );
    }
  }

  function onDelete() {
    if (selectedRow.length > 0) {
      if (selectedRow[0].status === 'ACTIVE') {
        onRemove(selectedRow[0].id);
      } else {
        FrontendNotification(
          'Operação não permitida!',
          NotificationType.WARNING
        );
      }
    } else {
      FrontendNotification(
        'Selecione pelo menos um registro!',
        NotificationType.WARNING
      );
    }
  }

  function onCreate() {
    onCreated();
  }

  function onPublished() {
    if (selectedRow.length > 0) {
      console.log('Selected', selectedRow);
      if (!selectedRow[0].publicada && selectedRow[0].status === 'ACTIVE') {
        onPublish(selectedRow[0].id);
      } else {
        FrontendNotification(
          'Não é permitido publicar tabela de frete inativa!',
          NotificationType.WARNING
        );
      }
      
    } else {
      FrontendNotification(
        'Selecione pelo menos um registro!',
        NotificationType.WARNING
      );
    }
  }

  function onCopyed() {
    if (selectedRow.length > 0) {
      onCopy(selectedRow[0].id);
    } else {
      FrontendNotification(
        'Selecione pelo menos um registro!',
        NotificationType.WARNING
      );
    }
  }

  function onViewed() {
    if (selectedRow.length > 0) {
        onView(selectedRow[0].id);
    } else {
      FrontendNotification(
        'Selecione pelo menos um registro!',
        NotificationType.WARNING
      );
    }
  }

  const onSelectionChanged = (params: SelectionChangedEvent) => {
    const selectedRows = params.api.getSelectedRows();
    setSelectedRow(selectedRows);
  };

  return (
    <Container>
      <div className={'tw-mb-2 tw-flex tw-flex-col '}>
        <div className='tw-flex tw-items-center'>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onCreate}
            id="btnCreate"
          >
            <CreateIcon className='tw-mr-1' /> {t('action.create')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onUpdated}
            id="btnUpdate"
          >
            <UpdateIcon className='tw-mr-1' /> {t('action.update')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onDelete}
            id="btnDelete"
          >
            <RemoveIcon className='tw-mr-1' /> {t('action.remove')}
          </button>
        </div>
        <div className='tw-flex tw-items-center tw-mt-2'>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onCopyed}
            id={'btnDelete'}
          >
            <img src={ContentCopyIcon} className="tw-mr-1" /> Copiar
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onPublished}
            id={'btnDelete'}
          >
            <img src={PublishIcon} className="tw-mr-1" /> Publicar
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={onViewed}
            id={'btnDelete'}
          >
            <img src={ViewTemplateIcon} className="tw-mr-1" /> Visualizar
          </button>
        </div>
      </div>
      <div className="ag-theme-quartz tw-h-80 tw-mb-6">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columns}
          rowSelection='single'
          rowModelType="clientSide"
          pagination={true}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
        />
        {/* <Grid<IShippingTable>
          columns={columns}
          pagination={true}
          perPage={100}
          rowSelection={RowSelection.SINGLE}
          path="/delivery/tabelas/frete"
          selectedRows={selectedRow}
          setSelectedRows={setSelectedRow}
          onUpdate={onUpdated}
          onDelete={onDelete}
          onCreate={onCreate}
          showCrudButtons={true}
          customButtons={[
            {
              label: 'Copiar',
              action: () => onCopyed(),
              icon: ContentCopyIcon,
            },
            {
              label: 'Publicar',
              action: () => onPublished(),
              icon: PublishIcon,
            },
            {
              label: 'Visualizar',
              action: () => onViewed(),
              icon: viewTemplateIcon,
            },
          ]}
          filters={filters}
        /> */}
      </div>
    </Container>
  );
};

export default ListShippingTable;
