import InputCustom from 'components/Input';
import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import { useFormik } from 'formik';
// import { Line } from 'pages/ControlTower/components/Table/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import LoadingBling from './LoadingBling';
import formValidator from './formValidator';
import { Line } from './styles';

interface DataFilters {
  field: string;
  value: any;
  operation: string;
}

type FormValues = {
  apiKey: string;
  time: number | null;
  situation: string;
};

interface Props {
  onClear: any;
}

const IntegrationsBling: React.FC<Props> = ({ onClear }: Props) => {
  const [dataBling, setDataBling] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<DataFilters[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [dataBlingResult, setDataBlingResult] = useState();

  const getIntegrationBling = useCallback(async () => {
    try {
      const data = {
        filters: filters.length > 0 ? filters : [],
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPage || 0,
        size: 5,
      };
      const response = await api.post('/audit/integration/api/pageable', data);

      setDataBling(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (err) {}
  }, [filters, currentPage]);

  function paginate(pageNumber: number) {
    setCurrentPage(pageNumber - 1);
  }

  useEffect(() => {
    getIntegrationBling();
  }, [getIntegrationBling]);

  const initialValues: FormValues = {
    apiKey: '',
    time: null,
    situation: '',
  };

  const handleSubmit = useCallback(async (values: FormValues) => {
    setLoading(true);

    sessionStorage.setItem('loadingBling', 'true');

    setActiveLoading(true);
    const dataBling = {
      tempo: values.time === null ? null : values.time,
      token: values.apiKey.length > 0 ? values.apiKey.trim() : null,
      situacao: values.situation.length > 0 ? values.situation.trim() : null,
    };

    let urlBlingSicronize = '/integracao/tmsErp/bling/pedidos';

    if (dataBling.token !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(`?token=${dataBling.token}`);
    } else {
      urlBlingSicronize = urlBlingSicronize;
    }
    if (dataBling.tempo !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(`&tempo=${dataBling.tempo}`);
    } else {
      urlBlingSicronize = urlBlingSicronize;
    }
    if (dataBling.situacao !== null) {
      urlBlingSicronize = urlBlingSicronize.concat(
        `&situacao=${dataBling.situacao}`
      );
    } else {
      urlBlingSicronize = urlBlingSicronize;
    }

    const response = await api.get(`${urlBlingSicronize}`);
    setStatusCode(response.status === 200 ? 200 : 400);
    setDataBlingResult(response.data);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const getParametersBling = useCallback(async () => {
    const response = await api.get('/cadastro/parametrosBling');

    if (response.data.length > 0) {
      if (response.data[0].token !== null) {
        formik.setFieldValue('apiKey', response.data[0].token);
      }
      if (response.data[0].filtroSituacao && response.data[0].filtroSituacao !== null) {
        formik.setFieldValue('situation', response.data[0].filtroSituacao);
      }
      if (response.data[0].filtroTempo && response.data[0].filtroTempo !== null) {
        formik.setFieldValue('time', response.data[0].filtroTempo);
      }
    }
  }, []);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values),
  });

  useEffect(() => {
    getParametersBling();
  }, []);

  return (
    <>
      <CustomModal
        isIntegration
        isOpen
        isClose={() => onClear()}
        label={t('integrationsBling.menuTitle')}
        styles={{ maxWidth: '90%' }}
      >
        <>
          {activeLoading && (
            <LoadingBling
              loading={loading}
              statusCode={statusCode}
              data={dataBlingResult}
              onClear={() => {
                setActiveLoading(!activeLoading);
                getIntegrationBling();
              }}
            />
          )}

          <Row>
            <Col md={5}>
              <InputCustom
                title="API KEY"
                onChange={formik.handleChange('apiKey')}
                placeholder=""
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
                value={formik.values.apiKey}
                touched={formik.touched.apiKey}
                error={formik.errors.apiKey}
              />
            </Col>
            <Col md={2}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                >
                  <InputCustom
                    type="text"
                    isTooltip
                    messageTooltip={
                      <div>
                        {navigator.language === 'pt' ||
                        navigator.language === 'pt-BR' ? (
                          <>
                            Para filtrar por situação, digite o Id da situação
                            do Bling. <br />
                            Para filtrar por mais de uma situação, digite os Ids
                            separados por vírgula.
                          </>
                        ) : (
                          <>
                            Use the Bling situation Id if you want to filter for
                            a specific situation. <br />
                            For more than one situation Id use coma( , ).
                          </>
                        )}
                      </div>
                    }
                    title={t('integrationsBling.fields.situation')}
                    onChange={formik.handleChange('situation')}
                    value={formik.values.situation}
                    placeholder=""
                    styleTitle={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  />
                </div>

                {/*  */}
              </div>
            </Col>
            <Col md={2}>
              <InputCustom
                type="number"
                title={t('integrationsBling.fields.time')}
                onChange={formik.handleChange('time')}
                placeholder=""
                value={formik.values.time}
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
              />
            </Col>
            <Col md={2}>
              <button
                type="button"
                className="button"
                style={{ marginTop: 40 }}
                onClick={() => formik.handleSubmit()}
              >
                <Trans i18nKey="integrationsBling.buttonSicronize" />
              </button>
            </Col>
          </Row>
        </>
        <Line style={{ padding: 0, margin: 0, marginTop: 30 }} />

        <Logs
          data={dataBling}
          totalPages={totalPages}
          page={currentPage}
          onPaginate={(page: number) => paginate(page)}
          onFilters={(date: string, status: string) => {
            setCurrentPage(0);
            if (date.length > 0 && status.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else if (date.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
              ]);
            } else if (status.length > 0) {
              setFilters([
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else {
              setFilters([]);
            }
          }}
        />
      </CustomModal>
    </>
  );
};

export default IntegrationsBling;
