import FilterListIcon from '@mui/icons-material/FilterList';
import { Filter, SearchOperation } from 'components/Grid/types';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/LoadingRoutering';
import { useFormik } from 'formik';
import { Region } from 'pages/Delivery/components/Region';
import { Route } from 'pages/Delivery/components/Route';
import { Sector } from 'pages/Delivery/components/Sector';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ClearFilters,
  ClearFiltersText,
  SectionFilter,
  SectionText,
} from '../styles';
import formValidator from './formValidator';

type FiltersFormValues = {
  filialExpedicao: string;
  dataAtendimentoInicial: string;
  dataAtendimentoFinal: string;
  dataFaturamentoInicial: string;
  dataFaturamentoFinal: string;
  region: string;
  route: string;
  sector: string;
  tipoAtendimento: string;
  reentrega: number | string;
};

export type BranchSchema = {
  id: number;
  nome: string;
  cnpj: string;
};

interface FiltersProps {
  onSendFilters: (data: Filter[]) => void;
  branches: BranchSchema[];
  route: Route[];
  region: Region[];
  sector: Sector[];
}

const FormFilter: React.FC<FiltersProps> = ({
  onSendFilters,
  branches,
  route,
  region,
  sector
}: FiltersProps) => {
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState<boolean>(false);

  function onLoadFilters(dataFilters: FiltersFormValues) {
    const loadFiltersData = dataFilters;
    let filters: Filter[] = [];

    if (
      loadFiltersData.dataAtendimentoInicial.length > 0 &&
      loadFiltersData.dataAtendimentoFinal.length > 0
    ) {
      filters.push({
        field: 'dataCriacao',
        value: `${loadFiltersData.dataAtendimentoInicial}T00:00:00`,
        operation: SearchOperation.GREATHER_THAN_EQUAL,
      });
      filters.push({
        field: 'dataCriacao',
        value: `${loadFiltersData.dataAtendimentoFinal}T23:59:59`,
        operation: SearchOperation.LESS_THAN_EQUAL,
      });
    }
    if (loadFiltersData.filialExpedicao.length > 0) {
      filters.push({
        field: 'filial',
        value: `${loadFiltersData.filialExpedicao}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.region.length > 0) {
      filters.push({
        field: 'regionReferenceId',
        value: `${loadFiltersData.region}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.route.length > 0) {
      filters.push({
        field: 'routeReferenceId',
        value: `${loadFiltersData.route}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.sector.length > 0) {
      filters.push({
        field: 'sectionReferenceId',
        value: `${loadFiltersData.sector}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.tipoAtendimento.length > 0) {
      filters.push({
        field: 'tipo',
        value: `${loadFiltersData.tipoAtendimento}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if(loadFiltersData.reentrega !== "null"){
      filters.push({
        field: 'reentrega',
        value: loadFiltersData.reentrega == 0 ? true : false,
        operation: SearchOperation.EQUAL,
      });
    }


    return filters;
  }

  const onHandleFilters = useCallback((values: FiltersFormValues) => {
    sessionStorage.setItem('subsidiary', values.filialExpedicao);
    const dataFilters: Filter[] = onLoadFilters(values);

    onSendFilters(dataFilters);
  }, []);

  const initialValues: FiltersFormValues = {
    filialExpedicao: '',
    dataAtendimentoInicial: '',
    dataAtendimentoFinal: '',
    dataFaturamentoInicial: '',
    dataFaturamentoFinal: '',
    region: '',
    route: '',
    sector: '',
    tipoAtendimento: '',
    reentrega: 1,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FiltersFormValues) => onHandleFilters(values),
  });
  
  return (
    <>
      <SectionFilter className="tw-mt-4">
        <FilterListIcon style={{ color: '#535669' }} />
        <SectionText>{t('filters')}</SectionText>
        <ClearFilters onClick={() => formik.resetForm({})}>
          <ClearFiltersText>{t('action.clearFilters')}</ClearFiltersText>
        </ClearFilters>
      </SectionFilter>

      <Loading loading={loading} />

      <div className="tw-mt-6 tw-mb-10">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('filialExpedicao')}
              error={formik.errors.filialExpedicao}
              touched={formik.touched.filialExpedicao}
              value={formik.values.filialExpedicao}
              title="Filial de expedição"
            >
              <>
                <option value="">{t('action.select')}</option>
                {branches.map((data: BranchSchema, index: number) => (
                  <option value={data.id} key={index}>
                    {data.nome}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataAtendimentoInicial')}
              error={formik.errors.dataAtendimentoInicial}
              touched={formik.touched.dataAtendimentoInicial}
              value={formik.values.dataAtendimentoInicial}
              placeholder=""
              title="Data Atendimento "
            />
          </div>
          <div className="tw-mt-5 tw-w-[170px]">
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataAtendimentoFinal')}
              error={formik.errors.dataAtendimentoFinal}
              touched={formik.touched.dataAtendimentoFinal}
              value={formik.values.dataAtendimentoFinal}
              placeholder=""
              title=""
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('region')}
              error={formik.errors.region}
              touched={formik.touched.region}
              value={formik.values.region}
              title="Região"
            >
              <>
                <option value="">Selecione uma opção</option>
                {region.map((data: Region, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('route')}
              error={formik.errors.route}
              touched={formik.touched.route}
              value={formik.values.route}
              title="Rota"
            >
              <>
                <option value="">Selecione uma opção</option>
                {route.map((data: Route, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('sector')}
              error={formik.errors.sector}
              touched={formik.touched.sector}
              value={formik.values.sector}
              title="Setor"
            >
              <>
                <option value="">Selecione uma opção</option>
                {sector.map((data: Sector, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('tipoAtendimento')}
              error={formik.errors.tipoAtendimento}
              touched={formik.touched.tipoAtendimento}
              value={formik.values.tipoAtendimento}
              title="Tipo atendimento"
            >
              <option value="">Selecione uma opção</option>
              <option value="Entrega">Entrega</option>
              <option value="Coleta">Coleta</option>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('reentrega')}
              error={formik.errors.reentrega}
              touched={formik.touched.reentrega}
              value={formik.values.reentrega}
              title="Reentrega"
            >
              <option value="null">Selecione uma opção</option>
              <option value={0}>SIM</option>
              <option value={1}>NÃO</option>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]" />
        </div>

        <div className="tw-mt-2">
          <div className="tw-absolute tw-right-0">
            <button
              type="button"
              className="tw-bg-[#EA004C] tw-w-[100px] tw-h-[35px] tw-border-none tw-rounded-md tw-mr-4 tw-text-[#fff]"
              onClick={() => formik.handleSubmit()}
            >
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormFilter;
