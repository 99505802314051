/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

import EditIcon from 'assets/images/editIcon.svg';
import ptBR from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import api from 'services/api';


import { useFormik } from 'formik';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import formValidatorPT from './formValidator-PT';
import './style.scss';
import { Footer } from './styles';

import formValidatorEN from './formValidator-EN';

registerLocale('pt-BR', ptBR);

export default function VideoClasses({ onClear }) {
  const [modal, setModal] = useState(true);
  const [edit, setEdit] = useState(false);
  const [videoLessons, setVideoLessons] = useState([]);

  const [refreshVideoLessons, setRefreshVideoLessons] = useState();
  const [updateId, setUpdateId] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values, arr, isUpdate, reset, setValue) => {
      try {
        const data = {
          url: values.urlVideo,
        };
        const { language } = navigator;
        const serviceLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
        if (arr.length > 0) {
          const response = await api.patch(
            `/delivery/videoAulas/${isUpdate}?lang=${serviceLanguage}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('videoClasses.messageUpdated'),
              NotificationType.SUCCESS
            );
          }

          setRefreshVideoLessons(!refreshVideoLessons);
        } else {
          const response = await api.post(
            `/delivery/videoAulas?lang=${serviceLanguage}`,
            data
          );

          if (response.status) {
            FrontendNotification(
              t('videoClasses.messageCreated'),
              NotificationType.SUCCESS
            );
          }
          setRefreshVideoLessons(!refreshVideoLessons);
        }

        reset({});
        setUpdateId();
        setEdit(false);
        setValue('urlVideo', values.urlVideo);

        // getVideoLessons();
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    []
  );

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const deleteDriver = useCallback(async driverId => {
    try {
      // setRemoveConfirmation(true);
      const response = await api.delete(`/delivery/videoAulas/${driverId}`);
      FrontendNotification(
        t('videoClasses.messageRemove'),
        NotificationType.SUCCESS
      );
      setRemoveConfirmation(false);
      // getVideoLessons();
    } catch (err) {}
  }, []);

  const formik = useFormik({
    initialValues: {
      urlVideo: '',
    },
    validationSchema:
      navigator.language === 'pt' || navigator.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm, setFieldValue }) =>
      handleSubmit(values, videoLessons, updateId, resetForm, setFieldValue),
  });

  const getVideoLessons = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/videoAulas/findByFilters',
        data
      );

      const videoLessonsOrder = response.data.sort((a, b) => b.id - a.id);

      const videoLeessonsFilter = videoLessonsOrder.filter(
        item => item.status === 'ACTIVE'
      );

      if (videoLeessonsFilter.length > 0) {
        formik.setFieldValue('urlVideo', videoLeessonsFilter[0].url);
      }

      setVideoLessons(videoLeessonsFilter);
    } catch (err) {}
  }, [refreshVideoLessons]);

  useEffect(() => {
    getVideoLessons();
  }, [getVideoLessons]);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteDriver(removeId)}>
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={toggleRemove}>
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={clearNav}
        dialogClassName="my-modal"
        style={{ maxWidth: '434px', maxHeight: '70%' }}
      >
        <div className="modalVideoLessons">
          <div className="iconCloseView">
            <button
              className="buttonClose"
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <p className="title">
            Associar Canal
            {/* <Trans i18nKey="videoClasses.titleField" /> */}
          </p>

          <span className="instuctionVideoLessons">
            O link/url a ser informado no campo abaixo deverá ser do canal do
            youtube onde estão relacionados os vídeos. Para identificar o link
            do canal basta digitar na barra superior de pesquisa no próprio
            youtube o nome do canal (exemplo: canal teste) e na sequencia entrar
            e copiar a URL (exemplo de canal:
            https://www.youtube.com/user/teste).
          </span>
          <div style={{ marginTop: 27 }}>
            <Footer>
              <div className="formWrapper">
                <div className="formInput">
                  <Label
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    Link do canal
                    {/* <Trans i18nKey="videoClasses.fields.url" /> */}
                    <input
                      disabled={!!(videoLessons.length > 0 && !edit)}
                      className={
                        videoLessons.length > 0 && edit
                          ? 'inputVideoLessons'
                          : videoLessons.length > 0 && !edit
                          ? 'inputVideoLessonsActive'
                          : 'inputVideoLessons'
                      }
                      type="url"
                      placeholder="Insira o link do canal"
                      // placeholder={t('videoClasses.placeholder.url')}
                      onChange={formik.handleChange('urlVideo')}
                      value={formik.values.urlVideo}
                    />
                    {formik.touched.urlVideo && formik.errors.urlVideo && (
                      <p className="errorText">{formik.errors.urlVideo}</p>
                    )}
                  </Label>
                </div>
                {videoLessons.length > 0 && (
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      setEdit(!edit);
                      setUpdateId(videoLessons[0].id);
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt=""
                    />
                  </Button>
                )}
              </div>
              {edit ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <button
                      onClick={() => {
                        formik.resetForm({});
                        setEdit(false);

                        setUpdateId();

                        getVideoLessons();
                      }}
                      className="button buttonCancel"
                      type="button"
                    >
                      <Trans i18nKey="common.cancel" />
                    </button>

                    <button
                      onClick={formik.handleSubmit}
                      className="buttonUpdate"
                      type="button"
                    >
                      <Trans i18nKey="videoClasses.update" />
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <button
                    onClick={formik.handleSubmit}
                    className="button buttonSave"
                    type="button"
                  >
                    <span className="buttonSaveText">
                      Associar canal
                      {/* <Trans i18nKey="videoClasses.save" /> */}
                    </span>
                  </button>
                </div>
              )}
            </Footer>
          </div>
        </div>
      </Modal>
    </>
  );
}
