import React from 'react';
import { Container, TextContent } from './styles';

interface Props {
  label: string;
  active: boolean;
  onChange: any;
  id?: string;
}

const Toogle: React.FC<Props> = ({ label, active, onChange, id }: Props) => {
  return (
    <Container activeToogle={active} onClick={() => onChange()} id={id}>
      <TextContent activeToogle={active}>
        {label}
      </TextContent>
    </Container>
  )
}

export default Toogle;