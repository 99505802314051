import CustomModal from 'components/ModalCustom';
import SelectVehicles from 'components/SelectVehicles';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import { DataStepsRoutering } from 'store/modules/routering/actions';
import iconAttendancesCargo from '../../assets/images/iconAttendancesCargo.svg';
import iconAttendancesCargoActive from '../../assets/images/iconAttendancesCargoActive.svg';
import iconWeightCargo from '../../assets/images/iconWeightCargo.svg';
import iconWeightCargoActive from '../../assets/images/iconWeightCargoActive.svg';
import iconCargoAction from '../../assets/images/orderRightCargo.svg';
import packageCargo from '../../assets/images/packageCargo.svg';
import packageCargoActive from '../../assets/images/packageCargoActive.svg';
import priceCargo from '../../assets/images/priceCargo.svg';
import priceCargoActive from '../../assets/images/priceCargoActive.svg';
import truckRoutering from '../../assets/images/truckRoutering.svg';
import '../../pages/RouterCargo/CargasFilterRoutering/CardCargo/styles.scss';
import ProgressCargoRoutering from '../../pages/RouterCargo/components/CargasRouting/components/Card/components/Progress';
import ConfirmationDriver from './ConfirmationDriver';
import './styles.scss';

interface ChangeVehiclesLoadsProps {
  onClear: () => void;
  dataOptmi: any;
  onUpdate: () => void;
}

const ChangeVehiclesLoads: React.FC<ChangeVehiclesLoadsProps> = ({
  onClear,
  dataOptmi,
  onUpdate,
}: ChangeVehiclesLoadsProps) => {
  // Hook de traducão.
  const { t } = useTranslation();

  // Hook responsavel por guardar os dados da simulacao.
  const getStepId = useSelector(
    (state: any) => state.routeringSimulation.id_step
  );

  const [modal, setModal] = React.useState(true);

  const [loading, setLoading] = useState(false);

  const [confirmation, setConfirmation] = useState(false);

  const [maximumCapacity, setMaximumCapacity] = useState<any>();

  const [selectCargo, setSelectedCargo] = useState();

  const [cargaColors, setCargaColors] = useState([]);

  const [dataCargo, setCargoData] = useState<any>([]);

  const [showVehicles, setShowVehicles] = useState<boolean>(false);

  const [listVehicles, setListVehicles] = useState([]);

  const [parameters, setParameters] = useState([]);

  const [violationsCapacity, setViolationsCapacity] = useState(false);

  const routeringSimulator = useSelector(
    (state: any) => state.routeringSimulation.editSimulator
  );

  const routeringSimulatorId = useSelector(
    (state: any) => state.routeringSimulation.routeOptmiId
  );

  const id_simulacao = useSelector(
    (state: any) => state.routeringSimulation.id_route_simulation
  );

  const dataStepsSelected: DataStepsRoutering[] = useSelector(
    (state: any) => state.routering.data.dataStepsSelected
  );

  function calcPorcentage(valueInitial: number, total: number) {
    const md_val: any = Number(valueInitial).toFixed(2);
    const medidab: any = Number(md_val / total) * 100;
    const formatMedidab: any = parseInt(medidab);
    return formatMedidab;
  }

  function getCalcPorcentage(dataAccumulator: any) {
    const accumulatorAttendances = calcPorcentage(
      dataAccumulator.steps.length,
      dataAccumulator.vehicle.quantidadeAtendimento
    );

    const accumulatorWeight = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.peso);
      },
      0
    );

    const accumulatorVolume = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return (
          acc +
          parseFloat(
            itemAcc.conhecimentoPre.cubagem.length === 0
              ? 0
              : itemAcc.conhecimentoPre.cubagem
          )
        );
      },
      0
    );

    const accumulatorValor = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.valor);
      },
      0
    );

    return {
      porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentage(
        accumulatorWeight,
        dataAccumulator.vehicle.peso
      ),
      porcentageVolume: calcPorcentage(
        accumulatorVolume,
        dataAccumulator.vehicle.cubagem
      ),
      porcentagePrice: calcPorcentage(
        accumulatorValor,
        dataAccumulator.vehicle.valor
      ),
    };
  }

  function formatReal(int: any) {
    const elemento = int;
    let valor = elemento;

    valor += '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor += '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    return valor;
  }

  // Valida a capacidade do veiculo após a troca.
  function getViolationsCapacity(optmisData: any, stepId: any) {
    let isViolate = false;
    let currentRoute = sessionStorage.getItem('violationsRoute');

    let updateRouteSteps: any = null;

    if (currentRoute) {
      updateRouteSteps = JSON.parse(currentRoute);
    }

    // const getFilterSteps = optmisData.steps.filter(
    //   (itemFilter: any, index: any) => {
    //     return itemFilter.conhecimentoPre.id === stepId;
    //   }
    // );

    const updateStepsViolate = dataStepsSelected.map((stepSelected) => {
      updateRouteSteps.steps.push(stepSelected);
    });

    const violateCapacity = getCalcPorcentage(updateRouteSteps);

    console.log("Violate Capacity", violateCapacity);

    if (
      violateCapacity.porcentageAttendances >= 100 ||
      violateCapacity.porcentagePrice >= 100 ||
      violateCapacity.porcentageVolume >= 100 ||
      violateCapacity.porcentageWeight >= 100
    ) {
      isViolate = true;
    }

    return isViolate;
  }

  const getCargo = useCallback(async () => {
    try {
      setLoading(true);
      if (routeringSimulator === true) {
        const data = {
          filters: [
            {
              field: 'status',
              value: 'ACTIVE',
              operation: 'EQUAL',
            },
            {
              field: 'simulation.id',
              value: sessionStorage.getItem('idSimulacao'),
              operation: 'EQUAL',
            },
          ],
          orders: [],
          page: 0,
          size: 1000,
        };
        const response = await api.post('/route/optmis/pageable', data);

        const filterLoad = response.data.content.filter(
          (itemFilter: any) => itemFilter.id !== dataOptmi.id
        );

        if (response.data.content.length > 0) {
          setCargoData(filterLoad);
          let listVehicle: any = [];
          const filterListVehicles = response.data.content.map((list: any) => {
            listVehicle.push(list.vehicle);
          });

          setListVehicles(listVehicle);
        } else {
          history.push('/dashboard');
          window.location.reload();
        }
      } else {
        const data = {
          filters: [
            {
              field: 'status',
              value: 'ACTIVE',
              operation: 'EQUAL',
            },
            {
              field: 'simulation.id',
              value: sessionStorage.getItem('idSimulacao'),
              operation: 'EQUAL',
            },
          ],
          orders: [],
          page: 0,
          size: 1000,
        };
        const response = await api.post('/route/optmis/pageable', data);

        if (response.data.content.length > 0) {
          const filterLoad = response.data.content.filter(
            (itemFilter: any) => itemFilter.id !== dataOptmi.id
          );

          const orderById = filterLoad.sort((a: any, b: any) => a.id - b.id);

          let listVehicle: any = [];
          const filterListVehicles = response.data.content.map((list: any) => {
            listVehicle.push(list.vehicle);
          });

          setListVehicles(listVehicle);

          setCargoData(orderById);
        } else {
          history.push('/uploadCSV');
          window.location.reload();
        }
      }
      setLoading(false);
    } catch (err) {}
  }, []);

  const onUpdateCargoSimulation = useCallback(
    async (vehiclesData: any, optmisData: any, idStep: number) => {
      try {
        const updateSteps = optmisData;
        let reorderSteps = dataStepsSelected.slice().sort((a: DataStepsRoutering, b: DataStepsRoutering) => a.order - b.order);
        
        const mappingKnowledge = reorderSteps.map(
          (itemKnowledge: any, indexKnowledge: number) => {
            return {
              id: itemKnowledge.conhecimentoPre.id,
              priority: 0,
            };
          }
        );
        let updateStepsCargo = mappingKnowledge;


        const dataSubmit = {
          conhecimentos: updateStepsCargo,
          // rota: optmisData.id,
          veiculos: vehiclesData,
          filial: sessionStorage.getItem('subsidiary'),
          simulation: sessionStorage.getItem('idSimulacao'),
        };

        const response = await api.post(
          '/delivery/conhecimentosPre/geraRotasSimulacao',
          dataSubmit
        );

        sessionStorage.setItem(
          'idSimulacao',
          JSON.stringify(response.data.id_route_simulation)
        );

        setLoading(false);

        FrontendNotification(
          'Nova rota criada com sucesso',
          NotificationType.SUCCESS
        );
        setModal(!modal);
        onClear();
        onUpdate();
        // onConfirm();
        // dataKnowledgeRouteringRemoved(knowledgeData);
      } catch (err) {}
    },
    []
  );

  const setChangeStepsSimulation = useCallback(
    async (optmisData: any, idStep: number, data: any) => {
      try {
        setLoading(true);
        let getCargas = sessionStorage.getItem('cargas');

        let getCargaDateScheduling;

        if (getCargas) {
          getCargaDateScheduling = JSON.parse(getCargas);
        }

        let dataChangeSteps: DataStepsRoutering[] = optmisData.steps.slice().sort((a: DataStepsRoutering, b: DataStepsRoutering) => a.order - b.order);

        console.log("Reorder Steps 1", dataChangeSteps);


        const updateStepsRoutering = dataChangeSteps.filter((stepChanged) => !dataStepsSelected.some((stepSelected) => stepSelected.id === stepChanged.id));


        // const filterSteps = optmisData.steps.filter(
        //   (itemFilter: any, index: number) => {
        //     return itemFilter.conhecimentoPre.id !== idStep;
        //   }
        // );

        const mappingKnowledge = updateStepsRoutering.map(
          (itemKnowledge: any, indexKnowledge: number) => {
            return {
              id: itemKnowledge.conhecimentoPre.id,
              priority: 0,
            };
          }
        );

        const dateScheduling = getCargaDateScheduling.find(
          (item: any) => item.id === optmisData.id
        ).dataAgendamento;

        const getDateScheduling = format(
          new Date(dateScheduling),
          'yyyy-MM-dd',
          {
            locale: pt,
          }
        );

        const getHoursScheduling = format(
          new Date(dateScheduling),
          'HH:mm:ss',
          {
            locale: pt,
          }
        );

        const dataSubmit = {
          conhecimentos: mappingKnowledge,
          rota: optmisData.id,
          veiculos: [
            {
              id: optmisData.vehicle.id,
              roadtrip: optmisData.roadtrip,
              outputForecast: `${getDateScheduling}T${getHoursScheduling}`,
            },
          ],
          filial: optmisData.subsidiary.id,
          alteraAtendimentosRota: false,
          idStep: null,
        };

        const response = await api.post(
          '/delivery/conhecimentosPre/alteraRotas',
          dataSubmit
        );

        const getFilterSteps = optmisData.steps.filter(
          (itemFilter: any, index: number) => {
            return itemFilter.conhecimentoPre.id === idStep;
          }
        );

        onUpdateCargoSimulation(
          data,
          getFilterSteps,
          getFilterSteps[0].conhecimentoPre.id
        );

        FrontendNotification(
          t('changeVehiclesLoads.messageSuccess'),
          NotificationType.SUCCESS
        );
      } catch (err) {}
    },
    []
  );

  const getParameters = useCallback(async () => {
    try {
      const data = {
        filters: [],
      };
      const response = await api.post(
        '/cadastro/parametrosWeb/findByFilters',
        data
      );

      setParameters(response.data);
    } catch (err) {}
  }, []);

  function getColors(id: any) {
    const getColorsByCargaId: any = cargaColors.find(
      (element: any) => element.id == id
    );

    return getColorsByCargaId.color;
  }

  useEffect(() => {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colors = [];

    if (getColorsByCarga) {
      colors = JSON.parse(getColorsByCarga);
    }

    console.log('color', colors);

    setCargaColors(colors);

    getCargo();

    getParameters();
  }, [getCargo]);

  return (
    <React.Fragment>
      {loading && <Loading loading={loading} />}
      {confirmation && (
        <ConfirmationDriver
          dataLoad={dataOptmi}
          dataParameters={parameters}
          data={selectCargo}
          maxCapacity={maximumCapacity}
          onClear={() => setConfirmation(!confirmation)}
          violations={violationsCapacity}
          onConfirm={() => {
            onClear();
            setConfirmation(!confirmation);
            setModal(!modal);
            onUpdate();
          }}
          idRomaneio={undefined}
          cpfDriver={undefined}
          onLoad={undefined} // onLoad={onChangeCargo}
        />
      )}
      {showVehicles && (
        <SelectVehicles
          filialId={Number(sessionStorage.getItem('subsidiary'))}
          onClear={() => setShowVehicles(!showVehicles)}
          defaultVehicles={listVehicles}
          onVehiclesSelected={(dataVehicles: any) => {
            console.log('optmisData', dataOptmi);
            setChangeStepsSimulation(dataOptmi, getStepId, dataVehicles);
          }}
          dataCargoValue={undefined}
          isUnique
        />
      )}
      <CustomModal
        isOpen
        label={t('changeVehiclesLoads.title')}
        isClose={onClear}
        styles={{ minWidth: '50%' }}
      >
        <div className="cargoList">
          <div
            className="containerRoute"
            onClick={() => setShowVehicles(!showVehicles)}
          >
            <div className="wrapperRoute">
              <img src={truckRoutering} width="48" height="48" />
              <span className="textNewRoute">
                <Trans i18nKey="changeVehiclesLoads.textNewRoute" />
              </span>
            </div>
          </div>
          {dataCargo.map((item: any, index: number) => (
            <div
            className={
              getCalcPorcentage(dataCargo[index]).porcentageAttendances >
                100 ||
              getCalcPorcentage(dataCargo[index]).porcentageWeight > 100 ||
              getCalcPorcentage(dataCargo[index]).porcentageVolume > 100 ||
              getCalcPorcentage(dataCargo[index]).porcentagePrice > 100
                ? 'containerCard'
                : 'containerCardActive'
            }
              onClick={() => {
                let saveDataRoute = sessionStorage.setItem(
                  'violationsRoute',
                  JSON.stringify(item)
                );
                // Verifica se após a troca a capacidade do veiculo (Se a capacidade do veiculo for ultrapassada a troca é cancelada).
                const validateViolations = getViolationsCapacity(
                  dataOptmi,
                  getStepId
                );
                setViolationsCapacity(validateViolations);

                setMaximumCapacity(getCalcPorcentage(dataCargo[index]));
                setConfirmation(!confirmation);
                setSelectedCargo(dataCargo[index]);
                console.log('dataCargo', dataCargo[index]);
              }}
              style={{
                borderTop: `10px solid ${getColors(dataCargo[index]?.id)}`,
                background: '#fff',
              }}
            >
              <div className="tw-flex tw-relative tw-items-start tw-justify-between">

                <div className="tw-flex tw-flex-col tw-ml-6">
                  <span className="tw-text-[#003049] tw-font-bold tw-text-sm">
                    {item.driver !== null && item.driver.nome}
                  </span>
                  <span className="tw-text-[#003049] tw-font-normal tw-text-sm">{item.vehicle.placa}</span>

                  <span className="tw-text-[#003049] tw-font-normal tw-text-sm">
                    {item.subsidiary.nome}
                  </span>
                </div>

                <div className="actionCargo">
                  <img src={iconCargoAction} alt="" />
                </div>
              </div>

              <div className="tw-flex tw-justify-between tw-mt-2">
                <div className="tw-flex tw-flex-col tw-mr-2">
                  <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                    <img
                      src={
                        getCalcPorcentage(dataCargo[index])
                          .porcentageAttendances > 100
                          ? iconAttendancesCargoActive
                          : iconAttendancesCargo
                      }
                      className="imgProgress"
                      alt=""
                    />
                    <ProgressCargoRoutering
                      progressValue={
                        getCalcPorcentage(dataCargo[index])
                          .porcentageAttendances
                      }
                      secondary
                    />
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                    <img
                      src={
                        getCalcPorcentage(dataCargo[index]).porcentageWeight >
                        100
                          ? iconWeightCargoActive
                          : iconWeightCargo
                      }
                      className="imgProgress"
                      alt=""
                    />

                    <ProgressCargoRoutering
                      progressValue={
                        getCalcPorcentage(dataCargo[index]).porcentageWeight
                      }
                    />
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                    <img
                      src={
                        getCalcPorcentage(dataCargo[index]).porcentageVolume >
                        100
                          ? packageCargoActive
                          : packageCargo
                      }
                      className="imgProgress"
                      alt=""
                    />

                    <ProgressCargoRoutering
                      progressValue={
                        getCalcPorcentage(dataCargo[index]).porcentageVolume
                      }
                    />
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                    <img
                      src={
                        getCalcPorcentage(dataCargo[index]).porcentagePrice >
                        100
                          ? priceCargoActive
                          : priceCargo
                      }
                      className="imgProgress"
                      alt=""
                    />

                    <ProgressCargoRoutering
                      progressValue={
                        getCalcPorcentage(dataCargo[index]).porcentagePrice
                      }
                    />
                  </div>
                </div>

                <div className="tw-flex tw-flex-col tw-items-center tw-mr-4 tw-ml-2">
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    {' '}
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.distance" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    {Number(item.distanceTotal / 1000)}Km
                  </span>
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    {' '}
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.duration" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    {`${item.travelTime.formattedTime}`}
                    {item.travelTime.hour === 0 ? 'min' : 'h'}
                  </span>
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    {' '}
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.price" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    R${' '}
                    {item.cost === 0
                      ? '0,00'
                      : formatReal(item.cost.toFixed(2))}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CustomModal>
    </React.Fragment>
  );
};

export default ChangeVehiclesLoads;
