import styled from 'styled-components';
import { responsiveSize } from 'styles/responsive';

interface StyledProps {
  activeToogle: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ activeToogle }: StyledProps) =>
    activeToogle ? '#EA004C' : '#fff'};
  border: ${responsiveSize(1)} solid #ea004c;
  border-radius: ${responsiveSize(20)};
  width: auto;
  height: ${responsiveSize(33)};
  cursor: pointer;
`;

export const TextContent = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: ${responsiveSize(16)};
  line-height: ${responsiveSize(19)};
  text-align: center;
  color: ${({ activeToogle }: StyledProps) =>
    activeToogle ? '#fff' : '#EA004C'};
`;
