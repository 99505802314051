import i18next from 'i18next';
import { Line } from 'pages/ResetPassword/styles';
import { AccessAcc, BackButtom } from 'pages/SignUp/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import api from 'services/api';
import { urlActiveAccount } from 'services/endpoint';
import history from 'services/history';
import sendIcon from '../../assets/images/sendIcon.png';
import voltar from '../../assets/voltar-seta.png';
import BaseTemplate from '../../template/baseTemplate';

const ActiveAccount = () => {
  const [uuid, setUuid] = useState<any>(null);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onHandleLoad = useCallback(async () => {
    try {
      setLoading(true);

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const uuidValue = params.get('uuid');

      setUuid(uuidValue);

      const language = i18next.language;

      const emailSendLanguage = language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

      const response = await api.get(urlActiveAccount + `?uuid=${uuidValue}`);

      if(response.status === 204) {        
        history.push('/');
        window.location.reload();
      }

      setLoading(false);
    }catch(err) {

    }
  }, []);

  // const resendEmailActive = useCallback(async () => {
  //   const 
  // }, [])


  useEffect(() => {
    onHandleLoad()
  }, [onHandleLoad])

  return (
    <BaseTemplate loading={loading} text={t('message.welcome.title')}>
      <div className="form-active">
        {displayMessage && (
          <React.Fragment>
            <div className="content">
              <div className="row">
                <BackButtom>
                  <AccessAcc>
                    <Link to="/" className="voltar">
                      <img src={voltar} className="seta" alt="Back" />
                      {t('forgotPassword.back')}
                    </Link>
                  </AccessAcc>
                  <h5
                    className="text-center bold"
                    style={{
                      fontFamily: 'Lato',
                      fontWeight: 'bold',
                      fontSize: 24,
                      color: '#003049',
                    }}
                  >
                    {t('signUp.title')}
                  </h5>
                  <Line />
                </BackButtom>
              </div>
              <hr className='redLine' />
              <div className='container-image'>
                <img src={sendIcon} alt="" />
                <span>{t('activeAccount.success')}</span>
              </div>
              <div>
                {uuid === null && (
                  <p>
                    {t('activeAccount.sendEmail')}
                    <span className='email-container'>{email}</span>
                  </p>
                )}
                <p>
                  {t('activeAccount.confirmAccount')}
                  <span className='project'>FastDelivery</span>
                </p>
                {/* <h1>
                  {t('activeAccount.receiveEmail')}
                  <button onClick={() => resendEmailActive()}>
                    <span>{t('activeAccount.resendEmail')}</span>
                  </button>
                </h1> */}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </BaseTemplate>
  );
};

export default ActiveAccount;
