import EditIcon from 'assets/images/editIcon.svg';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import searchIcon from '../../../assets/images/ant-design_search-outlined.png';
import deleteIcon from '../../../assets/images/deleteIcon.svg';
import Pagination from '../../Pagination';
import { IconColumn } from '../styles';
import '../styles.scss';

export default function ListSubsidiary({ onEdit, data, onRefresh }) {
  const [filiais, setFiliais] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [valueSearch, setValueSearch] = useState('');
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const { t } = useTranslation();

  const filtersData = useMemo(() => {
    let computedPages = data;

    computedPages = computedPages.filter(
      op =>
        op.nome.toLowerCase().includes(valueSearch.toLowerCase()) ||
        op.endereco.pais.toLowerCase().includes(valueSearch.toLowerCase()) ||
        op.endereco.uf.toLowerCase().includes(valueSearch.toLowerCase()) ||
        op.endereco.cidade.toLowerCase().includes(valueSearch.toLowerCase()) ||
        op.endereco.logradouro.toLowerCase().includes(valueSearch.toLowerCase())
    );

    setTotalPages(computedPages.length);

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [data, currentPage, valueSearch]);

  const deleteDriver = useCallback(async operatorId => {
    try {
      const response = await api.delete(`/cadastro/filiais/${operatorId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('filiais.messageRemove'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('filiais.messageErroRemoved'),
          NotificationType.ERROR
        );
      }

      setRemoveConfirmation(false);

      onRefresh();
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  function mascaraCnpj(valor) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const dispatch = useDispatch();

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <div className="filter">
        <Label className="search-Label" htmlFor="search-input">
          <div className="search">
            <input
              type="search"
              name="BuscaRomaneio"
              id="search-input"
              placeholder={t('driver.search')}
              className="tw-w-48 tw-h-9 tw-bg-[#fff] tw-box-border tw-rounded tw-font-normal tw-text-sm tw-text-[#003049] tw-pl-3 tw-mr-5"
              // value={valueSearch}
              onChange={e => {
                setValueSearch(e.target.value);
                setCurrentPage(1);
              }}
              style={{ border: '2px solid rgba(0,48,73,0.2)' }}
            />
            <img
              src={searchIcon}
              alt=""
              style={{
                width: 24,
                height: 24,
                position: 'relative',
                right: 56,
                top: '-2px',
              }}
            />
          </div>
        </Label>
        <div style={{ flexDirection: 'column' }} />
      </div>
      <div
        className="table-responsive"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <table style={{ width: '100%', marginBottom: 20 }}>
          <thead>
            <tr>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  <Trans i18nKey="filiais.fields.nome" />
                </span>
              </th>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  CNPJ
                  {/* <Trans i18nKey="filiais.fields.nome" /> */}
                </span>
              </th>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  <Trans i18nKey="filiais.fields.pais" />
                </span>
              </th>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  <Trans i18nKey="filiais.fields.estado" />
                </span>
              </th>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  <Trans i18nKey="filiais.fields.cidade" />
                </span>
              </th>
              <th>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    fontWeight: 900,
                    color: '#003049',
                  }}
                >
                  <Trans i18nKey="filiais.fields.logradouro" />
                </span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {filtersData.map(item => (
              <tr className="lineGroup">
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {item.nome}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {mascaraCnpj(item.cnpj)}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {item.endereco.pais}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {item.endereco.uf}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {item.endereco.cidade}
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#003049',
                    }}
                  >
                    {item.endereco.logradouro}
                  </span>
                </td>

                <IconColumn>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => onEdit(item.id)}
                  >
                    <img
                      src={EditIcon}
                      alt=""
                    />
                  </Button>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      setRemoveId(item.id);
                      setRemoveConfirmation(!removeConfirmation);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                  </Button>
                </IconColumn>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <Pagination /> */}
      </div>
      <Pagination
        perPage={perPage}
        total={totalPages}
        onPaginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
