/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import { useFormik } from 'formik';
import i18next from 'i18next';
// import CheckBox from 'pages/ControlTower/ListAttendances/Filters/CheckBox';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Col, Label, Row } from 'reactstrap';
import orderBottom from '../../../assets/images/orderBottom.png';
import orderTop from '../../../assets/images/orderTop.png';
import {
  FrontendNotification,
  NotificationType
} from '../../../core/common/Notification';
import api from '../../../services/api';
import { formatLanguage } from '../../../util/formatLanguage';
import CustomSelect from '../../CustomSelect';
import InputTag from '../../InputTag';
import { inputForm } from '../../Ocorrence/styles';
import Pagination from '../../Pagination';
import SelectParameters from '../../SelectParameters';
import Switch from '../../Switch';
import formValidationEN from '../formValidationEN';
import formValidationPT from '../formValidationPT';
import formValidatorENSMS from '../formValidatorENSMS';
import formValidatorPTSMS from '../formValidatorPTSMS';
import '../style.scss';
import {
  BoxForm,
  Footer,
  IconColumn,
  containerSwitch,
  inputDescription,
  labelStyle,
  labelSwitch
} from '../styles';
import CheckBox from './CheckBox';

export default function FormPhases({
  data,
  dataArr,
  totalPages,
  phaseTab,
  onUpdateSequence,
  onLoadPhases,
  onRemoveTag,
  onRemove,
  onSMS,
  onPaginatePhase,
}) {
  const [valuePhases, setValuePhases] = useState('');
  const [selectParamets, setSelectParamets] = useState(false);
  const [template, setTemplate] = useState([]);

  const [edit, setEdit] = useState(false);
  const [editTemplate, setEditTemplate] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMaxLength, setErrorMaxLength] = useState('');
  const [count, setCount] = useState(0);
  const [updateId, setUpdateId] = useState();
  const [loading, setLoading] = useState(false);
  const [tipo, setTipo] = useState('');
  const [description, setDescription] = useState('');
  const [sequenceEdit, setSequenceEdit] = useState(null);
  const [options, setOptions] = useState(false);
  const [journey, setJourney] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [
    selectParametersCommunication,
    setSelectParametersCommunication,
  ] = useState(false);
  const [journeyData, setJourneyData] = useState([]);

  const [countObservation, setCountObservation] = useState(0);

  const { t } = useTranslation();
  const [checkedSMS, setCheckedSMS] = React.useState(false);
  const [checkedEmail, setCheckedEmail] = useState(false);
  const [checkedLink, setCheckedLink] = useState(false);
  const [checkedJourney, setCheckedJourney] = useState(false);
  const [checked, setChecked] = useState({
    checkedSuccess: false,
    checkedPhoto: false,
    checkedAss: false,
    checkedPackage: false,
    checkedSkipServices: false,
    checkedDocument: false,
    checkedRecebedor: false,
  });

  const tagRef = useRef();

  const getLanguage = () => {
    return i18next.language;
  };

  const getTemplate = useCallback(async () => {
    try {
      const dataTemplate = {
        filters: [
          {
            field: 'status',
            value: 1,
            operation: 'EQUAL',
          },
          {
            field: 'publicado',
            value: true,
            operation: 'EQUAL'
          }
        ],
      };
      const response = await api.get('/delivery/templates', dataTemplate);

      const filterTemplateByPublished = response.data.filter(t => t.publicado === true);

      setTemplate(filterTemplateByPublished);
    } catch (err) {}
  }, []);

  const getParameters = useCallback(async tagField => {
    const response = await api.get('/delivery/templates/markups');

    const filter = response.data.filter(item => item.tag === `[[${tagField}]]`);

    return filter[0].length;
  }, []);

  const onRegisterTemplate = useCallback(
    async (registerId, isUpdate, valuesForm, emailCheck, linkCheck) => {
      try {
        setLoading(true);

        const resultTemplateEmailData = {
          id: valuesForm.templateEmailId,
        };
        const resultTemplateData = {
          id: valuesForm.templateId,
        };
        const phasesData = {
          id: registerId,
        };

        const isTemplateEmail =
          valuesForm.templateEmailId === 'SAIU_ENTREGA' ||
          valuesForm.templateEmailId === 'PEDIDO_CHEGOU' ||
          valuesForm.templateEmailId.length === 0
            ? 'TEMPLATE_EMAIL'
            : 'TEMPLATE';
        const isTemplate =
          valuesForm.templateId === 'SAIU_ENTREGA' ||
          valuesForm.templateId === 'PEDIDO_CHEGOU' ||
          valuesForm.templateId.length === 0
            ? 'TEMPLATE_LINK'
            : 'TEMPLATE';

        const dataCreated = {
          templateEmail:
            isTemplateEmail === 'TEMPLATE_EMAIL'
              ? null
              : resultTemplateEmailData,
          templateLink:
            isTemplate === 'TEMPLATE_LINK' ? null : resultTemplateData,
          templateEmailPadrao:
            valuesForm.templateEmailId === 'SAIU_ENTREGA' ||
            valuesForm.templateEmailId === 'PEDIDO_CHEGOU'
              ? valuesForm.templateEmailId
              : null,
          templateLinkPadrao:
            valuesForm.templateId === 'SAIU_ENTREGA' ||
            valuesForm.templateId === 'PEDIDO_CHEGOU'
              ? valuesForm.templateId
              : null,
          templateEmailAssunto: valuesForm.subject,
          etapa: phasesData,
        };

        if (isUpdate.length > 0) {
          const responseUpdate = await api.patch(
            `/delivery/etapas/template/${isUpdate}`,
            dataCreated
          );
        } else {
          const responseData = await api.post(
            `/delivery/etapas/template`,
            dataCreated
          );
        }

        setEditTemplate('');

        onLoadPhases();

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (
      values,
      type,
      check,
      listPhases,
      update,
      reset,
      countSubmit,
      sequence,
      tab,
      smsChecked,
      emailChecked,
      linkChecked,
      breakChecked,
      editTemplateId
    ) => {
      try {
        const maxLength = process.env.MAX_LENGTH_SMS || 132;
        if (smsChecked === true && countSubmit === 0) {
          const message =
            getLanguage() === 'pt' || getLanguage() === 'pt-BR'
              ? '* Informe a mensagem do sms!'
              : '* Enter the sms message';

          setErrorMaxLength(message);
        } else if (countSubmit <= maxLength) {
          setErrorMaxLength('');

          setLoading(true);

          const isBreaks = {
            id: parseInt(values.journeyId),
          };

          const removeChaves = values.smsMensagem.replace(/(\{)/g, '');
          const removeChaves2 = removeChaves.replace(/(\})/g, '');
          const removeAspas = removeChaves2.replace(/(\")/g, '');
          const removeValue = removeAspas.replace('value:', '');
          const dataSubmit = {
            descricao: values.descricao,
            tempo: values.tempo,
            tipo: values.icone,
            posicao: !!check.checkedSuccess,
            foto: !!check.checkedPhoto,
            assinatura: !!check.checkedAss,
            nomeRecebedor: check.checkedRecebedor,

            documento: check.checkedDocument,
            tipoAtendimento: tab === 0 ? 'ENTREGA' : 'COLETA',
            observacao: values.observacao,
            confirmaPacote: !!check.checkedPackage,
            pulaAtendimento: !!check.checkedSkipServices,
            email: {
              ativo: emailChecked,
            },
            link: {
              ativo: linkChecked,
            },
            sms: {
              ativo: smsChecked || false,
              mensagem: removeValue.length > 0 ? removeValue : null,
              mensagemLength: countSubmit,
            },
            sequencia: listPhases.length + 1,
            pause:
              values.journeyId.length > 0 && breakChecked === true
                ? isBreaks
                : null,
          };

          const dataUpdate = {
            descricao: values.descricao,
            tempo: values.tempo,
            tipo: values.icone,
            posicao: !!check.checkedSuccess,
            foto: !!check.checkedPhoto,
            assinatura: !!check.checkedAss,
            posicao: check.checkedSuccess,
            foto: check.checkedPhoto,
            assinatura: check.checkedAss,
            nomeRecebedor: check.checkedRecebedor,
            documento: check.checkedDocument,
            sequencia: sequence,
            tipoAtendimento: tab === 0 ? 'ENTREGA' : 'COLETA',
            observacao: values.observacao,
            confirmaPacote: !!check.checkedPackage,
            pulaAtendimento: !!check.checkedSkipServices,
            email: {
              ativo: !!emailChecked,
            },
            link: {
              ativo: !!linkChecked,
            },
            sms: {
              ativo: !!smsChecked,
              mensagem: removeValue.length > 0 ? removeValue : null,
              mensagemLength: countSubmit,
            },
            pause:
              values.journeyId.length > 0 && breakChecked === true
                ? isBreaks
                : null,
          };

          if (update) {
            const response = await api.patch(
              `/delivery/etapas/${update}`,
              dataUpdate
            );

            if (response.status === 204) {
              if (editTemplateId.length > 0) {
                onRegisterTemplate(
                  update,
                  editTemplateId,
                  values,
                  emailChecked,
                  linkChecked
                );
              } else {
                onRegisterTemplate(
                  update,
                  editTemplateId,
                  values,
                  emailChecked,
                  linkChecked
                );
              }

              FrontendNotification(
                t('phases.messageUpdated'),
                NotificationType.SUCCESS
              );
              onLoadPhases();

              setLoading(false);

              // getPhases();
            } else {
              setLoading(false);

              FrontendNotification(
                t('phases.messageErroUpdated'),
                NotificationType.ERROR
              );
            }
          } else {
            const response = await api.post('/delivery/etapas', dataSubmit);

            if (response.status === 201) {
              setLoading(false);

              onRegisterTemplate(
                response.data.id,
                editTemplateId,
                values,
                emailChecked,
                linkChecked
              );

              FrontendNotification(
                t('phases.messageCreated'),
                NotificationType.SUCCESS
              );

              onLoadPhases();
              // getPhases();
            } else {
              setLoading(false);

              FrontendNotification(
                t('phases.messageErroCreated'),
                NotificationType.ERROR
              );
            }
          }
          reset({});
          setValuePhases(0);
          setEdit(false);
          setUpdateId(null);
          setTipo('');
          setChecked({
            checkedSuccess: false,
            checkedAss: false,
            checkedPhoto: false,
          });
          setCheckedSMS(false);
          setCheckedEmail(false);
          setCheckedLink(false);
          setCheckedJourney(false);

          setDescription('');
        } else {
          const message =
            getLanguage() === 'pt' || getLanguage() === 'pt-BR'
              ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
              : 'Character limit exceeded! It is not possible to add the parameter.';

          setErrorMaxLength(message);
        }
      } catch (err) {
        setLoading(false);

        // BackendErrorNotification(err);
      }
    },
    []
  );

  function selectValidation(checked) {
    let form;
    if (
      (checked.checkedSMS && getLanguage() === 'pt') ||
      (checked.checkedSMS && getLanguage() === 'pt-BR')
    ) {
      form = formValidatorPTSMS;
    } else if (
      (checked.checkedSMS && getLanguage() === 'en') ||
      (checked.checkedSMS && getLanguage() === 'en-US')
    ) {
      form = formValidatorENSMS;
    } else if (getLanguage() === 'pt' || getLanguage() === 'pt-BR') {
      form = formValidationPT;
    } else if (getLanguage() === 'en' || getLanguage() === 'en-US') {
      form = formValidationEN;
    }
    return form;
  }

  const formik = useFormik({
    initialValues: {
      descricao: '',
      tempo: '',
      icone: '',
      smsMensagem: '',
      observacao: '',
      templateEmailId: '',
      templateId: '',
      subject: '',
      journeyId: '',
    },
    validationSchema: selectValidation(checked),
    onSubmit: (values, { resetForm }) =>
      handleSubmit(
        values,
        tipo,
        checked,
        dataArr,
        updateId,
        resetForm,
        count,
        sequenceEdit,
        phaseTab,
        checkedSMS,
        checkedEmail,
        checkedLink,
        checkedJourney,
        editTemplate
      ),
  });

  const getUpdateTemplate = useCallback(async (item) => {
    try {
      const data = {
        filters: [
          {
            field: 'etapa.id',
            value: item.id,
            operation: 'EQUAL',
          },
        ],
        orders: [],
        page: 0,
        size: 10,
      };
      const response = await api.post(
        `/delivery/etapas/template/pageable`,
        data
      );

      if (response.data.content.length > 0) {
        formik.setFieldValue(
          'templateEmailId',
          response.data.content[0].templateEmailPadrao !== null &&
            response.data.content[0].templateEmailPadrao.length > 0
            ? response.data.content[0].templateEmailPadrao
            : response.data.content[0].templateEmail !== null
            ? response.data.content[0].templateEmail.id
            : ''
        );
        formik.setFieldValue(
          'templateId',
          response.data.content[0].templateLinkPadrao !== null &&
            response.data.content[0].templateLinkPadrao.length > 0
            ? response.data.content[0].templateLinkPadrao
            : response.data.content[0].templateLink !== null
            ? response.data.content[0].templateLink.id
            : ''
        );
        formik.setFieldValue(
          'subject',
          response.data.content[0].templateEmailAssunto
        );

        setEditTemplate(`${response.data.content[0].id}`);
      }

      formik.setFieldValue('descricao', item.descricao);
      formik.setFieldValue('tempo', item.tempo);
      formik.setFieldValue('observacao', item.observacao);
      if (item.pause !== null) {
        formik.setFieldValue('journeyId', `${item.pause.id}`);
        setCheckedJourney(true);
      }
      if (item.tipo === 'INICIO_ENTREGA') {
        setValuePhases(1);
        setTipo('INICIO_ENTREGA');
        formik.setFieldValue('icone', 'INICIO_ENTREGA');
      } else if (item.tipo === 'SAINDO_ENTREGA') {
        setValuePhases(2);
        setTipo('SAINDO_ENTREGA');
        formik.setFieldValue('icone', 'SAINDO_ENTREGA');
      } else if (item.tipo === 'CONFIRMACAO_ENTREGA') {
        setValuePhases(3);
        setTipo('CONFIRMACAO_ENTREGA');
        formik.setFieldValue('icone', 'CONFIRMACAO_ENTREGA');
      } else if (item.tipo === 'FINALIZACAO_ENTREGA') {
        setValuePhases(4);
        setTipo('FINALIZACAO_ENTREGA');
        formik.setFieldValue('icone', 'FINALIZACAO_ENTREGA');
      }

      setCheckedEmail(!!item.email.ativo);
      setCheckedLink(!!item.link.ativo);

      setSequenceEdit(item.sequencia);
      if (item.sms.mensagem !== null) {
        setDescription(item.sms.mensagem);
        setCount(item.sms.mensagemLength);
        formik.setFieldValue('smsMensagem', item.sms.mensagem);
      }

      setChecked({
        checkedPackage: !!item.confirmaPacote,
        checkedAss: !!item.assinatura,
        checkedPhoto: !!item.foto,
        checkedSkipServices: !!item.pulaAtendimento,
        checkedSuccess: !!item.posicao,
        checkedDocument: !!item.documento,
        checkedRecebedor: !!item.nomeRecebedor,
      });

      setCheckedSMS(!!item.sms.ativo);

      setEdit(true);
      setUpdateId(item.id);
    } catch {}
  }, []);

  const getJourney = useCallback(async () => {
    try {
      const response = await api.get('/workday/pauses');

      setJourneyData(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getTemplate();
    getJourney();
  }, [getTemplate, getJourney]);
  const addTags = useCallback((params, text, countSMS) => {
    const concatText = `${text} ${params.tag}`;

    const maxLength = process.env.MAX_LENGTH_SMS || 132;

    if (countSMS > maxLength) {
      const message =
        getLanguage() === 'pt' || getLanguage() === 'pt-BR'
          ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
          : 'Character limit exceeded! It is not possible to add the parameter.';

      FrontendNotification(message, NotificationType.ERROR);
    } else {
      setCount(prevState => prevState + params.length);

      setDescription(concatText);
      formik.setFieldValue('smsMensagem', concatText);
    }
  }, []);
  return (
    <>
      <Loading loading={loading} />
      {selectParamets && (
        <SelectParameters
          onClear={() => setSelectParamets(!selectParamets)}
          onSelectParameters={item => addTags(item, description, count)}
        />
      )}
      {selectParametersCommunication && (
        <SelectParameters
          onClear={() =>
            setSelectParametersCommunication(!selectParametersCommunication)
          }
          onSelectParameters={item => {
            return FrontendNotification(
              t('template.messageCopied'),
              NotificationType.SUCCESS
            );
          }}
        />
      )}
      <div className="d-flex">
        <BoxForm>
          <InputCustom
            type="text"
            title={t('phases.fields.description')}
            name="select"
            id="SelectState"
            placeholder={t('phases.fields.description')}
            onChange={formik.handleChange('descricao')}
            style={{
              ...inputDescription,
            }}
            value={formik.values.descricao}
            touched={formik.touched.descricao}
            error={formik.errors.descricao}
          />
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col>
              <InputCustom
                type="number"
                title={t('phases.fields.time')}
                name="select"
                id="SelectState"
                placeholder={t('phases.placeholder.time')}
                onChange={formik.handleChange('tempo')}
                style={{
                  ...inputForm,
                }}
                value={formik.values.tempo}
                touched={formik.touched.tempo}
                error={formik.errors.tempo}
              />
            </Col>
            <Col>
              {/* <Trans i18nKey="phases.fields.icon" /> */}

              <CustomSelect
                title={t('phases.fields.icon')}
                onSelect={(value, typeSelect) => {
                  setTipo(value);
                  setValuePhases(typeSelect);
                  formik.setFieldValue('icone', value);
                }}
                valueTipo={valuePhases}
              />
              {formik.touched.icone && formik.errors.icone && (
                <p style={{ fontSize: 14, color: 'red' }}>
                  {formik.errors.icone}
                </p>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Label style={{ ...labelStyle }}>
                <Form.Group>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}
                  >
                    <Form.Label>
                      <Trans i18nKey="phases.fields.observation" />
                    </Form.Label>

                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        float: 'right',
                      }}
                    >
                      <p>({countObservation}/100)</p>
                    </div>
                  </div>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    // id="observation"
                    style={{
                      border: '2px solid rgba(0,48,73,0.2)',
                      borderRadius: 10,
                      marginBottom: 0,
                      width: '100%',
                    }}
                    maxLength={100}
                    onChange={e => {
                      setCountObservation(e.target.value.length);
                      formik.setFieldValue('observacao', e.target.value);
                    }}
                    value={formik.values.observacao}
                  />
                </Form.Group>
                {formik.touched.observacao && formik.errors.observacao && (
                  <p style={{ fontSize: 14, color: 'red' }}>
                    {formik.errors.observacao}
                  </p>
                )}
              </Label>
            </Col>
          </Row>
          <Label>
            <div
              style={{
                borderBottom: '2px solid #FF2E5D',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
              id="options"
              onClick={() => setOptions(!options)}
            >
              <div>
                <span
                  style={{ fontSize: 14, color: '#FF2E5D', fontWeight: 'bold' }}
                >
                  Opções
                </span>
              </div>
              <div>
                <img src={options ? orderTop : orderBottom} alt="" />
              </div>
            </div>
            {options && (
              <div className="boxOptions">
                <div style={{ ...containerSwitch }}>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>
                      <Trans i18nKey="phases.fields.posicao" />
                    </Label>
                    <Switch
                      active={checked.checkedSuccess}
                      fieldId="checkedPosition"
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedSuccess: !checked.checkedSuccess,
                        })
                      }
                    />
                  </Col>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Label style={{ marginRight: '40px' }}>
                      <Trans i18nKey="phases.fields.photo" />
                    </Label>
                    <Switch
                      active={checked.checkedPhoto}
                      fieldId="checkedPhoto"
                      onChange={() =>
                        setChecked({
                          ...checked,

                          checkedPhoto: !checked.checkedPhoto,
                        })
                      }
                    />
                  </Col>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Label style={{ marginRight: '40px' }}>
                      <Trans i18nKey="phases.fields.signatures" />
                    </Label>
                    <Switch
                      active={checked.checkedAss}
                      fieldId="checkedSignatures"
                      onChange={() =>
                        setChecked({
                          ...checked,

                          checkedAss: !checked.checkedAss,
                        })
                      }
                    />
                  </Col>
                </div>
                <div style={{ ...containerSwitch }}>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>
                      <Trans i18nKey="phases.fields.confirmPackages" />
                    </Label>
                    <Switch
                      active={checked.checkedPackage}
                      fieldId="checkedPackage"
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedPackage: !checked.checkedPackage,
                        })
                      }
                    />
                  </Col>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>
                      <Trans i18nKey="phases.fields.skipServices" />
                    </Label>
                    <Switch
                      active={checked.checkedSkipServices}
                      fieldId="checkedSkipServices"
                      onChange={() =>
                        setChecked({
                          ...checked,
                          checkedSkipServices: !checked.checkedSkipServices,
                        })
                      }
                    />
                  </Col>
                </div>
              </div>
            )}
            <div
              style={{
                borderBottom: '2px solid #FF2E5D',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 20,
                cursor: 'pointer',
              }}
              id="communication"
              onClick={() => setCommunication(!communication)}
            >
              <div>
                <span
                  style={{ fontSize: 14, color: '#FF2E5D', fontWeight: 'bold' }}
                >
                  Comunicação
                </span>
              </div>
              <div>
                <img src={communication ? orderTop : orderBottom} alt="" />
              </div>
            </div>
            {communication && (
              <div className="boxOptions">
                <div style={{ ...containerSwitch }}>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>Email</Label>
                    <Switch
                      active={checkedEmail}
                      fieldId="checkedEmail"
                      onChange={() => setCheckedEmail(!checkedEmail)}
                    />
                  </Col>
                  {checkedEmail && (
                    <Col md={8}>
                      <SelectCustom
                        type="select"
                        title={t('phases.fields.template')}
                        name="select"
                        id="templateEmail"
                        placeholder={t('phases.placeholder.time')}
                        onChange={formik.handleChange('templateEmailId')}
                        style={{
                          ...inputDescription,
                          fontWeight: 'normal',
                        }}
                        value={formik.values.templateEmailId}
                        touched={formik.touched.templateEmailId}
                        error={formik.errors.templateEmailId}
                      >
                        <option label={t('phases.placeholder.template')} />
                        <option
                          label={t('phases.placeholder.deliveryExited')}
                          value="SAIU_ENTREGA"
                        />
                        <option
                          label={t('phases.placeholder.orderArrived')}
                          value="PEDIDO_CHEGOU"
                        />
                        {template.map(item => (
                          <option label={item.nome} value={item.id} />
                        ))}
                      </SelectCustom>
                    </Col>
                  )}
                </div>
                {checkedEmail && (
                  <div style={{ ...containerSwitch }}>
                    <Col
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          right: 0,
                          float: 'right',
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#EA004C',
                            cursor: 'pointer',
                          }}
                          id="parametersEmail"
                          onClick={() =>
                            setSelectParametersCommunication(
                              !selectParametersCommunication
                            )
                          }
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                      <InputCustom
                        type="text"
                        title={t('phases.fields.subject')}
                        name="select"
                        id="subject"
                        placeholder={t('phases.placeholder.subject')}
                        onChange={formik.handleChange('subject')}
                        style={{
                          ...inputDescription,
                          fontWeight: 'normal',
                        }}
                        value={formik.values.subject}
                        touched={formik.touched.subject}
                        error={formik.errors.subject}
                      />
                    </Col>
                  </div>
                )}

                <div style={{ ...containerSwitch }}>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>
                      <Trans i18nKey="phases.placeholder.link" />
                    </Label>
                    <Switch
                      active={checkedLink}
                      fieldId="checkedLink"
                      onChange={() => setCheckedLink(!checkedLink)}
                    />
                  </Col>
                  {checkedLink && (
                    <Col
                      md={8}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <SelectCustom
                        type="select"
                        title={t('phases.fields.template')}
                        name="select"
                        id="templateLink"
                        placeholder={t('phases.placeholder.time')}
                        onChange={formik.handleChange('templateId')}
                        style={{
                          ...inputDescription,
                          fontWeight: 'normal',
                        }}
                        value={formik.values.templateId}
                        touched={formik.touched.templateId}
                        error={formik.errors.templateId}
                      >
                        <option label={t('phases.placeholder.template')} />
                        <option
                          label={t('phases.placeholder.deliveryExited')}
                          value="SAIU_ENTREGA"
                        />
                        <option
                          label={t('phases.placeholder.orderArrived')}
                          value="PEDIDO_CHEGOU"
                        />
                        {template.map(item => (
                          <option label={item.nome} value={item.id} />
                        ))}
                      </SelectCustom>
                    </Col>
                  )}
                </div>

                <div style={{ ...containerSwitch }}>
                  <Col
                    md={4}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Label style={{ ...labelSwitch }}>
                      <Trans i18nKey="phases.placeholder.sms" />
                    </Label>
                    <Switch
                      active={checkedSMS}
                      fieldId="checkedSMS"
                      onChange={() => setCheckedSMS(!checkedSMS)}
                    />
                  </Col>
                </div>

                {checkedSMS ? (
                  <Label style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'relative',
                        width: '100%',
                      }}
                    >
                      <Label>
                        <Trans i18nKey="phases.smsMessage" /> ({count}/132)
                      </Label>

                      <div
                        style={{
                          position: 'absolute',
                          right: 0,
                          float: 'right',
                        }}
                      >
                        <p
                          style={{
                            fontFamily: 'Lato',
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: '#EA004C',
                            cursor: 'pointer',
                          }}
                          onClick={() => setSelectParamets(!selectParamets)}
                        >
                          <Trans i18nKey="phases.parameters" />
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        backgroundColor: '#fff',
                        borderRadius: 10,
                        height: 129,
                      }}
                    >
                      <InputTag
                        tagifyRef={tagRef}
                        settings={{
                          duplicates: true,
                        }}
                        onChange={e => {
                          setDescription(e.detail.value);
                          formik.setFieldValue('smsMensagem', e.detail.value);
                          const maxLength = process.env.MAX_LENGTH_SMS || 132;

                          if (e.detail.value.length > maxLength) {
                            const message =
                              getLanguage() === 'pt' ||
                              getLanguage() === 'pt-BR'
                                ? 'Limite de caracteres excedido! Não é possível adicionar o parâmetro.'
                                : 'Character limit exceeded! It is not possible to add the parameter.';

                            setErrorMaxLength(message);
                          } else {
                            setErrorMaxLength('');
                          }
                        }}
                        onInput={e => {
                          const value = e.detail.textContent;
                          console.log(value);

                          if (value.length === 0) {
                            setCount(0);
                          }
                        }}
                        onKeydown={e => {
                          console.log(e.detail);
                          const key =
                            e.detail.event.keyCode ||
                            e.detail.event.charCode;

                          if (key == '8') {
                            setCount(prevState => {
                              if (prevState === 0) {
                                return 0;
                              }
                              return prevState - 1;
                            });
                          } else if (
                            key == '18' ||
                            key == '17' ||
                            key == '9' ||
                            key == '16' ||
                            key == '20' ||
                            key == '3'
                          ) {
                            setCount(count);
                          } else if (
                            key !== '8' &&
                            key !== '18' &&
                            key !== '17' &&
                            key !== '9' &&
                            key !== '16' &&
                            key !== '20' &&
                            key !== '3'
                          ) {
                            setCount(prevState => prevState + 1);
                          } else {
                            setCount(0);
                          }
                        }}
                        onRemove={async e => {
                          // onRemoveTag(e);
                          const lengthRemove = await getParameters(
                            e.detail.data.value
                          );
                          setCount(prevState => prevState - lengthRemove);
                        }}
                        value={description}
                      />
                    </div>
                    {formik.touched.smsMensagem &&
                      formik.errors.smsMensagem && (
                        <p style={{ fontSize: 14, color: 'red' }}>
                          {formik.errors.smsMensagem}
                        </p>
                      )}

                    {errorMaxLength !== '' && (
                      <p
                        style={{
                          fontSize: 14,
                          color: 'red',
                        }}
                      >
                        * {errorMaxLength}
                      </p>
                    )}
                  </Label>
                ) : (
                  <></>
                )}
              </div>
            )}

            <div
              style={{
                borderBottom: '2px solid #FF2E5D',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
                marginTop: 20,
              }}
              id="optionsJourney"
              onClick={() => setJourney(!journey)}
            >
              <div>
                <span
                  style={{ fontSize: 14, color: '#FF2E5D', fontWeight: 'bold' }}
                >
                  <Trans i18nKey="phases.journeyTitle" />
                </span>
              </div>
              <div>
                <img src={journey ? orderTop : orderBottom} alt="" />
              </div>
            </div>

            {journey && (
              <div style={{ marginTop: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CheckBox
                    active={checkedJourney}
                    id="checkedJourney"
                    onChecked={() => setCheckedJourney(!checkedJourney)}
                  />
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 14,
                      color: '#003049',
                      opacity: 0.6,
                    }}
                  >
                    <Trans i18nKey="phases.questionJourney" />
                  </span>
                </div>
                <SelectCustom
                  onChange={formik.handleChange('journeyId')}
                  value={formik.values.journeyId}
                  title=""
                  id="journeyPhases"
                  disabled={checkedJourney === false ? true : false}
                >
                  <option label={t('phases.placeholder.breaks')}></option>
                  {journeyData.map(item => (
                    <option label={item.name} value={item.id} />
                  ))}
                </SelectCustom>
              </div>
            )}
          </Label>

          <Footer>
            <Row>
              {edit ? (
                <>
                  <button
                    onClick={() => {
                      formik.resetForm({});
                      setEdit(false);

                      setUpdateId();
                      setEditTemplate('');
                      setCheckedEmail(false);
                      setCheckedLink(false);
                      setCheckedSMS(false);
                      setValuePhases('S');
                      setChecked({
                        checkedSuccess: false,
                        checkedPhoto: false,
                        checkedAss: false,
                      });
                    }}
                    className="button"
                    type="button"
                    id="buttonCancelPhases"
                    style={{
                      width: '45%',
                      background: '#ccc',
                      color: '#000',
                      marginTop: 30,
                    }}
                  >
                    <Trans i18nKey="common.cancel" />
                  </button>
                  <Col>
                    <button
                      onClick={formik.handleSubmit}
                      className="button"
                      id="buttonUpdatePhases"
                      type="button"
                      style={{ marginTop: 30 }}
                    >
                      <Trans i18nKey="phases.update" />
                    </button>
                  </Col>
                </>
              ) : (
                <button
                  onClick={formik.handleSubmit}
                  className="button"
                  id="buttonSavePhases"
                  type="button"
                  style={{
                    marginTop: 30,
                    width: '100%',
                    maxWidth: '100%',
                    height: 35,
                  }}
                >
                  <Trans i18nKey="phases.save" />
                </button>
              )}
            </Row>
          </Footer>
        </BoxForm>
        <div className="table-responsive">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th />
                <th
                  className="titlePhases"
                  style={{ maxWidth: '70%', marginRight: 40 }}
                >
                  <Trans i18nKey="phases.fields.description" />
                </th>
                <th className="titlePhases">
                  <Trans i18nKey="phases.time" />
                </th>
                <th className="titlePhases" style={{ marginLeft: 10 }}>
                  Ass.
                </th>
                <th className="titlePhases">
                  <Trans i18nKey="phases.fields.posicao" />
                </th>

                <th className="titlePhases">
                  <Trans i18nKey="phases.fields.photo" />
                </th>
                <th className="titlePhases">
                  <Trans i18nKey="phases.placeholder.list" />
                </th>
                <th className="titlePhases">
                  <Trans i18nKey="phases.placeholder.skip" />
                </th>
                <th className="titlePhases">
                  <Trans i18nKey="phases.placeholder.sms" />
                </th>

                <th />
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr className="lineGroup">
                  <td>
                    {index === 0 ? (
                      <img
                        src={orderBottom}
                        style={{ cursor: 'pointer' }}
                        onClick={() => onUpdateSequence(data, index, index + 1)}
                        alt=""
                      />
                    ) : index + 1 === data.length ? (
                      <img
                        src={orderTop}
                        onClick={() => onUpdateSequence(data, index, index - 1)}
                        style={{
                          width: 14,
                          height: 8,
                          marginBottom: 10,
                          cursor: 'pointer',
                        }}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <img
                          src={orderTop}
                          onClick={() =>
                            onUpdateSequence(data, index, index - 1)
                          }
                          style={{
                            width: 14,
                            height: 8,
                            marginBottom: 10,
                            cursor: 'pointer',
                          }}
                          alt=""
                        />
                        <img
                          src={orderBottom}
                          onClick={() =>
                            onUpdateSequence(data, index, index + 1)
                          }
                          style={{
                            width: 14,
                            height: 8,
                            cursor: 'pointer',
                          }}
                          alt=""
                        />
                      </div>
                    )}
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                    className="textPhases"
                  >
                    <p>{item.descricao}</p>
                  </td>
                  <td className="textPhases">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '90px',
                      }}
                    >
                      {item.tempo}
                    </div>
                  </td>
                  <td className="textPhases">
                    {formatLanguage(item.assinatura)}
                  </td>
                  <td className="textPhases">
                    <span className="textPhases" style={{ marginLeft: 10 }}>
                      {formatLanguage(item.posicao)}
                    </span>
                  </td>
                  <td className="textPhases">{formatLanguage(item.foto)}</td>
                  <td className="textPhases">
                    {formatLanguage(item.confirmaPacote)}
                  </td>
                  <td className="textPhases">
                    {formatLanguage(item.pulaAtendimento)}
                  </td>

                  <td
                    onClick={() => {
                      if (item.sms.mensagem !== null) {
                        onSMS(item.sms.mensagem);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                    className={item.sms.ativo === true ? 'teste' : 'textPhases'}
                  >
                    {formatLanguage(item.sms.ativo)}
                  </td>

                  <IconColumn>
                    <Button
                      color="link"
                      className="noMargin"
                      onClick={() => {
                        if(!edit) {
                          getUpdateTemplate(item);
                        }
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt=""
                      />
                    </Button>
                    <Button
                      color="link"
                      className="noMargin"
                      onClick={() => {
                        onRemove(item.id);
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt=""
                      />
                    </Button>
                  </IconColumn>
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent:'center' }}>

          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, page) => onPaginatePhase(page)}
          />
          </div>

        </div>
      </div>
    </>
  );
}
