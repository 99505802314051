/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'reactstrap/lib/Modal';
import './styles.scss';

import Pagination from '@material-ui/lab/Pagination';
import Driver from 'components/Driver';
import { Content } from 'components/Driver/styles';
import api from 'services/api';
import addDriver from '../../../assets/images/addDriver.png';
import closeModalIcon from '../../../assets/images/closeIcon.svg';
import ConfirmationDriver from './ConfirmationDriver';
import Filters from './Filters';

export default function ChangeDriver({ idRomaneio, onClear, onChangeCargo }) {
  const [modal, setModal] = React.useState(true);
  const [confirmation, setConfirmation] = useState(false);
  const [registerDriver, setRegisterDriver] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState('nome');
  const [dataChange, setDataChange] = useState({
    cpf: '',
    romaneioId: '',
  });

  function clearNav() {
    setModal(!modal);
    onClear();
  }

  const [driver, setDriver] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filters, setFilters] = useState([]);

  const [totalPages, setTotalPages] = useState(0);

  const [valueSearch, setValueSearch] = useState('');

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  const subsidiaryData = sessionStorage.getItem('subsidiary');

  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const getDriverList = useCallback(async () => {
    try {
      const subsidiaryId = sessionStorage.getItem('subsidiary');
      const currentFilters = [
        {
          field: 'filial.id',
          value: subsidiaryId,
          operation: 'EQUAL',
        },
      ];
      const dataDriver = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/pageable',
        dataDriver
      );

      setDriver(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (err) {}
  }, [filters, currentPage]);

  const paginate = pageNumber => setCurrentPage(pageNumber - 1);

  const onChangeDriver = useCallback((cpf, id) => {
    setConfirmation(!confirmation);
    setDataChange({
      cpf,
      romaneioId: id,
    });
  }, []);

  useEffect(() => {
    getDriverList();
  }, [getDriverList]);

  return (
    <>
      {registerDriver && (
        <Driver
          onClear={() => setRegisterDriver(!registerDriver)}
          onUpload="active"
          onLoadDriver={() => getDriverList()}
        />
      )}
      {confirmation && (
        <ConfirmationDriver
          idRomaneio={dataChange.romaneioId}
          cpfDriver={dataChange.cpf}
          onClear={() => setConfirmation(!confirmation)}
          onConfirm={() => {
            onClear();
            setConfirmation(!confirmation);
            setModal(!modal);
          }}
          onLoad={onChangeCargo}
        />
      )}
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '50%', maxHeight: '50%' }}
      >
        <div className="modalWrapper">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="changeDriver.titleDriver" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>

          <Filters
            options={optionsFilter}
            subsidiaryFilters={subsidiaryData}
            searchText={valueSearch}
            onChangeText={text => setValueSearch(text)}
            onChangeOptions={option => setOptionsFilter(option)}
            onChangeOptionText={optionText => setValueSearch(optionText)}
            onFilters={data => {
              setCurrentPage(0);
              setFilters(data);
            }}
          />

          <button
            type="button"
            className="buttonDriver"
            onClick={() => setRegisterDriver(!registerDriver)}
          >
            <img src={addDriver} className="imgAdd" alt="" />
            <Trans i18nKey="changeDriver.driver" />
          </button>
          <Content className="contentModal">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>
                      <Trans i18nKey="uploadCSV.columns.name" />
                    </th>
                    <th>
                      <Trans i18nKey="uploadCSV.columns.cpf" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {driver.map(item => (
                    <tr
                      className="lineGroup selectDriver"
                      onClick={() => onChangeDriver(item.cpf, idRomaneio)}
                    >
                      <td className="itemDriver">{item.nome}</td>
                      <td
                        className="itemDriver"
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          marginTop: 10,
                        }}
                      >
                        <p>{item.cpf}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Content>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, page) => paginate(page)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
