// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textStylesAPI {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #003049;
}

.activeText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #003049;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/IntegrationsSendGrid/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,cAAA;AACF;;AACA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;EACA,kBAAA;AAEF","sourcesContent":[".textStylesAPI {\n  display: flex;\n  flex-direction: column;\n  font-size: 14px;\n  color: #003049;\n}\n.activeText {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 19px;\n  text-align: center;\n  color: #003049;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
