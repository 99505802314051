import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { dropStyle } from './styles';
import initialDelivery from '../../assets/images/initialDelivery.png';
import saindoEntrega from '../../assets/images/saindoEntrega.png';
import confirmacaoEntrega from '../../assets/images/confirmacaoEntrega.png';
import finalizacaoEntrega from '../../assets/images/finalizacaoEntrega.png';
import orderBottom from '../../assets/images/orderBottom.png';

export default function CustomSelect({ onSelect, title, valueTipo }) {
  const [valuePhases, setValuePhases] = useState('');
  return (
    <div>
      <span>{title}</span>
      <Dropdown style={{ position: 'relative' }}>
        <Dropdown.Toggle
          id="typeIcon"
          style={{
            ...dropStyle,
          }}
        >
          {valueTipo === 1 && (
            <div style={{ display: 'flex' }}>
              <img
                src={initialDelivery}
                style={{
                  marginRight: 10,
                  marginBottom: '20px',
                }}
                alt=""
              />
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 15,
                  fontWeight: 600,
                  color: '#003049',
                }}
              >
                - <span>1</span>
              </p>
            </div>
          )}
          {valueTipo === 2 && (
            <div style={{ display: 'flex' }}>
              <img
                src={saindoEntrega}
                style={{
                  marginRight: 10,
                  marginBottom: '20px',
                }}
                alt=""
              />
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 15,
                  fontWeight: 600,
                  color: '#003049',
                }}
              >
                - <span>2</span>
              </p>
            </div>
          )}
          {valueTipo === 3 && (
            <div style={{ display: 'flex' }}>
              <img
                src={confirmacaoEntrega}
                style={{
                  marginRight: 10,
                  marginBottom: '20px',
                }}
                alt=""
              />
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 15,
                  fontWeight: 600,
                  color: '#003049',
                }}
              >
                - <span>3</span>
              </p>
            </div>
          )}
          {valueTipo === 4 && (
            <div style={{ display: 'flex' }}>
              <img
                src={finalizacaoEntrega}
                style={{
                  marginRight: 10,
                  marginBottom: '20px',
                }}
                alt=""
              />
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 15,
                  fontWeight: 600,
                  color: '#003049',
                }}
              >
                - <span>4</span>
              </p>
            </div>
          )}
          {valueTipo !== 1 &&
            valueTipo !== 2 &&
            valueTipo !== 3 &&
            valueTipo !== 4 && (
              <p
                style={{
                  color: '#000',
                  textAlign: 'center',
                }}
              >
                <Trans i18nKey="phases.select" />
              </p>
            )}
          <div
            style={{
              position: 'absolute',
              right: 0,
              marginRight: 10,
            }}
          >
            <img src={orderBottom} alt="" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ width: '100%' }}>
          <Dropdown.Item
            onClick={() => {
              setValuePhases(0);
              onSelect('', 0);
            }}
          >
            <div style={{ display: 'flex' }}>
              <p>
                <Trans i18nKey="phases.select" />
              </p>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setValuePhases(1);
              onSelect('INICIO_ENTREGA', 1);
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={initialDelivery} style={{ marginRight: 10 }} alt="" />
                <p
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    color: '#003049',
                    marginTop: 10,
                  }}
                >
                  - 1
                </p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setValuePhases(2);
              onSelect('SAINDO_ENTREGA', 2);
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={saindoEntrega} style={{ marginRight: 10 }} alt="" />
                <p
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    color: '#003049',
                    marginTop: 10,
                  }}
                >
                  - 2
                </p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setValuePhases(3);
              onSelect('CONFIRMACAO_ENTREGA', 3);
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={confirmacaoEntrega}
                  style={{ marginRight: 10 }}
                  alt=""
                />
                <p
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    color: '#003049',
                    marginTop: 10,
                  }}
                >
                  - 3
                </p>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setValuePhases(4);
              onSelect('FINALIZACAO_ENTREGA', 4);
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={finalizacaoEntrega}
                  style={{ marginRight: 10 }}
                  alt=""
                />
                <p
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 15,
                    color: '#003049',
                    marginTop: 10,
                  }}
                >
                  - 4
                </p>
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
