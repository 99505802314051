import {
  GetRowIdParams,
  SelectionChangedEvent
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { DetailedRow } from 'components/CreateCargo/types';
import { RowSelection } from 'components/Grid/types';
import InputCustom from 'components/Input';
import CargoPre from 'interfaces/Delivery/CargoPre';
import { FC, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';

interface Props {
  gridRef: any;
  gridColumns: any[];
  gridData: DetailedRow[];
  rowData: any[];
  onGridReady: (value: string) => void;
  setSelectedRows?: (rows: any[]) => void;
  onSubmit: (rows: any[]) => void;
}

const FormFilter: FC<Props> = (props: Props) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<CargoPre[]>([]);


  return(
    <>
    <Row className="tw-mt-4">
          <Col md={10}>
            <InputCustom
              onChange={(e: any) => setSearchText(e.target.value)}
              placeholder="Informe o nº do documento, id de referência do atendimento ou id do cliente"
            />
          </Col>
          <Col md={2}>
            <Button
              className="tw-mt-2 tw-bg-[#EA004C] tw-text-white tw-font-bold tw-border-none tw-bg:hover-[#EA004C]"
              onClick={() => props.onGridReady(searchText)}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Row className="ag-theme-quartz tw-h-80 tw-mt-4">
          <Col md="12">
            <AgGridReact
              ref={props.gridRef}
              rowData={props.gridData}
              columnDefs={props.gridColumns}

              getRowId={(params: GetRowIdParams) => {
                return params.data.id;
              }}
              pagination={true}
              rowSelection={RowSelection.MULTIPLE}
              onSelectionChanged={(params: SelectionChangedEvent) => {
                const rows = params.api.getSelectedRows();
                setSelectedRows(rows);
                // props.setSelectedRows?.(rows);
              }}
            />
          </Col>
        </Row>
        <div className='tw-w-full tw-flex tw-justify-end'>
            <Button className="tw-mt-2 tw-bg-[#EA004C] tw-text-white tw-font-bold tw-border-none tw-bg:hover-[#EA004C]" onClick={() => props.onSubmit(selectedRows)}>
                Adicionar
            </Button>
        </div>
    </>

  )
}

export default FormFilter;