import styled from 'styled-components';

export const BoxFirst = styled.button`
  background: ${props => (props.activePage ? '#ea004c' : '#fff')};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  color: ${props => props.activePage && '#fff'};

  border: ${props => (props.activePage ? 'none' : '1px solid #e2e2e2')};
  align-items: center;
  justify-content: center;
`;
