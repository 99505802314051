import CustomModal from 'components/ModalCustom';
// import { Container } from 'pages/ControlTower/components/Table/styles';
import React, { useState } from 'react';
import { Container } from './styles';

interface Props {
  type: string;
  data?: any;
  onClear?: any;
}

const DetailsLogs: React.FC<Props> = ({ type, data, onClear }: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <CustomModal isOpen isClose={() => onClear()} label={type}>
      <Container
        className="scrollDiv"
        style={{
          border: '2px solid #ccc',
          borderRadius: 10,
          overflow: 'scroll',
          marginTop: 40,
          padding: 20,
          maxHeight: `${window.innerHeight / 2 + 130}px`,
        }}
      >
        {data}
      </Container>
    </CustomModal>
  );
};

export default DetailsLogs;
