import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;

  .footer {
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-left: 0;
  }

  .button {
    height: 35px;
    width: 354px;
    background: #ea004c;
    border-radius: 6px;
    text-align: center;
    color: #ffffff;
    margin-right: 20px;
    /* float: right; */
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);
    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
    }
  }
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const WrapperRating = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.512rem;
`;