import { object, ref, string } from 'yup';

export default object().shape({
  description: string()
    .required('* Enter notification description!')
    .max(255, '*Maximum 255 characters'),
  title: string()
    .required('* Enter the title!')
    .max(128, '*Maximum 128 digits!'),
  date: string().required('* Please enter the sending date!'),
  sendGroup: string().required('* Enter send group'),
});
