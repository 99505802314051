import ReactDatePicker from 'react-datepicker';
import MultiSelect from '@khanacademy/react-multi-select';

import styled, { createGlobalStyle } from 'styled-components';

export const inputForm = {
  height: 35,
  background: '#FFFFFF',
  border: '2px solid rgba(0, 48, 73, 0.2)',
  boxSizing: 'border-box',
  borderRadius: '10px',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  color: '#003049',
  marginTop: '8px',
  paddingLeft: '10px',
};

export const Content = styled.div`
  background-color: #fff;
  border: 2px solid #edf2f4;
  border-radius: 10px;
  padding: 20px;
  width: 120%;
  .lineGroup {
    border-bottom: 1px solid #ccd6db;
  }
`;

export const BoxForm = styled.div`
  width: 70%;
  display: flex;
  /* padding-left: 15%; */
  flex-direction: column;
  margin-right: 20px;
  input[type='text'] {
    height: 35px;
    background: '#FFFFFF';
    border: '2px solid rgba(0, 48, 73, 0.2)';
    box-sizing: 'border-box';
    border-radius: 10;
    font-family: 'Lato';
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 14;
    line-height: 19;
    color: '#003049';
  }
  input[type='textarea'] {
    height: 196;
    background: '#FFFFFF';
    border: '2px solid rgba(0, 48, 73, 0.2)';
    box-sizing: 'border-box';
    border-radius: 10;
    font-family: 'Lato';
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 14;
    line-height: 19;
    color: '#003049';
    margin-top: 8;
    padding-left: 10;
    margin-bottom: 10;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  justify-content: space-around;
  display: flex;
  .table-line {
    margin-top: 20px;
    width: 320px;
  }
`;

export const SendButton = styled.button`
  height: 2.2rem;
  background: #ea004c;
  border-radius: 6px;
  text-align: center;
  color: #ffffff;
  width: 22rem;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  align-self: flex-end;
  border: none;
  box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);

  &:hover {
    background: #b5023b;
    transition: 0.5s;
    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
  }
`;

export const GroupInput = styled.div`
  width: 16.875rem;
  #group {
    height: 60;
    background: '#FFFFFF';
    border: '2px solid rgba(0, 48, 73, 0.2)';
    box-sizing: 'border-box';
    border-radius: 10;
    font-family: 'Lato';
    font-style: 'normal';
    font-weight: 'bold';
    font-size: 14;
    line-height: 19;
    color: '#003049';
    margin-top: 8;
  }
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CustomDatePicker = styled(ReactDatePicker)`
  width: 100%;
  border-radius: 10;
  border: '2px solid rgba(0, 48, 73, 0.2)';
  box-sizing: 'border-box';
  input {
    text-align: center;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid #e3b924;
  }
`;

export const CustomMultiSelect = styled(MultiSelect)``;
