import classNames from 'classnames';
import ModalCreatedCargo from 'components/ModalCreatedCargo';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import permissionMenu from 'shared/Permission';
import { DataRouteringCargas } from 'store/modules/routering/actions';
import errorRoutering from '../../../assets/images/errorRoutering.svg';
import routeCargoRelease from '../../../assets/images/routeCargoRelease.svg';
import successRoutering from '../../../assets/images/successRoutering.svg';
import ConfirmationRouteringCapacity from './ConfirmRouteringCapacity';
import ConfirmationDriver from './ConfirmationDriver';
import ReleaseLoading from './ReleaseLoading';
import './styles.scss';

interface RouteringConfirmProps {
  activeCargo: string;
  dataCargoConfirm: DataRouteringCargas[];
}

interface FormValues {
  descricao: string;
}

export default function RouteringConfirm(props: RouteringConfirmProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationCreateCargo, setConfirmationCreateCargo] = useState(false);
  const [
    confirmationReleaseLoadingCargo,
    setConfirmationReleaseLoadingCargo,
  ] = useState(false);
  const [modalCargo, setModalCargo] = useState<boolean>(false);
  const [dataCargo, setDataCargo] = useState<any>([]);
  const [permission, setPermission] = useState([]);

  const [confirmationCreateCargoCapacity, setConfirmationCreateCargoCapacity] = useState<boolean>(false);

  const userData = useSelector((state: any) => state.auth.user);

  const { t } = useTranslation();

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  function calcPorcentage(valueInitial: number, total: number) {
    const md_val: any = Number(valueInitial).toFixed(2);
    const medidab: any = Number(md_val / total) * 100;
    const formatMedidab: any = parseInt(medidab);
    return formatMedidab;
  }

  function getCalcPorcentage(dataAccumulator: any) {
    const accumulatorAttendances = calcPorcentage(
      dataAccumulator.steps.length,
      dataAccumulator.vehicle.quantidadeAtendimento
    );

    const accumulatorWeight = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.peso);
      },
      0
    );

    const accumulatorVolume = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return (
          acc +
          parseFloat(
            itemAcc.conhecimentoPre.cubagem.length === 0
              ? 0
              : itemAcc.conhecimentoPre.cubagem
          )
        );
      },
      0
    );

    const accumulatorValor = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.valor);
      },
      0
    );

    return {
      porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentage(
        accumulatorWeight,
        dataAccumulator.vehicle.peso
      ),
      porcentageVolume: calcPorcentage(
        accumulatorVolume,
        dataAccumulator.vehicle.cubagem
      ),
      porcentagePrice: calcPorcentage(
        accumulatorValor,
        dataAccumulator.vehicle.valor
      ),
    };
  }

  const onCreateCargo = useCallback(
    async (
      descriptionConfirm: string,
      dataCreateCargo: DataRouteringCargas[]
    ) => {
      try {
        setLoading(true);
        const mappingOptmi = dataCreateCargo[0];

        const data = {
          id: mappingOptmi.simulation.id,
        };
        const response = await api.post(
          '/delivery/romaneios/generate',
          data
        );

        if (response.status === 200) {
          setDataCargo(response.data);
          setModalCargo(!modalCargo);
          const message = t('uploadCSV.messageCreated');

          FrontendNotification(message, NotificationType.SUCCESS);
        }
        setLoading(false);
      } catch (err) {}
    },
    []
  );

  const onCreateSimulation = useCallback(
    async (values: FormValues, dataCreateCargo) => {
      try {
        setLoading(true);

        const data = {
          description: values.descricao,
        };
        const response = await api.patch(
          `/route/simulations/${dataCreateCargo[0].simulation.id}/updateDescription`,
          data
        );

        setLoading(false);

        FrontendNotification(
          'Simulação criada com sucesso',
          NotificationType.SUCCESS
        );

        // dispatch(editSimulatorRoute(false));

        history.push('/dashboard');

        window.location.reload();
      } catch (err) {}
    },
    []
  );

  const onCancelSimulation = useCallback(async () => {
    try {
      history.push('/dashboard');

      window.location.reload();
    } catch (err) {}
  }, []);

  const onReloadCarga = useCallback(async () => {
    try {
      setConfirmationReleaseLoadingCargo(!confirmationReleaseLoadingCargo);

      history.push('/routering');

      window.location.reload();
    } catch (err) {}
  }, []);

  const initialValues: FormValues = {
    descricao:
      props.dataCargoConfirm[props.dataCargoConfirm.length - 1].simulation
        .description || '',
  };

  const formikSimulator = useFormik({
    initialValues,
    onSubmit: values => onCreateSimulation(values, props.dataCargoConfirm),
  });
  return (
    <React.Fragment>
      <Loading loading={loading} />
      {confirmationCreateCargo && (
        <ConfirmationDriver
          cargas={props.dataCargoConfirm.length}
          onClear={() => setConfirmationCreateCargo(!confirmationCreateCargo)}
          onConfirm={() => {
            setConfirmationCreateCargo(!confirmationCreateCargo);

            onCreateCargo(
              formikSimulator.values.descricao,
              props.dataCargoConfirm
            );
          }}
        />
      )}
      {confirmationCreateCargoCapacity && (
        <ConfirmationRouteringCapacity 
        cargas={props.dataCargoConfirm.length}
        onClear={() => setConfirmationCreateCargoCapacity(!confirmationCreateCargoCapacity)}
        onConfirm={() => {
          setConfirmationCreateCargoCapacity(!confirmationCreateCargoCapacity);
          onCreateCargo(formikSimulator.values.descricao, props.dataCargoConfirm);
        }}
        />
      )}
      {confirmationReleaseLoadingCargo && (
        <ReleaseLoading
          onClear={() => onReloadCarga()}
          data={props.dataCargoConfirm}
        />
      )}
      {modalCargo && (
        <ModalCreatedCargo
          onClear={() => {
            setModalCargo(!modalCargo);
          }}
          data={dataCargo}
        />
      )}
      <div
        className={classNames('routeringConfirm containerRouteringConfirm', {
          'is-open': props.activeCargo === 'check',
        })}
      >
        <div className="side-menu">
          <input
            placeholder="Nomear simulação"
            className="inputRoutering"
            onChange={formikSimulator.handleChange('descricao')}
            value={formikSimulator.values.descricao}
          />
          <button
            type="button"
            className="buttonSaveRoutering"
            onClick={() => formikSimulator.handleSubmit()}
          >
            <Trans i18nKey="routeOptmization.simulation.buttonSave" />
          </button>
          <button
            type="button"
            className="buttonCreateRoutering"
            onClick={() => {
              const dataMappingRoute: DataRouteringCargas[] = props.dataCargoConfirm;

              const mappingRoutering = dataMappingRoute.map((item: DataRouteringCargas) => {
                return {
                  ...getCalcPorcentage(item)
                }
              });

              const capacityAttendances = mappingRoutering.find((item: any) => item.porcentageAttendances > 100);
              const capacityWeight = mappingRoutering.find((item: any) => item.porcentageWeight > 100);
              const capacityCubage = mappingRoutering.find((item: any) => item.porcentageVolume > 100);
              const capacityPrice = mappingRoutering.find((item: any) => item.porcentagePrice > 100);

              console.log("Attendances", capacityAttendances);
              console.log("Weight", capacityWeight);
              console.log("Cubage", capacityCubage);
              console.log("Price", capacityPrice);

              if (permissionMenu(userData.grupo.permissoes, 'routering.create-route')) {
                if(capacityAttendances || capacityWeight || capacityCubage || capacityPrice) {
                  setConfirmationCreateCargoCapacity(!confirmationCreateCargoCapacity);
                } else {
                  setConfirmationCreateCargo(!confirmationCreateCargo);
                }
              } else {
                FrontendNotification(
                  t('common.permission'),
                  NotificationType.WARNING
                );
              }
            }}
          >
            <img src={successRoutering} className="iconRoutering" alt="" />
            <Trans i18nKey="routeOptmization.simulation.buttonCreateCarga" />
          </button>

          <button
            type="button"
            className="buttonReleaseLoadingRoutering"
            onClick={() => {
              if (permissionMenu(userData.grupo.permissoes, 'routering.release-loading')) {
                setConfirmationReleaseLoadingCargo(
                  !confirmationReleaseLoadingCargo
                );
              } else {
                FrontendNotification(
                  t('common.permission'),
                  NotificationType.WARNING
                );
              }
            }}
          >
            <img src={routeCargoRelease} className="iconRoutering" alt="" />
            <Trans i18nKey="routeOptmization.simulation.buttonReleaseLoadingCarga" />
          </button>
          <button
            type="button"
            className="buttonCancelRoutering"
            onClick={() => onCancelSimulation()}
          >
            <img src={errorRoutering} className="iconRoutering" alt="" />
            <Trans i18nKey="routeOptmization.simulation.buttonCancelSimulation" />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
