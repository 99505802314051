import CustomModal from 'components/ModalCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClear: () => void;
  message: string;
}

const DetailsLog: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  return (
    <CustomModal isOpen label={t('zenvia.details')} isClose={props.onClear} styles={{ minWidth: '60%' }}>
      <div className='tw-flex tw-p-6 tw-w-full tw-h-80 tw-rounded-lg' style={{ border: '2px solid #EDF2F4' }}>
        <span>{props.message}</span>
      </div>
    </CustomModal>
  );
}

export default DetailsLog;