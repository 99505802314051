import React from 'react';

export default function Fields() {
  return (
    <div>
      <ul>
        <li>Button</li>
        <li>CheckBox</li>
        <li>Select</li>
        <li>Input</li>
      </ul>
    </div>
  );
}
