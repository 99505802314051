import SingleSelectVehicles from 'components/SelectVehicles/SingleSelectVehicles';
// import CheckBox from 'pages/ControlTower/ListAttendances/Filters/CheckBox';
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import api from 'services/api';
import addDriver from '../../../../assets/images/addDriver.png';
import deleteDriver from '../../../../assets/images/deleteDriver.png';
import iconAddress from '../../../../assets/images/iconAddress.png';
import markOff from '../../../../assets/images/markOff.svg';
import removeAll from '../../../../assets/images/removeAllRegister.svg';
import SelectVehicles from '../../../../components/SelectVehicles';
import { IElementsCSV } from '../Table';
import Totalizator from '../Totalizator';

import './styles.scss';

interface Props {
  onChangeDriver?: any;
  onClearFilters?: any;
  onRemove?: any;
  onChangeVehicles?: any;
  dataFilial?: any;
  onPageSize?: any;
  dataParameters?: any;
  onModalAddress?: any;
  onRemoveAll?: any;
  onSelectAll?: any;
  onRemoveSelectAll?: any;
  parameters?: any;
  dataVehicles?: any;
  onSelectedShipment: (idsSelected: any[]) => void;
  onUpdateSelected?: () => void;
  onUpdateVehicles: () => void;
  dataElementsHeader?: IElementsCSV[] | any;
  totalItems: number;
}

const Header: React.FC<Props> = ({
  onChangeDriver,
  onClearFilters,
  onRemove,
  onChangeVehicles,
  dataFilial,
  onPageSize,
  dataParameters,
  onModalAddress,
  onRemoveAll,
  onSelectAll,
  onRemoveSelectAll,
  parameters,
  dataVehicles,
  onSelectedShipment,
  onUpdateSelected,
  onUpdateVehicles,
  dataElementsHeader,
  totalItems,
}: Props) => {
  const [selectDrive, setSelectDrive] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  // const [selectedShipment, setSelectedShipment] = useState();

  const getSelectedItem = useCallback(async () => {
    try {
      const response = await api.get('/delivery/csvMapping/cargo/selected');

      const idsSelected = response.data.map((item: any) => item.id);

      setTotalSelected(response.data.length);

      return idsSelected;
    } catch (err) {}
  }, []);

  const onCheckAddItem = useCallback(async (dataSelected: any) => {
    try {
      const mappingSelectedRows = dataSelected.map((itemSelected: any) => {
        return itemSelected.columns[0].data;
      });

      const data = {
        action: 'ADD',
        ids: mappingSelectedRows,
      };

      const response = await api.post('/delivery/csvMapping/check/item', data);
    } catch {}
  }, []);

  const onCheckRemoveItem = useCallback(
    async (dataSelected: any, dataArr: any) => {
      try {
        const mappingSelectedRows = dataArr
          .filter(
            (itemFilterSelected: any) => itemFilterSelected.active !== true
          )
          .map((itemSelected: any) => {
            return itemSelected.columns[0].data;
          });

        const data = {
          action: 'REMOVE',
          ids: mappingSelectedRows,
        };

        const response = await api.post(
          '/delivery/csvMapping/check/item',
          data
        );
      } catch {}
    },
    []
  );

  return (
    <div>
      {selectDrive && (
        <SingleSelectVehicles
          filialId={dataFilial}
          onClear={() => setSelectDrive(!selectDrive)}
          onChangeVehicles={(vehicleId: number, vehicleName: string) =>
            onChangeDriver(vehicleId, vehicleName)
          }
          dataCargoValue={undefined}
          onVehiclesSelected={undefined}
          onSelectVehicles={undefined}
        />
      )}
      {selectedVehicles && (
        <SelectVehicles
          // dataCargoValue={accumulatorRouteringValue}
          filialId={dataFilial}
          onClear={() => setSelectedVehicles(!selectedVehicles)}
          onVehiclesSelected={(vehiclesArr: any) => {
            setSelectedVehicles(!selectedVehicles);
            onChangeVehicles(vehiclesArr);
          }}
          dataCargoValue={undefined}
        />
      )}
      <div className="headerCorrect">
        <div className="row">
          <span className="titleCorrect">
            <Trans i18nKey="uploadCSV.title" />
          </span>
          <button
            type="button"
            className="buttonFilter"
            onClick={() => {
              onUpdateVehicles();
              dataParameters.length > 0 &&
              dataParameters[0].roteirizacaoAtiva === false
              ? setSelectDrive(!selectDrive)
              : setSelectedVehicles(!selectedVehicles)
            }
            }
            id="addVehicles"
            style={{ width: 120, height: 35 }}
          >
            <img src={addDriver} alt="" />
            <span className="buttonText">Veículos</span>
          </button>

          <button
            type="button"
            className="buttonFilter"
            onClick={onRemove}
            id="removeRecord"
          >
            <img src={deleteDriver} alt="" />
            <span className="buttonText">
              <Trans i18nKey="uploadCSV.buttons.remove" />
            </span>
          </button>
          <button
            type="button"
            className="buttonFilter"
            onClick={onSelectAll}
            id="selectAll"
          >
            <img src={addDriver} alt="" />

            <span className="buttonText">
              <Trans i18nKey="uploadCSV.buttons.selectAll" />
            </span>
          </button>
          <button
            type="button"
            className="buttonFilter"
            onClick={onModalAddress}
            id="changeAddress"
          >
            <img src={iconAddress} alt="" />
            <span className="buttonText">
              <Trans i18nKey="uploadCSV.buttons.address" />
            </span>
          </button>
        </div>
        <div className="row" style={{ marginTop: 20, marginLeft: '2.20rem' }}>
          <div style={{ marginLeft: 60 }} />
          <button
            type="button"
            className="buttonFilter"
            onClick={onRemoveAll}
            id="removeAll"
          >
            <img src={removeAll} alt="" />
            <span className="buttonText">
              <Trans i18nKey="uploadCSV.buttons.removeAll" />
            </span>
          </button>
          <button
            type="button"
            className="buttonFilter"
            onClick={onRemoveSelectAll}
          >
            <img src={markOff} alt="" />
            <span className="buttonText">
              <Trans i18nKey="uploadCSV.buttons.markOff" />
            </span>
          </button>
          <span className="cleanFiltersCSV" onClick={onClearFilters} id="clearButton">
            Limpar filtros
          </span>
        </div>

        <Totalizator />
        {parameters.length > 0 && parameters[0]?.roteirizacaoAtiva === true && (
          <div style={{ position: 'absolute', top: -140 }}></div>
        )}

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {parameters.length > 0 && parameters[0]?.roteirizacaoAtiva === true && (
            <div style={{ marginTop: 30 }}>
              <span className="titleHeaderTable" style={{ fontSize: 16 }}>
                <Trans i18nKey="common.totalVehicles" /> {dataVehicles.length}
              </span>
            </div>
          )}
          <div style={{ marginTop: 30 }}>
            {/* <CheckBox
              active={itemSelected}
              onChecked={async () => {
                const dataFilterSelectedRows = sessionStorage.getItem(
                  'selectedRows'
                );
                setItemSelected(!itemSelected);

                // await onUpdateSelected();

                if (dataFilterSelectedRows) {
                  const selectRows = JSON.parse(dataFilterSelectedRows);
                  await onCheckAddItem(selectRows);
                  await onCheckRemoveItem(selectRows, dataElementsHeader);
                }

                let selected: boolean = itemSelected;

                let selectedShipment;

                if (selected === false) {
                  selectedShipment = await getSelectedItem();
                }

                onSelectedShipment(selectedShipment);
              }}
              label={`Exibir selecionados (${totalSelected})`}
              id=""
              styles={{}}
            /> */}
          </div>
          <div style={{ marginTop: 30 }}>
            <span className="titleHeaderTable" style={{ fontSize: 16 }}>
              Total de registros: {totalItems}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
