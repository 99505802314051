import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

// Converte uma string númerica em um Number.
export const strToNumber = (value: string): number => {
  if (value != null) {
    return Number(value.replace(/,/g, ''));
  }

  return 0;
};

// Converte um número em moeda brasileira.
export const numberToCurrencyBRL = (value: number): string => {
  const formatted: string = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatted;
};

// Formata um número decimal no padrão brasileiro.
export const formatNumberBR = (number: number): string => {
  return number.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatDateTime = (date: string) => {
  const dateFormat = format(new Date(date), 'dd/MM/yyyy HH:mm:ss', {
    locale: ptBR
  });

  return dateFormat;
}

// Formata um número decimal no padrão brasileiro.
export const formatNumber = (number: number): string => {
//   const valor: string = number.replace(',', '.');
  return number.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
  });
}

// Formata um Date para a formatação do idioma.
export const formatDate = (date: Date) => {
  // Defina as opções para formatar apenas a data
  const formatOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return date.toLocaleString(undefined, formatOption);
};

export const getFormatDistanceSteps = (distance: any) => {
  const formatDistanceCalc: any = distance / 1000;

  const convertDistance = parseInt(formatDistanceCalc);

  return convertDistance;
};
