import Pagination from '@material-ui/lab/Pagination';
import ViewDetailLogsIcon from 'assets/images/viewDetailsLogs.svg';
import InputCustom from 'components/Input';
import { ModalTitle } from 'components/ModalCustom/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Label } from 'reactstrap/lib';
import erroIcon from '../../assets/images/erroIcon.png';
import successIconTable from '../../assets/images/successIconTable.png';
import DetailsLogs from './DetailsLogs';
import { Container, Filters } from './styles';
import './styles.scss';

interface LogsData {
  id: number;
  dataCriacao: string;
  dataModificacao: string;
  authorization: string;
  contentType: string;
  requestData: string;
  responseData: string;
  statusCode: number;
  uri: string;
  event?: string;
}

interface Props {
  data: LogsData[];
  totalPages: number;
  page: number;
  onPaginate?: any;
  onFilters?: any;
  isWebhook?: boolean;
}

type FormValues = {
  dateCreated: string;
  status: string;
};

const Logs: React.FC<Props> = ({
  data,
  totalPages,
  page,
  onPaginate,
  onFilters,
  isWebhook,
}: Props) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [dataDetails, setDataDetails] = useState('');
  const [typeDetailsLogs, setTypeDetailsLogs] = useState('');
  const [dateCreate, setDateCreate] = useState();
  const [status, setStatus] = useState();

  const initialValues: FormValues = {
    dateCreated: '',
    status: '',
  };

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });
  return (
    <>
      {viewDetails && (
        <DetailsLogs
          type={typeDetailsLogs}
          onClear={() => setViewDetails(!viewDetails)}
          data={dataDetails}
        />
      )}
      <Container>
        <ModalTitle>Logs</ModalTitle>
        <Filters>
          <Label style={{ display: 'flex', flexDirection: 'column' }}>
            Data
            <input
              type="date"
              className="initialHoursTurno"
              placeholder={t('integrationsBling.logs.fields.date')}
              onChange={formik.handleChange('dateCreated')}
              value={formik.values.dateCreated}
              // disabled={listBreakDriver.length > 0 ? true : false}
              style={{
                width: '154px',

                borderRadius: 10,
                border: '2px solid rgba(0,48,73,0.2)',
                height: 35,
              }}
            />
          </Label>
          <InputCustom
            title=""
            onChange={formik.handleChange('status')}
            placeholder="Status"
            style={{ width: 119, marginTop: 24, marginLeft: 30 }}
          />

          <button
            className="button"
            style={{ marginTop: 32, marginLeft: 20, width: '120px' }}
            onClick={() =>
              onFilters(formik.values.dateCreated, formik.values.status)
            }
          >
            <Trans i18nKey="integrationsBling.logs.buttonFilter" />
          </button>
        </Filters>

        <table className="tableLog">
          <thead>
            <tr>
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  <Trans i18nKey="zenvia.dateTime" />
                </span>
              </th>
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  URI
                  {/* <Trans i18nKey="zenvia.dateTime" /> */}
                </span>
              </th>
              {isWebhook && (
                <th>
                  <span className="tableTitle" style={{ fontSize: 16 }}>
                    Evento
                    {/* <Trans i18nKey="zenvia.dateTime" /> */}
                  </span>
                </th>
              )}
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  Content-type
                  {/* <Trans i18nKey="zenvia.dateTime" /> */}
                </span>
              </th>
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  <Trans i18nKey="integrationsBling.logs.fields.authorization" />
                </span>
              </th>
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  Request data
                  {/* <Trans i18nKey="zenvia.dateTime" /> */}
                </span>
              </th>
              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  Response data
                  {/* <Trans i18nKey="zenvia.dateTime" /> */}
                </span>
              </th>

              <th>
                <span className="tableTitle" style={{ fontSize: 16 }}>
                  Status
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item: LogsData) => (
              <tr className="lineGroup">
                <td>{item.dataCriacao}</td>
                <td>
                  <div className="containerLogs">
                    <p className="uriLimit">{item.uri}</p>
                    <img
                      className="buttonViewDetailsLogs"
                      src={ViewDetailLogsIcon}
                      onClick={() => {
                        setTypeDetailsLogs('URI');
                        setDataDetails(item.uri);
                        setViewDetails(!viewDetails);
                      }}
                      alt=""
                    />
                  </div>
                </td>
                {isWebhook && (
                  <td>
                    <div className="containerLogs">
                      <p className="uriLimit">{item.event}</p>
                    </div>
                  </td>
                )}
                <td>
                  <div className="containerLogs">
                    <span>
                      {item.contentType && item.contentType.length > 0
                        ? item.contentType
                        : 'application/json'}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="containerLogs">
                    <span className="uriLimit">{item.authorization}</span>
                    {item.authorization && item.authorization.length > 0 ? (
                      <img
                        className="buttonViewDetailsLogs"
                        src={ViewDetailLogsIcon}
                        onClick={() => {
                          setTypeDetailsLogs('Authorization');
                          setDataDetails(item.authorization);
                          setViewDetails(!viewDetails);
                        }}
                        alt=""
                      />
                    ) : (
                      <span className="valueEmpty"> --- </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="containerLogs">
                    <span className="uriLimit">{item.requestData}</span>
                    {item.requestData && item.requestData.length > 0 ? (
                      <img
                        className="buttonViewDetailsLogs"
                        src={ViewDetailLogsIcon}
                        onClick={() => {
                          setTypeDetailsLogs('Request Data');
                          setDataDetails(item.requestData);
                          setViewDetails(!viewDetails);
                        }}
                        alt=""
                      />
                    ) : (
                      <span className="valueEmpty"> --- </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="containerLogs">
                    <p className="uriLimit">{item.responseData}</p>
                    {item.responseData && item.responseData.length > 0 ? (
                      <img
                        className="buttonViewDetailsLogs"
                        onClick={() => {
                          setTypeDetailsLogs('Response data');
                          setDataDetails(item.responseData);
                          setViewDetails(!viewDetails);
                        }}
                        src={ViewDetailLogsIcon}
                        alt=""
                      />
                    ) : (
                      <span className="valueEmpty"> --- </span>
                    )}
                  </div>
                </td>
                <td>
                  <span style={{ marginTop: 10 }}> {item.statusCode}</span>

                  {item.statusCode === 200 ||
                  item.statusCode === 201 ||
                  item.statusCode === 202 ||
                  item.statusCode === 204 ? (
                    <img
                      src={successIconTable}
                      style={{ marginLeft: 5 }}
                      alt=""
                    />
                  ) : (
                    <img src={erroIcon} style={{ marginLeft: 5 }} alt="" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, page) => onPaginate(page)}
          />
        </div>
      </Container>
    </>
  );
};

export default Logs;
