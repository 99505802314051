import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 0.313rem; */
  position: absolute;
  top: 1.212rem;
  left: 0.612rem;
`;

export const CreationDate = styled.div`
  display: flex;
  align-items: center;
`;

export const Hour = styled.div`
  font-family: "Lato";
  font-weight: 700;
  color: #EA004C;
  font-size: 0.813rem;
  line-height: 1rem;
  font-style: normal;
  text-align: right;
  margin-right: 0.438rem;
`;

export const IconViewAlert = styled.img`
  cursor: pointer;
`;