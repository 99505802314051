// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optionsMenu {
  padding: 0.71rem;
  cursor: pointer;
}

.optionsMenu:hover {
  background-color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/components/Table/RowTable/styles.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,eAAA;AADF;;AAMA;EACE,2BAAA;AAHF","sourcesContent":["\n\n.optionsMenu {\n  padding: 0.710rem;\n  cursor: pointer;\n\n  \n}\n\n.optionsMenu:hover{\n  background-color: lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
