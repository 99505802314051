import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import api from '../../services/api';
import { urlResetPassword } from '../../services/endpoint';
import { BackendErrorNotification, FrontendNotification, NotificationType } from 'core/common/Notification';
import BaseTemplate from '../../template/baseTemplate';
import Input from '../../components/form-teste/input';
import { Trans, withTranslation } from 'react-i18next';
import Button from '../../components/form-teste/button';
import BaseComponents from '../../template/BaseComponents';
import { isValidPassword, isValidPasswordConfirmation } from '../../util/validationUtil';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../constants/field';
import Form from '../../components/form-teste/form';
import {
  Line,
  SignUpTitle,
  TitleContainer,
  LabelInput,
} from './styles';

class ResetPassword extends BaseComponents {
    initialState = {
        uuid: '',
        password: '',
        passwordConfirmation: ''
    }
    state = this.initState(this.initialState);

    handleSubmit = e => {
        e.preventDefault();

        // Get UUID
        const search = this.props.location.search;
        const params = new URLSearchParams(search);
        const uuidValue = params.get('uuid');

        let data = this.getData();
        data.uuid = uuidValue;

        if (this.isValid(data)) {
            this.showLoading(true);

            api.post(urlResetPassword, data)
                .then(res => {
                    this.handleFormReset();
                    const message = this.props.t("resetPassword.passwordUpdated");
                    FrontendNotification(message, NotificationType.SUCCESS);
                    this.props.history.push("/");
                })
                .catch(error => {
                    BackendErrorNotification(error);
                })
                .finally(() => {
                    this.showLoading(false);
                });
        }
    }

    isValid(data) {
        let message = null;

        if (!data.password) {
            message = this.props.t('form.field.message.passwordRequired');
            FrontendNotification(message, NotificationType.WARNING);
            return false;
        }

        if (!isValidPassword(data.password)) {
            const password = {
                minimumSize: PASSWORD_MIN_LENGTH
            };
            message = this.props.t('form.field.message.passwordMinimunSize', { password });
            FrontendNotification(message, NotificationType.WARNING);
            return false;
        }

        if (!data.passwordConfirmation) {
            message = this.props.t('form.field.message.passwordConfirmationRequired');
            FrontendNotification(message, NotificationType.WARNING);
            return false;
        }

        if (!isValidPasswordConfirmation(data.password, data.passwordConfirmation)) {
            message = this.props.t('message.field.invalidPasswordConfirmation');
            FrontendNotification(message, NotificationType.WARNING);
            return false;
        }

        return true;
    }

    getData = () => {
        return {
            uuid: this.state.uuid,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation
        };
    }

    handleFormReset = () => {
        this.setState(this.initialState);
    }

    render() {
        return (
            <BaseTemplate loading={this.getStatusLoading()} >

              <div className="form-forget">
              <TitleContainer>
            <SignUpTitle className="text-center">
              {this.props.t('resetPassword.title')}
            </SignUpTitle>
            <Line />
          </TitleContainer>


                <Form onSubmit={this.handleSubmit}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                <p className="text-center">{this.props.t("resetPassword.instruction")}</p>
              </div>
              <LabelInput><Trans i18nKey="form.field.newPassword" /></LabelInput>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input.Password className="form-control"
                                    placeholder={this.props.t("form.field.password")}
                                    onChange={e => this.setState({ password: e.target.value })}
                                    value={this.state.password} />
                            </div>
                        </div>
                    </div>
                    <LabelInput><Trans i18nKey="form.field.confirmPassword" /></LabelInput>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input.Password
                                    className="form-control"
                                    placeholder={this.props.t("form.field.confirmPassword")}
                                    onChange={e => this.setState({ passwordConfirmation: e.target.value })}
                                    value={this.state.passwordConfirmation}
                                    maxLength={PASSWORD_MAX_LENGTH} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Button className="btn btn-success btn-block">{this.props.t("button.update")}</Button>
                            </div>
                        </div>
                    </div>


                </Form>
                </div>
            </BaseTemplate>
        );
    }
}

export default withRouter(withTranslation()(ResetPassword));
