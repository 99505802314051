import { LANGUAGE } from "constants/language";

export const today = () => {
    let d = new Date();
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
}

/**
 * Formata um datetime de acordo com o idioma utilizado.
 * 
 * @param {*} dateTime 
 * @param {*} language 
 */
export const formatDatetime = (dateTime, language) => {
    let dt = dateTime.split(' ');
    let d = dt[0]; // Data
    let h = dt[1]; // Hora
    let df = d.split('-');

    if (LANGUAGE.PT_BR == language) {
        return df[2] + '/' + df[1] + '/' + df[0] + ' ' + h;
    }

    return df[1] + '/' + df[2] + '/' + df[0] + ' ' + h;
}

/**
 * Formata uma data de acordo com o idioma utilizado.
 * 
 * @param {*} date
 * @param {*} language 
 */
export const formatDate = (date, language) => {
    let df = date.split('-');

    if (LANGUAGE.PT_BR == language) {
        return df[2] + '/' + df[1] + '/' + df[0];
    }

    return df[1] + '/' + df[2] + '/' + df[0];
}

export const startOfDay = (date) => {
    let start = new Date(date);
    start.setHours(0, 0, 0, 0);
    start.setDate(start.getDate() + 1)
    return start;
}

export const endOfDay = (date) => {
    let end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
}

export const formatDateToJSON = (date) => {
    const pad = (num) => String(num).padStart(2, '0');

    console.log(date);

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() retorna o mês de 0 a 11
    const day = pad(date.getDate() + 1);

    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}