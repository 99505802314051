import React from 'react';
import { SelectCustom, Title } from './styles';

interface InputProps {
  title: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: any[]
}

const Select: React.FC<InputProps> = ({ title, onChange, value, options }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <SelectCustom onChange={onChange} value={value}>
        {options.map(item => (
          <option label={item.label} value={item.value} />
        ))}
      </SelectCustom>
    </React.Fragment>
  );
};

export default Select;
