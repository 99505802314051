/* eslint-disable no-nested-ternary */
import { VehicleType } from 'components/Vehicles/interfaces/types';
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import orderBottom from '../../assets/images/arrow-down-3101.svg';
import bicycle from '../../assets/images/typeVehicles/bicycle.svg';
import carreta from '../../assets/images/typeVehicles/cart.svg';
import motorcycle from '../../assets/images/typeVehicles/motorcycle.svg';
import toco from '../../assets/images/typeVehicles/toco.svg';
import tourVehicles from '../../assets/images/typeVehicles/tourVehicles.svg';
import truck from '../../assets/images/typeVehicles/truck.svg';
import van from '../../assets/images/typeVehicles/van.svg';
import vuc from '../../assets/images/typeVehicles/vuc.svg';
import { stylesViewNotices } from './styles';
import './styles.scss';

interface Props {
  onChange: (type: string) => void;
  value: string;
  fieldId: string;
  isView: boolean;
}

export default function CustomSelectVehicles({
  onChange,
  value,
  fieldId,
  isView,
}: Props) {
  const [vehicleTypes, setVehicleTypes] = React.useState(value || '');

  const renderIcons = (icon: string) => {
    let urlIcon = vuc;
    switch (icon) {
      case VehicleType.VUC:
        urlIcon = vuc;
        break;
      case VehicleType.TOCO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK:
        urlIcon = toco;
        break;
      case VehicleType.TRUCK:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA:
        urlIcon = carreta;
        break;
      case VehicleType.VAN:
        urlIcon = van;
        break;
      case VehicleType.VEICULO_PASSEIO:
        urlIcon = tourVehicles;
        break;
      case VehicleType.MOTO:
        urlIcon = motorcycle;
        break;
      case VehicleType.BICICLETA:
        urlIcon = bicycle;
        break;
      case VehicleType.TRUCK_FRIO:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA_FRIO:
        urlIcon = carreta;
        break;
      case VehicleType.TOCO_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.RODOTREM:
        urlIcon = toco;
        break;
      case VehicleType.TRES_QUARTOS:
        urlIcon = carreta;
        break;
      case VehicleType.TRES_QUARTOS_FRIO:
        urlIcon = carreta;
        break;
      default:
        urlIcon = '';
        break;
    }

    return urlIcon;
  };

  useEffect(() => {
    setVehicleTypes(value);
  }, [value]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        disabled={isView}
        id={fieldId}
        style={{ ...stylesViewNotices }}
      >
        <div className="selectOption" id="vuc">
          {vehicleTypes && vehicleTypes.length > 1 && (
            <img src={renderIcons(vehicleTypes)} className="imgNotice" alt="" />
          )}
          <p className="textOptionVehicle">
            {vehicleTypes && vehicleTypes.length > 1
              ? vehicleTypes
              : 'Selecione'}
          </p>
        </div>
        <div className="iconView">
          <img
            src={orderBottom}
            alt=""
            style={{ width: '0.6rem', height: '0.6rem' }}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        style={{
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <div style={{ overflowY: 'scroll', height: '15rem' }}>
          <Dropdown.Item
            style={{ padding: 5 }}
            onClick={() => {
              setVehicleTypes('S');
              onChange('S');
            }}
          >
            <div className="selectView">
              <p className="textSelect">
                <Trans i18nKey="notices.select" />
              </p>
            </div>
          </Dropdown.Item>
          {Object.values(VehicleType).map((item: string) => (
            <Dropdown.Item
              style={{ padding: 5 }}
              onClick={() => {
                setVehicleTypes(item);
                onChange(item);
              }}
              id={item.toLowerCase()}
            >
              <div className="selectView" id={item.toLowerCase()}>
                <img src={renderIcons(item)} className="imgNotice" alt="" />
                <p className="textOptionVehicle">{item.replaceAll('_', ' ')}</p>
              </div>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
