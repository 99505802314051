import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';
import { SearchOperation } from 'components/Grid/types';
import { endOfDay, formatDateToJSON, startOfDay } from 'util/dateUtil';

export class DateFilter implements IFilterComp {
    filterParams!: IFilterParams;
    filterText!: any; // retorno do filtro
    gui!: HTMLDivElement;

    // Define os campos do formulário.
    startFilterText: any;
    endFilterText: any;
    filterButton: any;

    init(params: IFilterParams) {
        this.filterParams = params;
        this.filterText = null;
        this.setupGui(params);
    }

    // not called by AG Grid, just for us to help setup
    setupGui(params: IFilterParams) {
        this.gui = document.createElement('div');
        this.gui.innerHTML = `
            <div class="customFilter-filter">
                <div>Data</div>
                <div>
                    <label>Data Inicial</label><br/>
                    <input type="date" id="startDateText"/>
                </div>
                <div>
                    <label>Data Final</label><br/>
                    <input type="date" id="endDateText"/>
                </div>
                <div>
                    <input type="button" id="filterButton" value="Filtrar"/>
                </div>
            </div>
        `;

        const listener = (event: any) => {
            let startDate = this.startFilterText.value;
            let endDate = this.endFilterText.value;

            // Verifica se a data foi informada.
            // A DATA é OBRIGATÓRIA.
            if((startDate == null || (startDate != null && startDate.trim() == '')) ||
                (endDate == null || (endDate != null && endDate.trim() == ''))) {
                return;
            }

            let startDateFilter = {
                field: "dataCriacao",
                value: formatDateToJSON(startOfDay(startDate)),
                operation: SearchOperation.GREATHER_THAN_EQUAL
            };
            let endDateFilter = {
                field: "dataCriacao",
                value: formatDateToJSON(endOfDay(endDate)),
                operation: SearchOperation.LESS_THAN_EQUAL
            };
            
            // Passa o array que será tratado no Grid.js
            this.filterText = [startDateFilter, endDateFilter];

            params.filterChangedCallback();
        };

        // Data
        this.startFilterText = this.gui.querySelector('#startDateText');
        this.endFilterText = this.gui.querySelector('#endDateText');

        // Button
        this.filterButton = this.gui.querySelector('#filterButton');
        this.filterButton.addEventListener('click', listener);
    }

    getGui() {
        return this.gui;
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        return params.data.creationDate != null;
    }

    isFilterActive() {
        return this.filterText != null && this.filterText !== '';
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }

        return { value: this.filterText };
    }

    setModel(model: any) {
        /*const newValue = model == null ? null : model.value;
        this.eFilterText.value = newValue;
        this.filterText = newValue;*/
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        /*if (!params?.suppressFocus) {
            // focus the input element for keyboard navigation
            this.eFilterText.focus();
        }*/
    }
}