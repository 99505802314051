import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap/lib';
import i18next from 'i18next';
import api from 'services/api';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { NotificationManager } from 'react-notifications';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import CustomTable from '../../CustomTable';
import Pagination from '../../Pagination';

import iconOrderTop from '../../../assets/images/iconOrderTop.svg';
import iconOrderBottom from '../../../assets/images/iconOrderBottom.svg';
import searchIcon from '../../../assets/images/ant-design_search-outlined.png';

export default function ListTemplate({
  handleEdit,
  data,
  handleDelete,
  handlePublished,
  handleView,
}) {
  const [totalPages, setTotalPages] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [contractors, setContractors] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([
    {
      Header: i18next.language === 'pt-BR' ? 'Título' : 'Title',
      accessor: 'titulo',
    },

    {
      Header: i18next.language === 'pt-BR' ? 'Publicado' : 'Published',
      accessor: 'publicado',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'Publicado em' : 'Published at',
      accessor: 'dataPublicado',
    },
  ]);

  const padronizeData = useCallback(async values => {
    const padronizedContractors = [];
    if (values.length > 0) {
      values.map(async element => {
        const publicadoData = element.publicado ? 'SIM' : 'NAO';
        const dataPublicadoData =
          element.dataPublicacao !== null
            ? format(new Date(element.dataPublicacao), 'dd/MM/yyyy', pt)
            : '---';
        padronizedContractors.push({
          id: element.id,
          titulo: element.nome,
          publicado: publicadoData,
          dataPublicado: dataPublicadoData,
        });
      });
    }
    setContractors(padronizedContractors);
  }, []);

  useEffect(() => {
    padronizeData(data);
  }, [data, padronizeData]);
  const { t } = useTranslation();

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <CustomTable
        showDelete
        showEdit
        showPublished
        showView
        columns={columns}
        data={contractors}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handlePublished={handlePublished}
        handleView={handleView}
      />
      <Pagination
        perPage={perPage}
        total={totalPages}
        onPaginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
