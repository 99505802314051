
export const dropStyle = {
  display: 'flex',
  flex: 1,
  alignItems: 'left',
  justifyContent: 'left',
  background: '#fff',
  border: '2px solid rgba(0, 48, 73, 0.2)',
  borderRadius: 10,
  height: 40,
  maxWidth: '100%',
  width: '100%',
  marginTop: 8,
  position: 'relative',
};

export const stylesViewNotices = {
  display: 'flex',
  // position: Position,
  alignItems: 'center',
  justifyContent: 'left',
  width: '100%',
  backgroundColor: '#fff',
  border: '2px solid rgba(23, 40, 49, 0.2)',
  borderRadius: 10,
  height: 35,
  padding: 20,
};
