import { CellValueChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact } from 'ag-grid-react';
import { GroupedRow } from 'components/CreateCargo/types';
import { RowSelection, SearchOperation } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import { DataRouteringCargas } from 'store/modules/routering/actions';
import WeekDay from './components/WeekDay';
import { IWeekDay } from './interfaces/types';
// import { Container } from './styles';

interface Props {
  onClear: () => void;
  data?: GroupedRow[];
}

const RouteRecorrent: React.FC<Props> = (props: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const [columns] = useState([
    {
      field: 'id',
      headerName: 'Nº Rota',
      checkboxSelection: true,
      filter: true,
      width: 100,
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 130,
    },
    {
      field: 'steps',
      headerName: 'Atend.',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value.length;
      },
      width: 80,
    },
    {
      field: 'driver.nome',
      headerName: 'Motorista',
      filter: true,
      width: 150,
    },
    {
      field: 'vehicle.placa',
      headerName: 'Placa',
      filter: true,
      width: 90,
    },
    {
      field: 'days[1]',
      headerName: 'Segunda',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[1], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[1] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[2]',
      headerName: 'Terça',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[2], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[2] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[3]',
      headerName: 'Quarta',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[3], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[3] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[4]',
      headerName: 'Quinta',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[4], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[4] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[5]',
      headerName: 'Sexta',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[5], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[5] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[6]',
      headerName: 'Sábado',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[6], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[6] = params.newValue;
        return true;
      },
      width: 100,
    },
    {
      field: 'days[0]',
      headerName: 'Domingo',
      cellRenderer: WeekDay,
      valueGetter: (params: any) => params.data.days[0], // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.days[0] = params.newValue;
        return true;
      },
      width: 100,
    },
  ]);
  const [rowData, setRowData] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const dataCargoRoutering: DataRouteringCargas[] = useSelector(
    (state: any) => state.routering.data.dataOptmi
  );

  const { t } = useTranslation();

  const onCellValueChanged = (params: CellValueChangedEvent) => {
   
  };

  const onSelectionChanged = (params: any) => {
    const selectedRows = params.api.getSelectedRows();

    setSelectedRow([...selectedRows]);

    gridApi.refreshCells({
      force: true, // força a atualização de todas as células
    });
  };

  function converterWeekDays(data: any) {
    let daysConverter: any[] = Object.values(IWeekDay);

    daysConverter = daysConverter.map((day: string) => {
      const routes = data.routes.find((route: any) => route.dayWeek == day);

      return {
        day: day,
        time: routes ? routes.time : '',
        enabled: !!routes,
      };
    });

    return daysConverter;
  }

  const onGridReady = useCallback(async params => {
    try {
      setGridApi(params.api);

      const reqDTO = {
        filters: [
          {
            field: 'simulation.id',
            value: props.data ? String(props.data[0].simulation) : String(dataCargoRoutering[0].simulation.id),
            operation: SearchOperation.EQUAL,
          },
        ],
        orders: [],
        size: 100,
        page: 0,
      };

      const response = await api.post('/route/optmis/pageable', reqDTO);

      const data = response.data.content.map((item: any) => {
        return {
          ...item,
          selected: false,
          days: converterWeekDays(item),
        };
      });


      setRowData(data);
    } catch {}
  }, []);

  const handleSubmit = useCallback(async (selected: any[]) => {
    try {
      setLoading(true);

      if (selected.length > 0) {
        let emptyWeekDay: boolean = false;
        let emptyWeekDayTime: boolean = false;

        const mappingWeekDays = selected.map((item: any) => {
          return {
            ...item,
            days: item.days.filter((day: any) => day.enabled === true),
          };
        });

        const validateWeekDays = mappingWeekDays.map((data: any) => {
          const findWeekDay = data.days.find(
            (day: any) => day.time.length === 0
          );
          if (data.days.length === 0) {
            emptyWeekDay = true;
          } else if (findWeekDay) {
            emptyWeekDayTime = true;
          }
        });

        if (emptyWeekDay) {
          FrontendNotification(
            t('routeOptmization.simulation.routeRecorrent.messageEmptyWeekDay'),
            NotificationType.WARNING
          );
        } else if (emptyWeekDayTime) {
          FrontendNotification(
            t('routeOptmization.simulation.routeRecorrent.messageEmptyWeekDayTime'),
            NotificationType.WARNING
          );
        } else {
          const body = mappingWeekDays.map((data: any) => {
            return {
              optmization: {
                id: data.id,
              },
              items: data.days.map((item: any) => {
                return {
                  dayWeek: item.day,
                  time: item.time,
                };
              }),
            };
          });

          const response = await api.post('/route/recurring/schedule', body);

          FrontendNotification(
            t('routeOptmization.simulation.routeRecorrent.messageSuccess'),
            NotificationType.SUCCESS
          );

          setLoading(false);
        }
      } else {
        FrontendNotification(
          t('routeOptmization.simulation.routeRecorrent.messageEmptyRoute'),
          NotificationType.WARNING
        );
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  return (
    <CustomModal
      isOpen
      label={t('routeOptmization.simulation.routeRecorrent.title')}
      isClose={props.onClear}
      styles={{ minWidth: '90%' }}
    >
      <>
        <Loading loading={loading} />

        <Row className="ag-theme-quartz tw-h-80 tw-mt-4">
          <Col md={12}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columns}
              rowData={rowData}
              pagination={true}
              rowSelection={RowSelection.MULTIPLE}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              suppressRowClickSelection
            />
          </Col>
        </Row>

        <div className="tw-flex tw-justify-center tw-items-center">
          <button
            className="tw-w-1/3 tw-h-9 tw-bg-[#10841C] tw-border-none tw-rounded-md tw-text-[#fff]"
            type="button"
            onClick={() => handleSubmit(selectedRow)}
          >
            Confirmar
          </button>
        </div>
      </>
    </CustomModal>
  );
};

export default RouteRecorrent;
