/**
 * Gera uma cor hexadecimal aleatória.
 * @returns Uma string representando uma cor hexadecimal.
 */
export const generateRandomColor = () => {
  const hexLetters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += hexLetters[Math.floor(Math.random() * 16)];
  }
  return color;
};
