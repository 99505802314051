import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

import '../../../pages/DeliveryTracking/styles.scss';
import '../style.scss';

import { Content } from '../../Ocorrence/styles';

import closeModalIcon from '../../../assets/images/closeIcon.svg';

export default function FormParameters({ data, onClear, onSelectParameters }) {
  const [modal, setModal] = React.useState(true);
  const [detailsDelivery, setDetailsDelivery] = useState([]);
  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const { t } = useTranslation();

  return (
    <>
      <Modal isOpen dialogClassName="my-modal" style={{ maxWidth: '65%' }}>
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <p
            className="title"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
          >
            <Trans i18nKey="phases.titleParameter" />
          </p>
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
              <Content style={{ width: '100%' }}>
                <div className="table-responsive">
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>
                          <Trans i18nKey="phases.parameters" />

                        </th>
                        <th>
                          <Trans i18nKey="phases.fields.description" />
                        </th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((itemParameters, index) => (
                        <tr
                          className="lineGroup selectDriver"
                          onClick={() =>
                            onSelectParameters(
                              itemParameters.tag,
                              itemParameters
                            )
                          }
                        >
                          <td>{itemParameters.tag}</td>
                          <td>
                            <p>{itemParameters.name}</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Content>
        </div>
      </Modal>
    </>
  );
}
