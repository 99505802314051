import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const iconClose = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '1.25rem',
};

export const WrapEditor = styled.div`
  margin-top: 20px;
  min-height: 6em;
  cursor: text;
  margin-bottom: 38px;
`;

export const BoxForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 756px;
  max-width: 947px;
  align-items: center;
  justify-content: space-between;
  .table-line {
    margin-top: 20px;
    width: 320px;
  }
`;

export const ButtonFooter = styled.button`
  height: 35px;
  border-radius: 6px;
  text-align: center;
  color: #ffffff;
  width: 351px;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 4px 6px
    ${props =>
      props.isVisualize ? `rgba(140, 140, 140, 0.2)` : `rgba(234, 0, 76, 0.2)`};
  background: ${props => (props.isVisualize ? ' #8C8C8C' : '#ea004c')};
  max-width: 450px;
  &:hover {
    background: ${props => (props.isVisualize ? ' #666666' : '#b5023b')};
    transition: 0.5s;
    box-shadow: 0px 4px 6px
      ${props =>
        props.isVisualize
          ? `rgba(140, 140, 140, 0.4)`
          : `rgba(234, 0, 76, 0.4)`};
  }
`;
