/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export function PublicRoute({
  component: Component,
  content: Content,
  ...rest
}) {
  return <Route {...rest} render={props => <Component {...props} />} />;
}
