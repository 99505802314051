import styled from 'styled-components';

export const SelectCustom = styled.select`
  border: 1px solid #535669;
  padding: 0.310rem;
  border-radius: 0.313rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
`;

export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: #535669;
  margin-bottom: 0.313rem;
`;