import { ActivityType, CargoType, Contractor, OccurrenceData } from "components/Ocorrence/interfaces/types";
import { Stage } from "components/Phases/interfaces/types";
import { Branch } from "components/SubsidiaryRegister/interfaces/types";
import Driver from "interfaces/Delivery/Driver";

export interface Cargo {
  baixaForaRaio: boolean;
  cancelado: boolean;
  cliente: Client;
  checkIn: string;
  checkOut: string;
  contratante: Contractor;
  cubagem: number;
  dangerGood: boolean;
  dataBaixaApp: string;
  dataCriacao: string;
  dataEmissao: string;
  dataExpiracaoTracking: string;
  dataModificacao: string;
  dataPrevisao: string;
  dataRecebimentoBaixa: string;
  documento: string;
  endereco: CargoAddress;
  eta: string;
  numero: string;
  tipo: CargoType;
  ordemEntrega: string;
  statusEntrega: CargoStatus;
  pulaAtendimento: boolean;
  rota: string;
  idReferencia: string;
  filialOrigem: Branch;
  filialDestino: Branch;
  tipoTransporte: string;
  peso: number;
  qtdVolumes: number;
  valor: number;
  ocorrencia: OccurrenceData;
  etapa: Stage;
  urlTracklink: string;
  id: number;
  tipoAtividade: ActivityType;
  nomeRecebedor: string;
  romaneio: Route;
  latitude: string;
  longitude: string;
}

interface Route {
  id: number;
  data: string;
  numero: string;
  placa: string;
  roundtrip: boolean;
  cancelado: boolean;
  filial: Branch;
  motorista: Driver;
}


export interface Client {
  cnpj: string;
  email: string;
  horarioAbertura: string;
  horarioFechamento: string;
  nome: string;
  telefone: string;
  telefoneFixo: string | null;
  tipo: PeopleType;
}

export enum PeopleType {
  FISICA =  "FISICA",
  JURIDICA = "JURIDICA"
}

export type CargoAddress = {
  logradouro: string;
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  latitude: number;
  longitude: number;
  numero: string;
  uf: string;
  pais: string;
}

export enum CargoStatus {
  ATRASADO = "ATRASADO",
  NO_PRAZO = "NO_PRAZO",
  CANCELADO = "CANCELADO",
  ENTREGUE = "ENTREGUE"
}