import CustomSelectVehicles from 'components/CustomSelectVehicles';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import React from 'react';
import { RatingDataContent } from '../..';
import { Container, DotField, Header, Title } from './styles';

export interface RatingOptionsContent {
  id: number | string;
  label: string;
}

interface RatingFieldsProps {
  data: RatingDataContent[];
  field: string;
  title: string;
  dotColor: string;
  placeholder: string;
  onChange: (value: string) => void;
  value: any;
  errors: any;
  touched: any;
  isBlocked: boolean;
  type?: string;
}

const RatingFields: React.FC<RatingFieldsProps> = ({
  data,
  field,
  title,
  dotColor,
  placeholder,
  onChange,
  value,
  errors,
  touched,
  isBlocked = false,
  type,
}: RatingFieldsProps) => {
  return (
    <Container>
      <Header>
        <DotField color={dotColor} />
        <Title>{title}</Title>
      </Header>
      {type && type === 'text' ? (
        <InputCustom
          type="text"
          placeholder=""
          onChange={(e: any) => onChange(e.target.value)}
          value={value}
          error={errors[field]}
          touched={touched[field]}
          disabled={isBlocked}
          id={field}
        />
      ) : (
        <>
          {title === 'Tipo de veículo' ? (
            <CustomSelectVehicles
              fieldId="selectType"
              onChange={(type: string) => onChange(type)}
              value={value}
              isView={isBlocked}
            />
          ) : (
            <SelectCustom
              onChange={(e: any) => onChange(e.target.value)}
              style={{}}
              value={value}
              error={errors[field]}
              touched={touched[field]}
              disabled={isBlocked}
              id={field}
            >
              <>
                <option label={placeholder} />
                {data.map((options: RatingDataContent) => (
                  <option value={options.id}>{options.label}</option>
                ))}
              </>
            </SelectCustom>
          )}
        </>
      )}
    </Container>
  );
};

export default RatingFields;
