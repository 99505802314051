import SelectCustom from 'components/SelectCustom';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import { pt } from 'date-fns/locale';
import moment from 'moment';
import React from 'react';
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import TopBar from 'util/TopBar';
import Abonar from './Abonar';
import AddPoint from './AddPoint';
import Remove from './RemoveData';
import './styles.scss';
import withDndContext from './withDndContext';

class Scheduling extends React.Component {
  constructor(props) {
    super(props);
    moment.locale('pt-br');

    let initialDate = format(new Date(), 'yyyy-MM-dd', pt);

    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);
    let schedulerData = new SchedulerData(
      initialDate,
      ViewTypes.Week,
      false,
      false,
      {
        schedulerWidth: ViewTypes.Week ? '80%' : '60%',
        // dayResourceTableWidth: 140,
        // displayWeekend: true,
        agendaViewHeader: 'Text',
        nonAgendaDayCellHeaderFormat: 'HH:mm',
        nonAgendaOtherCellHeaderFormat: 'ddd|DD/MM/YYYY',
        views: [
          {
            viewName: 'Dia',
            viewType: ViewTypes.Day,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Sem',
            viewType: ViewTypes.Week,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: 'Mês',
            viewType: ViewTypes.Month,
            showAgenda: false,
            isEventPerspective: false,
          },
        ],
      },
      moment
    );
    schedulerData.localeMoment.locale('pt-br');
    // schedulerData.setResources(DemoData.resources);
    // schedulerData.setEvents(DemoData.events);

    const getSubsidiaryId = sessionStorage.getItem('subsidiary');

    this.state = {
      subsidiary: [],
      subsidiaryId: sessionStorage.getItem('subsidiary'),
      viewModel: schedulerData,
      viewType: 1,
      updateDate: new Date(),
      typeView: 1,
      isPay: false,
      isRemovePoint: false,
      isAddPoint: false,
      resources: [],
      resourceId: '',
      resourceFilter: '-1',
      pControlId: '',
    };
  }


  async getDataScheduler(date, view, resource) {
    try {
      const currentDate = new Date();

      let dateStart;
      let dateEnd;

  console.log("UpdateDate", this.state.updateDate);


      if (view === 0) {
        console.log('entrou');
        dateStart = `${format(
          new Date(date || currentDate),
          'yyyy-MM-dd',
          pt
        )}T00:00:00`;
        dateEnd = `${format(
          new Date(date || currentDate),
          'yyyy-MM-dd',
          pt
        )}T23:59:59`;
      } else if (view === 2) {
        dateStart = `${format(
          startOfMonth(date ? new Date(date) : currentDate, {
            weekStartsOn: 0,
          }),
          'yyyy-MM-dd',
          pt
        )}T00:00:00`;
        dateEnd = `${format(
          endOfMonth(date ? new Date(date) : currentDate, { weekStartsOn: 0 }),
          'yyyy-MM-dd',
          pt
        )}T23:59:59`;
      } else {
        console.log("Entrou semana");
        dateStart = `${format(
          startOfWeek(date ? new Date(date) : currentDate, { weekStartsOn: 0 }),
          'yyyy-MM-dd',
          pt
        )}T00:00:00`;
        dateEnd = `${format(
          endOfWeek(date ? new Date(date) : currentDate, { weekStartsOn: 0 }),
          'yyyy-MM-dd',
          pt
        )}T23:59:59`;
      }

      const languageService =
        navigator.language === 'pt' || navigator.language === 'pt-BR'
          ? 'pt-BR'
          : 'en';
      const subsidiaryId = sessionStorage.getItem('subsidiary');

      if (subsidiaryId !== null && subsidiaryId.length > 0) {
        const response = await api.get(
          `/workday/pcontrols/events/${subsidiaryId}?start=${dateStart}&end=${dateEnd}&resourceId=${
            resource ? resource : this.state.resourceFilter
          }&lang=${languageService}`
        );

        const updateSchedule = this.state.viewModel;

        const mappingEvents = response.data.events.map(item => {
          const formatStatDate = new Date(item.start);

          const addSecondsStartDate = formatStatDate.setSeconds(
            formatStatDate.getSeconds() + 1
          );
          return {
            ...item,
            end: item.end === null ? `${addSecondsStartDate}` : item.end,
            fontColor: '#fff',
          };
        });

        updateSchedule.setEvents(mappingEvents);
        updateSchedule.setResources(response.data.resources);

        this.setState({
          viewModel: updateSchedule,
        });
      }
    } catch {}
  }

  async getSubsidiary() {
    const response = await api.get('/cadastro/filiais');
    this.setState({
      subsidiary: response.data,
    });
  }

  async getResourcers() {
    try {
      const subsidiaryId = sessionStorage.getItem('subsidiary');

      const data = {
        filters: [
          {
            field: 'subsidiary.id',
            operation: 'EQUAL',
            value: subsidiaryId,
          },
        ],
        orders: [],
        page: 0,
        size: 100,
      };
      if (subsidiaryId !== null && subsidiaryId.length > 0) {
        const response = await api.post(`/workday/resources/pageable`, data);

        this.setState({
          resources: response.data.content,
        });
      }
    } catch {}
  }

  componentDidMount() {
    this.getDataScheduler();
    this.getResourcers();
    this.getSubsidiary();
  }

  render() {
    const { viewModel } = this.state;

    return (
      <div>
        <TopBar
          listSubsidiary={this.state.subsidiary}
          onChangeSubsidiary={value => {
            this.getDataScheduler(
              this.state.updateDate,
              this.state.viewType,
              this.state.resourceFilter
            );
            this.getResourcers();
            this.setState({
              subsidiaryId: value,
            });
          }}
        />

        {this.state.isPay && (
          <Abonar
            onClear={() => {
              this.setState({ isPay: !this.state.isPay });
              this.getDataScheduler(
                this.state.updateDate,
                this.state.viewType,
                this.state.resourceFilter
              );
            }}
          />
        )}

        {this.state.isAddPoint && (
          <AddPoint
            onClear={() => {
              this.setState({ isAddPoint: !this.state.isAddPoint });
              this.getDataScheduler(
                this.state.updateDate,
                this.state.viewType,
                this.state.resourceFilter
              );
            }}
            resourceId={this.state.resourceId}
          />
        )}

        {this.state.isRemovePoint ? (
          <Remove
            onClear={() => {
              this.setState({ isRemovePoint: !this.state.isRemovePoint });
              this.getDataScheduler(
                this.state.updateDate,
                this.state.viewType,
                this.state.resourceFilter
              );
            }}
            pControlId={this.state.pControlId}
          />
        ) : (
          <></>
        )}

        <div
          style={{
            width: '98%',
            marginTop: '20px',
            marginLeft: '10px',
            overflowX: 'scroll',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #CCD6DB',
            borderRadius: '5px',
          }}
        >
          <div className="headerResource">
            <Row>
              <Col md={7}>
                <SelectCustom
                  id="resource"
                  onChange={e => {
                    this.setState({
                      resourceFilter: e.target.value,
                    });

                    this.getDataScheduler(
                      this.state.updateDate,
                      this.state.viewType,
                      e.target.value
                    );
                  }}
                  title="Funcionário"
                >
                  <option label="Todos" value="-1" />

                  {this.state.resources.map(item => (
                    <option label={item.name} value={item.id} />
                  ))}
                </SelectCustom>
              </Col>
              <Col md={3}>
                <button
                  className="button"
                  id="buttonAbonar"
                  onClick={() => this.setState({ isPay: !this.state.isPay })}
                  style={{ marginTop: 30, marginLeft: 30 }}
                >
                  Abonar
                </button>
              </Col>
            </Row>
          </div>

          <div style={{ width: '100%' }}>
            <Scheduler
            
              schedulerData={viewModel}
              prevClick={this.prevClick}
              nextClick={this.nextClick}
              onSelectDate={this.onSelectDate}
              onViewChange={this.onViewChange}
              eventItemClick={this.eventClicked}
              viewEventClick={this.ops1}
              viewEvent2Click={this.ops2}
              viewEventText="Adicionar"
              viewEvent2Text="Remover"
              statusColor="#EA004C"
              updateEventStart={this.updateEventStart}
              updateEventEnd={this.updateEventEnd}
              moveEvent={this.moveEvent}
              newEvent={this.newEvent}
              onScrollLeft={this.onScrollLeft}
              onScrollRight={this.onScrollRight}
              onScrollTop={this.onScrollTop}
              onScrollBottom={this.onScrollBottom}
              toggleExpandFunc={this.toggleExpandFunc}
              eventItemPopoverTemplateResolver={
                this.eventItemPopoverTemplateResolver
              }
              nonAgendaCellHeaderTemplateResolver={
                this.nonAgendaCellHeaderTemplateResolver
              }
            />
          </div>
        </div>
      </div>
    );
  }
  prevClick = schedulerData => {
    schedulerData.prev();
    this.setState({
      viewModel: schedulerData,
    });
    let setDatePrev = new Date(this.state.updateDate);
    if (this.state.viewType === 0) {
      setDatePrev.setDate(setDatePrev.getDate() - 1);
    } else if (this.state.viewType === 1) {
      setDatePrev.setDate(setDatePrev.getDate() - 7);
    } else {
      setDatePrev.setDate(setDatePrev.getDate() - 30);
    }

    this.setState({
      updateDate: setDatePrev,
    });

    this.getDataScheduler(
      setDatePrev,
      this.state.viewType,
      this.state.resourceFilter
    );
  };

  nextClick = schedulerData => {
    schedulerData.next();
    this.setState({
      viewModel: schedulerData,
    });

    let setDateNext = new Date(this.state.updateDate);
    if (this.state.viewType === 0) {
      setDateNext.setDate(setDateNext.getDate() + 1);
    } else if (this.state.viewType === 1) {
      setDateNext.setDate(setDateNext.getDate() + 7);
    } else {
      setDateNext.setDate(setDateNext.getDate() + 30);
    }

    this.setState({
      updateDate: setDateNext,
    });

    this.getDataScheduler(
      setDateNext,
      this.state.viewType,
      this.state.resourceFilter
    );
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );

    const dateViewChange = new Date(this.state.updateDate);

    this.getDataScheduler(
      dateViewChange,
      view.viewType,
      this.state.resourceFilter
    );

    this.setState({
      viewType: view.viewType,
    });

    // this.getDataScheduler(null, )

    // schedulerData.setEvents(DemoData.events);

    // this.setState({
    //   viewModel: schedulerData,
    // });
  };

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date);

    this.setState({
      updateDate: date,
    });
    // schedulerData.setEvents(DemoData.events);
    this.getDataScheduler(date, this.state.viewType, this.state.resourceFilter);
  };

  eventClicked = (schedulerData, event) => {
    console.log("clicked")
  };

  ops1 = event => {
    console.log("resource", event.resourceId)
    this.setState({
      resourceId: event.resourceId,
      isAddPoint: !this.state.isAddPoint,
    });
  };

  ops2 = event => {
    this.setState({
      pControlId: event.id,
      isRemovePoint: !this.state.isRemovePoint,
    });
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    console.log(item)
    this.setState({
      isAddPoint: !this.state.isAddPoint,
      resourceId: slotId,
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.events);
      this.setState({
        viewModel: schedulerData,
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {};

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {};

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    console.log('entrou');

    this.setState({
      viewModel: schedulerData,
    });
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    return (
      <div style={{ width: '300px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            className="status-dot"
            style={{ backgroundColor: statusColor }}
          />
          <span
            className="header2-text"
            title={title}
            style={{ marginLeft: 20 }}
          >
            {title}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <span className="header1-text" style={{ color: '#212529' }}>
            {start.format('HH:mm')}{' '}
            {end.format('HH:mm') === start.format('HH:mm')
              ? ''
              : `- ${end.format('HH:mm')}`}
          </span>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              fontSize: 16,
              color: '#ea004c',
              border: 'none',
              background: 'none',
              marginRight: 20,
            }}
            onClick={() => this.ops1(eventItem)}
          >
            Adicionar
          </button>
          <button
            style={{
              fontSize: 16,
              color: '#ea004c',
              border: 'none',
              background: 'none',
            }}
            onClick={() => this.ops2(eventItem)}
          >
            Remover
          </button>
        </div>
      </div>
    );
  };

  nonAgendaCellHeaderTemplateResolver = (
    schedulerData,
    item,
    formattedDateItems,
    style
  ) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), 'hour');
    } else {
      isCurrentDate = datetime.isSame(new Date(), 'day');
    }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {formattedDateItems.map((formattedItem, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={{
              __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>'),
            }}
          />
        ))}
      </th>
    );
  };
}

export default withDndContext(Scheduling);
