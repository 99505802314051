/* eslint-disable operator-assignment */
import produce from 'immer';

const INITIAL_STATE = {
  data: {
    headers: [
      {
        name: 'Dia da semana',
      },
      {
        name: 'Trabalho',
      },
      {
        name: 'Total de Horas',
      },
    ],
    dataElements: [
      {
        columns: [
          {
            data: 'Segunda',
            id: 'MONDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Terça',
            id: 'TUESDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Quarta',
            id: 'WEDNESDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Quinta',
            id: 'THURSDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Sexta',
            id: 'FRIDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Sábado',
            id: 'SATURDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
      {
        columns: [
          {
            data: 'Domingo',
            id: 'SUNDAY',
            field: 'days',
          },
          {
            data: '',
            id: 'timeWorked',
          },
          {
            data: '',
            id: 'totalHours',
          },
        ],
      },
    ],
  },
};

export default function journey(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@journey/ADD_HEADERS_JOURNEY_FLEXIBLE': {
        draft.data.headers.splice(
          draft.data.headers.length - 1,
          0,
          action.payload.data
        );
        break;
      }
      case '@journey/ADD_COLUMN_JOURNEY_FLEXIBLE': {
        draft.data.dataElements[action.payload.indexElement].columns.splice(
          draft.data.dataElements[action.payload.indexElement].columns.length -
            1,
          0,
          {
            id: 'break',
            pause: {
              id: action.payload.dataBreak.id
            },
            data: '',
          }
        );
        break;
      }

      case '@journey/REMOVE_HEADER_JOURNEY_FLEXIBLE': {
        draft.data.headers.splice(action.payload.indexHeader, 1);
        break;
      }

      case '@journey/REMOVE_COLUMN_JOURNEY_FLEXIBLE': {
        draft.data.dataElements[action.payload.indexElements].columns.splice(
          action.payload.indexHeader,
          1
        );
        break;
      }

      case '@journey/SET_JOURNEY_DATA_VALUE_MULTIPLE': {
        draft.data.dataElements[action.payload.indexElements].columns[
          action.payload.indexHeader
        ].data = action.payload.text;
        break;
      }
      case '@journey/SET_JOURNEY_DATA_VALUE': {
        draft.data.dataElements[action.payload.indexElements].columns[
          action.payload.indexHeader
        ].data = action.payload.text;
        break;
      }
      case '@journey/UPDATE_JOURNEY_DATA': {
        draft.data.dataElements = action.payload.data;
        break;
      }

      case '@journey/UPDATE_COLUMN_JOURNEY_FLEXIBLE': {
        draft.data.dataElements[action.payload.indexElement].columns.splice(
          draft.data.dataElements[action.payload.indexElement].columns.length -
            1,
          0,
          {
            id: 'break',
            pause: {
              id: action.payload.dataBreak.id
            },
            data: `${action.payload.breakValue.slice(0, 5)}`,
          }
        );
        break;
      }

      case '@journey/CLEAR_COLUMN_JOURNEY_FLEXBILE':
        draft.data.dataElements = INITIAL_STATE.data.dataElements;
        draft.data.headers = INITIAL_STATE.data.headers;
        break;

      default:
    }
  });
}
