/* ---------------------------------------------------
     AUTH ACTIONS
----------------------------------------------------- */
/**
 * Responsável por realizar o login.
 *
 * @param {*} email
 * @param {*} password
 */
export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

/**
 * Responsável por indicar sucesso na autenticação do usuário.
 *
 * @param {*} token
 * @param {*} userName
 */

export function setLoading() {
  return {
    type: '@auth/SET_LOADING',
  };
}
export function signInSuccess(token, email, primaryAccess, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: {
      token,
      email,
      primaryAccess,
      user,
    },
  };
}

export function updatePrimaryAccess() {
  return {
    type: '@auth/UPDATE_PRIMARY_ACCESS',
  };
}

/**
 * Responsável por indicar falha no momento do login.
 */
export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

/**
 * Responsável por informar se o loding deverá aparecer ou não.
 * @param {*} loading
 */
export function setLoadingSignIn(loading) {
  return {
    type: '@auth/SET_LOADING_SIGN_IN',
    payload: { loading },
  };
}

/**
 * Responsável por realizar o logout da aplicação.
 */
export function logout() {
  return {
    type: '@auth/LOGOUT_REQUEST',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signUpRequest(email, password, name) {
  return {
    type: '@auth/SIGNUP',
    payload: { email, password, name },
  };
}

export function signUpSuccess() {
  return {
    type: '@auth/SIGNUP_SUCCESS',
  };
}

export function signUpFailure() {
  return {
    type: '@auth/SIGNUP_FAILURE',
  };
}
