import axios from 'axios';
import CustomModal from 'components/ModalCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import Leaflet, { LeafletMouseEvent } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import ReactLoading from 'react-loading';
import { Col, Input, Row } from 'reactstrap';
import api from 'services/api';
import { searchAddress } from 'services/endpoint';
import { States } from 'util/monitoring';
import MapIcon from '../../../../assets/images/mapPin.svg';
import ConfirmationDriver from './ConfirmationDriver';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

interface FormValues {
  cep: string;
  logradouro: string;
  numero: number | null;
  bairro: string;
  cidade: string;
  estado: string;
}

interface StateData {
  nome?: string;
  sigla?: string;
  id?: number;
}

interface Props {
  open: boolean;
  onClear?: any;
  data?: any;
  onEditColumns?: any;
}

const ModalAddress: React.FC<Props> = ({ onClear, data, onEditColumns }: Props) => {
  
  const [confirmAddress, setConfirmAddress] = useState<boolean>(false);
  const [dataCoordenates, setDataCoordenates] = useState<any>(null);
  const [position, setPosition] = useState<any>([]);
  const [display, setDisplay] = useState<any>(false);
  const [autoSearch, setAutoSearch] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [addressText, setAddressText] = useState<string>('');
  const [initialPosition, setInitialPosition] = useState<any>([
    -8.0527,
    -34.9099,
  ]);
  const [zoom, setZoom] = useState<number>(1);
  const [states, setStates] = useState<StateData[]>([]);  
  const { t } = useTranslation();
  let sortType = 'asc';

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    popupAnchor: [-30, -35],
  });

  const getPosition = useCallback((e: LeafletMouseEvent) => {
    const { lat, lng } = e.latlng;
    setPosition([lat, lng]);
  }, []);

  const handleSubmit = useCallback(
    async (values, positionData, dataAddressCargo) => {
      setLoading(true);
      let addressStreet;
      const valuesCEP =
        values && values.address && values.address.postcode
          ? values.address.postcode
          : dataAddressCargo[0].addressZipcode;

      const formattedField = valuesCEP.replaceAll('-', '').replaceAll('.', '');

      const resultCEP = `${formattedField.substring(
        0,
        5
      )}-${formattedField.substring(5)}`;
      if (values && values.address) {
        if (values.address.road || values.address.natural) {
          addressStreet = values.address.road || values.address.natural;
        } else {
          addressStreet = dataAddressCargo[0].enderecoLogradouro;
        }
      }

      let addressNeighborhood;

      if (values && values.address) {
        if (values.address.suburb) {
          addressNeighborhood = values.address.suburb;
        } else {
          addressNeighborhood = dataAddressCargo[0].enderecoBairro;
        }
      }
      if (positionData.length === 0) {
        const message =
          navigator.language === 'pt' || navigator.language === 'pt-BR'
            ? 'Selecione o ponto no mapa'
            : 'Select the point on the map';
        FrontendNotification(message, NotificationType.ERROR);
      } else {
        const stateAddress =
          values && values.address
            ? values.address.state
            : dataAddressCargo[0].estado;
        const arrData = [
          {
            enderecoLogradouro: addressStreet,
            enderecoNumero: dataAddressCargo[0].enderecoNumero,
            enderecoBairro: addressNeighborhood,
            enderecoCidade:
              values && values.address
                ? values.address.city ||
                  values.address.city_district ||
                  values.address.town
                : dataAddressCargo[0].enderecoCidade,
            enderecoUf: States.find((itemState: StateData) =>
              values && values.address
                ? itemState.nome === stateAddress
                : itemState.sigla === stateAddress
            )?.sigla,
            enderecoCep: resultCEP,
            enderecoLatitude:
              values && values.lat ? values.lat : positionData[0],
            enderecoLongitude:
              values && values.lon ? values.lon : positionData[1],
            status: '100',
          },
        ];

        let body = {
          street: arrData[0].enderecoLogradouro,
          neighborhood: arrData[0].enderecoBairro,
          city: arrData[0].enderecoCidade,
          state: arrData[0].enderecoUf,
          number: arrData[0].enderecoNumero,
          zipCode: arrData[0].enderecoCep,
          latitude: arrData[0].enderecoLatitude,
          longitude: arrData[0].enderecoLongitude,
        };

        const response = await api.patch(
          `/delivery/conhecimentosPre/addresses/${data[0].id}`,
          body
        );

        setLoading(false);
        onClear();
        onEditColumns(data, arrData);
      }
    },
    []
  );

  const handleChange = useCallback(async text => {
    setLoading(true);

    const valueSearch = text.replace(/\s+/g, '+');
    const response = await axios.get(
      `${searchAddress}/?addressdetails=1&q=${valueSearch}&format=json`
    );

    if (response.data.length > 0) {
      setDataCoordenates(response.data[0]);
      setDisplay(!display);
      setOptionsSearch(response.data);
    } else {
      FrontendNotification(
        t('searchAddress.noLocation'),
        NotificationType.WARNING
      );
    }
    setLoading(false);
  }, []);

  const getStates = useCallback(() => {
    const orderSort: StateData[] = States.sort((a: any, b: any) => {
      const isReversed = sortType === 'asc' ? 1 : -1;

      return isReversed * a.nome.localeCompare(b.nome);
    });

    setStates(orderSort);
  }, [sortType]);

  const initialValues: FormValues = {
    cep: '',
    logradouro: '',
    numero: null,
    bairro: '',
    cidade: '',
    estado: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      navigator.language === 'pt' || navigator.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: () => {},
  });

  const setFieldsAddress = useCallback(() => {
    formik.setFieldValue('bairro', data[0].addressNeighborhood);
    formik.setFieldValue('logradouro', data[0].addressStreet);
    formik.setFieldValue('cidade', data[0].addressCity);
    formik.setFieldValue('cep', data[0].addressZipcode);
    formik.setFieldValue('numero', data[0].addressNumber);
    formik.setFieldValue('estado', data[0].addressState);

    setAddressText(
      `${data[0].addressStreet},${data[0].addressNumber},${data[0].addressCity},${data[0].addressState}`
    );

    if (
      data[0].enderecoLatitude.length > 0 &&
      data[0].enderecoLongitude.length > 0
    ) {
      setPosition([data[0].enderecoLatitude, data[0].enderecoLongitude]);
      setInitialPosition([data[0].enderecoLatitude, data[0].enderecoLongitude]);
      setZoom(40);
    }
  }, [data, formik]);

  useEffect(() => {
    getStates();
    setFieldsAddress();
  }, [getStates])


  return (
    <>
    {confirmAddress && (
        <ConfirmationDriver
          onClear={() => setConfirmAddress(!confirmAddress)}
          onConfirmAddress={() => {
            handleSubmit(dataCoordenates, position, data);
          }}
        />
      )}
    <CustomModal isClose={onClear} isOpen label='Localizar endereço'>

      <strong>Endereço atual:</strong>
          <p>
            {data[0].addressStreet},{data[0].addressNumber},
            {data[0].addressNeighborhood},{data[0].addressCity},
            {data[0].addressState}
            <span style={{ marginLeft: 20 }}>
              <strong>CEP: </strong> {data[0].addressZipcode},{' '}
              {data[0].addressComplement}
            </span>
          </p>
          <Row>
            <Col md={8} style={{ marginTop: 20, marginBottom: 20 }}>
              <div style={{ width: '100%', position: 'relative' }}>
                <Input
                  type="text"
                  name="select"
                  autocomplete="off"
                  id="SelectState"
                  placeholder={t('locateAddress.placeholder.street')}
                  onChange={async (e: any) => {
                    setAddressText(e.target.value);
                  }}
                  className="inputSearch"
                  value={addressText}
                />
                {loading && (
                  <div style={{ position: 'absolute', top: 5, right: 10 }}>
                    <ReactLoading
                      type="spin"
                      color="#ea004c"
                      width={24}
                      height={24}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 20, marginBottom: 20 }}>
              <button
                type="button"
                className="buttonCSVAddress"
                onClick={() => {
                  handleChange(addressText);
                }}
              >
                <Trans i18nKey="searchAddress.buttonSearch" />
              </button>
            </Col>
            <Col md={2} style={{ marginTop: 20, marginBottom: 20 }}>
              {dataCoordenates !== null && addressText.length > 0 && (
                <button
                  type="button"
                  className="buttonCSVAddress"
                  onClick={() => {
                    setPosition([dataCoordenates?.lat, dataCoordenates?.lon]);
                    setInitialPosition([
                      dataCoordenates?.lat,
                      dataCoordenates?.lon,
                    ]);
                    setZoom(40);
                  }}
                >
                  <Trans i18nKey="filiais.buttons.location" />
                </button>
              )}
            </Col>
          </Row>
          {display && (
            <div className="autoCompleteSearchContainer">
              {optionsSearch.length > 0 ? (
                optionsSearch.map((item: any) => (
                  <div
                    className="searchContent"
                    onClick={() => {
                      setDataCoordenates(item);
                      setAutoSearch(!autoSearch);
                      setAddressText(`${item.display_name}`);
                      setDisplay(!display);
                    }}
                  >
                    <span className="resultAddress">{item.display_name}</span>
                  </div>
                ))
              ) : (
                <span className="resultAddress">No Search results.....</span>
              )}
            </div>
          )}
          <div style={{ width: '100%' }}>
            <Map
              center={initialPosition}
              zoom={zoom}
              zoomControl
              onclick={e => getPosition(e)}
              style={{
                display: 'flex',
                maxHeight: 440,
                width: '100%',
                marginTop: 0,
                cursor: 'pointer',
              }}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {position.length > 0 && (
                <Marker position={position} icon={pinIcon} />
              )}
            </Map>
            <div className="footerButton">
              <button
                type="button"
                className="button saveButton"
                onClick={() => {
                  setConfirmAddress(!confirmAddress);
                }}
              >
                Salvar
              </button>
            </div>
          </div>
    </CustomModal>
    </>

  );
}

export default ModalAddress;