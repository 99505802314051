import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border: 1px solid #00304933;
  border-radius: 10px;
  width: 195px;
  margin-top: 1.25rem;
  padding: 1rem;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const Footer = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const AddButton = styled.img`
  cursor: pointer;
  margin-right: 0.875rem;
`;

export const TextButton = styled.div`
  font-family: 'Lato';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
`;