/* eslint-disable no-extra-semi */
import produce from 'immer';

const INITIAL_STATE = {
  url: null,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@reports/SET_URL_PARAMS': {
        draft.url = action.payload.url;
        break;
      };
    }
      
  });
}
