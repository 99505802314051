import React from 'react';
import './styles.scss';


interface ProgressBarProps {
  value?: number;
  total: number;
  done: number;
  deliveryDone?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  total,
  done,
  deliveryDone,
}) => {
  // const piece = Math.ceil((done / 100) * total);
  // const calc = done / piece;
  // value = Math.ceil(parseInt(calc)) || 0;
  const progressBar = (
    <div
      className="progressBar text-right"
      style={{ flex: 1, width: done <= 17 ? '17%' : `${done}%` }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          width: '10rem',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ marginLeft: '0.125rem' }}>{done}%</span>

        <span style={{ color: '#000000' }}>
          {deliveryDone}/{total}
        </span>
      </div>
    </div>
  );

  return (
    <div
      id="progressBarMain"
      className="progressBarContainer"
      style={{ width: '10.625rem', height: '1.7rem' }}
    >
      {progressBar}
    </div>
  );
}

export default ProgressBar;