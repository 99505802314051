// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customPage {
  width: 1.375rem;
  height: 1.375rem;
  border: 0.063rem solid rgba(0, 48, 73, 0.2);
  border-radius: 0.25rem;
  background-color: #fff;
  font-family: "Roboto" sans-serif;
  font-weight: 400;
  font-size: 0.688rem;
  font-style: normal;
  color: #003049;
  cursor: pointer;
  margin-right: 0.375rem;
}
.customPage .selected {
  background: rgba(156, 156, 156, 0.15);
}

.customNextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.063rem solid rgba(0, 48, 73, 0.2);
  border-radius: 0.25rem;
  margin-top: 0.2rem;
}

.customEllipsisPage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  text-align: center;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.063rem solid rgba(0, 48, 73, 0.2);
  border-radius: 0.25rem;
  margin-top: 0.2rem;
  margin-right: 0.375rem;
}

.customPreviousButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.063rem solid rgba(0, 48, 73, 0.2);
  border-radius: 0.25rem;
  margin-top: 0.2rem;
  margin-right: 0.375rem;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomPagination/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,sBAAA;EACA,sBAAA;EACA,gCAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,sBAAA;AACF;AAAE;EACE,qCAAA;AAEJ;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,2CAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;AACF","sourcesContent":[".customPage {\n  width: 1.375rem;\n  height: 1.375rem;\n  border: 0.063rem solid rgba(0, 48, 73, 0.2);\n  border-radius: 0.25rem;\n  background-color: #fff;\n  font-family: 'Roboto' sans-serif;\n  font-weight: 400;\n  font-size: 0.688rem;\n  font-style: normal;\n  color: #003049;\n  cursor: pointer;\n  margin-right: 0.375rem;\n  .selected {\n    background: rgba(156, 156, 156, 0.15);\n  }\n}\n\n.customNextButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #fff;\n  width: 1.375rem;\n  height: 1.375rem;\n  border: 0.063rem solid rgba(0, 48, 73, 0.2);\n  border-radius: 0.25rem;\n  margin-top: 0.20rem;\n}\n\n.customEllipsisPage {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #fff;\n  text-align: center;\n  width: 1.375rem;\n  height: 1.375rem;\n  border: 0.063rem solid rgba(0, 48, 73, 0.2);\n  border-radius: 0.25rem;\n  margin-top: 0.20rem;\n  margin-right: 0.375rem;\n}\n\n.customPreviousButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #fff;\n  width: 1.375rem;\n  height: 1.375rem;\n  border: 0.063rem solid rgba(0, 48, 73, 0.2);\n  border-radius: 0.25rem;\n  margin-top: 0.20rem;\n  margin-right: 0.375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
