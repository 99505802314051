import React from 'react';
import { Container } from './styles';

const AlertAnimation: React.FC = () => {
  return (
    <Container>
      <div className="fa-3x">
        <i className="fa-solid fa-bell fa-shake" style={{ color: '#EA004C' }}></i>
      </div>
    </Container>
  );
};

export default AlertAnimation;
