/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import dangerIcon from '../../../assets/images/dangerIcon.png';
import informationIcon from '../../../assets/images/informationIcon.png';
import warningIcon from '../../../assets/images/warningIcon.png';
import orderBottom from '../../../assets/images/orderBottom.png';
import { stylesViewNotices } from './styles';
import './styles.scss';

export default function CustomSelectNotices({ onChange, valueNoticesType }) {
  const [valueNotices, setValueNotices] = useState('');

  useEffect(() => {
    setValueNotices(valueNoticesType);
  }, [valueNoticesType]);

  return (
    <Dropdown>
      <Dropdown.Toggle style={{ ...stylesViewNotices }}>
        <>
          {valueNotices === 'P' || valueNoticesType === 'P' ? (
            <div className="selectView">
              <img src={dangerIcon} className="imgNotice" alt="" />
              <p className="textNotice">
                <Trans i18nKey="driver.type.danger" />
              </p>
            </div>
          ) : valueNotices === 'I' || valueNoticesType === 'I' ? (
            <div className="selectView">
              <img src={informationIcon} className="imgNotice" alt="" />
              <p className="textNoticeInformation">
                <Trans i18nKey="driver.type.information" />
              </p>
            </div>
          ) : valueNotices === 'W' || valueNoticesType === 'W' ? (
            <div className="selectView">
              <img src={warningIcon} className="imgNotice" alt="" />
              <p className="textNoticeWarning">
                <Trans i18nKey="driver.type.warning" />
              </p>
            </div>
          ) : valueNotices === '' || valueNoticesType === '' ? (
            <p className="textSelect">
              <Trans i18nKey="notices.select" />
            </p>
          ) : (
            <></>
          )}

          <div className="iconView">
            <img src={orderBottom} alt="" />
          </div>
        </>
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: '100%' }}>
        <Dropdown.Item
          onClick={() => {
            setValueNotices('');
          }}
        >
          <div className="selectView">
            <p>
              <Trans i18nKey="notices.select" />
            </p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setValueNotices('P');
            onChange('PERIGO', 'P');
          }}
        >
          <div className="selectView">
            <img src={dangerIcon} className="imgNotice" alt="" />
            <p className="textNotice">
              <Trans i18nKey="driver.type.danger" />
            </p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setValueNotices('I');
            onChange('INFORMACAO', 'I');
          }}
        >
          <div className="selectView">
            <img src={informationIcon} className="imgNotice" alt="" />
            <p className="textNoticeInformation">
              <Trans i18nKey="driver.type.information" />
            </p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            setValueNotices('W');
            onChange('ATENCAO', 'W');
          }}
        >
          <div className="selectView">
            <img src={warningIcon} className="imgNotice" alt="" />
            <p className="textNoticeWarning">
              <Trans i18nKey="driver.type.warning" />
            </p>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
