/* eslint-disable prefer-const */
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import SearchFilter from 'components/SearchFilter';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import Pagination from '../Pagination';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';
import { BoxForm, Content, Footer } from './styles';

export default function Reasons({ onClear, active }) {
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [optionsFilter, setOptionsFilter] = useState('descricao');
  const [valueSearch, setValueSearch] = useState('');
  const [reasons, setReasons] = useState([]);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const dispatch = useDispatch();

  const [totalPages, setTotalPages] = useState(0);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const { t } = useTranslation();

  const filtersData = useMemo(() => {
    let computedPages = reasons;

    if (optionsFilter === 'descricao') {
      computedPages = computedPages.filter(op =>
        op.descricao.toLowerCase().includes(valueSearch.toLowerCase())
      );
    }

    setTotalPages(computedPages.length);

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [optionsFilter, reasons, currentPage, valueSearch]);

  const getReasonsData = useCallback(async () => {
    try {
      const response = await api.get('/delivery/motivos');

      const reasonsOrder = response.data.sort((a, b) => b.id - a.id);

      setReasons(reasonsOrder);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const handleSubmit = useCallback(
    async (values, reset, reasonsUpdate) => {
      try {
        const data = {
          descricao: values.descricao,
          tipo: 'CANCELAMENTO',
        };

        // const response = await api.post('/delivery/motivos', data);

        const languageFormat = navigator.language;
        const emailSendLanguage =
          languageFormat === 'pt' || languageFormat === 'pt-BR'
            ? 'pt_BR'
            : 'en_US';

        if (reasonsUpdate) {
          const response = await api.patch(
            `/delivery/motivos/${reasonsUpdate}?lang=${emailSendLanguage}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('reasons.messageUpdated'),
              NotificationType.SUCCESS
            );
            if (active === true) {
              onClear();
              setModal(!modal);
            }
          }
          setEdit(false);
          setUpdateId();
        } else {
          const response = await api.post(
            `/delivery/motivos?lang=${emailSendLanguage}`,
            data
          );
          if (response.status) {
            FrontendNotification(
              t('reasons.messageCreated'),
              NotificationType.SUCCESS
            );
          }
        }
        reset({});

        getReasonsData();
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [dispatch]
  );

  const deleteDriver = useCallback(async operatorId => {
    try {
      const response = await api.delete(`delivery/motivos/${operatorId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('reasons.messageRemove'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('reasons.messageErroRemove'),
          NotificationType.ERROR
        );
      }

      setRemoveConfirmation(false);

      getReasonsData();
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const getLanguages = () => {
    return i18next.language;
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const currentData = reasons.slice(indexOfFirstPage, indexOfLastPage);

  const formik = useFormik({
    initialValues: {
      descricao: '',
    },
    validationSchema:
      getLanguages() === 'pt' || getLanguages() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, resetForm, updateId),
  });

  useEffect(() => {
    getReasonsData();
  }, [getReasonsData]);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteDriver(removeId)}>
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={toggleRemove}>
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', height: '100%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <SearchFilter
            isDescription
            valueSearch={valueSearch}
            optionValue={optionsFilter}
            handleChange={e => setValueSearch(e.target.value)}
            handleSelect={e => setOptionsFilter(e.target.value)}
          />{' '}
          <p className="title">
            <Trans i18nKey="reasons.title" />
          </p>
          <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
            <BoxForm>
              <Label
                for="SelectState"
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                <Trans i18nKey="reasons.fields.description" />
              </Label>

              <Input
                name="select"
                id="SelectState"
                placeholder={t('reasons.fields.description')}
                onChange={formik.handleChange('descricao')}
                mask="99.999.999/9999-99"
                style={{
                  maxWidth: 450,
                  height: 60,
                  background: '#FFFFFF',
                  border: '2px solid rgba(0, 48, 73, 0.2)',
                  boxSizing: 'border-box',
                  borderRadius: 10,
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 14,
                  lineHeight: 19,
                  color: '#003049',
                  marginTop: 8,
                  paddingLeft: 10,
                }}
                value={formik.values.descricao}
              >
                {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
              </Input>

              {formik.touched.descricao && formik.errors.descricao && (
                <p style={{ fontSize: 14, color: 'red' }}>
                  {formik.errors.descricao}
                </p>
              )}

              {/* {formik.errors.cnpj && formik.touched.cnpj && (
            <p style={{ fontSize: 14, color: 'red' }}>{formik.errors.cnpj}</p>
          )} */}

              <Footer>
                {edit ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <button
                          onClick={() => {
                            formik.resetForm({});
                            setEdit(false);
                            setUpdateId();
                          }}
                          className="button"
                          type="button"
                          style={{
                            background: '#ccc',
                            color: '#000',
                            marginTop: 30,
                          }}
                        >
                          <Trans i18nKey="common.cancel" />
                        </button>
                      </Col>

                      <Col md={6}>
                        <button
                          onClick={formik.handleSubmit}
                          className="button"
                          type="button"
                          style={{ marginTop: 30 }}
                        >
                          <Trans i18nKey="reasons.update" />
                        </button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <button
                    onClick={formik.handleSubmit}
                    className="button"
                    type="button"
                    style={{ maxWidth: 450, marginTop: 30 }}
                  >
                    <Trans i18nKey="reasons.save" />
                  </button>
                )}
              </Footer>
            </BoxForm>

            <Content>
              <div className="table-responsive">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>
                        <Trans i18nKey="reasons.fields.description" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtersData.map((item, index) => (
                      <tr className="lineGroup">
                        <td>
                          <p style={{ marginBottom: 0 }}>{item.descricao}</p>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              color="link"
                              className="noMargin"
                              onClick={() => {
                                formik.setFieldValue(
                                  'descricao',
                                  item.descricao
                                );

                                setEdit(true);
                                setUpdateId(item.id);
                              }}
                            >
                              <img
                                src={EditIcon}
                                alt=""
                              />
                            </Button>
                            <Button
                              color="link"
                              className="noMargin"
                              onClick={() => {
                                setRemoveId(item.id);
                                setRemoveConfirmation(!removeConfirmation);
                              }}
                            >
                              <img
                                src={DeleteIcon}
                                alt=""
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  perPage={perPage}
                  total={totalPages}
                  onPaginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </Content>
          </div>
        </div>
      </Modal>
    </>
  );
}
