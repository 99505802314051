import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import { responsiveSize } from '../../../styles/responsive';

export const Content = styled.div`
  background-color: #fff;
  border: 2px solid #edf2f4 !important;
  border-radius: 10px;
  padding: ${responsiveSize(20)};
  width: 90%;
  margin-top: 50px;
  .lineGroup {
    border-bottom: 1px solid #ccd6db;
  }
`;

export const ButtonSaveJourney = styled.button`
  margin-bottom: 5px;
`;

export const ContainerJourney = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextSelectJourney = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #003049;
  margin-right: 10px;
`;



export const BoxForm = styled.div`
  width: 100%;
  display: flex;
  margin-right: ${responsiveSize(20)}px;
  flex-direction: column;
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  margin-bottom: 0px;
  .button {
    height: 35px;
    background: #ea004c;
    border-radius: 6px;
    text-align: center;
    color: #ffffff;
    /* float: right; */
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);

    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
    }
  }

  .table-line {
    margin-top: 20px;
    width: 320px;
  }
`;
export const InputDate = styled(DatePicker)``;
