import { FrontendNotification } from 'components/Notification';
import Loading from 'core/common/Loading';
import { NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import { formatNumber } from 'util/format';
import Header from './Header';
import Rating from './Rating';
import ShippingContent from './ShippingContent';
import ListShipping from './ShippingList';
import { Container, Content, Wrapper } from './styles';
import formValidatorRatingPT from './validation/formValidatorRatingPT';
import formValidatorRatingPublished from './validation/formValidatorRatingPublished';

export interface ICreateShippingTableForm {
  description?: string;
  dateInitial?: string;
  finalDate?: string;
  type: string;
  filialOrigem?: string;
  operadorLogistico?: string;
  tipoVeiculo?: string;
  veiculo?: string;
  tipoAtendimento?: string;
  qtdKM?: string;
  valueKM?: string;
  surplusValue?: string;
  initialDay?: string;
  zipcode?: string;
  valueZipcode?: string;
  obs: string;
}

interface NewShippingTableProps {
  isEdit: boolean;
  editId: number;
  data: any[];
  onHandleSubmit: () => void;
}

const EditShippingTable: React.FC<NewShippingTableProps> = ({
  isEdit,
  editId,
  data,
  onHandleSubmit,
}: NewShippingTableProps) => {
  const [dataRating, setDataRating] = useState<any[]>([]);
  const [isEditRating, setIsEditRanting] = useState<boolean>(false);
  const [updateIdRanting, setUpdateIdRanting] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const { t } = useTranslation();

  const onDeleteRow = useCallback(async (data: any, dataRating: any[]) => {
    let dataFilter = data;

    const findIndexRatingByData = dataFilter.findIndex(
      (i: any) => i.id == dataRating[0].id
    );

    dataFilter = dataFilter.filter(
      (item: any, index: number) => index !== findIndexRatingByData
    );

    setDataRating(dataFilter);
  }, []);

  const initialValues: ICreateShippingTableForm = {
    description: '',
    dateInitial: '',
    finalDate: '',
    type: '',
    filialOrigem: '',
    operadorLogistico: '',
    tipoVeiculo: '',
    veiculo: '',
    tipoAtendimento: '',
    qtdKM: '',
    valueKM: '',
    surplusValue: '',
    initialDay: '',
    zipcode: '',
    valueZipcode: '',
    obs: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      selectedRow && selectedRow.publicada
        ? formValidatorRatingPublished
        : formValidatorRatingPT,
    onSubmit: (values: ICreateShippingTableForm) => {
      onRegisterRating(values, isEditRating, updateIdRanting, dataRating);
    },
  });

  function convertDecimalValue(currency: any) {
    var currency: any = currency; //it works for US-style currency strings as well
    var cur_re = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
    var parts: any = cur_re.exec(currency);
    var number = parseFloat(
      parts[1].replace(/\D/, '') + '.' + (parts[2] ? parts[2] : '00')
    );

    console.log('Number', typeof parts[0]);

    if (parseFloat(parts[0]) > 0) {
      console.log('entrou');
      return number.toFixed(2);
    } else {
      return String(parts[0]).replace(',', '.');
    }
  }

  const isDateAfterToday = (dateString: any) => {
    const date = new Date(dateString);
    const today = new Date();

    // Defina a hora, minuto, segundo e milissegundo da data atual como 0
    today.setHours(0, 0, 0, 0);

    return date > today;
  };

  const onSubmitShipping = useCallback(
    async (
      values: ICreateShippingTableForm,
      rating: any[],
      updateId: number
    ) => {
      try {
        setLoading(true);
        console.log('BeforeRating', rating);
        if (isDateAfterToday(values.finalDate)) {
          const onMappingRating = rating.map((item: any) => {
            let formattedExcessValue = String(item.franquias[0].valorExcedente);
            if (formattedExcessValue.includes(',')) {
              formattedExcessValue = formattedExcessValue.replace(/\./g, '');
            }
            formattedExcessValue = formattedExcessValue.replace(',', '.');
            const excessValue = parseFloat(formattedExcessValue);

            let formattedValue = String(
              values.type === 'CEP'
                ? item.franquias[0].valueZipcode
                : item.franquias[0].valor
            );
            if (formattedValue.includes(',')) {
              formattedValue = formattedValue.replace(/\./g, '');
            }
            formattedValue = formattedValue.replace(',', '.');
            const value = parseFloat(formattedValue);

            let vehicleType = item.tipoVeiculo;

            return {
              ...item,
              id: null,
              filialOrigem:
                item.filialOrigem.id !== null ? item.filialOrigem : null,
              operadorLogistico:
                item.operadorLogistico.id.length > 0
                  ? item.operadorLogistico
                  : null,
              tipoVeiculo: vehicleType.length > 0 ? vehicleType : null,
              veiculo: item.veiculo.id.length > 0 ? item.veiculo : null,
              tipoConhecimento: null,
              franquias: [
                {
                  km:
                    String(item.franquias[0].qtd).length > 0
                      ? item.franquias[0].qtd
                      : 0,
                  valor: values.type === 'CEP' ? value : value,
                  valorExcedente:
                    String(item.franquias[0].valorExcedente).length > 0
                      ? excessValue
                      : 0,
                  diaInicio:
                    String(item.franquias[0].diaInicio).length > 0
                      ? item.franquias[0].diaInicio
                      : 0,
                  cep:
                    item.franquias[0].zipcode.length > 0
                      ? item.franquias[0].zipcode.split('-')[0]
                      : null,
                },
              ],
            };
          });

          console.log('After Rating', onMappingRating);
          if (rating.length <= 0) {
            FrontendNotification(
              t('shippingTable.messageErrorMinRating'),
              NotificationType.WARNING
            );
          } else {
            const requestData = {
              descricao: values.description,
              tipo: values.type,
              dataInicial: values.dateInitial,
              dataFinal: values.finalDate,
              classificadores: onMappingRating,
            };

            const response = await api.patch(
              `delivery/tabelas/frete/${updateId}`,
              requestData
            );

            if (response.status === 204) {
              FrontendNotification(
                'Tabela de Frete atualizada com sucesso!',
                NotificationType.SUCCESS
              );
              onHandleSubmit();
            }

            setLoading(false);
          }
          setLoading(false);
        } else {
          FrontendNotification(
            'Data deve ser maior que a data atual',
            NotificationType.WARNING
          );
          setLoading(false);
        }
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const onRegisterRating = useCallback(
    async (
      values: ICreateShippingTableForm,
      isEdit: boolean,
      updateIdRanting: any,
      dataUpdate: any[]
    ) => {
      try {
        let rantingData: any[] = dataUpdate;

        let typeVehicle = values.tipoVeiculo;
        if (values.tipoVeiculo && values.tipoVeiculo.length <= 1) {
          typeVehicle = values.tipoVeiculo.replace('S', '');
        }
        const data = {
          filialOrigem: {
            id: values.filialOrigem,
          },
          operadorLogistico: {
            id: values.operadorLogistico,
          },
          tipoVeiculo: typeVehicle,
          veiculo: {
            id:
              values.veiculo && values.veiculo.length > 0 ? values.veiculo : '',
          },
          tipoConhecimento: '',
          obs: values.obs,
          franquias: [
            {
              qtd: values.qtdKM,
              valor: values.valueKM,
              valorExcedente: values.surplusValue,
              diaInicio: values.initialDay,
              zipcode:
                values.zipcode && values.zipcode.length > 0
                  ? values.zipcode
                  : '',
              valueZipcode: values.valueZipcode,
            },
          ],
        };

        formik.resetForm({
          values: {
            description: values.description,
            type: values.type,
            dateInitial: values.dateInitial,
            finalDate: values.finalDate,
            filialOrigem: '',
            operadorLogistico: '',
            tipoVeiculo: '',
            veiculo: '',
            qtdKM: '',
            valueKM: '',
            surplusValue: '',
            initialDay: '',
            zipcode: '',
            valueZipcode: '',
            obs: ''
          },
        });

        if (isEdit) {
          const updatedRowData: any[] = dataUpdate.map((row, indexRow) => {
            console.log(row.id);
            if (indexRow == updateIdRanting) {
              return { id: row.id, ...data };
            }
            return row;
          });
          setDataRating(updatedRowData);
        } else {
          const registerData = {
            ...data,
            id: dataUpdate[dataUpdate.length - 1].id + 1
          }
          setDataRating((oldArray: any) => [...oldArray, registerData]);
        }

        setIsEditRanting(false);
      } catch {}
    },
    []
  );
  console.log('Edit Data', selectedRow);

  const onEditContent = useCallback((id: number, data: any[]) => {
    const getRowData = data[0];

    setSelectedRow(getRowData);

    formik.setFieldValue('description', getRowData.descricao);
    formik.setFieldValue('type', getRowData.tipo);
    formik.setFieldValue('dateInitial', getRowData.dataInicial);
    formik.setFieldValue('finalDate', getRowData.dataFinal);

    const onAddRowRating = getRowData.classificadores.map((rating: any, index: number) => {
      return {
        id: index + 1,
        filialOrigem: {
          id:
            rating.filialOrigem !== null ? String(rating.filialOrigem.id) : '',
        },
        operadorLogistico: {
          id:
            rating.operadorLogistico !== null
              ? String(rating.operadorLogistico.id)
              : '',
        },
        tipoVeiculo: rating.tipoVeiculo !== null ? rating.tipoVeiculo : '',
        veiculo: {
          id: rating.veiculo !== null ? String(rating.veiculo.id) : '',
        },
        tipoConhecimento: '',
        franquias: [
          {
            qtd: rating.franquias[0].km !== null ? rating.franquias[0].km : '',
            valor:
              rating.franquias[0].valor !== null
                ? rating.franquias[0].valor
                : '',
            valorExcedente:
              rating.franquias[0].valorExcedente !== null
                ? rating.franquias[0].valorExcedente
                : '',
            diaInicio:
              rating.franquias[0].diaInicio !== null
                ? rating.franquias[0].diaInicio
                : '',
            zipcode:
              rating.franquias[0].cep !== null
                ? String(rating.franquias[0].cep)
                : '',
            valueZipcode:
              rating.franquias[0].valor !== null
                ? rating.franquias[0].valor
                : '',
          },
        ],
      };
    });

    setDataRating(onAddRowRating);
  }, []);

  /**
   * data: Retorna a lista de tabela de frete
   * dataRating: Retorna uma lista de classificadores.
   */
  const onEditRow = useCallback((data: any[], dataRating: any[]) => {
    const findIndexRatingByData = dataRating.findIndex(i => i.id == data[0].id);

    const getRowData = data[0];

    formik.setFieldValue('filialOrigem', getRowData.filialOrigem.id);
    formik.setFieldValue('operadorLogistico', getRowData.operadorLogistico.id);
    formik.setFieldValue('tipoVeiculo', getRowData.tipoVeiculo);
    formik.setFieldValue('veiculo', getRowData.veiculo.id);
    formik.setFieldValue('qtdKM', getRowData.franquias[0].qtd);
    formik.setFieldValue(
      'valueKM',
      formatNumber(getRowData.franquias[0].valor)
    );
    formik.setFieldValue(
      'surplusValue',
      formatNumber(getRowData.franquias[0].valorExcedente)
    );
    formik.setFieldValue('initialDay', getRowData.franquias[0].diaInicio);
    formik.setFieldValue('zipcode', `${getRowData.franquias[0].zipcode}`);
    formik.setFieldValue(
      'valueZipcode',
      formatNumber(getRowData.franquias[0].valueZipcode)
    );
    formik.setFieldValue('obs', getRowData.obs);

    setIsEditRanting(true);
    setUpdateIdRanting(findIndexRatingByData);

    setDataRating(dataRating);
  }, []);

  console.log(formik.values);

  useEffect(() => {
    if (isEdit) {
      onEditContent(editId, data);
    }
  }, [data]);

  return (
    <>
      <Loading loading={loading} />
      <Container>
        <Header
          isBlocked={dataRating.length > 0}
          onChange={(field: string, value: string) =>
            formik.setFieldValue(field, value)
          }
          errors={formik.errors}
          values={formik.values}
          touched={formik.touched}
          isView={selectedRow && selectedRow.publicada}
        />

        <Wrapper>
          <Rating
            onChange={(field: string, value: string) =>
              formik.setFieldValue(field, value)
            }
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            isView={selectedRow && selectedRow.publicada}
          />
          <ShippingContent
            onSubmit={formik.handleSubmit}
            onChange={(field: string, value: any) =>
              formik.setFieldValue(field, value)
            }
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isView={selectedRow && selectedRow.publicada}
          />
          <Content>
            <ListShipping
              data={dataRating}
              type={formik.values.type}
              onDelete={(data: any[]) => onDeleteRow(dataRating, data)}
              onEdit={(data: any[]) => onEditRow(data, dataRating)}
              onSubmitShipping={() =>
                onSubmitShipping(formik.values, dataRating, editId)
              }
            />
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};

export default EditShippingTable;
