import React from 'react';
import sinal100 from '../../assets/images/sinal100.svg';
import sinal70 from '../../assets/images/sinal70.svg';
import sinal50 from '../../assets/images/sinal50.svg';
import sinal20 from '../../assets/images/sinal20.svg';

export default function OperatorSignal({ porcentage }) {
  let signalIcon;

  if (porcentage >= 4) {
    signalIcon = sinal100;
  } else if (porcentage === 3) {
    signalIcon = sinal70;
  } else if (porcentage === 2) {
    signalIcon = sinal50;
  } else if (porcentage <= 1) {
    signalIcon = sinal20;
  }

  return (
    <div style={{ marginRight: '5px' }}>
      <img
        src={signalIcon}
        width="24"
        alt=""
        height="24"
        style={{ marginRight: 10 }}
      />
    </div>
  );
}
