// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-heading {
  background: #fff !important;
  color: white !important;
  border: none !important;
  border-radius: 10px !important;
  font-family: "Lato" !important;
  width: 100% !important;
}

.dropdown-heading-dropdown-arrow {
  border-color: rgb(153,153,153) !important;
}

.multi-select {
  width: 168px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadCSV/CorrectCSV/Table/Filters/FilterTooltip/styles.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,uBAAuB;EACvB,uBAAuB;EACvB,8BAA8B;EAC9B,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".dropdown-heading {\n  background: #fff !important;\n  color: white !important;\n  border: none !important;\n  border-radius: 10px !important;\n  font-family: \"Lato\" !important;\n  width: 100% !important;\n}\n\n.dropdown-heading-dropdown-arrow {\n  border-color: rgb(153,153,153) !important;\n}\n\n.multi-select {\n  width: 168px;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
