import styled from 'styled-components';
import { responsiveSize } from '../../styles/responsive';

export const Content = styled.div`
  background-color: #fff;
  border: ${responsiveSize(2)} solid #edf2f4 !important;
  border-radius: 10px;
  padding: ${responsiveSize(20)};
  width: 90%;
  margin-top: 50px;
  .lineGroup {
    border-bottom: 1px solid #ccd6db;
  }
`;

export const BoxForm = styled.div`
  width: 50%;
  display: flex;
  margin-right: ${responsiveSize(20)};
  flex-direction: column;

  .button {
    height: ${responsiveSize(35)};
    background: #ea004c;
    border-radius: ${responsiveSize(6)};
    text-align: center;
    color: #ffffff;
    /* float: right; */
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-size: ${responsiveSize(18)};
    font-weight: bold;
    line-height: ${responsiveSize(19)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: ${responsiveSize(0)} ${responsiveSize(4)} ${responsiveSize(6)} rgba(234, 0, 76, 0.2);
    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: ${responsiveSize(0)} ${responsiveSize(4)} ${responsiveSize(6)} rgba(234, 0, 76, 0.4);
    }
  }
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  margin-bottom: ${responsiveSize(0)};
  .button {
    height: ${responsiveSize(35)};
    background: #ea004c;
    border-radius: ${responsiveSize(6)};
    text-align: center;
    color: #ffffff;
    /* float: right; */
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    font-size: ${responsiveSize(16)};
    font-weight: bold;
    line-height: ${responsiveSize(19)};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: ${responsiveSize(0)} ${responsiveSize(4)} ${responsiveSize(6)} rgba(234, 0, 76, 0.2);
    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: ${responsiveSize(0)} ${responsiveSize(4)} ${responsiveSize(6)} rgba(234, 0, 76, 0.4);
    }
  }
  .table-line {
    margin-top: ${responsiveSize(20)};
    width: ${responsiveSize(320)};
  }
`;
