import produce from 'immer';

const INITIAL_STATE = {
  data: null,
  dataFilters: [],
  dataTable: [],
};

export default function kpis(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@kpis/SET_DATA_KPI': {
        draft.data = action.payload.data;
        break;
      }
      case '@kpis/SET_FILTERS_KPI': {
        draft.dataFilters = action.payload.dataFilters;
        break;
      }
      case '@kpis/UPDATE_FILTERS_KPI_SUBSIDIARY': {
        draft.dataFilters[action.payload.indexFilters].value = action.payload.dataValue;
        break;
      }
      case '@kpis/SET_DATA_TABLE': {
        draft.dataTable = action.payload.data;
        break;
      }
      default:
    }
  });
}
