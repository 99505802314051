export const colors = [
  "#FF0000",
  "#0000ff",
  "#FFFF00",
  "#008000",
  "#ffa500",
  "#993399",
  "#000000",
  "#808080",
  "#ffcbdb",
  "#b7d5e5"
]