import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import React from 'react';
import { Form, FormFields, FormGroupFields, LabelGroupFields } from './styles';

interface HeaderProps {
  onChange: (field: string, value: string) => void;
  isBlocked: boolean;
  values: any;
  errors: any;
  touched: any;
  isView?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  onChange,
  isBlocked = false,
  values,
  errors,
  touched,
  isView = false,
}: HeaderProps) => {
  return (
    <Form>
      <FormFields>
        <InputCustom
          placeholder="Insira o nome da tabela"
          title="Nome da tabela*"
          onChange={(e: any) => onChange('description', e.target.value)}
          className="inputHeader"
          error={errors.description}
          touched={touched.description}
          value={values.description}
          disabled={isView}
          id='description'
        />
      </FormFields>
      <FormFields>
        <SelectCustom
          onChange={(e: any) => onChange('type', e.target.value)}
          title="Tipo da tabela"
          style={{ width: 217 }}
          disabled={isBlocked}
          error={errors.type}
          touched={touched.type}
          value={values.type}
          id='type'
        >
          <option label="Selecione o tipo" />
          <option label="Valor Fixo" value="VALOR_FIXO" />
          <option label="CEP" value="CEP" />
        </SelectCustom>
      </FormFields>
      <FormGroupFields>
        <FormFields>
          <LabelGroupFields>Vigência da tabela</LabelGroupFields>
          <FormGroupFields>
            <FormFields size>
              <InputCustom
                type="date"
                placeholder="Data inicial"
                onChange={(e: any) => {
                  console.log(e.target.value);
                  onChange('dateInitial', e.target.value);
                }}
                className="inputHeader"
                error={errors.dateInitial}
                touched={touched.dateInitial}
                value={values.dateInitial}
                disabled={isView}
                id='dateInitial'
              />
            </FormFields>

            <FormFields size>
              <InputCustom
                type="date"
                placeholder="Data inicial"
                onChange={(e: any) => onChange('finalDate', e.target.value)}
                className="inputHeader"
                error={errors.finalDate}
                touched={touched.finalDate}
                value={values.finalDate}
                id='finalDate'
              />
            </FormFields>
          </FormGroupFields>
        </FormFields>
      </FormGroupFields>
    </Form>
  );
};

export default Header;
