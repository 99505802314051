import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { pt } from 'date-fns/locale';
import TopBar from 'pages/ManagementForms/TopBar';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import './styles.scss';

const ReportsMetabase = () => {
  const dateStart = format(
    startOfWeek(new Date(), { weekStartsOn: 0 }),
    'yyyy-MM-dd'
  );
  const dateEnd = format(
    endOfWeek(new Date(), { weekStartsOn: 0 }),
    'yyyy-MM-dd'
  );

  const [startDate, setStartDate] = useState<any>(dateStart);
  const [endDate, setEndDate] = useState<any>(dateEnd);
  const [journeyResults, setJourneyResults] = useState<any>([]);
  const [resources, setResources] = useState<any>([]);
  const [resourceSelected, setResourceSelected] = useState<any>('-1');
  const [listSubsidiary, setListSubsidiary] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getResources = useCallback(async () => {
    try {
      const subsidiaryId = sessionStorage.getItem('subsidiary');

      const data = {
        filters: [
          {
            field: 'subsidiary.id',
            operation: 'EQUAL',
            value: subsidiaryId,
          },
        ],
        orders: [],
        page: 0,
        size: 100,
      };

      if (subsidiaryId !== null && subsidiaryId.length > 0) {
        const response = await api.post('/workday/resources/pageable', data);

        setResources(response.data.content);
      }
    } catch {}
  }, []);

  const getReportsJourney = useCallback(
    async (dateStart?: string, dateEnd?: string, resourceId?: string) => {
      try {
        setLoading(true);
        const initialStartDate = dateStart
          ? dateStart
          : format(new Date(startDate), 'yyyy-MM-dd');
        const initialEndDate = dateEnd
          ? dateEnd
          : format(new Date(endDate), 'yyyy-MM-dd');

        const susbsidiaryId = sessionStorage.getItem('subsidiary');

        if (susbsidiaryId !== null && susbsidiaryId.length > 0) {
          const response = await api.get(
            `/workday/pcontrols/hours/${susbsidiaryId}?start=${initialStartDate}T00:00:00&end=${initialEndDate}T00:00:00&resourceId=${
              resourceId ? resourceId : -1
            }`
          );

          setJourneyResults(response.data);
        }

        setLoading(false);
      } catch {}
    },
    []
  );

  const getSubsidiary = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      setListSubsidiary(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getResources();
    getReportsJourney();
    getSubsidiary();
  }, [getResources, getReportsJourney]);
  return (
    <React.Fragment>
      <Loading loading={loading} />
      <TopBar
        listSubsidiary={listSubsidiary}
        selectSubsidiary={(value: string) => {
          if (value.length > 0) {
            getResources();
            getReportsJourney();
          }
        }}
      />

      <div
        style={{
          width: '98%',
          marginTop: '40px',
          marginLeft: '10px',
          overflowX: 'scroll',
          display: 'flex',
          padding: 20,
          flexDirection: 'column',
          border: '1px solid #CCD6DB',
          borderRadius: '5px',
          justifyContent: 'center',
        }}
      >
        <Row>
          <Col md={4}>
            <SelectCustom
              onChange={e => {
                setResourceSelected(e.target.value);
              }}
              id="resource"
              title="Funcionário"
            >
              <option label="Todos" value="-1" />

              {resources.map((item: any) => (
                <option label={item.name} value={item.id} />
              ))}
            </SelectCustom>
          </Col>

          <Col md={3}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InputCustom
                  title="Data Inicial"
                  id="dateStart"
                  onChange={(e: any) => {
                    setStartDate(e.target.value);
                  }}
                  value={startDate}
                  type="date"
                  placeholder="Data inicial"
                  style={{ marginRight: 20, width: 125 }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <InputCustom
                  title="Data Final"
                  id="dateEnd"
                  onChange={(e: any) => {
                    setEndDate(e.target.value);
                  }}
                  type="date"
                  value={endDate}
                  placeholder="Data final"
                  style={{ width: 125 }}
                />
              </div>
            </div>
          </Col>

          <Col md={2}>
            <button
              className="button"
              type="button"
              id="buttonFilter"
              style={{ marginTop: 30, width: 100 }}
              onClick={() => {
                getReportsJourney(startDate, endDate, resourceSelected);
              }}
            >
              Filtrar
            </button>
          </Col>
        </Row>

        <table className="tablesJourney" style={{ marginTop: 10 }}>
          <thead>
            <th>
              <div className="reportsColumn">
                <span className="headerJourney">Funcionário</span>
              </div>
            </th>
            <th>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className="headerJourney">
                  Banco de horas <br /> no início de:
                </span>
                <span className="headerJourneyDate">
                  {format(
                    new Date(startDate).setDate(
                      new Date(startDate).getDate() + 1
                    ),
                    'dd/MM/yyy',
                    {
                      locale: pt,
                    }
                  )}
                </span>
              </div>
            </th>
            <th>
            <div className="reportsColumn">

              <span className="headerJourney">Horas extras</span>
              </div>
            </th>
            <th>
            <div className="reportsColumn">

              <span className="headerJourney">Pagamentos e deduções</span>
              </div>

            </th>
            <th>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span className="headerJourney">
                  Banco de horas <br /> no final de:
                </span>
                <span className="headerJourneyDate">
                  {format(
                    new Date(endDate).setDate(new Date(endDate).getDate() + 1),
                    'dd/MM/yyy',
                    {
                      locale: pt,
                    }
                  )}
                </span>
              </div>
            </th>
          </thead>
          <tbody>
            {journeyResults.map((item: any) => (
              <tr style={{ borderRight: '1px solid #CCD6DB' }}>
                <td className="rowJourney">
                  <div className="alignRowJourney">
                    <span className="rowText">{item.resource.name}</span>
                  </div>
                </td>
                <td className="rowJourney">
                  <div className="alignRowJourney">
                    <span className="rowText">
                      {item.startDate.timeInHours}
                    </span>
                  </div>
                </td>
                <td className="rowJourney">
                  <div className="alignRowJourney">
                    <span className="rowText">
                      {item.extraHour.timeInHours}
                    </span>
                  </div>
                </td>
                <td className="rowJourney">
                  <div className="alignRowJourney">
                    <span className="rowText">
                      {item.deductionOrPayment.timeInHours}
                    </span>
                  </div>
                </td>
                <td className="rowJourney">
                  <div className="alignRowJourney">
                    <span className="rowText">{item.endDate.timeInHours}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default ReportsMetabase;
