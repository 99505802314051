import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap/lib';
import i18next from 'i18next';
import CustomTable from '../../CustomTable';
import Pagination from '../../Pagination';

import iconOrderTop from '../../../assets/images/iconOrderTop.svg';
import iconOrderBottom from '../../../assets/images/iconOrderBottom.svg';
import searchIcon from '../../../assets/images/ant-design_search-outlined.png';

export default function ListContractor({ handleEdit, data, handleDelete }) {
  const [totalPages, setTotalPages] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [contractors, setContractors] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([
    {
      Header: i18next.language === 'pt-BR' ? 'ID Referencia' : 'Reference ID',
      accessor: 'idReferencia',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'CNPJ' : 'CNPJ',
      accessor: 'cnpj',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'Razão Social' : 'Company Name',
      accessor: 'razaoSocial',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'Nome Fantasia' : 'Trademark Name',
      accessor: 'nomeFantasia',
    },
  ]);

  const padronizeData = useCallback(async values => {
    const padronizedContractors = [];
    values.map(async element => {
      padronizedContractors.push({
        id: element.id,
        idReferencia: element.idReferencia,
        cnpj: element.pessoaJuridica.cnpj,
        razaoSocial: element.pessoaJuridica.razaoSocial,
        nomeFantasia: element.pessoaJuridica.nomeFantasia,
      });
    });
    setContractors(padronizedContractors);
  }, []);

  useEffect(() => {
    padronizeData(data);
  }, [data, padronizeData]);
  const { t } = useTranslation();

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <CustomTable
        showDelete
        showEdit
        columns={columns}
        data={contractors}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <Pagination
        perPage={perPage}
        total={totalPages}
        onPaginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
