import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import IconExportFilters from '../../../assets/images/iconExportPdfButton.svg';
import Input from './components/Input';
import Select from './components/Select';
import { Container, ItemFilters, Line, Title, WrapperFilters } from './styles';

// import { Container } from './styles';

interface OptionsField {
  label: string;
  value: string | number;
}

interface FiltersForm {
  name: string;
  fields: FiltersFormFields[];
}

interface FiltersFormFields {
  label: string;
  name: string;
  type: string;
  value?: any;
  // placeholder: string;
  // options: OptionsField[];
  // default: null | string | boolean | number;
}

interface FiltersProps {
  onClear: any;
  reportId: number;
  reportData: any;
}

const Filters: React.FC<FiltersProps> = ({
  onClear,
  reportId,
  reportData,
}: FiltersProps) => {
  const [dataFilters, setDataFilters] = useState<FiltersFormFields[]>([]);
  const [typeFileExport, setTypeFileExport] = useState<string>("PDF");
  const [loading, setLoading] = useState(false);

  const onExportByParameters = useCallback(
    async (filters: FiltersFormFields[], typeFile: string) => {
      try {
        setLoading(true);
        const mappingSendParameters = filters.map(
          (parameter: FiltersFormFields) => {
            return {
              name: parameter.name,
              type: parameter.type,
              value: parameter.value,
            };
          }
        );
        const response = await api.post(
          `/reportBuilder/reports/view/${reportId}/${typeFile}`,
          mappingSendParameters,
          {
            responseType: 'blob',
          }
        );

        const typeFileDownload = typeFile === "PDF" ? 'application/pdf;charset=utf-8' : 'text/csv;charset=utf-8';

        // const buffer = new ArrayBuffer(response.data);

        // const binaryString = Buffer.from(response.data, 'base64');
        var blob = new Blob([response.data], {
          type: typeFileDownload,
        });
        var url = URL.createObjectURL(blob);
        window.open(url);
        // document.body.removeChild(a);
        // URL.revokeObjectURL(url);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const onLoadParameters = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/reportBuilder/reports/parameters/${reportId}`
      );

      const mappingParameters = response.data.map((parameter: any) => {
        return {
          ...parameter,
          label: parameter.name
            .replaceAll('_', ' ')
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str: any) => str.toUpperCase()),
          value: '',
        };
      });
      setDataFilters(mappingParameters);

      setLoading(false);
    } catch {}
  }, []);

  // console.log(dataFilters);

  useEffect(() => {
    onLoadParameters();
  }, [onLoadParameters]);
  return (
    <CustomModal label={reportData.description} isClose={onClear} isOpen>
      <Loading loading={loading} />
      <Container>
        <Title>Filtros</Title>
        <Line />
        <WrapperFilters>
          <ItemFilters>
            <Select
              title="Tipo"
              onChange={(e) => setTypeFileExport(e.target.value)}
              value={typeFileExport}
              options={[
                {
                  label: 'PDF',
                  value: 'PDF',
                },
                {
                  label: 'CSV',
                  value: 'CSV',
                },
              ]}
            />
          </ItemFilters>
          {dataFilters.map((item: FiltersFormFields, index: number) => (
            <ItemFilters>
              {item.type === 'String' ? (
                <Input
                  title={item.label}
                  onChange={(e: any) => {
                    let changeDataFilters = dataFilters;

                    changeDataFilters[index].value = e.target.value;

                    setDataFilters(changeDataFilters);
                  }}
                  placeholder=""
                />
              ) : item.type === 'Datetime' ? (
                <Input
                  title={item.label}
                  onChange={(e: any) => {
                    let changeDataFilters = dataFilters;

                    changeDataFilters[index].value = `${e.target.value.replace(
                      'T',
                      ' '
                    )}:00`;

                    setDataFilters(changeDataFilters);
                  }}
                  placeholder=""
                  type="datetime-local"
                />
              ) : (
                <></>
              )}
            </ItemFilters>
          ))}
        </WrapperFilters>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <></>
          </div>
          <div style={{ width: '200px', marginTop: 20 }}>
            <button
              className="button"
              type="button"
              onClick={() => onExportByParameters(dataFilters, typeFileExport)}
              style={{ maxWidth: '205px', height: '35px' }}
            >
              <img src={IconExportFilters} />
              Exportar
            </button>
          </div>
        </div>
      </Container>
    </CustomModal>
  );
};

export default Filters;
