import {
  Card,
  Contractor,
  ContractorLabel,
  NumberDelivery,
  NumberDeliveryLabel,
  NumberOrder, ServiceColumn,
  ServiceRow,
  StatusLabel
} from 'components/CardKnowledge/styles';
import Loading from 'core/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import closeModalIcon from '../../../../assets/images/closeIcon.svg';
import IconArrowDown from '../../../../assets/images/knowledge/arrowDown.svg';
import IconArrowUp from '../../../../assets/images/knowledge/arrowUp.svg';
import api from '../../../../services/api';
import './styles.scss';

export default function ListShipment({ onClear, idRoute, alertCargo }) {
  const [modal, setModal] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [knowledge, setKnowledge] = useState([]);
  const [moreDetailsListKnowledge, setMoreDetailsListKnowledge] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [moreDetails, setMoreDetails] = useState({
    id: 0,
    collapse: true,
    collapse2: true,
  });
  const [dataReceiver, setDataReceiver] = useState();
  const [typeKnowledge, setTypeKnowledge] = useState();
  const [activeReceiver, setActiveReceiver] = useState(false);

  function clearNav() {
    setModal(!modal);
    onClear();
  }

  const getCargoData = useCallback(async () => {
    try {
      setLoading(true);
      let dataCargo;

      dataCargo = {
        filters: [
          {
            field: 'id',
            value: idRoute,
            operation: 'EQUAL',
          },
          {
            field: 'status',
            value: 'INACTIVE',
            operation: 'EQUAL',
          },
        ],
        orders: [],
        size: 1,
        page: 0,
      };
      const { data } = await api.post('/route/steps/pageable', dataCargo);

      setKnowledge(data.content);

      setLoading(false);
    } catch (err) {}
  }, [idRoute]);

  function toggleCollapse(id, collapse) {
    setMoreDetails({
      id,
      collapse,
    });
  }

  useEffect(() => {
    getCargoData();
  }, [getCargoData]);

  return (
    <>
    <Loading loading={loading} />
      <div style={{ width: '100%'}}>
        <div className="wrapperAttendances">
          {knowledge.map((c, index) => (
            <Card
              key={index}
              className="box"
              colorActive="#08b639"
              style={{ position: 'relative', width: "400px",  }}
            >
              <>
                <div
                  className="iconClose"
                  style={{
                    position: "absolute",
    top: "-28px",
    right: "-12px",
    padding: "20px",
                  }}
                >
                  <button
                    style={{ background: 'transparent', border: 'none' }}
                    type="button"
                    onClick={() => clearNav()}
                  >
                    <img src={closeModalIcon} alt="" />
                  </button>
                </div>
                <ServiceRow>
                  <NumberOrder size={c.ordemEntrega <= 99 ? 'normal' : 'max'}>
                    <span>{c.order}</span>
                  </NumberOrder>
                  <ServiceColumn size={30}>
                    <NumberDeliveryLabel>
                      {c.conhecimentoPre.tipo === 'Entrega' ? (
                        <Trans i18nKey="knowledge.deliveryNumber" />
                      ) : (
                        <Trans i18nKey="knowledge.collectionNumber" />
                      )}
                    </NumberDeliveryLabel>
                    <NumberDelivery>{c.conhecimentoPre.numero}</NumberDelivery>
                  </ServiceColumn>
                  <ServiceColumn size={40}>
                    <StatusLabel>
                      <Trans i18nKey="knowledge.status" />
                    </StatusLabel>
                  </ServiceColumn>
                  <ServiceColumn size={40}>
                    <NumberDeliveryLabel>
                      <Trans i18nKey="knowledge.eta" />
                    </NumberDeliveryLabel>
                    <NumberDelivery>---</NumberDelivery>
                  </ServiceColumn>
                </ServiceRow>
                <ServiceRow>
                  <ServiceColumn>
                    <ContractorLabel>Contratante</ContractorLabel>

                    <Contractor>
                      {c.conhecimentoPre.contratante.length > 0
                        ? c.conhecimentoPre.contratante
                        : '---'}
                    </Contractor>
                  </ServiceColumn>
                </ServiceRow>
                <ServiceRow>
                  <ServiceColumn size={70}>
                    <ContractorLabel>
                      <Trans i18nKey="knowledge.client" />
                    </ContractorLabel>

                    <Contractor className="uriLimit">
                      {c.conhecimentoPre.clienteNome}
                    </Contractor>
                  </ServiceColumn>
                  <ServiceColumn size={30}>
                    <ContractorLabel>Pulou Atend.</ContractorLabel>

                    <Contractor>---</Contractor>
                  </ServiceColumn>
                </ServiceRow>
                <ServiceRow>
                  <ServiceColumn size={60}>
                    <ContractorLabel>Etapa Atual</ContractorLabel>

                    <Contractor>---</Contractor>

                    {/* {c.etapa !== null && (
                    <span
                      className="linkButton"
                      onClick={() => {
                        setDelivery(!delivery);
                        setDeliveryId(c.id);
                        setNumberKnowledge(c.numero);
                        setTypeKnowledge(c.tipo);
                      }}
                    >
                      <Trans i18nKey="knowledge.viewSteps" />
                    </span>
                  )} */}
                  </ServiceColumn>
                  <ServiceColumn size={40}>
                    <ContractorLabel>Tipo atividade</ContractorLabel>

                    <Contractor>
                      {c.conhecimentoPre.tipoAtividade.length > 0
                        ? c.tipoAtividade
                        : '---'}
                    </Contractor>
                  </ServiceColumn>
                </ServiceRow>
                <hr />
                <ServiceRow>
                  <ContractorLabel>
                    <Trans i18nKey="knowledge.address" />
                  </ContractorLabel>
                </ServiceRow>
                <ServiceRow>
                  {/* <img
                  src={maps}
                  width="20"
                  alt=""
                  style={{ marginTop: 10, cursor: 'pointer' }}
                  onClick={() => {
                    setPositionMap({
                      romaneioId: knowledge[index].romaneio.id,
                      latitude: knowledge[index].endereco.latitude,
                      longitude: knowledge[index].endereco.longitude,
                    });
                    setGetRoutes(!getRoutes);
                  }}
                />
                <div className="line" /> */}
                  <ServiceColumn>
                    <Contractor>{`${c.conhecimentoPre.enderecoLogradouro}, ${c.conhecimentoPre.enderecoNumero}
            ${c.conhecimentoPre.enderecoBairro}, ${c.conhecimentoPre.enderecoCidade}, ${c.conhecimentoPre.enderecoUf}`}</Contractor>
                  </ServiceColumn>
                </ServiceRow>
                <hr />

                <Row>
                  <Col xs="12">
                    <button
                      type="button"
                      className="more-details btn align-items"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleCollapse(c.id, false);
                      }}
                    >
                      <span
                        className="more-details"
                        style={{ fontFamily: 'Lato' }}
                      >
                        <Trans i18nKey="knowledge.moreDetails" />
                      </span>
                      <img
                        src={IconArrowDown}
                        alt=""
                        className="icon-custom fa left-margin"
                      />
                    </button>
                  </Col>
                </Row>
                {c.id == moreDetails.id && moreDetails.collapse == false && (
                  <>
                    <ServiceRow>
                      <ServiceColumn size={50}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.deliveryTime" />
                        </ContractorLabel>

                        <Contractor>---</Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={50}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.idReference" />
                        </ContractorLabel>

                        <Contractor>
                          {c.conhecimentoPre.idReferencia}
                        </Contractor>
                      </ServiceColumn>
                    </ServiceRow>
                    <ServiceRow>
                      <ServiceColumn size={50}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.cityFrom" />
                        </ContractorLabel>

                        <Contractor>
                          {/* {c.filialOrigem.endereco.uf} -{' '}
                          {c.filialOrigem.endereco.cidade}{' '} */}
                          ---
                        </Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={50}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.cityTo" />
                        </ContractorLabel>

                        <Contractor>
                          {/* {c.filialDestino.endereco.uf} -{' '}
                          {c.filialDestino.endereco.cidade}{' '} */}
                          ---
                        </Contractor>
                      </ServiceColumn>
                    </ServiceRow>
                    <ServiceRow>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.typeTransport" />
                        </ContractorLabel>

                        <Contractor>
                          <Trans i18nKey="knowledge.nameTransport" />
                        </Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.weight" />
                        </ContractorLabel>

                        <Contractor>{c.conhecimentoPre.peso}KG</Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.volume" />
                        </ContractorLabel>

                        <Contractor>{c.conhecimentoPre.qtdVolumes}</Contractor>
                      </ServiceColumn>
                    </ServiceRow>
                    <ServiceRow>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.tracking" />
                        </ContractorLabel>

                        <Contractor>
                          <Trans i18nKey="common.no" />
                        </Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.dangerGood" />
                        </ContractorLabel>

                        <Contractor>
                          <Trans i18nKey="common.no" />
                        </Contractor>
                      </ServiceColumn>
                      <ServiceColumn size={30}>
                        <ContractorLabel>
                          <Trans i18nKey="knowledge.value" />
                        </ContractorLabel>

                        <Contractor>
                          R$ {parseFloat(c.conhecimentoPre.valor).toFixed(2)}
                        </Contractor>
                      </ServiceColumn>
                    </ServiceRow>
                    
                    <hr />
                    <button
                      type="button"
                      className="more-details btn align-items"
                      style={{
                        fontSize: 13,
                        color: '#EA004C',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        toggleCollapse(c.id, true);
                      }}
                    >
                      <Trans i18nKey="knowledge.lessDetail" />
                      <img
                        src={IconArrowUp}
                        alt=""
                        className="icon-custom fa left-margin"
                      />
                    </button>
                  </>
                )}
              </>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}