import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// import { Container } from './styles';

interface TawkAPI {
  setAttributes: (
    attributes: any,
    callback?: (error: any) => void
  ) => void;
  setLanguage: any;
  onLoad?: () => void;
  visitor: any;
  customStyle: any;
}

// Declaração do Tawk_API no escopo global
declare global {
  interface Window {
    Tawk_API: TawkAPI | undefined;
  }
}

const Chat: React.FC = () => {
  const userData = useSelector((state: any) => state.auth.user);
  
  useEffect(() => {
    // Adiciona o script do Tawk.to ao carregar o componente
    const script = document.createElement("script");
    script.src = `https://embed.tawk.to/66a3d7b4becc2fed692ba173/1i3o056dv`;
    script.async = true;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    if (window.Tawk_API && userData) {
      window.Tawk_API.onLoad = function () {
        if(window.Tawk_API) {
          window.Tawk_API.visitor = {
            name  : userData.nome,
            email : userData.email,
            id: userData.id,
            company: userData.company
        };
          window.Tawk_API.setAttributes({
            'name': userData.nome,
            'email': userData.email,
            'id': userData.id,
            'company': userData.company
            // Adicione outros atributos, se necessário
          });
        }
      };
    }
  }, [userData]);

  return <div />;
};

export default Chat;
