// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appInfoContent {
  display: flex;
  margin-top: 2.625rem;
  justify-content: space-around;
}

.appInfoVersion {
  display: flex;
  flex-direction: column;
}

.appInfoVersionTitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 0.75rem;
}

.appInfoVersionText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.75rem;
  margin-top: 0.45rem;
}

.appInfoPhoneNumber {
  display: flex;
  flex-direction: column;
}

.appInfoPhoneNumberTitle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 0.75rem;
}

.appInfoPhoneNumberText {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.75rem;
  margin-top: 0.45rem;
}

.phoneNumberWhatsapp {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/components/Table/Devices/DialogAppVersion/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oBAAA;EACA,6BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;AAEF;;AACA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;AAEF;;AACA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;AAEF;;AACA;EACE,eAAA;AAEF","sourcesContent":[".appInfoContent {\n  display: flex;\n  margin-top: 2.625rem;\n  justify-content: space-around;\n}\n\n.appInfoVersion {\n  display: flex;\n  flex-direction: column;\n}\n\n.appInfoVersionTitle {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 900;\n  font-size: 0.875rem;\n  line-height: 0.75rem;\n}\n\n.appInfoVersionText {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 0.75rem;\n  margin-top: 0.45rem;\n}\n.appInfoPhoneNumber {\n  display: flex;\n  flex-direction: column;\n}\n\n.appInfoPhoneNumberTitle {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 900;\n  font-size: 0.875rem;\n  line-height: 0.75rem;\n}\n\n.appInfoPhoneNumberText {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 0.875rem;\n  line-height: 0.75rem;\n  margin-top: 0.45rem;\n}\n\n.phoneNumberWhatsapp {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
