import { object, ref, string } from 'yup';

export default object().shape({
  name: string()
    .required('* Informe o nome!')
    .min(6, '* Minimo de 6 caracteres!')
    .max(40, '* Maximo de 40 caracteres!'),
  email: string()
    .email('* E-mail invalido!')
    .required('* Informe o email!'),
  senha: string()
    .required('* Informe a senha!')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, 'Senha Inválida')
    .min(6, '* Mínimo 6 caracteres!'),
  confirmPassword: string()
    .required('* Confirme sua senha!')
    .when('senha', (senha, field) =>
      senha
        ? field
            .required('* Repita sua senha!')
            .oneOf([ref('senha')], '* Senhas não coincidem!')
        : field
    ),
  group: string().required('* Informe o grupo!'),
  cnpj: string().notRequired().nullable(),
  // filial: string().required('* Informe a filial!'),
});
