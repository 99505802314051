// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonDriverConfirm {
  background: #EA004C;
  border-radius: 6px;
  width: 100%;
  padding: 7px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 07 */
  color: #FFFFFF;
}

.buttonDriverCancel {
  background: #8C8C8C;
  border-radius: 6px;
  width: 100%;
  padding: 7px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 07 */
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/pages/RouterCargo/components/CargasRouting/components/ChangeVehicles/ConfirmationDriver/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACF,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EAGA,OAAA;EAEA,cAAA;AAFA;;AAKA;EACE,mBAAA;EACF,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EAGA,OAAA;EAEA,cAAA;AALA","sourcesContent":[".buttonDriverConfirm {\n  background: #EA004C;\nborder-radius: 6px;\nwidth: 100%;\npadding: 7px;\nborder: none;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ncolor: white;\nfont-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 14px;\nline-height: 17px;\n/* identical to box height */\n\n\n/* 07 */\n\ncolor: #FFFFFF;\n}\n\n.buttonDriverCancel {\n  background: #8C8C8C;\nborder-radius: 6px;\nwidth: 100%;\npadding: 7px;\nborder: none;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ncolor: white;\nfont-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 14px;\nline-height: 17px;\n/* identical to box height */\n\n\n/* 07 */\n\ncolor: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
