import { date, object, ref, string } from 'yup';

export default object().shape({
  description: string().required('* Informe a descricao da tabela!'),
  type: string().required('* Informe o tipo da tabela!'),
  dateInitial: date().required('* Informe a data inicial da vigência!'),
  finalDate: date().min(
    ref('dateInitial'),
    "* Data final não pode ser menor que data inicial!"
  )
    .required('* Informe a data final da vigência!'),
  filialOrigem: string().required('* Inform a filial!'),
  qtdKM: string().when("type", {
    is: "VALOR_FIXO",
    then: string().required("* Insira o valor da franquia km!")
  }),
  valueKM: string().when("type", {
    is: "VALOR_FIXO",
    then: string().required("* Insira o valor da franquia!")
  }),
  surplusValue: string().when("type", {
    is: "VALOR_FIXO",
    then: string().required("* Insira o valor da franquia km excedente!")
  }),
  initialDay: string().when("type", {
    is: "VALOR_FIXO",
    then: string().required("* Insira o dia de inicio da franquia!")
  }),
  zipcode: string().when("type", {
    is: "CEP",
    then: string().required("* Insira o cep!")
  }),
  valueZipcode: string().when("type", {
    is: "CEP",
    then: string().required("* Insira o valor!")
  })

});
