import React from 'react';

class Email extends React.Component {
  render() {
    return (
      <input
        style={{
          width: '350px',
          height: '40px',
          borderRadius: '10px',
          background: '#ffffff',
          border: '2px solid rgba(0, 48, 73, 0.2)',
          padding: '13px',
          boxSizing: 'border-box',
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '19px',
          color: '#003049',
        }}
        type="email"
        {...this.props}
      />
    );
  }
}

export default Email;
