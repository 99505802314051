import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveSubsidiary } from 'store/modules/map/actions';
import TopBar from 'util/TopBar';
import './style.scss';

export default function SuperiorInterface(props) {
  const [showFilter, setShowFilter] = React.useState(false);
  const [showRomaneios, setShowRomaneios] = useState(false);
  const [showKnowledge, setShowKnowledge] = useState(false);
  const [dataSubsidiary, setDataSubsidiary] = useState([]);
  const [dataRomaneios, setDataRomaneios] = useState([]);
  const [dataKnowledge, setDataKnowledge] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [idSelectedCargo, setIdSelectedCargo] = useState(0);
  const [showCargo, setShowCargo] = useState(false);
  const [selectSubsidiary, setSelectSubsidiary] = useState('');

  const romaneioPositionMap = useSelector(state => state.map.data);

  const data = sessionStorage.getItem('conhecimentosData');
  const dataJSON = JSON.parse(data);

  const userData = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  const getSubsidiaryUsers = useCallback(async () => {
    try {
      setSelectSubsidiary(userData.filiais[0].id);
      dispatch(saveSubsidiary(userData.filiais[0].id));
      // sessionStorage.setItem('subsidiary', userData.filiais[0].id);
    } catch (err) {}
  }, []);

  const [positions, setPositions] = useState();

  function setSelectedCargo(data) {
    if (data) {
      setIdSelectedCargo(data.id);
    }
  }

  const { t } = useTranslation();

  useEffect(() => {
    if (showCargo) {
      props.visibleRomaneios(showRomaneios);
    }
  }, [props.visibleRomaneios, showKnowledge, showRomaneios]);

  useEffect(() => {
    if (props.toggleTruck && props.toggleTruck.selected) {
      setIdSelectedCargo(props.toggleTruck.id);
    }
  }, [props.toggleTruck]);

  useEffect(() => {
    if (props.listSubsidiary) {
      setDataSubsidiary(props.listSubsidiary);
    }

    getSubsidiaryUsers();
  }, [props.listSubsidiary]);

  // useEffect(() => {
  //   if(dataSubsidiary.length > 0) {
  //     props.loadSubsidiaryInitial(dataSubsidiary[0])
  //   }
  // }, [dataSubsidiary])

  useEffect(() => {
    props.selectFilial(selectSubsidiary);
  }, [selectSubsidiary]);

  useEffect(() => {
    if (dataRomaneios) {
      props.loadRomaneios(dataRomaneios);
    }
  }, [dataRomaneios, props.loadRomaneios]);

  useEffect(() => {
    props.loadKnowledge(dataKnowledge);
  }, [dataKnowledge]);

  useEffect(() => {
    props.setLoading(showLoading);
  }, [props.setLoading, showLoading]);

  function handleStateCallback(data) {
    props.loadLocation(data);
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TopBar
          listSubsidiary={dataSubsidiary}
          onChangeSubsidiary={() => {}}
        />
      </div>
    </>
  );
}
