// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerResource {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.header3-text div:first-child {
  margin-bottom: 10px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #EA004C !important;
  background: #fff;
  border-color: #EA004C !important;
  box-shadow: -1px 0 0 0 #EA004C !important;
}

.header2-text {
  color: #EA004C !important;
}

.header1-text {
  color: #003049 !important;
}

.status-dot {
  background-color: #EA004C !important;
}

.slot-text {
  font-family: "Lato" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 300 !important;
  color: #003049 !important;
  opacity: 0.6;
}

.header3-text {
  color: #EA004C !important;
  max-width: 123px;
}

.round-all .event-item span {
  font-size: 12px !important;
}

.containerScheduler {
  margin-top: 40px;
  margin-left: 10px;
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #CCD6DB;
  border-radius: 5px;
}

.containerWrapper {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Sheduling/styles.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAHF;;AAMA;EACE,mBAAA;AAHF;;AAMA;EACE,UAAA;EACE,yBAAA;EACA,gBAAA;EACA,gCAAA;EAEA,yCAAA;AAHJ;;AAMA;EACE,yBAAA;AAHF;;AAMA;EACE,yBAAA;AAHF;;AAMA;EACE,oCAAA;AAHF;;AAMA;EACE,8BAAA;EACA,0BAAA;EACA,kBAAA;EACA,2BAAA;EACA,yBAAA;EACA,YAAA;AAHF;;AAUA;EACE,yBAAA;EACA,gBAAA;AAPF;;AAeA;EACE,0BAAA;AAZF;;AAeA;EAEE,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;AAbF;;AAgBA;EACE,WAAA;AAbF","sourcesContent":["span {\n  // color: #EA004C\n}\n\n.headerResource {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 25px;\n}\n\n.header3-text div:first-child {\n  margin-bottom: 10px;\n}\n\n.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {\n  z-index: 1;\n    color: #EA004C !important;\n    background: #fff;\n    border-color: #EA004C !important;\n    -webkit-box-shadow: -1px 0 0 0 #EA004C !important;\n    box-shadow: -1px 0 0 0 #EA004C !important;\n}\n\n.header2-text {\n  color:#EA004C !important;\n}\n\n.header1-text {\n  color:#003049 !important;\n}\n\n.status-dot {\n  background-color: #EA004C !important;\n}\n\n.slot-text {\n  font-family: 'Lato' !important;\n  font-size: 12px !important;\n  font-style: normal;\n  font-weight: 300 !important;\n  color: #003049 !important;\n  opacity: 0.6;\n}\n\n.scheduler {\n  // width: 50% !important;\n}\n\n.header3-text {\n  color: #EA004C !important;\n  max-width: 123px;\n}\n\n\n.resource-table th {\n  // width: 100px !important;\n}\n\n.round-all .event-item span {\n  font-size: 12px !important;\n}\n\n.containerScheduler {\n  // width: 98%;\n  margin-top: 40px;\n  margin-left: 10px;\n  overflow-x: scroll;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid #CCD6DB;\n  border-radius: 5px;\n}\n\n.containerWrapper {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
