import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';
import { SearchOperation } from 'components/Grid/types';

export class DriverFilter implements IFilterComp {
    filterParams!: IFilterParams;
    filterText!: any;
    gui!: HTMLDivElement;
    eFilterText: any;
    eFilterButton: any;

    init(params: IFilterParams) {
        this.filterParams = params;
        this.filterText = null;
        this.setupGui(params);
    }

    // not called by AG Grid, just for us to help setup
    setupGui(params: IFilterParams) {
        this.gui = document.createElement('div');
        this.gui.innerHTML = `
            <div class="customFilter-filter">
                <div>Motorista</div>
                <div>
                    <input type="text" id="descriptionText" placeholder="Informe o motorista"/>
                </div>
                <div>
                    <input type="button" id="descriptionButton" value="Filtrar"/>
                </div>
            </div>
        `;

        const listener = () => {
            this.filterText = {
                field: "driver.nome",
                value: this.eFilterText.value,
                operation: SearchOperation.MATCH
            };
            params.filterChangedCallback();
        };

        // Input Text
        this.eFilterText = this.gui.querySelector('#descriptionText');
        // this.eFilterText.addEventListener('changed', listener);
        // this.eFilterText.addEventListener('paste', listener);
        // this.eFilterText.addEventListener('input', listener);

        // Input Button
        this.eFilterButton = this.gui.querySelector('#descriptionButton');
        this.eFilterButton.addEventListener('click', listener);
    }

    getGui() {
        return this.gui;
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        return params.data.description != null;
    }

    isFilterActive() {
        return this.filterText != null && this.filterText !== '';
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }

        return { value: this.filterText };
    }

    setModel(model: any) {
        const newValue = model == null ? null : model.value;
        this.eFilterText.value = newValue;
        this.filterText = newValue;
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        if (!params?.suppressFocus) {
            // focus the input element for keyboard navigation
            this.eFilterText.focus();
        }
    }
}