import CustomPagination from 'components/CustomPagination';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import permissionMenu from 'shared/Permission';
import IconDeliveryCancel from '../../../../assets/images/cancelIcon.svg';
import FinishRouteIcon from '../../../../assets/images/finishRouteIcon.svg';
import IconDeliveryDone from '../../../../assets/images/knowledge/deliveryDone.svg';
import StartRouteIcon from '../../../../assets/images/startRouteIcon.svg';
import HeaderTable from './HeaderTable';
import RowTable from './RowTable';
import { Container } from './styles';
import './styles.scss';

interface TableProps {
  dataTable: any[];
  totalPages: number;
  currentPage: number;
  onChangeTotalPages: (numPages: number) => void;
  onPagination: (page: number) => void;
  onViewRoute: (idRomaneio: number, idMotorista: number, cargo: any) => void;
  onListKnowledge: (idRomaneio: number) => void;
  onChangeDriverRoute: (idRomaneio: number) => void;
  onCancelCargoRoute: (idRomaneio: number) => void;
  onUpload: (idRomaneio: number) => void;
  onDetails: (data: any) => void;

}

const Table: React.FC<TableProps> = ({
  dataTable,
  totalPages,
  onChangeTotalPages,
  onPagination,
  currentPage,
  onViewRoute,
  onCancelCargoRoute,
  onChangeDriverRoute,
  onListKnowledge,
  onUpload,
  onDetails
}: TableProps) => {
  const [permission, setPermission] = useState([]);
  const { t } = useTranslation();

  const userData = useSelector((state: any) => state.auth.user);

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);
  return (
    <Container>
      <table className="tableTower">
        <thead>
          <tr>
            <HeaderTable field={t('controlTower.fields.vehicles')} />
            <HeaderTable field={t('controlTower.fields.dateIssue')} />
            <HeaderTable field={t('controlTower.fields.dateScheduling')} />
            <HeaderTable field={t('controlTower.fields.driver')} />
            <HeaderTable size="small" field={t('controlTower.fields.cargo')} />
            <HeaderTable field={t('controlTower.fields.devices')} />
            <HeaderTable field={t('controlTower.fields.deliveryProgress')} />
            <HeaderTable icon imageUrl={IconDeliveryDone} />
            <HeaderTable icon imageUrl={IconDeliveryCancel} />

            <HeaderTable
              icon
              imageUrl={StartRouteIcon}
              size="small"
              tooltipTitle={t('controlTower.tooltipStartRoute')}
            />
            <HeaderTable
              icon
              imageUrl={FinishRouteIcon}
              size="small"
              tooltipTitle={t('controlTower.tooltipFinishRoute')}
            />
            {/* <HeaderTable field="Fim da Rota" /> */}
            <HeaderTable field={t('controlTower.fields.actualClient')} />
            <HeaderTable field="O.L"/>
            <HeaderTable field="Obs." />
            <HeaderTable />
          </tr>
        </thead>
        <tbody>
          <RowTable
            data={dataTable}
            onViewMap={(idRomaneio: number, idMotorista: number, cargo: any) =>
              onViewRoute(idRomaneio, idMotorista, cargo)
            }
            onListAttendances={(idRomaneio: number) =>
              onListKnowledge(idRomaneio)
            }
            onChangeDriver={(idRomaneio: number) => {
              if (permissionMenu(permission, 'controlTower.change-driver')) {
                onChangeDriverRoute(idRomaneio)
              } else {
                FrontendNotification(
                  t('common.permission'),
                  NotificationType.ERROR
                );
              }
            }
            }
            onCancelCargo={(idRomaneio: number) => {
              if (permissionMenu(permission, 'controlTower.cancel-cargo')) {
                onCancelCargoRoute(idRomaneio);
              } else {
                FrontendNotification(
                  t('common.permission'),
                  NotificationType.ERROR
                );
              }
            }}
            onUploadCTE={(idRomaneio: number) => onUpload(idRomaneio)}
            onDetailsCTE={(data: any) => onDetails(data)}
          />
        </tbody>
      </table>
      <div
        className="alertPagination"
        style={{ marginBottom: '0.512rem', marginTop: '0.813rem' }}
      >
        <CustomPagination
          totalPages={totalPages}
          defaultPage={currentPage}
          onChangePage={(page: number | null) => {
            if (page !== null) {
              onPagination(page - 1);
              // setCurrentPage(page - 1);
            }
          }}
          onPrevPage={(page: number | null) => {
            if (page !== null) {
              const prevPage = page - 1;
              onPagination(prevPage - 1);
            }
          }}
          onNextPage={(page: number | null) => {
            if (page !== null) {
              const nextPage = page - 1;
              onPagination(nextPage + 1);
            }
          }}
        />
        <div className="paginationSelect">
          <span className="textRowsPerPage">Linhas por página:</span>
          <select
            className="selectRowsPerPage"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              onChangeTotalPages(parseInt(e.target.value))
            }
          >
            <option label="5" value={5}></option>
            <option label="10" value={10}></option>
            <option label="20" value={20}></option>
            <option label="40" value={40}></option>
            <option label="50" value={50}></option>
            <option label="70" value={70}></option>
            <option label="100" value={100}></option>
          </select>
        </div>
      </div>
    </Container>
  );
};

export default Table;
