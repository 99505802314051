import styled from 'styled-components';

interface StyledProps {
  activeToogle: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ activeToogle }: StyledProps) =>
    activeToogle ? '#EA004C' : '#fff'};
  border: 1px solid #ea004c;
  border-radius: 20px;
  width: auto;
  height: 33px;
  cursor: pointer;
`;

export const TextContent = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ activeToogle }: StyledProps) =>
    activeToogle ? '#fff' : '#EA004C'};
`;