import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Form from 'reactstrap/lib/Form';
import './style.scss';

export default function TopBar({ listSubsidiary, selectSubsidiary }) {
  const [dataSubsidiary, setDataSubsidiary] = React.useState([]);
  const [idSubsidiary, setIdSubisidiary] = React.useState();
  const userName = useSelector(state => state.auth.email);

  React.useEffect(() => {
    if (listSubsidiary && listSubsidiary.length > 0)  {
      setDataSubsidiary(listSubsidiary);
      setIdSubisidiary(listSubsidiary[0].id);
      if(sessionStorage.getItem('subsidiary')) {
        if(sessionStorage.getItem('subsidiary')?.length === 0) {
          sessionStorage.setItem('subsidiary', listSubsidiary[0].id);
        }
      } else {
        sessionStorage.setItem('subsidiary', listSubsidiary[0].id);
      }
      // sessionStorage.setItem('subsidiary', listSubsidiary[0].id)
    }



  }, [listSubsidiary]);

  const { t } = useTranslation();
  return (
    <div className="topBar">
      <Form inline>
        <div className="filialLabel" style={{ zIndex: 3 }}>
          <Trans i18nKey="filter.subsidiary" />
          <select
            name="filialSelector"
            id="filial1"
            className="filialInput"
            onChange={e => {
              sessionStorage.setItem('subsidiary', e.target.value);
              setIdSubisidiary(e.target.value);
              selectSubsidiary(e.target.value);
            }}
            value={sessionStorage.getItem('subsidiary')}
          >
            <option value="" label={t('common.select')} />
            {dataSubsidiary.map(subsidiary => (
              <option key={subsidiary.id} value={subsidiary.id}>
                {subsidiary.nome}
              </option>
            ))}
          </select>
        </div>
      </Form>
    </div>
  );
}
