/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Input, Modal, Row } from 'reactstrap';
import './styles.scss';

import {
  BackendErrorNotification,
  NotificationType,
} from 'core/common/Notification';
import api from 'services/api';
import { BoxForm, Content } from 'components/Driver/styles';
import { FrontendNotification } from 'components/Notification';
import closeModalIcon from '../../../../assets/images/closeIcon.svg';
import addDriver from '../../../../assets/images/addDriver.png';
import Loading from 'core/common/Loading';

export default function ConfirmationDriver({
  idRomaneio,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
}) {
  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const dataSubmit = {
        cpf: cpfDriver,
        idsRomaneios: [idRomaneio],
      };

      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';

      const response = await api.post(
        `/delivery/romaneios/trocaMotorista?lang=${emailSendLanguage}`,
        dataSubmit
      );

      setLoading(false);
      if (response.status === 204) {
        FrontendNotification(
          t('changeDriver.messageSuccess'),
          NotificationType.SUCCESS
        );
        setModal(!modal);
        onConfirm();
        onLoad();
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%' }}
      >
        <div className="modalWrapper">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="changeDriver.title" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="changeDriver.messageConfirmChanged" />
          </span>
          <Row>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeDriver.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => handleSubmit()}
              >
                <Trans i18nKey="changeDriver.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
