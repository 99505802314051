// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerValueKnowledge {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
}

.contentValueKnowledge {
  display: flex;
  flex-direction: column;
}

.textContentKnowledge {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  /* 01 */
  color: #003049;
}

.chipKnowledge {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6369D1;
  border-radius: 15px;
  width: auto;
  height: 25px;
  padding: 5px;
}

.chipText {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  margin-left: 7px;
}`, "",{"version":3,"sources":["webpack://./src/pages/RouterCargo/components/KnowledgeRouting/components/CardValueKnowledge/styles.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,2CAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,mBAAA;EACJ,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EAEA,OAAA;EAEA,cAAA;AADA;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACJ,mBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AADA;;AAIA;EACI,mBAAA;EACJ,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EAEA,cAAA;EACA,gBAAA;AAFA","sourcesContent":[".containerValueKnowledge {\n    background: #FFFFFF;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 10px;\n    width: 100%;\n    height: 60px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px;\n    margin-bottom: 10px;\n}\n\n.contentValueKnowledge {\n    display: flex;\n    flex-direction: column;\n}\n\n.textContentKnowledge {\n    font-family: 'Lato';\nfont-style: normal;\nfont-weight: normal;\nfont-size: 12px;\nline-height: 14px;\ntext-align: center;\n\n/* 01 */\n\ncolor: #003049;\n}\n\n.chipKnowledge {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: #6369D1;\nborder-radius: 15px;\nwidth: auto;\nheight: 25px;\npadding: 5px;\n}\n\n.chipText {\n    font-family: 'Lato';\nfont-style: normal;\nfont-weight: normal;\nfont-size: 12px;\nline-height: 14px;\n\ncolor: #FFFFFF;\nmargin-left: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
