import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import styled from 'styled-components';

export const ForgotPasswordLink = styled(Link)`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ea004c;
  opacity: 0.6;
`;

export const ContainerLogin = styled(Row)`
  display: flex;
  z-index: 2;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 480px;
  min-width: 465px;
  max-width: 465px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
`;
