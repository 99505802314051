// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperInput2 {
  background: #FFFFFF;
  border: 2px solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/ListAttendances/components/Filters/InputCustom/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACF,sCAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACA","sourcesContent":[".wrapperInput2 {\n  background: #FFFFFF;\nborder: 2px solid rgba(0, 48, 73, 0.2);\nbox-sizing: border-box;\nborder-radius: 10px;\nheight: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
