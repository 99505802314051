import copyClipboardIcon from 'assets/images/copyClipboardIcon.svg';
import CustomModal from 'components/ModalCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import { formatNumber, numberToCurrencyBRL } from 'util/format';

// import { Container } from './styles';

type IRomaneioTC = {
  id: number;
  previsaoValorFrete: number;
  totalPeso: number;
  totalCubagem: number;
  totalValor: number;
};

interface Props {
  data: IRomaneioTC;
  onClear: () => void;
}

const DetailsCTE: React.FC<Props> = ({ data, onClear }) => {
  const [loading, setLoading] = useState([]);
  const [listNf, setListNf] = useState([]);

  const getListKnowledge = useCallback(async () => {
    try {
      const dataFilters = {
        filters: [
          {
            field: 'romaneio.id',
            operation: 'EQUAL',
            value: data.id,
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentos/findByFilters',
        dataFilters
      );

      const getListNfe = response.data
        .map((knowledge: any) => knowledge.numero)
        .filter((filterKnowledge: any) => filterKnowledge.length > 0);

      setListNf(getListNfe);
    } catch {}
  }, []);

  useEffect(() => {
    getListKnowledge();
  }, [getListKnowledge]);

  return (
    <CustomModal
      isOpen
      label="Dados do Romaneio"
      isClose={onClear}
      styles={{ minWidth: '600px' }}
    >
      <div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
          <div className="tw-flex-col tw-items-center tw-justify-center tw-w-full tw-mr-4">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-lg tw-font-normal tw-text-[#003049]">
                Valor Frete
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-24 tw-border-2 tw-border-solid tw-border-[#D9D9D9] tw-rounded-lg tw-mt-3">
              <span className="tw-text-base tw-font-normal tw-text-[#003049]">
                {data.previsaoValorFrete !== null
                  ? numberToCurrencyBRL(data.previsaoValorFrete)
                  : '---'}
              </span>
            </div>
          </div>
          <div className="tw-w-full tw-mr-4">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-lg tw-font-normal tw-text-[#003049]">
                Valor
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-24 tw-border-2 tw-border-solid tw-border-[#D9D9D9] tw-rounded-lg tw-mt-3">
              <span className="tw-text-base tw-font-normal tw-text-[#003049]">
                {numberToCurrencyBRL(data.totalValor)}
              </span>
            </div>
          </div>
          <div className="tw-w-full tw-mr-4">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-lg tw-font-normal tw-text-[#003049]">
                Peso
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-24 tw-border-2 tw-border-solid tw-border-[#D9D9D9] tw-rounded-lg tw-mt-3">
              <span className="tw-text-base tw-font-normal tw-text-[#003049]">
                {formatNumber(data.totalPeso)} Kg
              </span>
            </div>
          </div>
          <div className="tw-w-full">
            <div className="tw-w-full tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-lg tw-font-normal tw-text-[#003049]">
                Cubagem
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-24 tw-border-2 tw-border-solid tw-border-[#D9D9D9] tw-rounded-lg tw-mt-3">
              <span className="tw-text-base tw-font-normal tw-text-[#003049]">
                {formatNumber(data.totalCubagem)} m3
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="tw-flex">
            <span className="tw-text-lg tw-font-normal tw-text-[#003049]">
              Notas Fiscais
            </span>
            <button
              className="tw-border-none tw-ml-2"
              style={{ backgroundColor: 'transparent' }}
              onClick={() => {
                if (listNf.length > 0) {
                  navigator.clipboard.writeText(`${listNf.join(';')}`);
                  FrontendNotification("Copiado pra área de transferência", NotificationType.SUCCESS);
                }
              }}
            >
              <img src={copyClipboardIcon} alt="" />
            </button>
          </div>

          <div className="tw-flex tw-items-center tw-justify-center tw-max-w[20px] tw-p-2 tw-h-24 tw-border-2 tw-border-solid tw-border-[#D9D9D9] tw-rounded-lg tw-mt-3 tw-break-all">
            <span className="tw-text-base tw-font-normal tw-text-[#003049]">
              {listNf.length > 0 ? listNf.join(';') : '---'}

            </span>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default DetailsCTE;
