import React from 'react';
import { ProgressContainer, ValueProgress } from './styles';
import './styles.scss';

interface ProgressCargoProps {
  progressValue: number;
  secondary?: any;
}

const ProgressCargo: React.FC<ProgressCargoProps> = (props: ProgressCargoProps) => {
  return (
    <div className="tw-flex tw-flex-col tw-w-[70%]">
      <ProgressContainer progressValueContent={props.progressValue}>
        <span
          className={
            props.progressValue > 100
              ? 'tw-font-bold tw-text-xs tw-text-[#EA004C]'
              : 'tw-font-bold tw-text-xs tw-text-[#6369D1]'
          }
        >
          {props.progressValue}%
        </span>
      </ProgressContainer>
      <div className="tw-flex tw-w-28 tw-h-[2px] tw-bg-[#C4C4C4]">
        <ValueProgress progressValueContent={props.progressValue} />
      </div>
    </div>
  );
}

export default ProgressCargo;
