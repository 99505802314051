import hidePasswordIcon from 'assets/images/hidePasswordIcon.png';
import showPasswordIcon from 'assets/images/showPasswordIcon.png';
import React, { useState } from 'react';
import CustomTooltip from './CustomTooltip';
import { Error, InputForm, InputMask, Title } from './styles';

interface InputProps {
  title?: string;
  name?: string;
  id?: string;
  autoComplete?: string;
  className?: any;
  typeInput?: string;
  style?: any;
  styleTitle?: any;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  mask?: any;
  messageTooltip?: JSX.Element;
  isTooltip?: boolean;
  placeholder: string;
  type?: any;
  touched?: any;
  error?: any;
  value?: any;
}

const InputCustom: React.FC<InputProps> = (
  {
    title,
    type,
    mask,
    touched,
    style,
    error,
    placeholder,
    autoComplete,
    onChange,
    onBlur,
    className,
    typeInput,
    styleTitle,
    messageTooltip,
    isTooltip,
    value,
    id,
    disabled,
  }: InputProps,
  ...rest
) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);

  const Input =
    typeInput === 'mask' ? InputMask : (InputForm as React.ElementType);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Title style={styleTitle}>{title}</Title>
        {isTooltip && <CustomTooltip message={messageTooltip} />}
      </div>
      <div className="tw-relative">
        {type === 'password' && (
          <div className="tw-absolute tw-right-3 tw-top-4">
            {isVisiblePassword ? (
              <img
                src={hidePasswordIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
              />
            ) : (
              <img
                src={showPasswordIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
              />
            )}
          </div>
        )}

        <Input
          {...rest}
          type={type === "password" && isVisiblePassword ? "text" : type}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={onChange}
          style={style}
          value={value}
          mask={mask}
          id={id}
          onBlur={onBlur}
          disabled={disabled}
          {...rest}
        />
      </div>

      {touched && error && <Error>{error}</Error>}
    </>
  );
};

export default InputCustom;
