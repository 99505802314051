import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { Trans } from 'react-i18next';
import { DataStepsRoutering } from 'store/modules/routering/actions';
// import { Container } from './styles';

interface Props {
  data: DataStepsRoutering;
  onExpand: () => void;
  onChangeOrder: () => void;
}

const CardCompacted: React.FC<Props> = ({
  data,
  onExpand,
  onChangeOrder,
}: Props) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-ml-2">
      <div className="tw-flex">
        <div className="tw-flex tw-mr-2">
          <div
            className="tw-flex tw-cursor-pointer tw-ml-1 tw-mr-2"
            onClick={onExpand}
          >
            <AddIcon color="error" />
          </div>
          <div className='tw-flex tw-flex-col'>
            <div
              className="tw-flex tw-items-center tw-justify-center tw-bg-[#6369D1] tw-w-6 tw-h-6 tw-rounded-full tw-mb-2"
              onClick={onChangeOrder}
            >
              <span className="tw-text-xs tw-text-[#fff] tw-font-bold">
                {data.order}
              </span>
            </div>
              {data.rows && data.rows.length > 0 && (
                <div
                  className="tw-flex tw-items-center tw-justify-center tw-bg-[#EA004C] tw-w-6 tw-h-6 tw-rounded-full"
                  // onClick={onChangeOrder}
                >
                  <span className="tw-text-xs tw-text-[#fff] tw-font-bold">
                    {data.rows.length > 0 ? `+ ${data.rows.length}` : `+ 0`}
                  </span>
                </div>
              )}
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-mr-2">
          <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
            <Trans i18nKey="routeOptmization.routeSteps.fields.arrival" />
          </span>
          <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-text-nowrap tw-max-w-[11ch] tw-text-ellipsis tw-overflow-hidden">
            {data.arrival.formattedDateTime}
          </span>
        </div>
        <div className="tw-flex tw-flex-col tw-mr-3">
          <span className="tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap">
            <Trans i18nKey="routeOptmization.routeSteps.fields.clientName" />
          </span>
          <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden">
            {data.conhecimentoPre.clienteNome}
          </span>
        </div>
        <div className="tw-flex tw-flex-col tw-ml-3">
          <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
            <Trans i18nKey="routeOptmization.routeSteps.fields.weight" />
          </span>
          <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-text-nowrap">
            {data.conhecimentoPre.peso} Kg
          </span>
        </div>
      </div>
      <div className="tw-flex">
      </div>
      <div className="tw-flex">
      </div>
    </div>
  );
};

export default CardCompacted;
