import addButton from 'assets/images/addButton.svg';
import React from 'react';
import { ICreateShippingTableForm } from '..';
import ShippingFixedValue from './components/ShippingFixedValue';
import ShippingZipCode from './components/ShippingZipCode';
import { AddButton, Container, Footer, TextButton, Wrapper } from './styles';

interface ShippingContentProps {
  onChange: (field: string, value: any) => void;
  values: ICreateShippingTableForm;
  onSubmit: () => void;
  errors: any;
  touched: any;
  isView: boolean;
}

const ShippingContent: React.FC<ShippingContentProps> = ({
  onSubmit,
  onChange,
  values,
  errors,
  touched,
  isView = false
}: ShippingContentProps) => {
  const onRenderTypeShippingTable = (type: string) => {
    switch (type) {
      case 'VALOR_FIXO':
        return (
          <ShippingFixedValue
            onChange={(field: string, value: string) => onChange(field, value)}
            values={values}
            errors={errors}
            touched={touched}
          />
        );
      case 'CEP':
        return (
          <ShippingZipCode
            errors={errors}
            touched={touched}
            onChange={(field: string, value: string) => onChange(field, value)}
            values={values}
          />
        );

      default:
        break;
    }
  };
  return (
    <Container>
      <Wrapper>

        {!isView && onRenderTypeShippingTable(values.type)}
        <Footer type="button" onClick={onSubmit} id='btnAddRating'>
          <AddButton src={addButton} />
          <TextButton>Adicionar</TextButton>
        </Footer>
      </Wrapper>
    </Container>
  );
};

export default ShippingContent;
