import AddJourneyBreaksIcon from 'assets/images/addJourneyBreaks.svg';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import Breaks from 'components/Breaks';
import { BoxForm, Content, IconColumn } from 'components/Driver/styles';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import { Footer } from 'components/Phases/styles';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import api from 'services/api';
import {
  addColumnsJourneyFlexible,
  addHeadersJourneyFlexible,
  clearColumnsJourneyFlexible, updateColumnsJourneyFlexible,
  updateColumnsValue
} from 'store/modules/journey/actions';
import { responsiveSize } from 'styles/responsive';
import JourneyFlexible from './JourneyFlexible';
import Toogle from './Toogle';
import formValidation from './formValidation';
import getDayOfBreaksPosition from './getDayOfBreaksPosition';
import {
  ContainerTypeJourney,
  // Footer,
  ImageAdd,
  TableResponsive,
  TextTypeJourney,
  Wrapper
} from './styles';


interface Props {
  onClear?: any;
}
interface IBreakData {
  id?: number;
  name?: string;
}

interface FormValues {
  descricao: string;
  tipo: string;
}

const WorkingDay: React.FC<Props> = ({ onClear }: Props) => {
  const [checkedJourneyFixed, setCheckedJourneyFixed] = useState(false);
  const [checkedJourneyFlexible, setCheckedJourneyFlexible] = useState(false);
  const [checkedJourneyRangeFlexible, setCheckedJourneyRangFlexible] = useState(
    false
  );
  const [isBreakJourney, setIsBreakJourney] = useState(false);
  const [breaks, setBreaks] = useState({
    id: '',
    name: '',
  });
  const [breaksData, setBreaksData] = useState<any>([]);
  const [breaksDataUpdate, setBreaksDataUpdate] = useState<any>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [dataJourneyList, setDataJourneyList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [updateId, setUpdateId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const getDataJourneyFlexible = useSelector(
    (state: any) => state.journey.data
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const getJourneyFlexible = useCallback(async () => {
    try {
      const currentFilters: any = [];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        orders: [],
        page: currentPage,
        size: 10,
      };
      const response = await api.post('/workday/journeys/pageable', data);

      setDataJourneyList(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch {}
  }, [currentPage, filters]);

  const addBreaksTimesJourneyFlexible = useCallback(
    (dataJourney, breakData) => {
      let headersJourney: any[] = dataJourney.headers;
      let elementsJourney = dataJourney.dataElements;

      const setDataHeadersJourney = {
        name: `${breakData.name}`,
      };

      dispatch(addHeadersJourneyFlexible(setDataHeadersJourney));
      const mappingElementsJourney = elementsJourney.map(
        (item: any, index: number) => {
          return dispatch(addColumnsJourneyFlexible(index, breakData));
        }
      );
    },
    []
  );

  const getBreaks = useCallback(async () => {
    try {
      const response = await api.get('/workday/pauses');

      setBreaksData(response.data);
      setBreaksDataUpdate(response.data);
    } catch {}
  }, []);

  const mappingUpdateColumns = useCallback(() => {}, []);

  const getMappingColumns = useCallback((data: any) => {
    const dataJourney = {
      breaks: {
        MONDAY: data.dataElements[0].columns.filter(
          (item: any) => item.id === 'break'
        ),
        TUESDAY: data.dataElements[1].columns.filter(
          (item: any) => item.id === 'break'
        ),
        WEDNESDAY: data.dataElements[2].columns.filter(
          (item: any) => item.id === 'break'
        ),
        THURSDAY: data.dataElements[3].columns.filter(
          (item: any) => item.id === 'break'
        ),
        FRIDAY: data.dataElements[4].columns.filter(
          (item: any) => item.id === 'break'
        ),
        SATURDAY: data.dataElements[5].columns.filter(
          (item: any) => item.id === 'break'
        ),
        SUNDAY: data.dataElements[6].columns.filter(
          (item: any) => item.id === 'break'
        ),
      },
    };

    const getIsBreaks = data.dataElements
      .map((item: any) => {
        return item.columns.filter(
          (itemColumn: any, index: number) => itemColumn.id === 'break'
        );
      })
      .filter((itemElement: any) => itemElement.length > 0);

    let dataJourneyResult: any[] = [];

    if (getIsBreaks.length === 0) {
      data.dataElements.map((item: any) => {
        return dataJourneyResult.push({
          day: item.columns[0].id,
          timeWorked: item.columns[1].data || '00:00',
        });
      });
    } else {
      dataJourney.breaks.MONDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'MONDAY',
          timeWorked: data.dataElements[0].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.TUESDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'TUESDAY',
          timeWorked: data.dataElements[1].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.WEDNESDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'WEDNESDAY',
          timeWorked: data.dataElements[2].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.THURSDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'THURSDAY',
          timeWorked: data.dataElements[3].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.FRIDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'FRIDAY',
          timeWorked: data.dataElements[4].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.SATURDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'SATURDAY',
          timeWorked: data.dataElements[5].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });

      dataJourney.breaks.SUNDAY.map((item: any) => {
        return dataJourneyResult.push({
          day: 'SUNDAY',
          timeWorked: data.dataElements[6].columns[1].data || '00:00',
          pause: {
            id: item.pause.id,
          },
          pauseTime: item.data || '00:00',
        });
      });
    }

    return dataJourneyResult;
  }, []);

  const getValueWeekend = useCallback((value: any, order: number) => {
    let weekendValue = 'Segunda';
    let orderValue = 0;
    switch (value) {
      case 'MONDAY':
        weekendValue = 'Segunda';
        orderValue = 0;
        break;
      case 'TUESDAY':
        weekendValue = 'Terça';
        orderValue = 1;
        break;
      case 'WEDNESDAY':
        weekendValue = 'Quarta';
        orderValue = 2;
        break;
      case 'THURSDAY':
        weekendValue = 'Quinta';
        orderValue = 3;
        break;
      case 'FRIDAY':
        weekendValue = 'Sexta';
        orderValue = 4;
        break;
      case 'SATURDAY':
        weekendValue = 'Sábado';
        orderValue = 5;
        break;
      case 'SUNDAY':
        weekendValue = 'Domingo';
        orderValue = 6;
        break;
      default:
        weekendValue = 'Segunda';
        orderValue = 0;
        break;
    }

    return {
      day: weekendValue,
      order: orderValue,
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: FormValues, reset: any, dataJourney: any, update: any) => {
      try {
        const dataJourneyMapping = getMappingColumns(dataJourney);

        const data = {
          name: values.descricao,
          type: values.tipo,
          items: dataJourneyMapping,
        };

        if (update) {
          const response = await api.patch(`/workday/journeys/${update}`, data);

          if (response.status === 204) {
            FrontendNotification(
              t('journey.messageUpdated'),
              NotificationType.SUCCESS
            );

            setLoading(false);

            getJourneyFlexible();
          }
        } else {
          const response = await api.post('/workday/journeys', data);

          if (response.status === 201) {
            setLoading(false);

            FrontendNotification(
              t('journey.messageSuccess'),
              NotificationType.SUCCESS
            );

            getJourneyFlexible();
          }
        }
        setLoading(false);
        reset({});
        setEdit(false);
        setUpdateId(null);
        getBreaks();
        setBreaks({
          id: '',
          name: '',
        });
        dispatch(clearColumnsJourneyFlexible());
      } catch {}
    },
    []
  );

  const deleteJourney = useCallback(async (driverId: string) => {
    try {
      const response = await api.delete(`/workday/journeys/${driverId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('journey.messageRemove'),
          NotificationType.SUCCESS
        );
        setRemoveConfirmation(false);

        getJourneyFlexible();
      }
    } catch (err) {}
  }, []);

  const initialValues: FormValues = {
    descricao: '',
    tipo: 'FLEXIBLE',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidation,
    onSubmit: (values: FormValues, { resetForm }) =>
      handleSubmit(values, resetForm, getDataJourneyFlexible, updateId),
  });

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  useEffect(() => {
    getBreaks();
    getJourneyFlexible();
  }, [getBreaks, getJourneyFlexible]);

  useEffect(() => {
    dispatch(clearColumnsJourneyFlexible());
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isOpen
        label={t('driverShift.title')}
        isClose={onClear}
        styles={{ maxWidth: '95%' }}
      >
        <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
          <ModalBody>
            <Trans i18nKey="common.questionRemove" />
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => deleteJourney(removeId)}
              id="buttonSaveRemove"
            >
              <Trans i18nKey="common.confirmRemove" />
            </Button>{' '}
            <Button
              color="secondary"
              onClick={toggleRemove}
              id="buttonCancelRemove"
            >
              <Trans i18nKey="common.cancelRemove" />
            </Button>
          </ModalFooter>
        </Modal>
        <Wrapper>
          {isBreakJourney ? (
            <Breaks onClear={() => setIsBreakJourney(!isBreakJourney)} />
          ) : (
            <></>
          )}
          <div className="d-flex">
            <BoxForm style={{ marginTop: 50, width: '80%' }}>
              <Row>
                <Col>
                  <InputCustom
                    onChange={formik.handleChange('descricao')}
                    placeholder={t('journey.fields.placeholder.description')}
                    title={t('journey.fields.description')}
                    value={formik.values.descricao}
                    error={formik.errors.descricao}
                    touched={formik.touched.descricao}
                  />
                </Col>
              </Row>
              <ContainerTypeJourney>
                <TextTypeJourney>
                  <Trans i18nKey="journey.fields.typesJourney.title" />
                </TextTypeJourney>

                <Row style={{ marginTop: 10 }}>
                  <Col md={3}>
                    <Toogle
                      id="journeyFixed"
                      active={checkedJourneyFixed}
                      onChange={() => {
                        FrontendNotification(
                          t('common.alert'),
                          NotificationType.WARNING
                        );
                        setCheckedJourneyFixed(true);
                        setCheckedJourneyFlexible(false);
                        setCheckedJourneyRangFlexible(false);
                      }}
                      label={t('journey.fields.typesJourney.fixed')}
                    />
                  </Col>
                  <Col md={4}>
                    <Toogle
                      id="journeyRangeFlexible"
                      active={checkedJourneyRangeFlexible}
                      onChange={() => {
                        FrontendNotification(
                          t('common.alert'),
                          NotificationType.WARNING
                        );
                        setCheckedJourneyFixed(false);
                        setCheckedJourneyFlexible(false);
                        setCheckedJourneyRangFlexible(true);
                      }}
                      label={t('journey.fields.typesJourney.intervalFlexible')}
                    />
                  </Col>
                  <Col md={3}>
                    <Toogle
                      id="journeyFlexible"
                      active={checkedJourneyFlexible}
                      onChange={() => {
                        setCheckedJourneyFixed(false);
                        setCheckedJourneyFlexible(true);
                        setCheckedJourneyRangFlexible(false);
                        formik.setFieldValue('tipo', 'FLEXIBLE');
                      }}
                      label={t('journey.fields.typesJourney.flexible')}
                    />
                  </Col>
                </Row>
                {formik.errors.tipo && formik.touched.tipo && (
                  <p style={{ fontSize: responsiveSize(12), color: 'red' }}>
                    {formik.errors.tipo}
                  </p>
                )}
              </ContainerTypeJourney>

              {checkedJourneyFlexible ? (
                <>
                  <Row
                    style={{
                      marginTop: 10,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Col md={6}>
                      <SelectCustom
                        id="breaksSelect"
                        onChange={(e: any) => {
                          const breaks = JSON.parse(e.target.value);

                          setBreaks(breaks);
                        }}
                        title={t('journey.fields.breaks')}
                        value={JSON.stringify(breaks)}
                      >
                        <option
                          label={t('common.select')}
                          value={JSON.stringify({
                            name: '',
                            id: '',
                          })}
                        ></option>
                        {breaksData.map((item: any) => {
                          return (
                            <option
                              value={JSON.stringify({
                                name: item.name,
                                id: item.id,
                              })}
                              label={item.name}
                            />
                          );
                        })}
                      </SelectCustom>
                    </Col>
                    <Col md={1}>
                      <ImageAdd
                        id="addBreaksJourney"
                        src={AddJourneyBreaksIcon}
                        onClick={() => {
                          if (breaks.name.length > 0) {
                            const updateBreaks = breaksData.filter(
                              (breakData: any) => breakData.id !== breaks.id
                            );

                            setBreaksData(updateBreaks);
                            setBreaks({
                              id: '',
                              name: '',
                            });

                            addBreaksTimesJourneyFlexible(
                              getDataJourneyFlexible,
                              breaks
                            );
                          }
                        }}
                      />
                    </Col>
                    <Col md={5}>
                      <button
                        id="viewBreaks"
                        className="button"
                        style={{ marginTop: 38, maxWidth: 84, marginRight: 15 }}
                        onClick={() => setIsBreakJourney(!isBreakJourney)}
                      >
                        <Trans i18nKey={t('journey.fields.breaks')} />
                      </button>
                    </Col>
                  </Row>

                  <JourneyFlexible
                    onRemoveColumn={(nameBreak: string) => {
                      const breaksUpdated = breaksDataUpdate.find(
                        (findBreak: any) => nameBreak === findBreak.name
                      );

                      let breaksUpdatedRemoved = breaksData;

                      breaksUpdatedRemoved.push(breaksUpdated);

                      setBreaksData(breaksUpdatedRemoved);
                    }}
                  />

                  <Footer>
                    {edit ? (
                      <Row>
                        <Col>
                          <button
                            onClick={() => {
                              formik.resetForm({});
                              setEdit(false);
                              dispatch(clearColumnsJourneyFlexible());

                              setUpdateId(null);
                            }}
                            className="button"
                            type="button"
                            id="buttonCancelPhases"
                            style={{
                              width: '100%',
                              background: '#ccc',
                              color: '#000',
                              marginTop: responsiveSize(40),
                            }}
                          >
                            <Trans i18nKey="common.cancel" />
                          </button>
                        </Col>

                        <Col>
                          <button
                            onClick={() => formik.handleSubmit()}
                            className="button"
                            id="buttonUpdatePhases"
                            type="button"
                            style={{ marginTop: responsiveSize(40) }}
                          >
                            <Trans i18nKey="journey.fields.updateButton" />
                          </button>
                        </Col>
                      </Row>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <button
                          onClick={() => formik.handleSubmit()}
                          className="button"
                          id="buttonSavePhases"
                          type="button"
                          style={{
                            marginTop: responsiveSize(40),
                            width: '70%',
                            maxWidth: '70%',
                            height: responsiveSize(35),
                          }}
                        >
                          <Trans i18nKey="journey.fields.saveButton" />
                        </button>
                      </div>
                    )}
                  </Footer>
                </>
              ) : (
                <></>
              )}
            </BoxForm>
            <Content>
              <TableResponsive className="table-responsive">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontSize: responsiveSize(15),
                          fontWeight: 'bold',
                          color: '#003049',
                        }}
                      >
                        <Trans i18nKey="journey.fields.description" />
                      </th>
                      <th
                        style={{
                          fontSize: responsiveSize(15),
                          fontWeight: 'bold',
                          color: '#003049',
                        }}
                      >
                        <Trans i18nKey="journey.fields.type" />
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {dataJourneyList.map((item: any, index: number) => (
                      <tr className="lineGroup">
                        <td
                          style={{
                            fontSize: responsiveSize(14),
                            color: '#003049',
                          }}
                        >
                          {item.name}
                        </td>
                        <td
                          style={{
                            fontSize: responsiveSize(14),
                            color: '#003049',
                          }}
                        >
                          Flexível
                        </td>
                        <IconColumn>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={async () => {
                              dispatch(clearColumnsJourneyFlexible());
                              formik.setFieldValue('descricao', item.name);
                              formik.setFieldValue('tipo', item.type);
                              setUpdateId(item.id);
                              // getBreaks();
                              setEdit(true);

                              setCheckedJourneyFlexible(true);

                              let breaksUpdate: any[] = [];

                              let mappingValue: any[] = [];
                              let indexOrder = 0;

                              dataJourneyList[index].items.map(
                                (valueColumn: any, index: number) => {
                                  const duplicated =
                                    mappingValue.findIndex((redItem: any) => {
                                      return valueColumn.day == redItem.day;
                                    }) > -1;

                                  if (!duplicated) {
                                    mappingValue.push({
                                      order: getValueWeekend(
                                        valueColumn.day,
                                        index
                                      ).order,
                                      day: valueColumn.day,
                                      columns: [
                                        {
                                          data: getValueWeekend(
                                            valueColumn.day,
                                            index
                                          ).day,
                                          id: valueColumn.day,
                                          field: 'days',
                                        },
                                        {
                                          data: valueColumn.timeWorked
                                            ? valueColumn.timeWorked.slice(0, 5)
                                            : '00:00',
                                          id: 'timeWorked',
                                        },
                                        {
                                          data: valueColumn.timeWorked
                                            ? valueColumn.timeWorked.slice(0, 5)
                                            : '00:00',
                                          id: 'totalHours',
                                        },
                                      ],
                                    });
                                  }
                                }
                              );

                              let sortColumn = mappingValue.sort(
                                (a: any, b: any) => a.order - b.order
                              );
                              console.log(sortColumn);

                              dispatch(updateColumnsValue(sortColumn));

                              let duplicatedBreaks: any[] = [];

                              dataJourneyList[index].items.map(
                                (breakItem: any, indexBreak: number) => {
                                  const duplicated =
                                    duplicatedBreaks.findIndex(
                                      (redItem: any) => {
                                        return (
                                          breakItem.pause !== null &&
                                          breakItem.pause.id == redItem.pause.id
                                        );
                                      }
                                    ) > -1;

                                  if (breakItem.pause !== null) {
                                    breaksUpdate.push({
                                      ...breakItem,
                                      order: getValueWeekend(
                                        breakItem.day,
                                        indexBreak
                                      ).order,
                                      pauseTime: breakItem.pauseTime,
                                      pause: breakItem.pause,
                                    });
                                  }

                                  if (!duplicated && breakItem.pause !== null) {
                                    duplicatedBreaks.push({
                                      pauseTime: breakItem.pauseTime,
                                      pause: breakItem.pause,
                                    });
                                  }
                                }
                              );
                              console.log(duplicatedBreaks);

                              duplicatedBreaks.length > 0 &&
                                duplicatedBreaks.map(breaks => {
                                  const breaksUpdated = breaksDataUpdate.find(
                                    (findBreak: any) =>
                                      breaks.pause !== null &&
                                      breaks.pause.id === findBreak.id
                                  );
                                  const setDataHeadersJourney = {
                                    name: `${breaksUpdated &&
                                      breaksUpdated.name}`,
                                  };

                                  dispatch(
                                    addHeadersJourneyFlexible(
                                      setDataHeadersJourney
                                    )
                                  );
                                });

                              const orderBreaks = breaksUpdate.sort(
                                (a: any, b: any) => a.order - b.order
                              );

                              orderBreaks.length > 0 &&
                                orderBreaks.map(
                                  (breakItem: any, breakIndex: number) => {
                                    return dispatch(
                                      updateColumnsJourneyFlexible(
                                        getDayOfBreaksPosition(breakItem.day),
                                        breakItem.pause,
                                        breakItem.pauseTime
                                      )
                                    );
                                  }
                                );
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                            />
                          </Button>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setRemoveId(item.id);
                              setRemoveConfirmation(!removeConfirmation);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                            />
                          </Button>
                        </IconColumn>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableResponsive>
            </Content>
          </div>
        </Wrapper>
      </CustomModal>
    </>
  );
};

export default WorkingDay;
