import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap/lib';
import i18next from 'i18next';
import CustomTable from '../../CustomTable';
import Pagination from '../../Pagination';

import iconOrderTop from '../../../assets/images/iconOrderTop.svg';
import iconOrderBottom from '../../../assets/images/iconOrderBottom.svg';
import searchIcon from '../../../assets/images/ant-design_search-outlined.png';

import { IconColumn } from '../styles';

export default function ListNotification({ handleEdit, data, handleDelete }) {
  const [totalPages, setTotalPages] = useState();

  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [columns, setColumns] = useState([
    {
      Header: i18next.language === 'pt-BR' ? 'Título' : 'Title',
      accessor: 'titulo',
    },
    {
      Header:
        i18next.language === 'pt-BR' ? 'Data/Hora de envio' : 'Send Date/Time',
      accessor: 'dataEnvio',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'Grupo de envio' : 'Send Group',
      accessor: 'tipo',
    },
    {
      Header: i18next.language === 'pt-BR' ? 'Modificado em' : 'Modified in',
      accessor: 'dataModificacao',
    },
  ]);

  const { t } = useTranslation();

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <CustomTable
        showDelete
        showEdit
        columns={columns}
        data={data}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />
      <Pagination
        perPage={perPage}
        total={totalPages}
        onPaginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
}
