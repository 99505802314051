import { object, ref, string } from 'yup';

export default object().shape({
  name: string()
    .required('* Enter the name!')
    .min(6, '* Minimum of 6 characteres!')
    .max(40, '* Maximum of 40 characteres!'),
  email: string()
    .email('* Invalid e-mail!')
    .required('* Enter the email!'),
  senha: string()
    .required('* Enter the password!')
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/, 'Invalid Password')
    .min(6, '* Minimum of 6 characteres!'),
  confirmPassword: string()
    .required('* Confirm your password!')
    .when('senha', (senha, field) =>
      senha
        ? field
            .required('* Repeat your password!')
            .oneOf([ref('senha')], '* Password does not matches!')
        : field
    ),
  group: string().required('* Enter the group!'),
  cnpj: string().notRequired(),
  // filial: string().required('* Informe a filial!'),
});
