import i18next from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { Label } from 'reactstrap';
import { BackendErrorNotification } from '../../core/common/Notification';
import api from '../../services/api';
import '../RegisterUsers/styles.scss';
import {
  GroupInput,
  animatedComponents,
  customStyles,
  useStyles,
} from './styles';

interface Props {
  handleChange?: any;
  handleSelect?: any;
  handleMultipleSelect?: any;
  optionValue?: string;
  valueSearch?: string;
  valueStartDate?: Date;
  valueFinalDate?: Date;
  toggleStartDate?: any;
  toggleEndDate?: any;
  optionCollection?: any;
  isDescription?: boolean;
  isTypeNotice?: boolean;
  isActivity?: boolean;
  isContractor?: boolean;
  isNumber?: boolean;
  isUpdateDate?: boolean;
  isUserName?: boolean;
  isURI?: boolean;
  isName?: boolean;
  isType?: boolean;
  isTypeNoticeValue?: boolean;
  isCPF?: boolean;
  isCNPJ?: boolean;
  isAvaliableVehicle?: boolean;
  isTypeVehicleDriver?: boolean;
  isTrademarkName?: boolean;
  isCompanyName?: boolean;
  isInitialDate?: boolean;
  isFinalDate?: boolean;
  isSubsidiary?: boolean;
  isRange?: boolean;
  isAvaliable?: boolean;
  isLicensePlate?: boolean;
  isTypeVehicle?: boolean;
  isIDVehicle?: boolean;
  isDriver?: boolean;
  isMaximumService?: boolean;
  isMaximumWeight?: boolean;
  isMaximumVolume?: boolean;
  isMaximumPrice?: boolean;
  isService?: boolean;

  isReferenceId?: boolean;
  isClientName?: boolean;
  isCity?: boolean;
  isNeighborhood?: boolean;
  isRouteNumber?: boolean;
  isDocNumber?: boolean;
  onExecuteFilter?: any;
  isTypeDriver?: boolean;
  handleSelectDriver?: any;
  style?: any;
  options?: any[];
  isTypeSubsidiary?: boolean;
}

const SearchFilter: React.FC<Props> = ({
  handleChange,
  handleSelect,
  handleMultipleSelect,
  optionValue,
  valueSearch,
  valueStartDate,
  valueFinalDate,
  toggleStartDate,
  toggleEndDate,
  optionCollection,
  isDescription,
  isTypeNotice,
  isActivity,
  isContractor,
  isNumber,
  isUpdateDate,
  isUserName,
  isName,
  isURI,
  isType,
  isTypeNoticeValue,
  isCPF,
  isCNPJ,
  isAvaliableVehicle,
  isTypeVehicleDriver,
  isTrademarkName,
  isCompanyName,
  isInitialDate,
  isFinalDate,
  isRange,
  isAvaliable,
  isLicensePlate,
  isTypeVehicle,
  isIDVehicle,
  isDriver,
  isMaximumService,
  isMaximumWeight,
  isMaximumVolume,
  isMaximumPrice,
  isService,
  isReferenceId,
  isClientName,
  isCity,
  isNeighborhood,
  isRouteNumber,
  isDocNumber,
  onExecuteFilter,
  isTypeDriver,
  handleSelectDriver,
  style,
  options,
  isSubsidiary,
  isTypeSubsidiary,
}: Props) => {
  const selectAllOption = {
    value: '<SELECT_ALL>',
    label:
      i18next.language === 'pt-BR' || i18next.language === 'pt'
        ? 'Selecionar todos'
        : 'Select ALl',
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const ref = useRef();
  const [isMulti, setIsMulti] = useState(false);

  const [optionValues, setOptionValues] = React.useState([]);

  const padronizeOptions = useCallback(
    values => {
      if (optionValue === 'tipoAtividade') {
        const options = values.map((item: any) => {
          return {
            value: item.id,
            label: item.nome,
          };
        });
        setOptionValues(options);
      } else if (optionValue === 'contratante') {
        const options = values.map((item: any) => {
          return {
            value: item.id,
            label: item.pessoaJuridica.nomeFantasia,
          };
        });
        setOptionValues(options);
      }
    },
    [handleMultipleSelect, optionValue]
  );

  const getVehicles = useCallback(async () => {
    try {
      const dataVehicles = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };

      const { data } = await api.post(
        '/delivery/veiculos/findByFilters',
        dataVehicles
      );
      const filterActiveVehicle = data.filter(
        (item: any) => item.status === 'ACTIVE'
      );
      const order = filterActiveVehicle.sort((a: any, b: any) => b.id - a.id);
      padronizeOptions(order);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, [padronizeOptions]);

  const getContrators = useCallback(async () => {
    try {
      const { data } = await api.get('/delivery/contratantes');

      const filterActiveContrator = data.filter(
        (item: any) => item.status === 'ACTIVE'
      );
      const order = filterActiveContrator.sort((a: any, b: any) => b.id - a.id);
      padronizeOptions(order);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, [padronizeOptions]);

  const getActivities = useCallback(async () => {
    try {
      const { data } = await api.get('/delivery/tiposAtividades');

      const filterActiveActivity = data.filter(
        (item: any) => item.status === 'ACTIVE'
      );
      const order = filterActiveActivity.sort((a: any, b: any) => b.id - a.id);
      padronizeOptions(order);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, [padronizeOptions]);

  const getDrivers = useCallback(async () => {
    try {
      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';
      const response = await api.get(
        `/delivery/motoristas?lang=${emailSendLanguage}`
      );

      const filterActiveDriver = response.data.filter(
        (item: any) => item.status === 'ACTIVE'
      );
      const order = filterActiveDriver.sort((a: any, b: any) => b.id - a.id);

      padronizeOptions(order);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, [padronizeOptions]);

  useEffect(() => {
    switch (optionValue) {
      case 'veiculo':
        setIsMulti(true);
        getVehicles();
        break;
      case 'contratante':
        setIsMulti(true);
        getContrators();
        break;
      case 'tipoAtividade':
        setIsMulti(true);
        getActivities();
        break;

      case 'motorista':
        setIsMulti(true);
        getDrivers();
        break;
      default:
        break;
    }
  }, [getActivities, getContrators, getDrivers, getVehicles, optionValue]);

  const tooglePermission = useCallback(
    (selectedOption, actionMeta) => {
      const { action, option, removedValue } = actionMeta;
      if (
        action === 'select-option' &&
        option.value === selectAllOption.value
      ) {
        handleMultipleSelect(optionValues);
      } else if (
        (action === 'deselect-option' &&
          option.value === selectAllOption.value) ||
        (action === 'remove-value' &&
          removedValue.value === selectAllOption.value)
      ) {
        handleMultipleSelect([]);
      } else {
        handleMultipleSelect(selectedOption);
      }
    },
    [handleMultipleSelect, optionValues, selectAllOption.value]
  );

  function changeValueStart(e: any) {
    toggleStartDate(e);
  }

  /**
   * Envia a data final para o elemento pai.
   */
  function changeValueEnd(e: any) {
    toggleEndDate(e);
  }

  const getMessageNoOptions = () => {
    return i18next.language === 'pt' || i18next.language === 'pt-BR'
      ? 'Todas as opções foram selecionadas'
      : 'All options are selected';
  };

  return (
    <div className="d-flex searchModal" style={style}>
      {optionValue === 'dataInicial' || optionValue === 'dataModificacao' ? (
        <div style={{ marginRight: 15 }}>
          <DatePicker
            className="dateInitialCargo"
            selected={valueStartDate}
            onChange={date => changeValueStart(date)}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
          />
        </div>
      ) : optionValue === 'dataFinal' ? (
        <div style={{ marginRight: 15 }}>
          <DatePicker
            className="dateFinalCargo"
            selected={valueFinalDate}
            onChange={date => changeValueEnd(date)}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
          />
        </div>
      ) : optionValue === 'periodo' ? (
        <>
          <DatePicker
            className="dateInitialCargo"
            selected={valueStartDate}
            onChange={date => changeValueStart(date)}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
          />
          <DatePicker
            className="dateFinalCargo"
            selected={valueFinalDate}
            onChange={date => changeValueEnd(date)}
            locale="ptBR"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
          />
        </>
      ) : (
        <>
          {' '}
          <Label className="search-Label" htmlFor="search-input">
            {!isMulti ? (
              <>
                {isTypeNoticeValue ? (
                  <GroupInput>
                    <select
                      name="filialSelector"
                      id="filial1"
                      className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                      style={{ borderBottom: '2px solid #EA004C' }}
                      onChange={handleSelectDriver}
                    >
                      <option label="Selecione uma Opcão" value="" />
                      <option label="Atencao" value="A" />
                      <option label="Perigo" value="P" />
                      <option label="Informacao" value="I" />
                    </select>
                  </GroupInput>
                ) : isTypeDriver ? (
                  <GroupInput>
                    <select
                      name="filialSelector"
                      id="filial1"
                      className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                      style={{ borderBottom: '2px solid #EA004C' }}
                      onChange={handleSelectDriver}
                    >
                      <option label="Selecione uma Opcão" value="" />
                      <option label="Próprio" value="PROPRIO" />
                      <option label="Autônomo" value="AUTONOMO" />
                      <option label="Terceirizado" value="TERCEIRIZADO" />
                    </select>
                  </GroupInput>
                ) : isAvaliableVehicle ? (
                  <GroupInput>
                    <select
                      name="filialSelector"
                      id="filial1"
                      className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                      style={{ borderBottom: '2px solid #EA004C' }}
                      onChange={handleSelectDriver}
                    >
                      <option label="Selecione uma Opcão" value="" />
                      <option label="SIM" value="true" />
                      <option label="NÃO" value="false" />
                    </select>
                  </GroupInput>
                ) : isTypeVehicleDriver ? (
                  <GroupInput>
                    <select
                      name="filialSelector"
                      id="filial1"
                      className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                      style={{ borderBottom: '2px solid #EA004C' }}
                      onChange={handleSelectDriver}
                    >
                      <option label="Selecione uma Opcão" value="" />
                      <option label="VUC" value="VUC" />
                      <option label="TOCO" value="TOCO" />
                      <option label="TRUCK" value="TRUCK" />
                      <option label="CARRETA" value="CARRETA" />
                      <option label="VAN" value="VAN" />
                      <option label="VEICULO PASSEIO" value="VEICULO_PASSEIO" />
                      <option label="MOTO" value="MOTO" />
                      <option label="BICICLETA" value="BICICLETA" />
                    </select>
                  </GroupInput>
                ) : isTypeSubsidiary ? (
                  <GroupInput>
                    <select
                      name="filialSelector"
                      id="filial1"
                      className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                      style={{ borderBottom: '2px solid #EA004C' }}
                      onChange={handleSelectDriver}
                    >
                      <option label="Selecione uma Opcão" value="" />
                      {options?.map((item: any) => (
                        <option label={item.nome} value={item.id} />
                      ))}
                    </select>
                  </GroupInput>
                ) : (
                  <div className="search">
                    <input
                      type="search"
                      name="BuscaRomaneio"
                      id="search-input"
                      placeholder={t('driver.search')}
                      className="tw-w-48 tw-h-9 tw-bg-[#fff] tw-box-border tw-rounded tw-font-normal tw-text-sm tw-text-[#003049] tw-pl-3 tw-mr-5"
                      style={{ border: '2px solid rgba(0,48,73,0.2)' }}
                      value={valueSearch}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            ) : (
              <GroupInput>
                <ReactSelect
                  className="select"
                  styles={customStyles}
                  value={optionCollection}
                  isMulti
                  options={
                    optionValues.length === optionCollection.length
                      ? optionValues
                      : [selectAllOption, ...optionValues]
                  }
                  components={animatedComponents}
                  onChange={tooglePermission}
                  isClearable
                  hideSelectedOptions
                  isSearchable
                  noOptionsMessage={getMessageNoOptions}
                  closeMenuOnSelect={false}
                />
              </GroupInput>
            )}
          </Label>
        </>
      )}
      <GroupInput>
        <select
          name="filialSelector"
          id="filial1"
          className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
          style={{ borderBottom: '2px solid #EA004C' }}
          onChange={handleSelect}
        >
          {isDescription && (
            <option value="descricao" label={t('selectVehicles.description')} />
          )}
          {isURI && <option value="URI" label="URI" />}
          {isLicensePlate && (
            <option value="placa" label={t('selectVehicles.licensePlate')} />
          )}
          {isAvaliable && (
            <option value="disponivel" label={t('selectVehicles.avaliable')} />
          )}

          {isTypeVehicle && (
            <option value="tipo" label={t('selectVehicles.type')} />
          )}

          {isIDVehicle && <option value="idReferencia" label="ID" />}
          {isDriver && (
            <option value="motorista.nome" label={t('selectVehicles.driver')} />
          )}
          {isMaximumService && (
            <option
              value="quantidadeAtendimento"
              label={t('selectVehicles.maxAttendances')}
            />
          )}
          {isMaximumWeight && (
            <option value="peso" label={t('selectVehicles.weight')} />
          )}
          {isMaximumVolume && (
            <option value="cubagem" label={t('selectVehicles.volume')} />
          )}
          {isMaximumPrice && (
            <option value="valor" label={t('selectVehicles.price')} />
          )}
          {isNumber && (
            <option value="numero" label={t('ocorrences.fields.number')} />
          )}
          {isActivity && (
            <option
              value="tipoAtividade"
              label={t('ocorrences.fields.activityType')}
            />
          )}

          {isReferenceId && (
            <option label="Id de referencia" value="idReferencia" />
          )}
          {isClientName && (
            <option label="Nome do Cliente" value="nomeCliente" />
          )}
          {isCity && <option label="Cidade" value="cidade" />}
          {isNeighborhood && <option label="Bairro" value="bairro" />}

          {isRouteNumber && <option label="Nº da Rota" value="numeroRota" />}
          {isDocNumber && <option label="Nº do Documento" value="docNumber" />}
          {isContractor && (
            <option
              value="contratante"
              label={t('ocorrences.fields.contractor')}
            />
          )}
          {isName && <option value="nome" label={t('driver.fields.name')} />}
          {isType && <option value="tipo" label={t('driver.fields.type')} />}
          {isTypeNotice && (
            <option value="tipo" label={t('driver.fields.type')} />
          )}
          {isCPF && <option value="cpf" label={t('driver.fields.cpf')} />}
          {isCNPJ && (
            <option value="cnpj" label={t('operatorLogistic.fields.cnpj')} />
          )}
          {isTrademarkName && (
            <option
              value="nomeFantasia"
              label={t('operatorLogistic.fields.nomeFantasia')}
            />
          )}
          {isCompanyName && (
            <option
              value="razaoSocial"
              label={t('operatorLogistic.fields.razaoSocial')}
            />
          )}
          {isInitialDate && (
            <option
              value="dataInicial"
              label={t('notices.fields.initialDate')}
            />
          )}
          {isFinalDate && (
            <option value="dataFinal" label={t('notices.fields.finalDate')} />
          )}
          {isRange && (
            <option value="periodo" label={t('notices.fields.range')} />
          )}
          {isUpdateDate && (
            <option
              value="dataModificacao"
              label={t('routeOptmization.simulation.fields.date')}
            />
          )}
          {isUserName && (
            <option
              value="user"
              label={t('routeOptmization.simulation.fields.user')}
            />
          )}

          {isSubsidiary && <option label="Filial" value="subsidiary" />}
        </select>
      </GroupInput>{' '}
      {isService && (
        <div>
          <button
            className="buttonFilterTower"
            style={{ marginTop: 0 }}
            type="button"
            onClick={() => onExecuteFilter()}
          >
            <Trans i18nKey="controlTower.buttonFilterActions" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
