import iconWeightCargoLight from 'assets/images/iconWeightCargoLight.svg';
import listAttendancesLight from 'assets/images/listAttendancesLight.svg';
import packageRouteringLight from 'assets/images/packageRouteringLight.svg';
import priceCargoLight from 'assets/images/priceCargoLight.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { DataStepsRoutering } from 'store/modules/routering/actions';
import './styles.scss';

// import { Container } from './styles';

interface Props {
  data: DataStepsRoutering[];
}

const CardValueKnowledge: React.FC<Props> = ({ data }: Props) => {

  const [totalValueWeight, setTotalValueWeight] = useState<string>('');
  const [totalValueVolume, setTotalValueVolume] = useState<string>('');
  const [totalValuePrice, setTotalValuePrice] = useState<string>('');

  const getValueKnowledge = useCallback(async () => {
    if (data.length > 0) {
      const valueWeight = data.reduce((acc: any, obj: any) => {
        return acc + parseFloat(obj.conhecimentoPre.peso);
      }, 0);
      const valueVolume = data.reduce((acc: any, obj: any) => {
        return (
          acc +
          parseFloat(
            obj.conhecimentoPre.cubagem.length === 0
              ? 0
              : obj.conhecimentoPre.cubagem
          )
        );
      }, 0);
      const valuePrice = data.reduce((acc, obj) => {
        return acc + parseFloat(obj.conhecimentoPre.valor);
      }, 0);

      setTotalValueWeight(valueWeight);
      setTotalValueVolume(valueVolume);
      setTotalValuePrice(`${valuePrice}`);
    }
  }, [data]);

  function formatReal(int: any) {
    const elemento = int;
    let valor = elemento;

    valor += '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor += '';
    valor = valor.replace(/([0-9]{2})$/g, ',$1');

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
    }

    return valor;
  }

  useEffect(() => {
    getValueKnowledge();
  }, [getValueKnowledge]);

  return (
    <div className="containerValueKnowledge" style={{ marginTop: 10 }}>
      <div className="contentValueKnowledge">
        <span className="textContentKnowledge">
          <Trans i18nKey="routeOptmization.routeSteps.attendances" />
        </span>
        <div className="chipKnowledge">
          <img src={listAttendancesLight} alt="" />
          <span className="chipText">{data.length}</span>
        </div>
      </div>
      <div className="contentValueKnowledge">
        <span className="textContentKnowledge">
          <Trans i18nKey="routeOptmization.routeSteps.weight" />
        </span>
        <div className="chipKnowledge">
          <img src={iconWeightCargoLight} alt="" />

          <span className="chipText">
            {parseFloat(totalValueWeight).toFixed(2)} Kg
          </span>
        </div>
      </div>
      <div className="contentValueKnowledge">
        <span className="textContentKnowledge">
          <Trans i18nKey="routeOptmization.routeSteps.volume" />
        </span>
        <div className="chipKnowledge">
          <img src={packageRouteringLight} alt="" />

          <span className="chipText">{parseFloat(totalValueVolume).toFixed(2)} m³</span>
        </div>
      </div>
      <div className="contentValueKnowledge">
        <span className="textContentKnowledge">
          <Trans i18nKey="routeOptmization.routeSteps.price" />
        </span>
        <div className="chipKnowledge">
          <img src={priceCargoLight} alt="" />

          <span className="chipText">
            R${' '}
            {Number(totalValuePrice) === 0
              ? '0,00'
              : formatReal(parseFloat(totalValuePrice).toFixed(2))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardValueKnowledge;
