import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from 'reactstrap/lib';
import { resetVehiclesAccumulator } from 'store/modules/accumulator/actions';
import { saveSubsidiary } from 'store/modules/map/actions';
import { clearVehiclesSelected } from 'store/modules/vehicles/actions';

interface Props {
  listSubsidiary?: any;
  blockedSubsidiary?: string;
  onChangeSubsidiary?: any;
}

interface SubsidiaryData {
  id?: number;
  nome?: string;
}

const TopBar: React.FC<Props> = ({
  listSubsidiary,
  blockedSubsidiary,
  onChangeSubsidiary
}: Props) => {
  const [dataSubsidiary, setDataSubsidiary] = useState<SubsidiaryData[]>([]);
  const [idSubsidiary, setIdSubisidiary] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();


  useEffect(() => {
    if(listSubsidiary) {
      setDataSubsidiary(listSubsidiary)
      if(sessionStorage.getItem('subsidiary')) {
        if(sessionStorage.getItem('subsidiary')?.length === 0) {
          sessionStorage.setItem('subsidiary', listSubsidiary[0].id);
        }
      } else {
        sessionStorage.setItem('subsidiary', listSubsidiary[0].id);
      }
    }
  }, [listSubsidiary])

  return (
    <div className="topBar">
      <Form inline>
        <div className="filialLabel" style={{ zIndex: 3 }}>
          <Trans i18nKey="filter.subsidiary" />
          <select
            name="filialSelector"
            id="filial1"
            disabled={blockedSubsidiary === 'blocked' ?  true : false}
            className="filialInput"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatch(clearVehiclesSelected());
              dispatch(resetVehiclesAccumulator());
              setIdSubisidiary(e.target.value)
              dispatch(saveSubsidiary(e.target.value));
              sessionStorage.setItem('subsidiary', e.target.value);
              onChangeSubsidiary(e.target.value)
            }}
            value={sessionStorage.getItem('subsidiary') || ''}
          >
            <option value="" label={t('common.select')} id='select' />
            {dataSubsidiary.map((subsidiary: SubsidiaryData) => (
              <option key={subsidiary.id} value={subsidiary.id} id={subsidiary.nome}>
                {subsidiary.nome}
              </option>
            ))}
          </select>
        </div>
      </Form>
    </div>
  );
};

export default TopBar;
