import { BackendErrorNotification } from 'core/common/Notification';
import TopBar from 'pages/ManagementForms/TopBar';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Trans } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';
import api from 'services/api';
import './styles.scss';


import 'react-datepicker/dist/react-datepicker.css';

export default function Billing() {
  const [subsidiary, setSubsidiary] = useState([]);

  const getSubsidiary = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      setSubsidiary(response.data);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  useEffect(() => {
    getSubsidiary();
  }, [getSubsidiary]);

  return (
    <>
      <TopBar listSubsidiary={subsidiary} />

      <div className="containerBilling">
        <div className="headerTitle">
          <span className="titleBilling">Cobranças</span>
          <div className="formBilling">
            <Row>
              <Col md={4}>
                <Label style={{ display: 'flex', flexDirection: 'column' }}>
                  <Trans i18nKey="notices.fields.initialDate" />

                  <DatePicker
                    className="formDateBilling"
                    //   selected={startDate}
                    onChange={date => {
                      // setStartDate(date);
                      // formik.setFieldValue('dataInicial', date);
                    }}
                    locale="ptBR"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    style={{
                      height: 60,
                      background: '#FFFFFF',
                      border: '2px solid rgba(0, 48, 73, 0.2)',
                      boxSizing: 'border-box',
                      borderRadius: 10,
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: 19,
                      color: '#003049',
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  />
                </Label>
              </Col>

              <Col md={4}>
                <Label style={{ display: 'flex', flexDirection: 'column' }}>
                  <Trans i18nKey="notices.fields.initialDate" />

                  <DatePicker
                    className="formDateBilling"
                    //   selected={startDate}
                    onChange={date => {
                      // setStartDate(date);
                      // formik.setFieldValue('dataInicial', date);
                    }}
                    locale="ptBR"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    style={{
                      height: 60,
                      background: '#FFFFFF',
                      border: '2px solid rgba(0, 48, 73, 0.2)',
                      boxSizing: 'border-box',
                      borderRadius: 10,
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: 19,
                      color: '#003049',
                      marginTop: 8,
                      paddingLeft: 10,
                    }}
                  />
                </Label>
              </Col>
              <Col md={4} lg={4}>
                <button className="filterBilling">Filtrar período</button>
              </Col>
            </Row>
          </div>
          <div className="formBilling">
            <div className="formBillingBox">
              <Row md={12}>
                <Col>
                  <span className="titleValue">Entregas realizadas</span>

                  <span>67</span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
