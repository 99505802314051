import React, { useCallback, useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import './styles.scss';
import leftArrow from '../../../assets/images/left-arrow.png';
import rightArrow from '../../../assets/images/right-arrow.png';
import IconDeliveryDone from '../../../assets/images/knowledge/deliveryDone.svg';
import IconDeliveryNoPrazo from '../../../assets/images/deliveryPrazo.svg';
import IconDeliveryCancel from '../../../assets/images/cancelIcon.svg';
import IconDeliveryOverdue from '../../../assets/images/knowledge/deliveryOverdue.svg';
import ProgressBar from '../../../components/Map/Cargo/progressbar';

export default function Carousel({
  data,
  actualSteps,
  onViewRoutes,
  onViewAlerts,
}) {
  const [stepsActual, setStepsActual] = useState(0);
  const [active, setActive] = useState(false);

  const getStepsContent = useCallback(
    (stepActual, dataArr) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === dataArr.length - 1) {
        setStepsActual(dataArr.length - 1);
      } else {
        setStepsActual(prevState => {
          return prevState + 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  const getStepsContentLeft = useCallback(
    (stepActual, dataArr) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === 0) {
        setStepsActual(0);
      } else {
        setStepsActual(prevState => {
          return prevState - 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  useEffect(() => {
    if (stepsActual > data.length - 1) {
      setStepsActual(0);
    }
  }, [stepsActual, data.length]);

  return (
    <div className="carouselContainer">
      <div className="carouselWrapper">
        <div
          className="carouselPanelLeft"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContentLeft(stepsActual, data)}
        >
          <img alt="" src={leftArrow} width="15" height="15" />
        </div>
        <div className="carouselPanelContent">
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 15, marginBottom: 11 }}>
              <b
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 900,
                  fontSize: 13,
                  color: '#003049',
                }}
              >
                <Trans i18nKey="common.nameDelivery" /> (a){' '}
              </b>{' '}
              <br />{' '}
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  color: '#003049',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual > data.length - 1 ? 0 : stepsActual]
                      .motorista.nome
                  : ''}
              </p>
            </div>
            <div style={{ marginBottom: 11 }}>
              <b
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 900,
                  fontSize: 13,
                  color: '#003049',
                }}
              >
                <Trans i18nKey="map.phone" />
              </b>{' '}
              <br />{' '}
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  color: '#003049',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual > data.length ? 0 : stepsActual].motorista
                      .telefone
                  : ''}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 15 }}>
              <b
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 900,
                  fontSize: 13,
                  color: '#003049',
                }}
              >
                <Trans i18nKey="delivery.shipment.number" />
              </b>{' '}
              <br />{' '}
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  color: '#003049',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual > data.length ? 0 : stepsActual].numero
                  : ''}
              </p>
            </div>
            <div style={{ marginRight: 15 }}>
              <b
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 900,
                  fontSize: 13,
                  color: '#003049',
                }}
              >
                <Trans i18nKey="common.delivery" />
              </b>{' '}
              <br />
              <p
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  color: '#003049',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual > data.length ? 0 : stepsActual]
                      .totalEntregas
                  : '0'}{' '}
                /{' '}
                {data && data.length > 0
                  ? data[stepsActual > data.length ? 0 : stepsActual]
                      .totalAtendimentos
                  : '0'}
              </p>
            </div>
            <div>
              <b
                style={{
                  fontFamily: 'Lato',
                  fontWeight: 900,
                  fontSize: 13,
                  color: '#003049',
                }}
              >
                <Trans i18nKey="map.status" />:
              </b>{' '}
              <br />
              <div style={{ display: 'flex' }}>
                {data[stepsActual > data.length ? 0 : stepsActual]
                  .statusEntrega === 'ATRASADO' ? (
                  <img
                    src={IconDeliveryOverdue}
                    alt=""
                    className="icon-custom fa"
                  />
                ) : data[stepsActual > data.length ? 0 : stepsActual]
                    .statusEntrega === 'NO_PRAZO' ? (
                  <img
                    src={IconDeliveryNoPrazo}
                    alt=""
                    className="icon-custom fa"
                  />
                ) : data[stepsActual > data.length ? 0 : stepsActual]
                    .statusEntrega === 'CANCELADO' ? (
                  <img
                    src={IconDeliveryCancel}
                    alt=""
                    className="icon-custom fa"
                  />
                ) : (
                  <>
                    <img
                      src={IconDeliveryDone}
                      alt=""
                      className="icon-custom fa"
                    />
                  </>
                )}
                <p
                  style={{
                    fontFamily: 'Lato',
                    fontSize: 14,
                    color: '#003049',
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {data[stepsActual > data.length ? 0 : stepsActual]
                    .statusEntrega === 'NO_PRAZO' ? (
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        color: '#6369d1',
                      }}
                    >
                      No prazo
                    </span>
                  ) : data[stepsActual > data.length ? 0 : stepsActual]
                      .statusEntrega === 'CANCELADO' ? (
                    <span
                      style={{
                        fontSize: 14,
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        color: '#d62828',
                      }}
                    >
                      {
                        data[stepsActual > data.length ? 0 : stepsActual]
                          .statusEntrega
                      }
                    </span>
                  ) : (
                    <span>
                      {
                        data[stepsActual > data.length ? 0 : stepsActual]
                          .statusEntrega
                      }
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div style={{ marginRight: 17 }}>
              <ProgressBar
                value={
                  data[stepsActual > data.length ? 0 : stepsActual]
                    .percentualEntregas
                }
                widthProgress={170}
                topProgress={10}
              />
            </div>
            <div style={{ marginTop: 5 }}>
              <b
                style={{
                  fontSize: 14,
                  fontFamily: 'Lato',
                  color: '#EA004C',
                  cursor: 'pointer',
                }}
                onClick={() => onViewRoutes(stepsActual)}
              >
                <Trans i18nKey="common.viewRoute" />
              </b>
            </div>
          </div>
          {data[0].possuiAlertaNaoLido && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <b
                style={{
                  fontSize: 14,
                  fontFamily: 'Lato',
                  color: '#EA004C',
                  cursor: 'pointer',
                }}
                onClick={() => onViewAlerts(stepsActual)}
              >
                Ver alertas
              </b>
            </div>
          )}
        </div>
        <div
          className="carouselPanelRight"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContent(stepsActual, data)}
        >
          <img alt="" src={rightArrow} width="15" height="15" />
        </div>
      </div>
      {/* <Dots /> */}
    </div>
  );
}
