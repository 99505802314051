/* eslint-disable operator-assignment */
import produce from 'immer';
import { SocketPayload } from './actions';

interface ActionReducer {
  type: string;
  payload: any;
}

const INITIAL_STATE: SocketPayload = {
  data: []
};


export default function socket(state = INITIAL_STATE, action: ActionReducer) {
  return produce(state, (draft: SocketPayload) => {
    switch (action.type) {
      case '@socket/SET_RECEIVED_DATA': {
        draft.data.push(action.payload.data);
        break;
      }
      case '@socket/UPDATE_RECEIVED_DATA': {
        draft.data[action.payload.index].isMessageReceived = action.payload.dataUpdated.isMessageReceived;
        break;
      }
      default:
    }
  });
}
