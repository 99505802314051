import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  title: string()
    .required('* Informe o titulo do template')
    .min(4, '* Mínimo de 4 caratere')
    .max(100, '* Máximo de 100 carateres'),
  content: string().required('* Informe o conteudo do template'),
});
