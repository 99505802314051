import styled from 'styled-components';

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  margin-top: 15px;
  /* height: 100%; */
  border-top: 12px solid ${props => props.colorActive && props.colorActive};
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.18);
  .labelStatus {
    font-size: 14px;
    font-family: 'Lato';
    font-weight: bold;
    color: ${props => props.color};
  }
  .line {
    width: 2px;
    height: 34px;
    background-color: #d2d2d2;
    margin-right: 10px;
    margin-left: 10px;
  }
  .weightMargin {
    margin-right: 5px;
  }
  .linkButton {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    color: #ea004c;
    cursor: pointer;
  }
  .imgMargin {
    margin-right: 5px;
    width: 16px;
    height: 16px;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 3px;
  }
  .clientName {
    word-break: break-word;
  }
`;

export const ServiceRow = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberOrder = styled.div`
  background-color: #6369d1;
  border-radius: 100%;
  color: #fff;
  width: ${props => (props.size === 'normal' ? 20 : 30)}px;
  height: ${props => (props.size === 'normal' ? 20 : 30)}px;
  display: flex;
  font-family: 'Lato-Black';
  font-size: 13px;
  margin-right: 0.5rem;
  justify-content: center;
  align-items: center;
`;

export const NumberDelivery = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 01 */
  color: #003049;
`;
export const NumberDeliveryLabel = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  /* 01 */
  color: #003049;
`;
export const ServiceColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.size}%;
  margin-right: 10px;
  margin-top: 10px;
`;

export const StatusLabel = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  /* 01 */
  color: #003049;
`;
export const ContractorLabel = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 16px;
  cursor: ${props => (props.click ? 'pointer' : 'default')};
  /* identical to box height */
  /* 01 */
  color: #003049;
`;
export const Contractor = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* 01 */
  color: #003049;
  align-items: center;
  justify-content: center;
`;

export const Status = styled.div`
  display: flex;
  .labelStatus {
    font-size: 14px;
    font-family: 'Lato';
    font-weight: bold;
    color: ${props => props.color};
  }
`;

export const Requirements = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  /* 01 */
  color: #003049;
`;