import React from 'react';
import classNames from 'classnames';
import { Container } from 'reactstrap';

const Content = props => (
  <Container
    fluid
    className={classNames('content', { 'is-open': props.sidebarIsOpen })}
  >
    {props.children}
  </Container>
);

export default Content;
