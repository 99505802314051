export const stylesViewNotices = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'left',
  width: '100%',
  backgroundColor: '#fff',
  border: '2px solid rgba(23, 40, 49, 0.2)',
  borderRadius: 10,
  height: 60,
  paddingRight: '30%',
};
