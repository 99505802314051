import styled from 'styled-components';

export const ContainerIntegrationAPI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none !important;
`;

export const ActiveText = styled.p`
  font-family: Lato;
  font-size: 16px;
  color: #003049;
  border: none;
`;


export const Line = styled.div`
  /* width: 100%; */
  height: 0px;
  border: 0.5px solid #ccd6db;
  margin-left: 18px;
  margin-bottom: 20px;
`;