/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import searchIcon from '../../assets/images/ant-design_search-outlined.png';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from '../../core/common/Notification';
import api from '../../services/api';
import './style.scss';
import { iconClose, iconCloseBody } from './styles';

import PhasesSMS from '../PhasesSMS';

import AcceptDecline from './AcceptDecline';
import FormPhases from './FormPhases';

export default function Phases({ onClear }) {
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsFilter, setOptionsFilter] = useState('descricao');
  const [valueSearch, setValueSearch] = useState('');
  const [sms, setSMS] = useState(false);
  const [messageSMS, setMessageSMS] = useState('');
  const [tabs, setTabs] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [descriptionCollect, setDescriptionCollect] = useState('');
  const [descriptionSMSAccept, setDescriptionSMSAccept] = useState('');
  const [descriptionSMSDecline, setDescriptionSMSDecline] = useState('');
  const [removeId, setRemoveId] = useState('');

  const [phases, setPhases] = useState([]);
  const useStyles = makeStyles(theme => ({
    switch: {
      width: 76,
      height: 34,
      border: '1px solid #C4C4C4',
    },
  }));
  const [totalPages, setTotalPages] = useState(0);

  const getPhases = useCallback(
    async tabsSelect => {
      try {
        let tabsServices;

        if (tabsSelect) {
          tabsServices = tabsSelect;
        } else {
          tabsServices = tabs;
        }
        const data = {
          filters: [
            {
              field: 'status',
              value: 'ACTIVE',
              operation: 'EQUAL',
            },
            {
              field: 'tipoAtendimento',
              value: tabsServices === 0 ? 'ENTREGA' : 'COLETA',
              operation: 'EQUAL',
            },
          ],
          
        };
        const response = await api.post('/delivery/etapas/findByFilters', data);

        const orderPhases = response.data.sort(
          (a, b) => a.sequencia - b.sequencia
        );

        const mappingPhases = response.data.map((item, index) => {
          return {
            id: item.id,
            sequencia: index + 1,
          };
        });

        const responseSequence = await api.patch(
          '/delivery/etapas/atualizaSequencia',
          mappingPhases
        );

        setPhases(orderPhases);
      } catch (err) {}
    },
    [tabs]
  );

  const { t } = useTranslation();

  const filtersData = useMemo(() => {
    let computedPages = phases;

    if (optionsFilter === 'descricao') {
      computedPages = computedPages.filter(op =>
        op.descricao.toLowerCase().includes(valueSearch.toLowerCase())
      );
    }

    setTotalPages(Math.ceil(computedPages.length / 10));

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [currentPage, phases, valueSearch]);

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const updateSequence = useCallback(
    async (arr, from, to) => {
      try {
        arr.splice(to, 0, arr.splice(from, 1)[0]);

        const mappingPhases = arr.map((item, index) => {
          return {
            id: item.id,
            sequencia: index + 1,
          };
        });

        const response = await api.patch(
          '/delivery/etapas/atualizaSequencia',
          mappingPhases
        );

        getPhases(tabs);

        // setPhases(arr);

        // setPhases(arr);
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [phases]
  );

  const deleteDriver = useCallback(async (driverId, tabsPhases) => {
    try {
      // setRemoveConfirmation(true);
      const response = await api.delete(`/delivery/etapas/${driverId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('phases.messageRemoved'),
          NotificationType.SUCCESS
        );
        setRemoveConfirmation(false);

        getPhases(tabsPhases);
      } else {
        FrontendNotification(
          t('phases.messageErroRemoved'),
          NotificationType.ERROR
        );
      }
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const currentData = phases.slice(indexOfFirstPage, indexOfLastPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const classes = useStyles();

  useEffect(() => {
    getPhases();
  }, [getPhases]);

  return (
    <>
      {sms && <PhasesSMS message={messageSMS} onClear={() => setSMS(!sms)} />}

      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId, tabs)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>

      {/* <Loading loading={loading} /> */}

      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', height: '100%' }}
      >
        <div className="iconCloseBody" style={{ ...iconCloseBody }}>
          <div
            className="iconClose"
            style={{
              ...iconClose,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          {tabs === 0 || tabs === 1 ? (
            <div
              className="d-flex"
              style={{
                position: 'absolute',
                right: 74,
                padding: 20,
                top: 30,
              }}
            >
              <Label className="search-Label" htmlFor="search-input">
                <div className="search">
                  <input
                    type="search"
                    name="BuscaRomaneio"
                    id="search-input"
                    placeholder={t('driver.search')}
                    className="tw-w-48 tw-h-9 tw-bg-[#fff] tw-box-border tw-rounded tw-font-normal tw-text-sm tw-text-[#003049] tw-pl-3 tw-mr-5"
                    value={valueSearch}
                    onChange={e => {
                      setValueSearch(e.target.value);
                    }}
                    style={{ border: '2px solid rgba(0,48,73,0.2)' }}
                  />
                  <img
                    src={searchIcon}
                    alt=""
                    style={{
                      width: 24,
                      height: 24,
                      position: 'relative',
                      right: 56,
                      top: '-2px',
                    }}
                  />
                </div>
              </Label>
              <div style={{ flexDirection: 'column' }}>
                <select
                  name="filialSelector"
                  id="filial1"
                  className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                  onChange={e => setOptionsFilter(e.target.value)}
                  style={{ borderBottom: '2px solid #EA004C' }}
                >
                  <option
                    value="descricao"
                    label={t('phases.fields.description')}
                  />
                </select>
              </div>
            </div>
          ) : null}{' '}
          <p className="title" style={{ fontSize: '1.4rem' }}>
            <Trans i18nKey="phases.title" />
          </p>
          <Tabs selectedIndex={tabs} onSelect={index => setTabs(index)}>
            <TabList>
              <Tab>
                <Trans i18nKey="phases.tabsPhases.titleTabs1" />
              </Tab>
              <Tab>
                <Trans i18nKey="phases.tabsPhases.titleTabs2" />
              </Tab>
              <Tab>
                <Trans i18nKey="phases.tabsPhases.titleTabs3" />
              </Tab>
            </TabList>

            <TabPanel>
              <FormPhases
                data={filtersData}
                totalPages={totalPages}
                dataArr={phases}
                onPaginatePhase={paginate}
                phaseTab={tabs}
                onLoadPhases={() => getPhases()}
                onRemove={phasesId => {
                  setRemoveId(phasesId);
                  setRemoveConfirmation(!removeConfirmation);
                }}
                onUpdateSequence={(
                  dataArrSequence,
                  indexSequence,
                  toSequence
                ) => updateSequence(dataArrSequence, indexSequence, toSequence)}
                onSMS={smsMessage => {
                  setSMS(true);
                  setMessageSMS(smsMessage);
                }}
              />
            </TabPanel>

            <TabPanel>
              <FormPhases
                data={filtersData}
                dataArr={phases}
                totalPages={totalPages}
                phaseTab={tabs}
                onPaginatePhase={paginate}
                onRemove={phasesId => {
                  setRemoveId(phasesId);
                  setRemoveConfirmation(!removeConfirmation);
                }}
                onLoadPhases={() => getPhases()}
                onUpdateSequence={(
                  dataArrSequence,
                  indexSequence,
                  toSequence
                ) => updateSequence(dataArrSequence, indexSequence, toSequence)}
                onSMS={smsMessage => {
                  setSMS(true);
                  setMessageSMS(smsMessage);
                }}
              />
            </TabPanel>

            {/* ------- Aceitar/Recusar ------- */}

            <TabPanel>
              <AcceptDecline />
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
