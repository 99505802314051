import { number, object, string } from 'yup';

export default object().shape({
  tipo: string().required('* Enter the type of vehicle!'),
  descricao: string().required('* Enter the description!'),
  motoristaId: string().required('* Enter the driver of vehicle'),

  placa: string().required('* Enter the license plate!'),
  filialId: string().required('* Enter the subsidiary vehicle!'),
  veiculoProprio: string().required('* Enter if you have your own vehicle!'),
  marcaModelo: string().required('* Enter the make or model of the vehicle!'),
  ano: number().required('* Enter the year of the vehicle!'),
  anoFabricacao: number().required(
    '* Enter the year of manufacture of the vehicle!'
  ),
  peso: number().required('* Enter the maximum weight of the vehicle!'),
  quantidadeAtendimento: number().required(
    '* Enter the maximum number of calls from the vehicle!'
  ),
  cubagem: number().required('* Enter the maximum cubage!'),
  valor: number().required('* Enter the maximum amount!'),
});
