import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import { ICreateShippingTableForm } from '..';
import RatingFields, { RatingOptionsContent } from './components/RatingFields';
import { Container, Title } from './styles';

export interface RatingDataContent {
  id: number | string;
  label: string;
}

export interface RatingData {
  branchs: RatingDataContent[];
  logisticOperators: RatingDataContent[];
  vehicle: RatingDataContent[];
}

interface RatingProps {
  onChange: (field: string, value: string) => void;
  values: ICreateShippingTableForm;
  errors: any;
  touched: any;
  isView: boolean;
}

const Rating: React.FC<RatingProps> = ({
  onChange,
  values,
  errors,
  touched,
  isView,
}: RatingProps) => {
  const [ratingData, setRatingData] = useState<RatingData>({
    branchs: [],
    logisticOperators: [],
    vehicle: [],
  });
  const [typeVehicle] = useState<RatingOptionsContent[]>([
    {
      id: 'TRUCK',
      label: 'TRUCK',
    },
    {
      id: 'CARRETA',
      label: 'CARRETA',
    },
    {
      id: 'BITREM',
      label: 'BITREM',
    },
  ]);
  const [typeAttendance] = useState<RatingOptionsContent[]>([
    {
      id: 'ENTREGA',
      label: 'ENTREGA',
    },
    {
      id: 'COLETA',
      label: 'COLETA',
    },
  ]);

  const userData = useSelector((state: any) => state.auth.user);

  const getOperadorLogistico = useCallback(async () => {
    const dataFilters = {
      filters: [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/delivery/operadoresLogisticos/findByFilters',
      dataFilters
    );

    const content = response.data.map((operator: any) => {
      return {
        id: operator.id,
        label: operator.razaoSocial,
      };
    });

    return content;
  }, []);

  const getVeiculo = useCallback(async (typeVehicle?: string) => {
    let dataFilters = {
      filters: [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/delivery/veiculos/findByFilters',
      dataFilters
    );

    const content = response.data.map((vehicle: any) => {
      return {
        id: vehicle.id,
        label: vehicle.placa,
      };
    });

    return content;
  }, []);

  const getRatingDataContent = useCallback(async () => {
    try {
      const getSubsidiary = userData.filiais.map((filial: any) => {
        return {
          id: filial.id,
          label: filial.nome,
        };
      });
      const getLogisticOperator = await getOperadorLogistico();
      const getVehicle = await getVeiculo();

      setRatingData({
        branchs: getSubsidiary,
        logisticOperators: getLogisticOperator,
        vehicle: getVehicle,
      });
    } catch {}
  }, []);

  useEffect(() => {
    getRatingDataContent();
  }, []);
  return (
    <Container>
      <Title>Classificadores</Title>

      <RatingFields
        data={ratingData.branchs}
        field="filialOrigem"
        dotColor="#EA004C"
        title="Filial Origem *"
        placeholder="Selecione a filial"
        onChange={value => onChange('filialOrigem', value)}
        value={values.filialOrigem || ''}
        errors={errors}
        touched={touched}
        isView={isView}
      />
      <RatingFields
        data={ratingData.logisticOperators}
        field="operadorLogistico"
        dotColor="#EA7000"
        title="Op. Logístico"
        placeholder="Selecione a origem"
        onChange={value => onChange('operadorLogistico', value)}
        value={values.operadorLogistico || ''}
        errors={errors}
        touched={touched}
        isView={isView}
      />
      <RatingFields
        data={typeVehicle}
        field="tipoVeiculo"
        dotColor="#10841C"
        title="Tipo de veículo"
        placeholder="Selecione o tipo"
        onChange={value => onChange('tipoVeiculo', value)}
        value={values.tipoVeiculo || ''}
        errors={errors}
        touched={touched}
        isView={isView}
      />
      <RatingFields
        data={ratingData.vehicle}
        field="veiculo"
        dotColor="#006CEA"
        title="Veículo"
        placeholder="Selecione o veículo"
        onChange={value => onChange('veiculo', value)}
        value={values.veiculo || ''}
        errors={errors}
        touched={touched}
        isView={isView}
      />
      <RatingFields
        data={typeAttendance}
        field="obs"
        dotColor="#ccc"
        title="Observação"
        placeholder=""
        onChange={value => onChange('obs', value)}
        value={values.obs || ''}
        errors={errors}
        touched={touched}
        isView={isView}
        type="text"
      />
    </Container>
  );
};

export default Rating;
