// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.6);
  cursor: default;
}
.warning .content {
  margin-top: 30px;
}
.warning .container {
  right: 0%;
  top: -10%;
  background: #ffffff;
  color: #ea004c;
  min-height: 120px;
  height: auto;
  width: 533px;
  border-radius: 10px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.18);
  top: 25px;
}
.warning .container .close {
  z-index: 999;
  background-color: transparent;
  border: none;
  outline: none;
  color: #EA004C;
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
  cursor: pointer;
  left: 250px;
  margin-top: 27px;
  margin-right: 17px;
  opacity: 1;
}
.warning .container .close:hover {
  opacity: 0.1;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalCreatedCargo/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,8BAAA;EACA,eAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,SAAA;EACA,SAAA;EACA,mBAAA;EACA,cAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,4CAAA;EACA,SAAA;AAAJ;AACI;EACE,YAAA;EACA,6BAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;AACN;AAAM;EACE,YAAA;AAER","sourcesContent":[".warning {\n  width: 100%;\n  height: 100vh;\n  position: absolute;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 999999999;\n  background: rgba(0,0,0,0.6);\n  cursor: default;\n\n  .content {\n    margin-top: 30px;\n  }\n\n  .container {\n    right: 0%;\n    top: -10%;\n    background: #ffffff;\n    color: #ea004c;\n    min-height: 120px;\n    height: auto;\n    width: 533px;\n    border-radius: 10px;\n    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.18);\n    top: 25px;\n    .close {\n      z-index: 999;\n      background-color: transparent;\n      border: none;\n      outline: none;\n      color: #EA004C;\n      width: 32px;\n      height: 32px;\n      display: flex;\n      position: relative;\n      cursor: pointer;\n      left: 250px;\n      margin-top: 27px;\n      margin-right: 17px;\n      opacity: 1;\n      &:hover{\n        opacity: 0.1;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
