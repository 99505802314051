import React, { useState } from 'react';
import SearchFilter from 'components/SearchFilter';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

interface Props {
  options?: string;
  searchText?: any;
  onChangeText?: any;
  onChangeOptions?: any;
  onChangeOptionText?: any;
  onFilters?: any;
  subsidiaryFilters?: any;
}

const Filters: React.FC<Props> = ({
  options,
  searchText,
  onChangeText,
  onChangeOptions,
  onChangeOptionText,
  onFilters,
  subsidiaryFilters,
}: Props) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  function toggleStartDate(date: any) {
    setStartDate(date);
  }

  function toggleEndDate(date: any) {
    setEndDate(date);
  }

  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <SearchFilter
        isDescription
        isInitialDate
        isFinalDate
        isRange
        isService
        isTypeNoticeValue={options === 'tipo'}
        valueSearch={searchText}
        optionValue={options}
        toggleStartDate={toggleStartDate}
        toggleEndDate={toggleEndDate}
        valueStartDate={startDate}
        valueFinalDate={endDate}
        handleChange={(e: any) => onChangeText(e.target.value)}
        handleSelect={(e: any) => onChangeOptions(e.target.value)}
        handleSelectDriver={(e: any) => onChangeOptionText(e.target.value)}
        onExecuteFilter={() => {
          if (options === 'descricao' && searchText.length > 0) {
            onFilters([
              
              {
                field: 'descricao',
                value: searchText,
                operation: 'MATCH',
              },
            ]);
          } 
          else if (
            options === 'dataInicial' &&
            startDate !== null
          ) {
            const formatDateInitial = format(
              new Date(startDate),
              'yyyy-MM-dd',
            );
            onFilters([
              {
                field: 'dataInicial',
                value: formatDateInitial,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'dataFinal' && endDate !== null) {
            const formatDateFinal = format(
              new Date(endDate),
              'yyyy-MM-dd',
            );
            onFilters([
              {
                field: 'dataFinal',
                value: formatDateFinal,
                operation: 'EQUAL',
              },
            ]);
          } else if (
            options === 'periodo' &&
            startDate !== null &&
            endDate !== null
          ) {
            const formatDateInitial = format(
              new Date(startDate),
              'yyyy-MM-dd',
            );
            const formatDateFinal = format(
              new Date(endDate),
              'yyyy-MM-dd',
            );
            onFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'dataInicial',
                value: `${formatDateInitial}`,
                operation: 'GREATER_THAN_EQUAL',
              },
              {
                field: 'dataFinal',
                value: `${formatDateFinal}`,
                operation: 'LESS_THAN_EQUAL',
              },
            ]);
          }
          else {
            onFilters([]);
          }
        }}
      />
    </div>
  );
};

export default Filters;