"use client"
 
import * as TooltipPrimitive from "@radix-ui/react-tooltip"
import * as React from "react"
 
import { cn } from "lib/utils"
 
const TooltipProvider = TooltipPrimitive.Provider
 
const TooltipWrapper = TooltipPrimitive.Root
 
const TooltipTrigger = TooltipPrimitive.Trigger
 
const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "tw-z-1000 tw-overflow-hidden tw-rounded-md tw-border tw-bg-[#ccc] tw-px-3 tw-py-1.5 tw-text-sm tw-text-popover-['#000'] tw-shadow-md tw-animate-in tw-fade-in-0 tw-zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className
    )}
    {...props}
  />
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName
 
export { TooltipContent, TooltipProvider, TooltipTrigger, TooltipWrapper }

