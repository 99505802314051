// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselContainer {
  display: flex;
  flex-direction: column;
}

.carouselPanelLeft {
  margin-right: 20px;
}

.carouselWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.carouselPanelContent {
  display: flex;
  flex-direction: column;
}

.carouselPanelContentText {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/pages/Delivery/Carousel/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAMA;EACE,aAAA;AAHF","sourcesContent":[".carouselContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.carouselPanelLeft {\n  margin-right: 20px;\n}\n\n.carouselWrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.carouselPanelContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.carouselPanelRight {\n  \n}\n\n.carouselPanelContentText {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
