/* eslint-disable no-dupe-keys */
import { combineReducers } from 'redux';
import accumulator from './accumulator/reducer';
import auth from './auth/reducer';
import csvMapping from './csvMapping/reducer';
import driverScheduling from './driverScheduling/reducer';
import journey from './journey/reducer';
import kpis from './kpis/reducer';
import map from './map/reducer';
import reasons from './reasons/reducer';
import reports from './reports/reducer';
import routering from './routering/reducer';
import routeringSimulation from './routeringSimulation/reducer';
import socket from './socket/reducer';
import vehicles from './vehicles/reducer';


export default combineReducers({
  auth,
  accumulator,
  map,
  reasons,
  csvMapping,
  accumulator,
  routeringSimulation,
  vehicles,
  kpis,
  reports,
  driverScheduling,
  journey,
  routering,
  socket
});
