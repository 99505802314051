import { styled } from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import PaginationNextIcon from 'assets/images/paginationNextIcon.svg';
import PaginationPreviousIcon from 'assets/images/paginationPreviousIcon.svg';
import React from 'react';
import './styles.scss';


interface CustomPaginationProps {
  totalPages: number;
  defaultPage: number;
  onPrevPage: (page: number | null) => void;
  onNextPage: (page: number | null) => void;
  onChangePage: (page: number | null) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ totalPages, onPrevPage, onNextPage, onChangePage, defaultPage }) => {  
  const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
  });

  const { items } = usePagination({
    count: totalPages,
    page: defaultPage + 1,
    onChange: (event: any, page: number) => onChangePage(page)
  });
  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <button type='button' className='customEllipsisPage'>
                ...
              </button>
            )
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                className='customPage'
                style={{
                  background: selected ? 'rgba(156, 156, 156, 0.15)' : '#fff'
                }}
                onClick={() => onChangePage(page)}
              >
                {page}
              </button>
            );
          } else if(type === "next") {
            children = (
              <button type="button" {...item} className='customNextButton' onClick={() => onNextPage(page)}>
                <img src={PaginationNextIcon} />
              </button>
            );
          }
          else if(type === "previous") {
            children = (
              <button type="button" {...item} className='customPreviousButton' onClick={() => onPrevPage(page)}>
                <img src={PaginationPreviousIcon} />
              </button>
            );
          }

          return <li  key={index}>{children}</li>;
        })}
      </List>
    </nav>
  )
}

export default CustomPagination;