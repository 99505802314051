export function addSimulatorRouteOptmi(routeOptmisId) {
  return {
    type: '@simulator/ADD_SIMULATOR_ROUTE_OPTMI',
    payload: {
      routeOptmisId,
    },
  };
}

export function editSimulatorRoute(editSimulator) {
  return {
    type: '@simulator/EDIT_SIMULATOR_ROUTE',
    payload: {
      editSimulator,
    },
  };
}

export function geraRotasSimulation(id_route_simulation) {
  return {
    type: '@simulator/SET_ID_SIMULATION',
    payload: {
      id_route_simulation,
    },
  };
}

export function saveStepsId(id_step) {
  return {
    type: '@simulator/SAVE_ID_STEP',
    payload: {
      id_step,
    },
  };
}

export function clearAction(id_step) {
  return {
    type: '@simulator/CLEAR',
    payload: {
      id_step,
    },
  };
}
export function setIdKnowledge(id_knowledge) {
  return {
    type: '@simulator/SET_ID_KNOWLEDGE',
    payload: {
      id_knowledge,
    },
  };
}

export function setChangeRoute() {
  return {
    type: '@simulator/SET_CHANGE_ROUTE',
  };
}

export function clearChangeRoute() {
  return {
    type: '@simulator/CLEAR_CHANGE_ROUTE',
  };
}

export function setAddressMap(address, addressData) {
  return {
    type: '@simulator/SET_ADDRESS_MAP',
    payload: {
      address,
      addressData,
    },
  };
}

export function clearAddress() {
  return {
    type: '@simulator/CLEAR_ADDRESS',
  };
}

export function saveRouteOptmi(routeOptmiId) {
  return {
    type: '@simulator/SAVE_ROUTE_OPTMI',
    payload: {
      routeOptmiId,
    },
  };
}

export function saveKnowledgeOrder(routeStep) {
  return {
    type: '@simulator/SAVE_ROUTE_STEP',
    payload: {
      routeStep,
    },
  };
}
