import React from 'react';

// import { Container } from './styles';

interface Props {
  errors: any[];
}

const Results: React.FC<Props> = ({ errors }: Props) => {
  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
      {errors && errors.length > 0 ? (
        <>
          {errors.map((result: any) => (
            <div
              className="tw-flex tw-w-full tw-h-auto tw-rounded-lg tw-p-4 tw-mb-2"
              style={{
                background: '#EA004C',
              }}
            >
              <span className="tw-text-base tw-font-normal tw-text-center tw-text-[#fff]">
                {result}
              </span>
            </div>
          ))}
        </>
      ) : (
        <div
          className="tw-flex tw-w-full tw-h-auto tw-rounded-lg tw-p-4 tw-mb-2"
          style={{
            background: '#08B639',
          }}
        >
          <span className="tw-text-base tw-font-normal tw-text-center tw-text-[#fff]">
            Upload realizado com sucesso!
          </span>
        </div>
      )}
    </div>
  );
};

export default Results;
