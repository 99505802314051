/* eslint-disable prefer-const */
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import closeModalIcon from '../../../../../../../assets/images/closeIcon.svg';
import Loading from '../../../../../../../core/common/Loading';
import './styles.scss';


export default function ConfirmationDriver({ onClear, onConfirmAddress }) {
  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  return (
    <>
      <Loading loading={loading} />
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%' }}
      >
        <div className="modalWrapperVehicles">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="searchAddress.title" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="searchAddress.messageConfirmSingle" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  setModal(!true);
                }}
              >
                <Trans i18nKey="searchAddress.actions.no" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => {
                  setModal(!true);
                  onClear();
                  onConfirmAddress('SINGLE');
                }}
              >
                <Trans i18nKey="searchAddress.actions.yes" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
