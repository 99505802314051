import { Pagination } from '@mui/material';
import Button from 'components/Button';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import api from 'services/api';
import {
  Container,
  EmptyTable,
  Footer,
  OccurrenceList,
  WrapperFilters,
  WrapperPagination,
} from './styles';

// import { Container } from './styles';

interface OcorrencesSelected {
  numero: string;
  index: null | number;
}

interface ModalOccurrenceProps {
  onClear: any;
  onConfirm: any;
  typeOcorrence: string;
}

const ModalOccurrence: React.FC<ModalOccurrenceProps> = ({  onClear, onConfirm, typeOcorrence }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [valueSearch, setValueSearch] = useState('');
  const [ocorrences, setOcorrences] = useState([]);
  const [selectedOcorrences, setSelectedOcorrences] = useState<
    OcorrencesSelected
  >({
    numero: '',
    index: null,
  });
  const [loading, setLoading] = useState(false);

  const getOcorrences = useCallback(
    async (textFilters?: string) => {
      setLoading(true);
      const filters = [
        {
          field: 'exibicaoMotorista',
          value: false,
          operation: 'EQUAL',
        },
        {
          field:  'baixaManual',
          value: true,
          operation: 'EQUAL'
        },
        {
          field: 'descricao',
          value: textFilters,
          operation: 'MATCH',
        },
        {
          field: 'tipo',
          value: typeOcorrence,
          operation: 'EQUAL'
        }
      ];

      const data = {
        filters:
          textFilters && textFilters.length > 0
            ? filters
            : [
                {
                  field: 'exibicaoMotorista',
                  value: false,
                  operation: 'EQUAL',
                },
                {
                  field:  'baixaManual',
                  value: true,
                  operation: 'EQUAL'
                },
                {
                  field:  'tipo',
                  value: typeOcorrence,
                  operation: 'EQUAL'
                },
              ],
        orders: [],
        size: 5,
        page: currentPage,
      };

      const response = await api.post('/delivery/ocorrencias/pageable', data);

      setOcorrences(response.data.content);
      setTotalPages(response.data.totalPages);

      setLoading(false);
    },
    [currentPage]
  );

  const paginate = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getOcorrences();
  }, [getOcorrences]);

  return (
    <CustomModal isOpen label="Ocorrências" styles={{ maxWidth: 530 }} isClose={onClear}>
      <Container>
        <WrapperFilters>
          <InputCustom
            type="text"
            onChange={(e: any) => setValueSearch(e.target.value)}
            placeholder="Buscar Ocorrência"
          />
          <Button
            label="Filtrar"
            onClick={() => getOcorrences(valueSearch)}
            styles={{ height: 40, width: '30%', marginLeft: '1rem' }}
          />
        </WrapperFilters>

        <OccurrenceList>
          <table>
            <thead>
              <tr>
                <th>Numero</th>
                <th>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {ocorrences.length > 0 ? (
                <>
                  {ocorrences.map((ocorrence: any, indexOcorrence: number) => (
                    <tr
                      id={
                        indexOcorrence === selectedOcorrences.index
                          ? 'rowActive'
                          : 'rowInactive'
                      }
                      onClick={() =>
                        setSelectedOcorrences({
                          numero: ocorrence.numero,
                          index: indexOcorrence,
                        })
                      }
                    >
                      <td
                        id={
                          ocorrence.sucesso
                            ? 'ocorrenceSuccess'
                            : ocorrence.informativa
                            ? 'ocorrenceInformation'
                            : ''
                        }
                      >
                        {ocorrence.numero}
                      </td>
                      <td
                        id={
                          ocorrence.sucesso
                            ? 'ocorrenceSuccess'
                            : ocorrence.informativa
                            ? 'ocorrenceInformation'
                            : ''
                        }
                      >
                        {ocorrence.descricao}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <Loading loading={loading} />
                  {loading === false && (
                    <EmptyTable>
                      Não há registros a serem exibidos...
                    </EmptyTable>
                  )}
                </>
              )}
            </tbody>
          </table>

          <WrapperPagination>
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e: any, page: number) => {
                paginate(page - 1);
              }}
            />
          </WrapperPagination>
        </OccurrenceList>
        <Footer>
          <Button
            label="Confirmar"
            onClick={() => {
              onConfirm(selectedOcorrences)
            }}
            styles={{ backgroundColor: '#10841C', height: 40, width: '30%' }}
          />
          <Button
            label="Cancelar"
            onClick={onClear}
            styles={{ backgroundColor: '#EA004C', height: 40, width: '30%' }}
          />
        </Footer>
      </Container>
    </CustomModal>
  );
};

export default ModalOccurrence;
