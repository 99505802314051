// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unCheckedAttendances {
  border: 1px solid #EA004C;
  box-sizing: border-box;
  border-radius: 4px;
  width: 17px;
  height: 17px;
  padding: 10px;
}

.checkedAttendances {
  background: #EA004C;
  border-radius: 4px;
  border: none;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.titleCheckbox {
  margin-left: 5px;
  margin-right: 10px;
  font-family: "Lato";
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/Sheduling/RemoveData/CheckBox/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACF,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACA;;AACA;EACE,mBAAA;EACF,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAEA;;AAAA;EACE,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AAGF","sourcesContent":[".unCheckedAttendances {\n  border: 1px solid #EA004C;\nbox-sizing: border-box;\nborder-radius: 4px;\nwidth: 17px;\nheight: 17px;\npadding: 10px;\n}\n.checkedAttendances {\n  background: #EA004C;\nborder-radius: 4px;\nborder: none;\nwidth: 18px;\nheight: 18px;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\npadding: 10px;\n}\n.titleCheckbox {\n  margin-left: 5px;\n  margin-right: 10px;\n  font-family: 'Lato';\n  font-size: 12px,\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
