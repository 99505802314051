import React from 'react';
import { Input } from 'reactstrap/lib';
import { Error, Title } from './styles';

interface InputProps {
  title?: string;
  name?: string;
  id?: string;
  edit?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  value?: any,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  touched?: any;
  error?: any;
  children: JSX.Element | React.ReactChild | React.ReactChild[];
  style?: any;
}

interface StylesProps {
  edit?: boolean;
  isHidden?: boolean
}

const SelectCustom: React.FC<InputProps> = (
  { title, id, disabled, edit, touched, error, onChange, isHidden, value, style, children }: InputProps,
  ...rest
) => {
  const styleSelect = {
    height: '2.5rem',
    backgroundColor: `${({ edit }: StylesProps) =>
      edit || disabled === true ? '#ccc' : '#FFFFFF'}`,
    opacity: `${({ edit }: StylesProps) => (edit ? 0.8 : 1)}`,
    border: `0.125rem solid rgba(0, 48, 73, 0.2)`,
    borderRadius: '0.625rem',
    fontfamily: 'Lato',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: 'rgb(0, 48, 73)',
    marginTop: '0.5rem',
    // marginBottom: responsiveSize(10),
    paddingLeft: '0.625rem',
  };
  return (
    <>

      <Title>{title}</Title>

      <Input {...rest} type="select" onChange={onChange} value={value} style={{...styleSelect, style}} id={id} disabled={disabled}>
        {children}
      </Input>

      {touched && error && <Error>{error}</Error>}
    </>
  );
};

export default SelectCustom;