import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap/lib';
import api from 'services/api';
import Create from './Create';
import { IconColumn } from './styles';

interface WebHookProps {
  onClear: () => void;
}

interface DataUpdate {
  id?: number | null;
}

interface WebhookData {
  id: number;
  description: string;
  event: string;
  endpoint: string;
}

export default function WebHook({ onClear }: WebHookProps) {
  const [tabs, setTabs] = React.useState<number>(0);
  // Pagination
  const [totalPages, setTotalPages] = useState(20);
  const [totalPagesAudit, setTotalPagesAudit] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [webhooksData, setWebhooksData] = useState<WebhookData[]>([]);
  const [dataUpdate, setDataUpdate] = useState({
    id: 0,
  });
  const [loading, setLoading] = useState(false);

  // Audit States
  const [filters, setFilters] = useState<any[]>([]);
  const [dataAuditWebhook, setDataAuditWebhook] = useState([]);
  const [currentPageAudit, setCurrentPageAudit] = useState(0);

  // Remove Action States
  const [removeId, setRemoveId] = useState<number>(0);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);

  const { t } = useTranslation();

  const getWebhook = useCallback(async () => {
    try {
      const filtersData = {
        filters: [],
        orders: [],
        page: currentPage,
        size: 10,
      };
      const response = await api.post(
        '/webhook/pageable',
        filtersData
      );

      setWebhooksData(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (err) {}
  }, [currentPage]);

  const getAuditWebhook = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        filters: filters.length > 0 ? filters : [],
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPageAudit || 0,
        size: 5,
      };
      const response = await api.post('/audit/webhook/pageable', data);

      setDataAuditWebhook(response.data.content);
      setTotalPagesAudit(response.data.totalPages);

      setLoading(false);
    } catch (err) {}
  }, [currentPageAudit, filters]);

  function paginateAudit(page: number) {
    setCurrentPageAudit(page - 1);
  }

  const deleteWebhook = useCallback(async driverId => {
    try {
      const response = await api.delete(`/webhook/${driverId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('phases.messageRemoved'),
          NotificationType.SUCCESS
        );
        setRemoveConfirmation(false);

        getWebhook();
      } else {
        FrontendNotification(
          t('phases.messageErroRemoved'),
          NotificationType.ERROR
        );
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    getWebhook();
    getAuditWebhook();
  }, [getWebhook, getAuditWebhook]);
  return (
    <>
      <Loading loading={loading} />

      <Modal
        isOpen={removeConfirmation}
        
        toggle={() => setRemoveConfirmation(!removeConfirmation)}
      >
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteWebhook(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setRemoveConfirmation(!removeConfirmation)}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>

      <CustomModal
        label="Webhooks"
        isOpen
        isClose={() => onClear()}
        styles={{ maxWidth: '98%' }}
      >
        <Tabs
          selectedIndex={tabs}
          onSelect={(index: number) => {
            setTabs(index);
          }}
        >
          <TabList>
            <Tab>
              <Trans i18nKey="tabs.list" />
            </Tab>
            <Tab>
              <Trans i18nKey="tabs.new" />
            </Tab>
            <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            <Tab>Logs</Tab>
          </TabList>
          <TabPanel>
            <div className="table-responsive" style={{ marginTop: '2rem' }}>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Descricão</th>
                    <th>Evento</th>
                    <th>Endpoint</th>
                  </tr>
                </thead>
                <tbody>
                  {webhooksData.map((webhook: WebhookData) => (
                    <tr className="lineGroup">
                      <td>{webhook.description}</td>
                      <td>{webhook.event}</td>
                      <td>{webhook.endpoint}</td>
                      <IconColumn>
                        <Button
                          color="link"
                          className="noMargin"
                          onClick={() => {
                            setDataUpdate({
                              id: webhook.id,
                            });
                            setTabs(2);
                          }}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                          />
                        </Button>
                        <Button
                          color="link"
                          className="noMargin"
                          onClick={() => {
                            setRemoveId(webhook.id);
                            setRemoveConfirmation(!removeConfirmation);
                          }}
                        >
                          <img
                            src={DeleteIcon}
                            alt=""
                          />
                        </Button>
                      </IconColumn>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '1.7rem',
                }}
              >
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e: any, page: number) => {
                    setCurrentPage(page - 1);
                  }}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Create
              onClear={() => {
                setTabs(0);
                getWebhook();
              }}
              isEdit={false}
              dataWebhook={dataUpdate}
            />
          </TabPanel>
          <TabPanel>
            <Create
              onClear={() => {
                setTabs(0);
                getWebhook();
              }}
              isEdit={true}
              dataWebhook={dataUpdate}
            />
          </TabPanel>
          <TabPanel>
            <Logs
              data={dataAuditWebhook}
              isWebhook
              totalPages={totalPagesAudit}
              page={currentPageAudit}
              onPaginate={(page: number) => paginateAudit(page)}
              onFilters={(date: string, status: string) => {
                setCurrentPageAudit(0);
                if (date.length > 0 && status.length > 0) {
                  setFilters([
                    {
                      field: 'dataCriacao',
                      value: `${date}T00:00:00`,
                      operation: 'GREATER_THAN_EQUAL',
                    },
                    {
                      field: 'dataCriacao',
                      value: `${date}T23:59:59`,
                      operation: 'LESS_THAN_EQUAL',
                    },
                    {
                      field: 'statusCode',
                      value: status,
                      operation: 'EQUAL',
                    },
                  ]);
                } else if (date.length > 0) {
                  setFilters([
                    {
                      field: 'dataCriacao',
                      value: `${date}T00:00:00`,
                      operation: 'GREATER_THAN_EQUAL',
                    },
                    {
                      field: 'dataCriacao',
                      value: `${date}T23:59:59`,
                      operation: 'LESS_THAN_EQUAL',
                    },
                  ]);
                } else if (status.length > 0) {
                  setFilters([
                    {
                      field: 'statusCode',
                      value: status,
                      operation: 'EQUAL',
                    },
                  ]);
                } else {
                  setFilters([]);
                }
              }}
            />
          </TabPanel>
        </Tabs>
      </CustomModal>
    </>
  );
}
