import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import leftArrow from '../../../../assets/images/left-arrow.png';
import rightArrow from '../../../../assets/images/right-arrow.png';
import './styles.scss';

export default function Carousel({ data }) {
  const [stepsActual, setStepsActual] = React.useState(0);
  const [dataLoads, setDataLoads] = useState({});
  const [active, setActive] = useState(false);

  const getStepsContent = useCallback(
    (stepActual, dataArr) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === dataArr.length - 1) {
        setStepsActual(dataArr.length - 1);
      } else {
        setStepsActual(prevState => {
          return prevState + 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  const getStepsContentLeft = useCallback(
    (stepActual, dataArr) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === 0) {
        setStepsActual(0);
      } else {
        setStepsActual(prevState => {
          return prevState - 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  useEffect(() => {
    if (stepsActual > data.length - 1) {
      setStepsActual(0);
    }
  }, [stepsActual, data]);

  return (
    <div className="carouselContainer">
      <div className="carouselWrapper">
        <div
          className="carouselPanelLeft"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContentLeft(stepsActual, data)}
        >
          <img alt="" src={leftArrow} width="15" height="15" />
        </div>
        <div className="carouselPanelContent">
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.serviceNumber" />
              :{'  '}
            </b>{' '}
            {/* {stepsActual !== null
              ? dataLoads.conhecimentoPre.numero
              : data && data.length > 0
              ? data[0].conhecimentoPre.numero
              : ''} */}
            {data && data.length > 0 ? data[stepsActual].numero : ''} <br />
          </div>
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.client" />:{' '}
            </b>
            {data && data.length > 0 ? data[stepsActual].cliente.nome : ''}{' '}
            <br />
          </div>
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.expectedArrival" />
              :{' '}
            </b>{' '}
            {data && data.length > 0
              ? data[stepsActual].eta !== null
                ? format(
                    new Date(data[stepsActual].eta),
                    'dd/MM/yyyy HH:mm',
                    pt
                  )
                : ''
              : ''}{' '}
            <br />
          </div>
          {/* <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.fields.eta" />:
            </b>{' '}
            {data && data.length > 0
              ? `${format(
                  new Date(data[stepsActual].eta),
                  'dd-MM-yyyy HH:mm',
                  pt
                )}`
              : ''}{' '}
            <br />
          </div> */}
          <div
            className="carouselPanelContentText"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <b>
              <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryAddress" />
              :
            </b>{' '}
            {data && data.length > 0
              ? `${data[stepsActual].endereco.logradouro}, ${data[stepsActual].endereco.numero}, ${data[stepsActual].endereco.complemento}`
              : ''}{' '}
            <br />
          </div>

          <div
            className="carouselPanelContentText"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <b style={{ marginRight: 3 }}>
                  <Trans i18nKey="routeOptmization.routeSteps.fields.neighborhood" />
                  :
                </b>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '80px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data && data.length > 0
                    ? data[stepsActual].endereco.bairro
                    : ''}{' '}
                </span>
              </div>
              <br />
              <b style={{ marginRight: 3 }}>
                <Trans i18nKey="routeOptmization.routeSteps.fields.city" />:
              </b>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '80px',
                  textOverflow: 'ellipsis',
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual].endereco.cidade
                  : ''}{' '}
              </span>
              <br />
            </div>
            <div style={{ display: 'flex', marginTop: 5 }}>
              <b style={{ marginRight: 3 }}>UF:</b>
              <span
                style={{
                  marginRight: 5,
                }}
              >
                {data && data.length > 0 ? data[stepsActual].endereco.uf : ''}{' '}
              </span>
              <br />
            </div>
          </div>
        </div>
        <div
          className="carouselPanelRight"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContent(stepsActual, data)}
        >
          <img alt="" src={rightArrow} width="15" height="15" />
        </div>
      </div>
      {/* <Dots /> */}
    </div>
  );
}
