import styled from 'styled-components';

export const Input = styled.input`
  width: ${props => props.width || '350px'};
  height: ${props => props.height || '40px'};
  border-radius: 10px;
  background: #ffffff;
  border: 2px solid rgba(0, 48, 73, 0.2);
  padding: 13px;
  box-sizing: border-box;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #003049;
`;
