import { object, ref, string } from 'yup';

export default object().shape({
  cep: string().required('* Enter the Postal Code of the subsidiary!'),
  logradouro: string().required('* Enter the street of the subsidiary!'),
  numero: string().required('* Enter the number of the subsidiary!'),
  bairro: string().required('* Enter the neighborhood of the subsidiary!'),
  cidade: string().required('* Enter the city of the subsidiary!'),
  estado: string().required('* Enter the state of the subsidiary!'),
});
