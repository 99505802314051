import GeneralIndicators from 'components/GeneralIndicators';
import SideBar from 'components/Sidebar';
import SuperiorInterface from 'components/SuperiorInterface';
import { BackendErrorNotification } from 'core/common/Notification';
import React from 'react';
import api from '../../services/api';
import TopBar from './TopBar';
import { PreviewForm, FieldsForm } from '../../shared/BuilderForm';
import {
  Container,
  OrderTitle,
  Line,
  Content,
  ContentTitle,
  Details,
  Row,
  Col,
} from './styles';
import './styles.scss';
import truckOrder from '../../assets/images/truckOrder.png';
import airplaneOrder from '../../assets/images/airplaneOrder.png';
import shipOrder from '../../assets/images/shipOrder.png';

export default function ManagementForms() {
  const [subsidiary, setSubsidiary] = React.useState([]);
  async function getSubsidiary() {
    const response = await api.get('/cadastro/filiais');
    setSubsidiary(response.data);
  }

  React.useEffect(() => {
    getSubsidiary();
  }, [getSubsidiary]);
  return (
    <>
      {/* <GeneralIndicators generalIndicators={generalIndicators} /> */}
      <TopBar listSubsidiary={subsidiary} />

      <div className="containerFluid">
        <PreviewForm />
        <div className="sideBarForm">
          <FieldsForm />
        </div>
      </div>
    </>
  );
}
