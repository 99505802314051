import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  from: string().required('* Required field!'),
  enctype: string().required('* Required field!'),
  charset: string().required('* Required field!'),
  uri: string().required('* Required field!'),
  account: string().required('* Required field!'),
  code: string().required('* Required field!'),
});
