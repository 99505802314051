import classNames from 'classnames';
import Loading from 'core/common/LoadingRoutering';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import {
  DataRouteringCargas,
  RouteringCargasPayload,
  clearDataRouteSelected,
  removeDataRouteSelected,
  setDataRouteSelected,
  setDataRouteringCargas,
  updateRouteringCargaChecked,
  updateRouteringCargaSelected,
} from 'store/modules/routering/actions';
import { colors } from 'util/colors';
import Card from './components/Card';
import CheckBox from './components/CheckBox';

// import { Container } from './styles';

interface Props {
  onSelectCarga: (menuSelected: string) => void;
  onReloadRoute: (dataRoute: DataRouteringCargas) => void;
  selectedMenuCarga: string;
}

const CargasRouting: React.FC<Props> = ({
  onSelectCarga,
  onReloadRoute,
  selectedMenuCarga,
}: Props) => {
  const dataCarga: RouteringCargasPayload = useSelector(
    (state: any) => state.routering
  );
  const [cargas, setCargas] = useState<DataRouteringCargas[]>([]);
  const [loading, setLoading] = useState(false);
  const [checkedAllRoutes, setCheckedAllRoutes] = useState(false);

  const dispatch = useDispatch();

  const dataCargaSelected: DataRouteringCargas[] = useSelector(
    (state: any) => state.routering.data.dataRouteSelected
  );

  function getColors(cargaId: number) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }

    const findColors = colorsData.find((color: any) => color.id === cargaId);

    return findColors;
  }

  function loadColors(
    cargaItem: DataRouteringCargas,
    data: any,
    index: number
  ) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }
    const dataColors = {
      id: cargaItem.id,
      color: colors[index],
    };

    let colorsList = colorsData;

    colorsList.push(dataColors);

    if (data.length > colorsData) {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    } else {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    }

    return dataColors;
  }

  const getCargas = useCallback(async () => {
    try {
      const body = {
        filters: [
          {
            field: 'simulation.id',
            value: sessionStorage.getItem('idSimulacao'),
            operation: 'EQUAL',
          },
        ],
        orders: [],
        page: 0,
        size: 1000,
      };
      const response = await api.post('/route/optmis/pageable', body);

      const orderById = response.data.content.sort(
        (a: any, b: any) => a.id - b.id
      );

      const mappingCargaRoutering = orderById.map(
        (cargaItem: DataRouteringCargas, indexCargas: any) => {
          return {
            ...cargaItem,
            fillColor:
              getColors(cargaItem.id) !== undefined
                ? getColors(cargaItem.id).color
                : loadColors(cargaItem, orderById, indexCargas).color,
            active: false,
            steps: cargaItem.steps.map(stepItem => {
              return {
                ...stepItem,
                active: false,
              };
            }),
          };
        }
      );

      const mappingCarga = mappingCargaRoutering.map(
        (cargaItem: DataRouteringCargas, indexCarga: number) => {
          return {
            dataAgendamento: cargaItem.dataAgendamento,
            id: cargaItem.id,
          };
        }
      );

      if (response.data.content.length > 0) {
        sessionStorage.setItem('cargas', JSON.stringify(mappingCarga));
        dispatch(setDataRouteringCargas(mappingCargaRoutering));
      } else {
        history.push('/uploadCSV');
        window.location.reload();
      }
    } catch {}
  }, []);

  function getRouteTimer(serviceTime: any, travelTime: any) {
    let sumHours: any = 0;
    let sumMinutes: any = parseInt(travelTime.minute + serviceTime.minute);
    let sumSeconds: any = parseInt(travelTime.seconds + serviceTime.seconds);

    // Soma o total de horas da rota.
    sumHours = sumMinutes / 60;
    // Soma o total de minutos da rota.
    sumMinutes = sumMinutes % 60;

    let restoSeconds = sumSeconds;

    // Soma o total de segundos da rota.
    if (restoSeconds > 60) {
      restoSeconds = sumSeconds % 60;
    }

    return `${parseInt(sumHours)}:${
      sumMinutes < 10 ? `0${parseInt(sumMinutes)}` : parseInt(sumMinutes)
    }:${parseInt(restoSeconds)}`;
  }

  useEffect(() => {
    let cargaData = dataCarga.data.dataOptmi.map(item => {
      return {
        ...item,
        routeTimer: `${getRouteTimer(item.serviceTime, item.travelTime)}`,
      };
    });

    setCargas(cargaData);

    const getColorByCarga = dataCarga.data.dataOptmi.map(
      (cargaItem: DataRouteringCargas) => {
        return {
          id: cargaItem.id,
          color: cargaItem.fillColor,
        };
      }
    );

    sessionStorage.setItem('@carga/colors', JSON.stringify(getColorByCarga));
  }, [dataCarga.data.dataOptmi]);
  return (
    <div>
      <div
        className={classNames('cargasFilter cargasContainer', {
          'is-open': selectedMenuCarga === 'cargas',
        })}
      >
        <Loading loading={loading} />
        <div className="tw-flex tw-items-center tw-flex-nowrap">
          <CheckBox
            active={checkedAllRoutes}
            onChecked={() => {
              setLoading(true);
              dispatch(clearDataRouteSelected());
              cargas.map(
                (
                  cargaSelected: DataRouteringCargas,
                  indexCargaSelected: number
                ) => {
                  let checked = false;
                  if (checkedAllRoutes === true) {
                    dispatch(clearDataRouteSelected());
                  } else {
                    dispatch(setDataRouteSelected(cargaSelected));
                  }

                  dispatch(
                    updateRouteringCargaSelected(
                      indexCargaSelected,
                      !checkedAllRoutes
                    )
                  );
                }
              );

              setCheckedAllRoutes(!checkedAllRoutes);
              setLoading(false);
            }}
          />
          <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-whitespace-nowrap">
            Selecionar todos
          </span>
        </div>
        {cargas.map((carga: DataRouteringCargas, index: number) => (
          <Card
            data={carga}
            indexCarga={index}
            menuSelected={selectedMenuCarga}
            onCheckedCarga={(index: number, dataRoute: DataRouteringCargas) => {
              const getDataCargoSelectedIndex = dataCargaSelected
                .map(
                  (
                    cargaSelected: DataRouteringCargas,
                    indexCargaSelected: number
                  ) => (cargaSelected.id === carga.id ? indexCargaSelected : -1)
                )
                .filter(
                  (filterCargaSelected: any) => filterCargaSelected !== -1
                );
              const findIsActiveCarga = cargas.find(
                (item: DataRouteringCargas) => item.active === true
              );
              if (carga.active === true) {
                console.log('Item', getDataCargoSelectedIndex[0]);
                dispatch(removeDataRouteSelected(getDataCargoSelectedIndex[0]));
              } else {
                if (!findIsActiveCarga) {
                  dispatch(clearDataRouteSelected());
                }
                dispatch(setDataRouteSelected(dataRoute));
              }

              dispatch(updateRouteringCargaChecked(index));
            }}
            onSelectMenu={(menuSelected: string) => onSelectCarga(menuSelected)}
            onUpdateData={() => getCargas()}
            onUpdateRoute={(dataRoute: DataRouteringCargas) =>
              onReloadRoute(dataRoute)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default CargasRouting;
