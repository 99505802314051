/* eslint-disable no-unused-vars */
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import i18next from 'i18next';
import Leaflet from 'leaflet';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Col, Modal, Row } from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../../assets/images/closeIcon.svg';
import MapIcon from '../../../assets/images/mapPin.svg';

export default function ConfirmationLocation({
  onClear,
  data,
  dataValues,
  onRegister,
  onPrimary,
  onEdit,
}) {
  const [initialPosition, setInitialPosition] = React.useState([]);
  const [zoom, setZoom] = useState(40);
  const [position, setPosition] = useState([]);

  let leafletMap = useRef();

  const { t } = useTranslation();

  const primeiroAcesso = useSelector(state => state.auth.primaryAccess);

  const handleSubmit = useCallback(async () => {
    try {
      const dataSubmit = {
        nome: dataValues.nome,
        cnpj: dataValues.cnpj,
        endereco: {
          logradouro: dataValues.logradouro,
          numero: dataValues.numero,
          bairro: dataValues.bairro,
          cidade: dataValues.cidade,
          cep: dataValues.cep,
          uf: dataValues.estado,
          pais: dataValues.pais,
          latitude: data[0],
          longitude: data[1],
        },
        idReferencia: dataValues.idReferencia,
      };
      if (onEdit) {
        const { language } = i18next;

        const emailSendLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
        const response = await api.patch(
          `/cadastro/filiais/${onEdit.editId}?lang=${emailSendLanguage}`,
          dataSubmit
        );
        if (response.status) {
          FrontendNotification(
            t('filiais.messageUpdated'),
            NotificationType.SUCCESS
          );
          onClear();
          onRegister();
        } else {
          FrontendNotification(
            t('filiais.messageErroUpdated'),
            NotificationType.ERROR
          );
          onClear();
        }
      } else {
        const { language } = i18next;

        const emailSendLanguage =
          language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

        const response = await api.post(
          `/cadastro/filiais?lang=${emailSendLanguage}`,
          dataSubmit
        );
        if (response.status === 201) {
          FrontendNotification(
            t('filiais.messageCreated'),
            NotificationType.SUCCESS
          );
          if (primeiroAcesso) {
            onPrimary();
          }
          onClear();
          onRegister();
        } else {
          FrontendNotification(
            response.response.data.message,
            NotificationType.ERROR
          );
          onClear();
        }
      }
    } catch (err) {
      // BackendErrorNotification(err);
    }
  }, []);

  function clearNav() {
    onClear();
  }

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  return (
    <Modal isOpen dialogClassName="my-modal" style={{ maxWidth: '55%' }}>
      <div
        className=""
        style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
      >
        <p
          className="title"
          style={{ fontSize: 30, fontWeight: 'bold', color: '#003049' }}
        >
          Confirmar localização
          {/* <Trans i18nKey="phases.title" /> */}
        </p>
        <div
          className="iconClose"
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            padding: 20,
          }}
        >
          <button
            style={{ background: 'transparent', border: 'none' }}
            type="button"
            onClick={() => clearNav()}
          >
            <img src={closeModalIcon} alt="" />
          </button>
        </div>
        <div>
          <Map
            ref={m => {
              leafletMap = m;
            }}
            style={{
              maxHeight: 340,
              width: '100%',
              marginTop: 20,
              cursor: 'pointer',
            }}
            center={data}
            zoom={zoom}
            zoomControl
            // onclick={e => getPosition(e)}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {data.length && <Marker position={data} icon={pinIcon} />}
          </Map>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <button
                type="button"
                id="buttonRevisionLocation"
                onClick={() => clearNav()}
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#8C8C8C',
                  height: 40,
                  borderRadius: 6,
                  fontFamily: 'Lato',
                  fontSize: 16,
                  border: 'none',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',

                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                Revisar localização
              </button>
            </Col>
            <Col>
              <button
                type="button"
                id="buttonConfirmLocation"
                onClick={() => handleSubmit()}
                style={{
                  display: 'flex',
                  width: '100%',
                  background: '#EA004C',
                  height: 40,
                  border: 'none',

                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 6,
                  fontFamily: 'Lato',
                  fontSize: 16,

                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                Confirmar localização
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
}
