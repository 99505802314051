// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lineGroup {
  padding: 21px;
  border-radius: 5px;
}

.selectDriver {
  padding: 21px;
  border-radius: 5px;
  width: 120%;
}

.selectDriver:hover {
  width: 120%;
  padding: 41px;
  background-color: #EA004C;
  border-radius: 15px;
  cursor: pointer;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectParameters/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EAEE,yBAAA;EACA,mBAAA;EACA,eAAA;EAEA,WAAA;AADJ","sourcesContent":[".lineGroup {\n  padding: 21px;\n  border-radius: 5px;\n\n}\n.selectDriver {\n  padding: 21px;\n  border-radius: 5px;\n  width: 120%;\n\n}\n.selectDriver:hover {\n  width: 120%;\n  padding: 41px;\n\n    background-color: #EA004C;\n    border-radius: 15px;\n    cursor: pointer;\n\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
