import React, { useState } from 'react';
import SearchFilter from 'components/SearchFilter';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

interface Props {
  options?: string;
  searchText?: any;
  onChangeText?: any;
  onChangeOptions?: any;
  onChangeOptionText?: any;
  onFilters?: any;
  subsidiaryFilters?: any;
  subsidiaryId?: any;
}

const Filters: React.FC<Props> = ({
  options,
  searchText,
  onChangeText,
  onChangeOptions,
  onChangeOptionText,
  onFilters,
  subsidiaryFilters,
  subsidiaryId
}: Props) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  function toggleStartDate(date: any) {
    setStartDate(date);
  }

  function toggleEndDate(date: any) {
    setEndDate(date);
  }

  return (
    <div>
      <SearchFilter
        isLicensePlate
        isTypeVehicle
        isDriver
        isMaximumService
        isMaximumWeight
        isMaximumVolume
        isMaximumPrice
        isTypeVehicleDriver={options === 'tipo'}
        isService
        // isTypeNoticeValue={options === 'tipo'}
        valueSearch={searchText}
        optionValue={options}
        toggleStartDate={toggleStartDate}
        toggleEndDate={toggleEndDate}
        valueStartDate={startDate}
        valueFinalDate={endDate}
        handleChange={(e: any) => onChangeText(e.target.value)}
        handleSelect={(e: any) => onChangeOptions(e.target.value)}
        handleSelectDriver={(e: any) => onChangeOptionText(e.target.value)}
        onExecuteFilter={() => {
          if (options === 'placa' && searchText.length > 0) {
            onFilters([
              {
                field: 'placa',
                value: searchText,
                operation: 'MATCH',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'tipo' && searchText.length > 0) {
            onFilters([
              {
                field: 'tipo',
                value: searchText,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'motorista.nome' && searchText.length > 0) {
            onFilters([
              {
                field: 'motorista.nome',
                value: searchText,
                operation: 'MATCH',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'quantidadeAtendimento' && searchText.length > 0) {
            onFilters([
              {
                field: 'quantidadeAtendimento',
                value: searchText,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'peso' && searchText.length > 0) {
            onFilters([
              {
                field: 'peso',
                value: searchText,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'cubagem' && searchText.length > 0) {
            onFilters([
              {
                field: 'cubagem',
                value: searchText,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else if (options === 'valor' && searchText.length > 0) {
            onFilters([
              {
                field: 'valor',
                value: searchText,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
            ]);
          } else {
            onFilters([
              {
                field: 'filial.id',
                value: subsidiaryId,
                operation: 'EQUAL',
              },
              {
                field: 'motorista.status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
            ]);
          }
        }}
      />
    </div>
  );
};

export default Filters;
