import {
  alpha,
  Button,
  Menu,
  MenuProps,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import Alertas from 'components/Alertas';
import HistoricCommunication from 'components/HistoricCommunication';
// import Tooltip from 'components/Tooltip';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReceivedData } from 'store/modules/socket/actions';
import hamburguerMenu from '../../../../../assets/images/hamburguerMenu.png';

import InfoIcon from '@mui/icons-material/Info';
import cteDisabledIcon from 'assets/images/cteDisabledIcon.svg';
import cteIcon from 'assets/images/cteIcon.svg';
import cancelCargo from '../../../../../assets/images/cancelCargo.svg';
import changeDriver from '../../../../../assets/images/changeDriver.svg';
import errorCommunication from '../../../../../assets/images/errorComunication.svg';
import infoIcon from '../../../../../assets/images/infoIcon.svg';
import listAttendences from '../../../../../assets/images/listAttendences.svg';
import nextDeliveryIcon from '../../../../../assets/images/nextDeliveryIcon.svg';
import timeCargoActive from '../../../../../assets/images/timerCargo.svg';
import timerCargo from '../../../../../assets/images/timerCargoInactive.svg';
import viewMapIcon from '../../../../../assets/images/viewMapIcon.svg';

import { DataRouteringCargas } from 'store/modules/routering/actions';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipWrapper,
} from 'ui/tooltip';
import Devices from '../Devices';
import ProgressBar from '../ProgressBar';
import '../styles.scss';
import TotalizadorTC from './components/TotalizadorTC';
import VehicleIcon from './components/VehicleIcon';
import './styles.scss';

interface RowTableProps {
  data: any;
  onViewMap: (idRomaneio: number, idMotorista: number, cargo: any) => void;
  onListAttendances: (idRomaneio: number) => void;
  onChangeDriver: (idRomaneio: number) => void;
  onCancelCargo: (idRomaneio: number) => void;
  onUploadCTE: (idRomaneio: number) => void;
  onDetailsCTE: (data: any) => void;
}

const RowTable: React.FC<RowTableProps> = ({
  data,
  onViewMap,
  onListAttendances,
  onChangeDriver,
  onCancelCargo,
  onUploadCTE,
  onDetailsCTE,
}: RowTableProps) => {
  const [dropdownOpen, setDropdownOpen] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [dataCargo, setDataCargo] = useState<any>([]);
  const [cargoId, setCargoId] = useState(0);
  const [showHistoricCommunication, setShowHistoricCommunication] = useState(
    false
  );
  const [CPFDriver, setCPFDriver] = useState('');
  const [alertCargo, setAlertCargo] = useState(null);
  const [showAlerts, setShowAlerts] = useState(false);
  const [cargoIndex, setCargoIndex] = useState(0);
  const [updateComponent, setUpdateComponent] = useState(true);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    idCargo: number
  ) => {
    setAnchorEl(event.currentTarget);
    // setOpen(!open);
    setCargoId(idCargo);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setOpen(!open);
  };

  const receivedComponent: ReceivedData[] = useSelector(
    (state: any) => state.socket.data
  );

  function getFormatDate(dateInitial: Date) {
    const dateStart = new Date(dateInitial);
    dateStart.setDate(dateStart.getDate() + 1);
    const formatDate = format(new Date(dateStart), 'dd/MM/yyyy', {
      locale: pt,
    });

    return formatDate;
  }

  function getFormatDateTime(dateInitial: Date) {
    const dateStart = new Date(dateInitial);
    dateStart.setDate(dateStart.getDate());
    const formatDate = format(new Date(dateStart), 'dd/MM/yyyy HH:mm', {
      locale: pt,
    });

    return formatDate;
  }

  function calcPorcentage(valueInitial: number, total: number) {
    const md_val = parseFloat(`${valueInitial}`);
    const medidab = parseInt(String((md_val / total) * 100));
    return medidab;
  }
  function getCalcPorcentage(dataAccumulator: DataRouteringCargas) {
    const accumulatorAttendances = calcPorcentage(
      dataAccumulator.steps.length,
      dataAccumulator.vehicle.quantidadeAtendimento
    );

    const accumulatorWeight = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.peso);
      },
      0
    );

    const accumulatorVolume = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return (
          acc +
          parseFloat(
            itemAcc.conhecimentoPre.cubagem.length === 0
              ? 0
              : itemAcc.conhecimentoPre.cubagem
          )
        );
      },
      0
    );

    const accumulatorValor = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.valor);
      },
      0
    );

    return {
      porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentage(
        accumulatorWeight,
        dataAccumulator.vehicle.peso
      ),
      porcentageVolume: calcPorcentage(
        accumulatorVolume,
        dataAccumulator.vehicle.cubagem
      ),
      porcentagePrice: calcPorcentage(
        accumulatorValor,
        dataAccumulator.vehicle.valor
      ),
    };
  }

  const handleTooltipClose = () => {
    setOpen(!open);
  };

  const handleTooltipOpen = (index: number, idCargo: number) => {
    setOpen(!open);
    setCargoIndex(index);
    setCargoId(idCargo);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  function calcPorcentageTC(valueInitial: number, total: number) {
    // const md_val = parseFloat(`${valueInitial}`);
    // const medidab = parseInt(String((md_val / total) * 100));
    // console.log("Porcentage2", (valueInitial / total) * 100);
    // console.log("Value", md_val);
    // console.log("Vehicle", total);
    return Math.round((valueInitial / total) * 100);
  }
  function getCalcPorcentageTC(data: any) {
    // const accumulatorAttendances = calcPorcentageTC(
    //   data.totalConhecimentos,
    //   50
    // );

    const totalPeso: any = parseFloat(data.totalPeso).toFixed(2);
    const totalCubagem: any = parseFloat(data.totalCubagem).toFixed(2);
    const totalValor: any = parseFloat(data.totalValor).toFixed(2);

    return {
      // porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentageTC(totalPeso, data.veiculo.peso),
      porcentageVolume: calcPorcentageTC(totalCubagem, data.veiculo.cubagem),
      porcentagePrice: calcPorcentageTC(totalValor, data.veiculo.valor),
    };
  }

  // useEffect(() => {
  //   let loadingDevices: any = "";

  //   const storage: any = sessionStorage.getItem('loadingDevices');
  //   if(storage) {
  //     loadingDevices = JSON.parse(storage);
  //   }
  //   setUpdateComponent(loadingDevices);
  // }, [sessionStorage.getItem('loadingDevices')]);

  return (
    <React.Fragment>
      {showHistoricCommunication && (
        <HistoricCommunication
          onClear={() => setShowHistoricCommunication(false)}
          cpfDriver={CPFDriver}
        />
      )}
      {showAlerts && (
        <Alertas
          idCargo={alertCargo}
          isShipment={false}
          onClear={() => setShowAlerts(!showAlerts)}
        />
      )}
      {data.map((row: any, indexRow: number) => (
        <>
          <tr className="lineGroup">
            <td>
              <div className="rowVehicle">
                <TotalizadorTC
                  cargasAccumulator={row.romaneio}
                  vehicleAccumulator={row.romaneio}
                  porcentageAccumulator={getCalcPorcentageTC(row.romaneio)}
                  activeTooltip={'active'}
                  typeTotalizator=""
                >
                  <VehicleIcon data={row} />
                </TotalizadorTC>

                <span className="titleTowerTable">
                  {row.romaneio.veiculo.placa}
                </span>
              </div>
              <span
                className="clientName"
                onClick={e => {
                  e.stopPropagation();
                  setAlertCargo(row.romaneio.id);
                  setShowAlerts(!showAlerts);
                }}
              >
                {row.romaneio.possuiAlertasNaoLidos && (
                  <span className="tw-cursor-pointer tw-text-[#EA004C] tw-font-bold">
                    Ver alertas
                  </span>
                )}
              </span>
            </td>
            <td>
              <TooltipProvider>
                <TooltipWrapper>
                  <TooltipTrigger asChild>
                    <span className="clientName tw-cursor-pointer">
                      {getFormatDate(new Date(row.romaneio.data))}
                    </span>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="tw-flex">
                      <span className="tw-text-sm tw-font-bold tw-mr-2">
                        Data Emissão:
                      </span>
                      <p>
                        {row.romaneio.dataCriacao !== null
                          ? format(
                              new Date(row.romaneio.dataCriacao),
                              'dd/MM/yyyy HH:mm:ss',
                              {
                                locale: pt,
                              }
                            )
                          : '----'}
                      </p>
                    </div>
                  </TooltipContent>
                </TooltipWrapper>
              </TooltipProvider>
              {/* {getFormatDate(new Date(row.romaneio.data))} */}
            </td>
            <td>
              <span className="clientName">
                {row.romaneio.dataAgendamento !== null
                  ? getFormatDateTime(new Date(row.romaneio.dataAgendamento))
                  : '----'}
              </span>
            </td>
            <td>
              <div className="cargoDriverLimitText">
                <span className="clientName">
                  {row.romaneio.motorista.nome}
                </span>
              </div>
            </td>
            <td className="tw-flex tw-items-center">
              <span className="clientName">{row.romaneio.numero}</span>
              {row.romaneio.uploadedCte ? (
                <TooltipProvider>
                  <TooltipWrapper>
                    <TooltipTrigger asChild>
                      <img src={cteIcon} className="tw-cursor-pointer" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="tw-flex">
                        <span className="tw-text-sm tw-font-bold tw-mr-2">
                          Data/Hora Upload:
                        </span>
                        <p>
                          {row.romaneio.uploadedCteDate !== null
                            ? format(
                                new Date(row.romaneio.uploadedCteDate),
                                'dd/MM/yyyy HH:mm:ss',
                                {
                                  locale: pt,
                                }
                              )
                            : '----'}
                        </p>
                      </div>
                    </TooltipContent>
                  </TooltipWrapper>
                </TooltipProvider>
              ) : (
                <TooltipProvider>
                  <TooltipWrapper>
                    <TooltipTrigger asChild>
                      <img
                        src={cteDisabledIcon}
                        className="tw-cursor-pointer"
                      />
                    </TooltipTrigger>
                    <TooltipContent>
                      <div className="tw-flex">
                        <span className="tw-text-sm tw-font-bold tw-mr-2">
                          Data/Hora Upload:
                        </span>
                        <p>
                          {row.romaneio.uploadedCteDate !== null
                            ? format(
                                new Date(row.romaneio.uploadedCteDate),
                                'dd/MM/yyyy HH:mm:ss',
                                {
                                  locale: pt,
                                }
                              )
                            : '----'}
                        </p>
                      </div>
                    </TooltipContent>
                  </TooltipWrapper>
                </TooltipProvider>
              )}
            </td>
            <td>
              <>
                {row.romaneio.cancelamento !== null ? (
                  <span className="textCancelCargo">
                    {row.romaneio.cancelamento !== null &&
                    row.romaneio.cancelamento.motivo === 'N/A' ? (
                      <span className="reasonCancel">
                        <Trans i18nKey="controlTower.fields.driverChanged" />{' '}
                        {format(
                          new Date(row.romaneio.cancelamento.data),
                          'dd/MM/yyyy',
                          {
                            locale: pt,
                          }
                        )}
                      </span>
                    ) : (
                      <React.Fragment>
                        {row.romaneio.cancelamento !== null && (
                          <>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <div style={{ display: 'flex' }}>
                                    <div className="tooltipStylesWrap">
                                      <span className="reasonCancel">
                                        <Trans i18nKey="controlTower.toasts.motivoCancelamento" />
                                      </span>
                                      <div className="tooltipStyles">
                                        <span className="textReasonCancel">
                                          {row.romaneio.cancelamento.motivo}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <span className="clientName">
                                {' '}
                                <Trans i18nKey="controlTower.textCancelCargo" />{' '}
                                {format(
                                  new Date(row.romaneio.cancelamento.data),
                                  'dd/MM/yyyy',
                                  {
                                    locale: pt,
                                  }
                                )}
                              </span>
                            </HtmlTooltip>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </span>
                ) : row.romaneio.motorista.statusComunicacao ? (
                  <React.Fragment>
                    <div className="noCommunicationWrapper">
                      <img src={errorCommunication} alt="Error Communication" />
                      <span
                        className="textNoCommunication"
                        style={{ marginRight: '1rem' }}
                      >
                        Sem comunicação
                      </span>
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <div style={{ display: 'flex' }}>
                              <div className="tooltipStylesWrap">
                                <span className="reasonCancel">
                                  <Trans i18nKey="controlTower.fields.dateTimeComunication" />
                                  :
                                </span>
                                <div className="tooltipStyles">
                                  {row.romaneio.motorista.dataComunicacao &&
                                  row.romaneio.motorista.dataComunicacao !==
                                    null ? (
                                    <span>
                                      {format(
                                        new Date(
                                          row.romaneio.motorista.dataComunicacao
                                        ),
                                        'dd/MM/yyyy HH:mm',
                                        {
                                          locale: pt,
                                        }
                                      )}
                                    </span>
                                  ) : (
                                    <>
                                      <span>---</span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        }
                      >
                        <img
                          src={timerCargo}
                          alt=""
                          className="imgDevice"
                          onClick={() => {}}
                        />
                      </HtmlTooltip>
                    </div>
                  </React.Fragment>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div style={{ display: 'flex' }}>
                            <div className="tooltipStylesWrap">
                              <span className="reasonCancel">
                                <Trans i18nKey="controlTower.fields.dateTimeComunication" />
                                :
                              </span>
                              <div className="tooltipStyles">
                                {row.romaneio.motorista.dataComunicacao &&
                                row.romaneio.motorista.dataComunicacao !==
                                  null ? (
                                  <span>
                                    {format(
                                      new Date(
                                        row.romaneio.motorista.dataComunicacao
                                      ),
                                      'dd/MM/yyyy HH:mm',
                                      {
                                        locale: pt,
                                      }
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    <span>---</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    >
                      <img
                        src={timeCargoActive}
                        alt=""
                        className="imgDevice"
                        onClick={() => {
                          setCPFDriver(row.romaneio.motorista.cpf);
                          setShowHistoricCommunication(
                            !showHistoricCommunication
                          );
                        }}
                      />
                    </HtmlTooltip>
                    <Devices
                      data={row}
                      communication={false}
                      onHistoric={() => {}}
                    />
                  </div>
                )}
              </>
            </td>
            {row.romaneio.cancelamento === null ? (
              <>
                <td>
                  <ProgressBar
                    done={data[indexRow].romaneio.percentualBaixa}
                    deliveryDone={Number(
                      parseInt(
                        data[indexRow].romaneio.totalSucesso +
                          data[indexRow].romaneio.totalInsucesso
                      )
                    )}
                    total={data[indexRow].romaneio.totalConhecimentos}
                    // widthProgress={120}
                  />
                </td>
                <td>
                  <span className="deliveryDone">
                    {row.romaneio.totalSucesso}
                  </span>
                </td>
                <td>
                  <span className="deliveryCancel">
                    {row.romaneio.totalInsucesso}
                  </span>
                </td>
                <td>
                  <span className="clientName">
                    {row.romaneio.romaneioCheckin !== null ? (
                      <>
                        {format(
                          new Date(row.romaneio.romaneioCheckin.horarioInicial),
                          'dd/MM/yyyy HH:mm',
                          {
                            locale: pt,
                          }
                        )}
                      </>
                    ) : (
                      '---'
                    )}
                  </span>
                </td>
                <td>
                  <span className="clientName">
                    {row.romaneio.dataHoraUltimaEntrega !== null ? (
                      <>
                        {format(
                          new Date(row.romaneio.dataHoraUltimaEntrega),
                          'dd/MM/yyyy HH:mm',
                          {
                            locale: pt,
                          }
                        )}
                      </>
                    ) : (
                      '---'
                    )}
                  </span>
                </td>
                <td>
                  <div
                    className="columnTable"
                    style={{
                      width: '12ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data[indexRow].romaneio.conhecimentoAtual !== null &&
                    parseInt(
                      data[indexRow].romaneio.totalSucesso +
                        data[indexRow].romaneio.totalInsucesso
                    ) < data[indexRow].romaneio.totalConhecimentos ? (
                      <>
                        <span className="clientName">
                          {data[indexRow].romaneio.conhecimentoAtual !== null &&
                          data[indexRow].romaneio.conhecimentoAtual.cliente !==
                            null
                            ? data[indexRow].romaneio.conhecimentoAtual.cliente
                            : '---'}
                        </span>
                        <span className="phasesTable">
                          {data[indexRow].romaneio.conhecimentoAtual.etapa ===
                            null &&
                          data[indexRow].romaneio.romaneioCheckin !== null &&
                          parseInt(
                            data[indexRow].romaneio.totalSucesso +
                              data[indexRow].romaneio.totalInsucesso
                          ) < data[indexRow].romaneio.totalConhecimentos
                            ? 'Em trânsito.'
                            : data[indexRow].romaneio.conhecimentoAtual
                                .etapa !== null
                            ? data[indexRow].romaneio.conhecimentoAtual.etapa
                            : '---'}
                        </span>
                      </>
                    ) : (
                      <span>---</span>
                    )}
                  </div>
                </td>
                <td>
                  <TooltipProvider>
                    <TooltipWrapper>
                      <TooltipTrigger asChild>
                        <img
                          src={infoIcon}
                          width={18}
                          height={18}
                          className="tw-relative tw-left-[-7px] tw-cursor-pointer"
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {row.romaneio.operadorLogistico !== null
                            ? row.romaneio.operadorLogistico
                                .nomeFantasia
                            : '----'}
                        </p>
                      </TooltipContent>
                    </TooltipWrapper>
                  </TooltipProvider>
                </td>
                <td>
                  <TooltipProvider>
                    <TooltipWrapper>
                      <TooltipTrigger asChild>
                        <img
                          src={infoIcon}
                          width={18}
                          height={18}
                          className="tw-relative tw-left-[-5px] tw-cursor-pointer"
                        />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>
                          {row.romaneio.routeOptmi !== null &&
                          row.romaneio.routeOptmi.description !== null
                            ? row.romaneio.routeOptmi.description
                            : '----'}
                        </p>
                      </TooltipContent>
                    </TooltipWrapper>
                  </TooltipProvider>
                </td>
                <td>
                  <div className="rowTable">
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div style={{ display: 'flex' }}>
                            <div className="tooltipStylesWrap">
                              <span className="reasonCancel">
                                <Trans i18nKey="controlTower.toasts.nextDelivery" />
                                :
                              </span>
                              <div className="tooltipStyles">
                                {row.romaneio.conhecimentoSeguinte !== null ? (
                                  <span className="textReasonCancel">
                                    {row.romaneio.conhecimentoSeguinte.cliente}
                                  </span>
                                ) : (
                                  <span>---</span>
                                )}
                              </div>
                            </div>

                            <div className="tooltipStylesWrap">
                              <span className="reasonCancel">
                                <Trans i18nKey="controlTower.toasts.referenceId" />
                                :
                              </span>
                              <div className="tooltipStyles">
                                {/* {row.romaneio.conhecimentoSeguinte !== null ? (
                                <span className="textReasonCancel">
                                  {
                                    row.romaneio.conhecimentoSeguinte
                                      .idReferencia
                                  }
                                </span>
                              ) : (
                                <span>---</span>
                              )} */}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    >
                      <img
                        src={nextDeliveryIcon}
                        className="imgActions"
                        style={{ width: '1.313rem', height: '0.99rem' }}
                        alt=""
                      />
                    </HtmlTooltip>
                    <HtmlTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={open && cargoIndex === indexRow ? open : false}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                      title={
                        <React.Fragment>
                          <div
                            className="optionsMenu"
                            onClick={() => {
                              onViewMap(
                                cargoId,
                                row.romaneio.motorista.id,
                                row
                              );
                              handleTooltipClose();
                            }}
                          >
                            <img
                              src={viewMapIcon}
                              alt=""
                              className="imgActions"
                            />
                            <span className="textMenuOptions">
                              <Trans i18nKey="controlTower.toasts.viewMap" />{' '}
                            </span>{' '}
                          </div>

                          <div
                            className="optionsMenu"
                            onClick={() => {
                              console.log(cargoId);
                              onListAttendances(cargoId);
                              handleTooltipClose();
                            }}
                          >
                            <img
                              src={listAttendences}
                              alt=""
                              className="imgActions"
                            />
                            <span className="textMenuOptions">
                              <Trans i18nKey="controlTower.toasts.listAttendances" />{' '}
                            </span>
                          </div>
                          <div
                            className="optionsMenu"
                            onClick={() => {
                              if (row.romaneio.percentualBaixa < 100) {
                                onChangeDriver(row.romaneio.id);
                                handleTooltipClose();
                              }
                            }}
                          >
                            <img
                              src={changeDriver}
                              alt=""
                              className="imgActions"
                            />
                            <span className="textMenuOptions">
                              <Trans i18nKey="controlTower.toasts.changeDriver" />{' '}
                            </span>
                          </div>
                          <div
                            className="optionsMenu"
                            onClick={() => {
                              if (row.romaneio.percentualBaixa < 100) {
                                onCancelCargo(cargoId);
                                handleTooltipClose();
                              }
                            }}
                          >
                            <img
                              src={cancelCargo}
                              alt=""
                              className="imgActions"
                            />
                            <span className="textMenuOptions">
                              <Trans i18nKey="controlTower.toasts.cancelCargo" />{' '}
                            </span>
                          </div>
                          <div
                            className="optionsMenu"
                            onClick={() => {
                              onUploadCTE(row.romaneio.id);
                              handleTooltipClose();
                            }}
                          >
                            <img
                              src={cteDisabledIcon}
                              alt=""
                              className="tw-w-7 tw-h-6 tw-ml-0 tw-mr-2"
                              // className="imgActions"
                            />
                            <span className="textMenuOptions">
                              Upload CTE
                              {/* <Trans i18nKey="controlTower.toasts.changeDriver" />{' '} */}
                            </span>
                          </div>
                          <div
                            className="optionsMenu"
                            onClick={() => {
                              onDetailsCTE(row.romaneio);
                              handleTooltipClose();
                            }}
                          >
                            <InfoIcon color='error' className='tw-mr-3' />
                            <span className="textMenuOptions">
                              Dados do Romaneio
                              {/* <Trans i18nKey="controlTower.toasts.changeDriver" />{' '} */}
                            </span>
                          </div>
                        </React.Fragment>
                      }
                    >
                      <Button
                        onClick={() =>
                          handleTooltipOpen(
                            indexRow,
                            data[indexRow].romaneio.id
                          )
                        }
                      >
                        <img src={hamburguerMenu} alt="" />
                      </Button>
                    </HtmlTooltip>
                  </div>
                </td>
              </>
            ) : (
              <>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </>
            )}
          </tr>
        </>
      ))}
    </React.Fragment>
  );
};

export default RowTable;
