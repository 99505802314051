import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import Button from 'components/Button';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import { Footer } from 'components/Reasons/styles';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Trans } from 'react-i18next';
import {
  Button as ButtonListAction,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import { Label } from 'reactstrap/lib';
import api from 'services/api';
import IconExportPDF from '../../assets/images/iconExportPdf.svg';
import UploadArrowUp from '../../assets/images/uploadArrowUp.svg';
import Filters from './Filters';
import { FormDataReports, FormikReports, ReportsData } from './interfaces';
import {
  ButtonsAction,
  Container,
  ContainerList,
  ContainerUploaded,
  FileName,
  Form,
  ResetFile,
  Status,
  WrapperFilters,
  WrapperForm,
  WrapperList,
} from './styles';

interface Props {
  onClear: any;
}

const Reports: React.FC<Props> = ({ onClear }: Props) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [idReports, setIdReports] = useState<number>(0);
  const [filesUpload, setFiles] = useState([]);
  const [filesPreview, setFilesPreview] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [typeUpload, setTypeUpload] = useState<string>('');
  const [reportsData, setReportsData] = useState<ReportsData[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number | null>(null);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const [removeId, setRemoveId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [textSearch, setTextSearch] = useState<string>('');
  const [reportsDetails, setReportsDetails] = useState<ReportsData>();

  const onHandleSubmit = useCallback(
    async (
      values: FormikReports,
      files: FormDataReports,
      updateId?: number | null
    ) => {
      try {
        setLoading(true);
        const data = new FormData();
        const formData = {
          description: values.description,
        };

        const json = JSON.stringify(formData);
        const blob = new Blob([json], {
          type: 'application/json',
        });
        console.log(files.file);

        data.append('file', files.file);
        data.append('data', blob);

        if (updateId !== null) {
          const responseUpdate = await api.post(
            `/reportBuilder/reports/upload/${updateId}`,
            data
          );
          FrontendNotification(
            'Relatório atualizado com sucesso',
            NotificationType.SUCCESS
          );
        } else {
          const responseSubmit = await api.post(
            `/reportBuilder/reports/upload`,
            data
          );
          FrontendNotification(
            'Relatório cadastrado com sucesso',
            NotificationType.SUCCESS
          );
        }
        setLoading(false);
        getReports();
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const handleUploadRejection = async (files: any) => {
    if (files[0].errors[0].code === 'file-invalid-type') {
      setErrorMessage('Tipo de arquivo inválido.');
    }
  };

  const handleUpload = async (files: any) => {
    const uploadedFiles = files.map((file: any) => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,

      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));


    setTypeUpload('R');
    setFiles(uploadedFiles[0].file);
    setFilesPreview(uploadedFiles[0]);
  };

  const getReports = useCallback(
    async (textFilter?: string) => {
      try {
        setLoading(true);
        let filtersSearch: any[] = [];
        if (textFilter && textFilter.length > 0) {
          filtersSearch = [
            {
              field: 'description',
              value: textFilter,
              operation: 'MATCH',
            },
          ];
        }
        const data = {
          filters: filtersSearch,
          orders: [],
          page: currentPage || 0,
          size: 10,
        };
        const response = await api.post(
          '/reportBuilder/reports/pageable',
          data
        );

        setReportsData(response.data.content);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch {}
    },
    [currentPage]
  );

  const initialValues: FormikReports = {
    description: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: FormikReports) =>
      onHandleSubmit(values, filesPreview, updateId),
  });

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const onDelete = useCallback(async (reportId: any) => {
    try {
      setLoading(true);
      // setRemoveConfirmation(true);
      const language = navigator.language;

      const erroLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
      const response = await api.delete(
        `/reportBuilder/reports/${reportId}?lang=${erroLanguage}`
      );
      if (response.status === 204) {
        FrontendNotification(
          'Relatório removido com sucesso',
          NotificationType.SUCCESS
        );
      }
      setRemoveConfirmation(false);
      setLoading(false);
      getReports();
    } catch (err) {}
  }, []);

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <React.Fragment>
      <Loading loading={loading} />
      {openFilters && (
        <Filters
          onClear={() => setOpenFilters(!openFilters)}
          reportId={idReports}
          reportData={reportsDetails}
        />
      )}
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <ButtonListAction
            color="danger"
            onClick={() => onDelete(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </ButtonListAction>{' '}
          <ButtonListAction
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </ButtonListAction>
        </ModalFooter>
      </Modal>
      <CustomModal label="Relatórios" isOpen isClose={onClear}>
        <Container>
          <WrapperForm>
            <Form>
              <InputCustom
                title="Descrição"
                placeholder="Relatório"
                onChange={formik.handleChange('description')}
                value={formik.values.description}
              />
              <div style={{ marginTop: '10px' }}>
                <Label
                  style={{
                    fontSize: 14,
                    color: '#003049',
                    opacity: 0.6,
                    fontStyle: 'normal',
                  }}
                >
                  Arquivo
                </Label>

                <Dropzone
                  onDropAccepted={handleUpload}
                  onDropRejected={handleUploadRejection}
                >
                  {({
                    getRootProps,
                    getInputProps,
                    isDragActive,
                    isDragReject,
                  }) => (
                    <div
                      // className="sectionDrop"
                      {...getRootProps()}
                      // isDragActive={isDragActive}
                      // isDragReject={isDragReject}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: 40,
                        border: `1px solid #D2D2D2`,
                        marginBottom: '15px',
                        borderRadius: '10px',
                        paddingLeft: '15px',
                      }}
                    >
                      <React.Fragment>
                        <>
                          {filesPreview.preview ? (
                            <ContainerUploaded>
                              <Status />
                              <FileName>{filesPreview.file.name}</FileName>
                              <ResetFile
                                onClick={e => {
                                  e.stopPropagation();
                                  setFiles([]);
                                  setFilesPreview([]);
                                  setErrorMessage('');
                                }}
                              >
                                X
                              </ResetFile>
                            </ContainerUploaded>
                          ) : errorMessage.length > 0 ? (
                            <ContainerUploaded>
                              <Status error />
                              <FileName>{errorMessage}</FileName>
                              <ResetFile
                                onClick={e => {
                                  e.stopPropagation();
                                  setFiles([]);
                                  setFilesPreview([]);
                                  setErrorMessage('');
                                }}
                              >
                                X
                              </ResetFile>
                            </ContainerUploaded>
                          ) : (
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: 14,
                                color: '#003049',
                              }}
                            >
                              <img
                                src={UploadArrowUp}
                                style={{ marginRight: '10px' }}
                                alt=""
                              />
                              Selecione o arquivo
                            </span>
                          )}
                        </>

                        <input {...getInputProps()} />
                      </React.Fragment>
                    </div>
                  )}
                </Dropzone>
              </div>

              {/* <button
                onClick={() => formik.handleSubmit()}
                className="button"
                type="button"
                style={{ width: '100%', height: 45, marginTop: 20 }}
              >
                Criar relatório
              </button> */}
            </Form>

            <Footer>
              <Row>
                {edit ? (
                  <>
                    <Col md={6}>
                      <button
                        onClick={() => {
                          formik.resetForm({});
                          setEdit(false);
                          setUpdateId(null);
                          // sessionStorage.setItem('journeyId', '')
                        }}
                        id="cancel"
                        className="button"
                        type="button"
                        style={{
                          width: '100%',
                          background: '#ccc',
                          color: '#000',
                        }}
                      >
                        <Trans i18nKey="common.cancel" />
                      </button>
                    </Col>

                    <Col md={6}>
                      <button
                        onClick={() => formik.handleSubmit()}
                        id="update"
                        className="button"
                        type="button"
                        style={{ width: '100%' }}
                      >
                        Atualizar relatório
                      </button>
                    </Col>
                  </>
                ) : (
                  <Col md={12} xs={12}>
                    <button
                      onClick={() => formik.handleSubmit()}
                      className="button"
                      id="save"
                      type="button"
                      style={{ width: '100%' }}
                    >
                      Novo Relatório
                      {/* <Trans i18nKey="driver.title" /> */}
                    </button>
                  </Col>
                )}
              </Row>
            </Footer>
          </WrapperForm>
          <WrapperList>
            <WrapperFilters>
              <InputCustom
                title=""
                placeholder="Buscar relatório"
                onChange={(e: any) => setTextSearch(e.target.value)}
              />
              <Button
                onClick={() => getReports(textSearch)}
                label="Filtrar"
                styles={{
                  width: '50%',
                  height: '40px',
                  marginLeft: '0.800rem',
                }}
              />
            </WrapperFilters>
            <ContainerList className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Descrição</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {reportsData.map((report: ReportsData) => (
                    <tr className="lineGroup">
                      <td>{report.description}</td>
                      <td>
                        <ButtonsAction>
                          <ButtonListAction
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setOpenFilters(!openFilters);
                              setIdReports(report.id);
                              setReportsDetails(report);
                              // formik.setFieldValue('name', item.nome);
                              // setEdit(true);
                              // setUpdateId(item.id);
                            }}
                          >
                            <img src={IconExportPDF} alt="" />
                          </ButtonListAction>
                          <ButtonListAction
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              formik.setFieldValue(
                                'description',
                                report.description
                              );
                              setEdit(true);
                              setUpdateId(report.id);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                            />
                          </ButtonListAction>
                          <ButtonListAction
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setRemoveId(report.id);
                              setRemoveConfirmation(!removeConfirmation);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                            />
                          </ButtonListAction>
                        </ButtonsAction>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e: any, page: number) => {
                    setCurrentPage(page - 1);
                  }}
                />
              </div>
            </ContainerList>
          </WrapperList>
        </Container>
      </CustomModal>
    </React.Fragment>
  );
};

export default Reports;
