import styled from 'styled-components';

interface StyledProps {
  loadingComponent?: boolean;
  color?: string;
}

export const Wrapper = styled.div`
  /* flex: 1; */
  width: auto;
  /* border: 2px solid #6369D1; */
  box-shadow: 14px 21px 15px 14px  rgba(0,0,0,0.1);
  border-radius: 0.313rem;
  padding: 0;
  margin-left: 0.715rem;
  /* min-height: 270px; */


  .loadingComponent {
    width: 100%;
    
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  max-height: 0.75rem;
  /* min-height: 1.75rem; */
  background-color: ${(props: StyledProps) => props.color};
  /* padding: 0.313rem; */
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* top: -2px; */
  /* left: 0.30px; */
  .loadingComponent {
    width: 100%;
    height: 1.75rem !important;
  }
`;

export const Title = styled.span`
  font-size: 0.938rem;
  color: #ffff;

`;

export const Icon = styled.img`
  margin-right: 0.625rem;
`;

export const Header = styled.div`
  display: flex;
  height: 12px;
`;

export const More = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  /* position: absolute; */
  /* right: 0; */
`;

export const MoreIcon = styled.img`
  cursor: pointer;
`;

export const LoadAnimation = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
