import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Line } from '../../components/Styled/guiPublic';

import { Button } from 'reactstrap';
import successIcon from '../../assets/images/successIcon.png';
import voltar from '../../assets/voltar-seta.png';
import BaseTemplate from '../../template/baseTemplate';
import { AccessAcc, BackButtom } from '../SignUp/styles';
import './styles.scss';

export default function ConfirmAccount() {
  const { t } = useTranslation();
  return (
    <BaseTemplate text={t('message.welcome.title')}>
      <div className="form-confirm">
        <div className="row">
          <BackButtom>
            <AccessAcc>
              <Link to="/forgotPassword" className="voltar">
                <img src={voltar} className="seta" alt="Back" />
                {t('forgotPassword.back')}
              </Link>
            </AccessAcc>
            <h5 className="text-center">{t('forgotPassword.title')}</h5>
            <Line />
          </BackButtom>
        </div>
        <div className="content">
          <div className="container-image">
            <img src={successIcon} alt="" />
            <span>Sucesso!</span>
          </div>
          <div className="footer">
            <span>Parabéns</span>
            <p>
              Enviamos um e-mail para você recuperar a conta. Verifique na caixa
              de entrada e no spam.{' '}
            </p>
            <Link to="/">
              <Button className="btn btn-signup btn-block">OK</Button>
            </Link>
          </div>
        </div>
      </div>
    </BaseTemplate>
  );
}
