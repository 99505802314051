import React from 'react';
import checkedIcon from '../../../../../assets/images/towerChecked.svg';
import './styles.scss';

export default function CheckBox({ label, active, onChecked , id, styles}) {

  const [checkbox, setCheckbox] = React.useState();
  return (
    <div className="checkbox" onClick={onChecked} id={id}>
      <div style={{ display: 'flex', alignItems: 'center'}}>
        {active ? (
          <div className="checkedAttendances">
            <img src={checkedIcon} style={{ width: 14, height: 14 }} alt="" />
          </div>
        ) : (
          <div className="unCheckedAttendances" />
        )}
        <span
          style={{
            marginLeft: 5,
            marginRight: 10,
            fontFamily: 'Lato',
            fontSize: 14,
          }}
        >
          {label}
        </span>
      </div>
    </div>
  );
}
