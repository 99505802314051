import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import React from 'react';
import { ICreateShippingTableForm } from '../../..';
import { dayOfMonths } from '../../data/helpers';
import {
  FormContent,
  FormContentField
} from './styles';

interface ShippingFixedValueProps {
  onChange: (field: string, value: string) => void;
  values: ICreateShippingTableForm;
  errors?: any;
  touched?: any;
}

const ShippingFixedValue: React.FC<ShippingFixedValueProps> = ({
  onChange,
  values,
  errors,
  touched,
}: ShippingFixedValueProps) => {
  return (
    <FormContent>
      <FormContentField>
        <InputCustom
          placeholder="KM Franquia"
          type="number"
          title="Franquia KM"
          onChange={(e: any) => onChange('qtdKM', e.target.value)}
          className="inputHeader"
          value={values.qtdKM}
          error={errors.qtdKM}
          touched={touched.qtdKM}
          id='qtdKM'
        />
      </FormContentField>
      <FormContentField>
        <InputCustom
          title="Valor Franquia"
          placeholder="Valor Franquia"
          onChange={(event: any) => {
            let userInput: string = event.target.value.replace(/[^0-9]/g, '');

            if (userInput === '') {
              onChange("valueKM", "0,00");
            } else {
              let userInputAsNumber: number = parseInt(userInput, 10) / 100;

              let formattedNumber: string = `${userInputAsNumber
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')}`;

              onChange('valueKM', formattedNumber);
            }
          }}
          value={values.valueKM}
          error={errors.valueKM}
          touched={touched.valueKM}
          id='valueKM'
        />
      </FormContentField>
      <FormContentField>
        <InputCustom
          title="Valor KM Exc."
          placeholder="Valor KM exc."
          onChange={(event: any) => {
            let userInput: string = event.target.value.replace(/[^0-9]/g, '');

            if (userInput === '') {
              onChange("valueKM", "0,00");
            } else {
              let userInputAsNumber: number = parseInt(userInput, 10) / 100;

              let formattedNumber: string = `${userInputAsNumber
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+\,)/g, '$1.')}`;

              onChange('surplusValue', formattedNumber);
            }
          }}
          value={values.surplusValue}
          error={errors.surplusValue}
          touched={touched.surplusValue}
          id='surplusValue'
        />
      </FormContentField>
      <FormContentField>
        <SelectCustom
          onChange={(e: any) => onChange('initialDay', e.target.value)}
          title="Dia início da franquia"
          style={{}}
          value={values.initialDay}
          error={errors.initialDay}
          touched={touched.initialDay}
          id='dayInitial'
        >
          <>
            <option label="Dia" />
            {dayOfMonths.map((day: number, index: number) => (
              <option key={index} value={day}>
                {day}
              </option>
            ))}
          </>
        </SelectCustom>
      </FormContentField>
    </FormContent>
  );
};

export default ShippingFixedValue;
