import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Title = styled.span`
  font-size: 0.938rem;
  color: #ffff;
  margin-left: 0.625rem;
`;

export const CloseModal = styled.div`
  position: absolute;
  margin-bottom: 0.230rem;
  cursor: pointer;
  right: 0;
`;

export const WrapperFiltersContent = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.625rem;
`;

export const SectionFilter = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 0.615rem; */
  position: relative;
`;

export const SectionText = styled.div`
  font-size: 0.813rem;
  color: #535669;
  margin-left: 0.400rem;
`;

export const ClearFilters = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
`;

export const ClearFiltersText = styled.span`
  font-family: 'Lato';
  font-size: 0.813rem;
  color: #EA004C;
`;