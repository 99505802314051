// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressBarContainer {
  background: #EDF2F4;
  border: 1px solid #CDD9DD;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 3px;
}
.progressBarContainer .progressBar {
  background: #6369D1;
  border-radius: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  color: #FFF;
  padding-right: 10px;
}
.progressBarContainer .noProgress {
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/Cargo/progressbar/style.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACI;EACI,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;AACR;AAEI;EACI,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AAAR","sourcesContent":[".progressBarContainer {\n    background: #EDF2F4;\n    border: 1px solid #CDD9DD;\n    box-sizing: border-box;\n    border-radius: 40px;\n    padding: 3px;\n\n    .progressBar {\n        background: #6369D1;\n        border-radius: 20px;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: bold;\n        color: #FFF;\n        padding-right: 10px;\n    }\n\n    .noProgress {\n        font-size: 12px;\n        font-style: normal;\n        font-weight: bold;\n        color: #000;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
