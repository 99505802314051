import styled from 'styled-components';

interface StylesProps {
  size?: boolean;
}

export const Form = styled.div`
  display: flex;
  margin-top: 2rem;
  .inputHeader {
    width: 217px !important;
  }
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: ${({size}: StylesProps) => size ? "120px" : "217px"};
`;

export const LabelGroupFields = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: #003049;
  font-style: normal;
  font-weight: 500;
  opacity: 0.6;
`;

export const FormGroupFields = styled.div`
  display: flex;
  /* align-items: center; */
`;