import { object, string } from 'yup';

export default object().shape({
  descricao: string()
    .required('* Enter the description!')
    .max(50, '* Maximum of 50 characters'),
  numero: string()
    .required('* Enter the number!')
    .max(5, '* Maximum of 5 digits!'),
  tipo: string().required('* Enter the Type!'),
  responsavel: string().required('* Enter the Responsible!'),
  sequencial: string().required('* Enter the sequence!'),
});
