import React, { useState, useEffect, useCallback } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Switch from '../../Switch';

import './styles.scss';
import { Trans } from 'react-i18next';
// Draft-JS-Mentions plugin configuration

export default function MyEditor({
  onChangeEditor,
  edit,
  editData,
  onVisibleEditor,
  view,
  onParameters,
}) {
  // Draft-JS editor configuration
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [visibleEditor, setVisibleEditor] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = state => {
    setEditorState(state);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    const currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    onChangeEditor(currentContentAsHTML);
  };

  const convertContentFromHTML = useCallback(htmlData => {
    const convertContentState = EditorState.createWithContent(
      convertFromHTML(htmlData)
    );
    setEditorState(convertContentState);
    const currentContentAsHTML = convertToHTML(
      convertContentState.getCurrentContent()
    );
    setConvertedContent(currentContentAsHTML);
  }, []);
  const createMarkup = html => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    setConvertedContent(editData);
  }, [convertContentFromHTML, edit, editData, visibleEditor]);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <span
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              fontFamily: 'Lato',
              color: '#003049',
              marginRight: 10,
            }}
          >
            HTML
          </span>
          <Switch
            active={visibleEditor}
            onChange={() => {
              onVisibleEditor(!visibleEditor);
              setVisibleEditor(!visibleEditor);
            }}
          />
          <span
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              fontFamily: 'Lato',
              color: '#003049',
              marginLeft: 10,
            }}
          >
            Editor
          </span>
          <div
            style={{
              position: 'absolute',
              right: 0,
              marginTop: 20,
            }}
          >
            <p
              style={{
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: 16,
                color: '#EA004C',
                cursor: 'pointer',
              }}
              onClick={onParameters}
            >
              <Trans i18nKey="phases.parameters" />
            </p>
          </div>
        </div>
        {!visibleEditor ? (
          <textarea
            onChange={e => {
              setConvertedContent(e.target.value);
              onChangeEditor(e.target.value);
            }}
            style={{
              marginTop: 20,
              width: '100%',
              borderRadius: 10,
              padding: 20,
            }}
            rows="10"
            value={convertedContent}
          />
        ) : (
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            readOnly={view}
            toolbar={{
              code: { inDropdown: true },
            }}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 140,
          width: '100%',
        }}
      >
        <span
          style={{
            fontSize: 22,
            fontWeight: 'bold',
            fontFamily: 'Lato',
            color: '#003049',
          }}
        >
          Preview
        </span>
        <div
          className="preview"
          dangerouslySetInnerHTML={createMarkup(convertedContent)}
        />
      </div>
    </div>
  );
}
