import SortingIcon from 'assets/images/sortingArrowsIcon.svg';
import CheckBox from '../../../CargasRouting/components/CheckBox';

import React from 'react';
import { DataStepsRoutering } from 'store/modules/routering/actions';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  onChecked: () => void;
  onChangeOrder: () => void;
  onGrouped: (grouper: any[]) => void;
  isChecked: boolean;
  isDrag: boolean;
  data: DataStepsRoutering[];
}

const Header: React.FC<Props> = (props: Props) => {
  const groupBySteps = (arr: DataStepsRoutering[], columnName: string) => {
    return arr.reduce((acc: any, item: any) => {
      let key = item[columnName]['clienteIdReferencia'] ? item[columnName]['clienteIdReferencia'] : item[columnName]['clienteNome'];
      
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(item);
      return acc;
    }, {});
  };

  const generateRowData = (key: string, items: DataStepsRoutering[]) => {
    let groupedRowData = items.slice(1);
    let entity = {
      grouper: key,
      data: items[0],
      rows: groupedRowData,
    };
    return entity;
  };

  const generateGroupedSteps = () => {
    var groupedSteps: any = {};

    let sortCargo = props.data
      .slice()
      .sort((a: any, b: any) => a.order - b.order);

    groupedSteps = groupBySteps(sortCargo, 'conhecimentoPre');

    let groupedRowData: any[] = [];
    for (const key in groupedSteps) {
      if (groupedSteps.hasOwnProperty(key)) {
        const items = groupedSteps[key];

        groupedRowData.push(generateRowData(key, items));
      }
    }
    const mappingCargoGrouped = groupedRowData.map(
      (item: any, index: number) => {
        return {
          ...item.data,
          order: index + 1,
          rows: item.rows,
          groupedRows: item.rows.length,
        };
      }
    );
    props.onGrouped(mappingCargoGrouped);
  };

  return (
    <React.Fragment>
      {props.isDrag ? (
        <div className="tw-flex tw-items-center tw-justify-between">
          <button
            className="tw-flex tw-w-2/4 tw-items-center tw-justify-center tw-text-sm tw-font-bold tw-text-[#fff] tw-bg-[#EA004C] tw-border-none tw-shadow-md tw-rounded-md tw-h-7 tw-mr-3"
            onClick={() => props.onConfirm()}
          >
            Confirmar ordem
          </button>
          <button
            className="tw-flex tw-w-2/4 tw-items-center tw-justify-center tw-text-sm tw-font-bold tw-text-[#fff] tw-bg-[#8C8C8C] tw-border-none tw-shadow-md tw-rounded-md tw-h-7"
            onClick={() => props.onCancel()}
          >
            Cancelar
          </button>
        </div>
      ) : (
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-3">
          <div className="tw-flex tw-items-center">
            <CheckBox active={props.isChecked} onChecked={props.onChecked} />
            <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-text-nowrap">
              Selecionar todos
            </span>
          </div>
          <div
            className="tw-flex tw-cursor-pointer"
            onClick={() => {
              props.onChangeOrder();
              generateGroupedSteps();
            }}
          >
            <img src={SortingIcon} className="tw-w-4 tw-h-4" />
            <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-text-nowrap tw-ml-2">
              Alterar ordem
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Header;
