import React from 'react';
import getIconVehicle from 'shared/IconVehicles';

interface VehicleIconProps {
  data: any;
}

const VehicleIcon: React.FC<VehicleIconProps> = ({ data }) => {
  return (
    <img
      src={getIconVehicle(
        data.romaneio.veiculo.tipo,
        data.romaneio.possuiAlertasNaoLidos,
        data.romaneio.statusEntrega,
        data.romaneio.cancelamento
      )}
      alt=""
      className="imgTruck"
    />
  );
};

export default VehicleIcon;
