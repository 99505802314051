import React, { useState } from 'react';
import './style.css';


interface Props {
  children?: JSX.Element;
  activeTooltip?: string;
  cargasAccumulator?: number;
  vehicleAccumulator?: number;
  porcentageAccumulator: number;
  typeTotalizator?: string;
}

const TotalizatorTooltip: React.FC<Props> = ({
  children,
  activeTooltip,
  cargasAccumulator,
  vehicleAccumulator,
  porcentageAccumulator,
  typeTotalizator,
}: Props) => {
  let timeout: any;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      {active && activeTooltip === 'active' && (
        <div className={`Tooltip-Tip-Totalizator ${'bottom'}`}>
          <div
            className={
              porcentageAccumulator < 100
                ? 'tagTotalizatorPrimary'
                : 'tagTotalizatorSecondary'
            }
          >
            {typeTotalizator}
          </div>
          <div className="contentTotalizator">
            <div className="textTotalizator">
              <span className="titleTotalizator">Veiculos</span>
              <span className="separatorTotalizator">
                {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                {vehicleAccumulator}
                {typeTotalizator === 'Peso' ? (
                  'Kg'
                ) : typeTotalizator === 'Cubagem' ? (
                  'm³'
                ) : (
                  <></>
                )}
              </span>
            </div>
            <div className="textTotalizator separatorTotalizator">X</div>
            <div className="textTotalizator">
              <span className="titleTotalizator">Cargas</span>
              <span className="separatorTotalizator">
                {typeTotalizator === 'Valor' ? 'R$' : <></>}{' '}
                {cargasAccumulator}
                {typeTotalizator === 'Peso' ? (
                  'Kg'
                ) : typeTotalizator === 'Cubagem' ? (
                  'm³'
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
          <div className="totalizatorFooter">
            <span
              className={
                porcentageAccumulator < 100
                  ? 'textFooter primary'
                  : 'textFooter secondary'
              }
            >{`${porcentageAccumulator}%`}</span>
            <span
              className={
                porcentageAccumulator < 100
                  ? 'textFooter primary'
                  : 'textFooter secondary'
              }
            >
              da capacidade
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalizatorTooltip;