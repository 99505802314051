import { number, object, string } from 'yup';

export default object().shape({
  tipo: string().required('* Informe o tipo do veículo!'),
  descricao: string()
    .required('* Informe a descrição!')
    .min(5, '* Mínimo de 5 caracteres!')
    .max(100, '* Máximo de 100 caracteres!'),
  placa: string().required('* Informe a placa do veículo!'),
  filialId: string().required('* Informe a filial do veículo!'),
  veiculoProprio: string().required('* Informe se possui veículo próprio!'),
  marcaModelo: string().required('* Informe a marca ou modelo do veículo!'),
  ano: number().required('* Informe o ano do veículo!'),
  anoFabricacao: number().required('* Informe o ano de fabricação do veículo!'),
  peso: number().required('* Informe o peso máximo do veículo!'),
  quantidadeAtendimento: number().required(
    '* Informe a quantidade máxima de atendimentos do veículo!'
  ),
  cubagem: number().required('* Informe a cubagem máxima!'),
  valor: number().required('* Informe o valor máximo!'),
});
