// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagify {
  padding: 10px;
  border: 2px solid rgba(0, 48, 73, 0.2);
  max-height: 200px;
  height: 129px;
  background-color: #fff;
  border-radius: 10px;
  font-family: "Lato" !important;
  font-size: 14px !important;
  color: #003049 !important;
}

.tagify__tag > div {
  background-color: #E1E0E0;
  border-radius: 129px !important;
  color: #003049 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/InputTag/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sCAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,0BAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,+BAAA;EACA,yBAAA;AACF","sourcesContent":[".tagify {\n  padding: 10px;\n  border: 2px solid rgba(0, 48, 73, 0.2);\n  max-height: 200px;\n  height: 129px;\n  background-color: #fff;\n  border-radius: 10px;\n  font-family: 'Lato' !important;\n  font-size: 14px !important;\n  color: #003049 !important;\n}\n\n.tagify__tag>div{\n  background-color: #E1E0E0;\n  border-radius: 129px !important;\n  color: #003049 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
