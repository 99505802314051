import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SelectCustom from 'components/SelectCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import { responsiveSize } from 'styles/responsive';
// import CheckBox from '../../../pages/ControlTower/ListAttendances/Filters/CheckBox';
import CheckBox from 'components/Sheduling/Abonar/CheckBox';
import formValidator from 'components/Sheduling/Abonar/formValidator';
import formValidatorMultiple from 'components/Sheduling/Abonar/formValidatorMultiple';
import formValidatorPartial from 'components/Sheduling/Abonar/formValidatorPartial';
import Toogle from 'components/Sheduling/Abonar/Toogle';

interface Props {
  onClear?: any;
}

interface FormValues {
  type: string;
  resourceId?: string;
  reasonId: string;
  anotherReason: string;
  dateStart: string;
  dateEnd: string;
  time: string;
}


const Abonar: React.FC<Props> = ({ onClear }) => {
  const [checkedCurrent, setCheckedCurrent] = useState<boolean>(true);
  const [checkedDayMultiple, setCheckedDayMultiple] = useState<boolean>(false);
  const [checkedPartial, setCheckedPartial] = useState<boolean>(false);
  const [checkedReason, setCheckedReason] = useState<boolean>(false);
  const [resources, setResources] = useState<any>([]);
  const [reasons, setReasons] = useState<any>([]);

  const { t } = useTranslation();


  const getResourcers = useCallback(async () => {
    try {
      const subsidiaryId = sessionStorage.getItem('subsidiary');

      const data = {
        filters: [
          {
            field: 'subsidiary.id',
            operation: 'EQUAL',
            value: subsidiaryId
          }
        ],
        orders: [],
        page: 0,
        size: 100,
      };
      const response = await api.post('/workday/resources/pageable', data);

      setResources(response.data.content)
      
    }catch {}
  }, []);

  const getReasons = useCallback(async () => {
    try {
      const response = await api.get('/workday/reasons');

      setReasons(response.data)
    }catch{}
  }, []);

  const handleSubmit = useCallback(async (values: FormValues, reasonCheck: boolean) => {
    try {
      const data = {
        type: values.type,
        resourceId: values.resourceId,
        reasonId: reasonCheck ? null : values.reasonId,
        anotherReason: reasonCheck ? values.anotherReason : null,
        date: {
          start: values.dateStart,
          end: values.dateEnd.length > 0 ? values.dateEnd : null,
        },
        time: values.time,
      };

      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';


      const response = await api.post(`/workday/pcontrols/add?lang=${emailSendLanguage}`, data)

      if(response.status === 204) {
        FrontendNotification('Abono adicionado com sucesso!', NotificationType.SUCCESS);

        onClear();
      }
    }catch{}
  }, [])

  const initialValues: FormValues = {
    type: 'PAY_DEFAULT',
    resourceId: '',
    reasonId: '',
    anotherReason: '',
    dateStart: '',
    dateEnd: '',
    time: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: checkedPartial === true ? formValidatorPartial : checkedDayMultiple === true ? formValidatorMultiple : formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values, checkedReason),
  });

  useEffect(() => {
    getResourcers();
    getReasons();
  }, [getResourcers, getReasons])


  return (
    <CustomModal isOpen isClose={onClear} label="Abono" styles={{ maxWidth: 432 }}>
      <div style={{ marginTop: responsiveSize(40) }}>
        <Row>
          <Col md={12}>
            <SelectCustom id='resource' onChange={formik.handleChange('resourceId')} title="Funcionário">
              <option>Todos</option>

              {resources.map((item: any) => (
                <option label={item.name} value={item.id} />
              ))}
            </SelectCustom>
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col md={12}>
            <SelectCustom id='reason' onChange={formik.handleChange('reasonId')} title="Motivo">
              <option value="" label={t('common.select')}></option>
              {reasons.map((item: any) => (
                <option label={item.name} value={item.id} />
              ))}
            </SelectCustom>
          </Col>
        </Row>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
          <CheckBox
            active={checkedReason}
            label=""
            id="checkedReason"
            onChecked={() => setCheckedReason(!checkedReason)}
            styles={{}}
          />

          <span
            style={{
              fontFamily: 'Lato',
              fontSize: 14,
              color: '#003049',
              opacity: 0.6,
            }}
          >
            Outro motivo
          </span>
        </div>
        {checkedReason && <InputCustom id='anotherReason' onChange={formik.handleChange('anotherReason')} placeholder="" />}

        <Row style={{ marginTop: 20 }}>
          <Col md={3}>
            <Toogle
              active={checkedCurrent}
              label="Padrão"
              id='checkedCurrent'
              onChange={() => {
                formik.setFieldValue('type', 'PAY_DEFAULT')
                setCheckedCurrent(true);
                setCheckedDayMultiple(false);
                setCheckedPartial(false);
              }}
            />
          </Col>
          <Col md={5}>
            <Toogle
              active={checkedDayMultiple}
              label="Múltiplos dias"
              id='checkedDayMultiple'
              onChange={() => {
                formik.setFieldValue('type', 'PAY_MULTIPLE_DAYS')

                setCheckedDayMultiple(true);
                setCheckedCurrent(false);
                setCheckedPartial(false);
              }}
            />
          </Col>
          <Col md={4}>
            <Toogle
              active={checkedPartial}
              label="Parcial"
              id='checkedPartial'
              onChange={() => {
                formik.setFieldValue('type', 'PAY_PARTIAL')
                setCheckedPartial(true);
                setCheckedCurrent(false);
                setCheckedDayMultiple(false);
              }}
            />
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}
        >
          {checkedDayMultiple && (
            <Row>
              <Col>
                <InputCustom
                  id='dateStart'
                  onChange={formik.handleChange('dateStart')}
                  placeholder=""
                  type="date"
                  style={{ width: 125 }}
                  error={formik.errors.dateStart}
                  touched={formik.touched.dateStart}
                />
              </Col>

              <Col>
                <InputCustom
                  onChange={formik.handleChange('dateEnd')}
                  id='dateEnd'
                  placeholder=""
                  type="date"
                  style={{ width: 125 }}
                  error={formik.errors.dateEnd}
                  touched={formik.touched.dateEnd}
                />
              </Col>
            </Row>
          )}
          {checkedCurrent && (
            <Row>
              <Col>
                <InputCustom
                id='dateStart'
                  onChange={formik.handleChange('dateStart')}
                  placeholder=""
                  type="date"
                  style={{ width: 125 }}
                  error={formik.errors.dateStart}
                  touched={formik.touched.dateStart}
                />
              </Col>
            </Row>
          )}

          {checkedPartial && (
            <Row>
              <Col>
                <InputCustom
                  onChange={formik.handleChange('dateStart')}
                  placeholder=""
                  id='dateStart'
                  type="date"
                  style={{ width: 125 }}
                  error={formik.errors.dateStart}
                  touched={formik.touched.dateStart}
                />
              </Col>

              <Col>
                <InputCustom
                  onChange={formik.handleChange('time')}
                  id='time'
                  placeholder=""
                  type="time"
                  style={{ width: 60 }}
                  error={formik.errors.time}
                  touched={formik.touched.time}
                />
              </Col>
            </Row>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <button className="button" type='button' id='saveAbonar' onClick={() => formik.handleSubmit()}>Abonar</button>
        </div>
      </div>
    </CustomModal>
  );
};

export default Abonar;
