import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';

interface Props {
  onClear?: any;
  onConfirmAddress?: any;
}

const ConfirmationDriver: React.FC<Props> = ({
  onClear,
  onConfirmAddress,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  // const onUpdateAddress = useCallback(async () => {
  //   try {
  //     setLoading(true);

  //     let body;

  //     if (data[0].tipo === 'Entrega') {
  //       body = {
  //         enderecoLogradouro: dataAddress.enderecoLogradouro,
  //         enderecoBairro: dataAddress.enderecoBairro,
  //         enderecoCidade: dataAddress.enderecoCidade,
  //         enderecoUf: dataAddress.enderecoUf,
  //         enderecoNumero: dataAddress.enderecoNumero,
  //         enderecoCep: dataAddress.enderecoCep,
  //         enderecoLatitude: dataAddress.enderecoLatitude,
  //         enderecoLongitude: dataAddress.enderecoLongitude
  //       };
  //     } else {
  //       body = {
  //         enderecoLogradouroColeta: dataAddress.enderecoLogradouro,
  //         enderecoBairroColeta: dataAddress.enderecoBairro,
  //         enderecoCidadeColeta: dataAddress.enderecoCidade,
  //         enderecoUfColeta: dataAddress.enderecoUf,
  //         enderecoNumeroColeta: dataAddress.enderecoNumero,
  //         enderecoCepColeta: dataAddress.enderecoCep,
  //         enderecoLatitudeColeta: dataAddress.enderecoLatitude,
  //         enderecoLongitudeColeta: dataAddress.enderecoLongitude
  //       };        
  //     }

  //     const response = await api.patch(`/delivery/conhecimentosPre/${data[0].id}`, body);

  //     setLoading(false);
  //   } catch {}
  // }, []);

  const { t } = useTranslation();

  return (
    <div>
      <Loading loading={loading} />

      <CustomModal
        isOpen
        label={`${t('searchAddress.title')}?`}
        isClose={() => onClear()}
        styles={{ maxWidth: '50%' }}
      >
        <Row>
          <Col md={6} mt="4">
            <button
              type="button"
              className="buttonDriverCancel"
              onClick={() => {
                onClear();
              }}
            >
              <Trans i18nKey="searchAddress.actions.no" />
            </button>{' '}
          </Col>
          <Col md={6}>
            <button
              type="button"
              className="buttonDriverConfirm"
              onClick={() => {
                onClear();
                onConfirmAddress('MULTIPLE');
              }}
            >
              <Trans i18nKey="searchAddress.actions.yes" />
            </button>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};

export default ConfirmationDriver;
