import CargoPre from "interfaces/Delivery/CargoPre"

export type GroupedProps = {
    type: GroupedType
    rowData: CargoPre[]
    setReturnedRows: (rows: ReturnedRow[]) => void    
    setGroupedType: (type: GroupedType) => void
    setSelectedGroupedRow: (row: GroupedRow) => void
}

export enum GroupedType {
    NO_ACTION,
    REGION,
    ROUTE,
    SECTOR
} 

export type ReturnedRow = {
    color: string,
    rows: CargoPre[]
}

export enum People {
    DRIVER = "DRIVER",
    COLLABORATOR = "COLLABORATOR",
}

export type Team = {
    id: number
    nome: string
    type: People
}

export type GroupedRow = {
    licensePlate: string | null
    estimateDate: Date | null
    color: string
    grouper: string
    weight: number
    cubage: number
    value: number
    volume: number
    customer: number
    order: number
    rows: CargoPre[]
    routing: boolean
    simulation: string | null
    released: boolean
    created: boolean
    teamList: Team[]
    roundtrip: boolean
    destiny: string | null
}

export type Metric = {
    weight: number
    cubage: number
    value: number
    volume: number
    order: number
    customer: number
}

export type DetailedRow = {
    id: string
    clienteNome: string
    isErro: boolean
    tipo: string
    status: string    
    customerName: string
    customerReferenceId: string;
    addressStreet: string
    addressCity: string
    addressState: string
    addressNeighborhood: string
    addressNumber: string
    addressComplement: string
    addressZipcode: string
    enderecoLatitude: string
    enderecoLongitude: string
    weight: number
    cubage: number
    value: number
    volume: number
    referenceId: string
    docNumber: string
    route: string
    region: string
    section: string
    parentReferenceId: string
    obs: string;
    branchShipping: string;
    branchShippingReferenceId: string;
    reentrega: boolean;
}