import InputCustom from 'components/Input';
import LoadingSAP from 'components/IntegrationsBling/LoadingBling';
import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import { useFormik } from 'formik';
// import { Line } from 'pages/ControlTower/components/Table/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import formValidator from './formValidator';
import { Line } from './styles';

interface DataFilters {
  field: string;
  value: any;
  operation: string;
}

type FormValues = {
  uri: string;
  username: string;
  password: string;
  db: string;
  time: number;
};

interface Props {
  onClear: any;
}

const IntegrationsSAP: React.FC<Props> = ({ onClear }: Props) => {
  const [dataSAP, setDataSAP] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<DataFilters[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [dataSAPResult, setDataSAPResult] = useState();

  const getIntegrationSAP = useCallback(async () => {
    try {
      const currentFilters = [
        {
          field: 'type',
          value: 'SAP_B1',
          operation: 'EQUAL',
        },
      ];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPage || 0,
        size: 5,
      };
      const response = await api.post('/audit/integration/api/pageable', data);

      setDataSAP(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (err) {}
  }, [filters, currentPage]);

  function paginate(pageNumber: number) {
    setCurrentPage(pageNumber - 1);
  }

  useEffect(() => {
    getIntegrationSAP();
  }, [getIntegrationSAP]);

  const initialValues: FormValues = {
    uri: '',
    username: '',
    password: '',
    db: '',
    time: 0,
  };

  const handleSubmit = useCallback(async (values: FormValues) => {
    setLoading(true);

    sessionStorage.setItem('loadingBling', 'true');

    setActiveLoading(true);

    const dataSapForm = {
      tempo: values.time,
      host: values.uri.length === 0 ? null : values.uri,
      username: values.username.length === 0 ? null : values.username,
      password: values.password.length === 0 ? null : values.password,
      db: values.db.length === 0 ? null : values.db,
    };

    let urlSAPSicronize = '/integracao/tmsErp/sap/b1/invoices';

    if (dataSapForm.host !== null) {
      urlSAPSicronize = urlSAPSicronize.concat(`?host=${dataSapForm.host}`);
    }
    if (dataSapForm.username !== null) {
      urlSAPSicronize = urlSAPSicronize.concat(`&username=${dataSapForm.username}`);
    }
    if (dataSapForm.password !== null) {
      urlSAPSicronize = urlSAPSicronize.concat(
        `&password=${dataSapForm.password}`
      );
    }
    if (dataSapForm.db !== null) {
      urlSAPSicronize = urlSAPSicronize.concat(
        `&db=${dataSapForm.db}`
      );
    }
    if (dataSapForm.tempo !== null) {
      urlSAPSicronize = urlSAPSicronize.concat(
        `&days=${dataSapForm.tempo}`
      );
    }


    const response = await api.get(`${urlSAPSicronize}`);
    setStatusCode(response.status === 200 ? 200 : 400);
    setDataSAPResult(response.data);
    setTimeout(() => {
      setLoading(false);
    }, 2000);

  }, []);

  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values),
  });

  const getParametersSAP = useCallback(async () => {
    const response = await api.get('/cadastro/parametros/sap/b1');

    if (response.data.length > 0) {
      if(response.data[0].filtroDias !== null) {
        formik.setFieldValue('time', response.data[0].filtroDias)
      }
      if (response.data[0].host !== null) {
        formik.setFieldValue('uri', response.data[0].host);
      }
      if (response.data[0].username !== null) {
        formik.setFieldValue('username', response.data[0].username);
      }
      if (response.data[0].password !== null) {
        formik.setFieldValue('password', response.data[0].password);
      }
      if (response.data[0].db !== null) {
        formik.setFieldValue('db', response.data[0].db);
      }
    }
  }, []);

  useEffect(() => {
    getParametersSAP();
  }, [getParametersSAP]);

  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isIntegrationSAP
        isOpen
        isClose={() => onClear()}
        label={t('integrationsBling.menuTitle')}
        styles={{ maxWidth: '90%' }}
      >
        <>
          {activeLoading && (
            <LoadingSAP
              loading={loading}
              statusCode={statusCode}
              data={dataSAPResult}
              onClear={() => {
                setActiveLoading(!activeLoading);
                getIntegrationSAP();
              }}
            />
          )}
          <Row>
            <Col md={12}>
              <InputCustom
                title="Service Layer Url"
                onChange={formik.handleChange('uri')}
                placeholder=""
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
                value={formik.values.uri}
                touched={formik.touched.uri}
                error={formik.errors.uri}
              />
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <InputCustom
                title="Usuário"
                onChange={formik.handleChange('username')}
                placeholder=""
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
                value={formik.values.username}
                touched={formik.touched.username}
                error={formik.errors.username}
              />
            </Col>
            <Col md={2}>
              <InputCustom
                title="Senha"
                onChange={formik.handleChange('password')}
                placeholder=""
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
                value={formik.values.password}
                touched={formik.touched.password}
                error={formik.errors.password}
              />
            </Col>
            <Col md={2}>
              <InputCustom
                title="CompanyDB"
                onChange={formik.handleChange('db')}
                placeholder=""
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
                value={formik.values.db}
                touched={formik.touched.db}
                error={formik.errors.db}
              />
            </Col>
            <Col md={2}>
              <InputCustom
                type="number"
                title={t('integrationsBling.fields.days')}
                onChange={formik.handleChange('time')}
                placeholder=""
                value={formik.values.time}
                styleTitle={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  color: '#003049',
                }}
              />
            </Col>
            <Col md={2}>
              <button
                type="button"
                className="button"
                style={{ marginTop: 40 }}
                onClick={() => formik.handleSubmit()}
              >
                <Trans i18nKey="integrationsBling.buttonSicronize" />
              </button>
            </Col>
          </Row>
          <Line style={{ padding: 0, margin: 0, marginTop: 30 }} />

          <Logs
            data={dataSAP}
            totalPages={totalPages}
            page={currentPage}
            onPaginate={(page: number) => paginate(page)}
            onFilters={(date: string, status: string) => {
              setCurrentPage(0);
              if (date.length > 0 && status.length > 0) {
                setFilters([
                  {
                    field: 'type',
                    value: 'SAP_B1',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'dataCriacao',
                    value: `${date}T00:00:00`,
                    operation: 'GREATER_THAN_EQUAL',
                  },
                  {
                    field: 'dataCriacao',
                    value: `${date}T23:59:59`,
                    operation: 'LESS_THAN_EQUAL',
                  },
                  {
                    field: 'statusCode',
                    value: status,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (date.length > 0) {
                setFilters([
                  {
                    field: 'type',
                    value: 'SAP_B1',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'dataCriacao',
                    value: `${date}T00:00:00`,
                    operation: 'GREATER_THAN_EQUAL',
                  },
                  {
                    field: 'dataCriacao',
                    value: `${date}T23:59:59`,
                    operation: 'LESS_THAN_EQUAL',
                  },
                ]);
              } else if (status.length > 0) {
                setFilters([
                  {
                    field: 'type',
                    value: 'SAP_B1',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'statusCode',
                    value: status,
                    operation: 'EQUAL',
                  },
                ]);
              } else {
                setFilters([
                  {
                    field: 'type',
                    value: 'SAP_B1',
                    operation: 'EQUAL',
                  },
                ]);
              }
            }}
          />
        </>
      </CustomModal>
    </>
  );
};

export default IntegrationsSAP;
