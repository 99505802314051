import polyline from '@mapbox/polyline';
import Pagination from '@material-ui/lab/Pagination';
import CustomModal from 'components/ModalCustom';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import Leaflet from 'leaflet';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Map, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import api from 'services/api';
import getIconVehicle from 'shared/IconVehicles';
import TruckIcon from '../../../assets/images/map/truck.svg';
import pointFilial from '../../../assets/images/pointFilial.svg';
import Loading from '../../../core/common/Loading';
import Carousel from './Carousel';
import SkeletonLoading from './Skeleton';
import './style.scss';
import {
  ContainerToggle,
  SwitchChecked,
  SwitchUnchecked,
  ToggleSwitch,
} from './styles';

interface CheckedMap {
  checkedPlanning: boolean;
  checkedCoarse: boolean;
  checkedFence: boolean;
  checkedLow: boolean;
  checkedAttendance: boolean;
  checkedPosition: boolean;
}

interface CargoResponse {
  id: number;
  motorista: {
    latitude: number;
    longitude: number;
    filial: {
      endereco: {
        latitude: number;
        longitude: number;
      };
    };
  };
}

interface GetRoutesProps {
  motoristaId: string;
  idRomaneio: string;
  onClear: () => void;
  subsidiaryPosition: any;
}

const GetRoutes: React.FC<GetRoutesProps> = ({
  motoristaId,
  idRomaneio,
  onClear,
  subsidiaryPosition,
}: GetRoutesProps) => {
  const [modal] = useState<boolean>(true);
  let leafletMap: any = useRef();
  const [positionDriver, setPositionDriver] = useState<any>([]);
  const [zoom, setZoom] = useState(13);
  const [cargoData, setCargo] = useState<any>([]);
  const [stepsActual, setStepsActual] = useState<any>(null);
  const [multiplePoints, setMultiplePoints] = useState([]);
  const [pointsPlanning, setPointsPlanning] = useState([]);
  const [pointsCoarse, setPointsCoarse] = useState([]);
  const [knowledge, setKnowledge] = useState([]);
  const [fence, setFence] = useState('45');
  const [jsonOSRM, setJsonOSRM] = useState([]);
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [checked, setChecked] = useState<CheckedMap>({
    checkedPlanning: true,
    checkedCoarse: true,
    checkedFence: false,
    checkedLow: true,
    checkedAttendance: true,
    checkedPosition: false,
  });
  const [initialPosition, setInitialPosition] = useState<any>([]);
  const [listCheckinRomaneio, setListCheckinRomaneio] = useState<any>([]);
  const [subsidiaryPositionData, setSubsidiaryPositionData] = useState<any>([]);
  const [lastCommunication, setLastCommunication] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [dateInitial, setDateInitial] = useState('');
  const [dateFinal, setDateFinal] = useState('');

  const { t } = useTranslation();

  const getPolyline = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(
      `/delivery/romaneios/polyline/${idRomaneio}`
    );
    if (data && data.polyline !== null) {
      const routePolyline: any = polyline.decode(data.polyline);

      let pointsCoarsePadronize: any = [];

      setPointsPlanning(routePolyline);
      if (data.polylineRealized !== null) {
        setPointsCoarse(data.polylineRealized);
      }
    } else {
      FrontendNotification(t('common.routeNotExists'), NotificationType.ERROR);
    }
    setLoading(false);
  }, [idRomaneio]);

  const getDriverPosition = useCallback(async () => {
    try {
      const response = await api.get(
        `/delivery/motoristasPosicoes/redis/${motoristaId}`
      );

      setPositionDriver([response.data.latitude, response.data.longitude])
    } catch {}
  }, []);

  const getPositionDriver = useCallback(async () => {
    try {
      const dataRomaneio = {
        filters: [
          {
            field: 'id',
            value: idRomaneio,
            operation: 'EQUAL',
          },
        ],
      };
      const response: any = await api.post(
        '/delivery/romaneios/findByFilters',
        dataRomaneio
      );

      setCargo(response.data);
      setSubsidiaryPositionData([
        response.data[0].filial.endereco.latitude,
        response.data[0].filial.endereco.longitude,
      ]);

      setInitialPosition([
        response.data[0].filial.endereco.latitude,
        response.data[0].filial.endereco.longitude,
      ]);

      setKnowledge(response.data[0].conhecimentos);

      setZoom(30);

      const routePolyline: any = polyline.decode(
        response.data[0].routeOptmi.polyline
      );
      setJsonOSRM(routePolyline);
    } catch (err) {}
  }, []);

  const getPositions = useCallback(
    async (filtersPositions: any, pageNumber?: any) => {
      try {
        setLoading(true);
        const data = {
          filters:
            filtersPositions && filtersPositions.length > 0
              ? filtersPositions
              : filters,
          orders: [
            {
              field: 'dataHora',
              direction: 'DESC',
            },
          ],
          page: pageNumber ? pageNumber : currentPage || 0,
          size: 100,
        };
        const response = await api.post(
          '/delivery/motoristasPosicoes/pageable',
          data
        );
        setTotalPages(response.data.totalPages);
        setLastCommunication(response.data.content);

        setLoading(false);
      } catch {}
    },
    [filters, currentPage]
  );

  const getCheckinCargo = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'romaneio.id',
            value: idRomaneio,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/romaneiosCheckins/findByFilters',
        data
      );
      setListCheckinRomaneio(response.data);

      if (response.data.length > 0) {
        const filtersPosition: any = [
          {
            field: 'motorista.id',
            value: motoristaId,
            operation: 'EQUAL',
          },
          {
            field: 'dataHora',
            value: response.data[0].horarioInicial,
            operation: 'GREATER_THAN_EQUAL',
          },
        ];

        setDateInitial(response.data[0].horarioInicial);

        setFilters(filtersPosition);

        getPositions(filtersPosition);
      }
    } catch {}
  }, []);

  useEffect(() => {
    getPositionDriver();
    getDriverPosition();
    getPolyline();
  }, [getPositionDriver, getPolyline, getDriverPosition]);

  const pinIcon = new Leaflet.Icon({
    iconUrl:
      cargoData.length === 0
        ? TruckIcon
        : getIconVehicle(
            cargoData[0].veiculo.tipo,
            false,
            false,
            cargoData[0].statusEntrega
          ),
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  function getHTMLPositionIcon() {
    const htmlIconPosition = `<div style="background-color: #006CEA; width: 20px; height: 20px; border-radius: 100%;"></div>`;

    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: htmlIconPosition,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  function getHtmlPositionIcon(
    number: number,
    foraRaio: boolean,
    posicaoBaixa: boolean
  ) {
    let htmlIconPosition;
    if (posicaoBaixa) {
      if (foraRaio) {
        htmlIconPosition = `<div class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i class='marker-text-current-raio'>${number}º</i></div></div>`;
      } else {
        htmlIconPosition = `<div style="border: 5px solid #08B639;" class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i style="color: #08B639;" class='marker-text-current-raio'>${number}º</i></div></div>`;
      }
    } else {
      htmlIconPosition = `<div style="border: 5px solid #6369d1;" class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i style="color: #6369d1;" class='marker-text-current'>${number}º</i></div></div>`;
    }
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: htmlIconPosition,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  function getHtmlPositionIconMultiple(
    number: number,
    foraRaio: boolean,
    posicaoBaixa: any
  ) {
    let htmlIconPositionMultiple;
    if (posicaoBaixa) {
      if (foraRaio) {
        htmlIconPositionMultiple = `<div class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i class='marker-text-current-raio'>+${number}</i></div></div>`;
      } else {
        htmlIconPositionMultiple = `<div style="border: 5px solid #08B639;" class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i style="color: #08B639;" class='marker-text-current-raio'>+${number}</i></div></div>`;
      }
    } else {
      htmlIconPositionMultiple = `<div style="border: 5px solid #FC8800;" class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i style="color: #FC8800;" class='marker-text-current'>+${number}</i></div></div>`;
    }
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: htmlIconPositionMultiple,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }
  function findPositionKnowledge(latitude: any, longitude: any) {
    let positionMultiple = knowledge.filter(
      (itemPosition: any) =>
        itemPosition.endereco.latitude === latitude &&
        itemPosition.endereco.longitude === longitude
    );

    return positionMultiple;
  }

  const PointFilial = new Leaflet.Icon({
    iconUrl: pointFilial,
    iconSize: [90, 90],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  return (
    <>
      <CustomModal
        isOpen={modal}
        styles={{ maxHeight: '67vh', minWidth: '80%' }}
        label={t('cargoMap.title')}
        isClose={onClear}
      >
        <Loading loading={loading} />
        <ContainerToggle>
          <div>
            <p>
              <Trans i18nKey="cargoMap.switch.planning" />
            </p>
            <ToggleSwitch
              onClick={() =>
                setChecked({
                  ...checked,
                  checkedPlanning: !checked.checkedPlanning,
                })
              }
            >
              {checked.checkedPlanning ? (
                <SwitchChecked />
              ) : (
                <SwitchUnchecked />
              )}
            </ToggleSwitch>
          </div>
          <div id="container">
            <p>
              <Trans i18nKey="cargoMap.switch.attendance" />
            </p>
            <ToggleSwitch
              onClick={() =>
                setChecked({
                  ...checked,
                  checkedAttendance: !checked.checkedAttendance,
                })
              }
            >
              {checked.checkedAttendance ? (
                <SwitchChecked />
              ) : (
                <SwitchUnchecked />
              )}
            </ToggleSwitch>
          </div>
          {/* <div id="container">
          <p>
            <Trans i18nKey="cargoMap.switch.fence" />
          </p>
          <ToggleSwitch
            onClick={() =>
              setChecked({
                ...checked,
                checkedFence: !checked.checkedFence,
              })
            }
          >
            {checked.checkedFence ? (
              <SwitchChecked type="fence" />
            ) : (
              <SwitchUnchecked />
            )}
          </ToggleSwitch>{' '}
        </div> */}
          <div id="container">
            <p>
              <Trans i18nKey="cargoMap.switch.coarse" />
            </p>
            <ToggleSwitch
              onClick={() =>
                setChecked({
                  ...checked,
                  checkedCoarse: !checked.checkedCoarse,
                })
              }
            >
              {checked.checkedCoarse ? (
                <SwitchChecked type="coarse" />
              ) : (
                <SwitchUnchecked />
              )}
            </ToggleSwitch>
          </div>
          <div id="container">
            <p>
              <Trans i18nKey="cargoMap.switch.low" />
            </p>
            <ToggleSwitch
              onClick={() =>
                setChecked({
                  ...checked,
                  checkedLow: !checked.checkedLow,
                })
              }
            >
              {checked.checkedLow ? (
                <SwitchChecked type="coarse" />
              ) : (
                <SwitchUnchecked />
              )}
            </ToggleSwitch>
          </div>
          <div id="container">
            <p>Posição</p>
            <ToggleSwitch
              onClick={() => {
                if (checked.checkedPosition === false) {
                  getCheckinCargo();
                }

                setChecked({
                  ...checked,
                  checkedPosition: !checked.checkedPosition,
                });
              }}
            >
              {checked.checkedPosition ? (
                <SwitchChecked type="position" />
              ) : (
                <SwitchUnchecked />
              )}
            </ToggleSwitch>
          </div>
        </ContainerToggle>
        {initialPosition.length > 0 ? (
          <div className="mapWrapper">
            <div
              className="mapColumn"
              style={{ width: checked.checkedPosition ? '75%' : '100%' }}
            >
              <Map
                ref={m => {
                  leafletMap = m;
                }}
                style={{
                  width: '100%',
                  maxHeight: '70vh',
                  cursor: 'pointer',
                }}
                center={initialPosition}
                zoom={zoom}
                zoomControl
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {positionDriver.length > 0 && (
                  <Marker
                    position={positionDriver}
                    icon={pinIcon}
                    // data={point}
                  />
                )}
                {knowledge.length > 0 &&
                  knowledge.map((item: any) => (
                    <>
                      {checked.checkedLow && (
                        <>
                          {item.latitude !== null && (
                            <Marker
                              key={`marker-${item.id}`}
                              position={[item.latitude, item.longitude]}
                              icon={
                                findPositionKnowledge(
                                  item.latitude,
                                  item.longitude
                                ).length > 1
                                  ? getHtmlPositionIconMultiple(
                                      findPositionKnowledge(
                                        item.latitude,
                                        item.longitude
                                      ).length,
                                      item.baixaForaRaio,
                                      true
                                    )
                                  : getHtmlPositionIcon(
                                      item.ordemEntrega,
                                      item.baixaForaRaio,
                                      true
                                    )
                              }
                              onClick={(e: any) => {
                                setStepsActual(0);
                                setMultiplePoints([]);
                                setMultiplePoints(
                                  findPositionKnowledge(
                                    e.target.options.position[0],
                                    e.target.options.position[1]
                                  )
                                );
                              }}
                            />
                          )}
                        </>
                      )}

                      {checked.checkedPosition && (
                        <React.Fragment>
                          {lastCommunication.length > 0 &&
                            lastCommunication.map((item: any) => (
                              <Marker
                                key={`marker-${item.id}`}
                                position={[item.latitude, item.longitude]}
                                icon={getHTMLPositionIcon()}
                              />
                            ))}
                        </React.Fragment>
                      )}

                      {checked.checkedAttendance && (
                        <Marker
                          key={`marker-${item.id}`}
                          position={[
                            item.endereco.latitude,
                            item.endereco.longitude,
                          ]}
                          icon={
                            findPositionKnowledge(
                              item.endereco.latitude,
                              item.endereco.longitude
                            ).length > 1
                              ? getHtmlPositionIconMultiple(
                                  findPositionKnowledge(
                                    item.endereco.latitude,
                                    item.endereco.longitude
                                  ).length,

                                  item.baixaForaRaio,
                                  false
                                )
                              : getHtmlPositionIcon(
                                  item.ordemEntrega,
                                  item.baixaForaRaio,
                                  false
                                )
                          }
                          onClick={(e: any) => {
                            setStepsActual(0);
                            setMultiplePoints([]);
                            setMultiplePoints(
                              findPositionKnowledge(
                                e.target.options.position[0],
                                e.target.options.position[1]
                              )
                            );
                          }}
                        >
                          <Popup className="popupRoutering">
                            <Carousel data={multiplePoints} />
                          </Popup>
                        </Marker>
                      )}
                    </>
                  ))}

                {subsidiaryPositionData.length > 0 && (
                  <Marker
                    // key={`marker-${point.id}`}
                    position={subsidiaryPositionData}
                    icon={PointFilial}
                  />
                )}
                <>
                  {checked.checkedFence ? (
                    <Polyline
                      weight={22}
                      color="#FC880040"
                      positions={[pointsPlanning]}
                    />
                  ) : null}
                  {checked.checkedPlanning ? (
                    <Polyline
                      lineCap="square"
                      dashArray="5, 10"
                      color="#6369D1"
                      positions={pointsPlanning}
                    />
                  ) : null}
                </>
                {checked.checkedCoarse ? (
                  <>
                    {pointsCoarse.map(item => (
                      <Polyline
                        color="#08B639"
                        positions={polyline.decode(item)}
                      />
                    ))}
                  </>
                ) : null}
              </Map>
            </div>
            {checked.checkedPosition && (
              <React.Fragment>
                {loading ? (
                  <SkeletonLoading />
                ) : (
                  <div className="listCommunication">
                    <span className="textPosition">Posições</span>
                    <div className="filterCommunication">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          maxHeight: 50,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '33.3%',
                          }}
                        >
                          <input
                            type="datetime-local"
                            className="inputDateCommunication"
                            onChange={e => setDateInitial(e.target.value)}
                            value={dateInitial}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: 15,
                            width: '33.3%',
                          }}
                        >
                          <input
                            type="datetime-local"
                            className="inputDateCommunication"
                            onChange={(e: any) => setDateFinal(e.target.value)}
                            value={dateFinal}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '33.3%',
                          }}
                        >
                          <div style={{ marginBottom: 40 }}>
                            <button
                              type="button"
                              className="button"
                              onClick={() => {
                                let initialDate = new Date(dateInitial);
                                let finalDate = new Date(dateFinal);
                                let dateNow = new Date();

                                if (listCheckinRomaneio.length > 0) {
                                  console.log('initial', initialDate);
                                  console.log('checkin', initialDate);
                                  let checkinCargoDate = new Date(
                                    listCheckinRomaneio[0].horarioInicial
                                  );
                                  if (finalDate >= initialDate) {
                                    console.log('entrou');
                                    const formatInitialDate = format(
                                      new Date(initialDate),
                                      'yyyy-MM-dd',
                                      {
                                        locale: pt,
                                      }
                                    );
                                    const formatInitialHourDate = format(
                                      new Date(initialDate),
                                      'HH:mm:ss',
                                      {
                                        locale: pt,
                                      }
                                    );
                                    const formatFinalDate = format(
                                      new Date(finalDate),
                                      'yyyy-MM-dd',
                                      {
                                        locale: pt,
                                      }
                                    );
                                    const formatFinalHourDate = format(
                                      new Date(finalDate),
                                      'HH:mm:ss',
                                      {
                                        locale: pt,
                                      }
                                    );
                                    const newFiltersPosition: any = [
                                      {
                                        field: 'motorista.id',
                                        value: motoristaId,
                                        operation: 'EQUAL',
                                      },
                                      {
                                        field: 'dataHora',
                                        value: `${formatInitialDate}T${formatInitialHourDate}`,
                                        operation: 'GREATER_THAN_EQUAL',
                                      },
                                      {
                                        field: 'dataHora',
                                        value: `${formatFinalDate}T${formatFinalHourDate}`,
                                        operation: 'LESS_THAN_EQUAL',
                                      },
                                    ];
                                    setFilters(newFiltersPosition);
                                    getPositions(
                                      newFiltersPosition,
                                      currentPage
                                    );
                                  } else {
                                    FrontendNotification(
                                      t('routes.pointsValidateField'),
                                      NotificationType.WARNING
                                    );
                                  }
                                } else {
                                  const formatInitialDate = format(
                                    new Date(initialDate),
                                    'yyyy-MM-dd',
                                    {
                                      locale: pt,
                                    }
                                  );
                                  const formatInitialHourDate = format(
                                    new Date(initialDate),
                                    'HH:mm:ss',
                                    {
                                      locale: pt,
                                    }
                                  );
                                  const formatFinalDate = format(
                                    new Date(finalDate),
                                    'yyyy-MM-dd',
                                    {
                                      locale: pt,
                                    }
                                  );
                                  const formatFinalHourDate = format(
                                    new Date(finalDate),
                                    'HH:mm:ss',
                                    {
                                      locale: pt,
                                    }
                                  );
                                  const newFiltersPosition: any = [
                                    {
                                      field: 'motorista.id',
                                      value: motoristaId,
                                      operation: 'EQUAL',
                                    },
                                    {
                                      field: 'dataHora',
                                      value: `${formatInitialDate}T${formatInitialHourDate}`,
                                      operation: 'GREATER_THAN_EQUAL',
                                    },
                                    {
                                      field: 'dataHora',
                                      value: `${formatFinalDate}T${formatFinalHourDate}`,
                                      operation: 'LESS_THAN_EQUAL',
                                    },
                                  ];
                                  setFilters(newFiltersPosition);
                                  getPositions(newFiltersPosition, currentPage);
                                }
                              }}
                              style={{
                                width: 50,
                                position: 'relative',
                                top: -21,
                                left: 25,
                                fontSize: 14,
                              }}
                            >
                              Filtrar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="boxCommunication">
                      {lastCommunication.map((item: any) => (
                        <div
                          style={{
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setInitialPosition([item.latitude, item.longitude]);
                            setZoom(20);
                          }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              right: 0,
                              marginTop: 5,
                              background: '#006CEA',
                              width: 12,
                              height: 12,
                              borderRadius: '100%',
                            }}
                          />
                          <span className="labelCommunication">Data hora</span>
                          <span className="content">
                            {format(
                              new Date(item.dataHora),
                              'dd/MM/yyyy HH:mm:ss',
                              {
                                locale: pt,
                              }
                            )}
                          </span>
                          <span
                            className="labelCommunication"
                            style={{ marginTop: 10 }}
                          >
                            Endereço
                          </span>
                          <span className="content"> --- </span>
                          <div className="linePosition" />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {checked.checkedPosition && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: -10,
                  right: 10,
                  marginTop: 10,
                  marginBottom: 20,
                }}
              >
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e: any, page: number) => {
                    setCurrentPage(page - 1);
                    getPositions(filters, page - 1);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </CustomModal>
    </>
  );
};

export default GetRoutes;
