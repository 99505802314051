import React, { useCallback, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import permissionMenu from 'shared/Permission';
import { resetCargasAccumulator } from 'store/modules/accumulator/actions';
import checkIcon from '../../../../assets/images/checkIcon.png';
import erroIcon from '../../../../assets/images/erroIcon.png';
import iconFilterCSV from '../../../../assets/images/iconFilterCSV.svg';
import iconFilterCSVLight from '../../../../assets/images/iconFilterCSVLight.svg';
import successIconTable from '../../../../assets/images/successIconTable.png';
import Header from '../Header';
import EditMode from './EditMode';
import FilterTooltip from './Filters/FilterTooltip';
import FilterTooltipError from './Filters/FilterTooltipError';
import OrderColumn from './OrderColumn';
import { Container } from './styles';
import './styles.scss';

interface IErrorColumn {
  message: string;
}

interface IColumns {
  data: string | number | boolean | null;
  description: string;
  error: IErrorColumn | null;
  field: string;
  id: string;
  length: number | null;
  name: string;
  type: string;
}

export interface IElementsCSV {
  columns: IColumns[];
  activeAll: boolean;
}

interface IHeadersCSV {
  id: string;
  label: string;
}

interface IParameters {
  roteirizacaoAtiva?: boolean;
}

interface Props {
  dataElements?: IElementsCSV[] | any;
  dataFiltersElements?: any;
  parameters?: IParameters[];
  dataHeaders?: IHeadersCSV[];
  onCheckAdd?: any;
  onUpdateSelected?: any;
  totalPages?: number;
  onPaginate?: any;
  onExecuteFilterMultiple?: any;
  onUpdateSequence?: any;
  onUpdateAddressTable?: any;
  onChangeFilters?: any;
  onOperationFilter?: any;
  onFilter?: any;
  onFilterCustom?: any;
  onUpdateRowTable?: any;
  onFilterError: (text: string) => void;
  onFilterSelected: (idsSelected: any[]) => void;
  onSelectAll?: any;
  onModalAddress?: any;
  onChangeDriver?: any;
  onChangeVehicles?: any;
  onPageSize?: any;
  onClearHeader?: any;
  onClearFiltersHeader?: any;
  onRemove?: any;
  onRemoveAll?: any;
  onRemoveSelectAll?: any;
  vehiclesData?: any;
  onClearTable?: any;
  onChangeVehiclesTable: any;
  onUpdateShipmentSelected: () => void;
  totalElements: number;
}

const Table: React.FC<Props> = ({
  dataElements,
  dataHeaders,
  parameters,
  onCheckAdd,
  totalPages,
  onPaginate,
  onExecuteFilterMultiple,
  onUpdateSequence,
  onUpdateAddressTable,
  onChangeFilters,
  onOperationFilter,
  onFilter,
  dataFiltersElements,
  onFilterCustom,
  onUpdateRowTable,
  onFilterError,
  onSelectAll,
  onModalAddress,
  onChangeDriver,
  onChangeVehicles,
  onChangeVehiclesTable,
  onPageSize,
  onClearHeader,
  onClearFiltersHeader,
  onRemove,
  onRemoveAll,
  onRemoveSelectAll,
  vehiclesData,
  onClearTable,
  onUpdateSelected,
  onFilterSelected,
  onUpdateShipmentSelected,
  totalElements,
}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [handleIndex, setHandleIndex] = useState<number | null>(null);
  const [dataColumn, setDataColumn] = useState(dataHeaders);
  const [editMode, setEditMode] = useState<any>({
    status: false,
    rowKey: null,
    rowId: null,
    columnId: null,
    index: null,
  });
  const [valueRow, setValueRow] = useState<string | null>(null);
  const [valueRowUpdated, setValueRowUpdated] = useState(null);
  const [activeTooltipFilter, setActiveTooltipFilter] = useState<boolean>(
    false
  );
  const [coordenatesFilter, setCoordenatesFilter] = useState<any>({});
  const [activeTooltipFilterError, setActiveTooltipFilterError] = useState<
    boolean
  >(false);
  const [coordenatesFilterError, setCoordenatesFilterError] = useState<any>({});
  const [dataFilterField, setDataFilterField] = useState<any>({});
  const [dataFilterFieldMulti, setDataFilterFieldMulti] = useState([]);
  const [active, setActive] = useState<boolean>(false);
  const [currentFilters, setCurrentFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getSubsidiaryId = useSelector((state: any) => state.map.subsidiaryId);
  const userData = useSelector((state: any) => state.auth.user);


  const { t } = useTranslation();

  const dispatch = useDispatch();

  const useStyles = makeStyles((theme: any) => ({
    root: {
      '& > *': {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  }));

  const classes = useStyles();

  function findErrorsColumn(columns: any) {
    const data = columns.find((item: any) => item.error !== null);
    return data;
  }

  const onEditRow = (
    id: string | number | boolean | null,
    value: any,
    index: number,
    indexRow: number
  ) => {
    setEditMode({
      status: true,
      rowKey: id,
      columnId: indexRow,
      rowId: index,
      index,
    });

    setValueRow(value);
    setValueRowUpdated(value);
  };

  const getFilterColumn = useCallback(
    async (
      indexColumn: number,
      subsidiaryColumn: string,
      columnName: string
    ) => {
      try {
        setLoading(true);
        const subsidiaryColumnId = sessionStorage.getItem('subsidiary');

        const response = await api.get(
          `/delivery/csvMapping/filter/column/${subsidiaryColumnId}/${columnName}`
        );

        const mappingFilterColumn = response.data.values.map((item: any) => {
          return `${item}`;
        });

        const filterMappingColumn = mappingFilterColumn.filter(
          (item: any) => item.length > 0
        );

        setDataFilterFieldMulti(filterMappingColumn);

        setLoading(false);
      } catch (err) {}
    },
    []
  );

  const onFilterMultiple = useCallback(async (filters, dataFilterColumn) => {
    try {
      setLoading(true);

      onUpdateSelected();

      console.log('filters', filters);

      let currentFiltersColumn = filters === null ? [] : filters;

      const valuesResult = dataFilterColumn.filters[0].values.map(
        (item: string | number) =>
          dataFilterColumn.type === 'NUMBER' ? Number(item) : item
      );

      console.log(valuesResult);

      const mappingFilterResult = {
        column: dataFilterColumn.columnId,
        values: valuesResult,
        operation: 'IN',
        action: 'ADD',
      };

      const isPresentFilter = currentFiltersColumn.find(
        (itemFilters: any) => itemFilters.column === dataFilterColumn.columnId
      );

      const findIndexPresentFilter = currentFiltersColumn
        .map((itemFilter: any, indexFilter: any) =>
          itemFilter.column === dataFilterColumn.columnId ? indexFilter : -1
        )
        .filter((filterColumn: any) => filterColumn !== -1);

      console.log(findIndexPresentFilter);

      if (valuesResult.length > 0 && !isPresentFilter) {
        currentFiltersColumn.push(mappingFilterResult);
      } else if (valuesResult.length > 0 && isPresentFilter) {
        currentFiltersColumn[findIndexPresentFilter[0]].values = valuesResult;
      } else {
        const resultEmpty = currentFiltersColumn.filter(
          (item: any) => item.column !== dataFilterColumn.columnId
        );

        currentFiltersColumn = resultEmpty;
      }

      const data = {
        columns: currentFiltersColumn,
      };

      const response = await api.post(
        '/delivery/csvMapping/filter/column',
        data
      );

      getCurrentFilters();

      setLoading(false);

      onExecuteFilterMultiple();
    } catch {}
  }, []);

  const getCurrentFilters = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/delivery/csvMapping/filter/column');

      setCurrentFilters(response.data.columns);

      setLoading(false);
    } catch {}
  }, []);

  const getHeaders = useCallback((elements, headers) => {
    if (elements.length > 0) {
      let dataIndexes = elements[0].columns
        .map((item: any, i: number) =>
          item.id === 'deliveryOrder' ||
          item.id === 'docNumber' ||
          item.id === 'referenceId' ||
          item.id === 'creationDate' ||
          item.id === 'estimateDate' ||
          item.id === 'address1' ||
          item.id === 'address2' ||
          item.id === 'addressZipcode' ||
          item.id === 'contactPhone' ||
          item.id === 'timeWindowOpen' ||
          item.id === 'timeWindowClose' ||
          item.id === 'boxesQty' ||
          item.id === 'cubage' ||
          item.id === 'weight' ||
          item.id === 'value' ||
          item.id === 'tag'
            ? item.name
            : -1
        )
        .filter((index: any) => index !== -1);
      const mappingHeaders = headers.map(
        (itemHeader: any, indexHeader: number) => {
          return {
            name: itemHeader.label,
            id: indexHeader,
            columnId: itemHeader.id,
            field:
              elements[0].columns[indexHeader].field === 'dataEmissao'
                ? 'dataEmissaoConv'
                : elements[0].columns[indexHeader].field === 'dataPrevisao'
                ? 'dataPrevisaoConv'
                : elements[0].columns[indexHeader].field === "tag" ? "etiqueta": elements[0].columns[indexHeader].field,
            type: elements[0].columns[indexHeader].type,
            active: false,
            isMulti: dataIndexes.find(
              (findItem: any) => findItem === itemHeader.label
            )
              ? 'false'
              : 'true',
            options: elements.map((itemElement: any) => {
              const getIndexColumn = itemElement.columns
                .map((itemColumn: any, i: number) =>
                  itemColumn.name === itemHeader.label ? i : -1
                )
                .filter((itemFilterColumn: any) => itemFilterColumn !== -1);
              return itemElement.columns[getIndexColumn[0]].data;
            }),
            filters: [
              {
                field: elements[0].columns[indexHeader].field,
                values: [],
                operation: 'EQUAL',
              },
            ],
          };
        }
      );

      setDataColumn(mappingHeaders);
    }
  }, []);

  const updateSequence = useCallback(
    (elements, from, to) => {
      elements.splice(to, 0, elements.splice(from, 1)[0]);

      const mappingSequence = elements.map((item: any, index: number) => {
        item.deliveryOrder = index + 1;

        return {
          ...item,
        };
      });

      onUpdateSequence(mappingSequence);
    },
    [dataColumn]
  );

  const onClearFilter = useCallback(async (filterClear?: any) => {
    try {
      const response = await api.delete('/delivery/csvMapping/filter/column');
      onClearTable(filterClear);

      dispatch(resetCargasAccumulator());

      return response;
    } catch {}
  }, []);

  function defineIsMultiFilter(name: string) {
    let isMulti = false;
    switch (name) {
      case 'Id':
        isMulti = false;
        break;
      case 'ID referência':
        isMulti = false;
        break;
      case 'Nome do Cliente':
        isMulti = false;
        break;
      case 'Id Ref Cliente':
        isMulti = false;
        break;
      case 'Ordem entrega':
        isMulti = false;
        break;
      case 'Número doc':
        isMulti = false;
        break;
      case 'Data emissão':
        isMulti = false;
        break;
      case 'Data previsão':
        isMulti = false;
        break;
      case 'Latitude':
        isMulti = false;
        break;
      case 'Longitude':
        isMulti = false;
        break;
      case 'Latitude (Coleta)':
        isMulti = false;
        break;
      case 'Longitude (Coleta)':
        isMulti = false;
        break;
      case 'Email':
        isMulti = false;
        break;
      case 'Telefone fixo':
        isMulti = false;
        break;
      case 'Logradouro':
        isMulti = false;
        break;
      case 'Complemento':
        isMulti = false;
        break;
      case 'Número':
        isMulti = false;
        break;
      case 'CEP':
        isMulti = false;
        break;
      case 'Telefone':
        isMulti = false;
        break;
      case 'horário início':
        isMulti = false;
        break;
      case 'horário fim':
        isMulti = false;
        break;
      case 'Qtd. Volumes':
        isMulti = false;
        break;
      case 'Cubagem':
        isMulti = false;
        break;
      case 'Peso':
        isMulti = false;
        break;
      case 'Valor':
        isMulti = false;
        break;
      case 'Etiqueta':
        isMulti = false;
        break;
      case 'Placa':
        isMulti  = false;
        break;
      default:
        isMulti = true;
        break;
    }

    return isMulti;
  }

  const onClear = useCallback(async (filterClear?: any) => {
    try {
      const response = await api.delete('/delivery/csvMapping/clear');

      onClearTable(filterClear);

      dispatch(resetCargasAccumulator());
    } catch {}
  }, []);

  useEffect(() => {
    getHeaders(dataElements, dataHeaders);
  }, [getHeaders]);

  useEffect(() => {
    onClearFilter();
    onClear();
    getCurrentFilters();
  }, [onClearFilter, getCurrentFilters, onClear]);

  return (
    <Container className="responsive">
      <Loading loading={loading} />

      <Header
        dataVehicles={vehiclesData}
        dataElementsHeader={dataElements}
        totalItems={totalElements}
        parameters={parameters}
        onUpdateSelected={onUpdateShipmentSelected}
        onSelectedShipment={(idsSelected: any[]) => {
          onFilterSelected(idsSelected);
        }}
        onSelectAll={() => onSelectAll()}
        onModalAddress={() => onModalAddress()}
        dataParameters={parameters}
        onChangeDriver={(idVehicle: number, nameVehicle: string) =>
          onChangeDriver(idVehicle, nameVehicle)
        }
        onUpdateVehicles={() => {
          onChangeVehiclesTable();
        }}
        onChangeVehicles={(vehiclesArr: any) => onChangeVehicles(vehiclesArr)}
        dataFilial={getSubsidiaryId}
        onPageSize={(value: number) => onPageSize(value)}
        onClearFilters={async () => {
          const dataFilterClear = [
            {
              field: 'filial',
              value: [Number(sessionStorage.getItem('subsidiary'))],
              operation: 'IN',
            },
          ];

          // onClearHeader(dataFilterClear);

          // onClearFiltersHeader(dataFilterClear);
          // setArrFilters(dataFilterClear);

          let dataHeaderColumn: any = dataColumn;

          const mappingClearHeaders: any = dataHeaderColumn.map(
            (item: any, indexHeaders: number) => {
              return {
                ...item,
                filters: [
                  {
                    field: item.filters[0].field,
                    values: [],
                    operation: 'EQUAL',
                  },
                ],
                active: false,
              };
            }
          );
          setDataColumn(mappingClearHeaders);

          setCurrentFilters([]);

          await onClearFilter(dataFilterClear);

          onClearHeader(dataFilterClear);
        }}
        onRemove={() => onRemove()}
        onRemoveAll={() => onRemoveAll()}
        onRemoveSelectAll={() => onRemoveSelectAll()}
      />
      {activeTooltipFilter && (
        <FilterTooltip
          onFilterMultiple={(
            dataFilterValue: any,
            operationFilterValue: any,
            indexFilterMultiple: number
          ) => {
            let activeHeader: any = dataColumn;

            activeHeader[dataFilterField.index].filters[
              indexFilterMultiple
            ].values = dataFilterValue;

            activeHeader[dataFilterField.index].active = true;

            setActive(!active);

            setDataColumn(activeHeader);

            const valuesResult = dataFilterValue.map((item: string | number) =>
              Number(item)
            );
            console.log('FIlter values', valuesResult);

            let selectedFilters = currentFilters;

            // setActiveTooltipFilter(!activeTooltipFilter)

            onFilterMultiple(currentFilters, dataFilterField);
          }}
          setFilter={(
            e: any,
            operationFilterValue: any,
            indexFilterValue: any,
            filterTypeValue: any
          ) => {
            let activeHeader: any = dataColumn;

            activeHeader[dataFilterField.index].filters[
              indexFilterValue
            ].value = `${
              dataFilterField.type === 'NUMBER'
                ? Number(e.target.value)
                : e.target.value
            }`;
            activeHeader[dataFilterField.index].filters[
              indexFilterValue
            ].operation = `${operationFilterValue}`;

            setDataColumn(activeHeader);

            onFilter(
              e,
              activeHeader,
              dataFilterField,
              operationFilterValue,
              indexFilterValue,
              dataElements
            );
          }}
          setOperationFilter={(
            e: any,
            operationFilterValue: any,
            indexFilterValue: any
          ) => {
            let changeHeaders: any = dataColumn;

            const filters = sessionStorage.getItem('filters');
            let changeFilter: any[] = [];
            if (filters) {
              changeFilter = JSON.parse(filters);
            }

            changeHeaders[dataFilterField.index].filters[
              indexFilterValue
            ].operation = e.target.value;

            const nanoIndexesFilter = changeFilter
              .map((item: any, i: number) =>
                item.field === dataFilterField.field ? i : -1
              )
              .filter((index: number) => index !== -1);

            if (nanoIndexesFilter.length > 0) {
              const indexOperation =
                indexFilterValue === 0
                  ? nanoIndexesFilter[0]
                  : nanoIndexesFilter[1];

              changeFilter[indexOperation].operation = e.target.value;

              onChangeFilters(changeFilter);
            }

            setActive(!active);

            onOperationFilter(
              '',
              changeHeaders,
              dataFilterField,
              e.target.value,
              indexFilterValue,
              dataElements
            );
          }}
          coordenates={coordenatesFilter}
          dataField={dataFilterField}
          dataFieldMultiple={dataFilterFieldMulti}
          addField={() => {
            const filters = sessionStorage.getItem('filters');
            let filtersData: any[] = [];
            if (filters) {
              filtersData = JSON.parse(filters);
            }

            let arrFiltersHeader: any = dataColumn;
            let filtersIndex = arrFiltersHeader[dataFilterField.index].filters;

            const dataFilters = {
              field: arrFiltersHeader[dataFilterField.index].field,
              value: '',
              operation: 'EQUAL',
            };

            filtersIndex.push(dataFilters);
            const dataFilter = {
              field: dataFilterField.field,
              value: '',
              operation: 'EQUAL',
            };

            filtersData.push(dataFilter);

            sessionStorage.setItem('filters', JSON.stringify(filtersData));

            // setArrFilters(filtersData);

            arrFiltersHeader[dataFilterField.index].filters = filtersIndex;

            setDataColumn(arrFiltersHeader);
            setActive(!active);
          }}
          removeField={() => {
            let arrFiltersData: any = dataColumn;

            const filters = sessionStorage.getItem('filters');
            let filterColumn: any[] = [];
            if (filters) {
              filterColumn = JSON.parse(filters);
            }

            let filtersIndex = arrFiltersData[dataFilterField.index].filters;

            filtersIndex.splice(1, 1);

            arrFiltersData[dataFilterField.index].filters = filtersIndex;

            const nanoIndexesFilter = filterColumn
              .map((item: any, i: number) =>
                item.field === dataFilterField.field ? i : -1
              )
              .filter((index: number) => index !== -1);

            filterColumn.splice(nanoIndexesFilter[1], 1);

            setDataColumn(arrFiltersData);
            setActive(!active);
          }}
          setFilterCustom={() => onFilterCustom()}
        />
      )}

      {coordenatesFilterError.clientX && activeTooltipFilterError && (
        <FilterTooltipError
          setFilter={(e: any) => {
            onFilterError(e.target.value);
          }}
          coordenates={coordenatesFilterError}
          // dataField={dataFilterField}
        />
      )}
      <table
        className="table table-responsive tableMargin"
        onScroll={() => {
          if (activeTooltipFilter) {
            setActiveTooltipFilter(!activeTooltipFilter);
            setCoordenatesFilter({});
          }
          if (activeTooltipFilterError) {
            setActiveTooltipFilterError(!activeTooltipFilterError);
            setCoordenatesFilterError({});
          }
        }}
      >
        <thead className="fixedHead">
          <tr>
            <th />
            <th>
              <div
                className={
                  dataElements && dataElements[0]?.activeAll
                    ? 'checkLarge checkboxColumnAll'
                    : 'uncheckLarge checkboxColumnAll'
                }
                onClick={() => {
                  onCheckAdd(dataElements[0], 0, true, dataElements);
                }}
              >
                {dataElements && dataElements[0]?.activeAll && (
                  <img src={checkIcon} alt="" />
                )}
              </div>
            </th>
            <th>
              <div
                className="columnFilterError"
                onClick={e => {
                  if (coordenatesFilterError.clientX) {
                    setCoordenatesFilterError({});
                  } else {
                    setCoordenatesFilterError({
                      clientX: e.clientX,
                      clientY: e.clientY,
                    });
                  }
                  setActiveTooltipFilterError(!activeTooltipFilterError);
                }}
              >
                <span className="titleHeaderTable">Erro</span>
                <div className="iconFilterCSV">
                  <img src={iconFilterCSV} alt="" />
                </div>
              </div>
            </th>
            {parameters && parameters[0]?.roteirizacaoAtiva === false && (
              <th>
                <div>
                  <span className="titleHeaderTable">
                    <Trans i18nKey="uploadCSV.buttons.driver" />
                  </span>
                </div>
              </th>
            )}
            {dataColumn &&
              dataColumn.map((itemHeaderColumn: any, index: number) => (
                <th className="headerColumnItem">
                  <div
                    className="headerColumn"
                    onClick={e => {
                      console.log('coordenates', coordenatesFilter.clientX);
                      console.log('active', activeTooltipFilter);
                      console.log('Column', itemHeaderColumn);
                      if (coordenatesFilter.clientX) {
                        setCoordenatesFilter({});
                      } else {
                        setCoordenatesFilter({
                          clientX: e.clientX,
                          clientY: e.clientY,
                        });
                        setDataFilterField({
                          ...dataColumn[index],
                          index,
                        });

                        if (defineIsMultiFilter(itemHeaderColumn.name)) {
                          getFilterColumn(
                            index,
                            getSubsidiaryId,
                            itemHeaderColumn.columnId
                          );
                        }
                      }
                      setActiveTooltipFilter(!activeTooltipFilter);
                    }}
                  >
                    <span className="titleHeaderTable">
                      {itemHeaderColumn.name}
                    </span>
                    <div
                      className="iconFilterCSV"
                      style={{
                        background: itemHeaderColumn.active
                          ? '#EA004C'
                          : 'transparent',

                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        width: '18px',
                        height: '18px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {itemHeaderColumn.active ? (
                        <img src={iconFilterCSVLight} alt="" />
                      ) : (
                        <img src={iconFilterCSV} alt="" />
                      )}
                    </div>
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody className="scrollList">
          {dataElements &&
            dataElements.map((item: any, index: number) => (
              <tr className="lineGroup">
                <td>
                  <OrderColumn
                    indexColumn={index}
                    arrElements={dataElements}
                    onOrderColumn={(
                      data: any,
                      indexData: number,
                      indexPosition: number
                    ) => {
                      updateSequence(data, indexData, indexPosition);
                    }}
                  />
                </td>
                <td>
                  <div
                    className={
                      item.active === true
                        ? 'checkSmall checkSmallContainer'
                        : 'uncheckSmall uncheckSmallContainer'
                    }
                    onClick={() => onCheckAdd(item, index, false, dataElements)}
                  >
                    {item.active && <img src={checkIcon} alt="" />}
                  </div>
                </td>
                <td>
                  <div className="columnErro">
                    {item.columns.length > 0 &&
                    findErrorsColumn(item.columns) ? (
                      <img src={erroIcon} alt="" className="erroIcon" />
                    ) : (
                      <img
                        src={successIconTable}
                        alt=""
                        className="successIcon"
                      />
                    )}
                  </div>
                </td>
                {parameters && parameters[0]?.roteirizacaoAtiva === false && (
                  <td>
                    <div className="containerNameDriver">
                      <span className="textNameDriver">{item.nameDriver}</span>
                    </div>
                  </td>
                )}

                {item.columns.map(
                  (itemColumn: IColumns, indexColumn: number) => (
                    <>
                      <td
                        className={
                          itemColumn.id === 'latitudePickup' ||
                          itemColumn.id === 'longitudePickup' ||
                          itemColumn.id === 'customerName'
                            ? 'limitText'
                            : ''
                        }
                        onClick={() => {
                          if (
                            handleIndex === index &&
                            editMode.rowKey === itemColumn.id
                            // eslint-disable-next-line no-empty
                          ) {
                          } else {
                            if (
                              permissionMenu(userData.grupo.permissoes, 'correctCSV.edit-cargo')
                            ) {
                              setHandleIndex(index);
                              onEditRow(
                                itemColumn.id,
                                itemColumn.data,
                                index,
                                indexColumn
                              );
                            } else {
                              FrontendNotification(
                                t('common.permission'),
                                NotificationType.WARNING
                              );
                            }
                          }
                        }}
                      >
                        <EditMode
                          arrElements={dataElements}
                          indexColumn={indexColumn}
                          indexRow={index}
                          editMode={editMode}
                          id={itemColumn.id}
                          error={itemColumn.error}
                          dataRow={itemColumn.data}
                          isTooltip={tooltipOpen}
                          valueColumn={valueRow}
                          onChangeRow={(value: string) => setValueRow(value)}
                          onUpdateAddress={() => {
                            onUpdateAddressTable(
                              dataElements[index].columns[0].data,
                              item.columns,
                              index,
                              itemColumn.id
                            );
                          }}
                          onUpdateRow={(text: string, dataRowText: any) => {
                            onUpdateRowTable(
                              dataElements,
                              index,
                              indexColumn,
                              valueRow,
                              item.columns,
                              dataColumn,
                              item.active
                            );
                            setEditMode({
                              status: false,
                              rowKey: null,
                              index: null,
                            });
                          }}
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <div className={classes.root}>
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e: any, page: number) => onPaginate(page)}
        />
      </div>
    </Container>
  );
};

export default Table;
