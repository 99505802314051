import React from 'react';
import './styles.scss';
import checkedIcon from '../../../assets/images/towerChecked.svg';


interface Props {
  onChecked?: any;
  active?: boolean;
  label?: string;
}

const CheckBox: React.FC<Props> = ({ onChecked, active, label }: Props) => {
  return (
    <div className="checkbox" onClick={onChecked}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {active ? (
          <div className="checkedVehicles">
            <img src={checkedIcon} alt="" />
          </div>
        ) : (
          <div className="unCheckedVehicles" />
        )}
        <span
          style={{
            marginLeft: 5,
            marginRight: 10,
            fontFamily: 'Lato',
            fontSize: '0.75rem',
          }}
        >
          {label}
        </span>
      </div>
    </div>
  )
}
export default CheckBox;
