// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  cursor: progress;
}

.loading-background .loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
}

.loading-background .loading-bar .loading-circle-1, .loading-background .loading-bar .loading-circle-2 {
  content: " ";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #6369D1;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.loading-background .loading-bar .loading-circle-2 {
  animation-delay: -1s;
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}`, "",{"version":3,"sources":["webpack://./src/core/common/LoadingRoutering/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EAEA,4CAAA;AACF;;AAEA;EAEE,oBAAA;AACF;AAWA;EACE;IACE,mBAAA;IACA,2BAAA;EAAF;EAEA;IACE,mBAAA;IACA,2BAAA;EAAF;AACF","sourcesContent":[".loading-background {\n  position: fixed;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9999999;\n  cursor: progress;\n}\n\n.loading-background .loading-bar {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  width: 50px;\n  height: 50px;\n  transform: translate(-50%, -50%);\n}\n\n.loading-background .loading-bar .loading-circle-1, .loading-background .loading-bar .loading-circle-2 {\n  content: \" \";\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background-color: #6369D1;\n  opacity: 0.6;\n  position: absolute;\n  top: 0;\n  left: 0;\n  -webkit-animation: sk-bounce 2s infinite ease-in-out;\n  animation: sk-bounce 2s infinite ease-in-out;\n}\n\n.loading-background .loading-bar .loading-circle-2 {\n  -webkit-animation-delay: -1s;\n  animation-delay: -1s;\n}\n\n@-webkit-keyframes sk-bounce {\n  0%, 100% {\n    -webkit-transform: scale(0);\n  }\n  50% {\n    -webkit-transform: scale(1);\n  }\n}\n\n@keyframes sk-bounce {\n  0%, 100% {\n    transform: scale(0);\n    -webkit-transform: scale(0);\n  }\n  50% {\n    transform: scale(1);\n    -webkit-transform: scale(1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
