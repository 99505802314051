import { object, string } from 'yup';

export default object().shape({
  descricao: string()
    .required('* Informe a descrição da ocorrência!')
    .max(50, '* Máximo de 50 caracteres'),
  numero: string()
    .required('* Informe o número!')
    .max(5, '* Máximo de 5 dígitos!'),
  tipo: string().required('* Informe o tipo!'),
  responsavel: string().required('* Informe o responsável!'),
  sequencial: string().required('* Informe a sequência da ocorrência!'),
});
