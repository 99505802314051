import FilterListIcon from '@mui/icons-material/FilterList';
import { Dialog, DialogContent, DialogTitle, styled } from '@mui/material';
import React from 'react';
import { Container, Title } from './styles';

// import { Container } from './styles';

type Props = {
  onClose: () => void;
  open: boolean;
  children: JSX.Element;
  showHeaderIcon: boolean;
  title: string;
}

const CustomDialog: React.FC<Props> = ({ onClose, open, children, showHeaderIcon, title}) => {
  
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiPaper-root': {
      borderRadius: '0.313rem',
      width: '800px',
    },
    '& .MuiDialogTitle-root': {
      background: '#EA004C',
      height: '2rem',
      padding: '0.313rem',
      borderRadius: '0.313rem 0.313rem 0 0',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  return (
    <Dialog onClose={onClose} open={open}>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Container>
            {showHeaderIcon && (
              <FilterListIcon style={{ color: 'white' }} fontSize="medium" />
            )}
            <Title>{title}</Title>

          </Container>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {/* <DialogActions className="tw-mb-4">
          
        </DialogActions> */}
      </BootstrapDialog>
    </Dialog>
  );
}

export default CustomDialog;