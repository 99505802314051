import React, { useCallback, useState } from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import '@yaireo/tagify/dist/tagify.css';
import './styles.scss';

export default function InputTag({
  onChange,
  value,
  readOnly,
  tagifyRef,
  onKeydown,
  onInput,
  countInput,
  onRemove,
  openParameters,
  settings,
}) {
  const [count, setCount] = useState(0);

  return (
    <>
      <Tags
        value={value}
        tagifyRef={tagifyRef}
        InputMode="textarea"
        autoFocus
        onChange={onChange}
        readOnly={readOnly}
        onInput={onInput}
        onKeydown={onKeydown}
        onRemove={onRemove}
        settings={settings}
      />
    </>
  );
}
