import { zodResolver } from '@hookform/resolvers/zod';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import { z } from 'zod';
import formValidator from './formValidator';

// import { Container } from './styles';

const newRouteSchema = z.object({
  description: z.string(),
  referenceId: z.string(),
  region: z.string(),
});

type EditRouteSchema = z.infer<typeof newRouteSchema>;

type Region = {
  id: number;
  description: string;
  referenceId: string;
}

type EditRouteData = {
  id: number;
  description?: string;
  referenceId?: string;
  region?: Region;
}

interface EditRouteProps {
  onClear: () => void;
  data: EditRouteData;
}



const EditRoute: React.FC<EditRouteProps> = ({ onClear, data }: EditRouteProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<Region[]>([]);

  const { register, handleSubmit } = useForm<EditRouteSchema>({
    resolver: zodResolver(newRouteSchema),
  });

  const onHandleSubmit = useCallback(async (values: EditRouteSchema) => {
    try {
      setLoading(false);
      const body = {
        description: values.description,
        referenceId: values.referenceId,
        region: {
          id: values.region
        }
      }
      const response = await api.patch(`/delivery/route/${data.id}`, body);

      if(response.status === 204) {
        FrontendNotification('Rota atualizada com sucesso!', NotificationType.SUCCESS);
        setLoading(false);
        onClear();
      }
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, []);

  const initialValues: EditRouteSchema = {
    description: '',
    referenceId: '',
    region: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidator,
    onSubmit: (values: EditRouteSchema) => onHandleSubmit(values),
  });

  const onHandleListRegion = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filters: [],
        orders: [],
        size: 1000,
        page: 0,
      };

      const response = await api.post('/delivery/region/pageable', body);

      setRegionList(response.data.content);
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, []);

  const onLoadRouteData = useCallback((data: EditRouteData) => {
    formik.setFieldValue('description', data.description);
    formik.setFieldValue('referenceId', data.referenceId);
    formik.setFieldValue('region', data.region?.id);
  }, [])


  useEffect(() => {
    onHandleListRegion();
    onLoadRouteData(data);
  }, [onHandleListRegion])

  return (
    <div className="tw-flex tw-container tw-max-w-full tw-mt-4">
      <Loading loading={loading} />
      <form className="tw-w-full">
        <div className="tw-flex tw-flex-1 tw-w-full tw-mb-2">
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-4">
            <InputCustom
              title="Descrição"
              onChange={formik.handleChange('description')}
              value={formik.values.description}
              touched={formik.touched.description}
              error={formik.errors.description}
              placeholder="Insira a descrição da rota"
              id='description'
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <InputCustom
              title="Id de referência"
              onChange={formik.handleChange('referenceId')}
              value={formik.values.referenceId}
              touched={formik.touched.referenceId}
              error={formik.errors.referenceId}
              placeholder="Insira o id de referência da rota"
              id='referenceId'
            />
          </div>
        </div>
        <div className="tw-flex tw-mb-6">
          <div className="tw-flex tw-flex-col tw-w-[50%] tw-mr-4">
            <SelectCustom
              title="Região"
              onChange={formik.handleChange('region')}
              value={formik.values.region}
              touched={formik.touched.region}
              error={formik.errors.region}
              id='region'
            >
              <>
              <option label={t('common.select')} />
              {regionList.map((region: Region, index: number) => (
                <option label={region.description} key={index} value={region.id}>
                  {region.description}
                </option>
              ))}
              </>
            </SelectCustom>
          </div>
        </div>

        <button
          type="button"
          id='btnUpdateRoute'
          onClick={() => formik.handleSubmit()}
          className="tw-w-[50%] tw-h-9 tw-rounded-md tw-text-[#fff] tw-bg-[#EA004C] tw-border-none"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default EditRoute;
