/* eslint-disable prefer-const */
import { FrontendNotification } from 'components/Notification';
import Loading from 'core/common/Loading';
import {
  NotificationType
} from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../../../../../../../assets/images/closeIcon.svg';
import './styles.scss';


export default function ConfirmationDriver({
  idRomaneio,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
  indexLoad,
  idRoute,
}) {
  const [modal, setModal] = React.useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/route/steps/${idRoute}`);
      FrontendNotification(
        t('routeOptmization.routeSteps.messageSuccess'),
        NotificationType.SUCCESS
      );
      setModal(!modal);
      onClear();
      onLoad(indexLoad, idRoute);

      setLoading(false);
    } catch (err) {}
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%' }}
      >
        <div className="modalWrapperVehicles">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            {/* <Trans i18nKey="changeDriver.title" /> */}
            Confirma remoção
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="routeOptmization.routeSteps.messageConfirmationDelete" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeDriver.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => handleSubmit()}
              >
                <Trans i18nKey="changeDriver.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
