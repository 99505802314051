/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import Pagination from '@material-ui/lab/Pagination';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import Vehicles from 'components/Vehicles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import api from 'services/api';
import addDriver from '../../../assets/images/addDriver.png';
import './styles.scss';



export default function SingleSelectVehicles({
  dataCargoValue,
  onClear,
  onVehiclesSelected,
  filialId,
  onChangeVehicles,
  onSelectVehicles
}) {
  const [modal, setModal] = useState(true);
  const { t } = useTranslation();

  const [filters, setFilters] = useState([]);

  const [vehicles, setVehicles] = useState([]);

  const [modalVehicles, setModalVehicles] = React.useState(false);

  const [valueSearch, setValueSearch] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [optionsFilter, setOptionsFilter] = useState('placa');
  const [perPage, setPerPage] = useState(10);

  const getVehiclesSelect = useCallback(async () => {
    try {
      const currentFilters = [
        {
          field: 'filial.id',
          value: parseInt(filialId),
          operation: 'EQUAL',
        },
      ];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [],
      };
      const response = await api.post('/delivery/veiculos/pageable', data);

      const mappingResponse = response.data.content.map(itemResponse => {
        return {
          ...itemResponse,
        };
      });

      setTotalPages(Math.ceil(response.data.totalElements / 10));

      setVehicles(mappingResponse);
    } catch (err) {}
  }, [currentPage, filters]);

  const handleSubmit = useCallback((data, idVehicles, name) => {
    setModal(!modal);
    onClear();
    onSelectVehicles(data);
    onChangeVehicles(idVehicles, name);
  }, []);

  const paginate = pageNumber => setCurrentPage(pageNumber - 1);

  useEffect(() => {
    getVehiclesSelect();
  }, [getVehiclesSelect]);

  return (
    <>
      {modalVehicles && (
        <Vehicles
          isNew
          onClear={() => {
            setModalVehicles(!modalVehicles);
            getVehiclesSelect();
          }}
        />
      )}
      <CustomModal
        isOpen
        label="Selecionar Veículos"
        isClose={() => onClear()}
      >
        
          <div style={{ display: 'flex' }}>
            <Label className="search-Label" htmlFor="search-input">
              <SearchFilter
                style={{
                  position: 'relative',
                  alignItems: 'center',
                  marginLeft: 60,
                  marginRight: 0,
                }}
                isLicensePlate
                isIDVehicle
                isDriver
                isMaximumService
                isMaximumWeight
                isMaximumVolume
                isMaximumPrice
                isService
                isTypeVehicleDriver={optionsFilter === 'tipo'}
                valueSearch={valueSearch}
                optionValue={optionsFilter}
                handleChange={e => setValueSearch(e.target.value)}
                handleSelect={e => setOptionsFilter(e.target.value)}
                handleSelectDriver={e => setValueSearch(e.target.value)}
                onExecuteFilter={() => {
                  setCurrentPage(0);
                  if (optionsFilter === 'placa' && valueSearch.length > 0) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'placa',
                        value: valueSearch,
                        operation: 'MATCH',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'tipo' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'tipo',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'motorista.nome' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'motorista.nome',
                        value: valueSearch,
                        operation: 'MATCH',
                      },
                    ]);
                  } else if (optionsFilter === 'id' && valueSearch.length > 0) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'id',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'quantidadeAtendimento' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'quantidadeAtendimento',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'peso' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'peso',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'cubagem' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'cubagem',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else if (
                    optionsFilter === 'valor' &&
                    valueSearch.length > 0
                  ) {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                      {
                        field: 'valor',
                        value: valueSearch,
                        operation: 'EQUAL',
                      },
                    ]);
                  } else {
                    setFilters([
                      {
                        field: 'status',
                        value: 'ACTIVE',
                        operation: 'EQUAL',
                      },
                      {
                        field: 'filial.id',
                        value: filialId,
                        operation: 'EQUAL',
                      },
                    ]);
                  }
                }}
              />
              <Button
                onClick={() => setModalVehicles(!modalVehicles)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 40,
                  background: '#EA004C',
                  border: 'none',
                  borderColor: 'transparent',
                  borderRadius: 6,
                  fontSize: 14,
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  marginTop: 20,
                }}
              >
                <div style={{ display: 'flex' }} onClick={() => setModalVehicles(!modalVehicles)}>
                  <img src={addDriver} alt="" style={{ marginRight: 9 }} />
                  Veículos
                  {/* <Trans i18nKey="uploadCSV.buttons.driver" /> */}
                </div>
              </Button>
            </Label>
          </div>

         

          <div style={{ flexDirection: 'column' }} />
          <div className="table-responsive tableSelect">
            <table style={{ width: '100%', marginBottom: 20 }}>
              <thead>
                <tr>
                  <th style={{ height: 120 }}>
                    <span>
                      <Trans i18nKey="selectVehicles.avaliable" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.licensePlate" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.type" />
                    </span>
                  </th>
                  <th>
                    <span>ID</span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.driver" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.maxAttendances" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.weight" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.volume" />
                    </span>
                  </th>
                  <th>
                    <span>
                      {' '}
                      <Trans i18nKey="selectVehicles.price" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicles.length > 0 &&
                  vehicles.map((itemVehicles, index) => (
                    <tr
                      className="lineGroup   rowSelect"
                      onClick={() =>
                        handleSubmit(
                          itemVehicles,
                          vehicles[index].id,
                          vehicles[index].motorista.nome
                        )
                      }
                    >
                      <td className="columnSelect">
                        <span>
                          {itemVehicles.disponivel ? (
                            <>
                              {navigator.language === 'pt' ||
                              navigator.language === 'pt-BR'
                                ? 'SIM'
                                : 'YES'}
                            </>
                          ) : (
                            <>
                              {navigator.language === 'pt' ||
                              navigator.language === 'pt-BR'
                                ? 'NÃO'
                                : 'NO'}
                            </>
                          )}
                        </span>
                      </td>
                      <td>
                        <span>{itemVehicles.placa}</span>
                      </td>
                      <td>
                        <span>{itemVehicles.tipo}</span>
                      </td>
                      <td>
                        <span>{itemVehicles.idReferencia}</span>
                      </td>
                      <td>
                        <span>
                          {itemVehicles.motorista !== null &&
                            itemVehicles.motorista.nome}
                        </span>
                      </td>
                      <td>
                        <span>{itemVehicles.quantidadeAtendimento}</span>
                      </td>
                      <td>
                        <span>{itemVehicles.peso} Kg</span>
                      </td>
                      <td>
                        <span>{itemVehicles.cubagem} m³</span>
                      </td>
                      <td className="columnSelectValue">
                        <span>R$ {itemVehicles.valor}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e, page) => paginate(page)}
              />
            </div>
          </div>
      </CustomModal>
    </>
  );
}
