import styled from 'styled-components';

export const Title = styled.p`
  color: #003049;
  font-size: 22px;
  line-height: 26px;
  font-weight: bold;
  font-style: normal;
  margin-left: 41px;
`;

export const Container = styled.div`
  width: 450px;
  border-radius: 10px;
  border: 2px solid #edf2f4;
  background: #ffffff;

  border: 2px solid #edf2f4;
  border-radius: 10px;
  margin-left: 41px;
  margin-bottom: 12px;
  margin-right: 41px;
  justify-content: space-around;
  overflow: auto;
  padding-bottom: 20px;
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #edf2f4;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  text-align: center;
  border: none;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 18px;
  border: none;
`;

export const ColumnTitle = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  color: #003049;
  border: none;
`;

export const ColumnText = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #003049;
`;

export const Line = styled.div`
  width: 410px;
  height: 0px;
  border: 0.5px solid #ccd6db;
  margin-left: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
