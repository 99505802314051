import { ValueFormatterParams } from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import erroIcon from 'assets/images/erroIcon.png';
import successIconTable from 'assets/images/successIconTable.png';
import updateIcon from 'assets/images/updateIcon.png';
import { CargoSMS } from 'components/DetailsDelivery/interfaces/types';
import Grid from 'components/Grid/Grid';
import { ColumnDef, Direction, RowSelection } from 'components/Grid/types';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import { DateFilter } from 'components/RouteringSimulations/Filter/DateFilter';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useFormik } from 'formik';
import i18next from 'i18next';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import api from 'services/api';
import DetailsLog from './DetailsLog';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

interface FormValues {
  dispatch: string;
  enctype: string;
  charset: string;
  uri: string;
  account: string;
  code: string;
  from: string;
}

interface Props {
  onClear: () => void;
}

const ZenviaSMS: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'sms.dataEnvio',
      headerName: 'Data/Hora',
      checkboxSelection: true,
      filter: DateFilter,
      width: 300,
      valueFormatter: (params: ValueFormatterParams) => {
        if(params.value) {
          return format(new Date(params.value), 'dd/MM/yyyy HH:mm:ss', {
            locale: pt
          })
        }
      }
    },
    {
      field: 'sms.status',
      headerName: 'Status',
      width: 350,
      cellRenderer: (params: CustomCellRendererProps) => {
        return (
          <div className="tw-flex tw-items-center tw-mt-2">
              <img
                alt={`${params.value} Flag`}
                src={params.value !== null && params.value < 300 ? successIconTable : erroIcon}
                className="tw-mr-2"
              />
            <span className="tw-text-sm tw-font-bold tw-text-[#EA004C]">
              {params.value || 500}
            </span>
          </div>
        );
      },
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<CargoSMS[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(async (values: FormValues, resetForm: any) => {
    try {
      setLoading(true);
      const { language } = i18next;
      const emailSendLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

      const response = await api.post(
        `/cadastro/parametrosZenvia?lang=${emailSendLanguage}`,
        values
      );

      if (response.status === 201) {
        setLoading(false);
        FrontendNotification(
          language === 'pt' || language === 'pt-BR'
            ? 'Configuração do zenvia cadastrado com sucesso!'
            : 'Configuring Zenvia successfully registered',
          NotificationType.SUCCESS
        );
      }
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, [])

  const onDetails = (selectedRows: CargoSMS[]) => {
    if(selectedRows.length > 0) {
        setShowDetails(!showDetails);
    } else {
      FrontendNotification('Deve selecionar pelo menos um registro!', NotificationType.WARNING);
    }
  };

  const initialValues: FormValues = {
    dispatch: 'send',
    enctype: '',
    charset: '',
    uri: '',
    account: '',
    code: '',
    from: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: navigator.language === 'pt-BR' || navigator.language === 'pt' ? formValidatorPT : formValidatorEN,
    onSubmit: (values: FormValues, { resetForm }) => handleSubmit(values, resetForm) 
  })

  return (
    <CustomModal
      isOpen
      label="Zenvia"
      isClose={props.onClear}
      styles={{ minWidth: '60%' }}
    >
      <>
      <Loading loading={loading} />
      {showDetails && (

        <DetailsLog onClear={() => setShowDetails(!showDetails)} message={selectedRows[0].sms.resposta} />
      )}
      <div>
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom
              title={t('zenvia.fields.from')}
              placeholder=""
              onChange={formik.handleChange('from')}
              value={formik.values.from}
              touched={formik.touched.from}
              error={formik.errors.from}
            />
          </div>
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom title={'Enctype'} onChange={formik.handleChange('enctype')}
              value={formik.values.enctype}
              touched={formik.touched.enctype}
              error={formik.errors.enctype} placeholder="" />
          </div>
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom title={'Charset'} onChange={formik.handleChange('charset')}
              value={formik.values.charset}
              touched={formik.touched.charset}
              error={formik.errors.charset} placeholder="" />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom title={'Uri'} onChange={formik.handleChange('uri')}
              value={formik.values.uri}
              touched={formik.touched.uri}
              error={formik.errors.uri} placeholder="" />
          </div>
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom
              title={t('zenvia.fields.account')}
              onChange={formik.handleChange('account')}
              value={formik.values.account}
              touched={formik.touched.account}
              error={formik.errors.account}
              placeholder=""
            />
          </div>
          <div className="tw-flex tw-flex-col tw-mr-2 tw-w-full">
            <InputCustom
              title={t('zenvia.fields.code')}
              onChange={formik.handleChange('code')}
              value={formik.values.code}
              touched={formik.touched.code}
              error={formik.errors.code}
              placeholder=""
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <button
              type="button"
              className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-9 tw-bg-[#EA0045] tw-text-[#fff] tw-text-base tw-border-none tw-rounded-md tw-mt-7"
              onClick={() => formik.handleSubmit()}
            >
              <Trans i18nKey="zenvia.save" />
            </button>
          </div>
        </div>
        <div className="tw-w-full tw-h-[2px] tw-bg-[#CCD6DB] tw-mt-4" />
        <div className="tw-flex tw-flex-col tw-w-full tw-mt-4">
          <div>
            <span className="tw-text-lg tw-font-bold tw-text-[#003049]">
              Logs
            </span>
          </div>
          <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
            <div className="tw-flex tw-w-1/3">
              <button
                type="button"
                className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-9 tw-bg-[#EA0045] tw-text-[#fff] tw-text-base tw-border-none tw-rounded-md tw-mt-2"
              >
                <img src={updateIcon} alt="" className="tw-mr-4" />
                <Trans i18nKey="zenvia.update" />
              </button>
            </div>
          </div>
          <div className="tw-w-full tw-h-96 tw-mt-4">
            <>
              <div>
                <button className="tw-flex tw-items-center tw-justify-center tw-w-32 tw-h-9 tw-bg-[#EA0045] tw-text-[#fff] tw-text-base tw-border-none tw-rounded-md tw-mt-2 tw-mb-2" onClick={() => onDetails(selectedRows)}>
                  <Trans i18nKey="zenvia.details" />
                </button>
              </div>
              <Grid<CargoSMS>
                columns={columns}
                filters={[]}
                rowSelection={RowSelection.SINGLE}
                orders={[
                  {
                    field: 'sms.dataEnvio',
                    direction: Direction.DESC,
                  },
                ]}
                pagination={true}
                path="/delivery/conhecimentosSms/pageable?lang=pt_BR"
                showCrudButtons={false}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                server
              />
            </>
          </div>
        </div>
      </div>
      </>

    </CustomModal>
  );
};

export default ZenviaSMS;
