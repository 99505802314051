const getDayOfBreaksPosition = (day: string) => {
  let positionBreak;

  switch (day) {
    case 'MONDAY':
      positionBreak = 0;
      break;
    case 'TUESDAY':
      positionBreak = 1;
      break;
    case 'WEDNESDAY':
      positionBreak = 2;
      break;
    case 'THURSDAY':
      positionBreak = 3;
      break;
    case 'FRIDAY':
      positionBreak = 4;
      break;
    case 'SATURDAY':
      positionBreak = 5;
      break;
    case 'SUNDAY':
      positionBreak = 6;
      break;
    default:
      break;
  }

  return positionBreak;
};

export default getDayOfBreaksPosition;
