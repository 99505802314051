import React from 'react';
import orderTop from '../../../../../assets/images/orderTop.png';
import orderBottom from '../../../../../assets/images/orderBottom.png';
import './styles.scss';

interface Props {
  indexColumn: number;
  arrElements: any;
  onOrderColumn?: any;
}

const OrderColumn: React.FC<Props> = ({
  indexColumn,
  arrElements,
  onOrderColumn,
}: Props) => {
  return (
    <div>
      {indexColumn === 0 ? (
        <img
          src={orderBottom}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onOrderColumn(arrElements, indexColumn, indexColumn + 1);
          }}
          alt=""
        />
      ) : indexColumn + 1 === arrElements.length ? (
        <img
          src={orderTop}
          onClick={() =>
            onOrderColumn(arrElements, indexColumn, indexColumn - 1)
          }
          className="orderTopColumn"
          alt=""
        />
      ) : (
        <div className="containerOrderGrid">
          <img
            src={orderTop}
            onClick={() => onOrderColumn(arrElements, indexColumn, indexColumn - 1)}
            className="orderTop"
            alt=""
          />
          <img
            src={orderBottom}
            onClick={() => onOrderColumn(arrElements, indexColumn, indexColumn + 1)}
            className="orderBottom"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default OrderColumn;
