import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const ModalSignature = ({
  modalToogle,
  setModal,
  imageLink,
  title,
  erro,
  type,
}) => {
  const [backdrop] = useState('static'); // true, false or static
  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={modalToogle}
        toggle={setModal}
        backdrop={backdrop}
        style={{ maxWidth: 'auto', height: 'auto' }}
      >
        <ModalHeader toggle={setModal}>{title}</ModalHeader>
        <ModalBody>
          {erro === true ? (
            <>
              {type === 'Foto' ? (
                <p style={{ fontFamily: 'Lato', fontSize: 16, color: '#000' }}>
                  <Trans i18nKey="common.noPhasesPhoto" />
                </p>
              ) : (
                <p style={{ fontFamily: 'Lato', fontSize: 16, color: '#000' }}>
                  <Trans i18nKey="common.noPhasesSignatures" />
                </p>
              )}
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Zoom>
                <img
                  className="img-fluid"
                  alt=""
                  src={imageLink}
                  style={{ width: 'auto', maxHeight: 350 }}
                />
              </Zoom>
            </div>
          )}
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
};

export default ModalSignature;
