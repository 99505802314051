import styled from 'styled-components';

export const Container = styled.div`
  background: #edf2f4;
  box-shadow: 10px 4px 14px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-left: 15px;
  height: auto;
  width: 100%;
  position: relative;
  max-width: 90%;
`;
