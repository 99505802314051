import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import history from 'services/history';
import { editSimulatorRoute } from '../../store/modules/routeringSimulation/actions';
import {
  ColumnContainer,
  ColumnText,
  ColumnTitle, Container, Line, RowContainer,
  Title
} from './styles';
import './styles.scss';

const ModalCreatedCargo = ({ data, onClear }) => {
  const [modal, setModal] = React.useState(false);
  const dispatch = useDispatch();
  const handleOutsideClick = e => {
    if (e.target.id === 'id') onClear();
  };
  return (
    <div id="id" className="warning">
      <div className="container">
        <div className="content">
          <Title>Romaneios Criados</Title>
          <Container>
            <RowContainer style={{ border: 'none' }}>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnTitle>Nº Romaneio</ColumnTitle>
              </ColumnContainer>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnTitle>Motorista</ColumnTitle>
              </ColumnContainer>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnTitle>Data de criação</ColumnTitle>
              </ColumnContainer>
            </RowContainer>
            {data.map((item, index) => (
              <>
                <RowContainer style={{ border: 'none' }}>
                  <ColumnContainer style={{ border: 'none' }}>
                    <ColumnText>
                      {item.numero !== undefined && item.numero}
                    </ColumnText>
                  </ColumnContainer>
                  <ColumnContainer style={{ border: 'none' }}>
                    <ColumnText>
                      {item.motorista !== undefined && item.motorista.nome}
                    </ColumnText>
                  </ColumnContainer>
                  <ColumnContainer style={{ border: 'none' }}>
                    <ColumnText>
                      {item.data !== undefined && item.data}
                    </ColumnText>
                  </ColumnContainer>
                </RowContainer>
                {index + 1 === data.length ? <></> : <Line />}
              </>
            ))}
          </Container>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
              paddingLeft: 60,
              paddingRight: 60,
              marginBottom: 26,
            }}
          >
            <button
              id='finishRoutering'
              style={{
                background: '#EA004C',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: ' 0px 4px 6px rgba(234, 0, 76, 0.2)',
                borderRadius: 6,
                border: 'none',
                width: '100%',
                color: '#fff',
                height: 35,
                fontFamily: 'Lato',
                fontSize: 16,
                fontWeight: 'bold',
              }}
              type="button"
              onClick={() => {
                dispatch(editSimulatorRoute(false));
                window.open('/controlTower', '_blank')
                        
                history.push('/dashboard');
                window.location.reload();
              }}
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ModalCreatedCargo);
