import { object, ref, string } from 'yup';

export default object().shape({
  cep: string().required('* Informe o cep da filial!'),
  logradouro: string().required('* Informe o logradouro da filial!'),
  numero: string().required('* Informe o número da filial!'),
  bairro: string().required('* Informe o bairro da filial!'),
  cidade: string().required('* Informe a cidade da filial!'),
  estado: string().required('* Informe o estado da filial!'),
});
