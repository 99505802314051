// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerProgress {
  display: flex;
  width: 127px;
  height: 2px;
  background: #C4C4C4;
  margin-right: 20px;
  margin-left: 6px;
}

.containerProgressCargo {
  display: flex;
  flex-direction: column;
  width: 76%;
}

.porcentageText {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #6369D1;
}

.porcentageTextSecondary {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #EA004C;
}`, "",{"version":3,"sources":["webpack://./src/pages/RouterCargo/components/CargasRouting/components/Card/components/Progress/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EAEA,kBAAA;EACA,gBAAA;AAAJ;;AAIA;EACI,aAAA;EACA,sBAAA;EACA,UAAA;AADJ;;AAIA;EACI,mBAAA;EACJ,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADA;;AAGA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAA","sourcesContent":[".containerProgress {\n    display: flex;\n    width: 127px;\n    height: 2px;\n    background: #C4C4C4;\n    // margin-bottom: 20px;\n    margin-right: 20px;\n    margin-left: 6px;\n    // margin-top: 20px;\n}\n\n.containerProgressCargo {\n    display: flex;\n    flex-direction: column;\n    width: 76%;\n}\n\n.porcentageText {\n    font-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 10px;\nline-height: 12px;\ncolor: #6369D1;\n}\n.porcentageTextSecondary {\nfont-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 10px;\nline-height: 12px;\ncolor: #EA004C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
