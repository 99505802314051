import CustomModal from 'components/ModalCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'react-loading';
import api from 'services/api';
import Results from './components/Results';
import Upload from './components/Upload';

interface Props {
  idRomaneio: any;
  onClear: () => void;
}

const UploadCTE: React.FC<Props> = ({ idRomaneio, onClear }: Props) => {
  const [loading, setLoading] = useState(false);
  const [typeUpload, setTypeUpload] = useState('');
  const [errors, setErrors] = useState([]);

  const { t } = useTranslation();

  const onSubmitUpload = useCallback(async(resultFiles: any[]) => {
    if (resultFiles.length === 0) {
      FrontendNotification(t('errors.upload'), NotificationType.ERROR);
    } else {
      setLoading(true);

      const data = new FormData();

      let cargoId: any = parseFloat(idRomaneio);
      let files = [];

      resultFiles.map((row: any) => {
        data.append('files', row.file);
      });

      data.append('id', cargoId);

        try {
          const emailSendLanguage =
            navigator.language === 'pt' || navigator.language === 'pt-BR'
              ? 'pt_BR'
              : 'en_US';
          const response = await api.post(
            `/delivery/uploads/xml-cte?lang=${emailSendLanguage}`,
            data
          );

          if (response.status === 200) {

            setErrors(response.data.errors);
            setLoading(false);
            setTypeUpload('R');
           
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          FrontendNotification(t('errors.upload'), NotificationType.ERROR);
        }
     
    }
  }, [])

  const onUpload = async (files: any) => {
    setLoading(true);
    const uploadedFiles = files.map((file: any) => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,

      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));

    onSubmitUpload(uploadedFiles);
  };
  return (
    <CustomModal
      isOpen
      isClose={onClear}
      label="Upload CT-e"
      styles={{ minWidth: '600px' }}
    >
      <div className="tw-flex tw-justify-center">
        {loading ? (
          <div className="tw-flex tw-w-2/4 tw-h-20 tw-bg-[#EA004C] tw-rounded-lg tw-p-4 tw-flex-col tw-items-center tw-justify-center">
            <Loading type="bars" width={48} height={48} />
            <span className="tw-text-base tw-text-[#fff] tw-font-normal">
              Loading
            </span>
          </div>
        ) : typeUpload.length === 0 ? (
          
          <Upload onUpload={onUpload} />
        ): (
          <Results errors={errors} />
        )}
      </div>
    </CustomModal>
  );
};

export default UploadCTE;
