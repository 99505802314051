import React from 'react';
import { Label } from 'reactstrap';

interface Props {
  title: string;
  active: boolean;
  id: string;
  onChange: () => void;
}

const Switch: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Label>{props.title}</Label>

      <div
        className="switch"
        onClick={props.onChange}
        id={props.id}
      >
        {props.active ? (
          <div className="switchChecked" />
        ) : (
          <div className="switchUnchecked" />
        )}
      </div>
    </>
  );
};

export default Switch;
