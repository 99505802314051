// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editColumnRow {
  font-size: 10px;
  padding: 10px;
}
.editColumnRow .editColumnRowActive {
  color: #EA004C;
  font-weight: bold;
}
.editColumnRow .editColumnRowInactive {
  color: #000;
  font-weight: normal;
}

.containerEditColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  cursor: pointer;
}

.errorColumn {
  cursor: pointer !important;
}

.inputErrorColumn {
  border-radius: 7px;
  border: 3px solid #EA004C;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadCSV/CorrectCSV/Table/EditMode/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;AACF;AACE;EACE,cAAA;EACA,iBAAA;AACJ;AAEE;EACE,WAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AADF;;AAIA;EACE,0BAAA;AADF;;AAIA;EACE,kBAAA;EACA,yBAAA;AADF","sourcesContent":[".editColumnRow {\n  font-size: 10px;\n  padding: 10px;\n\n  .editColumnRowActive {\n    color: #EA004C;\n    font-weight: bold;\n  }\n\n  .editColumnRowInactive {\n    color: #000;\n    font-weight: normal;\n  }\n}\n\n.containerEditColumn {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 120px;\n  cursor: pointer;\n}\n\n.errorColumn {\n  cursor: pointer !important;\n}\n\n.inputErrorColumn {\n  border-radius: 7px;\n  border: 3px solid #EA004C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
