import Logs from 'components/Logs';
import CustomModal from 'components/ModalCustom';
import { FrontendNotification } from 'components/Notification';
import Loading from 'core/common/Loading';
import { NotificationType } from 'core/common/Notification';
// import { Line } from 'pages/ControlTower/components/Table/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap/lib';
import api from 'services/api';
import { ContainerIntegrationAPI, Line } from './styles';
import './styles.scss';

interface Props {
  onClear?: any;
}

interface CompanyData {
  appId: string;
  appKey: string;
  id: number;
}

interface Filters {
  field: string;
  value: any;
  operation: string;
}

const IntegrationsAPI: React.FC<Props> = ({ onClear }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataAPI, setDataAPI] = useState([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [filters, setFilters] = useState<Filters[]>([]);

  const { t } = useTranslation();

  const getIntegrationAPI = useCallback(async () => {
    try {
      setLoading(true);
      const data = {
        filters: filters.length > 0 ? filters : [],
        orders: [
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: currentPage || 0,
        size: 5,
      };
      const response = await api.post('/audit/integration/pageable', data);

      setDataAPI(response.data.content);
      setTotalPages(response.data.totalPages);

      setLoading(false);
    } catch (err) {}
  }, [currentPage, filters]);

  const getCompanyData = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/empresas/api');

      if (response.data && response.data.length > 0) {
        setCompanyData(response.data[0]);
      }
    } catch {}
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.post('/cadastro/empresas/api/criaApp');
      setLoading(false);

      FrontendNotification(
        t('integrationsAPI.messageSuccess'),
        NotificationType.SUCCESS
      );

      getCompanyData();
    } catch {}
  }, []);

  function paginate(pageNumber: number) {
    setCurrentPage(pageNumber - 1);
  }

  useEffect(() => {
    getIntegrationAPI();
    getCompanyData();
  }, [getIntegrationAPI]);
  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isOpen
        label={`${t('integrationsAPI.menuTitle')} API`}
        styles={{ maxWidth: '90%' }}
        isClose={() => onClear()}
      >
        {companyData === null ? (
          <ContainerIntegrationAPI>
            <p className="activeText">
              <Trans i18nKey="integrationsAPI.textActiveEnviroment" /> <br />
              <Trans i18nKey="integrationsAPI.textActiveEnviromentContent" />
            </p>
            <button className="button" onClick={() => handleSubmit()}>
              <Trans i18nKey="integrationsAPI.buttonText" />
            </button>
          </ContainerIntegrationAPI>
        ) : (
          <Row>
            <Col md={6}>
              <Label className="textStylesAPI">API USER</Label>
              <span className="textStylesAPI">{companyData?.appId}</span>
            </Col>
            <Col md={6}>
              <Label className="textStylesAPI">API PASSWORD</Label>
              <span className="textStylesAPI">{companyData?.appKey}</span>
            </Col>
          </Row>
        )}

        <Line style={{ padding: 0, margin: 0, marginTop: 30 }} />

        <Logs
          data={dataAPI}
          totalPages={totalPages}
          page={currentPage}
          onPaginate={(page: number) => paginate(page)}
          onFilters={(date: string, status: string) => {
            setCurrentPage(0);
            if (date.length > 0 && status.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else if (date.length > 0) {
              setFilters([
                {
                  field: 'dataCriacao',
                  value: `${date}T00:00:00`,
                  operation: 'GREATER_THAN_EQUAL',
                },
                {
                  field: 'dataCriacao',
                  value: `${date}T23:59:59`,
                  operation: 'LESS_THAN_EQUAL',
                },
              ]);
            } else if (status.length > 0) {
              setFilters([
                {
                  field: 'statusCode',
                  value: status,
                  operation: 'EQUAL',
                },
              ]);
            } else {
              setFilters([]);
            }
          }}
        />
      </CustomModal>
    </>
  );
};

export default IntegrationsAPI;
