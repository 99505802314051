import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import SelectCustom from 'components/SelectCustom';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Col, Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import api from 'services/api';
import { responsiveSize } from 'styles/responsive';
import DriverShift from './DriverShift';
import formValidatorEN from './formValidatorEN';
import formValidatorENEdit from './formValidatorEN-Edit';
import formValidatorPT from './formValidatorPT';
import formValidatorPTEdit from './formValidatorPTEdit';
import { BoxForm, Content, Footer, IconColumn } from './styles';
import './styles.scss';

interface Props {
  onClear?: any;
}

interface ILogisticOperator {
  cnpj?: string;
  id?: number;
  nomeFantasia?: string;
  razaoSocial?: string;
}

interface ISubsidiaryData {
  id: number;
  nome: string;
}

interface FormValues {
  cpf: string;
  nome: string;
  tipo: string;
  filial: string;
}

const Collaborator: React.FC<Props> = ({ onClear }: Props) => {
  const { t } = useTranslation();

  const [updateId, setUpdateId] = useState(null);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [edit, setEdit] = useState(false);
  const [driver, setDriver] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [optionsFilter, setOptionsFilter] = useState<string>('nome');
  const [valueSearch, setValueSearch] = useState<string>('');
  const [passwordDisabled, setPasswordDisabled] = useState<boolean>(true);
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState<
    boolean
  >(false);
  const [operatorLogistic, setOperatorLogistic] = useState<any>([]);
  const [filiais, setFiliais] = useState<any>([]);
  const [driverShift, setDriverShift] = useState(false);
  const [blockedConfirmation, setBlockedConfirmation] = useState(false);
  const [unlockConfirmation, setUnlockConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [blockedId, setBlockedId] = useState('');
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [typeCollaborator, setTypeCollaborator] = useState<any>([]);

  const getCollaborator = useCallback(async () => {
    try {
      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';
      const currentFilters: any = [];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [
          {
            field: 'id',
            direction: 'DESC',
          },
        ],
      };

      const response = await api.post(
        `/delivery/colaboradores/pageable?lang=${emailSendLanguage}`,
        data
      );
      setTotalPages(Math.ceil(response.data.totalElements / 10));
      setDriver(response.data.content);
    } catch (err) {}
  }, [filters, currentPage]);

  function validaCpfCnpj(cpfCnpj: any) {
    const valorCpfCnpj = cpfCnpj.replace(/[^0-9]/g, '');

    let SomaFirst = 0;
    let SomaSecond = 0;
    let RestoFirst;
    let RestoSecond;
    let i;

    switch (valorCpfCnpj) {
      case '12345678909':
        return false;

      case '00000000000':
        return false;

      case '11111111111':
        return false;

      case '22222222222':
        return false;

      case '33333333333':
        return false;

      case '44444444444':
        return false;

      case '55555555555':
        return false;

      case '66666666666':
        return false;

      case '77777777777':
        return false;

      case '88888888888':
        return false;

      case '99999999999':
        return false;

      default:
        for (i = 0; i < 9; i++) {
          SomaFirst += parseInt(valorCpfCnpj[i], 10) * (11 - (i + 1));
          RestoFirst = (SomaFirst * 10) % 11;
        }

        if (RestoFirst === 10 || RestoFirst === 11) RestoFirst = 0;
        if (RestoFirst !== parseInt(valorCpfCnpj[9], 10)) return false;

        for (i = 0; i < 10; i++) {
          SomaSecond += parseInt(valorCpfCnpj[i], 10) * (12 - (i + 1));
          RestoSecond = (SomaSecond * 10) % 11;
        }

        if (RestoSecond === 10 || RestoSecond === 11) RestoSecond = 0;
        if (RestoSecond !== parseInt(valorCpfCnpj[10], 10)) return false;

        return true;
    }
  }

  const getFiliais = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      setFiliais(response.data);
    } catch (err) {}
  }, []);

  const getTypeCollaborator = useCallback(async () => {
    try {
      const response = await api.get('/delivery/colaboradores/tipos');

      setTypeCollaborator(response.data);
    } catch {}
  }, []);

  const getCurrentPosition = useCallback(() => {
    const position = navigator.geolocation.getCurrentPosition(location => {
      setInitialPosition([location.coords.latitude, location.coords.longitude]);
    });
  }, []);

  const handleSubmit = useCallback(
    async (
      values,
      driverUpdate,
      resetForm,
      isEditPassword,
      isEdit,
      dataShiftDriver
    ) => {
      try {
        const shiftData = {
          horarioInicial: dataShiftDriver.horaInicial,
          horarioFinal: dataShiftDriver.horaFinal,
        };

        const isJourney = {
          id: parseInt(dataShiftDriver),
        };
        let data;
        if (values.operadorLogistico !== '') {
          data = {
            idReferencia: 4435543,
            filial: {
              id: values.filial,
            },
            cpf: values.cpf,
            nome: values.nome,
            tipo: {
              id: values.tipo,
            },
            journey: dataShiftDriver.length > 0 ? isJourney : null,
          };
        } else {
          data = {
            idReferencia: 21421,
            filial: {
              id: values.filial,
            },
            cpf: values.cpf,
            nome: values.nome,
            tipo: {
              id: values.tipo,
            },
            journey: dataShiftDriver.length > 0 ? isJourney : null,
          };
        }
        const languageFormat = navigator.language;
        const emailSendLanguage =
          languageFormat === 'pt' || languageFormat === 'pt-BR'
            ? 'pt_BR'
            : 'en_US';
        if (validaCpfCnpj(values.cpf)) {
          if (driverUpdate) {
            const response = await api.patch(
              `/delivery/colaboradores/${driverUpdate}?lang=${emailSendLanguage}`,
              data
            );

            if (response.status) {
              FrontendNotification(
                t('collaborator.message.updatedDriver'),
                NotificationType.SUCCESS
              );
            }

            setEdit(false);
            setUpdateId(null);
          } else {
            const response = await api.post(
              `/delivery/colaboradores?lang=${emailSendLanguage}`,
              data
            );

            if (response.data) {
              FrontendNotification(
                t('collaborator.message.createdDriver'),
                NotificationType.SUCCESS
              );
            }
          }

          resetForm({});
        } else {
          FrontendNotification(
            t('form.field.message.driverCpfInvalid'),
            NotificationType.ERROR
          );
        }

        getCollaborator();
        sessionStorage.setItem('journeyId', '');
      } catch (err) {}
    },
    []
  );

  function selectValidation() {
    let form;
    if (
      (edit && navigator.language === 'pt') ||
      (edit && navigator.language === 'pt-BR')
    ) {
      form = formValidatorPTEdit;
    } else if (
      (edit && navigator.language === 'en') ||
      (edit && navigator.language === 'en-US')
    ) {
      form = formValidatorENEdit;
    } else if (navigator.language === 'pt' || navigator.language === 'pt-BR') {
      form = formValidatorPT;
    } else if (navigator.language === 'en' || navigator.language === 'en-US') {
      form = formValidatorEN;
    } else {
      form = formValidatorEN;
    }
    return form;
  }

  const cpfMask = (value: any) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const initialValues: FormValues = {
    cpf: '',
    nome: '',
    tipo: '',
    filial: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: selectValidation(),
    onSubmit: (values, { resetForm }) =>
      handleSubmit(
        values,
        updateId,
        resetForm,
        passwordDisabled,
        edit,
        sessionStorage.getItem('journeyId') || ''
      ),
  });

  const deleteDriver = useCallback(async (breakId: any) => {
    try {
      // setRemoveConfirmation(true);
      const language  = navigator.language;

      const erroLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
      const response = await api.delete(
        `/delivery/colaboradores/${breakId}?lang=${erroLanguage}`
      );
      if (response.status === 204) {
        FrontendNotification(
          t('Colaborador removido com sucesso!'),
          NotificationType.SUCCESS
        );
      }
      setRemoveConfirmation(false);
      getCollaborator();
    } catch (err) {
    }
  }, []);

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);


  useEffect(() => {
    getCollaborator();
    getTypeCollaborator();

    getCurrentPosition();
    getFiliais();
    formik.resetForm({});
  }, [getCollaborator, getFiliais, getTypeCollaborator]);

  return (
    <CustomModal
      isOpen
      label={t('collaborator.title')}
      isClose={onClear}
      styles={{ maxWidth: '95%' }}
    >
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      {driverShift ? (
        <DriverShift onClear={() => setDriverShift(!driverShift)} />
      ) : (
        <></>
      )}
      <SearchFilter
        isName
        isType
        isCPF
        isService
        isTypeDriver={optionsFilter === 'tipo'}
        valueSearch={valueSearch}
        optionValue={optionsFilter}
        handleChange={(e: any) => setValueSearch(e.target.value)}
        handleSelect={(e: any) => setOptionsFilter(e.target.value)}
        handleSelectDriver={(e: any) => setValueSearch(e.target.value)}
        onExecuteFilter={() => {
          setCurrentPage(0);
          if (optionsFilter === 'nome' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'nome',
                value: valueSearch,
                operation: 'MATCH',
              },
            ]);
          } else if (optionsFilter === 'tipo' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'tipo',
                value: valueSearch,
                operation: 'EQUAL',
              },
            ]);
          } else if (optionsFilter === 'cpf' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'cpf',
                value: valueSearch,
                operation: 'MATCH',
              },
            ]);
          } else {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
            ]);
          }
        }}
      />
      <div className="d-flex">
        <BoxForm>
          <Row>
            <Col md="12">
              <InputCustom
                title="CPF"
                typeInput="mask"
                mask="999.999.999-99"
                placeholder={t('collaborator.placeholder.cpf')}
                onChange={formik.handleChange('cpf')}
                value={formik.values.cpf}
                touched={formik.touched.cpf}
                error={formik.errors.cpf}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <InputCustom
                title={t('collaborator.fields.name')}
                placeholder={t('collaborator.placeholder.name')}
                onChange={formik.handleChange('nome')}
                value={formik.values.nome}
                touched={formik.touched.nome}
                error={formik.errors.nome}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <SelectCustom
                title={t('collaborator.fields.type')}
                onChange={formik.handleChange('tipo')}
                value={formik.values.tipo}
                touched={formik.touched.tipo}
                error={formik.errors.tipo}
                id='typeCollaborator'
              >
                <option label={t('common.select')} />

                {typeCollaborator.map((item: any) => (
                  <option id={item.nome} label={item.nome} value={item.id} />
                ))}
              </SelectCustom>
            </Col>
            <Col md={6}>
              <button
                className="button journey"
                id="journey"
                type="button"
                style={{ width: '100%', marginTop: responsiveSize(44) }}
                onClick={() => {
                  setDriverShift(!driverShift);
                }}
              >
                Jornada
              </button>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <SelectCustom
                title={t('filiais.driverTitle')}
                onChange={formik.handleChange('filial')}
                value={formik.values.filial}
                touched={formik.touched.filial}
                error={formik.errors.filial}
              >
                <option
                  style={{ fontSize: responsiveSize(9) }}
                  label={t('common.select')}
                />
                {filiais.map((item: ISubsidiaryData) => (
                  <option value={item.id} label={item.nome} />
                ))}
              </SelectCustom>
            </Col>
          </Row>
          <Footer>
            <Row>
              {edit ? (
                <>
                  <Col md={6}>
                    <button
                      onClick={() => {
                        formik.resetForm({});
                        setEdit(false);
                        setUpdateId(null);
                        sessionStorage.setItem('journeyId', '');
                      }}
                      id="cancel"
                      className="button"
                      type="button"
                      style={{
                        width: '100%',
                        background: '#ccc',
                        color: '#000',
                      }}
                    >
                      <Trans i18nKey="common.cancel" />
                    </button>
                  </Col>

                  <Col md={6}>
                    <button
                      onClick={() => formik.handleSubmit()}
                      id="update"
                      className="button"
                      type="button"
                      style={{ width: '100%' }}
                    >
                      <Trans i18nKey="collaborator.update" />
                    </button>
                  </Col>
                </>
              ) : (
                <Col md={12} xs={12}>
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="button"
                    id="save"
                    type="button"
                    style={{ width: '100%' }}
                  >
                    <Trans i18nKey="collaborator.title" />
                  </button>
                </Col>
              )}
            </Row>
          </Footer>
        </BoxForm>
        <Content
          style={{
            height: '50%',
            marginLeft: responsiveSize(20),
            padding: responsiveSize(40),
            paddingLeft: responsiveSize(50),
            marginTop: '50px !important',
          }}
        >
          <div className="table-responsive">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="collaborator.fields.cpf" />
                  </th>
                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="collaborator.fields.name" />
                  </th>

                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="collaborator.fields.type" />
                  </th>

                  <th />
                </tr>
              </thead>
              <tbody>
                {driver.map((item: any) => (
                  <tr className="lineGroup">
                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.cpf !== null ? cpfMask(item.cpf) : ''}
                    </td>
                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.nome}
                    </td>

                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {typeCollaborator && typeCollaborator.length > 0 && item.tipo !== null &&
                        typeCollaborator.find(
                          (type: any) => type.id === item.tipo?.id
                        )?.nome}
                    </td>
                    <IconColumn>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          // setPasswordDisabled(true);

                          formik.setFieldValue('nome', item.nome);
                          if (item.tipo !== null) {
                            formik.setFieldValue(
                              'tipo',
                              typeCollaborator.length > 0 &&
                                typeCollaborator.find(
                                  (type: any) => type.id === item.tipo.id
                                ).id
                            );
                          }
                          formik.setFieldValue('cpf', item.cpf);
                          if (item.journey !== null) {
                            sessionStorage.setItem('journeyId', item.journey.id);
                          }
                          if (item.filial && item.filial !== null) {
                            formik.setFieldValue('filial', item.filial.id);
                          }

                          if (
                            item.operadorLogistico &&
                            item.operadorLogistico !== null
                          ) {
                            formik.setFieldValue(
                              'operadorLogistico',
                              item.operadorLogistico.id
                            );
                          }

                          setEdit(true);
                          setUpdateId(item.id);
                        }}
                      >
                        <img
                          src={EditIcon}
                          alt=""
                        />
                      </Button>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          setRemoveId(item.id);
                          setRemoveConfirmation(!removeConfirmation);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                        />
                      </Button>
                    </IconColumn>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e: any, page: number) => {}}
              />
            </div>
          </div>
        </Content>
      </div>
    </CustomModal>
  );
};

export default Collaborator;
