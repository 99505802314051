export function addHeadersJourneyFlexible(data) {
  return {
    type: '@journey/ADD_HEADERS_JOURNEY_FLEXIBLE',
    payload: {
      data
    },
  }
}

export function addColumnsJourneyFlexible(indexElement, dataBreak) {
  return {
    type: '@journey/ADD_COLUMN_JOURNEY_FLEXIBLE',
    payload: {
      indexElement,
      dataBreak
    }
  }
}

export function removeHeaderJourneyFlexible(indexHeader) {
  return {
    type: '@journey/REMOVE_HEADER_JOURNEY_FLEXIBLE',
    payload: {
      indexHeader
    }
  }
}

export function removeColumnJourneyFlexible(indexElements, indexHeader) {
  return {
    type: '@journey/REMOVE_COLUMN_JOURNEY_FLEXIBLE',
    payload: {
      indexElements,
      indexHeader
    } 
  }
}

export function setJourneyDataValueMultiple(indexElements, indexHeader, text) {
  return {
    type: '@journey/SET_JOURNEY_DATA_VALUE_MULTIPLE',
    payload: {
      indexElements,
      indexHeader,
      text
    }
  }
}

export function setJourneyDataValue(indexElements, indexHeader, text) {
  return {
    type: '@journey/SET_JOURNEY_DATA_VALUE',
    payload: {
      indexElements,
      indexHeader,
      text
    }
  }
}

export function updateColumnsValue(data) {
  return {
    type: '@journey/UPDATE_JOURNEY_DATA',
    payload: {
      data
    }
  }
}

export function updateColumnsJourneyFlexible(indexElement, dataBreak, breakValue) {
  return {
    type: '@journey/UPDATE_COLUMN_JOURNEY_FLEXIBLE',
    payload: {
      indexElement,
      dataBreak,
      breakValue
    }
  }
}

export function clearColumnsJourneyFlexible() {
  return {
    type: '@journey/CLEAR_COLUMN_JOURNEY_FLEXBILE'
  }
}
