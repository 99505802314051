import CustomSelectVehicles from 'components/CustomSelectVehicles';
import InputCustom from 'components/Input';
import { inputForm } from 'components/Ocorrence/styles';
import SelectCustom from 'components/SelectCustom';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button, Col, Input, Label, Modal, Row } from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import collaboratorTeamIcon from '../../assets/images/collaboratorTeamIcon.svg';
// Assets
import DeleteIcon from 'assets/images/deleteIcon.svg';
import driverTeamIcon from '../../assets/images/driverTeamIcon.svg';
import EditVehicles from './EditVehicles';
import ListVehicles from './ListVehicles';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';
import { inputCustom, labelStyle } from './styles';
import './styles.scss';

export default function Vehicles({ onClear, isNew }) {
  const [modal, setModal] = useState(true);

  const [filialData, setFilialData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [collaboratorData, setCollaboratorData] = useState([]);
  const [vehiclesId, setVehiclesId] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [helperText, setHelperText] = useState("");

  const [tabs, setTabs] = useState(0);

  const { t } = useTranslation();

  const getFiliais = useCallback(async () => {
    try {
      const dataFilial = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post(
        '/cadastro/filiais/findByFilters',
        dataFilial
      );

      setFilialData(response.data);
    } catch (err) {}
  }, []);

  const getDriver = useCallback(async (subsidiaryId, nameDriver, listTeam) => {
    try {
      const currentSubsidiary = sessionStorage.getItem('subsidiary');
      const dataDriver = {
        filters: [
          {
            field: 'filial.id',
            value: subsidiaryId,
            operation: 'EQUAL',
          },
          {
            field: 'nome',
            operation: 'MATCH',
            value: nameDriver,
          },
        ],
        page: 0,
        size: 20,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/pageable',
        dataDriver
      );

      const sortDriver = response.data.content.sort((a, b) => {
        const isReversed = 1;

        return isReversed * a.nome.localeCompare(b.nome);
      });

      const mappingDriverData = sortDriver.map(item => {
        return {
          label: item.nome,
          value: item.id,
        };
      });

      let compareDriver = mappingDriverData.filter(
        driver =>
          !listTeam.some(selectedTeam => selectedTeam.id === driver.value)
      );

      setDriverData(compareDriver);
    } catch (err) {}
  }, []);

  const getCollaborator = useCallback(
    async (subsidiaryId, nameCollaborator, listTeam) => {
      try {
        const dataCollaborator = {
          filters: [
            {
              field: 'filial.id',
              value: subsidiaryId,
              operation: 'EQUAL',
            },
            {
              field: 'nome',
              operation: 'MATCH',
              value: nameCollaborator,
            },
          ],
          page: 0,
          size: 20,
          orders: [],
        };

        const response = await api.post(
          '/delivery/colaboradores/pageable',
          dataCollaborator
        );

        const sortCollaborator = response.data.content.sort((a, b) => {
          const isReversed = 1;

          return isReversed * a.nome.localeCompare(b.nome);
        });

        const mappingCollaboratorData = sortCollaborator.map(item => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        let compareCollaborator = mappingCollaboratorData.filter(
          collaborator =>
            !listTeam.some(
              selectedTeam => selectedTeam.id === collaborator.value
            )
        );

        setCollaboratorData(compareCollaborator);
      } catch (err) {}
    },
    []
  );

  useEffect(() => {
    getFiliais();
  }, []);

  const handleSubmit = useCallback(async (values, reset, team) => {
    try {
      let dataSubmit;
      console.log(team);
      const isVehicles = values.veiculoProprio === 'Sim';

      const getDriverTeam = team.map(item => {
        return item.id;
      });
      console.log('Driver Team', getDriverTeam);

      const getCollaboratorTeam = team
        .filter(itemFilter => itemFilter.type === 'COLLABORATOR')
        .map(item => {
          return {
            id: item.id,
          };
        });

      dataSubmit = {
        tipo: values.tipo === "3/4" ? "TRES_QUARTOS" : values.tipo === "3/4_FRIO" ? "TRES_QUARTOS_FRIO" : values.tipo,
        descricao: values.descricao,
        idReferencia: values.idReferencia.length === 0 ? null : values.idReferencia,
        motorista: {
          id: getDriverTeam[0],
        },
        filial: {
          id: values.filialId,
        },
        veiculoProprio: isVehicles,
        placa: values.placa,
        marcaModelo: values.marcaModelo,
        ano: values.ano,
        anoFabricacao: values.anoFabricacao,
        peso: values.peso,
        quantidadeAtendimento: values.quantidadeAtendimento,
        cubagem: values.cubagem,
        valor: values.valor,
        velocidadeMedia: values.velocidadeMedia,
        colaboradores: getCollaboratorTeam,
      };

      if (team.length > 0) {
        const response = await api.post('/delivery/veiculos', dataSubmit);

        if (response.status === 201) {
          FrontendNotification(
            t('vehicles.msgSuccessSave'),
            NotificationType.SUCCESS
          );

          if (isNew) {
            onClear();
            setTeamList([]);
          } else {
            reset({});
            setTeamList([]);
            setTabs(0);
          }
        }
      } else {
        FrontendNotification(
          t('vehicles.msgVehiclesRequiredTeam'),
          NotificationType.SUCCESS
        );
      }
    } catch (err) {}
  }, []);

  const formik = useFormik({
    initialValues: {
      tipo: '',
      descricao: '',
      idReferencia: '',
      motoristaId: '',
      filialId: sessionStorage.getItem('subsidiary') || '',
      veiculoProprio: '',
      placa: '',
      marcaModelo: '',
      ano: '',
      anoFabricacao: '',
      peso: '',
      quantidadeAtendimento: '',
      cubagem: '',
      valor: '',
      velocidadeMedia: '',
    },
    validationSchema:
      navigator.language === 'pt' || navigator.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,

    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, resetForm, teamList),
  });

  const customStyles = {
    control: styles => ({
      ...styles,
      border: '2px solid rgba(0, 48, 73, 0.2)',
      borderRadius: 10,
    }),
  };

  const onChangeDriver = useCallback((filialId, inputText, list) => {
    console.log(inputText);

    if (inputText.length >= 3) {
      getDriver(filialId, inputText, list);
    }
  }, []);

  const onChangeDriverTeam = useCallback((text, list) => {
    let updateTeam = list;

    const findDriverTeam = updateTeam
      .map((driverTeam, indexDriverTeam) =>
        driverTeam.type === 'DRIVER' ? indexDriverTeam : -1
      )
      .filter(driverTeamFilter => driverTeamFilter !== -1);

    if (findDriverTeam.length > 0) {
      updateTeam[findDriverTeam[0]].id = text.value;
      updateTeam[findDriverTeam[0]].nome = text.label;
    } else {
      updateTeam.unshift({
        type: 'DRIVER',
        id: text.value,
        nome: text.label,
      });
    }

    setTeamList(updateTeam);

    setDriverData([]);
  }, []);

  const onChangeCollaborator = useCallback((filialId, inputText, list) => {
    if (inputText.length >= 3) {
      getCollaborator(filialId, inputText, list);
    }
  }, []);

  const onChangeCollaboratorTeam = useCallback((text, list) => {
    let updateTeam = list;

    updateTeam.push({
      type: 'COLLABORATOR',
      id: text.value,
      nome: text.label,
    });

    setTeamList(updateTeam);

    setCollaboratorData([]);
  }, []);

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', position: 'relative' }}
      >
        <div
          className=""
          style={{
            height: '90%',
            padding: '40px',
            borderRadius: '10px',
            zIndex: 9,
            position: 'relative',
          }}
        >
          <p
            className="title"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
          >
            {tabs === 2 ? (
              <Trans i18nKey="vehicles.titleEdit" />
            ) : tabs === 1 ? (
              <Trans i18nKey="vehicles.title" />
            ) : (
              <Trans i18nKey="vehicles.titleList" />
            )}
          </p>
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => {
                setModal(!modal);
                onClear();
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <Tabs selectedIndex={tabs} onSelect={index => setTabs(index)}>
            <TabList>
              <Tab>
                <Trans i18nKey="tabs.list" />
              </Tab>
              <Tab>
                <Trans i18nKey="tabs.new" />
              </Tab>
              <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            </TabList>
            <TabPanel>
              <ListVehicles
                onEdit={idVehicles => {
                  setVehiclesId(idVehicles);
                  setTabs(2);
                }}
              />
            </TabPanel>

            <TabPanel>
              <Row style={{ marginTop: 20 }}>
                <Col md={3}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.type" />*
                  </Label>
                  <CustomSelectVehicles
                    fieldId="selectType"
                    onChange={type => formik.setFieldValue('tipo', type)}
                  />

                  {formik.touched.tipo && formik.errors.tipo && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.tipo}
                    </p>
                  )}
                </Col>
                <Col md={6}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.description" />*
                  </Label>
                  <Input
                    type="text"
                    placeholder={t('vehicles.placeholder.description')}
                    style={{
                      ...inputCustom,
                    }}
                    onChange={formik.handleChange('descricao')}
                    value={formik.values.descricao}
                  />
                  {formik.touched.descricao && formik.errors.descricao && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.descricao}
                    </p>
                  )}
                </Col>
                <Col md={3}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.idReference" />
                  </Label>
                  <Input
                    type="text"
                    placeholder={t('vehicles.placeholder.idReference')}
                    style={{
                      ...inputCustom,
                    }}
                    onChange={formik.handleChange('idReferencia')}
                    value={formik.values.idReferencia}
                  />
                </Col>
              </Row>
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: 10,
                      }}
                    >
                      <SelectCustom
                        title={t('vehicles.fields.subsidiary')}
                        type="select"
                        style={{
                          ...inputCustom,
                        }}
                        id="selectSubsidiary"
                        value={formik.values.filialId}
                        onChange={e => {
                          formik.setFieldValue('filialId', e.target.value);
                        }}
                        touched={formik.touched.filialId}
                        error={formik.errors.filialId}
                      >
                        <option label={t('vehicles.placeholder.subsidiary')} />
                        {filialData.map(itemFilial => (
                          <option
                            label={itemFilial.nome}
                            value={itemFilial.id}
                          />
                        ))}
                      </SelectCustom>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <SelectCustom
                        title={t('vehicles.fields.ownerVehicles')}
                        type="select"
                        id="selectOwnerVehicles"
                        style={{
                          ...inputCustom,
                        }}
                        onChange={formik.handleChange('veiculoProprio')}
                        touched={formik.touched.veiculoProprio}
                        error={formik.errors.veiculoProprio}
                      >
                        <option label={t('common.select')} value="" />
                        <option label="Sim" value="Sim" />
                        <option label="Nao" value="Nao" />
                      </SelectCustom>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: 10,
                      }}
                    >
                      <InputCustom
                        title={t('vehicles.fields.year')}
                        type="number"
                        placeholder={t('vehicles.placeholder.year')}
                        style={{
                          ...inputForm,
                        }}
                        maxLength="4"
                        onChange={formik.handleChange('ano')}
                        value={formik.values.ano}
                        touched={formik.touched.ano}
                        error={formik.errors.ano}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <InputCustom
                        title={t('vehicles.fields.manufacturingYear')}
                        type="number"
                        placeholder={t(
                          'vehicles.placeholder.manufacturingYear'
                        )}
                        style={{
                          ...inputForm,
                        }}
                        maxLength="4"
                        onChange={formik.handleChange('anoFabricacao')}
                        value={formik.values.anoFabricacao}
                        error={formik.errors.anoFabricacao}
                        touched={formik.touched.anoFabricacao}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: 10,
                      }}
                    >
                      <InputCustom
                        title={t('vehicles.fields.licensePlate')}
                        type="text"
                        placeholder={t('vehicles.placeholder.licensePlate')}
                        style={{
                          ...inputCustom,
                        }}
                        onChange={formik.handleChange('placa')}
                        value={formik.values.placa}
                        error={formik.errors.placa}
                        touched={formik.touched.placa}
                      />
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <InputCustom
                        title={t('vehicles.fields.brandModel')}
                        type="text"
                        placeholder={t('vehicles.placeholder.brandModel')}
                        style={{
                          ...inputCustom,
                        }}
                        onChange={formik.handleChange('marcaModelo')}
                        value={formik.values.marcaModelo}
                        error={formik.errors.marcaModelo}
                        touched={formik.touched.marcaModelo}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%' }}>
                  <div className="boxTeam">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        {formik.values.filialId.length > 0 && (
                          <React.Fragment>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                              }}
                            >
                              <Label style={{ ...labelStyle }}>Motorista</Label>
                              <Select
                                id="driver"
                                styles={customStyles}
                                options={driverData}
                                onMenuOpen={() => {
                                  setHelperText("Digite o nome do motorista");
                                }}
                                onMenuClose={() => {
                                  setHelperText("");
                                }}
                                onInputChange={inputText =>
                                  onChangeDriver(
                                    formik.values.filialId,
                                    inputText,
                                    teamList
                                  )
                                }
                                onChange={text =>
                                  onChangeDriverTeam(text, teamList)
                                }
                                value={{
                                  label: 'Digite o nome do motorista',
                                  value: '',
                                }}
                              />
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                              }}
                            >
                              <Label style={{ ...labelStyle }}>
                                Colaboradores
                              </Label>
                              <Select
                                id="collaborator"
                                styles={customStyles}
                                options={collaboratorData}
                                onInputChange={inputText =>
                                  onChangeCollaborator(
                                    formik.values.filialId,
                                    inputText,
                                    teamList
                                  )
                                }
                                onChange={text =>
                                  onChangeCollaboratorTeam(text, teamList)
                                }
                                value={{
                                  label: 'Digite o nome do colaborador',
                                  value: '',
                                }}
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Label style={{ ...labelStyle }}>Equipe</Label>
                        <div className="boxTeamList">
                          {teamList
                            .sort((a, b) => a.type - b.type)
                            .map((team, indexTeam) => (
                              <div className="listGroupTeam">
                                <img
                                  src={
                                    team.type === 'DRIVER'
                                      ? driverTeamIcon
                                      : collaboratorTeamIcon
                                  }
                                />
                                <span
                                  className={
                                    team.type === 'DRIVER'
                                      ? 'textTeamDriver'
                                      : 'textTeamCollaborator'
                                  }
                                >
                                  {team.nome}
                                </span>
                                <Button
                                  color="link"
                                  className="noMargin"
                                  onClick={() => {
                                    let removedElementTeam = teamList;

                                    const filterRemovedElement = removedElementTeam.filter(
                                      (item, index) => index !== indexTeam
                                    );

                                    setTeamList(filterRemovedElement);
                                  }}
                                >
                                  <img
                                    src={DeleteIcon}
                                    alt=""
                                  />
                                </Button>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Row style={{ marginTop: 15 }}>
                <Col md={4}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.weight" />*
                  </Label>
                  <Input
                    type="number"
                    placeholder={t('vehicles.placeholder.weight')}
                    style={{
                      ...inputForm,
                    }}
                    onChange={formik.handleChange('peso')}
                    value={formik.values.peso}
                  />
                  {formik.touched.peso && formik.errors.peso && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.peso}
                    </p>
                  )}
                </Col>
                <Col md={4}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.maxAttendances" />*
                  </Label>
                  <Input
                    type="number"
                    placeholder={t('vehicles.placeholder.maxAttendances')}
                    style={{
                      ...inputForm,
                    }}
                    onChange={formik.handleChange('quantidadeAtendimento')}
                    value={formik.values.quantidadeAtendimento}
                  />
                  {formik.touched.quantidadeAtendimento &&
                    formik.errors.quantidadeAtendimento && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.quantidadeAtendimento}
                      </p>
                    )}
                </Col>
                <Col md={4}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.cubagemMax" />*
                  </Label>
                  <Input
                    type="number"
                    placeholder={t('vehicles.placeholder.cubagemMax')}
                    style={{
                      ...inputForm,
                    }}
                    onChange={formik.handleChange('cubagem')}
                    value={formik.values.cubagem}
                  />
                  {formik.touched.cubagem && formik.errors.cubagem && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.cubagem}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.priceMax" />*
                  </Label>
                  <Input
                    type="number"
                    placeholder={t('vehicles.placeholder.priceMax')}
                    style={{
                      ...inputForm,
                    }}
                    onChange={formik.handleChange('valor')}
                    value={formik.values.valor}
                  />
                  {formik.touched.valor && formik.errors.valor && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.valor}
                    </p>
                  )}
                </Col>
                <Col md={6}>
                  <Label style={{ ...labelStyle }}>
                    <Trans i18nKey="vehicles.fields.averageSpeed" />
                  </Label>
                  <Input
                    type="number"
                    placeholder={t('vehicles.placeholder.averageSpeed')}
                    style={{
                      ...inputForm,
                    }}
                    onChange={formik.handleChange('velocidadeMedia')}
                    value={formik.values.velocidadeMedia}
                  />
                  {formik.touched.velocidadeMedia &&
                    formik.errors.velocidadeMedia && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.velocidadeMedia}
                      </p>
                    )}
                </Col>
              </Row>

              <div className="footerButton">
                <button
                  type="button"
                  id="buttonSaveVehicles"
                  className="buttonSaveVehicles"
                  onClick={formik.handleSubmit}
                >
                  <Trans i18nKey="vehicles.buttonSave" />
                </button>
              </div>

              <></>
            </TabPanel>
            <TabPanel>
              <EditVehicles
                loadVehicles={vehiclesId}
                loadList={() => setTabs(0)}
              />
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
