import CustomModal from 'components/ModalCustom';
import React from 'react';
import { Trans } from 'react-i18next';
import Zoom from 'react-medium-image-zoom';

interface Props {
  title: string;
  type: string;
  isEmpty:  boolean;
  onClear: () => void;
  urlImage: string;
}

const ModalSignature: React.FC<Props> = (props: Props) => {
  return (
    <CustomModal isOpen label={props.title} isClose={props.onClear} styles={{ minWidth: '60%' }}>
      {props.isEmpty ? (
        <>
          {props.type === 'PHOTO' ? (
            <span>
              <Trans i18nKey="common.noPhasesPhoto" />
            </span>
          ) : (
            <span>
              <Trans i18nKey="common.noPhasesSignatures" />
            </span>
          )}
        </>
      ) : (
        <div className='tw-flex tw-items-center tw-justify-center tw-w-full'>
          <Zoom>
            <img src={props.urlImage} className='img-fluid tw-max-h-80' />
          </Zoom>
        </div>
      )}
    </CustomModal>
  );
}

export default ModalSignature;