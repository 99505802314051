import React, { useRef } from 'react';
import { Map, TileLayer } from 'react-leaflet';

interface Coordinate {
  latitude: number;
  longitude: number;
}

interface Props {
  position: any;
  zoom: number;
  zoomControl: boolean;
  children: JSX.Element;
}

const RouteringMap: React.FC<Props> = (props: Props) => {
  let leafletMap = useRef();

  return (
    <Map
      ref={(m: any) => {
        leafletMap = m;
      }}
      center={props.position}
      zoom={props.zoom}
      zoomControl={props.zoomControl}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {props.children}
    </Map>
  );
}

export default RouteringMap;