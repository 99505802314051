import Skeleton from '@mui/material/Skeleton';
import React from 'react';

export default function SkeletonLoading() {
  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column' }}>

      <Skeleton variant="text" />
      <Skeleton variant="rectangular" width={210} height={118} />
      <Skeleton variant="rectangular" width={210} height={60} style={{ marginTop: 10 }} />
      <Skeleton variant="rectangular" width={210} height={100} style={{ marginTop: 10 }} />
      <Skeleton variant="rectangular" width={210} height={60} style={{ marginTop: 10 }} />
      </div>

    </React.Fragment>
  );
}
