/* eslint-disable operator-assignment */
import produce from 'immer';

const INITIAL_STATE = {
  accumulatorValue: {
    accumulatorAttendances: 0,
    accumulatorWeight: 0,
    accumulatorVolume: 0,
    accumulatorPrice: 0,
  },
  accumulatorValueCarga: {
    accumulatorAttendances: 0,
    accumulatorWeight: 0,
    accumulatorVolume: 0,

    accumulatorPrice: 0,
  },
  accumulatorPorcentage: {
    accumulatorAttendancesPorcentage: 0,
    accumulatorWeightPorcentage: 0,
    accumulatorVolumePorcentage: 0,

    accumulatorPricePorcentage: 0,
  },
};

export default function accumulatorRoutering(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@accumulator/UPDATE_ACCUMULATOR_CARGA': {
        draft.accumulatorValueCarga.accumulatorAttendances =
          action.payload.accumulatorAttendances
        draft.accumulatorValueCarga.accumulatorWeight =
          action.payload.accumulatorWeight
        draft.accumulatorValueCarga.accumulatorVolume =
          action.payload.accumulatorVolume
        draft.accumulatorValueCarga.accumulatorPrice =
          action.payload.accumulatorPrice
        break;
      }
      case '@accumulator/ADD_ACCUMULATOR_CARGA': {
        draft.accumulatorValueCarga.accumulatorAttendances =
          draft.accumulatorValueCarga.accumulatorAttendances +
          action.payload.accumulatorAttendances;
        draft.accumulatorValueCarga.accumulatorWeight =
          draft.accumulatorValueCarga.accumulatorWeight +
          action.payload.accumulatorWeight;
        draft.accumulatorValueCarga.accumulatorVolume =
          draft.accumulatorValueCarga.accumulatorVolume +
          action.payload.accumulatorVolume;
        draft.accumulatorValueCarga.accumulatorPrice =
          parseFloat(draft.accumulatorValueCarga.accumulatorPrice) +
          parseFloat(action.payload.accumulatorPrice);
        break;
      }
      case '@accumulator/ADD_ACCUMULATOR_VEHICLE': {
        draft.accumulatorValue.accumulatorAttendances =
          draft.accumulatorValue.accumulatorAttendances +
          action.payload.accumulatorAttendances;
        draft.accumulatorValue.accumulatorWeight =
          draft.accumulatorValue.accumulatorWeight +
          action.payload.accumulatorWeight;
        draft.accumulatorValue.accumulatorVolume =
          draft.accumulatorValue.accumulatorVolume +
          action.payload.accumulatorVolume;
        draft.accumulatorValue.accumulatorPrice =
          draft.accumulatorValue.accumulatorPrice +
          action.payload.accumulatorPrice;

        break;
      }
      case '@accumulator/REMOVE_ACCUMULATOR_CARGA': {
        draft.accumulatorValueCarga.accumulatorAttendances =
          draft.accumulatorValueCarga.accumulatorAttendances - 1;
        draft.accumulatorValueCarga.accumulatorWeight =
          parseInt(draft.accumulatorValueCarga.accumulatorWeight) -
          parseInt(action.payload.accumulatorWeight);
        draft.accumulatorValueCarga.accumulatorVolume =
          parseInt(draft.accumulatorValueCarga.accumulatorVolume) -
          parseInt(action.payload.accumulatorVolume);
        draft.accumulatorValueCarga.accumulatorPrice =
          parseInt(draft.accumulatorValueCarga.accumulatorPrice) -
          parseInt(action.payload.accumulatorPrice);
        break;
      }
      case '@accumulator/REMOVE_ACCUMULATOR_VEHICLE': {
        draft.accumulatorValue.accumulatorAttendances =
          draft.accumulatorValue.accumulatorAttendances -
          action.payload.accumulatorAttendances;
        draft.accumulatorValue.accumulatorWeight =
          draft.accumulatorValue.accumulatorWeight -
          action.payload.accumulatorWeight;
        draft.accumulatorValue.accumulatorVolume =
          draft.accumulatorValue.accumulatorVolume -
          action.payload.accumulatorVolume;
        draft.accumulatorValue.accumulatorPrice =
          draft.accumulatorValue.accumulatorPrice -
          action.payload.accumulatorPrice;
        break;
      }
      case '@accumulator/GET_PORCENTAGE_ACCUMULATOR': {
        draft.accumulatorPorcentage.accumulatorAttendancesPorcentage =
          (draft.accumulatorValueCarga.accumulatorAttendances /
            draft.accumulatorValue.accumulatorAttendances) *
          100;
        draft.accumulatorPorcentage.accumulatorWeightPorcentage =
          (draft.accumulatorValueCarga.accumulatorWeight /
            draft.accumulatorValue.accumulatorWeight) *
          100;
        draft.accumulatorPorcentage.accumulatorVolumePorcentage =
          (draft.accumulatorValueCarga.accumulatorVolume /
            draft.accumulatorValue.accumulatorVolume) *
          100;
        draft.accumulatorPorcentage.accumulatorPricePorcentage =
          (draft.accumulatorValueCarga.accumulatorPrice /
            draft.accumulatorValue.accumulatorPrice) *
          100;
        break;
      }
      case '@accumulator/RESET_VALUE_ACCUMULATOR': {
        draft.accumulatorPorcentage.accumulatorAttendancesPorcentage = 0;

        draft.accumulatorPorcentage.accumulatorWeightPorcentage = 0;
        draft.accumulatorPorcentage.accumulatorVolumePorcentage = 0;
        draft.accumulatorPorcentage.accumulatorPricePorcentage = 0;
        draft.accumulatorValue.accumulatorAttendances = 0;
        draft.accumulatorValue.accumulatorWeight = 0;
        draft.accumulatorValue.accumulatorVolume = 0;
        draft.accumulatorValue.accumulatorPrice = 0;
        draft.accumulatorValueCarga.accumulatorAttendances = 0;
        draft.accumulatorValueCarga.accumulatorWeight = 0;
        draft.accumulatorValueCarga.accumulatorVolume = 0;
        draft.accumulatorValueCarga.accumulatorPrice = 0;

        break;
      }

      case '@accumulator/RESET_VEHICLES_ACCUMULATOR': {
        draft.accumulatorPorcentage.accumulatorAttendancesPorcentage = 0;

        draft.accumulatorPorcentage.accumulatorWeightPorcentage = 0;
        draft.accumulatorPorcentage.accumulatorVolumePorcentage = 0;
        draft.accumulatorPorcentage.accumulatorPricePorcentage = 0;
        draft.accumulatorValue.accumulatorAttendances = 0;
        draft.accumulatorValue.accumulatorWeight = 0;
        draft.accumulatorValue.accumulatorVolume = 0;
        draft.accumulatorValue.accumulatorPrice = 0;

        break;
      }

      case '@accumulator/RESET_CARGA_ACCUMULATOR': {
        draft.accumulatorPorcentage.accumulatorAttendancesPorcentage = 0;

        draft.accumulatorPorcentage.accumulatorWeightPorcentage = 0;
        draft.accumulatorPorcentage.accumulatorVolumePorcentage = 0;
        draft.accumulatorPorcentage.accumulatorPricePorcentage = 0;
        draft.accumulatorValueCarga.accumulatorAttendances = 0;
        draft.accumulatorValueCarga.accumulatorWeight = 0;
        draft.accumulatorValueCarga.accumulatorVolume = 0;
        draft.accumulatorValueCarga.accumulatorPrice = 0;

        break;
      }

      default:
    }
  });
}
