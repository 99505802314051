import React from 'react';
import { Trans } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

interface CustomTabsProps {
  tabSelected: number;
  onChange: (selected: number) => void;
  renderTab1: JSX.Element;
  renderTab2: JSX.Element;
  renderTab3: JSX.Element;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabSelected,
  onChange,
  renderTab1,
  renderTab2,
  renderTab3,
}: CustomTabsProps) => {
  return (
    <Tabs
      selectedIndex={tabSelected}
      onSelect={(index: number) => onChange(index)}
    >
      <TabList>
        <Tab>
          <Trans i18nKey="tabs.list" />
        </Tab>
        <Tab>
          <Trans i18nKey="tabs.new" />
        </Tab>
        <Tab disabled>{tabSelected === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
      </TabList>
      <TabPanel>{renderTab1}</TabPanel>
      <TabPanel>{renderTab2}</TabPanel>
      <TabPanel>{renderTab3}</TabPanel>
    </Tabs>
  );
};

export default CustomTabs;
