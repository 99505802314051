// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buttonSaveVehicles {
  width: 350px;
  max-width: 100%;
  height: 35px;
  background: #EA004C;
  box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);
  border-radius: 6px;
  border: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Vehicles/EditVehicles/styles.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,qBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,YAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,6CAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EAEA,cAAA;EACA,gBAAA;AAFJ","sourcesContent":["\n\n.footerButton {\n    display: flex;\n    align-items: flex-end;\n    justify-content: flex-end;\n}\n\n.buttonSaveVehicles {\n    width: 350px;\n    max-width: 100%;\n    height: 35px;\n    background: #EA004C;\n    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);\n    border-radius: 6px;\n    border: none;\n    font-family: Lato;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 19px;\n    text-align: center;\n\n    color: #FFFFFF;\n    margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
