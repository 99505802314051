import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap/lib';
import { saveSubsidiary } from 'store/modules/map/actions';
import './style.scss';



interface Filial {
  nome: string;
  id: number;
}

interface Props {
  listSubsidiary: Filial[];
  onChangeSubsidiary: (id: string) => void;
}

const TopBar: React.FC<Props> = ({ listSubsidiary, onChangeSubsidiary }) => {
  const [dataSubsidiary, setDataSubsidiary] = useState<Filial[]>([]);
  const [idSubsidiary, setIdSubisidiary] = useState<string>('');

  const userData = useSelector((state: any) => state.auth.user);

  const getSubsidiaryId = useSelector((state: any) => state.map.subsidiaryId);

  const dispatch = useDispatch();

  const getSubsidiaryUsers = useCallback(async () => {
    try {
      setIdSubisidiary(userData.filiais[0].id);
      dispatch(saveSubsidiary(userData.filiais[0].id));
      sessionStorage.setItem('subsidiary', userData.filiais[0].id);
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (listSubsidiary) {
      setDataSubsidiary(listSubsidiary);
    }
    getSubsidiaryUsers();
  }, [listSubsidiary, getSubsidiaryUsers]);

  return (
    <div className="topBar">
      <Form inline>
        <div className="filialLabel">
          <span>

          <Trans i18nKey="filter.subsidiary" />
          </span>
          <select
            name="filialSelector"
            id="filial1"
            className="filialInput"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              sessionStorage.setItem('subsidiary', e.target.value);
              dispatch(saveSubsidiary(e.target.value));
              setIdSubisidiary(e.target.value);
              onChangeSubsidiary(e.target.value);
            }}
            value={getSubsidiaryId}
          >
            <option label="Todas" value="-1" />
            {dataSubsidiary.map((subsidiary: Filial) => (
              <option key={subsidiary.id} value={subsidiary.id}>
                {subsidiary.nome}
              </option>
            ))}
          </select>
        </div>
      </Form>
    </div>
  );
};

export default TopBar;
