import React from 'react';
import SearchFilter from 'components/SearchFilter';

interface FiltersProps {
  options: string;
  textSearch: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOptions: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onExecuteFilter?: any;
}

interface Filters {
  field: string;
  value: string;
  operation: string;
}

const CustomFilters: React.FC<FiltersProps> = ({
  options,
  textSearch,
  onChangeSearch,
  onChangeOptions,
  onExecuteFilter,
}: FiltersProps) => {
  return (
    <>
      <SearchFilter
        isDescription
        isService
        isTypeDriver={options === 'tipo'}
        valueSearch={textSearch}
        optionValue={options}
        handleChange={(e: any) => onChangeSearch(e.target.value)}
        handleSelect={(e: any) => onChangeOptions(e.target.value)}
        handleSelectDriver={(e: any) => onChangeSearch(e.target.value)}
        onExecuteFilter={() => {
          if (options === 'descricao' && textSearch.length > 0) {
            onExecuteFilter([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL'
              },
              {
                field: 'descricao',
                value: textSearch,
                operation: 'MATCH'
              },
            ]);
          } else {
            onExecuteFilter([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL'
              },
            ]);
          }
        } } handleMultipleSelect={undefined} valueStartDate={undefined} valueFinalDate={undefined} toggleStartDate={undefined} toggleEndDate={undefined} optionCollection={undefined} isActivity={undefined} isContractor={undefined} isNumber={undefined} isUpdateDate={undefined} isUserName={undefined} isName={undefined} isType={undefined} isCPF={undefined} isCNPJ={undefined} isAvaliableVehicle={undefined} isTypeVehicleDriver={undefined} isTrademarkName={undefined} isCompanyName={undefined} isInitialDate={undefined} isFinalDate={undefined} isRange={undefined} isAvaliable={undefined} isLicensePlate={undefined} isTypeVehicle={undefined} isIDVehicle={undefined} isDriver={undefined} isMaximumService={undefined} isMaximumWeight={undefined} isMaximumVolume={undefined} isMaximumPrice={undefined} style={undefined}      />
    </>
  );
};

export default CustomFilters;
