import PropTypes from 'prop-types';
import './styles.scss';

export default function Switch({ active, onChange, fieldId }) {
  return (
    <div className="switch" onClick={onChange} id={fieldId}>
      {active ? (
        <div className="switchChecked" />
      ) : (
        <div className="switchUnchecked" />
      )}
    </div>
  );
}

Switch.propTypes = {
  active: PropTypes.bool,
  onChange: PropTypes.func,
};
Switch.defaultProps = {
  active: false,
  onChange: null,
};
