// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-class {
  margin-top: 30px;
  margin-bottom: 90px;
}

.editor-class {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.preview {
  margin-top: 10px;
  margin-bottom: 50px;
}

.sc-elJkPf {
  margin-top: 0px;
  border: 1px solid rgba(0, 48, 73, 0.2);
  border-radius: 10px;
}

#rdw-wrapper-6863 {
  border: none;
}

#editing, #highlighting {
  /* Both elements need the same text and space styling so they are directly on top of each other */
  margin: 10px;
  padding: 10px;
  border: 0;
  width: calc(100% - 32px);
  height: 150px;
}

#editing, #highlighting, #highlighting * {
  /* Also add text styles to highlighting tokens */
  font-size: 15pt;
  font-family: monospace;
  line-height: 20pt;
}`, "",{"version":3,"sources":["webpack://./src/components/TemplateModal/MyEditor/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,sBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AACA;EACE,sBAAA;AAEF;;AAAA;EACE,gBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,eAAA;EACA,sCAAA;EACA,mBAAA;AAGF;;AAAA;EACE,YAAA;AAGF;;AAAA;EACE,iGAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;EACA,wBAAA;EACA,aAAA;AAGF;;AAAA;EACE,gDAAA;EACA,eAAA;EACA,sBAAA;EACA,iBAAA;AAGF","sourcesContent":[".wrapper-class {\n  margin-top: 30px;\n  margin-bottom: 90px;\n  //border: 1px solid #ccc;\n}\n.editor-class {\n  background-color:#fff;\n  padding: 1rem;\n  border: 1px solid #ccc;\n}\n.toolbar-class {\n  border: 1px solid #ccc;\n}\n.preview {\n  margin-top: 10px;\n  margin-bottom: 50px;\n}\n\n.sc-elJkPf {\n  margin-top: 0px;\n  border: 1px solid rgba(0, 48, 73,0.2);\n  border-radius: 10px;\n}\n\n#rdw-wrapper-6863 {\n  border: none;\n}\n\n#editing, #highlighting {\n  /* Both elements need the same text and space styling so they are directly on top of each other */\n  margin: 10px;\n  padding: 10px;\n  border: 0;\n  width: calc(100% - 32px);\n  height: 150px;\n}\n\n#editing, #highlighting, #highlighting * {\n  /* Also add text styles to highlighting tokens */\n  font-size: 15pt;\n  font-family: monospace;\n  line-height: 20pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
