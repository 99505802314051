// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectView {
  display: flex;
  flex-direction: row;
}

.imgNotice {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.textSelect {
  color: #000;
}

.iconView {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.textNotice {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 600;
  color: #EA004C;
}

.textNoticeInformation {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 600;
  color: #006CEA;
}

.textNoticeWarning {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 600;
  color: #EA7000;
}`, "",{"version":3,"sources":["webpack://./src/components/Notices/CustomSelect/styles.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;AADJ;;AAIA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,kBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ;;AAIA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ;;AAIA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AADJ","sourcesContent":["\n\n.selectView {\n    display: flex;\n    flex-direction: row;\n}\n\n.imgNotice {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}\n\n.textSelect {\n    color: #000;\n}\n\n.iconView {\n    position: absolute;\n    right: 0;\n    margin-right: 10px;\n}\n\n.textNotice {\n    font-family: 'Lato';\n    font-size: 15px;\n    font-weight: 600;\n    color: #EA004C;\n}\n\n.textNoticeInformation {\n    font-family: 'Lato';\n    font-size: 15px;\n    font-weight: 600;\n    color: #006CEA;\n}\n\n.textNoticeWarning {\n    font-family: 'Lato';\n    font-size: 15px;\n    font-weight: 600;\n    color: #EA7000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
