import React, { useState } from 'react';
import whatsappIcon from '../../../../../../assets/images/whatsappIcon.svg';
import CustomModal from '../../../../../../components/ModalCustom';
import './styles.scss';

interface DialogAppVersionProps {
  phoneNumber: string;
  phoneVersion: string;
  onClear: () => void;
}

const DialogAppVersion: React.FC<DialogAppVersionProps> = ({phoneNumber, phoneVersion, onClear}) => {
  const [open, setOpen] = React.useState(true);
  const [phone, setPhone] = useState('')

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomModal
      isOpen
      label="Informações"
      isClose={onClear}
      styles={{ maxWidth: '30vw', maxHeight: 93 }}
    >
      <div className="appInfoContent">
        <div className="appInfoVersion">
          <span className="appInfoVersionTitle">Versão</span>
          <span className="appInfoVersionText">{phoneVersion && phoneVersion.length > 0 ? phoneVersion : '---'}</span>
        </div>
        <div className="appInfoPhoneNumber">
          <span className="appInfoPhoneNumberTitle">Número</span>
          <span className="appInfoPhoneNumberText">
            {phoneNumber}{' '}
            <a
              href={phone}
              onClick={(event) => {
                event.stopPropagation();
                let number = phoneNumber.replace('(', '').replace(')', '').replace('-', '')
                setPhone(
                  `https://web.whatsapp.com/send?phone=+55${number}`
                );
              }}
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsappIcon} className="phoneNumberWhatsapp" alt="" />{' '}
            </a>
          </span>
        </div>
      </div>
    </CustomModal>
  );
}

export default DialogAppVersion;