export const States = [
  {
    id: 1,
    nome: 'Pernambuco',
    sigla: 'PE',
  },
  {
    id: 2,
    nome: 'Distrito Federal',
    sigla: 'DF',
  },
  {
    id: 3,
    nome: 'Sergipe',
    sigla: 'SE',
  },
  {
    id: 4,
    nome: 'Santa Catarina',
    sigla: 'SC',
  },
  {
    id: 5,
    nome: 'Roraima',
    sigla: 'RR',
  },
  {
    id: 6,
    nome: 'Rondônia',
    sigla: 'RO',
  },
  {
    id: 7,
    nome: 'Pará',
    sigla: 'PA',
  },
  {
    id: 8,
    nome: 'Mato Grosso do Sul',
    sigla: 'MS',
  },
  {
    id: 9,
    nome: 'Mato Grosso',
    sigla: 'MT',
  },
  {
    id: 10,
    nome: 'Maranhão',
    sigla: 'MA',
  },
  {
    id: 11,
    nome: 'Goiás',
    sigla: 'GO',
  },
  {
    id: 12,
    nome: 'Espírito Santo',
    sigla: 'ES',
  },
  {
    id: 13,
    nome: 'Amapá',
    sigla: 'AP',
  },
  {
    id: 14,
    nome: 'Ceará',
    sigla: 'CE',
  },
  {
    id: 15,
    nome: 'Acre',
    sigla: 'AC',
  },
  {
    id: 16,
    nome: 'Rio Grande do Sul',
    sigla: 'RS',
  },
  {
    id: 17,
    nome: 'Minas Gerais',
    sigla: 'MG',
  },
  {
    id: 18,
    nome: 'São Paulo',
    sigla: 'SP',
  },
  {
    id: 19,
    nome: 'Paraíba',
    sigla: 'PB',
  },
  {
    id: 20,
    nome: 'Amazonas',
    sigla: 'AM',
  },
  {
    id: 21,
    nome: 'Piauí',
    sigla: 'PI',
  },
  {
    id: 22,
    nome: 'Rio Grande do Norte',
    sigla: 'RN',
  },
  {
    id: 23,
    nome: 'Bahia',
    sigla: 'BA',
  },
  {
    id: 24,
    nome: 'Paraná',
    sigla: 'PR',
  },
  {
    id: 25,
    nome: 'Rio de Janeiro',
    sigla: 'RJ',
  },
  {
    id: 26,
    nome: 'Alagoas',
    sigla: 'AL',
  },
  {
    id: 27,
    nome: 'Tocantins',
    sigla: 'TO',
  },
];

export const Status = [
  {
    id: 1,
    nome: '001 - Aberto',
  },
  {
    id: 2,
    nome: '002 - Cancelado',
  },
  {
    id: 3,
    nome: '003 - Pendente',
  },
  {
    id: 4,
    nome: '004 - Devolução',
  },
  {
    id: 5,
    nome: '005 - Em Atraso',
  },
  {
    id: 6,
    nome: '006 - Saiu Para Entrega',
  },
  {
    id: 7,
    nome: '007 - Entregue',
  },
];
