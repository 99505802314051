import { ICellRendererParams } from 'ag-grid-community';
import checkedIcon from 'assets/images/towerChecked.svg';


export const checkboxRenderer = (params: ICellRendererParams) => {


  return (
    <div
    className="tw-flex tw-w-4 tw-h-4 tw-bg-[#fff] tw-border tw-border-[#000] tw-rounded-[4px] tw-cursor-pointer tw-p-[2px] tw-mr-2 tw-ml-0 tw-justify-center tw-items-center tw-mt-2"
  >
    <div className="tw-flex tw-items-center tw-justify-center">
      {params.data.recurring ? (
        <div className="tw-bg-[#EA004C] tw-rounded-[4px] tw-border-none tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-p-2">
          <img src={checkedIcon} className="tw-w-3 tw-h-3" />
        </div>
      ) : (
        <div className="tw-w-4 tw-h-4 tw-rounded-[4px] tw-bg-[#fff]" style={{ border: '1px solid #EA004C' }} />
      )}
    </div>
  </div>
  );
};