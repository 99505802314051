import CustomModal from 'components/ModalCustom';
import SelectCustom from 'components/SelectCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import formValidation from './formValidation';
import {
    ButtonSaveJourney
} from './styles';

interface Props {
  onClear?: any;
}

const DriverShift: React.FC<Props> = ({ onClear }: Props) => {
  const { t } = useTranslation();
  const [checkedJourney, setCheckedJourney] = useState(false);
  const [checkedJourneyFixed, setCheckedJourneyFixed] = useState(false);
  const [checkedJourneyFlexible, setCheckedJourneyFlexible] = useState(false);
  const [checkedJourneyRangeFlexible, setCheckedJourneyRangFlexible] = useState(
    false
  );
  const [dataJourney, setDataJourney] = useState<any>([]);

  const formik = useFormik({
    initialValues: {
      journeyId: ''
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      sessionStorage.setItem('journeyId', values.journeyId);
      FrontendNotification(t('driver.journey'), NotificationType.SUCCESS);
      onClear()
    }
  })

  const getListJourney = useCallback(async () => {
    try { 
      const response: any = await api.get('/workday/journeys');

      setDataJourney(response.data)

      formik.setFieldValue('journeyId', sessionStorage.getItem('journeyId') || '')
    }catch(err) {}
  }, []);



  useEffect(() => {
    getListJourney();
  }, [getListJourney])

  return (
    <CustomModal
      isOpen
      label={t('driverShift.title')}
      isClose={onClear}
      styles={{ maxWidth: '50%' }}
    >
      <Row>
        <Col md={6}>
          <SelectCustom
            onChange={formik.handleChange('journeyId')}
            value={formik.values.journeyId}
            touched={formik.touched.journeyId}
            error={formik.errors.journeyId}
            id='journeyId'
          >
            <option label={t('common.select')}></option>
            {dataJourney.map((item: any) => (
              <option label={item.name} value={item.id} />
            ))}
          </SelectCustom>
        </Col>
        <Col md={3}>
          <ButtonSaveJourney className="button" type='button' id='buttonSaveJourney' onClick={() => formik.handleSubmit()}>Salvar</ButtonSaveJourney>
        </Col>
      </Row>
      
    </CustomModal>
  );
};

export default DriverShift;
