import MultiSelect from '@khanacademy/react-multi-select';
import { makeStyles } from '@material-ui/core';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';

export const CustomMultiSelect = styled(MultiSelect)`
  .select-item .item-renderer input {
    display: none;
  }

  .select-item .item-renderer span {
    width: 12px;
    height: 12px;
    display: block;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  .select-item .item-renderer input:checked + span {
    background-color: #c03;
  }
`;

export const GroupInput = styled.div`
  .select {
    width: 20.875rem;
  }
  .filialInput2 {
    width: 10.875rem !important;
  }
`;

export const animatedComponents = makeAnimated();

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'black',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />

    backgroundColor: '#fff',
    border: '2px solid rgba(0, 48, 73, 0.2)',
    height: 60,
    borderRadius: '10px',
    display: 'flex',
    // paddingBottom: '5px',
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

export const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 160,

    width: '100%',
    marginTop: 10,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    background: '#E1E0E0',
    color: '#003049',
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
