import CustomModal from 'components/ModalCustom';
import { Cargo } from 'pages/ControlTower/ListAttendances/interfaces/types';
import React from 'react';
import { Trans } from 'react-i18next';

interface Props {
  title: string;
  onClear: () => void;
  data: Cargo;
}


const ModalReceiver: React.FC<Props> = (props: Props) => {
  return (
    <CustomModal isOpen label={props.title} isClose={props.onClear} styles={{ minWidth: '40%' }}>
      <div className='tw-flex tw-full'>
        <div className='tw-flex tw-flex-col'>
          <div className='tw-flex tw-flex-row'>
            <span className='tw-text-sm tw-font-bold tw-text-[#003049]'>
            <Trans i18nKey="knowledge.nameReceiver" />:
            </span>
            <span>{props.data && props.data.nomeRecebedor !== null ? props.data.nomeRecebedor : '----'}</span>
          </div>
          <div className='tw-flex tw-flex-row tw-mt-4'>
            <span className='tw-text-sm tw-font-bold tw-text-[#003049]'>
            <Trans i18nKey="knowledge.docReceiver" />:
            </span>
            <span>{props.data && props.data.documento !== null ? props.data.documento : '----'}</span>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default ModalReceiver;