import { object, ref, string } from 'yup';

export default object().shape({
  cpf: string().required('* Enter the cpf!'),
  nome: string()
    .required('* Enter the name!')
    .max(50, '* Maximum of 50 characters'),
  tipo: string().required('* Enter the type!'),
  filial: string().required('* Enter the subsidiary'),
});
