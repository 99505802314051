export function setSelectedVehicles(selectedVehiclesArr) {
  return {
    type: '@vehicles/ADD_SELECTED_VEHICLES',
    payload: {
      selectedVehiclesArr,
    },
  };
}

export function setDateForecast(date) {
  return {
    type: '@vehicles/SET_DATE_FORECAST',
    payload: {
      date
    }
  }
}

export function updateSelectedVehicles(selectedVehiclesArr) {
  return {
    type: '@vehicles/UPDATE_SELECTED_VEHICLES',
    payload: {
      selectedVehiclesArr,
    },
  };
}

export function removeSelectedVehicles(indexSelectedVehicles) {
  return {
    type: '@vehicles/REMOVE_SELECTED_VEHICLES',
    payload: {
      indexSelectedVehicles,
    },
  };
}

export function updateVehiclesRoadTrip(indexSelectedVehicles) {
  return {
    type: '@vehicles/UPDATE_VEHICLES_ROADTRIP',
    payload: {
      indexSelectedVehicles,
    },
  };
}

export function updateVehiclesForecast(indexSelectedVehicles, value) {
  return {
    type: '@vehicles/UPDATE_VEHICLES_OUTPUT_FORECAST',
    payload: {
      indexSelectedVehicles,
      value,
    },
  };
}

export function clearVehiclesSelected() {
  return {
    type: '@vehicles/CLEAR_VEHICLES_SELECTED',
  };
}
