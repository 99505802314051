/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import i18next from 'i18next';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import makeAnimated from 'react-select/animated';
import { inputForm } from '../Ocorrence/styles';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from '../../core/common/Notification';
import api from '../../services/api';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import { BoxForm, Footer } from '../Reasons/styles';
import formValidatorPT from './formValidatorPT';
import formValidatorEN from './formValidatorEN';
import ListContractor from './ListContractor';

export default function Contractor({ onClear, active }) {
  const [contractor, setContractor] = useState([]);
  const [contractorId, setContractorId] = useState(0);
  const [tabs, setTabs] = useState(0);
  const useStyles = makeStyles(theme => ({
    formControl: {
      minWidth: 160,

      width: '100%',
      marginTop: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      background: '#E1E0E0',
      color: '#003049',
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  const [userErrors, setUserErrors] = useState(false);
  const classes = useStyles();
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userCollection, setUserCollection] = useState([]);
  const [users, setUsers] = useState([]);

  const [removeId, setRemoveId] = useState('');

  const ref = useRef();
  const dispatch = useDispatch();

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  const animatedComponents = makeAnimated();
  const { t } = useTranslation();
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />

      backgroundColor: '#fff',
      border: '2px solid rgba(0, 48, 73, 0.2)',
      height: 60,
      borderRadius: '10px',
      display: 'flex',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const getContractorData = useCallback(async () => {
    try {
      const { data } = await api.get('/delivery/contratantes');

      setContractor(data);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const handleSubmit = useCallback(
    async (values, collection, reset, contractorUpdate) => {
      try {
        if (validarCNPJ(values.cnpj)) {
          let usersContractor = [];
          usersContractor = await Promise.all(
            collection.map(element => {
              return { id: element.value };
            })
          );
          const data = {
            idReferencia: values.referenceId,
            pessoaJuridica: {
              nomeFantasia: values.trademarkName,
              razaoSocial: values.companyName,
              cnpj: values.cnpj.replace(/[^\d]+/g, ''),
            },
            usuarios: usersContractor,
          };
          if (contractorUpdate) {
            const response = await api.patch(
              `/delivery/contratantes/${contractorUpdate}`,
              data
            );

            if (response.status === 204) {
              FrontendNotification(
                t('contractor.messageUpdated'),
                NotificationType.SUCCESS
              );
              if (active === true) {
                onClear();
                setModal(!modal);
              }
            }
            setEdit(false);
            setUpdateId();
          } else {
            const response = await api.post(`/delivery/contratantes`, data);
            if (response.status) {
              FrontendNotification(
                t('contractor.messageCreated'),
                NotificationType.SUCCESS
              );
            }
          }
          formik.handleReset();
          setUserCollection([]);
          getContractorData();
        } else {
          FrontendNotification('CNPJ Inválido!', NotificationType.ERROR);
        }
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [dispatch, userCollection]
  );

  const deleteContractor = useCallback(async () => {
    try {
      const response = await api.delete(
        `delivery/contratantes/${contractorId}`
      );

      if (response.status === 204) {
        FrontendNotification(
          t('contractor.messageRemove'),
          NotificationType.SUCCESS
        );
      }

      setRemoveConfirmation(false);

      getContractorData();
    } catch (err) {
      // BackendErrorNotification(err);
    }
  }, [contractorId, getContractorData, t]);

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const getLanguages = () => {
    return i18next.language;
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const currentData = contractor.slice(indexOfFirstPage, indexOfLastPage);

  const formik = useFormik({
    initialValues: {
      referenceId: '',
      cnpj: '',
      trademarkName: '',
      companyName: '',
    },
    validationSchema:
      getLanguages() === 'pt' || getLanguages() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, userCollection, resetForm, updateId),
  });

  const getUsers = useCallback(async () => {
    try {
      const response = await api.get('/acesso/usuarios');

      const filterUser = response.data.filter(item => item.status === 'ACTIVE');

      const mappingFilial = filterUser.map(item => {
        return {
          value: item.id,
          label: item.nome,
        };
      });
      setUsers(mappingFilial);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  useEffect(() => {
    getContractorData();
    getUsers();
  }, [getContractorData, getUsers]);

  const tooglePermission = useCallback(selectedOption => {
    setUserCollection(selectedOption);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuOpen]);

  const getContractorInfo = useCallback(
    async id => {
      try {
        const { data } = await api.get(`/delivery/contratantes/${id}`);
        formik.setFieldValue('referenceId', data.idReferencia);
        formik.setFieldValue('cnpj', data.pessoaJuridica.cnpj);
        formik.setFieldValue('trademarkName', data.pessoaJuridica.nomeFantasia);
        formik.setFieldValue('companyName', data.pessoaJuridica.razaoSocial);

        const userMapping = data.usuarios.map(item => {
          return {
            label: item.nome,
            value: item.id,
          };
        });
        setUserCollection(userMapping);
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [formik]
  );

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteContractor(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', height: '100%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            {/* Nova Notificação */}
            {tabs === 0 ? (
              <Trans i18nKey="contractor.list" />
            ) : (
              <Trans i18nKey="contractor.new" />
            )}
          </p>
          <Tabs
            selectedIndex={tabs}
            onSelect={index => {
              setTabs(index);
            }}
          >
            <TabList>
              <Tab>
                <Trans i18nKey="tabs.list" />
              </Tab>
              <Tab>
                <Trans i18nKey="tabs.new" />
              </Tab>
              <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            </TabList>
            <TabPanel>
              <ListContractor
                data={contractor}
                handleEdit={id => {
                  setTabs(2);
                  setEdit(true);
                  setUpdateId(id);
                  setContractorId(id);
                  getContractorInfo(id);
                }}
                handleDelete={id => {
                  setContractorId(id);
                  setRemoveConfirmation(true);
                }}
              />
            </TabPanel>
            <TabPanel>
              <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
                <BoxForm>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.referenceId" />
                    </Label>

                    <Input
                      type="number"
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.referenceId')}
                      onChange={formik.handleChange('referenceId')}
                      style={{
                        ...inputForm,
                        maxWidth: 450,
                        height: 60,
                      }}
                      value={formik.values.referenceId}
                    />
                    {formik.touched.referenceId &&
                      formik.errors.referenceId && (
                        <p style={{ fontSize: 14, color: 'red' }}>
                          {formik.errors.referenceId}
                        </p>
                      )}
                  </FormControl>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.cnpj" />
                    </Label>

                    <ReactInputMask
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.cnpj')}
                      onChange={formik.handleChange('cnpj')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.cnpj}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </ReactInputMask>
                  </FormControl>
                  {formik.touched.cnpj && formik.errors.cnpj && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.cnpj}
                    </p>
                  )}
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.social" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.social')}
                      onChange={formik.handleChange('companyName')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.companyName}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </Input>
                  </FormControl>
                  {formik.touched.companyName && formik.errors.companyName && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.companyName}
                    </p>
                  )}
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.fantasyName" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.fantasyName')}
                      onChange={formik.handleChange('trademarkName')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.trademarkName}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </Input>
                  </FormControl>
                  {formik.touched.trademarkName &&
                    formik.errors.trademarkName && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.trademarkName}
                      </p>
                    )}

                  <Footer>
                    <button
                      onClick={formik.handleSubmit}
                      className="button"
                      id="buttonSaveContractor"
                      type="button"
                      style={{ maxWidth: 450, marginTop: 30 }}
                    >
                      {tabs === 2 ? (
                        <Trans i18nKey="contractor.update" />
                      ) : (
                        <Trans i18nKey="contractor.new" />
                      )}
                    </button>
                  </Footer>
                </BoxForm>

                <Col md={6}>
                  <BoxForm style={{ width: '100%' }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Label
                        for="SelectState"
                        style={{
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          marginBottom: 20,
                        }}
                      >
                        <Trans i18nKey="contractor.fields.user" />
                        <FormControl className={classes.formControl} ref={ref}>
                          {menuOpen ? (
                            <Select
                              styles={customStyles}
                              value={userCollection}
                              isMulti
                              onMenuOpen={() => setMenuOpen(!menuOpen)}
                              menuIsOpen
                              defaultMenuIsOpen={menuOpen}
                              options={users}
                              components={animatedComponents}
                              onChange={tooglePermission}
                              isClearable
                            />
                          ) : (
                            <Select
                              styles={customStyles}
                              value={userCollection}
                              isMulti
                              onMenuOpen={() => setMenuOpen(!menuOpen)}
                              defaultMenuIsOpen={menuOpen}
                              options={users}
                              components={animatedComponents}
                              onChange={tooglePermission}
                            />
                          )}
                        </FormControl>
                        {userErrors && (
                          <>
                            {getLanguages === 'pt' ||
                            getLanguages === 'pt-BR' ? (
                              <p style={{ fontSize: 14, color: 'red' }}>
                                * Informe o(s) usuário(s)!
                              </p>
                            ) : (
                              <p style={{ fontSize: 14, color: 'red' }}>
                                * Enter the user(s)!
                              </p>
                            )}
                          </>
                        )}
                      </Label>
                    </div>
                  </BoxForm>
                </Col>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
                <BoxForm>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.referenceId" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      type="number"
                      placeholder={t('contractor.fields.referenceId')}
                      onChange={formik.handleChange('referenceId')}
                      mask="99.999.999/9999-99"
                      style={{
                        ...inputForm,
                        maxWidth: 450,
                        height: 60,
                      }}
                      value={formik.values.referenceId}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </Input>
                  </FormControl>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.cnpj" />
                    </Label>

                    <ReactInputMask
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.cnpj')}
                      onChange={formik.handleChange('cnpj')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.cnpj}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </ReactInputMask>
                  </FormControl>
                  {formik.touched.cnpj && formik.errors.cnpj && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.cnpj}
                    </p>
                  )}
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.social" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.social')}
                      onChange={formik.handleChange('companyName')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.companyName}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </Input>
                  </FormControl>
                  {formik.touched.companyName && formik.errors.companyName && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.companyName}
                    </p>
                  )}
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="contractor.fields.fantasyName" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      placeholder={t('contractor.fields.fantasyName')}
                      onChange={formik.handleChange('trademarkName')}
                      mask="99.999.999/9999-99"
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.trademarkName}
                    >
                      {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                    </Input>
                  </FormControl>
                  {formik.touched.trademarkName &&
                    formik.errors.trademarkName && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.trademarkName}
                      </p>
                    )}

                  <Footer>
                    <button
                      onClick={formik.handleSubmit}
                      className="button"
                      id="buttonUpdateContractor"
                      type="button"
                      style={{ maxWidth: 450, marginTop: 30 }}
                    >
                      <Trans i18nKey="contractor.update" />
                    </button>
                  </Footer>
                </BoxForm>

                <Col md={6}>
                  <BoxForm style={{ width: '100%' }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                      <Label
                        for="SelectState"
                        style={{
                          fontFamily: 'Lato',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          marginBottom: 20,
                        }}
                      >
                        <Trans i18nKey="contractor.fields.user" />
                        <FormControl className={classes.formControl} ref={ref}>
                          {menuOpen ? (
                            <Select
                              styles={customStyles}
                              value={userCollection}
                              isMulti
                              onMenuOpen={() => setMenuOpen(!menuOpen)}
                              menuIsOpen
                              defaultMenuIsOpen={menuOpen}
                              options={users}
                              components={animatedComponents}
                              onChange={tooglePermission}
                              isClearable
                            />
                          ) : (
                            <Select
                              styles={customStyles}
                              value={userCollection}
                              isMulti
                              onMenuOpen={() => setMenuOpen(!menuOpen)}
                              defaultMenuIsOpen={menuOpen}
                              options={users}
                              components={animatedComponents}
                              onChange={tooglePermission}
                            />
                          )}
                        </FormControl>
                        {userErrors && (
                          <>
                            {getLanguages === 'pt' ||
                            getLanguages === 'pt-BR' ? (
                              <p style={{ fontSize: 14, color: 'red' }}>
                                * Informe o(s) usuário(s)!
                              </p>
                            ) : (
                              <p style={{ fontSize: 14, color: 'red' }}>
                                * Enter the user(s)!
                              </p>
                            )}
                          </>
                        )}
                      </Label>
                    </div>
                  </BoxForm>
                </Col>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
