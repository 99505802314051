import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap/lib';
import { resetVehiclesAccumulator } from 'store/modules/accumulator/actions';
import { saveSubsidiary } from 'store/modules/map/actions';
import { clearVehiclesSelected } from 'store/modules/vehicles/actions';

interface Props {
  listSubsidiary?: any;
  selectSubsidiary?: any;
  defaultSelected?: any;
  getSubsidiary?: any;
  blockedSubsidiary?: any;
}

interface SubsidiaryData {
  id?: number;
  nome?: string;
}

const TopBar: React.FC<Props> = ({
  listSubsidiary,
  selectSubsidiary,
  defaultSelected,
  getSubsidiary,
  blockedSubsidiary,
}: Props) => {
  const [dataSubsidiary, setDataSubsidiary] = useState<SubsidiaryData[]>([]);
  const [idSubsidiary, setIdSubisidiary] = useState('');
  const userData = useSelector((state: any) => state.auth.user);


  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if(listSubsidiary && listSubsidiary.length > 0) {
      let getListSubsidiary = sessionStorage.getItem('subsidiaryList');
      let listSubsidiary = [];
      if(getListSubsidiary) {
        listSubsidiary = JSON.parse(getListSubsidiary);
      }
      setDataSubsidiary(listSubsidiary)
      setIdSubisidiary(userData.filiais[0].id);
      dispatch(saveSubsidiary(userData.filiais[0].id));
      sessionStorage.setItem('subsidiary', userData.filiais[0].id);
    }
  }, [listSubsidiary])

  return (
    <div className="topBar">
      <Form inline>
        <div className="filialLabel">
          <Trans i18nKey="filter.subsidiary" />
          <select
            name="filialSelector"
            disabled={blockedSubsidiary === 'blocked'}
            className="filialInput"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatch(clearVehiclesSelected());
              dispatch(resetVehiclesAccumulator());

              dispatch(saveSubsidiary(e.target.value));
              setIdSubisidiary(e.target.value);
              sessionStorage.setItem('subsidiary', e.target.value);
            }}
            value={sessionStorage.getItem("subsidiary") || ''}
          >
            <option value="" label={t('common.select')} id='select' />
            {dataSubsidiary.map((subsidiary: SubsidiaryData) => (
              <option key={subsidiary.id} value={subsidiary.id} id={subsidiary.nome}>
                {subsidiary.nome}
              </option>
            ))}
          </select>
        </div>
      </Form>
    </div>
  );
};

export default TopBar;