import React from 'react';
import { Trans } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

// import { Container } from './styles';

interface ModalDeleteProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({ isOpen, onClose, onConfirm }: ModalDeleteProps) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onConfirm}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={onClose}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
  );
}

export default ModalDelete;