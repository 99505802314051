import styled from 'styled-components';

export const CorrectButton = styled.button`
  display: flex;
  align-items: center;
  margin-left: 10px;
  // width: 190px;
  height: 30px !important;
  background-color: #ea004c;
  border: none;
  border-radius: 6px;
  padding: 10px;
  margin-right: 30px;

  span {
    font-family: 'Lato';
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
  }
  
`;

export const TitleModal = styled.span`
  font-size: 20px;
  font-family: 'Lato-Bold';
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;


export const Container = styled.div`
  position: absolute;
  top: 1.40rem;
  right: 4rem;
`;

export const SelectTypeDelivery = styled.select`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ea004c;

  option {
    color: #ea004c;
  }
`;