import ExpandIconJourney from 'assets/images/expandIconJourney.svg';
import HideIcon from 'assets/images/hideIcon.svg';
import RemoveColumnJourney from 'assets/images/removeColumnJourney.svg';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeColumnJourneyFlexible,
  removeHeaderJourneyFlexible,
  setJourneyDataValue,
  setJourneyDataValueMultiple
} from 'store/modules/journey/actions';
import { responsiveSize } from 'styles/responsive';
import './styles.scss';

interface Props {
  onRemoveColumn: any;
}

const JourneyFlexible: React.FC<Props> = ({ onRemoveColumn }: Props) => {
  const journeyData = useSelector((state: any) => state.journey.data);

  const [journeyFlexibleData, setJourneyFlexibleData] = useState(journeyData);
  const [hideDaysWeek, setHideDaysWeek] = useState(true);
  const [dataJourneyValue, setDataJourneyValue] = useState('');

  const dispatch = useDispatch();

  return (
    <div className="table-responsive" style={{ marginTop: 40 }}>
      <table>
        <thead>
          <tr>
            {journeyData.headers.map((item: any, indexHeader: any) => (
              <>
                {indexHeader === 0 ||
                indexHeader === 1 ||
                indexHeader === journeyData.headers.length - 1 ? (
                  <>
                    <th
                      style={{
                        fontSize: responsiveSize(14),
                        fontFamily: 'Lato',
                        color: '#003049',
                      }}
                    >
                      {item.name}
                    </th>
                  </>
                ) : (
                  <th>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={RemoveColumnJourney}
                        style={{ cursor: 'pointer' }}
                        id={`removeColumnJourney${indexHeader}`}
                        onClick={() => {
                          dispatch(removeHeaderJourneyFlexible(indexHeader));

                          const mappingColumnsJourney = journeyData.dataElements.map(
                            (item: any, index: number) => {
                              dispatch(
                                removeColumnJourneyFlexible(index, indexHeader)
                              );
                            }
                          );
                          onRemoveColumn(journeyData.headers[indexHeader].name);
                        }}
                      />
                      <span
                        style={{
                          fontSize: responsiveSize(14),
                          fontFamily: 'Lato',
                          color: '#003049',
                        }}
                      >
                        {item.name}
                      </span>
                    </div>
                  </th>
                )}
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {journeyData.dataElements.map((item: any, index: number) => (
            <>
              {hideDaysWeek &&
              item.columns[0].data !== 'Segunda' &&
              item.columns[0].data !== 'Sábado' &&
              item.columns[0].data !== 'Domingo' ? (
                <></>
              ) : (
                <tr className="lineGroup" style={{ padding: 20 }}>
                  {item.columns.map((item: any, indexColumn: any) => (
                    <>
                      {index === 0 && indexColumn === 0 ? (
                        <td style={{ padding: 10, paddingLeft: 15 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={
                                hideDaysWeek
                                  ? HideIcon
                                  : ExpandIconJourney
                              }
                              onClick={() => setHideDaysWeek(!hideDaysWeek)}
                              style={{
                                marginRight: responsiveSize(5),
                                cursor: 'pointer',
                              }}
                            />
                            <span
                              style={{
                                fontFamily: 'Lato',
                                fontSize: responsiveSize(14),
                                fontWeight: 300,
                                color: '#003049',
                                opacity: 0.6,
                              }}
                            >
                              {hideDaysWeek &&
                              index === 0 &&
                              indexColumn === 0 ? (
                                <>Seg - Sex</>
                              ) : (
                                <>
                                  {indexColumn === 1 ? (
                                    <input
                                      type="time"
                                      style={{
                                        backgroundColor: '#fff',
                                        border:
                                          '2px solid rgba(0, 48, 73, 0.2)',
                                        width: responsiveSize(50),
                                        height: responsiveSize(22),
                                        borderRadius: responsiveSize(5),
                                        position: 'relative',
                                        left: responsiveSize(-21),
                                      }}
                                    />
                                  ) : (
                                    <>{item.data}</>
                                  )}
                                </>
                              )}
                            </span>
                          </div>
                        </td>
                      ) : (
                        <td style={{ padding: 10, paddingLeft: 15 }}>
                          <span
                            style={{
                              fontFamily: 'Lato',
                              fontSize: responsiveSize(14),
                              fontWeight: 300,
                              color: '#003049',
                              opacity: 0.6,
                              marginLeft: responsiveSize(15),
                            }}
                          >
                            {indexColumn !== 0 &&
                            indexColumn !==
                              journeyData.dataElements[index].columns.length -
                                1 ? (
                              <input
                                id={
                                  indexColumn !== 1 &&
                                  indexColumn !==
                                    journeyData.dataElements[index].columns
                                      .length -
                                      1
                                    ? `changeValueBreak${index}${indexColumn}`
                                    : `changeValueDaysWeek${index}`
                                }
                                type="time"
                                onBlur={() => {}}
                                onChange={(e: any) => {
                                  if (hideDaysWeek && index === 0) {
                                    const mappingMultipleJourney = journeyData.dataElements.map(
                                      (item: any, index: number) => {
                                        if (
                                          item.columns[0].data !== 'Sábado' &&
                                          item.columns[0].data !== 'Domingo'
                                        ) {
                                          dispatch(
                                            setJourneyDataValueMultiple(
                                              index,
                                              indexColumn,
                                              e.target.value
                                            )
                                          );
                                          if (indexColumn === 1) {
                                            dispatch(
                                              setJourneyDataValueMultiple(
                                                index,
                                                journeyData.dataElements[index]
                                                  .columns.length - 1,
                                                e.target.value
                                              )
                                            );
                                          }
                                        }
                                      }
                                    );
                                  } else {
                                    dispatch(
                                      setJourneyDataValue(
                                        index,
                                        indexColumn,
                                        e.target.value
                                      )
                                    );
                                    if (indexColumn === 1) {
                                      dispatch(
                                        setJourneyDataValue(
                                          index,
                                          journeyData.dataElements[index]
                                            .columns.length - 1,
                                          e.target.value
                                        )
                                      );
                                    }
                                  }
                                }}
                                style={{
                                  backgroundColor: '#fff',
                                  border: `${responsiveSize(
                                    2
                                  )} solid rgba(0, 48, 73, 0.2)`,
                                  width: responsiveSize(50),
                                  height: responsiveSize(22),
                                  borderRadius: responsiveSize(5),
                                  position: 'relative',
                                  left: -21,
                                }}
                                value={item.data}
                              />
                            ) : (
                              <>{item.data}</>
                            )}
                          </span>
                        </td>
                      )}
                    </>
                  ))}
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JourneyFlexible;
