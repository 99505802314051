import styled from 'styled-components';

interface StyledProps {
  progressValueContent: number;
}

export const ProgressContainer = styled.div`
  width: ${(props: StyledProps) => `${props.progressValueContent}%`};
  min-width: 20%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ValueProgress = styled.div`
  background: ${(props: StyledProps) => props.progressValueContent > 100 ? '#EA004C' : '#6369D1'};
  width: ${(props: StyledProps) => `${props.progressValueContent}%`};
`;