// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uriLimit {
  max-width: 22ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 15px;
}

.containerLogs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.valueEmpty {
  color: red;
}

.buttonViewDetailsLogs {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Logs/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".uriLimit {\n  max-width: 22ch;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  margin-top: 15px;\n}\n\n.containerLogs {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.valueEmpty {\n  color: red;\n}\n\n.buttonViewDetailsLogs {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
