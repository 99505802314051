import { object, ref, string } from 'yup';

export default object().shape({
  description: string()
    .required('* Informe a descrição da notificação!')
    .max(255, '* Máximo de 255 caracteres'),
  title: string()
    .required('* Informe o título!')
    .max(128, '* Máximo de 128 dígitos!'),
  date: string().required('* Informe a data de envio!'),
  sendGroup: string().required('* Informe o grupo de envio'),
});
