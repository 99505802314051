import BackPageIcon from 'assets/images/backPage.png';
import NextPageIcon from 'assets/images/nextPage.png';
import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from 'reactstrap';
import './style.scss';
import { BoxFirst } from './styles';

const PaginationField = ({ total, perPage, currentPage, onPaginate }) => {
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    setTotalPages(Math.ceil(total / perPage));
  }, [total, perPage]);

  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination
      className="page"
      size="md"
      aria-label="Page navigation example"
      style={{ position: 'absolute' }}
    >
      <PaginationItem style={{ marginRight: 6 }}>
        {currentPage === 1 ? (
          <BoxFirst disabled>
            <img src={BackPageIcon} alt="" />
          </BoxFirst>
        ) : (
          <BoxFirst onClick={() => onPaginate(currentPage - 1)}>
            <img src={BackPageIcon} alt="" />
          </BoxFirst>
        )}
      </PaginationItem>

      {pageNumbers.map((page, index) => (
        <PaginationItem>
          <BoxFirst
            activePage={currentPage === page}
            onClick={() => onPaginate(page)}
            style={{ marginRight: 6 }}
          >
            {page}
          </BoxFirst>
        </PaginationItem>
      ))}
      {currentPage === pageNumbers.length ? (
        <BoxFirst
          disabled
          // onClick={() => onPaginate(currentPage + 1)}
          style={{ marginLeft: 6 }}
        >
          <img src={NextPageIcon} alt="" />
        </BoxFirst>
      ) : (
        <BoxFirst
          onClick={() => onPaginate(currentPage + 1)}
          style={{ marginLeft: 6 }}
        >
          <img src={NextPageIcon} alt="" />
        </BoxFirst>
      )}
    </Pagination>
  );
};

export default PaginationField;
