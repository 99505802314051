import React from 'react';
import { BottomLine, KpiContainer, KpiItem, Line, Wrapper } from './styles';

// import { Container } from './styles';

interface KpisProps {
  data: any[];
  legend: any[];
  isSizeLess?: boolean;
}

const Kpis: React.FC<KpisProps> = ({ data, legend, isSizeLess }: KpisProps) => {
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: isSizeLess ? '170px' : '130px',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <div>
            <img src={legend[0].icon} />
          </div>
          <span>{legend[0].label}</span>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              width: '2px',
              height: '5rem',
              background: '#ccc',
              // marginTop: '1rem',
              marginBottom: '0.812rem',
            }}
          />
          <div
            style={{
              display: 'flex',
              width: '2px',
              height: '5rem',
              background: '#ccc',
            }}
          />
        </div>
      </div>
      <KpiContainer isSize={isSizeLess}>
        {data.map((item, index) => (
          <React.Fragment>
            <KpiItem isLast={index === data.length - 1}>
              <span
                style={{
                  textAlign: 'center',
                  color: `${item.itemStyle.color}`,
                  fontSize: '1rem',
                  height: '7%',
                  // marginLeft: '1rem',
                }}
              >
                {item.label}
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '0.710rem'
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold',
                    color: `${item.itemStyle.color}`,
                    fontSize: '1rem',
                    marginTop: '1.60rem',
                  }}
                >
                  {item.value}
                </span>
                {item.name !== null && (
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: `${item.itemStyle.color}`,
                      marginTop: '1.50rem',
                      marginLeft: '0.610rem',
                      fontSize: '0.8rem',
                    }}
                  >
                    ({item.name}%)
                  </span>
                )}
              </div>

              {isSizeLess ? (
                index <= 1 ? (
                  <BottomLine />
                ) : (
                  <></>
                )
              ) : (
                index <= 2 && <BottomLine />
              )}

              {isSizeLess ? (
                index !== 1 && index !== data.length - 1 ? (
                  <Line />
                ) : (
                  <></>
                )
              ) : (
                index !== data.length - 1 && index !== 2 && <Line />
              )}
            </KpiItem>
          </React.Fragment>
        ))}

        {/* <KpiItem>
          <span>Total</span>
          <BottomLine />
        </KpiItem>

        <KpiItem isLast>
          <span>Total</span>
          <BottomLine />
        </KpiItem>

        <KpiItem>
          <span>Total</span>
        </KpiItem>

        <KpiItem>
          <span>Total</span>
        </KpiItem>
        <KpiItem isLast>
          <span>Total</span>
        </KpiItem> */}
      </KpiContainer>
    </Wrapper>
  );
};

export default Kpis;
