import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import { responsiveSize } from 'styles/responsive';
// import CheckBox from '../../../pages/ControlTower/ListAttendances/Filters/CheckBox';
import CheckBox from './CheckBox';
import Toogle from './Toogle';
import formValidator from './formValidator';
import formValidatorBreak from './formValidatorBreak';

interface Props {
  onClear?: any;
  resourceId: string;
}

interface FormValues {
  type: string;
  reasonId: string;
  breakId: string;
  anotherReason: string;
  dateStart: string;
  time: string;
}

const AddPoint: React.FC<Props> = ({ onClear, resourceId }: Props) => {
  const [checkedReason, setCheckedReason] = useState<boolean>(false);
  const [checkedWork, setCheckedWork] = useState<boolean>(true);
  const [checkedBreak, setCheckedBreak] = useState<boolean>(false);
  const [checkedDeduction, setCheckedDeduction] = useState<boolean>(false);
  const [reasons, setReasons] = useState<any>([]);
  const [breaks, setBreaks] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormValues, resource: string, reasonCheck: boolean) => {
      try {
        setLoading(true);

        const data = {
          type: values.type,
          resourceId: resourceId,
          reasonId: reasonCheck ? null : values.reasonId,
          pauseId: values.type === 'PAUSE' ? values.breakId : null,
          anotherReason: reasonCheck ? values.anotherReason : null,
          date: {
            start: values.dateStart,
            end: null,
          },
          time: values.time,
        };

        const languageFormat = navigator.language;
        const emailSendLanguage =
          languageFormat === 'pt' || languageFormat === 'pt-BR'
            ? 'pt_BR'
            : 'en_US';

        const response = await api.post(
          `/workday/pcontrols/add?lang=${emailSendLanguage}`,
          data
        );

        if (response.status === 204) {
          FrontendNotification(
            'Ponto de controle adicionado com sucesso!',
            NotificationType.SUCCESS
          );

          onClear();
        }

        setLoading(false);

        console.log('Status', response.status);
      } catch {}
    },
    []
  );

  const getReason = useCallback(async () => {
    try {
      const response = await api.get('/workday/reasons');

      setReasons(response.data);
    } catch {}
  }, []);

  const getBreaks = useCallback(async () => {
    try {
      const response = await api.get('/workday/pauses');

      setBreaks(response.data);
    } catch {}
  }, []);

  const initialValues: FormValues = {
    type: 'JOB',
    reasonId: '',
    breakId: '',
    anotherReason: '',
    dateStart: '',
    time: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: checkedBreak ? formValidatorBreak : formValidator,
    onSubmit: values => handleSubmit(values, resourceId, checkedReason),
  });

  useEffect(() => {
    getReason();
    getBreaks();
  }, [getReason, getBreaks]);

  return (
    <>
      <Loading loading={loading} />
      <CustomModal
        isOpen
        isClose={onClear}
        label="Adicionar"
        styles={{ maxWidth: 432 }}
      >
        <div style={{ marginTop: responsiveSize(40) }}>
          <Row style={{ marginBottom: responsiveSize(20) }}>
            <Col md={4}>
              <Toogle
                label="Trabalho"
                id="work"
                active={checkedWork}
                onChange={() => {
                  formik.setFieldValue('type', 'JOB');
                  setCheckedWork(true);
                  setCheckedBreak(false);
                  setCheckedDeduction(false);
                }}
              />
            </Col>
            <Col md={4}>
              <Toogle
                label="Pausa"
                id="breaks"
                active={checkedBreak}
                onChange={() => {
                  formik.setFieldValue('type', 'PAUSE');

                  setCheckedBreak(true);
                  setCheckedWork(false);
                  setCheckedDeduction(false);
                }}
              />
            </Col>
            <Col md={4}>
              <Toogle
                label="Dedução"
                id="deduction"
                active={checkedDeduction}
                onChange={() => {
                  formik.setFieldValue('type', 'DEDUCTION');

                  setCheckedDeduction(true);
                  setCheckedBreak(false);
                  setCheckedWork(false);
                }}
              />
            </Col>
          </Row>
          {checkedBreak && (
            <Row>
              <Col md={12}>
                <SelectCustom
                  id="breakId"
                  onChange={formik.handleChange('breakId')}
                  title="Pausas"
                  value={formik.values.breakId}
                  touched={formik.touched.breakId}
                  error={formik.errors.breakId}
                >
                  <option label={t('common.select')} />
                  {breaks.map((item: any) => (
                    <option label={item.name} value={item.id} />
                  ))}
                </SelectCustom>
              </Col>
            </Row>
          )}

          <Row style={{ marginTop: 15 }}>
            <Col md={12}>
              <SelectCustom
                id="reasonId"
                onChange={formik.handleChange('reasonId')}
                title="Motivo"
                value={formik.values.reasonId}
                touched={formik.touched.reasonId}
                error={formik.errors.reasonId}
              >
                <option label={t('common.select')} />
                {reasons.map((item: any) => (
                  <option label={item.name} value={item.id} />
                ))}
              </SelectCustom>
            </Col>
          </Row>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
            <CheckBox
              id="checkedReason"
              active={checkedReason}
              label=""
              onChecked={() => setCheckedReason(!checkedReason)}
              styles={{}}
            />

            <span
              style={{
                fontFamily: 'Lato',
                fontSize: responsiveSize(12),
                color: '#003049',
                opacity: 0.6,
              }}
            >
              Outro motivo
            </span>
          </div>
          {checkedReason && (
            <InputCustom
              id="anotherReason"
              onChange={formik.handleChange('anotherReason')}
              value={formik.values.anotherReason}
              touched={formik.touched.anotherReason}
              error={formik.errors.anotherReason}
              placeholder=""
            />
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <Row>
              <Col>
                <InputCustom
                  id="dateStart"
                  onChange={formik.handleChange('dateStart')}
                  placeholder=""
                  type="date"
                  value={formik.values.dateStart}
                  touched={formik.touched.dateStart}
                  error={formik.errors.dateStart}
                  style={{ width: 125 }}
                />
              </Col>
              <Col>
                <InputCustom
                  id="time"
                  onChange={formik.handleChange('time')}
                  placeholder="00:00"
                  type="time"
                  value={formik.values.time}
                  touched={formik.touched.time}
                  error={formik.errors.time}
                  style={{ width: 60 }}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <button
              className="button"
              id="saveAddPoint"
              type="button"
              onClick={() => formik.handleSubmit()}
            >
              Salvar
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AddPoint;
