import InputCustom from 'components/Input';
import { LogisticOperator } from 'components/ModalOperator/interfaces/types';
import SelectCustom from 'components/SelectCustom';
import { Branch } from 'components/SubsidiaryRegister/interfaces/types';
import { useFormik } from 'formik';
import i18n from 'i18n';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import api from 'services/api';
import { deleteGroupsUserId } from 'store/modules/map/actions';
import { Group } from '../GroupsRegister/interfaces/types';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

export interface FormValues {
  name: string;
  email: string;
  senha: string;
  confirmPassword: string;
  cnpj: string;
  group: string;
  operadorLogistico: string;
}

interface Props {
  onSubmit: (values: FormValues, subsidiary: any[], resetForm: any) => void;
  onGroupUser: () => void;
  userId: number;
}

const EditUsers: React.FC<Props> = (props: Props) => {
  // const [tabs, setTabs]  = useState<number>(0);
  const [groups, setGroups] = useState<Group[]>([]);
  const [branchs, setBranchs] = useState([]);
  const [operator, setOperator] = useState<LogisticOperator[]>([]);
  const [branchCollection, setBranchCollection] = useState([]);

  const userId = useSelector((state: any) => state.map.groupsUsersId);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues: FormValues = {
    name: '',
    email: '',
    senha: '',
    confirmPassword: '',
    cnpj: '',
    group: '',
    operadorLogistico: '',
  };

  const getLanguage = i18n.language;

  const formik = useFormik({
    initialValues,
    validationSchema:
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) =>
      props.onSubmit(values, branchCollection, resetForm),
  });

  const tooglePermission = useCallback(selectedOption => {
    console.log(selectedOption);
    setBranchCollection(selectedOption);
  }, []);

  const getBranchs = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      const branchFilter = response.data.filter(
        (item: Branch) => item.status === 'ACTIVE'
      );

      const branchMapping = branchFilter.map((item: Branch) => {
        return {
          value: item.id,
          label: item.nome,
        };
      });
      setBranchs(branchMapping);
      dispatch(deleteGroupsUserId());
    } catch (err) {}
  }, []);

  const getOperatorLogistic = useCallback(async () => {
    try {
      const response = await api.get('/delivery/operadoresLogisticos');

      setOperator(response.data);
    } catch (err) {}
  }, []);

  const getCompanies = useCallback(async () => {
    try {
      const response = await api.get('/acesso/grupos');

      let sortType = 'asc';

      const orderSort = response.data.sort((a: Group, b: Group) => {
        const isReversed = sortType === 'asc' ? 1 : -1;

        return isReversed * a.nome.localeCompare(b.nome);
      });

      setGroups(orderSort);

      if (userId !== null) {
        formik.setFieldValue('group', userId);
      }
    } catch (err) {}
  }, [userId]);

  const getUsersById = useCallback(async () => {
    try {
      const response = await api.get(`/acesso/usuarios/${props.userId}`);

      const groupValue =
        response.data.grupo !== null ? response.data.grupo.id : '';
      const operatorValue =
        response.data.operadorLogistico !== null
          ? response.data.operadorLogistico.id
          : '';

      formik.setFieldValue('group', groupValue);

      formik.setFieldValue('operadorLogistico', operatorValue);

      const mappingFiliais = response.data.filiais.map((item: Branch) => {
        return {
          value: item.id,
          label: item.nome,
        };
      });

      setBranchCollection(mappingFiliais);

      formik.setFieldValue('name', response.data.nome);
      formik.setFieldValue('email', response.data.email);
      formik.setFieldValue('cnpj', response.data.cnpj);
    } catch {}
  }, [userId]);

  useEffect(() => {
    getCompanies();
    getBranchs();
    getOperatorLogistic();
    getUsersById();
  }, [getCompanies, getBranchs, getOperatorLogistic, getUsersById]);

  return (
    <div>
      <Row>
        <Col md={6}>
          <InputCustom
            title={t('users.fields.name')}
            onChange={formik.handleChange('name')}
            placeholder={t('users.fields.name')}
            error={formik.errors.name}
            touched={formik.touched.name}
            value={formik.values.name}
          />
        </Col>
        <Col md={6}>
          <InputCustom
            title={t('users.fields.email')}
            onChange={formik.handleChange('email')}
            placeholder={t('users.fields.email')}
            error={formik.errors.email}
            touched={formik.touched.email}
            value={formik.values.email}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col md={6}>
          <InputCustom
            title={t('users.fields.password')}
            type="password"
            autoComplete="new-passwword"
            onChange={formik.handleChange('senha')}
            placeholder={t('users.fields.password')}
            error={formik.errors.senha}
            touched={formik.touched.senha}
            value={formik.values.senha}
          />
          <span>
            {navigator.language === 'pt' || navigator.language === 'pt-BR' ? (
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#003049',
                  marginBottom: 10,
                }}
              >
                A senha deve possuir pelo menos 6 caracteres, contendo:
                <br />
                uma letra e um número
              </span>
            ) : (
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#003049',
                  marginBottom: 10,
                }}
              >
                The password must be at least 6 characters long, containing:
                <br />a letter and a number
              </span>
            )}
          </span>
        </Col>
        <Col md={6}>
          <InputCustom
            title={t('users.fields.confirmPassword')}
            type="password"
            autoComplete="new-passwword"
            onChange={formik.handleChange('confirmPassword')}
            placeholder={t('users.fields.confirmPassword')}
            error={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            value={formik.values.confirmPassword}
          />
        </Col>
      </Row>

      <Row className="tw-mt-3">
        <Col md={6}>
          <Row>
            <Col md={7}>
              <SelectCustom
                title={t('users.fields.group')}
                onChange={formik.handleChange('group')}
                error={formik.errors.group}
                touched={formik.touched.group}
                value={formik.values.group}
              >
                <Fragment>
                  <option label={t('common.select')} value="" />
                  {groups.map((item: Group) => (
                    <option label={item.nome} value={item.id} />
                  ))}
                </Fragment>
              </SelectCustom>
            </Col>
            <Col md={5}>
              <button
                type="button"
                onClick={() => props.onGroupUser()}
                className="tw-border-none tw-bg-[#fff] tw-text-[#EA004C] tw-font-bold tw-mt-8"
              >
                + <Trans i18nKey="groups.buttons.save" />
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <InputCustom
            typeInput="mask"
            mask="99.999.999/9999-99"
            title={t('users.fields.cnpj')}
            onChange={formik.handleChange('cnpj')}
            placeholder={t('users.fields.cnpj')}
            error={formik.errors.cnpj}
            touched={formik.touched.cnpj}
            value={formik.values.cnpj}
          />
        </Col>
      </Row>
      <Row className="tw-mt-3">
        <Col md={6}>
          <Label className="tw-text-[#003049] tw-opacity-60 tw-text-sm tw-mb-2">
            {t('users.fields.filial')}
          </Label>
          <Select
            isMulti
            name="subsidiary"
            value={branchCollection}
            options={branchs}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={tooglePermission}
          />
        </Col>
        <Col md={6}>
          <SelectCustom
            title={t('users.fields.operatorLogistic')}
            onChange={formik.handleChange('operadorLogistico')}
            // placeholder={t('users.fields.operatorLogistic')}
            error={formik.errors.operadorLogistico}
            touched={formik.touched.operadorLogistico}
            value={formik.values.operadorLogistico}
          >
            <Fragment>
              <option label={t('common.select')} />
              {operator.map((item: LogisticOperator) => (
                <option label={item.nomeFantasia} value={item.id} />
              ))}
            </Fragment>
          </SelectCustom>
        </Col>
      </Row>
      <div className="tw-mt-4 tw-flex tw-justify-end">
        <button
          type="button"
          onClick={() => formik.handleSubmit()}
          className="button tw-mr-0 tw-w-[40%]"
          id="buttonSaveUsers"
        >
          <Trans i18nKey="users.buttons.update" />
        </button>
      </div>
    </div>
  );
};

export default EditUsers;
