/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import Leaflet from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input, Modal, Row } from 'reactstrap';
import { searchAddress } from 'services/endpoint';
import closeModalIcon from '../../../../../../assets/images/closeIcon.svg';
import MapIcon from '../../../../../../assets/images/mapPin.svg';
import { States } from '../../../../../../util/monitoring';
import ConfirmationDriver from './ConfirmationDriver';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';
import './styles.scss';


export default function SearchAddress({
  onClear,
  accuracy,
  onInitial,
  data,
  columns,
  dataColumns,
  onEditColumns,
}) {
  const [tabs, setTabs] = useState(0);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [zoom, setZoom] = useState(14);
  const [states, setStates] = useState([]);
  const [position, setPosition] = useState([]);
  const [addressText, setAddressText] = useState('');
  const [modal, setModal] = useState(true);
  const [filiais, setFiliais] = useState([]);
  const [coordenates, setCoordenates] = useState();
  const [optionsSearch, setOptionsSearch] = useState([]);
  const [dataLocation, setDataLocation] = useState({
    dataValues: {},
    coordenates: {
      latitude: '',
      longitude: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [autoSearch, setAutoSearch] = useState(false);
  const [display, setDisplay] = useState(false);
  const [confirmationLocation, setConfirmationLocation] = useState(false);
  const [dataCoordenates, setDataCoordenates] = useState();
  let sortType = 'asc';

  let leafletMap = useRef();

  const primeiroAcesso = useSelector(state => state.auth.primaryAccess);

  const [confirmAddress, setConfirmAddress] = useState(false);
  const [closeModal, setCloseModal] = useState(primeiroAcesso);

  const { t } = useTranslation();

  const getStates = useCallback(() => {
    const orderSort = States.sort((a, b) => {
      const isReversed = sortType === 'asc' ? 1 : -1;

      return isReversed * a.nome.localeCompare(b.nome);
    });

    setStates(orderSort);
  }, [sortType]);

  const getPosition = useCallback(e => {
    setPosition([e.latlng.lat, e.latlng.lng]);
  }, []);

  const getLanguage = () => {
    return i18next.language;
  };

  function clearNav(data) {
    if (closeModal && data.length === 0) {
      return null;
    }

    onClear();
    setModal(!modal);
  }

  const handleSubmit = useCallback((values, positionData, isMultiple) => {
    if (positionData.length === 0) {
      const message =
        getLanguage() === 'pt' || getLanguage() === 'pt-BR'
          ? 'Selecione o ponto no mapa'
          : 'Select the point on the map';
      FrontendNotification(message, NotificationType.ERROR);
    } else {
      const stateAddress =
        values && values.address ? values.address.state : data.enderecoUf;
      const arrData = [
        {
          dataValue:
            values && values.address
              ? values.address.road || values.address.natural
              : data.enderecoLogradouro,
        },

        {
          dataValue: data.enderecoNumero,
        },
        {
          dataValue:
            values && values.address
              ? values.address.suburb
              : data.enderecoBairro,
        },
        {
          dataValue:
            values && values.address
              ? values.address.city
              : data.enderecoCidade,
        },
        {
          dataValue: States.find(itemState =>
            values && values.address
              ? itemState.nome === stateAddress
              : itemState.sigla === stateAddress
          ).sigla,
        },
        {
          dataValue:
            values && values.address
              ? values.address.postcode
              : data.enderecoCep,
        },
        {
          dataValue: values && values.lat ? values.lat : positionData[0],
        },
        {
          dataValue: values && values.lon ? values.lon : positionData[1],
        },
      ];
      const dataAddress = {
        logradouro:
          values && values.address
            ? values.address.road || values.address.natural
            : data.enderecoLogradouro,
        numero: data.enderecoNumero,
        bairro:
          values && values.address
            ? values.address.suburb
            : data.enderecoBairro,
        cidade:
          values && values.address ? values.address.city : data.enderecoCidade,
        estado: States.find(itemState =>
          values && values.address
            ? itemState.nome === stateAddress
            : itemState.sigla === stateAddress
        ).sigla,
        cep:
          values && values.address && values.address.postcode
            ? values.address.postcode.replace('-', '')
            : data.enderecoCep,
        latitude: values && values.lat ? values.lat : positionData[0],
        longitude: values && values.lon ? values.lon : positionData[1],
      };

      clearNav();

      onEditColumns(columns, arrData, dataAddress, isMultiple);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      cep: '',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
    },
    validationSchema:
      getLanguage() === 'pt' || getLanguage() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: values => handleSubmit(values, position),
  });

  const dispatch = useDispatch();

  const setFieldsAddress = useCallback(() => {
    formik.setFieldValue('bairro', data.enderecoBairro);
    formik.setFieldValue('logradouro', data.enderecoLogradouro);
    formik.setFieldValue('cidade', data.enderecoCidade);
    formik.setFieldValue('cep', data.enderecoCep);
    formik.setFieldValue('numero', data.enderecoNumero);
    formik.setFieldValue('estado', data.enderecoUf);

    setAddressText(
      `${data.enderecoLogradouro},${data.enderecoNumero},${data.enderecoCidade},${data.enderecoUf}`
    );

    if (data.enderecoLatitude.length > 0 && data.enderecoLongitude.length > 0) {
      setPosition([data.enderecoLatitude, data.enderecoLongitude]);
      setInitialPosition([data.enderecoLatitude, data.enderecoLongitude]);
      setZoom(40);
    }
  }, []);

  const handleChange = useCallback(async text => {
    setLoading(true);

    const valueSearch = text.replace(/\s+/g, '+');
    const response = await axios.get(
      `${searchAddress}/?addressdetails=1&q=${valueSearch}&format=json`
    );

    if (response.data.length > 0) {
      setDataCoordenates(response.data[0]);
      setDisplay(!display);
      setOptionsSearch(response.data);
    } else {
      const message =
        getLanguage() === 'pt' || getLanguage() === 'pt-BR'
          ? 'Localização não encontrada. Por favor, indicar no mapa a localização correta.'
          : 'Location not found. Please indicate on the map the exact location.';

      FrontendNotification(message, NotificationType.WARNING);
    }

    setLoading(false);
  }, []);

  function getHandlerError(error) {
    if (error.length === 0) {
      FrontendNotification('Serviço Indisponivel', NotificationType.ERROR);
    }
  }

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    getStates();
    setFieldsAddress();
    // getLocation();
  }, [getStates]);
  return (
    <>
      {confirmAddress && (
        <ConfirmationDriver
          onClear={() => setConfirmAddress(!confirmAddress)}
          onConfirmAddress={isMultipleRows => {
            handleSubmit(dataCoordenates, position, isMultipleRows);
          }}
        />
      )}
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        dialogClassName="my-modal"
        style={{ maxWidth: '75%', position: 'relative' }}
      >
        <div className="modalWrapper">
          <p className="titleSearch">
            Localizar endereço
            {/* <Trans i18nKey="filiais.listTitle" /> */}
          </p>
          <div className="iconCloseView">
            <button
              type="button"
              className="buttonClose"
              onClick={() => clearNav(filiais)}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <div style={{ position: 'relative' }}>
            <Row>
              <Col md={8} style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ position: 'relative' }}>
                  <Input
                    type="text"
                    name="select"
                    autocomplete="off"
                    id="SelectState"
                    placeholder={t('locateAddress.placeholder.street')}
                    onChange={async e => {
                      setAddressText(e.target.value);
                    }}
                    className="inputSearch"
                    value={addressText}
                  />
                  {loading && (
                    <div style={{ position: 'absolute', top: 5, right: 10 }}>
                      <ReactLoading
                        type="spin"
                        color="#ea004c"
                        width={24}
                        height={24}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col md={2} style={{ marginTop: 20, marginBottom: 20 }}>
                <button
                  type="button"
                  className="buttonCSVAddress"
                  onClick={() => {
                    handleChange(addressText);
                  }}
                >
                  <Trans i18nKey="searchAddress.buttonSearch" />
                </button>
              </Col>
              <Col md={2} style={{ marginTop: 20, marginBottom: 20 }}>
                <button
                  type="button"
                  className="buttonCSVAddress"
                  onClick={() => {
                    if (autoSearch) {
                      setPosition([dataCoordenates.lat, dataCoordenates.lon]);
                      setInitialPosition([
                        dataCoordenates.lat,
                        dataCoordenates.lon,
                      ]);
                      setZoom(40);
                    }
                  }}
                >
                  <Trans i18nKey="filiais.buttons.location" />
                </button>
              </Col>
            </Row>
            {display && (
              <div className="autoCompleteSearchContainer">
                {optionsSearch.length > 0 ? (
                  optionsSearch.map(item => (
                    <div
                      className="searchContent"
                      onClick={() => {
                        setDataCoordenates(item);
                        setAutoSearch(!autoSearch);
                        setAddressText(`${item.display_name}`);
                        setDisplay(!display);
                      }}
                    >
                      <span className="resultAddress">{item.display_name}</span>
                    </div>
                  ))
                ) : (
                  <span className="resultAddress">No Search results.....</span>
                )}
              </div>
            )}
          </div>

          <div>
            <Map
              ref={m => {
                leafletMap = m;
              }}
              center={initialPosition}
              zoom={zoom}
              zoomControl
              onclick={e => getPosition(e)}
              style={{
                display: 'flex',
                maxHeight: 440,
                width: '100%',
                marginTop: 0,
                cursor: 'pointer',
              }}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {position.length > 0 && (
                <Marker position={position} icon={pinIcon} />
              )}
            </Map>
            <div className="footerButton">
              <button
                type="button"
                className="button saveButton"
                onClick={() => {
                  setConfirmAddress(!confirmAddress);
                }}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
