import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DataComponentField } from '..';
import Input from '../../components/Input';
import {
  ButtonConfirm,
  ClearFilters,
  ClearFiltersText,
  Container,
  Footer,
  TextButtonConfirm,
  WrapperFields,
  WrapperFilters,
} from './styles';

interface FormFieldShipment {
  dataInicial?: string;
  dataFinal?: string;
  nomeCliente?: string;
  nomeMotorista?: string;
  placa?: string;
  numeroCte?: string;
  idReferencia?: string;
  romaneiosPendentes?: string;
}

interface FiltersProps {
  filtersData: DataComponentField[];
  data: any;
  // onCancel: () => void;
  onClearFilter: () => void;
  onFilter: () => void;
}

const Filters: React.FC<FiltersProps> = ({
  filtersData,
  data,
  // onCancel,
  onFilter,
  onClearFilter,
}) => {
  const [dataFilters, setDataFilters] = React.useState<DataComponentField[]>(
    []
  );
  const [textFilter, setTextFilter] = useState('');
  const [fields, setFields] = useState<FormFieldShipment>({});
  const [checked, setChecked] = useState({
    index: 0,
    value: false,
  });

  const getData = useCallback(() => {
    let fieldsData: any = {};
    filtersData.map((data: DataComponentField) => {
      const fieldName = data.field;
      Object.defineProperty(fieldsData, fieldName, {
        value: '',
        writable: true,
      });
    });

    setFields(fieldsData);
    console.log('fields22', fieldsData);
  }, []);

  const formik = useFormik({
    initialValues: fields,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (data) {
      setDataFilters(filtersData);
      getData();
    }
  }, [data]);

  return (
    <Container>
      <WrapperFilters>
        {filtersData.map((field: DataComponentField, index: number) => (
          <WrapperFields>
            {field.type === 'date' ? (
              <Input
                placeholder={field.placeholder}
                type={field.type}
                title={field.label}
                onFocus={(e: any) => {
                  setTextFilter(field.value);
                }}
                onChange={e => {
                  setTextFilter(e.target.value);

                  data[field.field] = e.target.value;

                  setDataFilters(data);
                }}
                value={data[field.field]}
              />
            ) : (
              <Input
                placeholder={field.placeholder}
                type={field.type}
                title={field.label}
                onFocus={(e: any) => {
                  setTextFilter(field.value);
                }}
                onChange={e => {
                  setTextFilter(e.target.value);

                  data[field.field] = e.target.value;

                  setDataFilters(data);
                }}
                value={data[field.field]}
              />
            )}
          </WrapperFields>
        ))}
      </WrapperFilters>

      <Footer>
        <ButtonConfirm
          onClick={() => {
            setTextFilter('');
            onFilter();
          }}
        >
          <TextButtonConfirm>Filtrar</TextButtonConfirm>
        </ButtonConfirm>
        <ClearFilters onClick={onClearFilter}>
          <ClearFiltersText>Limpar filtros</ClearFiltersText>
        </ClearFilters>
      </Footer>
    </Container>
  );
};

export default Filters;
