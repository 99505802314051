import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

interface StyledProps {
  edit?: boolean;
  disabled?: boolean;
}

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormContentField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

export const InputCurrency = styled(NumericFormat)`
  height: 2.5rem;
  background-color: ${({edit, disabled}: StyledProps) => edit || disabled  ? '#ccc' : '#FFFFFF'};
  opacity: ${({edit}: StyledProps) => edit ? 0.8 : 1};
  border: 2px solid rgba(0, 48, 73, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.188rem;
  color: rgb(0, 48, 73);
  margin-top: 0.5rem;
  padding-left: 0.625rem;
  width: 100%;
  &::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 0.875rem;
    color: rgb(0, 48, 73);
  }
`;

export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: rgb(0, 48, 73);
  opacity: 0.6;
  font-style: normal;
  display: flex;
  flex-direction: column;
`;

export const Error = styled.span`
  font-family: Inter;
  font-size: 0.875rem;
  color: red;
`;