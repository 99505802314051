import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import closeModalIcon from '../../../../assets/images/closeIcon.svg';

interface ConfirmationDriverProps {
  cargas: number;
  onClear: () => void;
  onConfirm: () => void;
}

export default function ConfirmationRouteringCapacity(props: ConfirmationDriverProps) {
  const [modal, setModal] = useState(true);

  return (
    <React.Fragment>
      <Modal size="xl" isOpen={modal} dialogClassName="my-modal" style={{ maxWidth: '40%', maxHeight: '90%' }}>
      <div className="modalWrapperVehicles">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                props.onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="routeOptmization.simulation.messageConfirm" />
            {'  '}
            {props.cargas} cargas?
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="routeOptmization.routeSteps.messageConfirmCreateCargasCapacity" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  props.onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeDriver.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={props.onConfirm}
              >
                <Trans i18nKey="changeDriver.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}