import { object, ref, string, number } from 'yup';

export default object().shape({
  icone: string().required('* Informe o icone da etapa!'),
  descricao: string()
    .required('* Informe a descrição!')
    .min(5, '* Minimo de 5 caracteres!')
    .max(100, '* Maximo de 100 caracteres!'),
  tempo: number()
    .required('* Informe o tempo em minutos!')
    .min(0, '* Esse campo não permite numeros negativos')

    .max(10000, '* Maximo de 5 caracteres'),
  subject: string().notRequired(),
});
