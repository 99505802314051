import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContainer, TitleHeaderTower } from './styles';

interface HeaderTableProps {
  field?: string;
  icon?: boolean;
  imageUrl?: string;
  size?: string;
  tooltipTitle?: string;
}

const HeaderTable: React.FC<HeaderTableProps> = ({
  field,
  icon,
  imageUrl,
  size,
  tooltipTitle,
}: HeaderTableProps) => {
  const { t } = useTranslation();
  return (
    <HeaderContainer>
      {icon ? (
        <>
          {size === 'small' ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Tooltip
                title={tooltipTitle}
                placement="top"
                style={{ cursor: 'pointer' }}
              >
                <img src={imageUrl} alt="" style={{ width: 28, height: 48 }} />
              </Tooltip>
            </div>
          ) : (
            <img src={imageUrl} alt="" style={{ width: 16, height: 16 }} />
          )}
        </>
      ) : (
        <TitleHeaderTower>{field}</TitleHeaderTower>
      )}
    </HeaderContainer>
  );
};

export default HeaderTable;
