import iconUpload from 'assets/images/iconUpload.svg';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Trans } from 'react-i18next';

// import { Container } from './styles';

interface Props {
  onUpload: (files: any) => void;
}

const Upload: React.FC<Props> = ({ onUpload }: Props) => {
 

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
      <Dropzone onDropAccepted={onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div
            className="sectionDrop"
            {...getRootProps()}
            // isDragActive={isDragActive}
            // isDragReject={isDragReject}
            style={{
              cursor: 'pointer',
              border: isDragActive
                ? `4px dashed #EA004C`
                : `4px dashed #D2D2D2`,
              marginBottom: '15px',
              marginTop: '0',
            }}
          >
            <span className='tw-text-base tw-font-medium tw-text-[#003049] tw-mr-4'>
              <Trans i18nKey="common.description" />
            </span>
            <img src={iconUpload} />
            <input {...getInputProps()} multiple />
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default Upload;
