import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  width: 70%;
  margin-left: 2rem;
  margin-top: 1.25rem;

`;

export const ContentRating = styled.div`
display: flex;
  /* width: 70%; */
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  /* width: 50%; */
`;