import InputCustom from 'components/Input';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Label } from 'reactstrap';
import api from 'services/api';
import { Alert, AlertType } from '../interfaces/types';
import formValidator from './formValidator';

interface FormValues {
  descricao: string;
  tipo: string;
}

interface Props {
  selectedRow: Alert[];
  onConfirm: () => void;
}

const EditAlert: React.FC<Props> = (props: Props) => {
  const [optionsAlert, setOptionsAlert] = useState<any[]>([]);
  const [alertSelected, setAlertSelected] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = useCallback(async (values: FormValues, selected: Alert) => {
    try {
      setLoading(true);

      const body = {
        descricao: values.descricao,
        tipo: values.tipo,
      };

      const response = await api.patch(`/delivery/motivos/alertas/${selected.id}`, body);

      setLoading(false);

      FrontendNotification(t('reasonsAlert.messageUpdateSuccess'), NotificationType.SUCCESS);

      props.onConfirm();
    } catch {
      setLoading(false);
    }
  }, []);

  const handleChangeOptions = (selectedOption: any) => {
    setAlertSelected(selectedOption);
    const mappingOptionsAlert = selectedOption.map(
      (item: any, index: number) => {
        return item.value;
      }
    );
    formik.setFieldValue('tipo', mappingOptionsAlert.join(';'));
  };


  const onLoadFormValues = useCallback(() => {
    const data = props.selectedRow[0];

    formik.setFieldValue('descricao', data.descricao);
    formik.setFieldValue('tipo', data.tipo);

    const getTypeAlert = data.tipo.split(';');

    const mappingAlertType = getTypeAlert.map((item: any) => {
      return {
        label: item,
        value: item
      }
    });

    setAlertSelected(mappingAlertType);
  }, [])

  
  const initialValues: FormValues = {
    descricao: '',
    tipo: '',
  };
  
  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values, props.selectedRow[0])
  });
  


  useEffect(() => {
    let options: any[] = Object.values(AlertType);

    options = options.map((item: any) => {
      return {
        value: item,
        label: item,
      };
    });

    setOptionsAlert(options);

    onLoadFormValues();
  }, []);

  return (
    <div className="tw-flex tw-w-full tw-h-full tw-mt-4">
      <Loading loading={loading} />
      <div className="tw-flex-col tw-w-full">
        <InputCustom
          placeholder={t('reasonsAlert.placeholder.description')}
          onChange={formik.handleChange('descricao')}
          title={t('reasonsAlert.fields.description')}
          error={formik.errors.descricao}
          touched={formik.touched.descricao}
          value={formik.values.descricao}
        />
        <div className="tw-flex-col tw-w-full tw-items-center tw-mt-4">
          <Label className="tw-text-sm tw-text-[rgb(0,48,73)] tw-opacity-60">
            {t('reasonsAlert.fields.alerts')}
          </Label>
          <Select
            isMulti
            id='typeAlert'
            name="subsidiary"
            value={alertSelected}
            options={optionsAlert}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleChangeOptions}
          />
          {formik.touched.tipo && formik.errors.tipo && (
            <span className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
              {formik.errors.tipo}
            </span>
          )}
        </div>
        <div className="tw-flex tw-items-center tw-justify-end">
          <button
            type="button"
            onClick={() => formik.handleSubmit()}
            className="button  tw-w-[40%]"
          >
            <Trans i18nKey="reasonsAlert.buttons.save" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAlert;
