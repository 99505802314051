import CargoPre from "interfaces/Delivery/CargoPre";
import { GroupedRow, ReturnedRow } from "./types";

const DEFAULT_COLOR: string = '#808080';

// Gera dados de retorno quando não há operação de agrupamento.
export const generateUngroupedReturnData = (rows: CargoPre[]): ReturnedRow[] => {
    let returnedData: ReturnedRow[] = [];
    returnedData.push({
        color: DEFAULT_COLOR,
        rows: rows
    });

    return returnedData;
}

// Gera dados de retorno quando não há operação de agrupamento.
export const generateGroupedReturnData = (rows: GroupedRow[]): ReturnedRow[] => {
    let returnedData: ReturnedRow[] = [];
    rows.forEach((item) => {
        returnedData.push({
            color: item.color,
            rows: item.rows
        });
    });

    return returnedData;
}

// Desmarca os itens selecionados.
export const uncheckedItems = (gridRef: any) => {
    if (gridRef.current != null) {
        gridRef.current?.api.forEachNode((node: any) => {
            node.setSelected(false);
        })
    }
}