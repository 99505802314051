// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line {
  border: 1px solid #ccd6db;
}

.buttonGroup {
  font-family: "Lato";
  color: #ea004c;
  font-size: 16px;
  font-weight: bold;
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/RegisterUsers/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAKA;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;AAFF","sourcesContent":[".line {\n  border: 1px solid #ccd6db;\n}\n\n.group {\n}\n\n.buttonGroup {\n  font-family: 'Lato';\n  color: #ea004c;\n  font-size: 16px;\n  font-weight: bold;\n  background: transparent;\n  border: none;\n  position: absolute;\n  top: 50%;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
