import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 0.212rem; */
`;

export const WrapperLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;