import React from 'react';
import checkedIcon from '../../../../../../assets/images/towerChecked.svg';
import './styles.scss';


interface Props {
  onChecked?: any;
  active?: boolean;
  label?: string;
}

const CheckBox: React.FC<Props> = ({ onChecked, active, label }: Props) => {
  return (
    <div className="checkbox" onClick={(e) => {
      e.stopPropagation();
      onChecked()
      }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {active ? (
          <div className="checkedCargoRoutering">
            <img src={checkedIcon} alt="" className='tw-w-3 tw-h-3' />
          </div>
        ) : (
          <div className="unCheckedCargoRoutering" />
        )}
        <span
          style={{
            marginLeft: 5,
            marginRight: 5,
            fontFamily: 'Lato',
            fontSize: '0.625rem',
          }}
        >
          {label}
        </span>
      </div>
    </div>
  )
}
export default CheckBox;
