import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Assets
import CargoPre from 'interfaces/Delivery/CargoPre';
import leftArrow from '../../../../assets/images/left-arrow.png';
import rightArrow from '../../../../assets/images/right-arrow.png';

interface CarouselProps {
  data: CargoPre[];
  onChangeAddress: () => void;
  onDeleteStep: (stepId: number, indexStep: number) => void;
  onChangeRoute: (stepId: number) => void;
}

export default function Carousel({
  data,
  onChangeAddress,
  onDeleteStep,
  onChangeRoute,
}: CarouselProps) {
  const [stepsActual, setStepsActual] = React.useState<number>(0);
  const [active, setActive] = useState<boolean>(false);
  const [cargoPre, setCargoPre] = useState({});

  const userData = useSelector((state: any) => state.auth.user);

  const { t } = useTranslation();

  const getStepsContent = useCallback(
    (stepActual: number, dataArr: any) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === dataArr.length - 1) {
        setStepsActual(dataArr.length - 1);
      } else {
        setStepsActual(prevState => {
          return prevState + 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  const getStepsContentLeft = useCallback(
    (stepActual: number, dataArr: any) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === 0) {
        setStepsActual(0);
      } else {
        setStepsActual(prevState => {
          return prevState - 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  useEffect(() => {
    if (stepsActual > data.length - 1) {
      setStepsActual(0);
    }
    if (data.length > 0) {
      setCargoPre({
        enderecoNumero: 0,
      });
    }
  }, [stepsActual, data.length]);

  console.log('Carroussel', data);

  return (
    <>
      {data.length > 0 && (
        <div className="carouselContainer">
          <div className="carouselWrapper">
            <div
              className="carouselPanelLeft"
              style={{ cursor: 'pointer' }}
              onClick={() => getStepsContentLeft(stepsActual, data)}
            >
              <img alt="" src={leftArrow} width="15" height="15" />
            </div>
            <div className="carouselPanelContent">
              <div className="carouselPanelContentText">
                <b style={{ marginRight: '3px' }}>
                  <Trans i18nKey="routeOptmization.routeSteps.steps.serviceNumber" />
                  :{'  '}
                </b>{' '}
                {data[stepsActual].enderecoNumero.length > 0
                  ? data[stepsActual].enderecoNumero
                  : data[stepsActual].enderecoNumero}
                {/* {data && data.length > 0
              && (

              ) */}
                <br />
              </div>
              <div className="carouselPanelContentText">
                <b style={{ marginRight: '3px' }}>
                  <Trans i18nKey="routeOptmization.routeSteps.steps.client" />:{' '}
                </b>
                {data[stepsActual].clienteNome.length > 0
                  ? data[stepsActual].clienteNome
                  : '---'}
                {/* {data && data.length > 0
              ? data[stepsActual].conhecimentoPre.clienteNome
              : ''}{' '} */}
                <br />
              </div>
              <div className="carouselPanelContentText">
                <b style={{ marginRight: '3px' }}>
                  <Trans i18nKey="routeOptmization.routeSteps.steps.expectedArrival" />
                  :{' '}
                </b>{' '}
                {data[stepsActual].dataPrevisao.length > 0
                  ? data[stepsActual].dataPrevisao
                  : '---'}
                {/* {data && data.length > 0
              ? data[stepsActual].arrival.formattedDateTime
              : ''}{' '} */}
                <br />
              </div>
              <div
                className="carouselPanelContentText"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <b>
                  <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryAddress" />
                  :
                </b>{' '}
                {data[stepsActual].tipo === 'Entrega'
                  ? `${data[stepsActual].enderecoLogradouro}, ${data[stepsActual].enderecoNumero}, ${data[stepsActual].enderecoComplemento}`
                  : `${data[stepsActual].enderecoLogradouroColeta}, ${data[stepsActual].enderecoNumeroColeta}, ${data[stepsActual].enderecoComplementoColeta}`}
                <br />
              </div>
              <div
                className="carouselPanelContentText"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <b style={{ marginRight: 3 }}>
                      <Trans i18nKey="routeOptmization.routeSteps.fields.neighborhood" />
                      :
                    </b>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width: '80px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data[stepsActual].enderecoBairro.length > 0
                        ? data[stepsActual].enderecoBairro
                        : data[stepsActual].enderecoBairroColeta}
                    </span>
                  </div>
                  <br />
                  <b style={{ marginRight: 3 }}>
                    <Trans i18nKey="routeOptmization.routeSteps.fields.city" />:
                  </b>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '80px',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data[stepsActual].enderecoCidade.length > 0
                      ? data[stepsActual].enderecoCidade
                      : data[stepsActual].enderecoCidadeColeta}
                  </span>
                  <br />
                </div>
                <div style={{ display: 'flex', marginTop: 5 }}>
                  <b style={{ marginRight: 3 }}>UF:</b>
                  <span
                    style={{
                      marginRight: 5,
                    }}
                  >
                    {data[stepsActual].enderecoUf.length > 0
                      ? data[stepsActual].enderecoUf
                      : data[stepsActual].enderecoUfColeta}
                  </span>
                  <br />
                </div>
              </div>
            </div>
            <div
              className="carouselPanelRight"
              style={{ cursor: 'pointer' }}
              onClick={() => getStepsContent(stepsActual, data)}
            >
              <img alt="" src={rightArrow} width="15" height="15" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
