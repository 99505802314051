// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderTopColumn {
  width: 12px;
  height: 6px;
  margin-bottom: 10px;
  cursor: pointer;
}

.containerOrderGrid {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.orderTop {
  width: 12px;
  height: 6px;
  margin-bottom: 2px;
  cursor: pointer;
}

.orderBottom {
  width: 12px;
  height: 6px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/UploadCSV/CorrectCSV/Table/OrderColumn/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".orderTopColumn {\n  width: 12px;\n  height: 6px;\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n\n.containerOrderGrid {\n  display: flex;\n  flex-direction: column;\n  margin-top: 5px;\n}\n\n.orderTop {\n  width: 12px;\n  height: 6px;\n  margin-bottom: 2px;\n  cursor: pointer;\n}\n\n.orderBottom {\n  width: 12px;\n  height: 6px;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
