// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  position: relative;
  width: 76px;
  height: 34px;
  border: 1px solid #C4C4C4;
  border-radius: 26px;
  background: #EDF2F4;
  padding: 3px;
}

.switchUnchecked {
  position: absolute;
  left: 0;
  margin-left: 4px;
  width: 34px;
  height: 26px;
  border-radius: 26px;
  background-color: #003049;
}

.switchChecked {
  position: absolute;
  right: 0;
  margin-right: 4px;
  width: 34px;
  height: 26px;
  border-radius: 26px;
  background-color: #EA004C;
}

.footerButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.button {
  height: 35px;
  background: #ea004c;
  border-radius: 6px;
  text-align: center;
  color: #ffffff;
  width: 350px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);
}
.button:hover {
  background: #b5023b;
  transition: 0.5s;
  box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/Phases/AcceptDecline/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,OAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,QAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;AAGF;;AADA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAIF;;AAFA;EACE,YAAA;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,6CAAA;AAKJ;AAHI;EACE,mBAAA;EACA,gBAAA;EACA,6CAAA;AAKN","sourcesContent":[".switch {\n  position: relative;\n  width: 76px;\n  height: 34px;\n  border: 1px solid #C4C4C4;\n  border-radius: 26px;\n  background: #EDF2F4;\n  padding: 3px;\n}\n.switchUnchecked {\n  position: absolute;\n  left: 0;\n  margin-left: 4px;\n  width: 34px;\n  height: 26px;\n  border-radius: 26px;\n  background-color: #003049;\n}\n.switchChecked {\n  position: absolute;\n  right: 0;\n  margin-right: 4px;\n  width: 34px;\n  height: 26px;\n  border-radius: 26px;\n  background-color:#EA004C;\n}\n.footerButton {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n}\n.button {\n  height: 35px;\n    background: #ea004c;\n    border-radius: 6px;\n    text-align: center;\n    color: #ffffff;\n    width: 350px;\n    box-sizing: border-box;\n    font-size: 16px;\n    font-weight: bold;\n    line-height: 19px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: none;\n    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);\n\n    &:hover {\n      background: #b5023b;\n      transition: 0.5s;\n      box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
