import styled from 'styled-components';

interface StyledProps {
  isLast?: boolean;
  isSize?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* justify-content: center; */
  /* padding: 0.00rem; */
`;

export const KpiContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props: StyledProps) => props.isSize ? 'auto auto' : 'auto auto auto'};
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  margin-top: 1rem;
  /* grid-column-gap: 1.8rem; */
`;

export const BottomLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  height: 2px;
  width: 4rem;
  position: absolute;
  bottom: 0;

`;

export const KpiItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 5rem;
  align-items: center;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
  /* border-right: ${(props: StyledProps) => props.isLast ? "none" : "1px solid #ccc"}; */
  position: relative;
  margin-bottom: 1rem;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  height: 5rem;
  width: 2px;
  /* margin-left: 2rem; */
  position: absolute;
  bottom: 0;
  right: 0;
`;

