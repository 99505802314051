export const urlAuth = '/auth';
export const urlSignUp = '/middleware/signup';
export const urlForgotPassword = '/middleware/forgotPassword';
export const urlResetPassword = '/middleware/resetPassword';
export const urlActiveAccount = '/middleware/activeAccount';
export const urlPhasesFindByFilters = '/delivery/etapas/findByFilters';
export const urlPhasesAtualizaSequencia = '/delivery/etapas/atualizaSequencia';
export const urlRomaneio = '/delivery/romaneio';
export const viaCep = 'https://viacep.com.br/ws';
export const searchAddress = 'https://nominatim.openstreetmap.org';
