// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CpfInput:focus {
  outline: 0;
  box-shadow: 0 0 0pt 2pt rgba(128, 189, 255, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/components/Collaborator/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,gDAAA;AACF","sourcesContent":[".CpfInput:focus {\n  outline: 0;\n  box-shadow: 0 0 0pt 2pt rgba(128, 189, 255, 0.6);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
