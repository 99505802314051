/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Chat from 'shared/Chat';

export function PrivateRoute({
  component: Component,
  content: Content,
  ...rest
}) {
  const token = useSelector(state => state.auth.token);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props}>
            <Chat />

            <Content />
          </Component>
        ) : (
          <Redirect
            to={{ pathname: '/', state: { from: props.history.location } }}
          />
        )
      }
    />
  );
}
