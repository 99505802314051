import { object, ref, string } from 'yup';

export default object().shape({
  cpf: string().required('* Informe seu cpf!'),
  // .min(14, '* Minimo de 14 caracteres'),
  // .max(14, '* Máximo de 14 caracteres'),
  nome: string()
    .required('* Informe seu nome!')
    .max(50, '* Máximo de 50 caracteres'),
  telefone: string().required('* Informe seu telefone!'),
  tipo: string().required('* Informe o tipo!'),
  confirmarSenha: string().when('password', (password, field) =>
    password
      ? field
          .required('* Repita sua senha!')
          .min(6, '* Mínimo 6 caracteres!')
          .oneOf([ref('password')], '* Senhas não coincidem!')
      : field
  ),
  filial: string().required('* Informe a filial'),
});
