import MultiSelect from '@khanacademy/react-multi-select';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import filterAddField from '../../../../../../assets/images/filterAddField.svg';
import filterRemoveField from '../../../../../../assets/images/filterRemoveField.svg';
import './style.css';
import './styles.css';

interface Props {
  children?: JSX.Element;
  data?: any;
  setFilter?: any;
  onFilterMultiple?: any;
  fieldFilter?: any;
  coordenates?: any;
  dataField?: any;
  dataFieldMultiple?: any;
  addField?: any;
  removeField?: any;
  onActive?: any;
  setOperationFilter?: any;
  setFilterCustom?: any;
}

const FilterTooltip: React.FC<Props> = ({
  children,
  data,
  setFilter,
  onFilterMultiple,
  fieldFilter,
  coordenates,
  dataField,
  dataFieldMultiple,
  addField,
  removeField,
  onActive,
  setOperationFilter,
  setFilterCustom,
}: Props) => {
  let timeout;
  const { t } = useTranslation();
  const [active, setActive] = useState(true);
  const [activeSelected, setActiveSelected] = useState(false);
  const [optionsReduced, setOptionsReduced] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState({
    equal: true,
    greatherThan: true,
    lessThan: true,
    greatherThanEqual: true,
    lessThanEqual: true,
    like: true,
  });
  const [optionsFilter, setOptionsFilter] = useState<any[]>([]);
  const [textOperationFilter, setTextOperationFilter] = useState('');
  const [isMulti, setIsMulti] = useState(true);
  const [textFilter, setTextFilter] = useState('');

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const mappingFilter = useCallback(() => {
    let reduced: any = [];
    let reducedOptions: any = [];
    let reducedValues: any = [];

    if (isMulti) {
      const dataFiltersResult = dataField.filters[0].values.map((item: any) => {
        reducedValues.push(item);
      });
      dataField.filters.map((itemFilters: any) => {
        reducedOptions.push(itemFilters.values);
      });
      dataFieldMultiple.map((item: any) => {
        const duplicated =
          reduced.findIndex((redItem: any) => {
            return item == redItem.label;
          }) > -1;

        if (!duplicated) {
          reduced.push({
            label: item,
            value: item,
          });
        }
      });
    } else {
      dataField.options.map((item: any) => {
        const duplicated =
          reduced.findIndex((redItem: any) => {
            return item == redItem.label;
          }) > -1;

        if (!duplicated) {
          reduced.push({
            label: `${item}`,
            value: item,
          });
        }
      });

      setTextOperationFilter(dataField.filters[0].operation);
      setTextFilter(dataField.filters[0].value);
    }

    setOptionsReduced(reduced);
    setSelectedOptions(reducedValues);
  }, [isMulti, dataFieldMultiple]);

  const defineOption = (name: string) => {
    switch (name) {
      case 'Id':
        setIsMulti(false);
        break;
      case 'ID referência':
        setIsMulti(false);
        break;
      case 'Nome do Cliente':
        setIsMulti(false);
        break;
      case 'Id Ref Cliente':
        setIsMulti(false);
        break;
      case 'Ordem entrega':
        setIsMulti(false);
        break;
      case 'Número doc':
        setIsMulti(false);
        break;
      case 'Data emissão':
        setOptions({
          ...options,
          like: false
        });
        setIsMulti(false);
        break;
      case 'Data previsão':
        setOptions({
          ...options,
          like: false
        });
        setIsMulti(false);
        break;
      case 'Latitude':
        setIsMulti(false);
        break;
      case 'Longitude':
        setIsMulti(false);
        break;
      case 'Latitude (Coleta)':
        setIsMulti(false);
        break;
      case 'Longitude (Coleta)':
        setIsMulti(false);
        break;
      case 'Email':
        setIsMulti(false);
        break;
      case 'Telefone fixo':
        setIsMulti(false);
        break;
      case 'Logradouro':
        setOptions({
          ...options,
          equal: false,
          greatherThan: false,
          lessThan: false,
          greatherThanEqual: false,
          lessThanEqual: false,
        });
        setIsMulti(false);
        break;
      case 'Complemento':
        setOptions({
          ...options,
          equal: false,
          greatherThan: false,
          lessThan: false,
          greatherThanEqual: false,
          lessThanEqual: false,
        });
        setIsMulti(false);
        break;
      case 'Número':
        setIsMulti(false);
        break;
      case 'CEP':
        setOptions({
          ...options,
          like: false
        });
        setIsMulti(false);
        break;
      case 'Telefone':
        setOptions({
          ...options,
          equal: false,
          greatherThan: false,
          lessThan: false,
          greatherThanEqual: false,
          lessThanEqual: false,
        });
        setIsMulti(false);
        break;
      case 'horário início':
        setIsMulti(false);
        setOptions({
          ...options,
          like: false,
        });
        break;
      case 'horário fim':
        setIsMulti(false);
        setOptions({
          ...options,
          like: false,
        });
        break;
      case 'Qtd. Volumes':
        setIsMulti(false);
        break;
      case 'Cubagem':
        setIsMulti(false);
        break;
      case 'Peso':
        setIsMulti(false);
        break;
      case 'Valor':
        setIsMulti(false);
        break;
      case 'Etiqueta':
        setIsMulti(false);
        break;
      case 'Placa':
        setIsMulti(false);
        break;
      default:
        setIsMulti(true);
        break;
    }
  };
  useEffect(() => {
    defineOption(dataField.name);
  }, [dataField]);
  useEffect(() => {
    if (coordenates.clientX) {
      showTip();
      mappingFilter();
    }
  }, [isMulti, dataFieldMultiple, coordenates]);

  return (
    <div>
      {isMulti ? (
        <div className="Tooltip-Wrapper-Multi" onBlur={onActive}>
          {children}

          {active && (
            <div
              className={
                dataField.filters && dataField.filters.length > 1
                  ? `Tooltip-Tip-Filter-Large-Multi ${' '}`
                  : `Tooltip-Tip-Filter-Multi ${'top'}`
              }
              style={{
                left: `${coordenates.clientX - 185}px`,
              }}
            >
              {dataField.filters &&
                dataField.filters.map((item: any, index: number) => (
                  <>
                    <div className="containerFilter">
                      <div
                        className="containerFilterColumn"
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <MultiSelect
                          options={optionsReduced}
                          selected={selectedOptions}
                          onSelectedChanged={(selectedOption: any) => {
                            const selectedFilters = selectedOptions;

                            setSelectedOptions(selectedOption);

                            setActiveSelected(!activeSelected);
                          }}
                          overrideStrings={{
                            selectSomeItems: t('multiselect.selectSomeItems'),
                            allItemsAreSelected: t(
                              'multiselect.allItemsAreSelected'
                            ),
                            selectAll: t('multiselect.selectAll'),
                            search: t('multiselect.search'),
                          }}
                        />

                        <button
                          className="buttonFilterTower"
                          type="button"
                          style={{
                            marginTop: 0,
                            marginBottom: '9px',
                            marginLeft: '16px',
                          }}
                          onClick={() => {
                            setActive(!active);
                            onFilterMultiple(selectedOptions, 'IN', index);
                          }}
                        >
                          Aplicar
                          {/* <Trans i18nKey="controlTower.buttonFilter" /> */}
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          )}
        </div>
      ) : (
        <div className="Tooltip-Wrapper" onBlur={onActive}>
          {children}

          {active && (
            <div
              className={
                dataField.filters && dataField.filters.length > 1
                  ? `Tooltip-Tip-Filter-Large ${'top'}`
                  : `Tooltip-Tip-Filter ${'top'}`
              }
              style={{ left: `${coordenates.clientX - 145}px` }}
            >
              {dataField.filters &&
                dataField.filters.map((item: any, index: number) => (
                  <>
                    <div className="containerFilter">
                      <div className="containerFilterColumn">
                        <div>
                          <input
                            id="el"
                            type={
                              dataField.type && dataField.type === 'NUMBER'
                                ? 'number'
                                : dataField.type === 'DATE'
                                ? 'date'
                                : dataField.type === 'TIME'
                                ? 'time'
                                : 'text'
                            }
                            className="inputFilter"
                            placeholder={dataField.name ? dataField.name : ''}
                            onChange={e => {
                              setTextFilter(e.target.value);
                              setFilter(e, item.operation, index);
                            }}
                            value={item.value}
                          />
                        </div>

                        <select
                          className="inputFilter"
                          onChange={e => {
                            setTextOperationFilter(e.target.value);

                            // switch (e.target.value) {
                            //   case 'EQUAL':
                            //     setOptions({
                            //       ...options,
                            //       equal: false,
                            //     });
                            //     break;
                            //   case 'GREATER_THAN':
                            //     setOptions({
                            //       ...options,
                            //       greatherThan: false,
                            //     });
                            //     break;
                            //   case 'LESS_THAN':
                            //     setOptions({
                            //       ...options,
                            //       lessThan: false,
                            //     });
                            //     break;
                            //   case 'GREATER_THAN_EQUAL':
                            //     setOptions({
                            //       ...options,
                            //       greatherThanEqual: false,
                            //     });
                            //     break;
                            //   case 'LESS_THAN_EQUAL':
                            //     setOptions({
                            //       ...options,
                            //       lessThanEqual: false,
                            //     });
                            //     break;
                            //   case 'MATCH':
                            //     setOptions({
                            //       ...options,
                            //       like: false,
                            //     });
                            //     break;
                            // }

                            setOperationFilter(e, item.operation, index);
                          }}
                          value={item.operation}
                        >
                          <option label="Selecione uma opção" />
                          {options.equal && (
                            <option label="Igual (=)" value="EQUAL" />
                          )}
                          {options.greatherThan && (
                            <option label="Maior (>)" value="GREATER_THAN" />
                          )}
                          {options.lessThan && (
                            <option label="Menor (<)" value="LESS_THAN" />
                          )}
                          {options.greatherThanEqual && (
                            <option
                              label="Maior/Igual (>=)"
                              value="GREATER_THAN_EQUAL"
                            />
                          )}
                          {options.lessThanEqual && (
                            <option
                              label="Menor/Igual (<=)"
                              value="LESS_THAN_EQUAL"
                            />
                          )}
                          {options.like && (
                            <option label="Like (%)" value="MATCH" />
                          )}
                        </select>
                      </div>
                      {index + 1 === dataField.filters.length &&
                      dataField.filters &&
                      dataField.filters.length > 1 ? (
                        <div
                          className="containerFilterColumn click"
                          onClick={() => removeField()}
                        >
                          <img src={filterRemoveField} alt="" />
                        </div>
                      ) : (
                        <>
                          {dataField.filters && dataField.filters.length === 1 && (
                            <div
                              className="containerFilterColumn click"
                              onClick={() => addField()}
                            >
                              <img src={filterAddField} alt="" />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ))}
              <div className="buttonFilterTooltip">
                <button
                  className="buttonFilterTower"
                  type="button"
                  style={{
                    marginTop: 0,
                    marginBottom: '9px',
                    marginLeft: '16px',
                  }}
                  onClick={() => {
                    if (textOperationFilter.length > 0) {
                      setActive(!active);
                      setFilterCustom();
                    }
                  }}
                >
                  Aplicar
                  {/* <Trans i18nKey="controlTower.buttonFilter" /> */}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterTooltip;
