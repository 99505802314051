/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useState } from 'react';

import Pagination from '@material-ui/lab/Pagination';
import EditIcon from 'assets/images/editIcon.svg';
import SearchFilter from 'components/SearchFilter';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import deleteIcon from '../../../assets/images/deleteIcon.svg';

export default function ListVehicles({ onEdit }) {
  const { t } = useTranslation();

  const [removeConfirmation, setRemoveConfirmation] = React.useState(false);
  const [removeId, setRemoveId] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [valueSearch, setValueSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [optionsFilter, setOptionsFilter] = useState('placa');

  const [vehicles, setVehicles] = useState([]);

  const getVehicles = useCallback(async () => {
    try {
      const currentFilters = [];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [],
      };
      const response = await api.post('/delivery/veiculos/pageable', data);

      setTotalPages(Math.ceil(response.data.totalElements / 10));

      setVehicles(response.data.content);
    } catch (err) {}
  }, [currentPage, filters]);

  const deleteVehicles = useCallback(async vehiclesId => {
    try {
      const response = await api.delete(`/delivery/veiculos/${vehiclesId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('vehicles.msgSuccessRemove'),
          NotificationType.SUCCESS
        );
      }

      setRemoveConfirmation(false);

      getVehicles();
    } catch (err) {
      // BackendErrorNotification(err);
    }
  }, []);

  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  const paginate = pageNumber => setCurrentPage(pageNumber - 1);

  return (
    <>
      <Modal isOpen={removeConfirmation}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteVehicles(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setRemoveConfirmation(!removeConfirmation)}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <div className="filter">
        <Label className="search-Label" htmlFor="search-input">
          <SearchFilter
            style={{
              position: 'relative',
              alignItems: 'center',
              marginLeft: 60,
              marginRight: 0,
            }}
            isLicensePlate
            isIDVehicle
            isDriver
            isMaximumService
            isMaximumWeight
            isMaximumVolume
            isMaximumPrice
            isSubsidiary
            isService
            isTypeSubsidiary={optionsFilter === 'subsidiary'}
            // isTypeVehicleDriver={optionsFilter === 'tipo'}
            options={JSON.parse(sessionStorage.getItem('subsidiaryList'))}
            valueSearch={valueSearch}
            optionValue={optionsFilter}
            handleChange={e => setValueSearch(e.target.value)}
            handleSelect={e => setOptionsFilter(e.target.value)}
            handleSelectDriver={e => setValueSearch(e.target.value)}
            onExecuteFilter={() => {
              setCurrentPage(0);
              if (optionsFilter === 'placa' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'placa',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              } else if (optionsFilter === 'tipo' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'tipo',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (
                optionsFilter === 'motorista.nome' &&
                valueSearch.length > 0
              ) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'motorista.nome',
                    value: valueSearch,
                    operation: 'MATCH',
                  },
                ]);
              } else if (optionsFilter === 'id' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'id',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (
                optionsFilter === 'quantidadeAtendimento' &&
                valueSearch.length > 0
              ) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'quantidadeAtendimento',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (optionsFilter === 'peso' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'peso',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (
                optionsFilter === 'cubagem' &&
                valueSearch.length > 0
              ) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'cubagem',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (optionsFilter === 'valor' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'valor',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else if (optionsFilter === 'subsidiary' && valueSearch.length > 0) {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                  {
                    field: 'filial.id',
                    value: valueSearch,
                    operation: 'EQUAL',
                  },
                ]);
              } else {
                setFilters([
                  {
                    field: 'status',
                    value: 'ACTIVE',
                    operation: 'EQUAL',
                  },
                ]);
              }
            }}
          />
        </Label>
      </div>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>
                <span>
                  <Trans i18nKey="vehicles.fields.licensePlate" />
                </span>
              </th>
              <th>
                <span>
                  <Trans i18nKey="vehicles.fields.idReference" />
                </span>
              </th>
              <th>
                <span>
                  <Trans i18nKey="vehicles.fields.description" />
                </span>
              </th>
              <th>
                <span>
                  <Trans i18nKey="vehicles.typeVehicle" />
                </span>
              </th>
              <th>
                <span>
                  <Trans i18nKey="vehicles.fields.ownerVehicles" />?
                </span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {vehicles.map(item => (
              <tr className="lineGroup">
                <td>
                  <span>{item.placa}</span>
                </td>
                <td>
                  <span>{item.idReferencia}</span>
                </td>
                <td>
                  <span>{item.descricao}</span>
                </td>
                <td>
                  <span>{item.tipo === "TRES_QUARTOS" ? "3/4" : item.tipo === "TRES_QUARTOS_FRIO" ? "3/4 FRIO" : item.tipo}</span>
                </td>
                <td>
                  <span>
                    {item.veiculoProprio ? (
                      <>
                        {navigator.language === 'pt' ||
                        navigator.language === 'pt-BR'
                          ? 'SIM'
                          : 'YES'}
                      </>
                    ) : (
                      <>
                        {navigator.language === 'pt' ||
                        navigator.language === 'pt-BR'
                          ? 'NAO'
                          : 'NO'}
                      </>
                    )}
                  </span>
                </td>
                <td>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => onEdit(item.id)}
                  >
                    <img
                      src={EditIcon}
                      alt=""
                    />
                  </Button>
                  <Button
                    color="link"
                    className="noMargin"
                    onClick={() => {
                      setRemoveId(item.id);
                      setRemoveConfirmation(!removeConfirmation);
                    }}
                  >
                    <img src={deleteIcon} alt="" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, page) => paginate(page)}
        />
      </div>
    </>
  );
}
