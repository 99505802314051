import { Trans, useTranslation } from 'react-i18next';

import 'pages/DeliveryTracking/styles.scss';

import { Content } from 'components/Ocorrence/styles';

import IconLocalization from 'assets/images/assinatura.svg';
import IconPhoto from 'assets/images/knowledge/photo.svg';
import { CargoOccurrenceData, CargoStageMedia } from 'components/DetailsDelivery/interfaces/types';
import CustomModal from 'components/ModalCustom';
import { useCallback, useState } from 'react';
import Carroussel from './Carroussel';


interface Props {
  onClear: () => void;
  data: CargoOccurrenceData[];
  cargoId: number;
}

export default function OcorrencesDelivery({ onClear, data, cargoId }: Props) {
  const [medias, setMedias] = useState<string[]>([])
  const [isOpenMedias, setIsOpenMedias] = useState<boolean>(false);
  const [typeMedia, setTypeMedia] = useState('');

  const { t } = useTranslation();

  const requestMedia = useCallback((cargo: CargoOccurrenceData, type: string) => {
    let medias: CargoStageMedia[] = [];
    if(type === "FOTO") {
      medias = cargo.medias.filter((row: CargoStageMedia) => row.tipo === "FOTO");
      setTypeMedia('Foto');
    } else {
      medias = cargo.medias.filter((row: CargoStageMedia) => row.tipo === "ASSINATURA");
      setTypeMedia('Assinatura');

    }

    const mappingMedias = medias.map((row: CargoStageMedia) => row.url);

    setMedias(mappingMedias);

    setIsOpenMedias(!isOpenMedias);

  }, []);


  return (
    <CustomModal isOpen label={t('ocorrences.menu')} styles={{ width: '50%' }} isClose={onClear}>
      <>
      {isOpenMedias && (
        <Carroussel images={medias} onClear={() => setIsOpenMedias(!isOpenMedias)} title={typeMedia} />
      )}
        <Content style={{ maxWidth: '100%' }}>
          <div className="table-responsive">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>N</th>
                  <th>
                    <Trans i18nKey="reasons.fields.description" />
                  </th>
                  <th>Tipo</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {data.map((item: CargoOccurrenceData) => (
                  <tr className="lineGroup">
                    <td>{item.occurrence.numero}</td>
                    <td
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: 10,
                      }}
                    >
                      <p>{item.occurrence.descricao}</p>
                    </td>
                    <td>{item.occurrence.tipo}</td>
                    <td>
                      <img src={IconPhoto} onClick={() => requestMedia(item, "FOTO")} />
                    </td>
                    <td>
                      <img src={IconLocalization} className='tw-cursor-pointer' onClick={() => requestMedia(item, "ASSINATURA")} />

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Content>
      </>

    </CustomModal>
  );
}
