import { object, ref, string } from 'yup';

export default object().shape({
  nome: string()
    .required('* Enter the name of the subsidiary!')
    .min(6, '* Minimum of 6 characteres!')
    .max(40, '* Maximum of 40 characteres!'),
  cnpj: string().required('* Enter the cnpj of the subsidiary!'),
  pais: string().required('* Enter the country of the subsidiary!'),
  cep: string().required('* Enter the Postal Code of the subsidiary!'),
  logradouro: string().required('* Enter the street of the subsidiary!'),
  numero: string().required('* Enter the number of the subsidiary!'),
  bairro: string().required('* Enter the neighborhood of the subsidiary!'),
  cidade: string().required('* Enter the city of the subsidiary!'),
  estado: string().required('* Enter the state of the subsidiary!'),
});
