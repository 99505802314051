/* eslint-disable prefer-const */
import searchIcon from 'assets/images/ant-design_search-outlined.png';
import closeModalIcon from 'assets/images/closeIcon.svg';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import formValidatorEN from 'components/ActivityType/formValidatorEN';
import formValidatorPT from 'components/ActivityType/formValidatorPT';
import Pagination from 'components/Pagination';
import { BoxForm, Content, Footer } from 'components/Reasons/styles';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import api from 'services/api';

export default function ActivityType({ onClear, active }) {
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [optionsFilter, setOptionsFilter] = useState('descricao');
  const [valueSearch, setValueSearch] = useState('');
  const [activities, setActivities] = useState([]);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const dispatch = useDispatch();

  const [totalPages, setTotalPages] = useState(0);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const { t } = useTranslation();

  const filtersData = useMemo(() => {
    let computedPages = activities;

    if (optionsFilter === 'descricao') {
      computedPages = computedPages.filter(op =>
        op.nome.toLowerCase().includes(valueSearch.toLowerCase())
      );
    }

    setTotalPages(computedPages.length);

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [optionsFilter, activities, currentPage, valueSearch]);

  const getActivityData = useCallback(async () => {
    try {
      const response = await api.get('/delivery/tiposAtividades');

      const activitiesOrder = response.data.sort((a, b) => b.id - a.id);

      setActivities(activitiesOrder);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const deleteDriver = useCallback(
    async operatorId => {
      try {
        const response = await api.delete(
          `delivery/tiposAtividades/${operatorId}`
        );
        if (response.status === 204) {
          FrontendNotification(
            t('activity.messageRemove'),
            NotificationType.SUCCESS
          );
        } else if (response.status === 400) {
          FrontendNotification(
            t('activity.messageRemoveUsed'),
            NotificationType.ERROR
          );
        }

        setRemoveConfirmation(false);

        getActivityData();
      } catch (err) {}
    },
    [getActivityData, t]
  );

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const getLanguages = () => {
    return i18next.language;
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const currentData = activities.slice(indexOfFirstPage, indexOfLastPage);

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema:
      getLanguages() === 'pt' || getLanguages() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm, updateId);
    },
  });

  const handleSubmit = useCallback(
    async (values, reset, activitiesUpdate) => {
      try {
        const data = {
          nome: values.name,
        };
        if (activitiesUpdate) {
          const response = await api.patch(
            `/delivery/tiposAtividades/${activitiesUpdate}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('activity.messageUpdated'),
              NotificationType.SUCCESS
            );
            if (active === true) {
              onClear();
              setModal(!modal);
            }
          }
          setEdit(false);
          setUpdateId();
        } else {
          const response = await api.post(`/delivery/tiposAtividades`, data);
          if (response.status) {
            FrontendNotification(
              t('activity.messageCreated'),
              NotificationType.SUCCESS
            );
          }
        }
        formik.handleReset();

        getActivityData();
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [active, formik, getActivityData, modal, onClear, t]
  );

  useEffect(() => {
    getActivityData();
  }, [getActivityData]);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', height: '100%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <div
            className="d-flex"
            style={{
              position: 'absolute',
              right: 74,
              padding: 20,
              top: 30,
            }}
          >
            <Label className="search-Label" htmlFor="search-input">
              <div className="search">
                <input
                  type="search"
                  name="BuscaRomaneio"
                  id="search-input"
                  placeholder={t('driver.search')}
                  className="tw-w-48 tw-h-9 tw-bg-[#fff] tw-box-border tw-rounded tw-font-normal tw-text-sm tw-text-[#003049] tw-pl-3 tw-mr-5"
                  value={valueSearch}
                  onChange={e => {
                    setValueSearch(e.target.value);
                  }}
                  style={{ border: '2px solid rgba(0,48,73,0.2)' }}
                />
                <img
                  src={searchIcon}
                  alt=""
                  style={{
                    width: 24,
                    height: 24,
                    position: 'relative',
                    right: 56,
                    top: '-2px',
                  }}
                />
              </div>
            </Label>
            <div style={{ flexDirection: 'column' }}>
              <select
                name="filialSelector"
                id="filial1"
                className="tw-w-40 tw-border-none tw-bg-transparent tw-font-bold tw-text-base tw-text-[#ea004c] tw-mb-3 tw-p-0 tw-mr-5"
                onChange={e => setOptionsFilter(e.target.value)}
                style={{ borderBottom: '2px solid #EA004C' }}
              >
                <option
                  value="descricao"
                  label={t('activity.fields.description')}
                />
              </select>
            </div>
          </div>
          <p className="title">
            <Trans i18nKey="activity.title" />
          </p>
          <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
            <BoxForm>
              <Label
                for="SelectState"
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                <Trans i18nKey="activity.fields.description" />
              </Label>

              <Input
                name="select"
                id="SelectState"
                placeholder={t('activity.fields.description')}
                onChange={formik.handleChange('name')}
                mask="99.999.999/9999-99"
                style={{
                  maxWidth: 450,
                  height: 60,
                  background: '#FFFFFF',
                  border: '2px solid rgba(0, 48, 73, 0.2)',
                  boxSizing: 'border-box',
                  borderRadius: 10,
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 14,
                  lineHeight: 19,
                  color: '#003049',
                  marginTop: 8,
                  paddingLeft: 10,
                }}
                value={formik.values.name}
              />

              {formik.touched.name && formik.errors.name && (
                <p style={{ fontSize: 14, color: 'red' }}>
                  {formik.errors.name}
                </p>
              )}

              <Footer>
                {edit ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <button
                          onClick={() => {
                            formik.resetForm({});
                            setEdit(false);
                            setUpdateId();
                          }}
                          className="button"
                          id="buttonCancelActivityType"
                          type="button"
                          style={{
                            background: '#ccc',
                            color: '#000',
                            marginTop: 30,
                          }}
                        >
                          <Trans i18nKey="common.cancel" />
                        </button>
                      </Col>

                      <Col md={6}>
                        <button
                          onClick={formik.handleSubmit}
                          className="button"
                          id="buttonUpdateActivityType"
                          type="button"
                          style={{ marginTop: 30 }}
                        >
                          <Trans i18nKey="activity.update" />
                        </button>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <button
                    onClick={formik.handleSubmit}
                    className="button"
                    id="buttonSaveActivityType"
                    type="button"
                    style={{ maxWidth: 450, marginTop: 30 }}
                  >
                    <Trans i18nKey="activity.save" />
                  </button>
                )}
              </Footer>
            </BoxForm>

            <Content>
              <div className="table-responsive">
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>
                        <Trans i18nKey="activity.fields.description" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtersData.map((item, index) => (
                      <tr className="lineGroup">
                        <td>
                          <p style={{ marginBottom: 0 }}>{item.nome}</p>
                        </td>
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              color="link"
                              className="noMargin"
                              onClick={() => {
                                formik.setFieldValue('name', item.nome);

                                setEdit(true);
                                setUpdateId(item.id);
                              }}
                            >
                              <img
                                src={EditIcon}
                                alt=""
                              />
                            </Button>
                            <Button
                              color="link"
                              className="noMargin"
                              onClick={() => {
                                setRemoveId(item.id);
                                setRemoveConfirmation(!removeConfirmation);
                              }}
                            >
                              <img
                                src={DeleteIcon}
                                alt=""
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  perPage={perPage}
                  total={totalPages}
                  onPaginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </Content>
          </div>
        </div>
      </Modal>
    </>
  );
}
