export enum VehicleType {
  VUC = "VUC",
  TOCO = "TOCO",
  BITRUCK = "BITRUCK",
  TRUCK = "TRUCK",
  CARRETA = "CARRETA",
  VAN = "VAN",
  VEICULO_PASSEIO = "VEICULO_PASSEIO",
  MOTO = "MOTO",
  BICICLETA = "BICICLETA",
  TRUCK_FRIO = "TRUCK_FRIO",
  CARRETA_FRIO = "CARRETA_FRIO",
  TOCO_FRIO = "TOCO_FRIO",
  BITRUCK_FRIO = "BITRUCK_FRIO",
  RODOTREM = "RODOTREM",
  TRES_QUARTOS = "TRES_QUARTOS",
  TRES_QUARTOS_FRIO = "TRES_QUARTOS_FRIO",
}