/* eslint-disable operator-assignment */
import produce from 'immer';

const INITIAL_STATE = {
  selectedVehicles: [],
  dateForecast: ''
};

export default function selectedVehicles(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@vehicles/ADD_SELECTED_VEHICLES': {
        draft.selectedVehicles.unshift(action.payload.selectedVehiclesArr);
        break;
      }
      case '@vehicles/UPDATE_SELECTED_VEHICLES': {
        draft.selectedVehicles = action.payload.selectedVehiclesArr;
        break;
      }

      case '@vehicles/REMOVE_SELECTED_VEHICLES': {
        draft.selectedVehicles.splice(action.payload.indexSelectedVehicles, 1);
        break;
      }

      case '@vehicles/UPDATE_VEHICLES_ROADTRIP': {
        draft.selectedVehicles[
          action.payload.indexSelectedVehicles
        ].roundtrip = !draft.selectedVehicles[
          action.payload.indexSelectedVehicles
        ].roundtrip;
        break;
      }

      case '@vehicles/UPDATE_VEHICLES_OUTPUT_FORECAST': {
        draft.selectedVehicles[
          action.payload.indexSelectedVehicles
        ].previsaoInicio = action.payload.value;
        break;
      }

      case '@vehicles/CLEAR_VEHICLES_SELECTED': {
        draft.selectedVehicles = [];
        break;
      }

      case '@vehicles/SET_DATE_FORECAST': {
        draft.dateForecast = action.payload.date;
        break;
      }

      default:
    }
  });
}
