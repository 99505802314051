import React from 'react';
import { ButtonCustom } from './styles';

interface ButtonProps {
  label: string,
  className?: string,
  onClick: () => void,
  icon?: string;
  theme?: string,
  styles?: any
}

const Button: React.FC<ButtonProps> = ({label, className, onClick, theme, styles, icon}: ButtonProps) => {
  return (
    <ButtonCustom onClick={onClick} className={className} style={styles}>
      {label}
      {icon && (

        <img src={icon} style={{ marginLeft: '10%' }} />
      )}
    </ButtonCustom>
  )
}

export default Button;