import CachedIcon from '@mui/icons-material/Cached';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  MenuProps,
  styled
} from '@mui/material';
import React from 'react';
import moreIcon from '../../../../../assets/images/moreIcon.svg';
import { MoreIcon } from '../styles';

interface MoreComponentProps {
  onFilter: () => void;
  onUpdate: () => void;
}

const MoreComponent: React.FC<MoreComponentProps> = ({
  onFilter,
  onUpdate,
}) => {
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }: any) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],

      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenuItem-root': {
        '&:hover': {
          backgroundColor: '#6369D1',
          color: '#fff',
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
        },
        fontSize: '0.813rem',
        fontFamily: 'Lato',
        fontWeight: 400,
        fontStyle: 'normal',
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  }));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        onClick={handleClick}
      >
        <MoreIcon src={moreIcon} />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onFilter();
          }}
          disableRipple
        >
          <FilterListIcon sx={{ '&:hover': { color: 'white' } }} />
          Filtros
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem
          onClick={() => {
            handleClose();
            onUpdate();
          }}
          disableRipple
        >
          <CachedIcon sx={{ '&:hover': { color: 'white' } }} />
          Atualizar
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
      </StyledMenu>
    </div>
  );
};

export default MoreComponent;
