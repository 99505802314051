import CustomDialog from 'components/CustomDialog';
import { Filter } from 'components/Grid/types';
import { Path } from 'constants/path';
import { Region } from 'pages/Delivery/components/Region';
import { Route } from 'pages/Delivery/components/Route';
import { Sector } from 'pages/Delivery/components/Sector';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import Filters, { BranchSchema } from './FormFilter';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (data: Filter[]) => void;
}

/**
 * Componente modal para aplicar filtros.
 */
const ModalFilter: React.FC<Props> = ({ onClose, open, onConfirm }: Props) => {
  const [branches, setBranches] = useState<BranchSchema[]>([]);
  const [route, setRoute] = useState<Route[]>([]);
  const [region, setRegion] = useState<Region[]>([]);
  const [sector, setSector] = useState<Sector[]>([]);

  const { t } = useTranslation();

  const getBranch = useCallback(async () => {
    try {
      const response = await api.get(Path.REGISTRATION_BRANCH);

      setBranches(response.data);
    } catch {}
  }, []);

  const getRoute = useCallback(async () => {
    try {
      const response = await api.get(Path.DELIVERY_ROUTE);

      setRoute(response.data);
    } catch {}
  }, []);

  const getRegion = useCallback(async () => {
    try {
      const response = await api.get(Path.DELIVERY_REGION);

      setRegion(response.data);
    } catch {}
  }, []);

  const getSection = useCallback(async () => {
    try {
      const response = await api.get(Path.DELIVERY_SECTOR);

      setSector(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    // Busca as filiais.
    getBranch();

    // Busca as rotas.
    getRoute();

    // Busca as regiões.
    getRegion();

    // Busca as seções/setores.
    getSection();

  }, [getBranch, getRoute, getRegion, getSection]);

  return (
    <CustomDialog open={open} onClose={onClose} showHeaderIcon title={t('filters')}>
      <Filters onSendFilters={(data: Filter[]) => onConfirm(data)} branches={branches} route={route} region={region} sector={sector} />
    </CustomDialog>
  );
};

export default ModalFilter;
