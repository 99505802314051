import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Modal } from 'reactstrap';
import closeModalIcon from '../../../assets/images/closeIcon.svg';
import iconFilter from '../../../assets/images/filterTower.svg';
import CardKnowledge from '../../../components/CardKnowledge';
import api from '../../../services/api';
import Filters from './Filters';
import './styles.scss';



export default function ListAttendances({
  onClear,
  idRomaneio,
  alertCargo,
  isAlert,
}) {
  const [modal, setModal] = React.useState(true);
  const [knowledge, setKnowledge] = useState([]);
  const [moreDetailsListKnowledge, setMoreDetailsListKnowledge] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);

  function clearNav() {
    setModal(!modal);
    onClear();
  }

  const getCargoData = useCallback(async () => {
    try {
      let dataCargo;
      if (alertCargo) {
        dataCargo = {
          filters: [
            {
              field: 'id',
              value: alertCargo,
              operation: 'EQUAL',
            },
          ],
          orders: [],
          size: 1000,
          page: 0,
        };
      } else {
        dataCargo = {
          filters: [
            {
              field: 'romaneio.id',
              value: idRomaneio,
              operation: 'EQUAL',
            },
          ],
          orders: [],
          size: 1000,
          page: 0,
        };
      }
      const { data } = await api.post(
        '/delivery/conhecimentos/pageable',
        dataCargo
      );



      const orderLate = data.content
        .filter(op => op.statusEntrega === 'ATRASADO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderOnTime = data.content
        .filter(op => op.statusEntrega === 'NO_PRAZO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderCancelled = data.content
        .filter(op => op.statusEntrega === 'CANCELADO')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderDone = data.content
        .filter(op => op.statusEntrega === 'ENTREGUE')
        .sort((a, b) => a.ordemEntrega - b.ordemEntrega);
      const orderLoads = orderLate.concat(
        orderOnTime,
        orderCancelled,
        orderDone
      );

      setKnowledge(orderLoads);

    } catch (err) {}
  }, []);

  useEffect(() => {
    getCargoData();
  }, [getCargoData]);

  console.log(knowledge);

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '90%' }}
      >
        <div className="containerList">
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              className="title"
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: '#003049',
                marginBottom: 0,
              }}
            >
              <Trans i18nKey="listAttendances.title" />
            </p>

          </div>

          <div style={{ display: 'flex' }}>
            {!isAlert && !filterVisible && (
              <button
                className="buttonFilterTower"
                type="button"
                onClick={e => {
                  e.preventDefault();
                  setFilterVisible(!filterVisible);
                }}
              >
                <img src={iconFilter} alt="" style={{ marginRight: 6 }} />
                <span className="buttonTextFilter">
                  <Trans i18nKey="controlTower.buttonFilter" />
                </span>
              </button>
            )}

            {filterVisible && (
              <Filters
                data={knowledge}
                romaneioId={idRomaneio}
                setData={data => setKnowledge(data)}
                onClose={() => setFilterVisible(!filterVisible)}
              />
            )}
          </div>

          <div className="wrapperAttendances">
            {knowledge.length === 0 ? (
              <></>
            ) : knowledge.map((c, index) => (

              <CardKnowledge
                data={c}
                indexKnowledge={index}
                dataArr={knowledge}
                moreDetailsList={idMore => setMoreDetailsListKnowledge(idMore)}
                />
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}