import { ValueFormatterParams } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';
import { DetailedRow } from 'components/CreateCargo/types';
import Loading from 'core/common/Loading';
import CustomDialog from './CustomDialog';
// import Loading from 'components/Grid/Loading';
import { Filter } from 'components/Grid/types';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { FC, useCallback, useRef, useState } from 'react';
import { Resizable } from 'react-resizable';
import api from 'services/api';
import { formatDate, formatNumberBR, numberToCurrencyBRL } from 'util/format';
import FormFilter from './FormFilter';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (rows: any[]) => void;
}

const CreateAttendance: FC<Props> = (props: Props) => {
  const [width, setWidth] = useState(900);
  const [height, setHeight] = useState(400);

  // Formata a coluna com a moeda brasileira.
  const currencyFormatter = (params: ValueFormatterParams) => {
    return numberToCurrencyBRL(Number(params.value));
  };

  // Formata a coluna com a unidade de peso.
  const kgFormatter = (params: ValueFormatterParams) => {
    return formatNumberBR(Number(params.value)) + ' kg';
  };

  // Formata a coluna com a unidade de volume.
  const m3Formatter = (params: ValueFormatterParams) => {
    return formatNumberBR(Number(params.value)) + ' m³';
  };

  // Formata a coluna com a unidade de peso.
  const dateFormatter = (params: ValueFormatterParams) => {
    if (params.data != null && params.data.estimateDate != null) {
      return formatDate(params.data.estimateDate);
    }
    return '';
  };

  const statusFormatter = (params: ValueFormatterParams) => {
    if (params.data != null && params.data.tipo != null) {
      return params.data.tipo === 'Entrega'
        ? params.data.mapPrecisaoStatus
        : params.data.mapPrecisaoStatusColeta;
    }
    return '';
  };

  const gridRef = useRef<AgGridReact>(null);
  const [gridRowData, setGridRowData] = useState<DetailedRow[]>([]);

  const [columns] = useState([
    {
      field: 'branchShipping',
      headerName: 'Filial de Expedição',
      filter: true,
      checkboxSelection: true,
    },

    {
      field: 'branchShippingReferenceId',
      headerName: 'Id Filial de Expedição',
      filter: true,
    },
    {
      field: 'reentrega',
      headerName: 'Reentrega',
      filter: true,
    },
    {
      field: 'docNumber',
      headerName: 'Nº Doc',
      filter: true,
    },
    {
      field: 'referenceId',
      headerName: 'Id Ref',
      filter: true,
    },
    {
      field: 'customerName',
      headerName: 'Cliente',
      width: 120,
      filter: true,
    },
    {
      field: 'customerReferenceId',
      headerName: 'Id Cliente',
      filter: true,
    },
    {
      field: 'status',
      headerName: 'Acuracidade',
      filter: true,
    },
    {
      field: 'addressStreet',
      headerName: 'Logradouro',
      filter: true,
    },
    {
      field: 'addressCity',
      headerName: 'Cidade',
      filter: true,
    },
    {
      field: 'addressState',
      headerName: 'Estado',
      filter: true,
    },
    {
      field: 'addressNeighborhood',
      headerName: 'Bairro',
      filter: true,
    },
    {
      field: 'addressNumber',
      headerName: 'Numero',
      filter: true,
    },
    {
      field: 'addressComplement',
      headerName: 'Complemento',
      filter: true,
    },
    {
      field: 'addressZipcode',
      headerName: 'CEP',
      filter: true,
    },
    {
      field: 'obs',
      headerName: 'Observação',
      filter: true,
    },
    {
      field: 'route',
      headerName: 'Rota',
      filter: true,
    },
    {
      field: 'region',
      headerName: 'Regiao',
      filter: true,
    },
    {
      field: 'section',
      headerName: 'Setor',
      filter: true,
    },
    {
      field: 'weight',
      headerName: 'Peso',
      valueFormatter: kgFormatter,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      valueFormatter: m3Formatter,
    },
    {
      field: 'cubage',
      headerName: 'Cubagem',
      valueFormatter: m3Formatter,
    },
    {
      field: 'value',
      headerName: 'Valor',
      valueFormatter: currencyFormatter,
    },
    {
      field: 'parentReferenceId',
      headerName: 'ID Link',
      filter: true,
    },
  ]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any[]>([]);
  // const [orders, setOrders] = useState<Order[]>([]);

  const onGridReady = useCallback(async (id: string) => {
    try {
      if (id.length > 0) {
        setLoading(true);
        // const items: CargoPre[] = selectedRow.rows;
        const response = await api.get(
          `/delivery/conhecimentosPre/getCargosByGenericId/${id}`
        );
        const items: any[] = response.data;

        // Carrega os itens associados ao agrupamento no grid de detalhe.
        const rowData: DetailedRow[] = [];
        items.forEach((i: any) => {
          rowData.push({
            id: String(i.id),
            isErro: i.isErro !== null ? i.isErro : false,
            clienteNome: String(i.clienteNome),
            customerReferenceId: String(i.clienteIdReferencia),
            tipo: String(i.tipo),
            status: `${
              i.tipo === 'Entrega'
                ? i.mapPrecisaoStatus
                : i.mapPrecisaoStatusColeta
            }%`,
            customerName: String(i.clienteNome),
            addressStreet: String(
              i.enderecoLogradouro && i.enderecoLogradouro.length > 0
                ? i.enderecoLogradouro
                : i.enderecoLogradouroColeta &&
                  i.enderecoLogradouroColeta.length > 0
                ? i.enderecoLogradouroColeta
                : ''
            ),
            addressCity: String(
              i.enderecoCidade && i.enderecoCidade.length > 0
                ? i.enderecoCidade
                : i.enderecoCidadeColeta && i.enderecoCidadeColeta.length > 0
                ? i.enderecoCidadeColeta
                : ''
            ),
            addressState:
              i.enderecoUf !== null
                ? String(
                    i.enderecoUf.length > 0 ? i.enderecoUf : i.enderecoUfColeta
                  )
                : '',
            addressNeighborhood: String(
              i.enderecoBairro && i.enderecoBairro.length > 0
                ? i.enderecoBairro
                : i.enderecoBairroColeta && i.enderecoBairroColeta.length > 0
                ? i.enderecoBairroColeta
                : ''
            ),
            addressNumber: String(
              i.enderecoNumero && i.enderecoNumero?.length > 0
                ? i.enderecoNumero
                : i.enderecoNumeroColeta && i.enderecoNumeroColeta.length > 0
                ? i.enderecoNumeroColeta
                : ''
            ),
            addressComplement: String(
              i.enderecoComplemento.length > 0
                ? i.enderecoComplemento
                : i.enderecoComplementoColeta
            ),
            enderecoLatitude: String(
              i.enderecoLatitude.length > 0
                ? i.enderecoLatitude
                : i.enderecoLatitudeColeta
            ),
            enderecoLongitude: String(
              i.enderecoLongitude.length > 0
                ? i.enderecoLongitude
                : i.enderecoLongitudeColeta
            ),
            cubage: Number(i.cubagem),
            weight: Number(i.peso),
            volume: Number(i.qtdVolumes),
            value: Number(i.valor),
            addressZipcode: String(
              i.enderecoCep.length > 0 ? i.enderecoCep : i.enderecoCepColeta
            ),
            referenceId: i.idReferencia,
            docNumber: i.numero,
            region: i.region,
            route: i.route,
            section: i.section,
            parentReferenceId: i.parentReferenceId,
            obs: i.obs !== null ? String(i.obs) : '',
            branchShipping:
              i.branchShipping !== null ? String(i.branchShipping) : '',
            branchShippingReferenceId:
              i.branchShippingReferenceId !== null
                ? String(i.branchShippingReferenceId)
                : '',
            reentrega: i.reentrega !== null ? i.reentrega : false,
          });
        });
        setRowData(items);
        setLoading(false);
        setGridRowData(rowData);
      }
    } catch (err) {}
  }, []);

  // Define o tamanho do modal da tela de atendimentos.
  const onResize = (data: any) => {
    setWidth(data.size.width);
    setHeight(data.size.height);
  };

  // useEffect(() => {
  //   onGridReady();
  // }, [onGridReady]);

  return (
    <Resizable
      width={width}
      height={height}
      onResize={(e: any, data: any) => onResize(data)}
      draggableOpts={{ grid: [20, 20] }}
    >
      <CustomDialog
        open={props.open}
        onClose={props.onClose}
        showHeaderIcon={false}
        title="Pesquisar atendimento"
        width={width}
        height={height}
      >
        <>
          <Loading loading={loading} />
          <FormFilter
            gridColumns={columns}
            gridRef={gridRef}
            gridData={gridRowData}
            rowData={rowData}
            onGridReady={(value: string) => onGridReady(value)}
            onSubmit={(rows: any[]) => {
              if (rows.length > 0) {
                props.onClose();

                const listCargoPre: any[] = [];
                const findCargoPreById = rowData.map((j: any) => {
                  rows.map(row => {
                    if (j.id == row.id) {
                      listCargoPre.push(j);
                    }
                  });
                });
                // console.log("Find", listCargoPre);
                props.onConfirm(listCargoPre);
              } else {
                FrontendNotification(
                  'Deve selecionar pelo menos um atendimento!',
                  NotificationType.WARNING
                );
              }
            }}
          />
        </>

      </CustomDialog>
    </Resizable>
  );
};

export default CreateAttendance;
