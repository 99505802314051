import styled from 'styled-components';

export const labelStyle = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  color: '#003049',
  opacity: 0.6,
};

export const inputCustom = {
  height: 35,
  background: '#FFFFFF',
  border: '2px solid rgba(0, 48, 73, 0.2)',
  boxSizing: 'border-box',
  borderRadius: 10,
  fontFamily: 'Lato',
  fontStyle: 'normal',
  //   fontWeight: 'bold',
  fontSize: 14,
  lineHeight: 19,
  //   color: '#003049',
};
