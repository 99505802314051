/* eslint-disable prefer-const */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import i18next from 'i18next';

import { FormControl } from '@material-ui/core';
import SelectParameters from 'components/SelectParameters';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from '../../core/common/Notification';
import api from '../../services/api';
import closeModalIcon from '../../assets/images/closeIcon.svg';

import formValidatorPT from './formValidatorPT';
import formValidatorEN from './formValidatorEN';
import ListTemplate from './ListTemplate';
import { WrapEditor, BoxForm, Footer, ButtonFooter } from './styles';
import MyEditor from './MyEditor';

export default function TemplateModal({ onClear, active }) {
  const [template, setTemplate] = useState([]);
  const [templateId, setTemplateId] = useState(0);
  const [modal, setModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectParamets, setSelectParamets] = useState(false);
  const [contentTemplate, setContentTemplate] = useState();
  const [isView, setIsView] = useState(false);
  const [visible, setVisible] = useState(false);

  const [removeId, setRemoveId] = useState('');
  const [tabs, setTabs] = useState(0);

  const ref = useRef();

  const paginate = pageNumber => setCurrentPage(pageNumber);

  const { t } = useTranslation();

  const getTemplateData = useCallback(async () => {
    try {
      const { data } = await api.get('/delivery/templates');

      setTemplate(data);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const deleteTemplate = useCallback(
    async idTemplate => {
      try {
        const response = await api.delete(`/delivery/templates/${idTemplate}`);

        if (response.status === 204) {
          FrontendNotification(
            t('template.messageRemoved'),
            NotificationType.SUCCESS
          );
        }

        setRemoveConfirmation(false);

        getTemplateData();
      } catch (err) {}
    },
    [getTemplateData, t, templateId]
  );

  const clearNav = () => {
    onClear();
    setModal(!modal);
  };

  const getLanguages = () => {
    return i18next.language;
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(
    async (values, reset, templateUpdate, tabSelected) => {
      try {
        const data = {
          nome: values.title,
          conteudo: values.content,
        };
        if (tabSelected === 2) {
          const response = await api.patch(
            `/delivery/templates/${templateUpdate}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('template.messageUpdated'),
              NotificationType.SUCCESS
            );
            reset({});

            setTabs(0);
          }
          setEdit(false);
          setUpdateId();
        } else {
          const response = await api.post(`/delivery/templates`, data);
          if (response.status === 201) {
            FrontendNotification(
              t('template.messageCreated'),
              NotificationType.SUCCESS
            );
            reset({});
            setTabs(0);
          }
        }

        getTemplateData();
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [active, getTemplateData, modal, onClear, t]
  );

  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema:
      getLanguages() === 'pt' || getLanguages() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, resetForm, updateId, tabs),
  });

  useEffect(() => {
    getTemplateData();
  }, [getTemplateData]);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuOpen]);

  const getTemplateInfo = useCallback(
    async id => {
      try {
        const { data } = await api.get(`/delivery/templates/${id}`);
        formik.setFieldValue('title', data.nome);
        formik.setFieldValue('content', data.conteudo);
        setContentTemplate(data.content);
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    [formik]
  );

  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  const setPublishedTemplate = useCallback(async idTemplate => {
    try {
      const response = await api.patch(
        `/delivery/templates/publica/${idTemplate}`
      );

      if (response.status === 201) {
        FrontendNotification(
          t('template.messagePublished'),
          NotificationType.SUCCESS
        );
      }

      getTemplateData();
    } catch {}
  }, []);

  const getBlockStyle = block => {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return null;
    }
  };

  return (
    <>
      {selectParamets && (
        <SelectParameters
          onClear={() => setSelectParamets(!selectParamets)}
          onSelectParameters={item => {
            return FrontendNotification(
              t('template.messageCopied'),
              NotificationType.SUCCESS
            );
          }}
        />
      )}
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteTemplate(templateId)} id='buttonSaveRemove'>
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={toggleRemove} id='buttonCancelRemove'>
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%', height: '100%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            {/* Nova Notificação */}
            <Trans i18nKey={tabs === 0 ? "template.title" : tabs === 1 ? 'template.newTemplate' : 'template.editTemplate'} />
          </p>
          <Tabs
            selectedIndex={tabs}
            onSelect={index => {
              setTabs(index);
            }}
          >
            <TabList>
              <Tab>
                <Trans i18nKey="tabs.list" />
              </Tab>
              <Tab>
                <Trans i18nKey="tabs.new" />
              </Tab>
              <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            </TabList>
            <TabPanel>
              <ListTemplate
                data={template}
                handleEdit={id => {
                  setTabs(2);
                  setEdit(true);
                  setIsView(false);

                  setUpdateId(id);
                  setTemplateId(id);
                  getTemplateInfo(id);
                }}
                handleView={id => {
                  setTabs(2);
                  setEdit(true);
                  setIsView(true);
                  setUpdateId(id);
                  setTemplateId(id);
                  getTemplateInfo(id);
                }}
                handleDelete={id => {
                  setTemplateId(id);
                  setRemoveConfirmation(true);
                }}
                handlePublished={id => setPublishedTemplate(id)}
              />
            </TabPanel>
            <TabPanel>
              <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
                <BoxForm>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="template.fields.title" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      type="text"
                      placeholder={t('template.placeholder.title')}
                      onChange={formik.handleChange('title')}
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.title}
                      </p>
                    )}
                  </FormControl>

                  <FormControl>
                    <WrapEditor>
                      <MyEditor
                        onChangeEditor={formik.handleChange('content')}
                        onParameters={() => setSelectParamets(!selectParamets)}
                        onVisibleEditor={(dataVisible) => setVisible(dataVisible)}
                      />
                    </WrapEditor>
                  </FormControl>
                  {formik.touched.content && formik.errors.content && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.content}
                    </p>
                  )}

                  <Footer>
                    <ButtonFooter onClick={formik.handleSubmit} type="button">
                      {tabs === 2 ? (
                        <Trans i18nKey="template.update" />
                      ) : (
                        <Trans i18nKey="template.new" />
                      )}
                    </ButtonFooter>
                  </Footer>
                </BoxForm>
              </div>
            </TabPanel>

            <TabPanel>
              <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
                <BoxForm>
                  <FormControl>
                    <Label
                      for="SelectState"
                      style={{
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 500,
                      }}
                    >
                      <Trans i18nKey="template.fields.title" />
                    </Label>

                    <Input
                      name="select"
                      id="SelectState"
                      type="text"
                      disabled={isView}
                      placeholder={t('template.placeholder.title')}
                      onChange={formik.handleChange('title')}
                      style={{
                        maxWidth: 450,
                        height: 60,
                        background: isView ? '#ccc' : '#FFFFFF',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        boxSizing: 'border-box',
                        borderRadius: 10,
                        fontFamily: 'Lato',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 14,
                        lineHeight: 19,
                        color: '#003049',
                        marginTop: 8,
                        paddingLeft: 10,
                      }}
                      value={formik.values.title}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <p style={{ fontSize: 14, color: 'red' }}>
                        {formik.errors.title}
                      </p>
                    )}
                  </FormControl>

                  <FormControl>
                    <WrapEditor>
                      <MyEditor
                        onChangeEditor={formik.handleChange('content')}
                        onParameters={() => setSelectParamets(!selectParamets)}
                        edit
                        editData={formik.values.content}
                        view={isView}
                      />
                    </WrapEditor>
                  </FormControl>
                  {formik.touched.content && formik.errors.content && (
                    <p style={{ fontSize: 14, color: 'red' }}>
                      {formik.errors.content}
                    </p>
                  )}

                  <Footer style={{ marginTop: visible === false ? '0px !important' : '30px !important'}}>
                    {!isView ? (
                      <ButtonFooter
                        onClick={formik.handleSubmit}
                        id='buttonSaveTemplate'
                        disabled={isView}
                        style={{ marginTop: visible === false ? 5 : 10}}
                      >
                        {tabs === 2 ? (
                          <Trans i18nKey="template.update" />
                        ) : (
                          <Trans i18nKey="template.new" />
                        )}
                      </ButtonFooter>
                    ) : null}
                  </Footer>
                </BoxForm>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
