import styled from 'styled-components';

export const HeaderContainer = styled.th`
  width: 'auto';
  /* width: 100%; */
  align-items: center;
  justify-content: center;
`;

export const TitleHeaderTower = styled.span`
  font-size: 0.715rem;
`;