/* eslint-disable operator-assignment */
import produce from 'immer';
import { RouteringCargasPayload } from './actions';

interface ActionReducer {
  type: string;
  payload: any;
}

const INITIAL_STATE: RouteringCargasPayload = {
  data: {
    dataOptmi: [],
    detail: null,
    dataRouteSelected: [],
    dataStepsSelected: []
  },
};


export default function routering(state = INITIAL_STATE, action: ActionReducer) {
  return produce(state, draft => {
    switch (action.type) {
      case '@routering/SET_DATA_ROUTERING_CARGAS': {
        draft.data.dataOptmi = action.payload.data;
        break;
      }
      case '@routering/UPDATE_DATA_ROUTERING_CARGAS': {
        draft.data.dataOptmi[action.payload.indexCarga].dataAgendamento = action.payload.data;
        break;
      }
      case '@routering/SET_DETAIL_ROUTERING_CARGA': {
        draft.data.detail = action.payload.detail;
        break;
      }
      case '@routering/CLEAR_DETAIL_ROUTERING_CARGA': {
        draft.data.detail = null;
        break;
      }
      case '@routering/UPDATE_ROUTERING_CARGA_CHECKED': {
        draft.data.dataOptmi[action.payload.indexCarga].active = !draft.data.dataOptmi[action.payload.indexCarga].active
        break;
      }
      case '@routering/UPDATE_ROUTERING_CARGA_SELECTED': {
        draft.data.dataOptmi[action.payload.indexCarga].active = action.payload.checked
        break;
      }
      case '@routering/SET_DATA_ROUTE_SELECTED': {
        draft.data.dataRouteSelected.push(action.payload.dataRoute);
        break;
      }

      // ------------------- Knowledge Actions ---------------------

      case '@routering/UPDATE_ROUTERING_STEPS_CHECKED': {
        if(draft.data.detail != undefined) {
          draft.data.detail.steps[action.payload.indexStep].active = !draft.data.detail.steps[action.payload.indexStep].active
        }
        break;
      }
      case '@routering/UPDATE_ROUTERING_STEPS_SELECTED': {
        if(draft.data.detail != undefined) {
          draft.data.detail.steps[action.payload.indexStep].active = action.payload.checked
        }
        break;
      }
      case '@routering/SET_DATA_STEPS_SELECTED': {
        draft.data.dataStepsSelected.push(action.payload.dataSteps);
        break;
      }

      case '@routering/REMOVE_DATA_STEP_SELECTED': {
        draft.data.dataStepsSelected.splice(action.payload.indexSelected, 1)
        break;
      }

      case '@routering/CLEAR_DATA_STEPS_SELECTED': {
        draft.data.dataStepsSelected = [];
        break;
      }

      case '@routering/UPDATE_ROUTERING_STEPS_COMPACTED': {
        if(draft.data.detail != undefined) {
          draft.data.detail.steps[action.payload.indexStep].isCompacted = !draft.data.detail.steps[action.payload.indexStep].isCompacted
        }
        break;
      }

      case '@routering/UPDATE_ALL_ROUTERING_STEPS_COMPACTED': {
        if(draft.data.detail != undefined) {
          draft.data.detail.steps = action.payload.data;
        }
        break;
      }

      // ------------------- ***** ---------------------------------


      case '@routering/SET_DATA_ROUTE': {
        draft.data.dataRouteSelected = action.payload.dataRoute;
        break;
      }
      case '@routering/UPDATE_DATA_ROUTE': {
        draft.data.dataRouteSelected[action.payload.indexOptmi].steps = action.payload.dataSteps;
        break;  
      }
      case '@routering/REMOVE_DATA_ROUTE_SELECTED': {
        draft.data.dataRouteSelected.splice(action.payload.indexSelected, 1)
        break;
      }
      case '@routering/CLEAR_DATA_ROUTE_SELECTED': {
        draft.data.dataRouteSelected = [];
        break;
      }
      default:
    }
  });
}
