/* eslint-disable no-unused-vars */
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import SearchFilter from 'components/SearchFilter';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import { Trans, withTranslation } from 'react-i18next';
import ReactInputMask from 'react-input-mask';
import closeModalIcon from '../../assets/images/closeIcon.svg';
import api from '../../services/api';
import { BackendErrorNotification } from '../Notification';
import Pagination from '../Pagination';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';
import { BoxForm, Content, Footer } from './styles';
import './styles.scss';

const ModalOperator = ({ t, onClear }) => {
  const [operator, setOperator] = useState([]);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();

  const [optionsFilter, setOptionsFilter] = useState('cnpj');

  const [valueSearch, setValueSearch] = useState('');

  const [modal, setModal] = useState(true);

  const [totalPages, setTotalPages] = useState(0);

  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');

  const customStyles = {
    content: {
      zIndex: 4,
    },
  };

  const leafletMap = useRef();

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const getOperatorLogistic = useCallback(async () => {
    try {
      const response = await api.get('/delivery/operadoresLogisticos');

      const orderOperator = response.data.sort((a, b) => b.id - a.id);

      setOperator(orderOperator);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const getCurrentPosition = useCallback(() => {
    const position = navigator.geolocation.getCurrentPosition(location => {
      setInitialPosition([location.coords.latitude, location.coords.longitude]);
    });
  }, []);

  function mascaraCnpj(valor) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  const handleSubmit = useCallback(
    async (values, operatorUpdate, resetForm, edit) => {
      try {
        if (validarCNPJ(values.cnpj)) {
          const data = {
            cnpj: values.cnpj,
            razaoSocial: values.razaoSocial,
            nomeFantasia: values.nomeFantasia,
          };

          const { language } = i18next;

          const erroLanguage =
            language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

          if (edit) {
            const response = await api.patch(
              `/delivery/operadoresLogisticos/${operatorUpdate}?lang=${erroLanguage}`,
              data
            );
            if (response.status) {
              FrontendNotification(
                t('operatorLogistic.message.updatedOperatorLogistic'),
                NotificationType.SUCCESS
              );
              setEdit(false);
              setUpdateId(null);
            }
          } else {
            const response = await api.post(
              `/delivery/operadoresLogisticos?lang=${erroLanguage}`,
              data
            );
            if (response.status) {
              FrontendNotification(
                t('operatorLogistic.message.createdOperatorLogistic'),
                NotificationType.SUCCESS
              );
            }
          }

          resetForm({});

          getOperatorLogistic();
        } else {
          FrontendNotification('CNPJ Inválido!', NotificationType.ERROR);
        }
      } catch (err) {
        BackendErrorNotification(err);
      }
    },
    []
  );

  const deleteDriver = useCallback(async driverId => {
    try {
      // setRemoveConfirmation(true);
      const { language } = i18next;

      const erroLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
      const response = await api.delete(
        `/delivery/operadoresLogisticos/${driverId}?lang=${erroLanguage}`
      );
      if (response.status === 204) {
        FrontendNotification(
          t('operatorLogistic.message.removeOperatorLogistic'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('operatorLogistic.message.erroDeleteOperatorLogistic'),
          NotificationType.ERROR
        );
      }
      setRemoveConfirmation(false);
      getOperatorLogistic();
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  // const handleClickDelete = useCallback(async operatorId => {
  //   try {

  //     const response = await api.delete(
  //       `delivery/operadoresLogisticos/${operatorId}`
  //     );

  //     FrontendNotification(
  //       'Operador Logístico removido com sucesso!',
  //       NotificationType.SUCCESS
  //     );

  //     getOperatorLogistic();
  //   } catch (err) {
  //     BackendErrorNotification(err);
  //   }
  // }, []);

  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const filtersData = useMemo(() => {
    let computedPages = operator;

    if (optionsFilter === 'cnpj') {
      computedPages = computedPages.filter(
        op =>
          op.cnpj !== '' &&
          op.cnpj.toLowerCase().includes(valueSearch.toLowerCase())
      );
    } else if (optionsFilter === 'nomeFantasia') {
      computedPages = computedPages.filter(
        op =>
          op.nomeFantasia !== '' &&
          op.nomeFantasia.toLowerCase().includes(valueSearch.toLowerCase())
      );
    } else if (optionsFilter === 'razaoSocial') {
      computedPages = computedPages.filter(
        op =>
          op.razaoSocial !== '' &&
          op.razaoSocial.toLowerCase().includes(valueSearch.toLowerCase())
      );
    }

    setTotalPages(computedPages.length);

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [operator, currentPage, valueSearch]);

  const getLanguage = () => {
    return i18next.language;
  };

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      razaoSocial: '',
      nomeFantasia: '',
    },
    validationSchema:
      navigator.language === 'pt' || navigator.language === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm }) =>
      handleSubmit(values, updateId, resetForm, edit),
  });

  const updateOperator = useCallback(async update => {
    try {
      const data = {
        cnpj: formik.values.cnpj,
        razaoSocial: formik.values.razaoSocial,
        nomeFantasia: formik.values.nomeFantasia,
      };

      const response = await api.patch(
        `/delivery/operadoresLogisticos/${update}`,
        data
      );

      getOperatorLogistic();
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  useEffect(() => {
    getOperatorLogistic();

    getCurrentPosition();
  }, [getOperatorLogistic, getCurrentPosition]);

  const indexOfLastPage = currentPage * perPage;
  const indexOfFirstPage = indexOfLastPage - perPage;
  const currentData = operator.slice(indexOfFirstPage, indexOfLastPage);
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="xl"
        isOpen={modal}
        toggle={clearNav}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '95%' }}
      >
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <SearchFilter
            isCNPJ
            isCompanyName
            isTrademarkName
            valueSearch={valueSearch}
            optionValue={optionsFilter}
            handleChange={e => setValueSearch(e.target.value)}
            handleSelect={e => setOptionsFilter(e.target.value)}
          />{' '}
          <p className="title">
            <Trans i18nKey="operatorLogistic.title" />
          </p>
          <div className="d-flex" style={{ flexWrap: 'nowrap' }}>
            <BoxForm>
              <Label
                for="SelectState"
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Trans i18nKey="operatorLogistic.fields.cnpj" />

                <ReactInputMask
                  className="InputShadow"
                  name="select"
                  id="SelectState"
                  placeholder="CNPJ"
                  onChange={formik.handleChange('cnpj')}
                  mask="99.999.999/9999-99"
                  disabled={!!edit}
                  style={{
                    height: 60,
                    background: edit ? '#ccc' : '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                    paddingLeft: 10,
                  }}
                  value={formik.values.cnpj}
                >
                  {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                    {item.nome}
                    </option>
                  ))} */}
                </ReactInputMask>
              </Label>

              {formik.errors.cnpj && formik.touched.cnpj && (
                <p style={{ fontSize: 14, color: 'red' }}>
                  {formik.errors.cnpj}
                </p>
              )}
              <Label
                for="SelectState"
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                <Trans i18nKey="operatorLogistic.fields.razaoSocial" />

                <Input
                  placeholder={t('operatorLogistic.fields.razaoSocial')}
                  name="select"
                  id="SelectState"
                  onChange={formik.handleChange('razaoSocial')}
                  style={{
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                  value={formik.values.razaoSocial}
                >
                  {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                      {item.nome}
                    </option>
                  ))} */}
                </Input>
                {formik.errors.razaoSocial && formik.touched.razaoSocial && (
                  <p style={{ fontSize: 14, color: 'red' }}>
                    {formik.errors.razaoSocial}
                  </p>
                )}
              </Label>
              <Label
                for="SelectState"
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 500,
                }}
              >
                <Trans i18nKey="operatorLogistic.fields.nomeFantasia" />

                <Input
                  name="select"
                  id="SelectState"
                  placeholder={t('operatorLogistic.fields.nomeFantasia')}
                  onChange={formik.handleChange('nomeFantasia')}
                  style={{
                    height: 60,
                    background: '#FFFFFF',
                    border: '2px solid rgba(0, 48, 73, 0.2)',
                    boxSizing: 'border-box',
                    borderRadius: 10,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 14,
                    lineHeight: 19,
                    color: '#003049',
                    marginTop: 8,
                  }}
                  value={formik.values.nomeFantasia}
                >
                  {/* {status.map(item => (
                    <option key={item.id} value={item.nome}>
                      {item.nome}
                    </option>
                  ))} */}
                </Input>
                {formik.errors.nomeFantasia && formik.touched.nomeFantasia && (
                  <p style={{ fontSize: 14, color: 'red' }}>
                    {formik.errors.nomeFantasia}
                  </p>
                )}
              </Label>

              <Footer>
                <Row>
                  {edit ? (
                    <>
                      <button
                        onClick={() => {
                          formik.resetForm({});
                          setEdit(false);
                          setUpdateId();
                        }}
                        id="buttonCancelOperator"
                        className="button"
                        type="button"
                        style={{
                          width: '40%',
                          background: '#ccc',
                          color: '#000',
                        }}
                      >
                        <Trans i18nKey="common.cancel" />
                      </button>
                      <Col>
                        <button
                          onClick={formik.handleSubmit}
                          className="button"
                          id="buttonUpdateOperator"
                          type="button"
                        >
                          <Trans i18nKey="operatorLogistic.update" />
                        </button>
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <button
                        onClick={formik.handleSubmit}
                        className="button"
                        id="buttonSaveOperator"
                        type="button"
                        style={{ width: '100%' }}
                      >
                        <Trans i18nKey="operatorLogistic.title" />
                      </button>
                    </Col>
                  )}
                </Row>
              </Footer>
            </BoxForm>

            <Content>
              <div>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>
                        <Trans i18nKey="operatorLogistic.fields.cnpj" />
                      </th>
                      <th>
                        <Trans i18nKey="operatorLogistic.fields.razaoSocial" />
                      </th>
                      <th style={{ width: '20%' }}>
                        <Trans i18nKey="operatorLogistic.fields.nomeFantasia" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtersData.map((item, index) => (
                      <tr className="lineGroup">
                        <td>
                          <p>{mascaraCnpj(item.cnpj)}</p>
                        </td>
                        <td>
                          <p>{item.razaoSocial}</p>
                        </td>
                        <td>
                          <p>{item.nomeFantasia}</p>
                        </td>
                        <td>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              formik.setFieldValue('cnpj', item.cnpj);
                              formik.setFieldValue(
                                'razaoSocial',
                                item.razaoSocial
                              );
                              formik.setFieldValue(
                                'nomeFantasia',
                                item.nomeFantasia
                              );

                              setEdit(true);
                              setUpdateId(item.id);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                            />
                          </Button>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setRemoveId(item.id);
                              setRemoveConfirmation(!removeConfirmation);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  perPage={perPage}
                  total={totalPages}
                  onPaginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            </Content>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default withTranslation()(ModalOperator);
