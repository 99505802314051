import React from 'react';
import BackgroundForm from '../assets/images/backgroundForm.png';
import Logo from '../assets/images/logo.png';
import VectorForm from '../assets/images/vector.png';
import Loading from '../core/common/Loading';

export default function BaseTemplate({ children, loading, text }) {
  const [template, setTemplate] = React.useState();
  return (
    <div className="container-form">
      <div className="container-fluid h-100">
        <div className="figureText">
          <img src={Logo} alt="" className="logo-app" />
          <span
            style={{
              fontFamily: 'Lato',
              fontWeight: 300,
              fontSize: 14,
              color: '#fff',
            }}
          >
            v{process.env.REACT_APP_VERSION}
          </span>
        </div>

        <span className="welcome-message ">{text}</span>
        <div className="image-container" />
        <div className="image-container h-100 w-100">
          <div className="image-form h-100 w-100">
            <img src={BackgroundForm} alt="" />
          </div>
          <div className="vector-form h-100 w-100">
            <img src={VectorForm} alt="" />
          </div>
        </div>
        <div
          className="row justify-content-center align-items-center h-100"
          style={{ padding: 20, marginBottom: 150 }}
        >
          {children}
        </div>

        <Loading loading={loading} />
      </div>
    </div>
  );
}
