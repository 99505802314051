import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1.25rem;
  margin-right: 1rem;
  width: 13%;
`;

export const Title = styled.h1`
  font-family: 'Lato';
  font-weight: bold;
  color: #003049;
  font-size: 0.938rem;
`;