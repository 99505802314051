export function getData(data) {
  return {
    type: '@csvMapping/GET_DATA_CSV',
    payload: { data },
  };
}

export function setData(data) {
  return {
    type: '@csvMapping/SET_DATA_CSV',
    payload: { data },
  };
}

export function setDataAddress(dataAddress) {
  return {
    type: '@csvMapping/SET_DATA_ADDRESS',
    payload: { dataAddress },
  };
}
