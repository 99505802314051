import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { format, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import i18n from 'i18n';
import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from 'reactstrap';
import api from 'services/api';
import dangerIcon from '../../assets/images/dangerIcon.png';
import informationIcon from '../../assets/images/informationIcon.png';
import warningIcon from '../../assets/images/warningIcon.png';
import Filters from './CustomFilters';
import CustomSelectNotices from './CustomSelect';
import formValidatorEN from './formValidator-EN';
import formValidatorPT from './formValidator-PT';
import './style.scss';
import { BoxForm, Content, Footer, IconColumn } from './styles';

// registerLocale('pt-BR', ptBR);

interface Props {
  onClear?: any;
}

interface FormValues {
  tipo: string;
  descricao: string;
  dataInicial: string;
  dataFinal: string;
}

interface FiltersData {
  field: string;
  value: any;
  operation: string;
}

interface NoticesData {
  id: number;
  descricao: string;
  tipo: string;
  dataInicial: string;
  dataFinal: string;
}

const Notices: React.FC<Props> = ({ onClear }: Props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState<boolean>(true);
  const [tipo, setTipo] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorDateInitial, setErrorDateInitial] = useState<string>('');
  const [errorDateFinal, setErrorDateFinal] = useState<string>('');
  const [edit, setEdit] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number | null>(null);
  const [notices, setNotices] = useState<NoticesData[]>([]);
  const [removeId, setRemoveId] = useState<number | null>(null);
  const [removeConfirmation, setRemoveConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [errors, setErrors] = useState({
    tipo: false,
    descricao: false,
  });
  const [optionsFilter, setOptionsFilter] = useState<string>('descricao');
  const [valueSearch, setValueSearch] = useState<string>('');

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  function reformatDate(dateStr: string) {
    let dArr = dateStr.split('-'); // ex input "2010-01-18"
    return `${dArr[2]}-${dArr[1]}-${dArr[0]}`; // ex out: "18/01/10"
  }

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      text: string,
      type: string,
      isUpdate: any,
      reset: any,
      fieldValue: any
    ) => {
      const date = new Date();

      let dateInitialCompare = new Date(values.dataInicial);
      let dateFinal = new Date(values.dataFinal);

      const data = {
        descricao: text,
        dataInicial: format(dateInitialCompare, 'yyyy-MM-dd'),
        dataFinal: format(dateFinal, 'yyyy-MM-dd'),
        tipo: type,
      };

      if (text === '') {
        setErrors({
          ...errors,
          descricao: !errors.descricao,
        });
      }

      dateInitialCompare.setDate(
        isUpdate
          ? dateInitialCompare.getDate()
          : dateInitialCompare.getDate()
      );
      dateFinal.setDate(
        isUpdate ? dateFinal.getDate() : dateFinal.getDate()
      );

      console.log("dateInitial", date);
      console.log("dateInitial2", dateInitialCompare);

      if (isBefore(dateInitialCompare.getDate(), date.getDate())) {
        setErrorDateInitial(
          '* Data inicial não pode ser menor que a data atual!'
        );
      } else if (dateFinal < dateInitialCompare) {
        setErrorDateInitial('');
        setErrorDateFinal(
          '* Data final não pode ser menor que a data inicial!'
        );
      } else if (
        dateInitialCompare.getDate() >= date.getDate() &&
        dateFinal.getDate() >= dateInitialCompare.getDate()
      ) {
        setErrorDateInitial('');
        setErrorDateFinal('');

        if (isUpdate !== null) {
          const response = await api.patch(
            `/delivery/avisos/${isUpdate}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('notices.messageUpdated'),
              NotificationType.SUCCESS
            );
          } else {
            FrontendNotification(
              t('notices.messageErroUpdated'),
              NotificationType.ERROR
            );
          }

          fieldValue('tipo', '');
          reset({});
          setDescription('');
          fieldValue('tipo', '');
          setStartDate(null);
          setEndDate(null);
          setEdit(false);
          setUpdateId(null);
        } else {
          const response = await api.post('/delivery/avisos', data);

          if (response.status) {
            FrontendNotification(
              t('notices.messageCreated'),
              NotificationType.SUCCESS
            );
            reset({});
            setDescription('');
            fieldValue('tipo', '');
            setStartDate(null);
            setEndDate(null);
          } else {
            FrontendNotification(
              t('notices.messageErroCreated'),
              NotificationType.ERROR
            );
          }
          fieldValue('tipo', '');

          reset({});
          setDescription('');
          fieldValue('tipo', '');
          setStartDate(null);
          setEndDate(null);
          setEdit(false);
          setUpdateId(null);
        }
      }

      getNotices();
    },
    []
  );

  const getNotices = useCallback(async () => {
    setLoading(true);

    const data = {
      filters: filters.length > 0 ? filters : [],
      page: currentPage,
      size: 10,
      orders: [
        {
          field: 'id',
          direction: 'DESC',
        },
      ],
    };

    const response = await api.post('/delivery/avisos/pageable', data);

    setTotalPages(response.data.totalPages);
    setNotices(response.data.content);

    setLoading(false);
  }, [currentPage, filters]);

  const deleteDriver = useCallback(async (driverId: number | null) => {
    try {
      // setRemoveConfirmation(true);
      const response = await api.delete(`/delivery/avisos/${driverId}`);
      FrontendNotification(
        t('notices.messageRemove'),
        NotificationType.SUCCESS
      );
      setRemoveConfirmation(false);
      getNotices();
    } catch (err) {}
  }, []);

  const getLanguage = () => {
    return i18n.language;
  };

  const initialValues: FormValues = {
    tipo: '',
    descricao: '',
    dataInicial: '',
    dataFinal: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      getLanguage() === 'pt' || getLanguage() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values, { resetForm, setFieldValue }) =>
      handleSubmit(
        values,
        description,
        tipo,
        updateId,
        resetForm,
        setFieldValue
      ),
  });

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber - 1);

  useEffect(() => {
    getNotices();
  }, [getNotices]);

  return (
    <>
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Loading loading={loading} />

      <CustomModal
        isOpen={modal}
        isClose={() => onClear()}
        label={t('notices.titleField')}
        styles={{ maxWidth: '95%', maxHeight: '70%' }}
      >
        <Filters
          options={optionsFilter}
          searchText={valueSearch}
          onChangeText={(text: string) => setValueSearch(text)}
          onChangeOptions={(option: string) => setOptionsFilter(option)}
          onChangeOptionText={(optionText: string) =>
            setValueSearch(optionText)
          }
          onFilters={(data: any) => setFilters(data)}
        />
        <div className="d-flex contentNotice">
          <BoxForm>
            <Label>
              <Trans i18nKey="notices.fields.type" />
              <CustomSelectNotices
                valueNoticesType={formik.values.tipo}
                onChange={(value: string, valueType: string) => {
                  formik.setFieldValue('tipo', valueType);
                  setTipo(value);
                }}
              />

              {formik.touched.tipo && formik.errors.tipo && (
                <p className="errorText">{formik.errors.tipo}</p>
              )}
            </Label>
            <Label>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <div className="countCharacters">
                  <Form.Label>
                    <Trans i18nKey="notices.fields.description" />
                  </Form.Label>
                  <div className="count">
                    <p>({count}/100)</p>
                  </div>
                </div>
                <Form.Control
                  as="textarea"
                  rows={6}
                  maxLength={100}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue('descricao', event.target.value);
                    setDescription(event.target.value);
                    if (
                      event.target.value.length > 0 &&
                      event.target.value.length <= 100
                    ) {
                      setCount(count + 1);
                    } else if (event.target.value.length >= 100) {
                      setCount(count);
                    } else {
                      setCount(0);
                    }
                  }}
                  value={description}
                />
              </Form.Group>
              {formik.touched.descricao && formik.errors.descricao && (
                <p className="errorText">{formik.errors.descricao}</p>
              )}
            </Label>
            <Row>
              <Col md={6}>
                <Label className="columnDate">
                  <Trans i18nKey="notices.fields.initialDate" />

                  <DatePicker
                    className="formDate"
                    selected={startDate}
                    id="initialDate"
                    onChange={(date: Date) => {
                      let dateInitialDate = new Date(date);

                      setStartDate(date);
                      formik.setFieldValue('dataInicial', dateInitialDate);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                  />
                </Label>

                {formik.touched.dataInicial && formik.errors.dataInicial && (
                  <p className="errorText">{formik.errors.dataInicial}</p>
                )}
              </Col>
              <p className="errorText">{errorDateInitial}</p>
              <Col md={6}>
                <Label className="columnDate">
                  <Trans i18nKey="notices.fields.finalDate" />
                  <DatePicker
                    className="formDate"
                    selected={endDate}
                    id="finalDate"
                    onChange={(date: Date) => {
                      setEndDate(date);
                      formik.setFieldValue('dataFinal', date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                  />
                </Label>

                {formik.touched.dataFinal && formik.errors.dataFinal && (
                  <p className="errorText">{formik.errors.dataFinal}</p>
                )}
                <p className="errorText">{errorDateFinal}</p>
              </Col>
            </Row>
            <Footer>
              {edit ? (
                <>
                  <Row>
                    <Col md={6}>
                      <button
                        onClick={() => {
                          formik.resetForm({});
                          setEdit(false);

                          setUpdateId(null);
                          setDescription('');
                          setStartDate(null);
                          setEndDate(null);
                        }}
                        className="button buttonCancel"
                        type="button"
                      >
                        <Trans i18nKey="common.cancel" />
                      </button>
                    </Col>

                    <Col style={{ marginTop: 0 }}>
                      <button
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          formik.handleSubmit()
                        }
                        id="buttonUpdate"
                        className="button"
                        type="button"
                      >
                        <Trans i18nKey="notices.update" />
                      </button>
                    </Col>
                  </Row>
                </>
              ) : (
                <button
                  onClick={() => formik.handleSubmit()}
                  className="button buttonSave"
                  type="button"
                >
                  <span className="buttonSaveText">
                    <Trans i18nKey="notices.save" />
                  </span>
                </button>
              )}
            </Footer>
          </BoxForm>
          <Content>
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>
                      <Trans i18nKey="notices.fields.description" />
                    </th>
                    <th>
                      <Trans i18nKey="notices.fields.type" />
                    </th>
                    <th>
                      <Trans i18nKey="notices.fields.initialDate" />
                    </th>
                    <th>
                      <Trans i18nKey="notices.fields.finalDate" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notices.map((item: NoticesData, index: number) => (
                    <tr className="lineGroup">
                      <td className='uriLimit'>{item.descricao}</td>

                      <td>
                        <div
                          style={{
                            display: 'flex',
                            marginRight: 20,
                          }}
                        >
                          {item.tipo === 'PERIGO' ? (
                            <img
                              style={{ width: 24, height: 24 }}
                              src={dangerIcon}
                              alt=""
                            />
                          ) : item.tipo === 'ATENCAO' ? (
                            <img
                              style={{ width: 24, height: 24 }}
                              src={warningIcon}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ width: 24, height: 24 }}
                              src={informationIcon}
                              alt=""
                            />
                          )}
                          <p
                            style={{
                              marginBottom: 0,
                              marginLeft: 10,
                              fontWeight: 600,
                              fontFamily: 'Lato',
                              color:
                                item.tipo === 'PERIGO'
                                  ? '#EA004C'
                                  : item.tipo === 'ATENCAO'
                                  ? '#EA7000'
                                  : '#006CEA',
                            }}
                          >
                            {item.tipo === 'PERIGO'
                              ? 'Perigo'
                              : item.tipo === 'ATENCAO'
                              ? 'Atenção'
                              : 'Informação'}
                          </p>
                        </div>
                      </td>
                      <td>{reformatDate(item.dataInicial)}</td>
                      <td>{reformatDate(item.dataFinal)}</td>
                      <IconColumn>
                        <div style={{ display: 'flex' }}>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setDescription(item.descricao);

                              formik.setFieldValue('descricao', item.descricao);
                              if (item.tipo === 'PERIGO') {
                                setTipo('PERIGO');
                                formik.setFieldValue('tipo', 'P');
                              } else if (item.tipo === 'INFORMACAO') {
                                setTipo('INFORMACAO');
                                formik.setFieldValue('tipo', 'I');
                              } else if (item.tipo === 'ATENCAO') {
                                setTipo('ATENCAO');
                                formik.setFieldValue('tipo', 'W');
                              }

                              let dateInitialDate = new Date(item.dataInicial);
                              let dateFinal = new Date(item.dataFinal);

                              dateInitialDate.setDate(
                                dateInitialDate.getDate() + 1
                              );
                              dateFinal.setDate(dateFinal.getDate() + 1);

                              formik.setFieldValue(
                                'dataInicial',
                                dateInitialDate
                              );
                              formik.setFieldValue('dataFinal', dateFinal);

                              setStartDate(dateInitialDate);
                              setEndDate(dateFinal);
                              setEdit(true);
                              setUpdateId(item.id);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                            />
                          </Button>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              setRemoveId(item.id);
                              setRemoveConfirmation(!removeConfirmation);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                            />
                          </Button>
                        </div>
                      </IconColumn>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="containerPagination">
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={(e: any, page: number) => paginate(page)}
                />
              </div>
            </div>
          </Content>
        </div>
      </CustomModal>
    </>
  );
};

export default Notices;
