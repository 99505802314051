import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import EditAlert from './EditAlert';
import NewAlert from './NewAlert';
import { Alert } from './interfaces/types';

interface Props {
  onClear: () => void;
}

const ReasonsAlert: React.FC<Props> = (props: Props) => {
  const [tabs, setTabs] = useState<number>(0);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'descricao',
      headerName: 'Justificativa',
      filter: true,
      checkboxSelection: true,
    },
    {
      field: 'tipo',
      headerName: 'Alertas',
      filter: true,
      width: 400,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<Alert[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleDelete = useCallback(async (selectedRows: Alert[]) => {
    try {
      setLoading(false);

      const response = await api.delete(
        `/delivery/motivos/alertas/${selectedRows[0].id}`
      );

      setLoading(true);

      FrontendNotification(t('reasonsAlert.messageRemoveSuccess'), NotificationType.SUCCESS);

      setIsRemove(false);

      setLoading(false);

      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  const onCreate = () => {
    setTabs(1);
  };

  const onUpdate = (selectedRows: Alert[]) => {
    if (selectedRows.length > 0) {
      setTabs(2);
    }
  };

  const onDelete = (selectedRows: Alert[]) => {
    if (selectedRows.length > 0) {
      setIsRemove(!isRemove);
    }
  };

  return (
    <CustomModal
      label={t('reasonsAlert.title')}
      isClose={props.onClear}
      isOpen
      styles={{ minWidth: tabs < 1 ? '70%' : '600px' }}
    >
      <Loading loading={loading} />
      
      <ModalDelete
        isOpen={isRemove}
        onConfirm={() => handleDelete(selectedRows)}
        onClose={() => setIsRemove(!isRemove)}
      />
      <Tabs
        selectedIndex={tabs}
        onSelect={(index: number) => {
          setTabs(index);
        }}
      >
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
        </TabList>
        <TabPanel>
          <div className="tw-flex tw-w-full tw-h-full tw-mt-6">
            <div className="tw-flex tw-flex-col tw-w-full tw-h-96">
              <Grid<Alert>
                columns={columns}
                filters={[]}
                orders={[]}
                pagination={true}
                path="/delivery/motivos/alertas/pageable"
                showCrudButtons
                rowSelection={RowSelection.SINGLE}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onCreate={() => onCreate()}
                onUpdate={() => onUpdate(selectedRows)}
                onDelete={() => onDelete(selectedRows)}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <NewAlert onConfirm={() => setTabs(0)} />
        </TabPanel>
        <TabPanel>
          <EditAlert selectedRow={selectedRows} onConfirm={() => setTabs(0)} />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default ReasonsAlert;
