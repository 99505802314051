import Pagination from '@material-ui/lab/Pagination';
import CustomModal from 'components/ModalCustom';
import Vehicles from 'components/Vehicles';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import Totalizator from 'pages/UploadCSV/CorrectCSV/Totalizator';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import permissionMenu from 'shared/Permission';
import addDriver from '../../assets/images/addDriver.png';
import deleteRegisterVehicle from '../../assets/images/deleteRegisterVehicle.svg';
import {
  addAccumulatorRoutering,
  getPorcentageAccumulator,
  removeAccumulatorRoutering,
} from '../../store/modules/accumulator/actions';
import {
  clearVehiclesSelected,
  removeSelectedVehicles,
  setDateForecast,
  setSelectedVehicles,
  updateSelectedVehicles,
  updateVehiclesForecast,
  updateVehiclesRoadTrip,
} from '../../store/modules/vehicles/actions';
import CheckBox from './CheckBox';
import Filters from './CustomFilters';
import './styles.scss';

interface Props {
  dataCargoValue: any;
  onClear?: any;
  onVehiclesSelected?: any;
  filialId?: number;
  isUnique?: boolean;
  defaultVehicles?: any;
}

interface FiltersData {
  field?: string;
  value?: any;
  operation?: string;
}

interface VehiclesData {
  placa: string;
  tipo: string;
  id: number;
  idReferencia: number;
  motorista: any;
  quantidadeAtendimento: number;
  peso: number;
  cubagem: number;
  valor: number;
  velocidadeMedia: number;
  disponivel?: boolean;
  roundtrip?: boolean;
  previsaoInicio?: string;
}

const SelectVehicles: React.FC<Props> = ({
  dataCargoValue,
  onClear,
  onVehiclesSelected,
  defaultVehicles,
  filialId,
  isUnique,
}: Props) => {
  const [vehicles, setVehicles] = useState<VehiclesData[]>([]);
  const [filters, setFilters] = useState<FiltersData[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const selectedVehicles = useSelector(
    (state: any) => state.vehicles.selectedVehicles
  );
  const dateForecast = useSelector((state: any) => state.vehicles.dateForecast);
  const [selectedVehiclesArr, setSelectedVehiclesArr] = useState(
    selectedVehicles
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [optionsFilter, setOptionsFilter] = useState<string>('placa');
  const [valueSearch, setValueSearch] = useState<string>('');
  const [modalVehicles, setModalVehicles] = useState<boolean>(false);
  const [parameters, setParameters] = useState<any[]>([]);
  const { t } = useTranslation();
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber - 1);
  const dispatch = useDispatch();

  const userData = useSelector((state: any) => state.auth.user);


  const getVehicles = useCallback(async () => {
    try {
      setLoading(true);
      const currentFilters = [
        {
          field: 'filial.id',
          value: sessionStorage.getItem('subsidiary'),
          operation: 'EQUAL',
        },
        {
          field: 'motorista.status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ];

      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [],
      };

      const response = await api.post('/delivery/veiculos/pageable', data);

      const mappingVehiclesData = response.data.content.map(
        (itemResponse: VehiclesData) => {
          return {
            ...itemResponse,
            active: false,
          };
        }
      );

      let compareVehicles: VehiclesData[] = mappingVehiclesData;

      if (isUnique) {
        compareVehicles = mappingVehiclesData.filter(
          (vehicle: VehiclesData) =>
            !defaultVehicles.some(
              (selectedVehicles: VehiclesData) =>
                selectedVehicles.id === vehicle.id
            )
        );
        console.log('Compare', compareVehicles);
      }

      if (selectedVehicles.length > 0) {
        compareVehicles = compareVehicles.filter(
          (vehicle: VehiclesData) =>
            !selectedVehicles.some(
              (selectedVehicle: VehiclesData) =>
                selectedVehicle.id === vehicle.id
            )
        );
      }

      setTotalPages(response.data.totalPages);
      setVehicles(compareVehicles);

      setLoading(false);
    } catch {}
  }, [currentPage, filters]);

  const onSelectVehicles = (
    dataVehicle: VehiclesData[],
    indexVehicle: number,
    operationVehicles: string
  ) => {
    if (operationVehicles === 'removeField') {
      dispatch(removeSelectedVehicles(indexVehicle));

      const dateFormat = format(new Date(), 'yyyy-MM-dd');
      let hoursFormat;
      if (dataVehicle[indexVehicle].motorista.turno !== null) {
        const hoursFormat =
          dataVehicle[indexVehicle].motorista.turno.horarioInicial;
      }

      const data: any = {
        active: true,
        placa: dataVehicle[indexVehicle].placa,
        tipo: dataVehicle[indexVehicle].tipo,
        id: dataVehicle[indexVehicle].id,
        idReferencia: dataVehicle[indexVehicle].idReferencia,
        motorista: dataVehicle[indexVehicle].motorista,
        quantidadeAtendimento: dataVehicle[indexVehicle].quantidadeAtendimento,
        peso: dataVehicle[indexVehicle].peso,
        cubagem: dataVehicle[indexVehicle].cubagem,
        valor: dataVehicle[indexVehicle].valor,
        previsaoInicio:
          dataVehicle[indexVehicle].motorista.turno !== null
            ? `${dateFormat}T${hoursFormat}`
            : '',
        roundtrip: true,
        disponivel: dataVehicle[indexVehicle].disponivel,
      };

      setVehicles(prevState => [...prevState, data]);

      /* Add Calc Accumulator */

      dispatch(
        removeAccumulatorRoutering(
          dataVehicle[indexVehicle].quantidadeAtendimento,
          dataVehicle[indexVehicle].peso,
          dataVehicle[indexVehicle].cubagem,
          dataVehicle[indexVehicle].valor
        )
      );

      /* Add Calc Porcentage Accumulator */
      if (
        dataVehicle[indexVehicle].quantidadeAtendimento !== 0 ||
        dataVehicle[indexVehicle].peso !== 0 ||
        dataVehicle[indexVehicle].cubagem !== 0 ||
        dataVehicle[indexVehicle].valor !== 0
      ) {
        dispatch(getPorcentageAccumulator());
      }
    } else {
      const dateFormat = format(new Date(), 'yyyy-MM-dd');
      let hoursFormat;
      if (dataVehicle[indexVehicle].motorista.turno !== null) {
        hoursFormat = dataVehicle[indexVehicle].motorista.turno.horarioInicial;
      }
      const data = {
        active: true,
        placa: dataVehicle[indexVehicle].placa,
        tipo: dataVehicle[indexVehicle].tipo,
        id: dataVehicle[indexVehicle].id,
        idReferencia: dataVehicle[indexVehicle].idReferencia,
        motorista: dataVehicle[indexVehicle].motorista,
        quantidadeAtendimento: dataVehicle[indexVehicle].quantidadeAtendimento,
        peso: dataVehicle[indexVehicle].peso,
        cubagem: dataVehicle[indexVehicle].cubagem,
        valor: dataVehicle[indexVehicle].valor,
        previsaoInicio:
          dataVehicle[indexVehicle].motorista.turno !== null
            ? `${dateFormat}T${hoursFormat}`
            : '',
        roundtrip: true,
        disponivel: dataVehicle[indexVehicle].disponivel,
      };
      dispatch(setSelectedVehicles(data));

      const filterSelectVehicles = dataVehicle.filter(
        (item, indexSelect) => indexSelect !== indexVehicle
      );

      setVehicles(filterSelectVehicles);

      dispatch(
        addAccumulatorRoutering(
          dataVehicle[indexVehicle].quantidadeAtendimento,
          dataVehicle[indexVehicle].peso,
          dataVehicle[indexVehicle].cubagem,
          dataVehicle[indexVehicle].valor
        )
      );

      if (
        dataVehicle[indexVehicle].quantidadeAtendimento !== 0 ||
        dataVehicle[indexVehicle].peso !== 0 ||
        dataVehicle[indexVehicle].cubagem !== 0 ||
        dataVehicle[indexVehicle].valor !== 0
      ) {
        dispatch(getPorcentageAccumulator());
      }
    }
  };

  const getParameters = useCallback(async () => {
    try {
      const data = {
        filters: [],
      };
      const response = await api.post(
        '/cadastro/parametrosWeb/findByFilters',
        data
      );

      setParameters(response.data);
    } catch (err) {}
  }, []);

  const handleSubmit = useCallback(
    (dataParmeters: any[], arrSelectVehicles) => {

      const getSelectedVehicles: any[] = arrSelectVehicles.filter((item: any) => item.previsaoInicio.length === 0);

      const findAvaliableVehicles = arrSelectVehicles.find((vehiclesItem: any) => vehiclesItem.disponivel === false);

      console.log("vehicles", arrSelectVehicles);

      if (dataParmeters[0].roteirizacaoDtPrevisaoObrigatoria === true && getSelectedVehicles.length > 0) {
        FrontendNotification(
          t('selectVehicles.messageRequired'),
          NotificationType.WARNING
        );
      }  else {
        if(findAvaliableVehicles) {
          FrontendNotification(
            t('uploadCSV.messageVehicleNotAvaliable'),
            NotificationType.WARNING
          );
        }
        const selectMappingVehicles = arrSelectVehicles.map((item: any) => {
          return {
            id: item.id,
            roadtrip: item.roundtrip,
            outputForecast: item.previsaoInicio,
          };
        });

        if (isUnique) {
          dispatch(clearVehiclesSelected());
        }

        onVehiclesSelected(selectMappingVehicles);
        
      }
    },
    []
  );

  useEffect(() => {
    getVehicles();
    getParameters();
  }, [getVehicles, getParameters]);

  return (
    <>
      <Loading loading={loading} />
      {modalVehicles && (
        <Vehicles
          isNew
          onClear={() => {
            setModalVehicles(!modalVehicles);
            getVehicles();
          }}
        />
      )}
      <CustomModal
        isOpen
        label={t('selectVehicles.title')}
        isClose={onClear}
        styles={{ maxWidth: '90%' }}
      >
        <div>
          <div className="containerFilters">
            <button
              className="button buttonFiltersVehicles"
              onClick={() => setModalVehicles(!modalVehicles)}
            >
              <img src={addDriver} alt="" className="buttonAddDriver" />
              <Trans i18nKey="selectVehicles.vehicleTitle" />
            </button>
          </div>

          <Totalizator />

          <div className="table-responsive tableSelect">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                left: '65%',
                top: 25,
              }}
            >
              <Filters
                subsidiaryId={sessionStorage.getItem('subsidiary')}
                options={optionsFilter}
                searchText={valueSearch}
                onChangeText={(text: string) => setValueSearch(text)}
                onChangeOptions={(option: string) => setOptionsFilter(option)}
                onChangeOptionText={(optionText: string) =>
                  setValueSearch(optionText)
                }
                onFilters={(data: any) => {
                  setCurrentPage(0);
                  setFilters(data);
                }}
              />
            </div>

            <table>
              <thead>
                <tr>
                  <th className="tableResponsiveVehicles">
                    <Trans i18nKey="selectVehicles.avaliable" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.licensePlate" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.type" />
                  </th>
                  <th>ID</th>
                  <th>
                    <Trans i18nKey="selectVehicles.driver" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.maxAttendances" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.weight" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.volume" />
                  </th>
                  <th>
                    <Trans i18nKey="selectVehicles.price" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {vehicles.length > 0 &&
                  vehicles.map(
                    (vehiclesSelect: VehiclesData, index: number) => (
                      <tr
                        className="lineGroup   rowSelect"
                        id={`vehicles` + index}
                        onClick={() => {
                          if (isUnique && selectedVehicles.length > 0) {
                            FrontendNotification(
                              'Não é possivel adicionar mais veiculos a essa simulacão!',
                              NotificationType.WARNING
                            );
                          } else {
                            onSelectVehicles(vehicles, index, 'addField');
                          }
                        }}
                      >
                        <td className="columnSelect">
                          <span>
                            {vehiclesSelect.disponivel ? (
                              <>
                                {navigator.language === 'pt' ||
                                navigator.language === 'pt-BR'
                                  ? 'SIM'
                                  : 'YES'}
                              </>
                            ) : (
                              <>
                                {navigator.language === 'pt' ||
                                navigator.language === 'pt-BR'
                                  ? 'NÃO'
                                  : 'NO'}
                              </>
                            )}
                          </span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.placa}</span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.tipo}</span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.idReferencia}</span>
                        </td>
                        <td>
                          <span>
                            {vehiclesSelect.motorista !== null &&
                              vehiclesSelect.motorista.nome}
                          </span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.quantidadeAtendimento}</span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.peso} Kg</span>
                        </td>
                        <td>
                          <span>{vehiclesSelect.cubagem} m³</span>
                        </td>
                        <td className="columnSelectValue">
                          <span>R$ {vehiclesSelect.valor.toFixed(2)}</span>
                        </td>
                      </tr>
                    )
                  )}
              </tbody>
            </table>
            <div className="vehiclesPagination">
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e, page) => paginate(page)}
              />
            </div>
            <div>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <p className="title titleVehicleSelected">
                  <Trans i18nKey="selectVehicles.selectVehicle" />
                </p>

                <div
                  className="wrapperForecast"
                  style={{ marginRight: selectedVehicles.length > 0 ? 40 : 0 }}
                >
                  <th>
                    <label htmlFor="">
                      <Trans i18nKey="selectVehicles.forecastGeral" />
                    </label>
                  </th>
                  <input
                    type="datetime-local"
                    onChange={(e: any) => {
                      dispatch(setDateForecast(e.target.value));
                      if (selectedVehicles.length > 0) {
                        let updateSelected: any = selectedVehicles;

                        updateSelected = selectedVehicles.map((item: any) => {
                          return {
                            ...item,
                            previsaoInicio: e.target.value,
                          };
                        });

                        dispatch(updateSelectedVehicles(updateSelected));
                      }
                    }}
                    value={dateForecast}
                    className="finalHoursPausa"
                    style={{
                      width: '83%',
                      borderRadius: 10,
                      border: '2px solid rgba(0,48,73,0.2)',
                      height: 35,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive tableSelect">
              <table style={{ width: '100%', marginBottom: 20 }}>
                <thead>
                  <tr>
                    <th>RoundTrip</th>
                    <th>
                      <Trans i18nKey="selectVehicles.licensePlate" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.type" />
                    </th>
                    <th>ID</th>
                    <th>
                      <Trans i18nKey="selectVehicles.driver" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.maxAttendances" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.weight" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.volume" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.price" />
                    </th>
                    <th>
                      <Trans i18nKey="selectVehicles.forecast" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {selectedVehicles.length > 0 &&
                    selectedVehicles.map(
                      (
                        itemSelectedVehicles: VehiclesData,
                        indexSelectedVehicles: number
                      ) => (
                        <tr className="lineGroup">
                          <td>
                            <div className="roundtripView">
                              <span>
                                <CheckBox
                                  onChecked={() => {
                                    if (
                                      permissionMenu(userData.grupo.permissoes, 'correctCSV.checked-roundtrip')
                                    ) {
                                      dispatch(
                                        updateVehiclesRoadTrip(
                                          indexSelectedVehicles
                                        )
                                      );
                                    } else {
                                      FrontendNotification(
                                        t('common.permission'),
                                        NotificationType.WARNING
                                      );
                                    }
                                   
                                  }}
                                  active={itemSelectedVehicles.roundtrip}
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <span>{itemSelectedVehicles.placa}</span>
                          </td>
                          <td>
                            <span>{itemSelectedVehicles.tipo}</span>
                          </td>
                          <td>
                            <span>{itemSelectedVehicles.idReferencia}</span>
                          </td>
                          <td>
                            <span>
                              {itemSelectedVehicles.motorista !== null &&
                                itemSelectedVehicles.motorista.nome}
                            </span>
                          </td>
                          <td>
                            <span>
                              {itemSelectedVehicles.quantidadeAtendimento}
                            </span>
                          </td>
                          <td>
                            <span>{itemSelectedVehicles.peso} Kg</span>
                          </td>
                          <td>
                            <span>{itemSelectedVehicles.cubagem} m³</span>
                          </td>
                          <td className="columnSelectValue">
                            <span>R$ {itemSelectedVehicles.valor}</span>
                          </td>
                          <td>
                            <input
                              type="datetime-local"
                              onChange={(e: any) => {
                                dispatch(
                                  updateVehiclesForecast(
                                    indexSelectedVehicles,
                                    e.target.value
                                  )
                                );
                              }}
                              value={itemSelectedVehicles.previsaoInicio}
                              className="finalHoursPausa"
                              style={{
                                width: '83%',
                                borderRadius: 10,
                                border: '2px solid rgba(0,48,73,0.2)',
                                height: 35,
                              }}
                            />
                          </td>
                          <td>
                            <img
                              src={deleteRegisterVehicle}
                              className="deleteView"
                              alt=""
                              onClick={() =>
                                onSelectVehicles(
                                  selectedVehicles,
                                  indexSelectedVehicles,
                                  'removeField'
                                )
                              }
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            <div className="confirmFooter">
              <button
                className="buttonConfirmVehicles"
                type="button"
                onClick={() => handleSubmit(parameters, selectedVehicles)}
              >
                Finalizar seleção de veículos
              </button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default SelectVehicles;
