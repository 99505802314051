import FilterListIcon from '@mui/icons-material/FilterList';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { DataComponentField } from '..';
import Input from '../components/Input';
import CheckBox from './CheckBox';
import {
  ButtonCancel,
  ButtonConfirm,
  ClearFilters,
  ClearFiltersText,
  Container,
  Footer,
  SectionFilter,
  SectionText,
  TextButtonCancel,
  TextButtonConfirm,
  WrapperFilters,
} from './styles';

// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface FiltersProps {
  filtersData: DataComponentField[];
  data: any;
  onCancel: () => void;
  onClearFilter: () => void;
  onFilter: () => void;
}

const Filters: React.FC<FiltersProps> = ({
  filtersData,
  data,
  onCancel,
  onFilter,
  onClearFilter,
}) => {
  const [dataFilters, setDataFilters] = React.useState<any>();
  const [textFilter, setTextFilter] = useState('');
  const [fields, setFields] = useState({});
  const [checked, setChecked] = useState({
    index: 0,
    value: false,
  });

  const getData = useCallback(() => {
    let fieldsData: any = {};
    filtersData.map((data: DataComponentField) => {
      const fieldName = data.field;
      Object.defineProperty(fieldsData, fieldName, {
        value: '',
        writable: true,
      });
    });

    setFields(fieldsData);
    console.log('fields22', fieldsData);
  }, []);

  const formik = useFormik({
    initialValues: fields,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (data) {
      setDataFilters(data);
    }
  }, [data]);

  console.log('DataTC', dataFilters);

  return (
    <Container>
      <SectionFilter>
        <FilterListIcon style={{ color: '#535669' }} />
        <SectionText>Filtros</SectionText>
        <ClearFilters onClick={onClearFilter}>
          <ClearFiltersText>Limpar filtros</ClearFiltersText>
        </ClearFilters>
      </SectionFilter>

      <Divider style={{ marginTop: '0.316rem' }} />

      <WrapperFilters>
        {filtersData.map((field: DataComponentField, index: number) => (
          <React.Fragment>
            {field.type === 'checkbox' ? (
              <div style={{ marginTop: '0.412rem' }}>
                <CheckBox
                  label={field.label}
                  active={data[field.field]}
                  onChecked={() => {
                    setChecked({
                      index: index,
                      value: !checked.value,
                    });

                    data[field.field] = !data[field.field];

                    setDataFilters(data);
                  }}
                  id=""
                  styles={{
                    fontSize: '0.820rem',
                  }}
                />
              </div>
            ) : field.type === 'container' && field.filter === false ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <RadioGroup
                    row
                    value={
                      data['dtAgendamento'] ? 'dtAgendamento' : 'dtEmissao'
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTextFilter(e.target.value);

                      data['dtAgendamento'] =
                        e.target.value === 'dtAgendamento' ? true : false;

                      setDataFilters(data);
                    }}
                  >
                    {field.groups.map((g: DataComponentField) => (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FormControlLabel
                          value={g.value}
                          control={
                            <Radio
                              sx={{
                                color: '#EA004C',
                                '&.Mui-checked': {
                                  color: '#EA004C',
                                },
                              }}
                            />
                          }
                          label={
                            <span
                              style={{ fontSize: '0.875rem', color: '#535669' }}
                            >
                              {g.label}
                            </span>
                          }
                          labelPlacement="end"
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>

                <Divider />
              </div>
            ) : field.type === 'container' ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {field.groups.map(item => (
                    <div
                      style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginRight: 80
                      }}
                    >
                      {field.type === 'date' ? (
                        <Input
                          placeholder={item.placeholder}
                          type={item.type}
                          title={item.label}
                          onFocus={(e: any) => {
                            setTextFilter(item.value);
                          }}
                          onChange={e => {
                            setTextFilter(e.target.value);

                            data[field.field] = e.target.value;

                            setDataFilters(data);
                          }}
                          value={data[item.field]}
                        />
                      ) : (
                        <Input
                          placeholder={item.placeholder}
                          type={item.type}
                          title={item.label}
                          onFocus={(e: any) => {
                            setTextFilter(item.value);
                          }}
                          onChange={e => {
                            setTextFilter(e.target.value);

                            data[item.field] = e.target.value;

                            setDataFilters(data);
                          }}
                          value={data[item.field]}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : field.type === 'date' ? (
              <Input
                placeholder={field.placeholder}
                type={field.type}
                title={field.label}
                onFocus={(e: any) => {
                  setTextFilter(field.value);
                }}
                onChange={e => {
                  setTextFilter(e.target.value);

                  data[field.field] = e.target.value;

                  setDataFilters(data);
                }}
                value={data[field.field]}
              />
            ) : (
              <Input
                placeholder={field.placeholder}
                type={field.type}
                title={field.label}
                onFocus={(e: any) => {
                  setTextFilter(field.value);
                }}
                onChange={e => {
                  setTextFilter(e.target.value);

                  data[field.field] = e.target.value;

                  setDataFilters(data);
                }}
                value={data[field.field]}
              />
            )}
          </React.Fragment>
        ))}
      </WrapperFilters>

      <Footer>
        <ButtonCancel onClick={() => onCancel()}>
          <TextButtonCancel>Cancelar</TextButtonCancel>
        </ButtonCancel>
        <ButtonConfirm
          onClick={() => {
            setTextFilter('');
            onFilter();
          }}
        >
          <TextButtonConfirm>Filtrar</TextButtonConfirm>
        </ButtonConfirm>
      </Footer>
    </Container>
  );
};

export default Filters;
