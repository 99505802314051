import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2.25rem;
  margin-bottom: 4rem;
`;

export const IconColumn = styled.td`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;