import axios from 'axios';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import i18next from 'i18next';
import Leaflet from 'leaflet';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Col, Label, Row } from 'reactstrap';
import api from 'services/api';
import { searchAddress, viaCep } from 'services/endpoint';
import { country } from 'util/countrys';
import { validateZipcode } from 'util/validationUtil';
import MapIcon from '../../../assets/images/mapPin.svg';
import { States } from '../../../util/monitoring';
import ConfirmationLocation from '../ConfirmationLocation';
import '../styles.scss';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

export default function EditFiliais({ loadList }) {
  const [tabs, setTabs] = React.useState(0);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [zoom, setZoom] = useState(14);
  const [states, setStates] = useState([]);
  const [position, setPosition] = useState([]);
  const [confirmationLocation, setConfirmationLocation] = useState(false);
  const sortType = 'asc';

  const tabsData = useSelector(state => state.map.editId);
  const { t } = useTranslation();

  function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == '00000000000000' ||
      cnpj == '11111111111111' ||
      cnpj == '22222222222222' ||
      cnpj == '33333333333333' ||
      cnpj == '44444444444444' ||
      cnpj == '55555555555555' ||
      cnpj == '66666666666666' ||
      cnpj == '77777777777777' ||
      cnpj == '88888888888888' ||
      cnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  let leafletMap = useRef();

  const getStates = useCallback(() => {
    const orderSort = States.sort((a, b) => {
      const isReversed = sortType === 'asc' ? 1 : -1;

      return isReversed * a.nome.localeCompare(b.nome);
    });

    setStates(orderSort);
  }, [sortType]);

  const getPosition = useCallback(e => {
    setPosition([e.latlng.lat, e.latlng.lng]);
  }, []);

  const getLanguage = () => {
    return i18next.language;
  };

  const handleSubmit = useCallback((values, estados) => {
    if (validarCNPJ(values.cnpj)) {
      setConfirmationLocation(!confirmationLocation);
    } else if (position.length === 0) {
      const message =
        getLanguage() === 'pt' || getLanguage() === 'pt-BR'
          ? 'Selecione o ponto no mapa da filial'
          : 'Invalid CNPJ';
      FrontendNotification(message, NotificationType.ERROR);
    } else {
      const message =
        getLanguage() === 'pt' || getLanguage() === 'pt-BR'
          ? 'CNPJ Invalido'
          : 'Invalid CNPJ';
      FrontendNotification(message, NotificationType.ERROR);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      nome: '',
      cnpj: '',
      cep: '',
      pais: 'Brasil',
      logradouro: '',
      numero: '',
      bairro: '',
      cidade: '',
      estado: '',
      idReferencia: '',
    },
    validationSchema:
      getLanguage() === 'pt' || getLanguage() === 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: values => handleSubmit(values),
  });

  const getSearchAddress = useCallback(
    async arrStates => {
      const { values } = formik;
      const formatState = arrStates.find(item => item.sigla === values.estado);
      const data = {
        logradouro: values.logradouro.replace(/\s+/g, '+'),
        cep: values.cep.replace(/\s+/g, '+').replace('-', ''),
        pais: values.pais.replace(/\s+/g, '+'),
        bairro: values.bairro.replace(/\s+/g, '+'),
        cidade: values.cidade.replace(/\s+/g, '+'),
        estado: formatState.nome.replace(/\s+/g, '+'),
      };

      const { logradouro, cep, pais, bairro, cidade, estado } = data;

      const response = await axios.get(
        `${searchAddress}/search?addressdetails=1&q=${logradouro},${cep},${cidade},${bairro},${estado},${pais}&format=json&limit=1`
      );

      if (response.data.length === 0) {
        const message =
          getLanguage() === 'pt' || getLanguage() === 'pt-BR'
            ? 'Localização não encontrada. Por favor, indicar no mapa a localização correta.'
            : 'Location not found. Please indicate on the map the exact location.';

        FrontendNotification(message, NotificationType.WARNING);
      } else {
        setPosition([response.data[0].lat, response.data[0].lon]);
        setInitialPosition([response.data[0].lat, response.data[0].lon]);
        setZoom(40);
      }
    },
    [formik]
  );

  const searchCep = useCallback(async cep => {
    if (validateZipcode(cep)) {
      const response = await axios.get(`${viaCep}/${cep}/json/`, {
        'Content-Type': 'application/json',
      });

      formik.setFieldValue('bairro', response.data.bairro);
      formik.setFieldValue('logradouro', response.data.logradouro);
      formik.setFieldValue('cidade', response.data.localidade);
      formik.setFieldValue('estado', response.data.uf);
    }
  }, []);

  const getFiliaisById = useCallback(async () => {
    try {
      const response = await api.get(`/cadastro/filiais/${tabsData}`);

      formik.setFieldValue('nome', response.data.nome);
      formik.setFieldValue('cnpj', response.data.cnpj);
      formik.setFieldValue('pais', response.data.endereco.pais);
      formik.setFieldValue('cep', response.data.endereco.cep);
      formik.setFieldValue('logradouro', response.data.endereco.logradouro);
      formik.setFieldValue('numero', response.data.endereco.numero);
      formik.setFieldValue('bairro', response.data.endereco.bairro);
      formik.setFieldValue('cidade', response.data.endereco.cidade);
      formik.setFieldValue('estado', response.data.endereco.uf);
      formik.setFieldValue('idReferencia', response.data.idReferencia);
      setPosition([
        response.data.endereco.latitude,
        response.data.endereco.longitude,
      ]);
      setInitialPosition([
        response.data.endereco.latitude,
        response.data.endereco.longitude,
      ]);
      setZoom(40);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  function confirmEdit() {
    loadList();
  }

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    getStates();
    getFiliaisById();
  }, [getStates, getFiliaisById]);
  return (
    <>
      {confirmationLocation && (
        <ConfirmationLocation
          onClear={() => setConfirmationLocation(!confirmationLocation)}
          data={position}
          dataValues={formik.values}
          onEdit={{
            editId: tabsData,
          }}
          onRegister={() => confirmEdit()}
        />
      )}
      <Row>
        <Col md={4}>
          <InputCustom
            title={t('filiais.fields.nome')}
            placeholder={t('filiais.placeholder.name')}
            onChange={formik.handleChange('nome')}
            error={formik.errors.nome}
            touched={formik.touched.nome}
            value={formik.values.nome}
          />
        </Col>
        <Col md={3}>
          <InputCustom
            title={t('filiais.fields.cnpj')}
            typeInput="mask"
            mask="99.999.999/9999-99"
            id="SelectState"
            placeholder={t('filiais.placeholder.cnpj')}
            onChange={formik.handleChange('cnpj')}
            error={formik.errors.cnpj}
            touched={formik.touched.cnpj}
            value={formik.values.cnpj}
          />
        </Col>
        <Col md={3}>
          <SelectCustom
            title={t('filiais.fields.pais')}
            placeholder={t('filiais.placeholder.country')}
            onChange={formik.handleChange('pais')}
            error={formik.errors.pais}
            touched={formik.touched.pais}
            value={formik.values.pais}
          >
            <option value="" label={t('common.select')}></option>
            {country.map(item => (
              <option label={item.Pais} value={item.Pais} />
            ))}
          </SelectCustom>
        </Col>
        <Col md={2}>
          <InputCustom
          id='zipcode'
            typeInput="mask"
            title={t('filiais.fields.cep')}
            placeholder={t('filiais.placeholder.postalCode')}
            mask="99999-999"
            onChange={formik.handleChange('cep')}
            onBlur={() => searchCep(formik.values.cep)}
            touched={formik.touched.cep}
            error={formik.errors.cep}
            value={formik.values.cep}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <InputCustom
            title={t('filiais.fields.logradouro')}
            placeholder={t('filiais.placeholder.street')}
            onChange={formik.handleChange('logradouro')}
            touched={formik.touched.logradouro}
            error={formik.errors.logradouro}
            value={formik.values.logradouro}
          />
        </Col>
        <Col md={2}>
          <InputCustom
            title={t('filiais.fields.numero')}
            placeholder={t('filiais.placeholder.number')}
            onChange={formik.handleChange('numero')}
            touched={formik.touched.numero}
            error={formik.errors.numero}
            value={formik.values.numero}
          />
        </Col>
        <Col md={4}>
          <InputCustom
            title={t('filiais.fields.bairro')}
            placeholder={t('filiais.placeholder.neighborhood')}
            onChange={formik.handleChange('bairro')}
            touched={formik.touched.bairro}
            error={formik.errors.bairro}
            value={formik.values.bairro}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <InputCustom
            title={t('filiais.fields.cidade')}
            placeholder={t('filiais.placeholder.city')}
            onChange={formik.handleChange('cidade')}
            touched={formik.touched.cidade}
            error={formik.errors.cidade}
            value={formik.values.cidade}
          />
        </Col>
        <Col md={2}>
          <SelectCustom
            title={t('filiais.fields.estado')}
            placeholder="Insira o estado da filial"
            onChange={formik.handleChange('estado')}
            touched={formik.touched.estado}
            error={formik.errors.estado}
            value={formik.values.estado}
          >
            <option label={t('filiais.placeholder.state')} />
            {states.map(item => (
              <option label={item.nome} value={item.sigla} />
            ))}
          </SelectCustom>
        </Col>
        <Col md={4}>
          <InputCustom
            title={t('filiais.fields.idReference')}
            placeholder="Insira o idReferencia"
            onChange={formik.handleChange('idReferencia')}
            touched={formik.touched.idReferencia}
            error={formik.errors.idReferencia}
            value={formik.values.idReferencia}
          />
        </Col>
        <Col md={2}>
          <Label />
          <button
            type="button"
            className="buttonLocate"
            onClick={() => getSearchAddress(states)}
          >
            <Trans i18nKey="filiais.buttons.location" />
          </button>
        </Col>
      </Row>
      <div style={{ position: 'relative' }}>
        <Map
          ref={m => {
            leafletMap = m;
          }}
          style={{
            maxHeight: 200,
            width: '100%',
            marginTop: 20,
            cursor: 'pointer',
          }}
          center={initialPosition}
          zoom={zoom}
          zoomControl
          onclick={e => getPosition(e)}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {position.length !== 0 && (
            <Marker
              position={position}
              icon={pinIcon}
            // data={point}
            />
          )}
        </Map>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 40,
          }}
        >
          <button
            type="button"
            id="buttonSaveLocation"
            className="button"
            onClick={formik.handleSubmit}
            style={{ maxWidth: 354, height: 35 }}
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  );
}
