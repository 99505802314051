import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  from: string().required('* Campo Obrigatório!'),
  enctype: string().required('* Campo Obrigatório!'),
  charset: string().required('* Campo Obrigatório!'),
  uri: string().required('* Campo Obrigatório!'),
  account: string().required('* Campo Obrigatório!'),
  code: string().required('* Campo Obrigatório!'),
});
