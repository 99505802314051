// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  background-color: '#fff';
  display: 'flex';
  flex-direction: 'row';
  height: 103px;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/GeneralIndicators/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,eAAe;EACf,qBAAqB;EACrB,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".status {\n  background-color: '#fff';\n  display: 'flex';\n  flex-direction: 'row';\n  height: 103px;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
