import { object, ref, string } from 'yup';
import i18n from 'i18next';

export default object().shape({
  title: string()
    .required('* Enter the template title')
    .min(4, '* Minimum 1 caratere')
    .max(100, '* Maximum 100 carateres'),
  content: string().required('* Enter the template content'),
});
