import HTTP_STATUS from 'constants/httpStatus';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

export const NotificationType = {
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
};

/**
 * It is responsible for showing the backend notification to the user.
 * @param {*} data - Data received by the backend application.
 *
 *
 */

export const BackendErrorNotification = (data: any) => {
  const { t } = useTranslation();
  if (data.hasOwnProperty('response')) {
    let { response } = data;
    if (response.status === HTTP_STATUS.BAD_REQUEST) {
      response = response.data;

      if (response !== null && response.message !== null) {
        // eslint-disable-next-line no-unused-vars
        const messageSaga = t(response.message);

        NotificationManager.error(messageSaga);
      }

      if (response.errors !== null && response.errors.length > 0) {
        let err;
        for (err in response.errors) {
          const messageSaga = t(err);

          NotificationManager.error(messageSaga);
        }
      }
    }
  } else {
    NotificationManager.error(data.message);
  }
};

/**
 * It is responsible for showing the frontend notification to the user.
 * @param {*} message - Message that will be displayed to the user.
 * @param {*} type - Message type.
 */

export const FrontendNotification = (message: string, type: any) => {
  if (type === NotificationType.SUCCESS) {
    NotificationManager.success(message);
  } else if (type === NotificationType.WARNING) {
    NotificationManager.warning(message);
  } else if (type === NotificationType.ERROR) {
    NotificationManager.error(message);
  }
};
