// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagify {
  background-color: transparent !important;
  border: "none";
  border-color: transparent;
  padding: 0;
  height: 129px;
  padding-left: 0;
  padding: 10px !important;
}
.tagify:hover {
  border-color: transparent;
}

.titleMessage {
  font-family: "Lato";
  font-size: 15px;
  font-weight: bold;
  color: "#003049";
}`, "",{"version":3,"sources":["webpack://./src/components/PhasesSMS/styles.scss"],"names":[],"mappings":"AAAA;EACE,wCAAA;EACA,cAAA;EACA,yBAAA;EACA,UAAA;EACA,aAAA;EACA,eAAA;EACA,wBAAA;AACF;AACE;EACE,yBAAA;AACJ;;AAIA;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADF","sourcesContent":[".tagify {\n  background-color: transparent !important;\n  border: 'none';\n  border-color: transparent;\n  padding: 0;\n  height: 129px;\n  padding-left: 0;\n  padding: 10px !important;\n\n  &:hover {\n    border-color: transparent;\n    \n  }\n}\n\n.titleMessage {\n  font-family: 'Lato';\n  font-size: 15px;\n  font-weight: bold;\n  color: '#003049'\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
