import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';

import IconSignature from 'assets/images/order/signature.svg';
import { ENDPOINT_SIGNATURE } from 'constants/url.js';
import {
  Contractor,
  ContractorLabel,
  ServiceColumn,
  ServiceRow,
} from '../styles';

const ModalSignature = ({ modalToogle, setModal, title, data }) => {
  const [backdrop] = useState('static'); // true, false or static
  const { t } = useTranslation();

  return (
    <>
      <Modal
        isOpen={modalToogle}
        toggle={setModal}
        backdrop={backdrop}
        style={{ maxWidth: 'auto', height: 'auto' }}
      >
        <ModalHeader toggle={setModal}>{title}</ModalHeader>
        <ModalBody>
          <ServiceRow>
            <ServiceColumn>
              <ContractorLabel>
                <Trans i18nKey="knowledge.nameReceiver" />:
              </ContractorLabel>
              <Contractor>
                {data && data.nomeRecebedor !== null
                  ? data.nomeRecebedor
                  : '---'}
              </Contractor>
            </ServiceColumn>
          </ServiceRow>
          <ServiceRow style={{ marginTop: 10 }}>
            <ServiceColumn>
              <ContractorLabel>
                <Trans i18nKey="knowledge.docReceiver" />:
              </ContractorLabel>
              <Contractor>
                {data && data.documento !== null ? data.documento : '---'}
              </Contractor>
            </ServiceColumn>
          </ServiceRow>
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
};

export default ModalSignature;
