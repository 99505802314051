import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.713rem;
  margin-right: 1rem;
  position: relative;
  margin-top: 2.42rem;
`;

export const Line = styled.div`
  /* width: 100%; */
  height: 0px;
  border: 0.5px solid #ccd6db;
  margin-left: 18px;
  margin-bottom: 20px;
`;