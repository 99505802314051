import produce from 'immer';

const INITIAL_STATE = {
  data: [],
};

export default function reasons(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@reasons/SAVE_DATA_REASONS': {
        draft.data.unshift(action.payload.data);
        break;
      }
      default:
    }
  });
}
