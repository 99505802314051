// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  display: flex;
  margin-top: 28px;
  align-items: center;
  justify-content: center;
}

.prevItem {
  background: #ccc;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.item {
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/style.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,gBAAA;EAEA,mBAAA;EACA,uBAAA;AADF;;AAKA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,cAAA;AAFF","sourcesContent":[".page {\n\n  display: flex;\n  margin-top: 28px;\n\n  align-items: center;\n  justify-content: center;\n\n}\n\n.prevItem {\n  background: #ccc;\n  width: 40px;\n  height: 40px;\n  border-radius: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 20px;\n}\n\n.item {\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
