// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperMap {
  padding: 20px;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0.125rem 0.125rem 0.938rem 0rem rgba(0, 0, 0, 0.75);
}

.titleMap {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  /* 01 */
  color: #003049;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/MapDeliveryTracking/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,+DAAA;AACF;;AAEA;EACE,mBAAA;EACF,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;EAEA,OAAA;EAEA,cAAA;EACA,mBAAA;AADA","sourcesContent":[".wrapperMap {\n  padding: 20px;\n}\n\n.mapContainer {\n  display: flex;\n  flex-direction: column;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0.125rem 0.125rem 0.938rem 0rem rgba(0,0,0,0.75);\n}\n\n.titleMap {\n  font-family: 'Lato';\nfont-style: normal;\nfont-weight: bold;\nfont-size: 24px;\nline-height: 29px;\n\n/* 01 */\n\ncolor: #003049;\nmargin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
