import styled from 'styled-components';

interface StyledProps {
  color?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
`;
export const DotField = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(props: StyledProps ) => props.color && props.color};
  border-radius: 50%;
  margin-right: 0.515rem;
`;
export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: #003049;
  font-style: normal;
  font-weight: 500;
  opacity: 0.6;
`;