import styled from 'styled-components';

export const Container = styled.div``;
export const WrapperFilters = styled.div`
display: flex;
align-items: center;
  width: 100%;
`;
export const OccurrenceList = styled.div`
  width: 100%;
  margin-top: 1rem;

  #rowActive {
    border-bottom: 1px solid gray;
    cursor: pointer;
    background-color: #EA004C;

    #ocorrenceSuccess {
          color: #fff !important;
        }

        #ocorrenceInformation {
          color: #fff !important;
        }

        td {
          color: #fff !important;
        }
  }

  #rowInactive {
    border-bottom: 1px solid gray;
    cursor: pointer;
    background-color: #fff;
  }

  tbody tr td {
    color: #EA004C;
    font-size: 18px;
    font-weight: bold;

    
  }

  tbody tr {
    #ocorrenceSuccess {
      color: green !important;

    }

    #ocorrenceInformation {
      color: #000 !important;
    }

    &:hover {
      #ocorrenceSuccess {
          color: #fff !important;
        }

        #ocorrenceInformation {
          color: #fff !important;
        }
      td{

        color: #fff !important;

       
      }
      background-color: #EA004C !important;
    }
  }
`;

export const WrapperPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;


export const EmptyTable = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  
`;