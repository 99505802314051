export function addAccumulatorRoutering(
  accumulatorAttendances,
  accumulatorWeight,
  accumulatorVolume,

  accumulatorPrice
) {
  return {
    type: '@accumulator/ADD_ACCUMULATOR_VEHICLE',
    payload: {
      accumulatorAttendances,
      accumulatorWeight,
      accumulatorVolume,
      accumulatorPrice,
    },
  };
}

export function updateAccumulatorRoutering(
  accumulatorAttendances,
  accumulatorWeight,
  accumulatorVolume,
  
  accumulatorPrice
) {
  return {
    type: '@accumulator/UPDATE_ACCUMULATOR_CARGA',
    payload: {
      accumulatorAttendances,
      accumulatorWeight,
      accumulatorVolume,
      accumulatorPrice,
    },
  };
}

export function addAccumulatorRouteringCargas(
  accumulatorAttendances,
  accumulatorWeight,
  accumulatorVolume,

  accumulatorPrice
) {
  return {
    type: '@accumulator/ADD_ACCUMULATOR_CARGA',
    payload: {
      accumulatorAttendances,
      accumulatorWeight,
      accumulatorVolume,
      accumulatorPrice,
    },
  };
}

export function removeAccumulatorRoutering(
  accumulatorAttendances,
  accumulatorWeight,
  accumulatorVolume,

  accumulatorPrice
) {
  return {
    type: '@accumulator/REMOVE_ACCUMULATOR_VEHICLE',
    payload: {
      accumulatorAttendances,
      accumulatorWeight,
      accumulatorVolume,
      accumulatorPrice,
    },
  };
}

export function removeAccumulatorRouteringCargas(
  accumulatorAttendances,
  accumulatorWeight,
  accumulatorVolume,

  accumulatorPrice
) {
  return {
    type: '@accumulator/REMOVE_ACCUMULATOR_CARGA',
    payload: {
      accumulatorAttendances,
      accumulatorWeight,
      accumulatorVolume,
      accumulatorPrice,
    },
  };
}

export function resetValueAccumulator() {
  return {
    type: '@accumulator/RESET_VALUE_ACCUMULATOR',
  };
}

export function resetVehiclesAccumulator() {
  return {
    type: '@accumulator/RESET_VEHICLES_ACCUMULATOR',
  };
}

export function resetCargasAccumulator() {
  return {
    type: '@accumulator/RESET_CARGA_ACCUMULATOR',
  };
}

export function getPorcentageAccumulator(
  accumulatorCarga,
  accumulatorAttendancesPorcentage,
  accumulatorWeightPorcentage,
  accumulatorPricePorcentage
) {
  return {
    type: '@accumulator/GET_PORCENTAGE_ACCUMULATOR',
    payload: {},
  };
}
