import InputCustom from 'components/Input';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import api from 'services/api';
import formValidator from './formValidator';

// import { Container } from './styles';

interface NewRegionProps {
  onClear: () => void;
}

type NewRegionSchema = {
  description: string;
  referenceId: string;
};

const NewRegion: React.FC<NewRegionProps> = ({ onClear }: NewRegionProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onHandleSubmit = useCallback(async (data: NewRegionSchema) => {
    try {
      setLoading(true);
      const body = {
        description: data.description,
        referenceId: data.referenceId,
      };
      const response = await api.post('/delivery/region', body);

      if (response.status === 201) {
        FrontendNotification(
          'Região criada com sucesso!',
          NotificationType.SUCCESS
        );
        setLoading(false);
        onClear();
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const initialValues: NewRegionSchema = {
    description: '',
    referenceId: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: NewRegionSchema) => onHandleSubmit(values),
  });

  return (
    <div className="tw-flex tw-container tw-max-w-full tw-mt-4">
      <Loading loading={loading} />
      <form className="tw-w-full">
        <div className="tw-flex tw-flex-1 tw-w-full tw-mb-6">
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-4">
            <InputCustom
              title="Descrição"
              onChange={formik.handleChange('description')}
              placeholder="Insira a descrição da região"
              error={formik.errors.description}
              touched={formik.touched.description}
              value={formik.values.description}
              id="description"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <InputCustom
              title="Id de referencia"
              onChange={formik.handleChange('referenceId')}
              placeholder="Insira o id de referência"
              error={formik.errors.referenceId}
              touched={formik.touched.referenceId}
              value={formik.values.referenceId}
              id="referenceId"
            />
          </div>
        </div>

        <button
          type="button"
          id='btnSaveRegion'
          onClick={() => formik.handleSubmit()}
          className="tw-w-[350px] tw-h-9 tw-rounded-md tw-text-[#fff] tw-bg-[#EA004C] tw-border-none"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default NewRegion;
