import { Box, Tooltip } from '@mui/material';
import Alertas from 'components/Alertas';
import CustomFilterDialog from 'components/CustomFilterDialog';
import CustomPagination from 'components/CustomPagination';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import alarm from '../../../../assets/images/alarm.svg';
import iconViewAlert from '../../../../assets/images/viewAlertIcon.svg';
import CustomComponent from '../CustomComponent';
import AlertAnimation from './Animation';
import alertForm from './data/component.json';
import { Container, CreationDate, Hour, IconViewAlert } from './styles';
import './styles.scss';

interface AlertData {
  id: number;
  conhecimento: {
    id?: number;
    romaneio: {
      motorista: {
        id: number;
        nome: string;
        cpf: string;
      };
      placa: string;
    };
  };
  descricao: string;
  dataRegistro: string;
}

interface AlertFormData {
  placa: string;
  nomeMotorista: string;
  descricao: string;
}

interface AlertFormProps {
  updateAlert: boolean;
}

const Alert: React.FC<AlertFormProps> = ({ updateAlert }: AlertFormProps) => {
  const urlSocket =
    process.env.REACT_APP_SOCKET_URL +
    ':' +
    process.env.REACT_APP_SOCKET_PORT +
    '/' +
    'tc';

  const [password, setPassword] = React.useState('');

  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalFilters, setModalFilters] = useState(false);
  const [formData, setFormData] = useState<AlertFormData>({
    descricao: '',
    nomeMotorista: '',
    placa: '',
  });
  const [showAlerts, setShowAlerts] = useState<boolean>(false);
  const [alertCargo, setAlertCargo] = useState<number>();

  const { t } = useTranslation();

  const getAlert = useCallback(async () => {
    setLoading(true);

    const filters = sessionStorage.getItem('data::alert');

    let dataFilters: any = null;

    if (filters) {
      dataFilters = JSON.parse(filters);
    }

    const getFilters: any[] = [];


    if(dataFilters["conhecimento.romaneio.placa"]) {
      getFilters.push({
        field: "conhecimento.romaneio.placa",
        value: dataFilters["conhecimento.romaneio.placa"],
        operation: 'MATCH'
      })
    }

    if(dataFilters["conhecimento.romaneio.motorista.nome"]) {
      getFilters.push({
        field: "conhecimento.romaneio.motorista.nome",
        value: dataFilters["conhecimento.romaneio.motorista.nome"],
        operation: 'MATCH'
      })
    }

    if(dataFilters["descricao"]) {
      getFilters.push({
        field: "descricao",
        value: dataFilters["descricao"],
        operation: 'MATCH'
      })
    }


    const currentFilters = {
      field: 'conhecimento.filialOrigem.id',
      value: sessionStorage.getItem('subsidiary'),
      operation: 'EQUAL',
    };

    if (sessionStorage.getItem('subsidiary') !== '-1') {
      getFilters.unshift(currentFilters);
    }

    const defaultFilters = {
      field: 'leitura',
      value: false,
      operation: 'EQUAL',
    };

    getFilters.push(defaultFilters);

    const dataAlerts = {
      filters: getFilters,
      page: currentPage,
      size: 5,
      orders: [
        {
          field: 'dataRegistro',
          direction: 'DESC',
        },
      ],
    };

    const response = await api.post(
      `/delivery/conhecimentosAlertas/pageable`,
      dataAlerts
    );

    setAlerts(response.data.content);
    setTotalPages(response.data.totalPages);

    setLoading(false);
  }, [currentPage, updateAlert]);

  const setAlertReading = useCallback(async alertId => {
    try {
      setLoading(true);
      const response = await api.patch(
        `/delivery/conhecimentosAlertas/realizaLeitura/${alertId}`
      );
      setLoading(false);

      FrontendNotification(t('common.alertReading'), NotificationType.SUCCESS);

      getAlert();
    } catch (err) {}
  }, []);

  useEffect(() => {
    getAlert();

    if (!sessionStorage.getItem('component::alert')) {
      sessionStorage.setItem('component::alert', JSON.stringify(alertForm));
    }
  }, [getAlert, updateAlert]);

  return (
    <React.Fragment>
      {showAlerts && (
        <Alertas
          idCargo={alertCargo}
          isShipment
          onClear={() => {
            setShowAlerts(!showAlerts);
            getAlert();
          }}
        />
      )}
      <CustomFilterDialog
        open={modalFilters}
        onClose={() => setModalFilters(!modalFilters)}
        data={sessionStorage.getItem('component::alert')}
        dataComponent={sessionStorage.getItem('data::alert')}
        component="data::alert"
        onSendFilter={() => {
          setModalFilters(!modalFilters);
          getAlert();
        }}
        onCancel={() => {
          setModalFilters(!modalFilters);
        }}
        onFilter={() => {
          setModalFilters(!modalFilters);
        }}
      >
        <React.Fragment>
          <></>
        </React.Fragment>
      </CustomFilterDialog>
      {/* <WebSocketConfig
        url={urlSocket}
        options={{
          username: 'ALERTA_FILIAL' + sessionStorage.getItem('subsidiary'),
          channel: '/users/queue/messages',
          endpoint: '/app/tc/updates',
          message: 'Testando socket (send)',
        }}
        onReceivedData={() => getAlert()}
      /> */}
      <CustomComponent
        title="Alertas"
        icon
        color="#EA004C"
        imageUrl={alarm}
        loading={loading}
        disabledOptions={false}
        animationResource={<AlertAnimation />}
        onUpdate={() => getAlert()}
        onFilter={() => setModalFilters(!modalFilters)}
        styles={{
          minWidth: '370px',
          maxWidth: '370px',
          minHeight: '220px',
          height: '230px',
          position: 'relative',
        }}
      >
        <Container>
          <table
            className="tableTower table-action"
            style={{ marginBottom: '0.25rem' }}
          >
            <thead>
              <tr>
                <th>
                  <span className="titleHeaderTower">Veiculo</span>
                </th>
                <th>
                  <span className="titleHeaderTower">Motorista</span>
                </th>
                <th>
                  <span className="titleHeaderTower">Alerta</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((alert: AlertData) => (
                <tr>
                  <td style={{ padding: 0 }}>
                    {alert.conhecimento.romaneio.placa}
                  </td>
                  <td style={{ padding: 0 }} className="limitText">
                    <Tooltip title={alert.conhecimento.romaneio.motorista.nome}>
                      <Box
                        component="div"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: '12ch',
                        }}
                      >
                        {alert.conhecimento.romaneio.motorista.nome}
                      </Box>
                    </Tooltip>
                  </td>
                  <td style={{ padding: 0 }} className="limitText">
                    <Tooltip title={alert.descricao}>
                      <Box
                        component="div"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          width: '12ch',
                        }}
                      >
                        {alert.descricao}
                      </Box>
                    </Tooltip>
                  </td>
                  <td style={{ padding: 0 }}>
                    <CreationDate>
                      <Hour>
                        {format(
                          new Date(alert.dataRegistro),
                          'dd/MM/yyyy HH:mm',
                          {
                            locale: pt,
                          }
                        )}
                      </Hour>
                      <IconViewAlert
                        src={iconViewAlert}
                        onClick={() => {
                          setShowAlerts(!showAlerts);
                          setAlertCargo(alert.conhecimento.id);
                        }}
                      />
                    </CreationDate>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="alertPagination">
            <CustomPagination
              totalPages={totalPages}
              defaultPage={currentPage}
              onChangePage={(page: number | null) => {
                if (page !== null) {
                  setCurrentPage(page - 1);
                }
              }}
              onPrevPage={(page: number | null) => {
                if (page !== null) {
                  const prevPage = page - 1;
                  setCurrentPage(prevPage - 1);
                }
              }}
              onNextPage={(page: number | null) => {
                if (page !== null) {
                  const nextPage = page - 1;
                  setCurrentPage(nextPage + 1);
                }
              }}
            />
          </div>
        </Container>
      </CustomComponent>
    </React.Fragment>
  );
};

export default Alert;
