/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import api from 'services/api';
import closeModalIcon from '../../../assets/images/closeIcon.svg';
import errorIcon from '../../../assets/images/erroIcon.svg';
import iconOrderBottom from '../../../assets/images/iconOrderBottom.svg';
import iconOrderTop from '../../../assets/images/iconOrderTop.svg';
import iconSearch from '../../../assets/images/searchModal.png';
import successIcon from '../../../assets/images/successIconTable.png';
import Pagination from '../../Pagination';
import './styles.scss';

export default function ListPackages({
  knowledgePackageId,
  phasesPackageId,
  onClear,
}) {
  const [modal, setModal] = useState(true);
  const [packages, setPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);

  const [direction, setDirection] = useState('disabled');

  const [sortData, setSortData] = useState({
    index: null,
    direction: 'disabled',
  });

  let filterData = [
    {
      field: 'conhecimento.id',
      value: knowledgePackageId,
      operation: 'EQUAL',
    },
  ];

  const [optionsFilter, setOptionsFilter] = useState('');

  const getKnowledgePackages = useCallback(async (dataOrder, filtersSearch) => {
    try {
      const data = {
        filters: filtersSearch || filterData,
        page: 0,
        size: 10,
        orders: dataOrder || [],
      };
      const response = await api.post(
        '/delivery/conhecimentosPacotes/pageable',
        data
      );

      const filterPhasesPackages = response.data.content.filter(
        item => item.conhecimento.etapa.etapa.id === phasesPackageId
      );
      setPackages(response.data.content);
    } catch (err) {}
  }, []);

  const paginate = () => {};

  const { t } = useTranslation();

  function clearNav() {
    onClear();
  }

  useEffect(() => {
    getKnowledgePackages();
  }, [getKnowledgePackages]);

  return (
    <>
      <Modal
        size="lg"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        className="modalPackage"
      >
        <div className="modalWrapperPackages">
          <div className="iconCloseViewPackages">
            <button
              className="buttonClosePackages"
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">Pacotes</p>
          <div className="packagesSearch">
            <div>
              <select
                name="filialSelector"
                id="filial1"
                className="filialInput2"
                onChange={e => setOptionsFilter(e.target.value)}
              >
                <option
                  value="descricao"
                  label={t('packages.fields.description')}
                />
                <option value="tag" label={t('packages.fields.code')} />

                <option
                  value="dataCriacao"
                  label={t('packages.fields.dateTime')}
                />
              </select>
            </div>
            <img src={iconSearch} alt="" className="imgSearch" />
            <input
              className="inputSearchPackages"
              type={optionsFilter === 'dataCriacao' ? 'date' : 'text'}
              placeholder="Buscar"
              onChange={e => {
                let filter = filterData;

                const dateFormat = `${e.target.value}T00:00:00`;
                const filterSearch = [
                  {
                    field: 'conhecimento.id',
                    value: 648,
                    operation: 'EQUAL',
                  },
                  {
                    field: `${optionsFilter}`,
                    value:
                      optionsFilter === 'dataCriacao'
                        ? dateFormat
                        : e.target.value,
                    operation:
                      optionsFilter === 'dataCriacao'
                        ? 'GREATER_THAN_EQUAL'
                        : 'MATCH',
                  },
                ];

                getKnowledgePackages(null, filterSearch);
              }}
            />
          </div>
          <div className="d-flex">
            <div className="contentPackage">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="containerOrder">
                          <div
                            className="click"
                            onClick={() => {
                              let directionSort;
                              setDirection(prevState => {
                                if (prevState === 'disabled') {
                                  directionSort = 'ascending';
                                } else if (prevState === 'ascending') {
                                  directionSort = 'descending';
                                } else {
                                  directionSort = 'disabled';
                                }
                                return directionSort;
                              });
                              setSortData({
                                index: 0,
                                direction: directionSort,
                              });
                              const dataOrder = [
                                {
                                  field: 'descricao',
                                  direction:
                                    direction === 'ascending' ? 'ASC' : 'DESC',
                                },
                              ];
                              getKnowledgePackages(dataOrder, null);
                            }}
                          >
                            <Trans i18nKey="packages.fields.description" />
                          </div>
                          {sortData.index === 0 && direction === 'ascending' ? (
                            <img
                              src={iconOrderTop}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : sortData.index === 0 &&
                            direction === 'descending' ? (
                            <img
                              src={iconOrderBottom}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="containerOrder">
                          <div
                            className="click"
                            onClick={() => {
                              let directionSort;
                              setDirection(prevState => {
                                if (prevState === 'disabled') {
                                  directionSort = 'ascending';
                                } else if (prevState === 'ascending') {
                                  directionSort = 'descending';
                                } else {
                                  directionSort = 'disabled';
                                }
                                return directionSort;
                              });
                              setSortData({
                                index: 1,
                                direction: directionSort,
                              });
                              const dataOrder = [
                                {
                                  field: 'tag',
                                  direction:
                                    direction === 'ascending' ? 'ASC' : 'DESC',
                                },
                              ];
                              getKnowledgePackages(dataOrder, null);
                            }}
                          >
                            <Trans i18nKey="packages.fields.code" />
                          </div>
                          {sortData.index === 1 && direction === 'ascending' ? (
                            <img
                              src={iconOrderTop}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : sortData.index === 1 &&
                            direction === 'descending' ? (
                            <img
                              src={iconOrderBottom}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="containerOrder">
                          <div
                            className="click"
                            onClick={() => {
                              let directionSort;
                              setDirection(prevState => {
                                if (prevState === 'disabled') {
                                  directionSort = 'ascending';
                                } else if (prevState === 'ascending') {
                                  directionSort = 'descending';
                                } else {
                                  directionSort = 'disabled';
                                }
                                return directionSort;
                              });
                              setSortData({
                                index: 2,
                                direction: directionSort,
                              });
                              const dataOrder = [
                                {
                                  field: 'confirmado',
                                  direction:
                                    direction === 'ascending' ? 'ASC' : 'DESC',
                                },
                              ];
                              getKnowledgePackages(dataOrder, null);
                            }}
                          >
                            <Trans i18nKey="packages.fields.confirmation" />
                          </div>
                          {sortData.index === 2 && direction === 'ascending' ? (
                            <img
                              src={iconOrderTop}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : sortData.index === 2 &&
                            direction === 'descending' ? (
                            <img
                              src={iconOrderBottom}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="containerOrder">
                          <div
                            className="click"
                            // className="headerTowerTable"
                            onClick={() => {
                              let directionSort;
                              setDirection(prevState => {
                                if (prevState === 'disabled') {
                                  directionSort = 'ascending';
                                } else if (prevState === 'ascending') {
                                  directionSort = 'descending';
                                } else {
                                  directionSort = 'disabled';
                                }
                                return directionSort;
                              });
                              setSortData({
                                index: 3,
                                direction: directionSort,
                              });
                              const dataOrder = [
                                {
                                  field: 'dataCriacao',
                                  direction:
                                    direction === 'ascending' ? 'ASC' : 'DESC',
                                },
                              ];
                              getKnowledgePackages(dataOrder, null);
                            }}
                          >
                            <Trans i18nKey="packages.fields.dateTime" />
                          </div>
                          {sortData.index === 3 && direction === 'ascending' ? (
                            <img
                              src={iconOrderTop}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : sortData.index === 3 &&
                            direction === 'descending' ? (
                            <img
                              src={iconOrderBottom}
                              alt=""
                              className="iconOrder iconTableMargin"
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packages.map(item => (
                      <tr className="lineGroup">
                        <td className="textTableRow">{item.descricao}</td>
                        <td className="textTableRow">{item.tag}</td>
                        <td className="textTableRow">
                          <div className="iconTable">
                            {item.confirmado ? (
                              <img src={successIcon} alt="" />
                            ) : (
                              <img src={errorIcon} alt="" />
                            )}
                          </div>
                        </td>
                        <td className="textTableRow">{item.dataCriacao}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="paginationPackage">
              <Pagination
                perPage={10}
                total={1}
                onPaginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
