import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import CargoPre from 'interfaces/Delivery/CargoPre';
import RouterCargo from 'pages/RouterCargo';
import React, { useCallback, useState } from 'react';
import api from 'services/api';
import { DataStepsRoutering } from 'store/modules/routering/actions';

interface Props {
  onConfirm: (data: CargoPre[]) => void;
}

const Routering: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const getCargasRoutering = useCallback(
    async () => {
      try {
        setLoading(true);

        const data = {
          filters: [
            {
              field: 'simulation.id',
              value: sessionStorage.getItem('idSimulacao'),
              operation: 'EQUAL',
            },
          ],
          orders: [],
          page: 0,
          size: 1000,
        };

        const response = await api.post('/route/optmis/pageable', data);

        const mappingCargoPre = response.data.content[0].steps.map((item: DataStepsRoutering) => {
          return {
            ...item.conhecimentoPre
          }
        });

        setLoading(false);
        props.onConfirm(mappingCargoPre);
      } catch (err) {}
    },
    []
  );

  return (
    <CustomModal isOpen label='Roteirização' isClose={() => {}} styles={{ minWidth: '100%', height: '400px' }} isNotClose>
      <Loading loading={loading} />

      <div className='tw-h-[80vh] tw-overflow-scroll'>
      <RouterCargo />
      </div>
      <div className='tw-flex tw-justify-end tw-mr-0 tw-ml-0'>
        <button className='button' onClick={() => getCargasRoutering()}>
          Confirmar
        </button>
      </div>
    </CustomModal>
  );
}

export default Routering;