// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectView {
  display: flex;
  flex-direction: row;
  padding: 0px;
}

.selectOption {
  display: flex;
  align-items: center;
  position: absolute;
}

.imgNotice {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.textSelect {
  margin-top: 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgb(255, 255, 255);
  opacity: 0.6;
}

.iconView {
  position: absolute;
  top: 20%;
  right: 0;
  margin-right: 10px;
}

.textOptionVehicle {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  opacity: 0.6;
  text-align: center;
  margin-bottom: 0px;
}

.textNoticeInformation {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 600;
  color: #006CEA;
}

.textNoticeWarning {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 600;
  color: #EA7000;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomSelectVehicles/styles.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;AADJ;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;;AAMA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;;AAMA;EACI,gBAAA;EAEA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;AAJJ;;AAOA;EACI,kBAAA;EACA,QAAA;EACA,QAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAJJ;;AAOA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAJJ","sourcesContent":["\n\n.selectView {\n    display: flex;\n    flex-direction: row;\n    padding: 0px;\n    \n}\n\n.selectOption {\n    display: flex;\n    align-items: center;\n    position: absolute;\n\n}\n\n.imgNotice {\n    width: 24px;\n    height: 24px;\n    margin-right: 10px;\n}\n\n.textSelect {\n    margin-top: 20px;\n\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    color: rgb(255, 255, 255);\n    opacity: 0.6;\n}\n\n.iconView {\n    position: absolute;\n    top: 20%;\n    right: 0;\n    margin-right: 10px;\n}\n\n.textOptionVehicle {\n    font-family: 'Lato';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 12px;\n    color: #000;\n    opacity: 0.6;\n    text-align: center;\n    margin-bottom: 0px;\n}\n\n.textNoticeInformation {\n    font-family: 'Lato';\n    font-size: 15px;\n    font-weight: 600;\n    color: #006CEA;\n}\n\n.textNoticeWarning {\n    font-family: 'Lato';\n    font-size: 15px;\n    font-weight: 600;\n    color: #EA7000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
