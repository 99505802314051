import styled from 'styled-components';

export const EditorJSON = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 2px solid rgba(0, 48, 73, 0.2);
  border-radius: 10px;
  padding: 20px;
  padding-left: 0px;
`;

export const ContainerEditorJSON = styled.div`
  position: relative;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;