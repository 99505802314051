import { zodResolver } from '@hookform/resolvers/zod';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import { z } from 'zod';
import formValidator from './formValidator';

// import { Container } from './styles';

const newRouteSchema = z.object({
  description: z.string(),
  referenceId: z.string(),
  region: z.string(),
});

type NewRouteSchema = z.infer<typeof newRouteSchema>;

interface NewRouteProps {
  onClear: () => void;
}

type Region = {
  id: number;
  description: string;
  referenceId: string;
}

const NewRoute: React.FC<NewRouteProps> = ({ onClear }: NewRouteProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [regionList, setRegionList] = useState<Region[]>([]);

  const { register, handleSubmit } = useForm<NewRouteSchema>({
    resolver: zodResolver(newRouteSchema),
  });

  const onHandleSubmit = useCallback(async (data: NewRouteSchema) => {
    try {
      setLoading(true);
      const body = {
        description: data.description,
        referenceId: data.referenceId,
        region: {
          id: data.region
        }
      }
      const response = await api.post('/delivery/route', body);

      if(response.status === 201) {
        FrontendNotification('Rota criada com sucesso!', NotificationType.SUCCESS);
        setLoading(false);
        onClear();
      }
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, []);

  const initialValues: NewRouteSchema = {
    description: '',
    referenceId: '',
    region: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidator,
    onSubmit: (values: NewRouteSchema) => onHandleSubmit(values),
  });

  const onHandleListRegion = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filters: [],
        orders: [],
        size: 1000,
        page: 0,
      };

      const response = await api.post('/delivery/region/pageable', body);

      setRegionList(response.data.content);
      setLoading(false);
    }catch{
      setLoading(false);
    }
  }, [])


  useEffect(() => {
    onHandleListRegion();
  }, [onHandleListRegion])

  return (
    <div className="tw-flex tw-container tw-max-w-full tw-mt-4">
      <Loading loading={loading} />
      <form className="tw-w-full">
        <div className="tw-flex tw-flex-1 tw-w-full tw-mb-2">
          <div className="tw-flex tw-flex-col tw-w-full tw-mr-4">
            <InputCustom
              title="Descrição"
              onChange={formik.handleChange('description')}
              value={formik.values.description}
              touched={formik.touched.description}
              error={formik.errors.description}
              placeholder="Insira a descrição da rota"
              id='description'
            />
          </div>
          <div className="tw-flex tw-flex-col tw-w-full">
            <InputCustom
              title="Id de referência"
              onChange={formik.handleChange('referenceId')}
              value={formik.values.referenceId}
              touched={formik.touched.referenceId}
              error={formik.errors.referenceId}
              placeholder="Insira o id de referência da rota"
              id='referenceId'
            />
          </div>
        </div>
        <div className="tw-flex tw-mb-6">
          <div className="tw-flex tw-flex-col tw-w-[50%] tw-mr-4">
            <SelectCustom
              title="Região"
              onChange={formik.handleChange('region')}
              value={formik.values.region}
              touched={formik.touched.region}
              error={formik.errors.region}
              id='region'
            >
              <>
              <option label={t('common.select')} />
              {regionList.map((region: Region, index: number) => (
                <option label={region.description} key={index} value={region.id}>
                  {region.description}
                </option>
              ))}
              </>
            </SelectCustom>
          </div>
        </div>

        <button
          type="button"
          id='btnSaveRoute'
          onClick={() => formik.handleSubmit()}
          className="tw-w-[50%] tw-h-9 tw-rounded-md tw-text-[#fff] tw-bg-[#EA004C] tw-border-none"
        >
          Salvar
        </button>
      </form>
    </div>
  );
};

export default NewRoute;
