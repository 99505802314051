import { GroupedRow } from 'components/CreateCargo/types';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import CargoPre from 'interfaces/Delivery/CargoPre';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import { removeDuplicates } from 'util/array';

type Props = {
  open: boolean;
  onClose: () => void;
  data: GroupedRow[];
  onUpdateRow: (routing: boolean, simulation: string) => void;
};

const CargoRoutingDialog: React.FC<Props> = ({
  open,
  onClose,
  data,
  onUpdateRow
}: Props) => {

  const [loading, setLoading] = useState(false);
  const [listVehicles, setListVehicles] = useState([]);
  const { t } = useTranslation();

  /**
   * Função assíncrona para lidar com o roteamento de cargas.
   * 
   * @param groupedRows Um array de objetos agrupados contendo informações sobre as cargas.
   * @param vehicleList Um array contendo informações sobre os veículos disponíveis para o transporte.
   * @returns Uma Promise que representa o resultado do roteamento de cargas.
   */
  const onRoutingCargo = useCallback(
    async (groupedRows: GroupedRow[], vehicleList: any[]) => {
      try {

      setLoading(true);

      let cargoList: CargoPre[] = [];

      // Monta a lista de cargas a partir do agrupamento.
      groupedRows.map((grouper: GroupedRow, index: number) => {
        grouper.rows.map((row: CargoPre) => {
          const cargo = {
            ...row,
            route_id: index,
            destiny: grouper.destiny
          }
          cargoList.push(cargo);
        });
      });

      // Monta o array de Jobs que será enviado na roteirização..
      const routingJobList = cargoList.map(
        (cargo: any) => {
          return {
            id: cargo.id,
            priority: 0,
            skills: [cargo.route_id],
            destiny: cargo.destiny
          };
        }
      );

      // Monta a lista de veículos que será enviado na roteirização.
      const routingVehicleList = groupedRows.map((grouper: GroupedRow, grouperIndex: number) => {
        let dateFormat;
        let hourFormat;

        let findVehicleByLicensePlate = vehicleList.find(
          (vehicle: any) => vehicle.placa === grouper.licensePlate
        );

        if (grouper.estimateDate !== null) {
          dateFormat = format(new Date(grouper?.estimateDate), 'yyyy-MM-dd', {
            locale: ptBR,
          });

          hourFormat = format(new Date(grouper.estimateDate), 'HH:mm', {
            locale: ptBR,
          });
        }
        return {
          id: findVehicleByLicensePlate.id,
          outputForecast: `${dateFormat}T${hourFormat}`,
          roadtrip: grouper.roundtrip,
          skills: [grouperIndex],
        };
      });

      // Remove os duplicados, caso existam.
      const vehicleWithoutDuplicateList = removeDuplicates(
        routingVehicleList,
        'id'
      );

      // Monta o payload para envio.
      const dataSubmit = {
        vehicles: vehicleWithoutDuplicateList,
        jobs: routingJobList,
        branchId: sessionStorage.getItem('subsidiary')
      };

      const response = await api.post(
        `/route/routing/generate`,
        dataSubmit
      );

      setLoading(false);

      if(response && response.data) {
        sessionStorage.setItem(
          'idSimulacao',
          response.data.id_route_simulation
        );
        
        if (response.status === 201) {
          onUpdateRow(true, response.data.id_route_simulation);
          const message = t('uploadCSV.messageCreated');
          FrontendNotification(message, NotificationType.SUCCESS);
        }
        
      }
      setLoading(false);
    }catch{
      setLoading(false);
    }

    },
    []
  );

  /**
   * Recupera veículos de forma assíncrona.
   * 
   * @returns {Promise<void>} Uma Promise que é resolvida quando os veículos são recuperados.
   */
  const getVehicles = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'filial.id',
            value: sessionStorage.getItem('subsidiary'),
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/delivery/veiculos/findByFilters', body);
      setListVehicles(response.data);
      setLoading(false);
    } catch { }
  }, []);

  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  return (
    <>
      <Modal isOpen={open} toggle={onClose}>
      <Loading loading={loading} />
        <ModalBody>
          <Trans i18nKey="common.questionRouting" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => onRoutingCargo(data, listVehicles)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={onClose} id="buttonCancelRemove">
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CargoRoutingDialog;