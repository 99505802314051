/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import i18next from 'i18next';
import history from './history';

// const apiURL = import.meta.env.VITE_APP_API_URL;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
  // baseURL: "http://localhost:8080"
});

api.interceptors.response.use(
  response => {    
    return response;
  },
  error => {    
    const getLanguage = i18next.language;

    if(error.response == undefined) {
      FrontendNotification('Internal Server Error', NotificationType.ERROR);
      return;
    }

    if (error.response.status === 401) {
      history.push('/');
      window.location.reload();
    }
    if (error.response.data.message === 'User without account activation.') {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Para realizar o login, é necessário ativar sua conta pelo link enviado por e-mail. Por favor, verifique sua caixa de entrada.',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'In order to login, you must activate your account via the link sent to your e-mail. Please check your e-mail inbox.',
            NotificationType.ERROR
          );
    }  else if (error.response.data.message === 'Invalid username/password.') {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Email e senha invalidos!',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'Invalid username/password',
            NotificationType.ERROR
          );
    } else if (
      error.response.data.message.includes(
        'ERROR: update or delete on table "tipo_atividade'
      )
    ) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Este tipo de atividade está em uso em algum conhecimento',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This activity type is already used at some cargo',
            NotificationType.ERROR
          );
    }  else if(error.response.data.message.includes('ERROR: update or delete on table \"region\"')) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Esta região está em uso no momento',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This region is currently in use',
            NotificationType.ERROR
          );
    } else if(error.response.data.message.includes('ERROR: update or delete on table \"route\"')) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Esta rota está em uso no momento',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This route is currently in use',
            NotificationType.ERROR
          );
    } else if (
      error.response.data.message.includes(
        'ERROR: update or delete on table "contratante"'
      )
    ) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Este contratante está em uso em algum conhecimento',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This contrator is already used at some cargo',
            NotificationType.ERROR
          );
    } else if (
      error.response.data.message.includes(
        'ERROR: update or delete on table "motivo"'
      )
    ) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Este motivo de cancelamento está em uso em alguma entidade',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This cancel reason is already used at some entity',
            NotificationType.ERROR
          );
    } else if (
      error.response.data.message.includes(
        'ERROR: update or delete on table "template"'
      )
    ) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification(
            'Este template está em uso em alguma entidade',
            NotificationType.ERROR
          )
        : FrontendNotification(
            'This template is already used at some entity',
            NotificationType.ERROR
          );
    } else if (error.response.data.message) {
      FrontendNotification(error.response.data.message, NotificationType.ERROR);
    } else if (error.response.status >= 500) {
      getLanguage === 'pt' || getLanguage === 'pt-BR'
        ? FrontendNotification('Internal Server Error', NotificationType.ERROR)
        : FrontendNotification('Internal Server Error', NotificationType.ERROR);
    }

    return error;
  }
);

export default api;
