import styled from 'styled-components';

export const Content = styled.div`
  background-color: #fff;
  border: 2px solid #edf2f4;
  border-radius: 10px;
  padding: 20px;
  width: 120%;
  .lineGroup {
    border-bottom: 1px solid #ccd6db;
  }
`;

export const BoxForm = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export const Footer = styled.div`
  .button {
    height: 52px;
    background: #ea004c;
    border-radius: 6px;
    text-align: center;
    color: #ffffff;
    /* float: right; */
    width: 75%;
    margin-top: 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.2);

    &:hover {
      background: #b5023b;
      transition: 0.5s;
      box-shadow: 0px 4px 6px rgba(234, 0, 76, 0.4);
    }
  }

  .table-line {
    margin-top: 20px;
    width: 320px;
  }
`;

export const IconColumn = styled.td``;
