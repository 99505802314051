import React from 'react';
import { Container } from './styles';

const CargoAnimation: React.FC = () => {
  return (
    <Container>
      <div className="fa-3x">
        <i className="fa-solid fa-sync fa-spin" style={{ color: '#EA004C' }}></i>
      </div>
    </Container>
  );
};

export default CargoAnimation;
