import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const SignUpTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  /*   margin-bottom: 3rem; */
  line-height: 28px;
`;

export const LabelInput = styled.p`
  color: #003049;
  margin-bottom: 5px;
  opacity: 0.6;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const AccessAcc = styled.span`
  display: flex;
  justify-content: space-between;
  a {
    color: #ea004c;
  }
  a:hover {
    text-decoration: none;
    color: #ea004c;
  }
`;

export const Line = styled.div`
  height: 0px;
  width: 82px;
  border: 2px solid #ea004c;
  position: fixed;
  margin-bottom: -30px;
  background: #ea004c;
`;

export const BackButtom = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin-top: -45px; */
  /* margin-bottom: -24px; */
  margin-top: 20px;
  line-height: 0px;
  margin-left: 20px;
`;

export const RedLine = styled.div`
  height: 0px;
  width: 82px;
  border: 2px solid #ea004c;
  position: absolute;
  margin-bottom: -40px;
  background: #ea004c;
`;
