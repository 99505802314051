import i18next from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Input, Modal, Row } from 'reactstrap';

import '../../pages/DeliveryTracking/styles.scss';
import './style.scss';

import { BackendErrorNotification } from 'core/common/Notification';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import searchModal from '../../assets/images/searchModal.png';
import { Content } from '../Ocorrence/styles';
import Pagination from '../Pagination';

import closeModalIcon from '../../assets/images/closeIcon.svg';
import FormParameters from './FormParameters';

export default function SelectParameters({ onClear, onSelectParameters }) {
  const [modal, setModal] = React.useState(true);
  const [detailsDelivery, setDetailsDelivery] = useState([]);
  function clearNav() {
    onClear();
    setModal(!modal);
  }

  const [driver, setDriver] = useState([]);
  const [operatorLogistic, setOperatorLogistic] = useState([]);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [edit, setEdit] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [optionsFilter, setOptionsFilter] = useState('nome');
  const [totalPages, setTotalPages] = useState(0);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [registerDriver, setRegisterDriver] = useState(false);
  const [dataForm, setDataForm] = useState([]);
  const [tabs, setTabs] = useState(0);
  const [formFields, setFormField] = useState([]);
  const [showFieldTags, setShowFieldTags] = useState(false);

  const [valueSearch, setValueSearch] = useState();

  const { t } = useTranslation();

  const customStyles = {
    content: {
      zIndex: 4,
    },
  };

  const toggle = () => setModal(!modal);
  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const getDriverList = useCallback(async () => {
    try {
      const { language } = i18next;

      const serviceLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';

      const response = await api.get(
        `/delivery/templates/markups?lang=${serviceLanguage}`
      );

      setDriver(response.data);
    } catch (err) {
      BackendErrorNotification(err);
    }
  }, []);

  const filtersData = useMemo(() => {
    let computedPages = driver;

    if (valueSearch) {
      computedPages = computedPages.filter(
        op =>
          op.tag.includes(valueSearch.toLowerCase()) ||
          op.hint.toLowerCase().includes(valueSearch.toLowerCase()) ||
          op.length.toString().includes(valueSearch.toLowerCase())
      );
    }

    setTotalPages(computedPages.length);

    // Current Page slice
    return computedPages.slice(
      (currentPage - 1) * perPage,
      (currentPage - 1) * perPage + perPage
    );
  }, [driver, currentPage, valueSearch]);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  function closeModal() {
    setModal(!modal);
    onClear();
  }

  function handleSelectDriver(tagText, data) {
    navigator.clipboard.writeText(`${tagText} `);

    onSelectParameters(data);

    setModal(!modal);
    onClear();
  }

  const onHandleSelectForm = useCallback(async idFormGroup => {
    const data = {
      filters: [
        {
          field: 'group.id',
          value: idFormGroup,
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/formBuilder/forms/fields/findByFilters',
      data
    );

    setFormField(response.data);
    setShowFieldTags(true);
  }, []);

  const getForms = useCallback(async () => {
    const data = {
      filters: [],
    };
    const response = await api.post(
      '/formBuilder/forms/groups/findByFilters',
      data
    );

    setDataForm(response.data);
  }, []);

  useEffect(() => {
    getDriverList();
    getForms();
  }, [getDriverList, getForms]);

  return (
    <>
      {showFieldTags && (
        <FormParameters
          data={formFields}
          onClear={() => setShowFieldTags(!showFieldTags)}
          onSelectParameters={(tagText, data) => {
            navigator.clipboard.writeText(`${tagText} `);
            onSelectParameters(tagText);
            setModal(!modal);
            onClear();
          }}
        />
      )}
      <Modal isOpen dialogClassName="my-modal" style={{ maxWidth: '65%' }}>
        <div
          className=""
          style={{ height: '90%', padding: 40, borderRadius: 10, zIndex: 9 }}
        >
          <p
            className="title"
            style={{ fontSize: 24, fontWeight: 'bold', color: '#003049' }}
          >
            <Trans i18nKey="phases.titleParameter" />
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <div
            className="iconClose"
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: 20,
            }}
          >
            <button
              style={{ background: 'transparent', border: 'none' }}
              type="button"
              onClick={() => clearNav()}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>

          <Tabs
            selectedIndex={tabs}
            onSelect={index => {
              setTabs(index);
            }}
          >
            <TabList>
              <Tab>
                Parâmetros
                {/* <Trans i18nKey="tabs.list" /> */}
              </Tab>
              <Tab>
                Form
                {/* <Trans i18nKey="tabs.new" /> */}
              </Tab>
              <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
            </TabList>
            <TabPanel>
              <Row style={{ marginBottom: 15, marginTop: 0 }}>
                <Col md={12}>
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        marginLeft: 18,
                        marginTop: 5,
                      }}
                    >
                      <img src={searchModal} alt="" />
                    </div>
                    <Input
                      placeholder={t('phases.searchParameter')}
                      onChange={e => setValueSearch(e.target.value)}
                      style={{
                        height: 40,
                        background: '#fff',
                        border: '2px solid rgba(0, 48, 73, 0.2)',
                        borderRadius: 10,
                        paddingLeft: 50,
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Content style={{ width: '100%' }}>
                <div className="table-responsive">
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>
                          <Trans i18nKey="phases.parameters" />

                          {/* <Trans i18nKey="uploadCSV.columns.name" /> */}
                        </th>
                        <th>
                          {/* Descrição */}
                          <Trans i18nKey="phases.fields.description" />
                        </th>
                        <th>
                          <Trans i18nKey="phases.sizeParameter" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtersData.map((itemParameters, index) => (
                        <tr
                          className="lineGroup selectDriver"
                          onClick={() =>
                            handleSelectDriver(
                              itemParameters.tag,
                              itemParameters
                            )
                          }
                        >
                          <td>{itemParameters.tag}</td>
                          <td>
                            <p>{itemParameters.hint}</p>
                          </td>
                          <td>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20%',
                              }}
                            >
                              <p>{itemParameters.length}</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    perPage={perPage}
                    total={totalPages}
                    onPaginate={paginate}
                    currentPage={currentPage}
                  />
                </div>
              </Content>
            </TabPanel>
            <TabPanel>
              <Content style={{ width: '100%' }}>
                <div className="table-responsive">
                  <table style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>
                          Nome
                          {/* <Trans i18nKey="phases.parameters" /> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataForm.map((itemForm, index) => (
                        <tr
                          className="lineGroup selectDriver"
                          onClick={() => {
                            onHandleSelectForm(itemForm.id);
                            // handleSelectDriver(
                            //   itemParameters.tag,
                            //   itemParameters
                            //   )
                          }}
                        >
                          <td>{itemForm.form.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Content>
            </TabPanel>
          </Tabs>
        </div>
      </Modal>
    </>
  );
}
