import React, { useEffect, useState } from 'react';
import dataAlert from '../../../pages/ControlTower/components/Alert/data/data.json';
import dataShipment from '../../../pages/ControlTower/data/data.json';
import Filters from './Filters';
// import { Container } from './styles';

export interface DataComponentField {
  label: string;
  field: string;
  placeholder: string;
  type: string;
  value: any;
  groups: DataComponentField[];
  filter: boolean;
}

interface CustomFilterProps {
  data: string | null;
  dataComponent: string | null;
  component: string;
  onSendFilter: () => void;
  onCancel: () => void;
  onFilter: () => void;
  children: JSX.Element;
}

const CustomFilter: React.FC<CustomFilterProps> = ({
  data,
  dataComponent,
  component,
  onSendFilter,
  onCancel,
  children,
}: CustomFilterProps) => {
  const [dataJson, setDataJson] = useState<DataComponentField[]>([]);
  const [dataJsonComponent, setDataJsonComponent] = useState<DataComponentField[]>([]);
  const [copyDataJson, setCopyDataJson] = useState<DataComponentField[]>([]);

  useEffect(() => {
    if (data) {
      const dataSendFilter = JSON.parse(data);

      setCopyDataJson(JSON.parse(data));
      
      const dataFilter = dataSendFilter.filter((item: DataComponentField, index: number) => index >= 2 && index <=6);
      setDataJson(dataFilter);
    }

    if(dataComponent) {
      setDataJsonComponent(JSON.parse(dataComponent));
    }
  }, [data, dataComponent]);

  return (
    <Filters
      filtersData={dataJson}
      data={dataJsonComponent}
      onClearFilter={() => {
        const getDataFilters = dataJson.map((item: any) => {
          return {
            ...item,
            value: ""
          }
        });

        console.log(getDataFilters);

        const dataFields: any = component === "data::alert" ? dataAlert : dataShipment;

        const dataJSONFileds = JSON.stringify(dataFields);

        const dataComponentField = JSON.parse(dataJSONFileds);

        // const dataFilter = getDataFilters.filter((item: DataComponentField, index: number) => index >= 3 && index <=7);
        sessionStorage.setItem(component, dataJSONFileds);
        setDataJsonComponent(dataComponentField);

        setDataJson(getDataFilters);
        setCopyDataJson(getDataFilters);


      }}
      // onCancel={onClose}
      onFilter={() => {
        const filterData = dataJson;

        const data = [];

        sessionStorage.setItem(component, JSON.stringify(dataJsonComponent));

        onSendFilter();
      }}
    />
  );
};

export default CustomFilter;
