/**
 * Remove elementos duplicados de um array de objetos com base em uma chave específica.
 * 
 * @param array O array de objetos do qual remover os elementos duplicados.
 * @param key A chave pela qual identificar os elementos duplicados.
 * @returns Um novo array contendo apenas elementos únicos, sem duplicatas.
 */
export const removeDuplicates = (array: any[], key: keyof any): any[] => {
  const seenKeys = new Set();

  // Use the filter method to create a new array without duplicate elements
  return array.filter(item => {
    // Get the value of the specified key from the object
    const keyValue = item[key];

    // Check if the key has been seen already
    if (!seenKeys.has(keyValue)) {
      // If it hasn't been seen, add it to the set and return true (keeping the item in the new array)
      seenKeys.add(keyValue);
      return true;
    }

    // If the key has been seen, return false (ignoring the item in the new array)
    return false;
  });
}