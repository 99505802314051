import React from 'react';
import TotalizatorValue from 'components/SelectVehicles/TotalizatorValue';
import { useSelector } from 'react-redux';
import TotalizatorTooltip from '../TotalizatorTooltip';

import listAttendancesVehicle from '../../../../assets/images/listAttendancesVehicle.svg';
import weightVehicle from '../../../../assets/images/weightVehicle.svg';
import volumeVehicle from '../../../../assets/images/volumeVehicle.svg';
import priceVehicle from '../../../../assets/images/priceVehicle.svg';


const Totalizator = () => {

  const accumulatorValueCarga = useSelector(
    (state: any) => state.accumulator.accumulatorValueCarga
  );
  const accumulatorValueVehicle = useSelector(
    (state: any) => state.accumulator.accumulatorValue
  );
  const accumulatorPorcentageRoutering = useSelector(
    (state: any) => state.accumulator.accumulatorPorcentage
  );


  return (
    <div className="totalCargasContainer">
      <div className="totalCargasBox">
        <div className="totalCargasColumn">
          <div>
            <TotalizatorTooltip
              cargasAccumulator={accumulatorValueCarga.accumulatorAttendances}
              vehicleAccumulator={
                accumulatorValueVehicle.accumulatorAttendances
              }
              porcentageAccumulator={
                accumulatorValueCarga.accumulatorAttendances === 0 ||
                accumulatorValueVehicle.accumulatorAttendances === 0
                  ? 0
                  : accumulatorPorcentageRoutering.accumulatorAttendancesPorcentage.toFixed(2)
              }
              activeTooltip={
                accumulatorPorcentageRoutering.accumulatorAttendancesPorcentage !==
                0
                  ? 'active'
                  : 'inactive'
              }
              typeTotalizator="Atendimentos"
            >
              <TotalizatorValue
                progressValue={
                  accumulatorValueVehicle.accumulatorAttendances !== 0 &&
                  accumulatorValueCarga.accumulatorAttendances !== 0
                    ? accumulatorPorcentageRoutering.accumulatorAttendancesPorcentage
                    : 0
                }
              />
            </TotalizatorTooltip>
          </div>
          <div>
            <TotalizatorTooltip
              cargasAccumulator={accumulatorValueCarga.accumulatorWeight.toFixed(2)}
              vehicleAccumulator={accumulatorValueVehicle.accumulatorWeight}
              porcentageAccumulator={
                accumulatorValueCarga.accumulatorWeight === 0 ||
                accumulatorValueVehicle.accumulatorWeight === 0
                  ? 0
                  : accumulatorPorcentageRoutering.accumulatorWeightPorcentage.toFixed(2)
              }
              activeTooltip={
                accumulatorPorcentageRoutering.accumulatorWeightPorcentage !== 0
                  ? 'active'
                  : 'inactive'
              }
              typeTotalizator="Peso"
            >
              <TotalizatorValue
                progressValue={
                  accumulatorValueVehicle.accumulatorWeight !== 0 &&
                  accumulatorValueCarga.accumulatorWeight !== 0
                    ? accumulatorPorcentageRoutering.accumulatorWeightPorcentage
                    : 0
                }
              />
            </TotalizatorTooltip>
          </div>
          <div>
            <TotalizatorTooltip
              cargasAccumulator={accumulatorValueCarga.accumulatorVolume.toFixed(2)}
              vehicleAccumulator={accumulatorValueVehicle.accumulatorVolume}
              porcentageAccumulator={
                accumulatorValueCarga.accumulatorVolume === 0 ||
                accumulatorValueVehicle.accumulatorVolume === 0
                  ? 0
                  : accumulatorPorcentageRoutering.accumulatorVolumePorcentage.toFixed(2)
              }
              activeTooltip={
                accumulatorPorcentageRoutering.accumulatorVolumePorcentage !== 0
                  ? 'active'
                  : 'inactive'
              }
              typeTotalizator="Cubagem"
            >
              <TotalizatorValue
                progressValue={
                  accumulatorValueVehicle.accumulatorVolume !== 0 &&
                  accumulatorValueCarga.accumulatorVolume !== 0
                    ? accumulatorPorcentageRoutering.accumulatorVolumePorcentage
                    : 0
                }
              />
            </TotalizatorTooltip>
          </div>
          <div>
            <TotalizatorTooltip
              cargasAccumulator={accumulatorValueCarga.accumulatorPrice.toFixed(2)}
              vehicleAccumulator={accumulatorValueVehicle.accumulatorPrice}
              porcentageAccumulator={
                accumulatorValueCarga.accumulatorPrice === 0 ||
                accumulatorValueVehicle.accumulatorPrice === 0
                  ? 0
                  : accumulatorPorcentageRoutering.accumulatorPricePorcentage.toFixed(2)
              }
              activeTooltip={
                accumulatorPorcentageRoutering.accumulatorPricePorcentage !== 0
                  ? 'active'
                  : 'inactive'
              }
              typeTotalizator="Valor"
            >
              <TotalizatorValue
                progressValue={
                  accumulatorValueVehicle.accumulatorPrice !== 0 &&
                  accumulatorValueCarga.accumulatorPrice !== 0
                    ? accumulatorPorcentageRoutering.accumulatorPricePorcentage
                    : 0
                }
              />
            </TotalizatorTooltip>
          </div>
        </div>
        <div className="totalCargasColumn">
          <div>
            <img src={listAttendancesVehicle} alt="" />
          </div>
          <div>
            <img src={weightVehicle} alt="" />
          </div>
          <div>
            <img src={volumeVehicle} alt="" />
          </div>
          <div>
            <img src={priceVehicle} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Totalizator;