import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';

import TruckIcon from '../../assets/images/map/truck.svg';

import './styles.scss';

export default function MapDeliveryTracking() {
  let mapRef = useRef();

  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [zoom, setZoom] = useState(14);

  const [driverPosition, setDriverPosition] = useState([-8.0527, -34.9099]);
  const [ctePosition, setCtePosition] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRoutesDelivery = useCallback(async () => {
    setLoading(true);
    const query = window.location.search.slice(1);

    if (query.length > 0) {
      const separatorQuery = query.split('&');
      const data = {};

      const formatSeparator = separatorQuery[1].split('=');

      if (formatSeparator[1].length !== 0) {
        separatorQuery.forEach(q => {
          const chaveValor = q.split('=');
          const chave = chaveValor[0];
          const valor = chaveValor[1].split(',');
          data[chave] = valor;
        });

        setCtePosition(data.cte);
        setDriverPosition(data.driver);
      } else {
        separatorQuery.forEach(q => {
          const chaveValor = q.split('=');
          const chave = chaveValor[0];
          const valor = chaveValor[1].split(',');
          data[chave] = valor;
        });

        setCtePosition(data.cte);
        setDriverPosition([-8.0527, -34.9099]);
      }
    } else {
      setCtePosition([]);
      setDriverPosition([-8.0527, -34.9099]);
    }

    setLoading(false);
  }, []);

  const truckIcon = new Leaflet.Icon({
    iconUrl: TruckIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  function getHtmlPositionIcon(number) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `<div class='marker-container'><div class='marker-box'><i class='marker-text'>" +
      ${1} +
      'º</i></div></div>`,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  useEffect(() => {
    getRoutesDelivery();
  }, [getRoutesDelivery]);

  return (
    <Map
      ref={m => {
        mapRef = m;
      }}
      center={driverPosition}
      zoom={zoom}
      zoomControl
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {driverPosition.length > 0 && (
        <Marker position={driverPosition} icon={truckIcon} />
      )}

      {ctePosition.length > 0 && (
        <Marker
          // key={`marker-${point.id}`}
          position={ctePosition}
          icon={getHtmlPositionIcon()}
        />
      )}
    </Map>
  );
}
