import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import './styles.scss';

import { FrontendNotification } from 'components/Notification';
import {
  NotificationType
} from 'core/common/Notification';
import api from 'services/api';
import closeModalIcon from '../../../../assets/images/closeIcon.svg';

export default function ConfirmationDriver({
  idRomaneio,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
}) {
  const [modal, setModal] = React.useState(true);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(async (cargoReactivate) => {
    try {
      const dataSubmit = {
        idMotivo: cpfDriver,
        idsRomaneios: [idRomaneio],
        reactivate: cargoReactivate
      };

      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';

      const response = await api.post(
        `/delivery/romaneios/cancela?lang=${emailSendLanguage}`,
        dataSubmit
      );
      if (response.status === 204) {
        FrontendNotification(
          t('cancelDriver.messageSuccess'),
          NotificationType.SUCCESS
        );
        setModal(!modal);
        onConfirm();
        onLoad();
        const store = sessionStorage.setItem('updateTable', idRomaneio);
      }
    } catch (err) {}
  }, []);

  return (
    <>
    
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        className="modal-dialog"
        // dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%' }}
      >
        <div className="modalWrapper">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="cancelDriver.title" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
           Deseja reativar os conhecimentos na tela de planejamento?
            {/* <Trans i18nKey="cancelDriver.messageConfirmChanged" /> */}
          </span>
          <Row>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  handleSubmit(false)
                }}
              >
                <Trans i18nKey="common.no" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => handleSubmit(true)}
              >
                <Trans i18nKey="common.yes" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
