// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerTower {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.headerTitle {
  margin-left: 1.25rem;
}

.titleTower {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem !important;
  line-height: 1.938px;
  color: #003049;
}

.buttonFilterTower {
  background: #ea004c;
  border-radius: 0.313rem;
  padding: 0.688rem;
  width: 4.625rem;
  height: 2.188rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.063rem;
  margin-top: 0.938rem;
  color: #ffffff;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.titleTower {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.938rem;
  color: #003049;
}

.kpi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/pages/ControlTower/styles.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,uBAAA;AACF;AAMA;EACE,oBAAA;AAJF;;AAOA;EACE,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,6BAAA;EACA,oBAAA;EACA,cAAA;AAJF;;AAMA;EACE,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,oBAAA;EACA,cAAA;AAHF;;AAMA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAHF;;AAMA;EACE,iBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AAHF","sourcesContent":[".headerTower {\n  height: auto;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  margin-top: 0.625rem;\n  margin-bottom: 0.625rem;\n\n  div {\n    // margin-right: 1.614rem;\n  }\n}\n\n.headerTitle {\n  margin-left: 1.25rem;\n}\n\n.titleTower {\n  font-family: 'Lato';\n  font-style: normal;\n  font-weight: bold;\n  font-size: 1.25rem !important;\n  line-height: 1.938px;\n  color: #003049;\n}\n.buttonFilterTower {\n  background: #ea004c;\n  border-radius: 0.313rem;\n  padding: 0.688rem;\n  width: 4.625rem;\n  height: 2.188rem;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-family: Lato;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 0.875rem;\n  line-height: 1.063rem;\n  margin-top: 0.938rem;\n  color: #ffffff;\n}\n\n.filters {\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.titleTower {\n  font-family: Lato;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 1.625rem;\n  line-height: 1.938rem;\n  color: #003049;\n}\n\n.kpi {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n}\n\n\n.cargoContainer {\n  // width: 90%;\n  // padding: 1.13rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
