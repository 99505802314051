import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  userName: 'Admin',
  signed: false,
  loadingSignIn: false,
  loadingSinUp: false,
  id: null,
  email: 'Admin',
  primaryAccess: null,
  user: null,
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loadingSignIn = true;
        break;
      }
      case '@auth/SET_LOADING': {
        draft.loading = !draft.loading;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.email = action.payload.email;
        draft.primaryAccess = action.payload.primaryAccess;
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loadingSignIn = false;
        break;
      }

      case '@auth/UPDATE_PRIMARY_ACCESS': {
        draft.primaryAccess = null;
      }
      case '@auth/SIGN_FAILURE': {
        draft.loadingSignIn = false;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case '@auth/LOGIN': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case '@auth/SIGNUP': {
        draft.loadingSinUp = true;
        break;
      }
      case '@auth/SIGNUP_SUCCESS': {
        draft.loadingSinUp = false;
        break;
      }
      case '@auth/SIGNUP_FAILURE': {
        draft.loadingSinUp = false;
        break;
      }
      case '@auth/SET_LOADING_SIGN_IN': {
        draft.loadingSignIn = action.payload.loading;
        break;
      }
      case '@auth/LOGOUT_REQUEST': {
        draft.token = '';
        break;
      }
      default:
    }
  });
}
