import { object, ref, string, number } from 'yup';

export default object().shape({
  icone: string().required('* Enter the stage icon!'),
  descricao: string()
    .required('* Enter the description!')
    .min(5, '* Minimum of 5 characters!')

    .max(100, '* Maximum of 100 characters!'),
  tempo: number()
    .required('* Enter time in minutes!')
    .min(0, '* This field does not allow negative number')

    .max(10000, '* Maximum of 5 characteres'),
  smsMensagem: string().required('* Enter the sms message'),
});
