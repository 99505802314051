import Editor from '@monaco-editor/react';
import AddButtonIcon from 'assets/images/addButton.svg';
import EditIcon from 'assets/images/editIcon.svg';
import RemoveColumnJoruneyIcon from 'assets/images/removeColumnJourney.svg';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import SelectParameters from 'components/SelectParameters';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap/lib';
import api from 'services/api';
import './jsonEditor.css';
import { ContainerEditorJSON, EditorJSON, Footer } from './styles';

interface CreateProps {
  onClear: () => void;
  isEdit: boolean;
  dataWebhook?: {
    id: number;
  };
}

interface Headers {
  id?: number;
  key: string;
  value: string;
  webhook?: number;
}

interface Event {
  id: number;
  descricao: string;
  slug: string;
  nome: string;
  path: string;
}

interface FormikInitialValues {
  description: string;
  event: string;
  url: string;
  headerKey: string;
  headerValue: string;
  payload: string;
}

export default function Create({ onClear, isEdit, dataWebhook }: CreateProps) {
  const [headers, setHeaders] = React.useState<Headers[]>([]);
  const [headersEdit, setHeadersEdit] = useState<Headers[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectParamets, setSelectParamets] = useState(false);
  const [editHeaders, setEditHeaders] = useState(false);
  const [dataEditHeaders, setDataEditHeaders] = useState<any>(null);
  const [dataHeaders, setDataHeaders] = useState();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormikInitialValues, headersData: Headers[]) => {
      try {
        const data = {
          description: values.description,
          event: values.event,
          endpoint: values.url,
          payload: values.payload,
          headers: headersData,
        };
        const URL_CREATE = isEdit
          ? `/webhook/${dataWebhook?.id}`
          : '/webhook';

        if (isEdit) {
          const response = await api.patch(URL_CREATE, data);

          FrontendNotification(
            'Webhook atualizado com sucesso!',
            NotificationType.SUCCESS
          );
        } else {
          const response = await api.post(URL_CREATE, data);

          FrontendNotification(
            'Webhook criado com sucesso!',
            NotificationType.SUCCESS
          );
        }

        onClear();
      } catch (err) {}
    },
    []
  );

  const handleSubmitHeaders = useCallback(async (headersData: Headers[]) => {
    try {
      const dataHeaders = headersData.map(async (header: Headers) => {
        const data = {
          key: header.key,
          value: header.value,
          webhook: dataHeaders,
        };

        const URL_CREATE = '/webhook/header';

        const response = await api.post(URL_CREATE, data);

        onClear();
      });
    } catch (err) {}
  }, []);

  const initialValues: FormikInitialValues = {
    description: '',
    event: '',
    url: '',
    headerKey: '',
    headerValue: '',
    payload: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: FormikInitialValues) => {
      handleSubmit(values, headers);
      // handleSubmitHeaders(headers);
    }
  });

  const getEvents = useCallback(async () => {
    try {
      const response = await api.get('/webhook/event');

      if (response.data.length > 0) setEvents(response.data);
    } catch (err) {}
  }, []);

  const getHeaders = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/webhook/header/findByFilters',
        data
      );

      const headersData = response.data.map((item: Headers) => {
        return {
          key: item.key,
          value: item.value,
        };
      });

      return headersData;
    } catch (err) {}
  }, []);

  const getDataFieldValue = useCallback(async (events: Event[]) => {
    try {
      const response = await api.get(`/webhook/${dataWebhook?.id}`);

      setDataHeaders(response.data);

      formik.setFieldValue('description', response.data.description);
      formik.setFieldValue('url', response.data.endpoint);
      formik.setFieldValue('payload', response.data.payload);
      console.log('events', events);

      formik.setFieldValue(
        'event',
        events.find(eventData => eventData.nome === response.data.event)?.nome
      );

      const headersEdit = await getHeaders();

      setHeaders(headersEdit);
    } catch {}
  }, []);

  function handleEditorChange(value: any, event: any) {
    formik.setFieldValue('payload', value);
  }

  useEffect(() => {
    getEvents();
  }, [getEvents]);
  useEffect(() => {
    if (isEdit && events.length > 0) {
      getDataFieldValue(events);
    }
  }, [events, isEdit, getDataFieldValue]);

  return (
    <>
      {selectParamets && (
        <SelectParameters
          onClear={() => setSelectParamets(!selectParamets)}
          onSelectParameters={(item: any) => {
            return FrontendNotification(
              t('template.messageCopied'),
              NotificationType.SUCCESS
            );
          }}
        />
      )}
      <Row>
        <Col md={9}>
          <InputCustom
            title="Descricão*"
            onChange={formik.handleChange('description')}
            error={formik.errors.description}
            touched={formik.touched.description}
            placeholder="Insira a descrição"
            value={formik.values.description}
          />
        </Col>
        <Col md={3}>
          <SelectCustom
            title="Evento*"
            onChange={formik.handleChange('event')}
            error={formik.errors.event}
            touched={formik.touched.event}
            value={formik.values.event}
          >
            <>
              <option label={t('common.select')} value="" />
              {events.map(event => (
                <option label={event.slug} value={event.nome} />
              ))}
            </>
          </SelectCustom>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <InputCustom
            title="Endpoint"
            onChange={formik.handleChange('url')}
            error={formik.errors.url}
            touched={formik.touched.url}
            placeholder="Url"
            value={formik.values.url}
          />
        </Col>
      </Row>
      <Label
        style={{
          marginTop: '1.5rem',
          fontFamily: 'Lato',
          fontSize: '14px',
          color: '#003049',
          opacity: 0.6,
          fontStyle: 'normal',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        Headers
      </Label>

      <Row>
        <Col md={6}>
          <InputCustom
            title=""
            onChange={formik.handleChange('headerKey')}
            placeholder=""
            value={formik.values.headerKey}
          />
        </Col>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              textAlign: 'center',
              fontFamily: 'Lato',
              fontSize: '20px',
              color: '#003049',
              opacity: 0.6,
              fontStyle: 'normal',
            }}
          >
            =
          </span>
        </div>

        <Col md={5}>
          <InputCustom
            title=""
            onChange={formik.handleChange('headerValue')}
            placeholder=""
            value={formik.values.headerValue}
          />
        </Col>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{ cursor: 'pointer' }}
            src={AddButtonIcon}
            onClick={() => {
              if (editHeaders) {
                let addHeader: Headers[] = headers;

                const findHeaderByIndex = addHeader
                  .map((item: Headers, index: number) =>
                    item.id === dataEditHeaders.id ? index : -1
                  )
                  .filter((headerFilter: any) => headerFilter !== -1);

                addHeader[findHeaderByIndex[0]].key = formik.values.headerKey;
                addHeader[findHeaderByIndex[0]].value =
                  formik.values.headerValue;

                setHeaders(addHeader);

                setEditHeaders(!editHeaders);
              } else {
                let addHeader: Headers[] = headers;

                addHeader.push({
                  key: formik.values.headerKey,
                  value: formik.values.headerValue,
                });

                setHeaders(addHeader);
              }
              formik.setFieldValue('headerKey', '');
              formik.setFieldValue('headerValue', '');
            }}
          />
        </div>
      </Row>
      {headers.length > 0 && (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>
                <span
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    color: '#003049',
                    opacity: 0.6,
                    fontStyle: 'normal',
                  }}
                >
                  Chave
                </span>
              </th>
              <th>
                <span
                  style={{
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    color: '#003049',
                    opacity: 0.6,
                    fontStyle: 'normal',
                  }}
                >
                  Valor
                </span>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {headers.map((header: Headers, headerIndex: number) => (
              <tr className="lineGroup">
                <td style={{ width: '50%' }}>{header.key}</td>
                <td
                  style={{ width: '45%', maxWidth: '14ch' }}
                  className="uriLimit"
                >
                  {header.value}
                </td>
                <td>
                  <img
                    src={RemoveColumnJoruneyIcon}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      let removeElementHeaders: Headers[] = headers;

                      removeElementHeaders = removeElementHeaders.filter(
                        (el, i) => i !== headerIndex
                      );

                      setHeaders(removeElementHeaders);
                    }}
                  />
                  <img
                    src={EditIcon}
                    alt=""
                    style={{ cursor: 'pointer', marginLeft: '0.400rem' }}
                    onClick={() => {
                      console.log('Click Header Edit', header);
                      formik.setFieldValue('headerKey', header.key);
                      formik.setFieldValue('headerValue', header.value);
                      setEditHeaders(!editHeaders);
                      setDataEditHeaders(header);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ContainerEditorJSON>
        <Label
          style={{
            marginTop: '1.5rem',
            fontFamily: 'Lato',
            fontSize: '14px',
            color: '#003049',
            opacity: 0.6,
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          Payload
        </Label>
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: -10,
            float: 'right',
          }}
        >
          <p
            style={{
              fontFamily: 'Lato',
              fontWeight: 'bold',
              fontSize: 16,
              color: '#EA004C',
              cursor: 'pointer',
            }}
            onClick={() => setSelectParamets(!selectParamets)}
          >
            <Trans i18nKey="phases.parameters" />
          </p>
        </div>
        <EditorJSON>
          <Editor
            height="100px"
            defaultLanguage="json"
            defaultValue=""
            line={2}
            value={formik.values.payload}
            onChange={handleEditorChange}
          />
        </EditorJSON>
      </ContainerEditorJSON>

      <Footer>
        <div />
        <button className="button" onClick={() => formik.handleSubmit()}>
          Cadastrar
        </button>
      </Footer>
    </>
  );
}
