import React from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';

import {
  BackendErrorNotification,
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import history from '../../services/history'
import api from '../../services/api';
import { urlForgotPassword } from '../../services/endpoint';
import BaseTemplate from '../../template/baseTemplate';
import { Trans, withTranslation } from 'react-i18next';
import BaseComponents from '../../template/BaseComponents';
import { Title, ContainerTitle, PasswordContainer, Line, LabelInput, FowardButton } from 'components/Styled/guiPublic';
import Input from '../../components/form-teste/input';
import Button from '../../components/form-teste/button';
import Form from '../../components/form-teste/form';
import { isValidEmail } from '../../util/validationUtil';
import voltar from '../../assets/voltar-seta.png';
import './style.scss';
import { AccessAcc, BackButtom } from '../SignUp/styles';
import i18next from 'i18next';


class ForgotPassword extends BaseComponents {

  initialState = {
    email: '',
  };
  state = this.initState(this.initialState);

  handleSubmit = e => {



    e.preventDefault();

    if (this.isValid(this.getData())) {


      const language = i18next.language;

      const emailSendLanguage = language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US'

      api
        .post(urlForgotPassword+`?lang=${emailSendLanguage}`, this.getData())
        .then((result) => {
          
          if(result.status === 204) {
            FrontendNotification(language === 'pt' || language === 'pt-BR' ? `Enviamos um e-mail para ${this.getData().email}` : `We sent an email to ${this.getData().email}`, NotificationType.SUCCESS)

            setTimeout(() => {
              this.handleFormReset();
            history.push('/confirmForgot');
          window.location.reload();
            }, 3000)
            

          }

           
        })
        .catch(error => {

          BackendErrorNotification(error);
        })
        .finally(() => {
          this.showLoading(false);
        });


    }

  };

  isValid(data) {
    if (!data.email) {
      const message = this.props.t('form.field.message.emailRequired');
      FrontendNotification(message, NotificationType.WARNING);
      return false;
    }

    if (!isValidEmail(data.email)) {
      const message = this.props.t('form.field.message.invalidEmail');
      FrontendNotification(message, NotificationType.WARNING);
      return false;
    }

    return true;
  }

  getData = () => {
    return {
      email: this.state.email,
    };
  };

  handleFormReset = () => {
    this.setState(this.initialState);
  };

  render() {

    return (
      <BaseTemplate
        loading={this.getStatusLoading()}
        text={this.props.t('message.welcome.back')}
      >
        <div className="form-forget">
          <div className="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
              <AccessAcc style={{ marginTop: 30 }}>
                <Link to="/" className="voltar">
                  <img src={voltar} className="seta" alt="Back" />
                  {this.props.t('forgotPassword.back')}
                </Link>
              </AccessAcc>

            <ContainerTitle>
          <Title>
            <Trans i18nKey="forgotPassword.title" />
          </Title>
          <Line />
        </ContainerTitle>

          </div>
          <hr className="redLine"/>
          <div className="information">
            {this.props.t('forgotPassword.instruction')}
          </div>
          <Form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelMail">E-mail</label>
                  <br />
                  <Input.Text
                    className="form-control"
                    placeholder={this.props.t('form.field.placeholder.email')}
                    onChange={e => this.setState({ email: e.target.value })}
                    value={this.state.email}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                      <Button  className="btn btn-signup btn-block">
                        {this.props.t('button.send')}
                      </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </BaseTemplate>
    );
  }
}

export default withRouter(withTranslation()(ForgotPassword));
