import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import { SelectionChangedEvent, ValueFormatterParams } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import RemoveIcon from 'components/Grid/icons/RemoveIcon';
import UpdateIcon from 'components/Grid/icons/UpdateIcon';
import { useTranslation } from 'react-i18next';
import { numberToCurrencyBRL } from 'util/format';

interface ShippingListProps {
  data: any[];
  onDelete: (data: any[]) => void;
  onEdit: (data: any[]) => void;
  onSubmitShipping: () => void;
  type: string;
}

const ShippingList: React.FC<ShippingListProps> = ({
  data,
  onEdit,
  onDelete,
  onSubmitShipping,
  type,
}: ShippingListProps) => {
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>(null);

  const ratingDataList: any = localStorage.getItem('ratingData');

  const userData = useSelector((state: any) => state.auth.user);

  const [columns] = useState<any[]>([
    {
      field: 'filialOrigem',
      headerName: 'Filial',
      filter: true,
      width: 140,
      checkboxSelection: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.filialOrigem !== null && params.data.filialOrigem.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.branchs.find(
            (row: any) => String(row.id) == String(params.data.filialOrigem.id)
          );
          if (rating) {
            return rating.label;
          }
          return '';
        }
        return '';
      },
    },
    {
      field: 'operadorLogistico',
      headerName: 'Operador Log.',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.operadorLogistico !== null && params.data.operadorLogistico.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.logisticOperators.find(
            (row: any) => String(row.id) == String(params.data.operadorLogistico.id)
          );
          if (rating) {
            return rating.label;
          }
          return '---';
        }
        return '---';
      },
    },
    {
      field: 'tipoVeiculo',
      headerName: 'Tipo de Veiculo',
      filter: true,
      width: 175,
    },
    {
      field: 'veiculo',
      headerName: 'Veiculo',
      filter: true,
      width: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.veiculo !== null && params.data.veiculo.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.vehicle.find(
            (row: any) => String(row.id) == String(params.data.veiculo.id)
          );
          if (rating) {
            return rating.label;
          }
          return '---';
        }
        return '---';
      },
    },
    {
      field: 'franquias',
      headerName: 'KM Franquia',
      filter: true,
      width: 130,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return Number(params.data.franquias[0].qtd);
        }
      },
    },
    {
      field: 'franquias',
      headerName: 'VL Franquia',
      filter: true,
      width: 120,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return `R$ ${numberToCurrencyBRL(params.data.franquias[0].valor)}`;
        }
      },
    },
    {
      field: 'franquias',
      headerName: 'KM Exc.',
      filter: true,
      width: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return `R$ ${numberToCurrencyBRL(params.data.franquias[0].valorExcedente)}`;
        }
      },
    },
    {
      field: 'franquias',
      headerName: 'Inicio',
      filter: true,
      width: 90,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return Number(params.data.franquias[0].diaInicio);
        }
      },
    },
    {
      field: 'obs',
      headerName: 'Obs.',
      filter: true
    }
  ]);

  const [columnsZipcode] = useState([
    {
      field: 'filialOrigem',
      headerName: 'Filial',
      filter: true,
      checkboxSelection: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.filialOrigem !== null && params.data.filialOrigem.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.branchs.find(
            (row: any) => String(row.id) == String(params.data.filialOrigem.id)
          );
          if (rating) {
            return rating.label;
          }
          return '';
        }
        return '';
      },
    },
    {
      field: 'operadorLogistico',
      headerName: 'Operador Log.',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.operadorLogistico !== null && params.data.operadorLogistico.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.logisticOperators.find(
            (row: any) => String(row.id) == String(params.data.operadorLogistico.id)
          );
          if (rating) {
            return rating.label;
          }
          return '---';
        }
        return '---';
      },
    },
    {
      field: 'tipoVeiculo',
      headerName: 'Tipo de Veiculo',
      filter: true,
      width: 175,
    },
    {
      field: 'veiculo',
      headerName: 'Veiculo',
      filter: true,
      width: 100,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.veiculo !== null && params.data.veiculo.id.length > 0) {
          const ratingData = JSON.parse(ratingDataList);
          const rating = ratingData.vehicle.find(
            (row: any) => String(row.id) == String(params.data.veiculo.id)
          );
          if (rating) {
            return rating.label;
          }
          return '---';
        }
        return '---';
      },
    },
    {
      field: 'franquias',
      headerName: 'CEP',
      filter: true,
      width: 110,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return String(params.data.franquias[0].zipcode);
        }
      },
    },
    {
      field: 'franquias',
      headerName: 'Valor',
      filter: true,
      width: 110,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return numberToCurrencyBRL(params.data.franquias[0].valueZipcode);
        }
      },
    },
    {
      field: 'obs',
      headerName: 'Obs.',
      filter: true
    }
  ]);

  const { t } = useTranslation();

  const onSelectionChanged = (params: SelectionChangedEvent) => {
    const selectedRows = params.api.getSelectedRows();
    setSelectedRow(selectedRows);
    // props.setSelectedRows?.(selectedRows);
  };

  useEffect(() => {
    if (data) {
      setRowData(data);
    }
  }, [data]);

  return (
    <Container>
      <div className={'tw-mb-2 tw-flex tw-flex-col'}>
        <div className='tw-flex tw-items-center'>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={() => {
              if(selectedRow.length > 0) {
                onEdit(selectedRow)
              }
            }}
            // onClick={props.onUpdate}
            id="btnUpdate"
          >
            <UpdateIcon /> {t('action.update')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={() => onDelete(selectedRow)}
            // onClick={props.onDelete}
            id="btnDelete"
          >
            <RemoveIcon /> {t('action.remove')}
          </button>
        </div>
      </div>
      <div className="ag-theme-quartz tw-h-96 tw-mb-4">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={type === 'CEP' ? columnsZipcode : columns}
          rowSelection={'single'}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
      <div className="footer">
        <button
          type="button"
          className="button saveButton"
          style={{ maxWidth: 205 }}
          onClick={() => onSubmitShipping()}
        >
          Salvar
        </button>
      </div>
    </Container>
  );
};

export default ShippingList;
