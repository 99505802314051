export interface OccurrenceData {
  assinatura: boolean;
  assinaturaDescricao: string;
  baixaManual: boolean;
  bipagem: boolean;
  contratante: Contractor | null;
  dataCriacao: string;
  descricao: string;
  exibicaoMotorista: boolean;
  finalizacao: boolean;
  form: OccurrenceForm | null;
  foto: boolean;
  fotoDescricao: string;
  id: number;
  informativa: boolean;
  numero: string;
  recebedorDocumento: boolean;
  recebedorDocumentoDescricao: string;
  recebedorNome: boolean;
  recebedorNomeDescricao: string;
  responsabilidade: Responsability;
  sequencia: number;
  status: string;
  sucesso: boolean;
  tipo: CargoType;

}


export type OccurrenceForm = {
  id: number;
  description: string;
}

export type Company = {
  cnpj: string;
  nomeFantasia: string;
  razaoSocial: string;
}

export type Contractor = {
  id: number;
  idReferencia: string;
  pessoaJuridica: Company | null;
}

export type ActivityType = {
  id: number;
  nome: string;
}

export enum Responsability {
  EMBARCADOR = "EMBARCADOR",
  TRANSPORTADOR = "TRANSPORTADOR",
  CLIENTE = "CLIENTE"

}

export enum CargoType {
  ENTREGA = "ENTREGA",
  COLETA = "COLETA"
}

export enum TypeOccurrence {
  SUCESSO = "SUCESSO",
  INSUCESSO = "INSUCESSO",
  INFORMATIVA = "INFORMATIVA"
}