// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lineGroupSimulation {
  border-bottom: 1px solid #ccd6db;
}

.pagination-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.centerRowRoutering {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.columnRoutering {
  font-size: 0.875rem;
  font-weight: bold;
  font-family: "Lato";
}

.rowRoutering {
  font-size: 0.875rem;
  font-family: "Lato";
  color: #003049;
}`, "",{"version":3,"sources":["webpack://./src/components/Routering/styles.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;;AAGA;EACI,mBAAA;EACA,mBAAA;EACA,cAAA;AAAJ","sourcesContent":[".lineGroupSimulation {\n    border-bottom: 1px solid #ccd6db;\n}\n\n.pagination-footer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 20px;\n}\n\n.centerRowRoutering {\n    width: 40%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.columnRoutering {\n    font-size: 0.875rem;\n    font-weight: bold;\n    font-family: 'Lato';\n\n}\n\n.rowRoutering {\n    font-size: 0.875rem;\n    font-family: 'Lato';\n    color: #003049;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
